import React, { ChangeEvent } from 'react';

import { InputFieldWrapper } from '../InputFieldWrapper';
import { Message } from '../Message';
import { Skeleton } from '../Skeleton';

import { Checkbox } from './Checkbox';
import { ICheckboxItem } from './Checkbox/Checkbox.types';
import { ICheckboxGroup } from './CheckboxGroup.types';

const CheckboxGroup = ({
  errorMessage,
  isDisabled = false,
  isLabelHidden = false,
  isRequired = false,
  items = [],
  legendText,
  name,
  onChange,
  values = [],
  tooltipPosition = 'end',
  tooltipText,
  hasLabelPadding = false,
  hasPadding = true,
  isLoading = false,
  labelFontWeight = 'light',
}: ICheckboxGroup) => {
  if (isLoading) return <Skeleton height="5rem" />;
  return (
    <fieldset aria-describedby={errorMessage ? `id_${name}_error` : undefined} data-testid="qa-checkbox-group-fieldset">
      <InputFieldWrapper
        hasLabelPadding={hasLabelPadding}
        hasPadding={hasPadding}
        id=""
        isLabelHidden={isLabelHidden}
        isLoading={isLoading}
        isRequired={isRequired}
        label={legendText}
        labelFontWeight={labelFontWeight}
        tooltipPosition={tooltipPosition}
        tooltipText={tooltipText}
        type="custom"
      >
        <div>
          {items.map((item: ICheckboxItem) => {
            const { deleteButton, id, imageSrc, labelText: checkboxItemLabelText, value } = item;
            return (
              <Checkbox
                key={`checkbox-${id}`}
                data-testid="qa-checkbox"
                deleteButton={deleteButton}
                id={id}
                imageSrc={imageSrc}
                isChecked={values.includes(value.toString())}
                isDisabled={isDisabled}
                labelText={checkboxItemLabelText}
                name={name}
                value={value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const {
                    target: { value: inputValue },
                  } = event;

                  const updatedValues = values.includes(inputValue)
                    ? values.filter((currentValue) => currentValue !== inputValue)
                    : [...values, inputValue];

                  onChange(updatedValues);
                }}
              />
            );
          })}
          {Boolean(errorMessage) && <Message id={`id_${name}_error`} text={errorMessage} type="ERROR" />}
        </div>
      </InputFieldWrapper>
    </fieldset>
  );
};

export { CheckboxGroup };
