import React from 'react';

import '../../Moderation/Moderation.scss';

import RoundSettingsButton from '../../../UI/Button/RoundSettingsButton/RoundSettingsButton';

export const getTopicsTableData = (data, onOpenSidebar) =>
  data.map(({ id, title: topic }) => ({
    id,
    topic,
    settings: <RoundSettingsButton onClick={() => onOpenSidebar(id)} />,
  }));
