import gql from 'graphql-tag';

export const FRAGMENT_DOCUMENT = gql`
  fragment document on ContentSnippetDocumentType {
    assetId
    asset {
      presignedUrl
      s3Key
      contentType
    }
  }
`;
