import gql from 'graphql-tag';

export const ADD_BUBBLE_TEXT = gql`
  mutation addBubbleText(
    $text: String!
    $angle: Decimal!
    $xAxis: Int!
    $yAxis: Int!
    $width: Int!
    $shape: EBubbleShape!
    $tailType: EBubbleTail!
    $assetId: String!
    $characterId: Int
    $hasShadow: Boolean!
  ) {
    bubbleText {
      add(
        request: {
          text: $text
          angle: $angle
          x: $xAxis
          y: $yAxis
          w: $width
          shape: $shape
          tailType: $tailType
          assetId: $assetId
          characterId: $characterId
          hasShadow: $hasShadow
        }
      ) {
        id
        text
        angle
        x
        y
        w
        shape
        tailType
        isTailVisible
        assetId
        characterId
        hasShadow
        character {
          name
          id
        }
      }
    }
  }
`;
