import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_MULTI_LINE_FEEDBACK_CONFIG } from './UseUpsertSummaryFeedbackConfigMultiLine.graphql';

const useUpsertSummaryFeedbackConfigMultiLine = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeUpsertSummaryFeedbackConfigMultiLine, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? CREATE_MULTI_LINE_FEEDBACK_CONFIG,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { createMultiLineSummaryFeedbackConfig: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpsertSummaryFeedbackConfigMultiLine = ({ variables }) => {
    executeUpsertSummaryFeedbackConfigMultiLine({
      variables,
    });
  };

  return {
    handleUpsertSummaryFeedbackConfigMultiLine,
    isLoading,
  };
};

export { useUpsertSummaryFeedbackConfigMultiLine };
