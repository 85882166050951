/* eslint-disable react/jsx-props-no-spreading */

import React, { Component, useState, useEffect } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import FirstPageOfContentGroup from './FirstPageOfContentGroup';
import PageInitial from './PageInitial';
import { getIsFormBuilder, handleOnDragEnd } from './PageUtils';
import { Bin, BinContainer, Container, EditBox, ModalButtonGroup } from './styled';

import { CustomSettingsSidebar, ActionsSidebar } from '../../../new-ekardo';
import CartoonContent from '../../components/Cartoon/CartoonContent';
import PageLevelFooter from '../../components/ConsoleFooter/PageLevelFooter';
import ContentPageNavigation from '../../components/ContentPage/ContentPageNavigation';
import ContentSection from '../../components/ContentSection/ContentSection';
import LayoutContainer from '../../components/LayoutContainer/LayoutContainer';
import ContentPageNavBar from '../../components/LeftNavBar/ContentPageNavBar';
import Preloader from '../../components/Preloader/Preloader';
import Preview from '../../components/Preview/Preview';
import ContainerSideBar from '../../components/RightNavBar/ContentContainerSideBar';
import ContentEventSidebar from '../../components/RightNavBar/ContentEventSideBar';
import ContentPageQuestionSideBar from '../../components/RightNavBar/ContentPageQuestionSideBar';
import FormSidebar from '../../components/RightNavBar/FormSideBar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import EditBtn from '../../components/UI/Button/EditButton';
import PlusBtn from '../../components/UI/Button/PlusButtonCircle';
import SquareButton from '../../components/UI/Button/SquareButton';
import NormalModal from '../../components/UI/Modal/NormalModal';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useContentPageState } from '../../context/contentPage/ContentPageContext';
import ContentPageState from '../../context/contentPage/ContentPageState';
import { useNewEkardoSiteUrls } from '../../hooks';
import DeleteContentContainerRequest from '../../middleware/ContentContainer/deleteContainerRequest';
import SortContainerRequest from '../../middleware/ContentContainer/sortContainerRequest';
import CreateContentSectionRequest from '../../middleware/ContentSection/createContentSectionRequest';
import DeleteContentSectionRequest from '../../middleware/ContentSection/deleteContentSectionRequest';
import UpdateContentSectionRequest from '../../middleware/ContentSection/updateContentSectionRequest';
import DeleteContentSnippetRequest from '../../middleware/ContentSnippet/deleteContentSnippetRequest';
import UpdateContentSnippetBaseRequest from '../../middleware/ContentSnippet/updateContentSnippetBaseRequest';
import { capitalize, getBreadCrumbPath, getContainers } from '../../utils/utils';

class ContentPage extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      activePageId: null,
      answers: [],
      bin: {
        isDeletingContainer: false,
        isDeletingSection: false,
        isDeletingSinppet: false,
        isShowingBin: false,
        type: '',
      },
      container: {
        containerId: null,
        createRequest: false,
        deleteRequest: false,
        layoutId: null,
        sectionId: null,
        sortRequest: false,
        updateRequest: false,
      },
      contentPage: {},
      delete: {
        container: null,
        isDeleteModalOpen: false,
        message: '',
        section: null,
        snippet: null,
        type: '',
      },
      dndSections: {},
      editMode: true,
      group: {},
      identities: {},
      layout: {
        layoutArray: [],
        selectedLayout: '',
      },
      navbarOpen: {},
      organisation: {},
      pageInitialized: false,
      section: {
        containers: [],
        createRequest: false,
        deleteRequest: false,
        id: null,
        isAddNew: false,
        layoutId: null,
        sectionArray: [],
        sort: null,
        sortRequest: false,
        updateRequest: false,
      },
      section2: {
        deleteRequest: false,
        id: null,
        sortRequest: false,
        layoutId: null,
        updateRequest: false,
      },
      sectionSortArray: [],
      snippet: {
        createRequest: false,
        deleteRequest: false,
        updateRequest: false,
        id: null,
      },
      snippetArray: [],
    };
  }

  getData = (data) => {
    const { section, layout } = this.state;

    const {
      activePageId,
      answers,
      dndSections,
      group,
      layout: { layoutArray },
      organisation,
      pageInitialized,
      project,
      section: { sectionArray },
      sectionSortArray,
      snippetArray,
    } = data;

    const { id: groupId } = group;
    const { id: projectId } = project;
    const { id: organisationId } = organisation;

    this.setState({
      activePageId,
      answers,
      dndSections,
      group: {
        formType: group.formType,
        id: groupId,
        name: group.name,
        type: group.type,
      },
      identities: {
        organisationId,
        projectId,
        groupId,
        pageId: activePageId,
      },
      layout: {
        ...layout,
        layoutArray,
      },
      organisation: {
        id: organisationId,
        name: organisation.name,
      },
      pageInitialized,
      project: {
        id: projectId,
        name: project.name,
      },
      section: {
        ...section,
        sectionArray,
      },
      sectionSortArray,
      snippetArray,
    });
  };

  getDndSectionKey = (sectionId) => `section-${sectionId}`;

  handleAddContainer = (container) => {
    const { dndSections } = this.state;
    const { layoutId: contentLayoutId, newContainer, sectionId } = container;

    const newContainerOrder = dndSections[sectionId].containerOrder;

    newContainerOrder.push(newContainer.dndId);

    this.setState({
      dndSections: {
        ...dndSections,
        [sectionId]: {
          ...dndSections[sectionId],
          containerOrder: newContainerOrder,
          containers: {
            ...dndSections[sectionId].containers,
            [newContainer.dndId]: {
              dndId: newContainer.dndId,
              dndSnippets: {},
              id: newContainer.id,
              snippetOrder: [],
              snippets: [],
              sort: newContainer.sort,
            },
          },
          contentLayoutId,
        },
      },
    });
  };

  handleAddNewPage = (newPageId) => {
    this.setState({
      activePageId: newPageId,
      pageInitialized: false,
    });
  };

  handleAddSection = () => {
    const { section } = this.state;

    this.setState({
      section: {
        ...section,
        isAddNew: true,
      },
    });
  };

  handleAddSnippet = (newSnippet) => {
    const { dndSections, snippetArray } = this.state;
    const { containerId, sectionId, snippet } = newSnippet;
    const { id: snippetId } = snippet;

    const dndSectionKey = this.getDndSectionKey(sectionId);
    const currentContainer = dndSections[dndSectionKey].containers[`container-${containerId}`];

    currentContainer.dndSnippets[`snippet-${snippetId}`] = snippet;
    currentContainer.snippetOrder.push(`snippet-${snippetId}`);
    currentContainer.snippets.push(snippet);
    this.setState({
      snippetArray: [...snippetArray, snippet],
    });
  };

  handleCloseRightSideBar = () => {
    const { handleShowSidebar } = this.context;

    handleShowSidebar([false, '', null]);
  };

  handleCreateSectionError = () => {
    const { section } = this.state;

    this.setState({
      section: {
        ...section,
        createRequest: false,
      },
    });
  };

  handleCreateSectionSuccess = (data) => {
    const { id, contentLayoutId, sort, sectionContainers } = data;
    const { activePageId, dndSections, group, organisation, project, section, sectionSortArray } = this.state;

    const newSectionArray = [...section.sectionArray];

    newSectionArray.push({
      contentLayoutId,
      contentPageId: activePageId,
      id,
      sectionContainers,
      sort,
      type: 'ContentSectionType',
    });

    const containers = {};
    const containerOrder = sectionContainers
      .sort((a, b) => a.sort - b.sort)
      .map((item, index) => {
        containers[`container-${item.id}`] = {
          dndId: `container-${item.id}`,
          dndSnippets: {},
          id: item.id,
          sectionId: id,
          snippetOrder: [],
          snippets: [],
          sort: index,
        };
        return `container-${item.id}`;
      });

    const dndSectionKey = this.getDndSectionKey(id);
    const newDndsections = dndSections;

    newDndsections[dndSectionKey] = {
      containerNumber: sectionContainers.length,
      containerOrder,
      containers,
      contentLayoutId,
      contentPageId: activePageId,
      dndId: dndSectionKey,
      groupId: group.id,
      groupType: group.type,
      id,
      organisationId: organisation.id,
      projectId: project.id,
      sort,
    };

    this.setState({
      section: {
        ...section,
        createRequest: false,
        dndSections: newDndsections,
        isAddNew: false,
        sectionArray: newSectionArray,
      },
      sectionSortArray: [...sectionSortArray, dndSectionKey],
    });
  };

  handleDeleteAction = () => {
    const {
      delete: { type, snippet, container, section },
    } = this.state;

    if (type === 'snippet') {
      this.handleDeleteSnippet(snippet, false);
      return;
    }

    if (type === 'container') {
      this.handleDeleteContainer(container, false);
      return;
    }

    if (type === 'section') {
      this.handleDeleteSection(section, false);
    }
  };

  handleDeleteContainer = (container, doConfirm) => {
    const { id: containerId, sectionId, layoutId } = container;

    const result = {
      containerId,
      destination: {
        droppableId: 'bin',
        index: 0,
      },
      draggableId: `container-${containerId}`,
      layoutId,
      sectionId,
      source: {
        droppableId: `section-${sectionId}`,
        index: -1,
      },
      type: 'container',
    };

    if (doConfirm) {
      this.setState({
        delete: {
          container,
          isDeleteModalOpen: true,
          message: 'Are you sure you want to delete this container?',
          type: 'container',
        },
      });
      return;
    }

    this.setState({
      delete: {
        isDeleteModalOpen: false,
      },
    });

    this.handleDragEnd(result);
  };

  handleDeleteContainerCompleted = () => {
    const { container } = this.state;

    this.setState({
      container: {
        ...container,
        deleteRequest: false,
      },
    });
  };

  handleDeleteSection = (section, doConfirm) => {
    const { id: sectionId } = section;
    const dndSectionKey = this.getDndSectionKey(sectionId);

    const result = {
      destination: {
        droppableId: 'bin',
        index: 0,
      },
      draggableId: dndSectionKey,
      sectionId,
      source: {
        droppableId: dndSectionKey,
        index: -1,
      },
      type: 'section',
    };

    if (doConfirm) {
      this.setState({
        delete: {
          isDeleteModalOpen: true,
          message: 'Are you sure you want to delete this section?',
          section,
          type: 'section',
        },
      });

      return;
    }

    this.setState({
      delete: {
        isDeleteModalOpen: false,
      },
    });

    this.handleDragEnd(result);
  };

  handleDeleteSectionCompleted = () => {
    const { section } = this.state;

    this.setState({
      section: {
        ...section,
        deleteRequest: false,
      },
    });
  };

  handleDeleteSnippet = (snippet, doConfirm) => {
    const result = {
      destination: {
        droppableId: 'bin',
        index: 0,
      },
      draggableId: `snippet-${snippet.id}`,
      snippetId: snippet.id,
      source: {
        droppableId: `container-${snippet.containerId}`,
        index: -1,
      },
      type: 'snippet',
    };

    if (doConfirm) {
      this.setState({
        delete: {
          isDeleteModalOpen: true,
          message: 'Are you sure you want to delete this snippet?',
          snippet,
          type: 'snippet',
        },
      });
      return;
    }

    this.setState({
      delete: {
        isDeleteModalOpen: false,
      },
    });

    this.handleDragEnd(result);
  };

  handleDeleteSnippetCompleted = () => {
    const { snippet } = this.state;

    this.setState({
      snippet: {
        ...snippet,
        deleteRequest: false,
      },
    });
  };

  handleDragEnd = (result) => {
    const { container, dndSections, section, sectionSortArray, snippet } = this.state;

    this.setState({
      bin: {
        isShowingBin: false, // keep
      },
    });

    // handle data
    const finalResult = handleOnDragEnd(result, sectionSortArray, dndSections);
    if (!finalResult) {
      return;
    }

    const {
      container: finalResultContainer,
      delete: finalResultDelete,
      dndSections: finalResultDndSections,
      sectionSortArray: finalResultSectionSortArray,
      snippet: finalResultSnippet,
      update: finalResultUpdate,
    } = finalResult;

    if (finalResultSectionSortArray) {
      // sort sections in UI
      this.setState({
        sectionSortArray: finalResultSectionSortArray,
      });

      // delete a section
      if (finalResultDelete) {
        this.setState({
          section: {
            ...section,
            id: finalResultDelete.id,
            deleteRequest: true,
            sectionArray: finalResultSectionSortArray,
          },
          sectionSortArray: finalResultSectionSortArray,
        });
      }

      // update (sort) a section
      if (finalResultUpdate) {
        const { containers, id, layoutId, sort } = finalResultUpdate;

        this.setState({
          section: {
            ...section,
            containers,
            id,
            layoutId,
            sort,
            updateRequest: true,
          },
        });
      }
    }

    if (finalResultDndSections) {
      // sort containers or snippets in UI
      this.setState({
        dndSections: finalResultDndSections,
      });

      // changes in container
      if (finalResultContainer) {
        const { delete: deleteContainer, update: updateContainer } = finalResultContainer;

        // delete a container
        if (deleteContainer) {
          const { containerId, layoutId, sectionId } = deleteContainer;

          // if this section only left one container
          if (!layoutId) {
            const dndSectionKey = this.getDndSectionKey(sectionId);
            const filteredSectionSortArray = sectionSortArray.filter((item) => item !== dndSectionKey);

            const updatedDndSections = {
              ...dndSections,
            };

            delete updatedDndSections[dndSectionKey];

            this.setState({
              dndSections: updatedDndSections,
              section: {
                ...section,
                deleteRequest: true,
                id: sectionId,
                sectionArray: filteredSectionSortArray,
              },
              sectionSortArray: filteredSectionSortArray,
            });
          } else {
            this.setState({
              container: {
                ...container,
                containerId,
                deleteRequest: true,
                layoutId,
                sectionId,
              },
            });
          }
        }

        // update container
        if (updateContainer) {
          const { containerId, finishLayoutId, sectionId, sort, startLayoutId, startSectionId } = updateContainer;

          // dnd a container to the different section
          if (finishLayoutId) {
            this.setState({
              section: {
                ...section,
                id: sectionId,
                layoutId: finishLayoutId,
              },
              container: {
                ...container,
                sortRequest: true,
                sectionId,
                containerId,
                sort,
              },
            });

            if (!startLayoutId) {
              const dndSectionKey = this.getDndSectionKey(startSectionId);
              const filteredSectionSortArray = sectionSortArray.filter((item) => item !== dndSectionKey);

              this.setState({
                section2: {
                  deleteRequest: true,
                  id: startSectionId,
                },
                sectionSortArray: filteredSectionSortArray,
              });
            } else {
              this.setState({
                section2: {
                  id: startSectionId,
                  layoutId: startLayoutId,
                  sortRequest: true,
                },
              });
            }
          } else {
            // sort container in the same section
            this.setState({
              ...container,
              container: {
                containerId,
                sectionId,
                sort,
                sortRequest: true,
              },
            });
          }
        }
      }

      // changes in snippet
      if (finalResultSnippet) {
        const { delete: deleteSnippet, update: updateSnippet } = finalResultSnippet;

        // delete a snippet
        if (deleteSnippet) {
          this.setState({
            ...snippet,
            snippet: {
              deleteRequest: true,
              id: deleteSnippet.id,
            },
          });
        }

        // sort a snippet
        if (updateSnippet) {
          const { id, containerId, sort } = updateSnippet;

          this.setState({
            ...snippet,
            snippet: {
              containerId,
              id,
              sort,
              updateRequest: true,
            },
          });
        }
      }
    }
  };

  handleFirstPageCreationSuccess = (data) => {
    this.setState({
      activePageId: data,
      pageInitialized: false,
    });
  };

  handleGetActivePageId = (id) => {
    const { firstPage, reloadCartoonResponse } = this.state;

    if (id) {
      this.setState({
        activePageId: id,
        pageInitialized: false,
        reloadCartoonResponse: {
          ...reloadCartoonResponse,
          activePageId: id,
        },
      });

      return;
    }

    this.setState({
      activePageId: id,
      firstPage: {
        ...firstPage,
        hasFirstPage: false,
      },
      reloadCartoonResponse: {
        ...reloadCartoonResponse,
        activePageId: id,
      },
    });
  };

  handleGetDisplayStatus = (showed) => {
    if (showed) {
      const style = {
        left: '10em',
        position: 'relative',
        width: 'calc(90% - 20em)',
      };

      this.setState({
        navbarOpen: style,
      });

      return;
    }

    this.setState({ navbarOpen: {} });
  };

  handleLayoutClose = () => {
    const { layout, section } = this.state;

    this.setState({
      layout: {
        ...layout,
        isAddNew: false,
      },
      section: {
        ...section,
        isAddNew: false,
      },
    });
  };

  handleOnBeforeCapture = (value) => {
    const { bin } = this.state;

    const type = value.draggableId.substring(0, value.draggableId.indexOf('-'));

    this.setState({
      bin: {
        ...bin,
        isShowingBin: true,
        type,
      },
    });
  };

  handleRefreshData = (data) => {
    const { section, layout } = this.state;

    const {
      activePageId,
      answers,
      dndSections,
      layout: { layoutArray },
      pageInitialized,
      section: { sectionArray },
      sectionSortArray,
    } = data;

    this.setState({
      activePageId,
      answers,
      dndSections,
      layout: {
        ...layout,
        layoutArray,
      },
      pageInitialized,
      section: {
        ...section,
        sectionArray,
      },
      sectionSortArray,
    });
  };

  handleRefreshPage = () => {
    this.setState({
      pageInitialized: false,
    });
  };

  handleRefreshTree = (activePageId) => {
    const { pageInitialized } = this.state;

    this.setState({
      activePageId,
      pageInitialized: !pageInitialized,
    });
  };

  handleSelectLayout = (name) => {
    const { layout, section } = this.state;

    this.setState({
      layout: {
        ...layout,
        isAddNew: false,
        selectedLayout: name,
      },
      section: {
        ...section,
        createRequest: true,
      },
    });
  };

  handleSetEditMode = (value) => {
    this.setState({
      editMode: value,
    });
  };

  handleSortContainerError = () => {
    const { container } = this.state;

    this.setState({
      container: {
        ...container,
        sortRequest: false,
      },
    });
  };

  handleSortContainerSuccess = () => {
    const { container, section, section2 } = this.state;

    if (section2.sortRequest) {
      this.setState({
        section: {
          ...section,
          updateRequest: true,
        },
        section2: {
          ...section2,
          sortRequest: false,
          updateRequest: true,
        },
      });
    }

    if (section2.deleteRequest) {
      this.setState({
        section: {
          ...section,
          updateRequest: true,
        },
      });
    }

    this.setState({
      container: {
        ...container,
        sortRequest: false,
      },
    });
  };

  handleUpdateForm = ({ group: { title } }) => {
    const { group } = this.state;

    this.setState({
      group: {
        ...group,
        name: title,
      },
    });
  };

  handleUpdateSectionError = () => {
    const { section, section2 } = this.state;

    this.setState({
      section: {
        ...section,
        updateRequest: false,
      },
      section2: {
        ...section2,
        updateRequest: false,
      },
    });
  };

  handleUpdateSectionSuccess = () => {
    const { section, section2 } = this.state;

    if (section2.deleteRequest) {
      this.setState({
        section: {
          ...section,
          deleteRequest: true,
          id: section2.id,
          updateRequest: false,
        },
        section2: {
          ...section2,
          deleteRequest: false,
          updateRequest: false,
        },
      });

      return;
    }

    this.setState({
      section: {
        ...section,
        updateRequest: false,
      },
      section2: {
        ...section2,
        updateRequest: false,
      },
    });
  };

  handleUpdateSnippet = (updatedSnippet) => {
    const { dndSections } = this.state;
    const { containerId, sectionId, snippet } = updatedSnippet;
    const { id: snippetId } = snippet;

    const dndSectionKey = this.getDndSectionKey(sectionId);
    const currentContainer = dndSections[dndSectionKey].containers[`container-${containerId}`];
    const snippetIndex = currentContainer.dndSnippets[`snippet-${snippetId}`].sort;

    currentContainer.snippets.splice(snippetIndex, 1, snippet);
    currentContainer.dndSnippets[`snippet-${snippetId}`] = snippet;
  };

  handleUpdateSnippetCompleted = () => {
    const { snippet } = this.state;

    this.setState({
      snippet: {
        ...snippet,
        updateRequest: false,
      },
    });
  };

  render() {
    const {
      activePageId,
      answers,
      bin,
      container,
      dndSections,
      editMode,
      group,
      identities,
      layout,
      navbarOpen,
      pageInitialized,
      section,
      section2,
      sectionSortArray,
      snippet,
      snippetArray,
      contentPage,
    } = this.state;

    const {
      match,
      match: {
        params: { pageType, type },
      },
      breadCrumbPath,
      dispatch,
      pageState,
    } = this.props;

    const { handleShowSidebar, showRightSidebar, actionsSidebarDetails, setActionsSidebarDetails } = this.context;
    const { layoutArray, selectedLayout } = layout || {};

    const { type: actionType } = actionsSidebarDetails || {};

    const activeLayoutId = selectedLayout && layoutArray.find((obj) => obj.name === selectedLayout).id;
    const containers = selectedLayout && getContainers(selectedLayout);

    const isFormBuilder = getIsFormBuilder(pageType);

    const sectionsOfGroup = sectionSortArray
      .map((dndSectionKey, index) => {
        const currentSection = dndSections[dndSectionKey];

        return (
          <ContentSection
            key={currentSection.dndId}
            activePageId={activePageId}
            addContainer={this.handleAddContainer}
            addSnippet={this.handleAddSnippet}
            answers={answers}
            deleteContainer={(deleteContainer) => this.handleDeleteContainer(deleteContainer, true)}
            deleteSection={this.handleDeleteSection}
            deleteSnippet={(deleteSnippet) => this.handleDeleteSnippet(deleteSnippet, true)}
            getSelectedContainerId={this.getSelectedContainerId}
            identities={identities}
            index={index}
            match={match}
            section={currentSection}
            updateSnippet={this.handleUpdateSnippet}
            onRefreshPage={this.handleRefreshPage}
          />
        );
      })
      .filter((item) => item);

    const {
      delete: { message, isDeleteModalOpen },
    } = this.state;

    // Actions delete functionality
    let onDelete;

    if (actionType === 'targetSnippet') {
      onDelete = (data) => {
        this.handleDeleteSnippet(data, false);
      };
    } else if (actionType === 'container') {
      onDelete = (data) => {
        this.handleDeleteContainer(data, false);
      };
    } else {
      onDelete = undefined;
    }

    return (
      <PageWrapper
        breadPath={breadCrumbPath}
        containerStyle={navbarOpen}
        // eslint-disable-next-line react/destructuring-assignment
        info={
          <>
            <span>
              Manage {group.name} content: {contentPage.title}
            </span>
            {isFormBuilder && (
              <EditBox>
                <EditBtn
                  className="button--blue"
                  type="button"
                  onClick={() => {
                    handleShowSidebar([true, 'FORMEDIT', group.id]);
                  }}
                />
              </EditBox>
            )}
          </>
        }
        pageDescription="Build your own content pages"
        pageTitle="Content page"
        params={match.params}
      >
        {!pageInitialized && (
          <>
            <Preloader />
            <PageInitial getData={this.getData} match={match} pageId={activePageId} refreshData={this.handleRefreshData} />
          </>
        )}
        {pageInitialized && (
          <>
            {activePageId && <Preview editMode={this.handleSetEditMode} groupId={group.id} pageId={activePageId} />}
            {group.name !== '' && activePageId && !isFormBuilder && (
              <ContentPageNavBar
                contentGroupId={group.id}
                contentGroupName={group.name}
                contentGroupType={type}
                defaultPageId={activePageId}
                getActivePageId={this.handleGetActivePageId}
                handleRefreshPage={this.handleRefreshPage}
                handleGetDisplayStatus={this.handleGetDisplayStatus}
              />
            )}
            {/* Display Right sidebar for 1. adding a new form
              or adding animation on section/container/snippet;
              2. adding question conditions;
                      Why I use React context?
              Because the sidebar is sitting on Page level(contentPage),
              we trigger "showRightSidebar" at snippet level
              (or section/container).
              the value of "showRightSidebar" is an array, which includes
              [show, type, id].
              show - true/false
              type - Form/Snippet/Container/Section
              id
              */}
            {showRightSidebar[2] && showRightSidebar[0] && (
              <div>
                {showRightSidebar[1] === 'FORM' && <FormSidebar groupId={group.id} />}
                {showRightSidebar[1] === 'FORMEDIT' && (
                  <FormSidebar
                    closeFromBar={() => {
                      handleShowSidebar([false, '', null]);
                    }}
                    groupId={group.id}
                    isEdit
                    onUpdate={this.handleUpdateForm}
                  />
                )}
                {showRightSidebar[1] === 'SNIPPET' && (
                  <ContentEventSidebar
                    onRefreshPage={this.handleRefreshPage}
                    onSnippetDelete={(data) => {
                      this.handleDeleteSnippet(data, false);
                    }}
                  />
                )}
                {showRightSidebar[1] === 'CUSTOM_SETTINGS' && (
                  <CustomSettingsSidebar
                    snippet={snippetArray.find(({ id }) => id === showRightSidebar[2])}
                    isOpen
                    onClose={this.handleCloseRightSideBar}
                    onDelete={(data) => {
                      this.handleDeleteSnippet(data, false);
                    }}
                    onRefreshPage={this.handleRefreshPage}
                  />
                )}
                {showRightSidebar[1] === 'QUESTION_ADVANCE' && (
                  <ContentPageQuestionSideBar pageId={activePageId} questionId={showRightSidebar[2]} snippets={snippetArray} />
                )}
                {showRightSidebar[1] === 'ACTIONS' && (
                  <ActionsSidebar
                    activePageId={activePageId}
                    isOpen
                    onClose={() => {
                      setActionsSidebarDetails({});
                      this.handleCloseRightSideBar();
                    }}
                    onDelete={onDelete}
                  />
                )}

                {showRightSidebar[1] === 'CONTAINER' && (
                  <ContainerSideBar
                    onContainerDelete={(data) => {
                      this.handleDeleteContainer(data, false);
                    }}
                    onRefreshPage={this.handleRefreshPage}
                  />
                )}
                {!['CUSTOM_SETTINGS', 'ACTIONS'].includes(showRightSidebar[1]) && <Backdrop isClicked={this.handleCloseRightSideBar} />}
              </div>
            )}
            <NormalModal isOpen={Boolean(isDeleteModalOpen)} onClose={() => this.setState({ delete: { isDeleteModalOpen: false } })}>
              {message}
              <ModalButtonGroup>
                <SquareButton isClicked={() => this.setState({ delete: { isDeleteModalOpen: false } })}>No, cancel</SquareButton>
                <SquareButton bgColor="#dc3545" color="white" isClicked={this.handleDeleteAction}>
                  Yes, delete
                </SquareButton>
              </ModalButtonGroup>
            </NormalModal>
            {/* When content group doesn't has any pages */}
            {pageState.navTreeContentPageIds.length > 1 && (
              <ContentPageNavigation activePageId={activePageId} refreshTree={(response) => this.handleRefreshTree(response)} />
            )}
            {Object.keys(pageState.contentPage).length > 0 && type === 'cartoon' && <CartoonContent />}
            {!activePageId && (
              <FirstPageOfContentGroup contentGroupId={group.id} onFirstPageCreationSuccess={this.handleFirstPageCreationSuccess} />
            )}
            {activePageId && editMode && (
              <DragDropContext onBeforeCapture={this.handleOnBeforeCapture} onDragEnd={this.handleDragEnd}>
                <Droppable direction="vertical" droppableId="all-sections" type="section">
                  {(provided, snapshot) => (
                    <Container
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      draggingFromThisWith={snapshot.draggingFromThisWith}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      {sectionsOfGroup}
                      {provided.placeholder}
                    </Container>
                  )}
                </Droppable>
                {bin.isShowingBin && (
                  <Bin>
                    <Droppable direction="vertical" droppableId="bin" type={bin.type}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          <BinContainer isDraggingOver={snapshot.isDraggingOver} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Bin>
                )}
              </DragDropContext>
            )}
            {(section.isAddNew || (activePageId && section.sectionArray.length === 0)) && (
              <LayoutContainer
                activeLayout={selectedLayout}
                handleActiveLayout={this.handleSelectLayout}
                onClose={this.handleLayoutClose}
              />
            )}
            {editMode &&
              section &&
              section.sectionArray.length > 0 &&
              !section.isAddNew &&
              (group.formType === undefined || group.formType === null || group.formType !== 'QUIZ') && (
                <div style={{ margin: '1em 0em 7em 1em', width: 'fit-content' }}>
                  <PlusBtn onClicked={this.handleAddSection} />
                </div>
              )}
            {pageState.navTreeContentPageIds.length > 1 && (
              <ContentPageNavigation activePageId={activePageId} refreshTree={(response) => this.handleRefreshTree(response)} />
            )}
            {activePageId && pageInitialized && (
              <PageLevelFooter
                contentGroupId={group.id}
                currentPageId={activePageId}
                sideOpen={Object.keys(navbarOpen).length > 0}
                onAdded={(id) => this.handleAddNewPage(id)}
                onContentPageLoaded={(contentPageLoaded) => {
                  dispatch({
                    type: 'setContentPage',
                    newState: contentPageLoaded,
                  });
                }}
              />
            )}
          </>
        )}

        {section.createRequest && (
          <CreateContentSectionRequest
            containers={containers}
            contentLayoutId={activeLayoutId}
            contentPageId={activePageId}
            sort={section.sectionArray.length}
            onError={this.handleCreateSectionError}
            onSuccessResult={this.handleCreateSectionSuccess}
          />
        )}
        {section.updateRequest && (
          <UpdateContentSectionRequest
            // containers={section.containers}
            contentLayoutId={section.layoutId}
            sectionId={section.id}
            sort={section.sort}
            onError={this.handleUpdateSectionError}
            onSuccessResult={this.handleUpdateSectionSuccess}
          />
        )}
        {section.deleteRequest && (
          <DeleteContentSectionRequest
            sectionContainerId={section.id}
            onError={this.handleDeleteSectionCompleted}
            onSuccessResult={this.handleDeleteSectionCompleted}
          />
        )}
        {section2.updateRequest && (
          <UpdateContentSectionRequest
            contentLayoutId={section2.layoutId}
            sectionId={section2.id}
            onError={this.handleUpdateSectionError}
            onSuccessResult={this.handleUpdateSectionSuccess}
          />
        )}
        {container.deleteRequest && (
          <DeleteContentContainerRequest
            containerId={container.containerId}
            contentLayoutId={container.layoutId}
            sectionId={container.sectionId}
            onError={this.handleDeleteContainerCompleted}
            onSuccessResult={this.handleDeleteContainerCompleted}
          />
        )}
        {container.sortRequest && (
          <SortContainerRequest
            containerId={container.containerId}
            sectionId={container.sectionId}
            sort={container.sort}
            onError={this.handleSortContainerError}
            onSuccessResult={this.handleSortContainerSuccess}
          />
        )}
        {snippet.deleteRequest && (
          <DeleteContentSnippetRequest
            contentSnippetId={snippet.id}
            onError={this.handleDeleteSnippetCompleted}
            onSuccessResult={this.handleDeleteSnippetCompleted}
          />
        )}
        {snippet.updateRequest && (
          <UpdateContentSnippetBaseRequest
            containerId={snippet.containerId}
            snippetId={snippet.id}
            sort={snippet.sort}
            visible
            onError={this.handleUpdateSnippetCompleted}
            onSuccessResult={this.handleUpdateSnippetCompleted}
          />
        )}
      </PageWrapper>
    );
  }
}

ContentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired,
      orgId: PropTypes.string.isRequired,
      pageType: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

// This is a temporary state provider because the ContentPage is a class based component
// This solution is placed here so it is obvious that it is only temporary
const FunctionalContentPageWrapper = (props) => {
  const [pageState, dispatch] = useContentPageState();
  const params = useParams();
  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const [breadCrumbPath, setBreadcrumbPath] = useState([]);
  const {
    location: { pathname },
  } = props;

  const { projectId, orgId, type } = params;

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/content`,
        title: 'Content group library',
        isExternal: true,
      },
      {
        path:
          type === 'form'
            ? `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library/forms`
            : `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/content/${type}`,
        title: capitalize(type),
        isExternal: true,
      },
      ...getBreadCrumbPath(pathname, 0, 'Content page'),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return <ContentPage dispatch={dispatch} pageState={pageState} {...props} breadCrumbPath={breadCrumbPath} />;
};

const FinalResult = (props) => (
  <ContentPageState>
    <FunctionalContentPageWrapper {...props} />
  </ContentPageState>
);

export default FinalResult;
