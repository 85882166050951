import { FRAGMENT_APP } from './App';
import { FRAGMENT_AUDIO } from './Audio';
import { FRAGMENT_BUTTON } from './Button';
import { FRAGMENT_CODE } from './Code';
import { FRAGMENT_CONTENT_PAGE } from './ContentPage';
import { FRAGMENT_DOCUMENT } from './Document';
import { FRAGMENT_EMBED } from './Embed';
import { FRAGMENT_FORM } from './Form';
import { FRAGMENT_IMAGE } from './Image';
import { FRAGMENT_MAP } from './Map';
import { FRAGMENT_QUESTION } from './Question';
import { FRAGMENT_QUOTE } from './Quote';
import { FRAGMENT_REGIONS } from './Regions';
import { FRAGMENT_SLIDER } from './Slider';
import { FRAGMENT_SPACER } from './Spacer';
import { FRAGMENT_TEXT } from './Text';
import { FRAGMENT_VIDEO } from './Video';

export const AppFragment = FRAGMENT_APP;
export const AudioFragment = FRAGMENT_AUDIO;
export const ButtonFragment = FRAGMENT_BUTTON;
export const CodeFragment = FRAGMENT_CODE;
export const ContentSnippetSliderFragment = FRAGMENT_SLIDER;
export const DocumentFragment = FRAGMENT_DOCUMENT;
export const EmbedFragment = FRAGMENT_EMBED;
export const FormFragment = FRAGMENT_FORM;
export const ImageFragment = FRAGMENT_IMAGE;
export const MapFragment = FRAGMENT_MAP;
export const QuestionFragment = FRAGMENT_QUESTION;
export const QuoteFragment = FRAGMENT_QUOTE;
export const RegionsFragment = FRAGMENT_REGIONS;
export const SpacerFragment = FRAGMENT_SPACER;
export const TextFragment = FRAGMENT_TEXT;
export const VideoFragment = FRAGMENT_VIDEO;
export const ContentPage = FRAGMENT_CONTENT_PAGE;
