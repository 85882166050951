import React from 'react';

import { Icon, IconIdType } from '@netfront/ui-library';
import cx from 'classnames';

import { EventTriggerIconProps, IIconTypes } from './EventTriggerIcon.types';

const iconTypeMap: IIconTypes = {
  ARROW: ['caret_down', 'caret_up'],
  CIRCLE_ARROW: ['caret_down', 'caret_up'],
  CIRCLE_PLUS: ['plus', 'minus'],
  PLUS: ['plus', 'minus'],
};

const EventTriggerIcon = ({ iconType, isExpanded, triggerBackgroundColor }: EventTriggerIconProps) => {
  const isCircle = ['CIRCLE_PLUS', 'CIRCLE_ARROW'].includes(iconType);
  const color = isCircle ? triggerBackgroundColor : undefined;

  const selectedIconType = iconTypeMap[iconType || 'ARROW'];
  const icon: string = selectedIconType[!isExpanded ? 0 : 1];

  return (
    <span
      className={cx('c-event-trigger__icon-container', {
        'c-event-trigger__icon-container--circle': isCircle,
      })}
      style={{ color }}
    >
      <Icon className="c-event-trigger__icon" id={`id_${icon}_icon` as IconIdType} />
    </span>
  );
};

export { EventTriggerIcon };
