import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import QueryRequest from '../QueryRequest';

export const GET_ORGANISATION = gql`
  query GetOrganisation($id: Int!) {
    organisation {
      getOrganisation(organisationId: $id) {
        id
        name
      }
    }
  }
`;

const getOrganisation = ({ id, onError, onSuccessResult }) => (
  <Query client={Client()} query={GET_ORGANISATION} variables={{ id }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.organisation.getOrganisation}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getOrganisation.propTypes = {
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getOrganisation;
