import gql from 'graphql-tag';

import { NOTE_ATTACHMENT_FRAGMENT, NOTE_FRAGMENT } from '../fragments/notes';

export const GET_NOTES_BY_GROUP = gql`
  query GetNotesByGroup($entityId: Int!) {
    note {
      getByGroup(groupId: $entityId) {
        ...note
      }
    }
  }
  ${NOTE_ATTACHMENT_FRAGMENT}
  ${NOTE_FRAGMENT}
`;

export const GET_NOTES_BY_USER = gql`
  query GetNotesByUser($entityId: Int!) {
    note {
      getByUser(userId: $entityId) {
        ...note
      }
    }
  }
  ${NOTE_ATTACHMENT_FRAGMENT}
  ${NOTE_FRAGMENT}
`;
