import React from 'react';

import MenuCard from './MenuCard';

export const LoadingMenuCard = ({ count = 7 }) => {
  const menuItems = [];

  for (let i = 0; i < count; i += 1) {
    menuItems.push(<MenuCard key={i} isLoading />);
  }
  return <>{menuItems}</>;
};
