import { addDays, addHours, addMinutes, addSeconds } from 'date-fns';

import { CookieExpiryTimeUnitCalculationFunctionMapType } from './useCookie.types';

const COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP = Object.freeze({
  expires: Object.freeze({
    attributeName: 'Expires',
    interfaceKey: 'expires',
  }),
  domain: Object.freeze({
    attributeName: 'Domain',
    interfaceKey: 'domain',
  }),
  httpOnly: Object.freeze({
    attributeName: 'HttpOnly',
    interfaceKey: 'httpOnly',
  }),
  maxAge: Object.freeze({
    attributeName: 'Max-Age',
    interfaceKey: 'maxAge',
  }),
  path: Object.freeze({
    attributeName: 'Path',
    interfaceKey: 'path',
  }),
  sameSite: Object.freeze({
    attributeName: 'SameSite',
    interfaceKey: 'sameSite',
  }),
  secure: Object.freeze({
    attributeName: 'Secure',
    interfaceKey: 'secure',
  }),
});

const COOKIE_EXPIRED_DATE = 'Thu, 01-Jan-1970 00:00:00 GMT';

const COOKIE_MAX_SIZE = Math.pow(2, 12);

const COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP: CookieExpiryTimeUnitCalculationFunctionMapType = Object.freeze({
  days: addDays,
  hours: addHours,
  minutes: addMinutes,
  seconds: addSeconds,
});

export { COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP, COOKIE_EXPIRED_DATE, COOKIE_MAX_SIZE, COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP };
