import React, { useState } from 'react';

import { Login } from '@netfront/gelada-react-shared';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import InputHolder from '../../components/InputHolder/InputHolder';

function validate({ username, password }) {
  return ValidationProcessor({
    UsernameEmpty: {
      validation: username.trim().length > 0,
      errorMessage: 'Username must not be empty',
    },
    PasswordEmpty: {
      validation: password.trim().length > 0,
      errorMessage: 'Password must not be empty',
    },
  });
}

class NewLogin extends React.PureComponent {
  // Using PureComponent to only render the Login component once
  // Idealy Login should be a hook to use without a render
  render() {
    return (
      // disabling as the Login component needs to be a hook
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Login {...this.props} />
    );
  }
}

export function LoginForm({ setError, projectId, onComplete }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);

  function handleComplete(...data) {
    setSubmitting(false);
    onComplete(...data);
  }

  function handleSubmit(e) {
    if (submitting) return;

    const validationErrors = validate({ username, password });

    e.preventDefault();

    if (!validationErrors.modelValid) {
      setError(validationErrors.validations);
    } else {
      const cookie = new Cookies();
      cookie.remove('_a');
      setError('');
      setSubmitting(true);
    }
  }

  return (
    <form className="login-page__form" onSubmit={handleSubmit}>
      <InputHolder
        label="Username"
        name="username"
        onChange={({ target: { value } }) => setUsername(value)}
        required
        value={username}
      />
      <InputHolder
        label="Password"
        name="password"
        onChange={({ target: { value } }) => setPassword(value)}
        required
        type="password"
        value={password}
      />

      <button
        className="button--outline"
        disabled={submitting}
        style={{
          margin: '1em 0 1em auto',
        }}
        type="submit"
      >
        LOGIN
      </button>

      <Link
        style={{
          cursor: 'pointer',
          display: 'flex',
          marginLeft: 'auto',
        }}
        to="/forgot-password"
      >
        Forgot password?
      </Link>
      {submitting && (
        <NewLogin
          customBuildGuid={projectId}
          login={username}
          onComplete={handleComplete}
          password={password}
        />
      )}
    </form>
  );
}

LoginForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
};
