/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';

const UnknownComponent = ({ type }: { type: any }) => <div>The component {type} has not been created yet.</div>;
const EmptyWrapper = ({ children }: { children: ReactNode }) => <React.Fragment>{children}</React.Fragment>;

// TODO: Add a type to the 'type' parameter below to restrict it's values
const makeComponentMap =
  (components: any) =>
  (type = 'NONE') => {
    const componentMap = Object.freeze({
      DEFAULT: UnknownComponent,
      NONE: EmptyWrapper,
      ...components,
    });
    const component = componentMap[type];
    if (!component) {
      // eslint-disable-next-line no-console
      console.log({ type });
    }
    return component || componentMap.DEFAULT;
  };

export { makeComponentMap };
