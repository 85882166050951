import React from 'react';

import PropTypes from 'prop-types';

function ConsoleContainer(props) {
  const { children, styles, classNames } = props;

  return (
    <div className={classNames || 'c-console-container'} style={{ ...styles }}>
      {children}
    </div>
  );
}

ConsoleContainer.propTypes = {
  /* eslint react/forbid-prop-types: 0 */
  children: PropTypes.any,
  classNames: PropTypes.string,
  styles: PropTypes.shape({}),
};

ConsoleContainer.defaultProps = {
  children: undefined,
  classNames: '',
  styles: null,
};

export default ConsoleContainer;
