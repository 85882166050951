/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
export class Logger {
  private canDebug = Boolean(process.env.REACT_APP_DEBUG);

  err(obj: any) {
    if (!this.canDebug) {
      return;
    }

    console.error(obj);
  }

  log(obj: any) {
    if (!this.canDebug) {
      return;
    }

    console.log(obj);
  }

  warn(obj: any) {
    if (!this.canDebug) {
      return;
    }

    console.warn(obj);
  }
}
