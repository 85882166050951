import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { APPLY_CONDITION_TEMPLATE } from './UseApplyConditionTemplate.graphql';

const useApplyConditionTemplate = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeApplyConditionTemplate, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? APPLY_CONDITION_TEMPLATE,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { applyTemplate: questionCondition },
        } = data;

        onCompleted({
          questionCondition,
        });
      },
      onError,
    },
    token,
  });

  const handleApplyConditionTemplate = ({ variables }) => {
    executeApplyConditionTemplate({
      variables,
    });
  };

  return {
    handleApplyConditionTemplate,
    isLoading,
  };
};

export { useApplyConditionTemplate };
