import React from 'react';

import cx from 'classnames';

import { ButtonProps } from './Button.types';

import { Icon } from '../../Icon';
import { Skeleton } from '../../Skeleton';

import './Button.css';

const Button = ({
  additionalClassNames,
  backgroundColor,
  borderColor,
  iconId,
  isDisabled = false,
  isIconOnly,
  isLoading,
  linkButton,
  onClick,
  size,
  text,
  type = 'button',
  variant = 'primary',
  isPageLoading = false,
}: ButtonProps) => {
  const ButtonComponent = linkButton ? linkButton.linkComponent ?? 'a' : 'button';

  const buttonClassNames = cx('c-button', additionalClassNames, {
    [`c-button--${variant}`]: Boolean(variant),
    [`c-button--${String(size)}`]: Boolean(size),
    'c-button--icon-only': Boolean(isIconOnly),
    'c-button--loading': Boolean(isLoading),
  });

  if (isPageLoading) return <Skeleton height="2rem" width="6rem" />;

  const ButtonSpinner = () => {
    return (
      <div className="c-button__spinner">
        <div className={cx(['c-spinner__icon'])} aria-hidden>
          <p className="h-hide-visually">Loading</p>
        </div>
      </div>
    );
  };

  return (
    <ButtonComponent
      className={buttonClassNames}
      data-testid="qa-button"
      disabled={isDisabled}
      href={linkButton?.url}
      id={linkButton?.id}
      rel={linkButton?.url.indexOf('http') === 0 ? 'noopener noreferrer' : undefined}
      style={{
        backgroundColor /* stylelint-disable-line value-keyword-case */,
        borderColor /* stylelint-disable-line value-keyword-case */,
      }}
      target={linkButton?.target}
      type={type}
      onClick={onClick}
    >
      {isIconOnly ? (
        <>
          <span className="c-button__text h-hide-visually">{text}</span>
          {iconId ? <Icon className="c-button__icon" id={iconId} /> : null}
        </>
      ) : (
        <span className="c-button__text">
          {text} {iconId ? <Icon className="c-button__icon" id={iconId} /> : null}
        </span>
      )}

      {isLoading && <ButtonSpinner />}
    </ButtonComponent>
  );
};

export { Button };
