import React from 'react';

import { CheckboxGroup, Label, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

const ConditionCheckboxQuestion = ({ questionResponses, selectedResponse, onInputChange }) => {
  const handleCheckboxGroupChange = (values) => {
    onInputChange({
      name: 'questionResponseCheckboxId',
      value: Number(values[0]),
    });
  };

  return (
    <>
      <Label additionalClassNames="c-action__item__label h-flex" forId="question response" labelText="Question response" isRequired>
        <Tooltip placement="left" text="This action will execute if the checkbox selection is as selected below" />
      </Label>

      <CheckboxGroup
        items={questionResponses}
        name="questionResponseCheckboxId"
        values={selectedResponse}
        onChange={handleCheckboxGroupChange}
      />
    </>
  );
};

ConditionCheckboxQuestion.propTypes = {
  onInputChange: PropTypes.func,
  questionResponses: PropTypes.arrayOf(PropTypes.shape()),
  selectedResponse: PropTypes.arrayOf(PropTypes.number),
};

export { ConditionCheckboxQuestion };
