import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  animation: fadein 0.5s;
  height: 11em;
  width: 11em;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-color: #e4e4e338;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 1em 1em 0;
  cursor: pointer;
  transition: 0.25s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.04);
  }
  @media only screen and (max-width: 540px) {
    height: 10em;
    width: 8.5em;
    margin: 0 0.2em;
  }
`;

const Name = styled.p`
  margin: 0;
  padding: 0.5em 0;
  font-weight: 600;
  @media only screen and (max-width: 540px) {
    padding: 0.2em;
    font-size: 0.8em;
  }
`;

const Icon = styled.span`
  background-color: #4bc1c9;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const P = styled.p`
  bottom: 0.15rem;
  color: #fff;
  font-size: 2.5rem;
  position: relative;
`;

const AddNewCard = (props) => {
  const { onClick } = props;
  return (
    <Wrapper onClick={onClick}>
      <Icon>
        <P>+</P>
      </Icon>
      <Name>Add new</Name>
    </Wrapper>
  );
};

AddNewCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddNewCard;
