import React, { useState } from 'react';

import { Dialog } from '@netfront/ui-library';
import cx from 'classnames';

import { EventTrigger } from '../EventTrigger';

import { PopUpProps } from './PopUp.types';

const animationMap = {
  BOTTOM: 'bounceInUp',
  LEFT: 'rotateInDownLeft',
  RIGHT: 'rotateInDownRight',
  TOP: 'bounceInDown',
};

const PopUp = ({ asset, backgroundColor, borderColor, children, eventDirection, eventTitle, iconUrl, style, titleColor }: PopUpProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="ek-popup">
      <EventTrigger
        asset={asset}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        color={titleColor}
        handleClick={() => setIsOpen(!isOpen)}
        iconType="PLUS"
        iconUrl={iconUrl}
        triggerText={eventTitle}
        triggerType={style}
      />

      <Dialog
        additionalClassNames={cx('animate__animated', `animate__${animationMap[eventDirection]}`)}
        isOpen={isOpen}
        title={eventTitle}
        onClose={() => setIsOpen(false)}
      >
        {children}
      </Dialog>
    </div>
  );
};

export { PopUp };
