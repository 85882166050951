import React from 'react';

import { Avatar } from '@netfront/ui-library';
import cx from 'classnames';

import './LogoAvatar.css';

const LogoAvatar = ({ additionalClassNames, logoUrl, title, avatarImageSrc, size = 'medium', hasRoundedBorder = false }) => {
  const avatarImage = avatarImageSrc ? <img alt={title} data-testid="qa-avatar-image" src={avatarImageSrc} /> : null;

  const classNames = cx('c-logo-avatar', `c-logo-avatar--${size}`, additionalClassNames, { 'c-logo-avatar--rounded': hasRoundedBorder });

  return (
    <div className={classNames}>
      {logoUrl ? (
        <div className="c-logo-avatar__logo-container">
          <div className="c-logo-avatar__logo" style={{ backgroundImage: `url('${logoUrl}')` }}>
            <span className="h-hide-visually">{title} logo</span>
          </div>
        </div>
      ) : (
        <Avatar image={avatarImage} size={size} title={title} />
      )}
    </div>
  );
};

export default LogoAvatar;
