const getFormsTableData = (formsList, onSettingsButtonClick, orgId, projectId) =>
  formsList.map(({ description, id, title, status }) => ({
    description,
    settingsButtonData: {
      id,
      orgId,
      projectId,
      title,
      onClick: onSettingsButtonClick,
    },
    status: status === 'PUBLISHED' ? 'Active' : 'Inactive',
    title,
  }));

export { getFormsTableData };
