/* eslint-disable no-underscore-dangle */
import React, { Fragment } from 'react';

import { IContentPage } from 'interfaces';

import { QuestionSnippet } from '../QuestionSnippet';

import { SummaryFeedbackConfigurationMultiResponse } from './SummaryFeedbackConfigurationMultiResponse';
import { SummaryFeedbackConfigurationMultiResponseText } from './SummaryFeedbackConfigurationMultiResponseText';
import { SummaryFeedbackConfigurationRadio } from './SummaryFeedbackConfigurationRadio';
import { SummaryFeedbackConfigurationRankAndNotes } from './SummaryFeedbackConfigurationRankAndNote';
import { SummarySnippetProps } from './SummarySnippet.interfaces';

import { makeComponentMap } from '../../../components/makeComponentMap/makeComponentMap';
import { getContentSnippet, getContentSnippetFormId, useContentPageContext } from '../../../contexts';

const getComponentByType = makeComponentMap({
  SummaryFeedbackConfigurationMultiResponseTextType: SummaryFeedbackConfigurationMultiResponseText,
  SummaryFeedbackConfigurationRankAndNoteType: SummaryFeedbackConfigurationRankAndNotes,
  SummaryFeedbackConfigurationRangeType: QuestionSnippet,
  SummaryFeedbackConfigurationMultiResponseType: SummaryFeedbackConfigurationMultiResponse,
  SummaryFeedbackConfigurationRadioType: SummaryFeedbackConfigurationRadio,
});

const SummarySnippet = ({
  accessToken,
  contentSnippetId,
  summaryFeedbackConfiguration,
  summaryFeedbackMode,
  userFlowStepTrackId,
}: SummarySnippetProps) => {
  const { state: contentPage } = useContentPageContext();

  const { customConfigurationOptions } = contentPage as IContentPage;
  const { placeholders } = customConfigurationOptions ?? {};

  const contentSnippet = getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { contentSnippetQuestion, id: contentSnippetSummaryId } = contentSnippet ?? {};
  const { answer, configuration } = contentSnippetQuestion ?? {};
  const { answers = [], id: questionAnswerId, summaryFeedback, selectedAnswers, responses } = answer ?? {};
  const { responseSet, __typename } = configuration ?? {};
  const { availableResponses = [] } = responseSet ?? {};

  const Component = summaryFeedbackConfiguration ? getComponentByType(summaryFeedbackConfiguration.__typename) : QuestionSnippet;

  return (
    <Fragment>
      {contentPage && contentSnippetQuestion && (
        <div className="c-summary-snippet">
          <Component
            accessToken={accessToken}
            answers={answers}
            availableResponses={availableResponses}
            contentPageId={contentPage.id}
            contentSnippetFormId={contentSnippetFormId}
            contentSnippetQuestion={contentSnippetQuestion}
            contentSnippetQuestionId={contentSnippetQuestion.id}
            contentSnippetSummaryId={contentSnippetSummaryId}
            placeholders={placeholders}
            questionAnswerId={questionAnswerId}
            responses={responses}
            selectedAnswers={selectedAnswers}
            summaryFeedback={summaryFeedback}
            summaryFeedbackConfiguration={summaryFeedbackConfiguration}
            summaryFeedbackMode={summaryFeedbackMode}
            type={__typename}
            userFlowStepTrackId={userFlowStepTrackId}
          />
        </div>
      )}
    </Fragment>
  );
};

export { SummarySnippet };
