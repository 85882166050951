import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_SIGNED_URL_FOR_REPLACEMENT = gql`
  query getAssetSignedUrlForReplacement($assetId: String!) {
    asset {
      getAssetSignedUrlForReplacement(request: { assetId: $assetId }) {
        signedUrl
        asset {
          assetId
          contentType
          fileName
          projectId
          presignedUrl
          s3Key
        }
      }
    }
  }
`;

const getAssetSignedUrlForReplacementRequest = ({
  assetId,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={GET_SIGNED_URL_FOR_REPLACEMENT}
    variables={{ assetId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.asset.getAssetSignedUrlForReplacement
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAssetSignedUrlForReplacementRequest.propTypes = {
  assetId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAssetSignedUrlForReplacementRequest;
