import React, { Component } from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import CsvDownload from 'react-json-to-csv';
import Select from 'react-select';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import ConsoleMaster from '../../components/ConsoleMaster/ConsoleMaster';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import AppContext from '../../context/AppContext';
import GetOrganisation from '../../middleware/Organisation/getOrganisation';
import GetProject from '../../middleware/Project/getProject';
import ExecuteReport from '../../middleware/Report/executeReportRequest';
import GetEnumValues from '../../middleware/Report/getEnumValues';
import GetQueryableFields from '../../middleware/Report/getQueryableFields';
import GetReport from '../../middleware/Report/getReport';
import GetReportTypesAvailable from '../../middleware/Report/getReportTypesAvailable';
import SaveReportRequest from '../../middleware/Report/saveReportRequest';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class ReportBuilderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationId: null,
      projectId: null,
      query: null,
      report: null,
      reportTitle: null,
      selectedReportType: null,
      reportArguments: [],
      newArgument: {
        value: '',
        type: '',
      },
      enumValuesLoaded: false,
      enumValues: null,
      mutation: null,
      reportTypes: null,
      queryableFields: null,
      projectLoaded: true,
      reportExecuted: false,
      reportTypesLoaded: false,
      getQueryableFields: false,
      ReportLoaded: true,
      reportData: null,
      saveReport: false,
      orgLoaded: true,
      downloadReady: false,
      queryableOptionsSelected: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { org: orgId, projectId, reportId } = params;
    const { orgName, projectName } = this.context;
    let loaded = false;
    if (reportId === undefined) loaded = true;
    this.setState({
      organisationId: Number(orgId),
      projectId,
      reportId: Number(reportId),
      orgLoaded: orgName !== '',
      projectLoaded: projectName !== '',
      ReportLoaded: loaded,
    });
  }

  handleOrgError = () => {
    this.setState({
      orgLoaded: true,
    });
  };

  handleProjectError = () => {
    this.setState({
      projectLoaded: true,
    });
  };

  handleRemoveArgument = (arg) => {
    const { reportArguments } = this.state;
    const args = reportArguments.filter((value) => value !== arg);
    this.setState({
      reportArguments: args,
    });
  };

  handleAddArgument = () => {
    const { newArgument, reportArguments } = this.state;
    if (newArgument.value === '' || newArgument.type === '') return;

    const arg = {};
    arg.value = newArgument.value;
    arg.type = newArgument.type;

    reportArguments.push(arg);
    this.setState({
      reportArguments,
    });
  };

  handleSaveReport = () => {
    this.setState({
      saveReport: true,
    });
  };

  handleExecuteReport = () => {
    const { reportTypes, report } = this.state;
    let fragment = reportTypes.filter((c) => c.label === report.pivot)[0].value;
    fragment = `... on ${fragment}{ {{0}} }`;
    const fields = [];
    report.queriedFields.forEach((c) => fields.push(c.value));
    const fieldsString = fields.join(' ');
    fragment = fragment.replace('{{0}}', fieldsString);
    let mutation =
      'mutation executeReport($reportId: Int!)      {       report{         executeReport(reportId:$reportId) {             {{0}}        }       }    }';
    mutation = mutation.replace('{{0}}', fragment);
    const toto = gql(mutation);

    this.setState({
      mutation: toto,
      reportExecuted: true,
    });
  };

  handleSavedReport = () => {
    this.setState({
      saveReport: false,
    });
  };

  handleReportError = () => {
    this.setState({
      ReportLoaded: true,
    });
  };

  handleGetReportTypes = (data) => {
    const items = [];
    data.map((item) => {
      const type = {};
      type.value = item.description;
      type.label = item.name;
      items.push(type);
      return items;
    });
    this.setState({
      reportTypes: items,
      reportTypesLoaded: true,
    });
  };

  handleGetQueryableFields = (data) => {
    const items = [];
    data.map((item) => {
      const type = {};
      type.value = item.name;
      type.label = item.name;
      type.type = item.type.ofType === null ? item.type.name : item.type.ofType.name;
      items.push(type);
      return items;
    });
    this.setState({
      getQueryableFields: false,
      queryableFields: items,
    });
  };

  handleGetReport = (data) => {
    this.setState({
      report: data,
      ReportLoaded: true,
    });
  };

  handleGetOrganisation = (name) => {
    const { updateOrgName } = this.context;
    updateOrgName(name);
    this.setState({
      orgLoaded: true,
    });
  };

  handleReportExecuted = (data) => {
    this.setState({
      reportExecuted: false,
      reportData: data,
      downloadReady: true,
    });
  };

  handleGetProject = (name) => {
    const { updateProjectName } = this.context;
    updateProjectName(name);
    this.setState({
      projectLoaded: true,
    });
  };

  handleGetEnum = (data) => {
    const items = [];
    data.map((item) => {
      const type = {};
      type.value = item.name;
      type.label = item.name;
      items.push(type);
      return items;
    });
    this.setState({
      enumValuesLoaded: true,
      enumValues: items,
    });
  };

  onOptionChange = (e) => {
    this.setState({
      selectedReportType: e,
      getQueryableFields: true,
    });
  };

  handleTitleChange = (e) => {
    const { value } = e.target;
    this.setState({
      reportTitle: value,
    });
  };

  handleQueryChange = (e) => {
    const { value } = e.target;
    this.setState({
      query: value,
    });
  };

  handleArgumentName = (e) => {
    const { newArgument } = this.state;
    const { value } = e.target;
    this.setState({
      newArgument: {
        value,
        type: newArgument.type,
      },
    });
  };

  onEnumValueChanged = (e) => {
    const { newArgument } = this.state;
    this.setState({
      newArgument: {
        type: e.value,
        value: newArgument.value,
      },
    });
  };

  onQueryableOptionChange = (e) => {
    const queriedFields = [];

    e.forEach((element) => {
      const queriedField = {};
      queriedField.value = element.value;
      queriedField.children = [];
      queriedFields.push(queriedField);
    });

    this.setState({
      queryableOptionsSelected: queriedFields,
    });
  };

  handleGroupAvatar = (title) => {
    const splitTitle = title.split(' ');
    return splitTitle.length > 1 ? `${splitTitle[0].split('')[0]}${splitTitle[1].split('')[0]}` : title.split('')[0];
  };

  render() {
    const {
      ReportLoaded,
      orgLoaded,
      projectLoaded,
      saveReport,
      // report,
      reportData,
      getQueryableFields,
      reportTypes,
      organisationId,
      projectId,
      reportId,
      reportTitle,
      enumValues,
      enumValuesLoaded,
      selectedReportType,
      reportArguments,
      downloadReady,
      reportExecuted,
      mutation,
      query,
      reportTypesLoaded,
      queryableFields,
      queryableOptionsSelected,
    } = this.state;

    const { projectName } = this.context;

    const { match } = this.props;

    return (
      <ConsoleMaster path={this.props}>
        <>
          <ProjectNavBar title="Report Builder" />
          <ConsoleContainer styles={{ marginTop: '2em' }}>
            {projectName !== '' && (
              <Breadcrumb
                avatar={projectName}
                breads={[
                  {
                    id: 1,
                    path: `/dashboard/${match.params.org}`,
                    title: 'Dashboard',
                  },
                  {
                    id: 2,
                    path: `/dashboard/${match.params.org}/${projectId}`,
                    title: projectName,
                  },
                  {
                    id: 3,
                    path: `/dashboard/${match.params.org}/${projectId}/reporting`,
                    title: 'Reporting',
                  },
                ]}
                title={projectName}
              />
            )}

            <input id="title" name="search" placeholder="Title" style={{ width: '100%' }} type="text" onChange={this.handleTitleChange} />
            <Select options={reportTypes} onChange={(e) => this.onOptionChange(e)} />
            {queryableFields !== null && queryableFields.length !== 0 && (
              <>
                <Select
                  isMulti="True"
                  options={queryableFields}
                  placeholder="Select the data to export"
                  onChange={(e) => this.onQueryableOptionChange(e)}
                />
                <input
                  id="search"
                  name="search"
                  placeholder="Enter your filter here (E.G id>@0 & firstname.Contains(@1)) - interface must be improved"
                  style={{ width: '100%' }}
                  type="text"
                  onChange={this.handleQueryChange}
                />

                <hr />
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Identifier</Th>
                      <Th>Value</Th>
                      <Th>Type</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reportArguments.map((arg, index) => (
                      <Tr>
                        <Td>@{index}</Td>
                        <Td>{arg.value}</Td>
                        <Td>{arg.type}</Td>
                        <Td>
                          {' '}
                          <button className="mt-4 button--blue" type="submit" onClick={() => this.handleRemoveArgument(arg)}>
                            X
                          </button>
                        </Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Td />
                      <Td>
                        <input
                          id="newArgument.value"
                          name="newArgument.value"
                          placeholder="Enter the value"
                          style={{ width: '100%' }}
                          type="text"
                          onChange={this.handleArgumentName}
                        />
                      </Td>
                      <Td>
                        <Select options={enumValues} placeholder="Select the type " onChange={(e) => this.onEnumValueChanged(e)} />
                      </Td>
                      <Td>
                        <button className="mt-4 button--blue" type="submit" onClick={this.handleAddArgument}>
                          Add
                        </button>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </>
            )}
            <button className="mt-4 button--blue" type="submit" onClick={this.handleExecuteReport}>
              Execute
            </button>

            <button className="mt-4 button--blue" type="submit" onClick={this.handleSaveReport}>
              Save
            </button>
          </ConsoleContainer>
        </>

        {getQueryableFields && (
          <GetQueryableFields
            reportType={selectedReportType.value}
            onError={this.handleReportError}
            onSuccessResult={this.handleGetQueryableFields}
          />
        )}

        {saveReport && (
          <SaveReportRequest
            includes=""
            order=""
            pivot={selectedReportType.label}
            projectId={projectId}
            queriedFields={queryableOptionsSelected}
            query={query}
            reportArgs={reportArguments}
            select=""
            title={reportTitle}
            onCreateError={this.handleSavedReport}
            onSuccessResult={this.handleSavedReport}
          />
        )}

        {!enumValuesLoaded && (
          <GetEnumValues enumValue="EValueType" onError={this.handleReportError} onSuccessResult={this.handleGetEnum} />
        )}
        {!ReportLoaded && (
          <GetReport projectId={projectId} reportId={reportId} onError={this.handleReportError} onSuccessResult={this.handleGetReport} />
        )}
        {!reportTypesLoaded && <GetReportTypesAvailable onError={this.handleReportError} onSuccessResult={this.handleGetReportTypes} />}
        {!orgLoaded && <GetOrganisation id={organisationId} onError={this.handleOrgError} onSuccessResult={this.handleGetOrganisation} />}
        {!projectLoaded && <GetProject id={projectId} onError={this.handleProjectError} onSuccessResult={this.handleGetProject} />}
        {downloadReady && <CsvDownload data={reportData} />}
        {reportExecuted && (
          <ExecuteReport
            mutation={mutation}
            reportId={reportId}
            onError={this.handleProjectError}
            onSuccessResult={this.handleReportExecuted}
          />
        )}
      </ConsoleMaster>
    );
  }
}
ReportBuilderPage.contextType = AppContext;

ReportBuilderPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      org: PropTypes.string,
      projectId: PropTypes.string,
      reportId: PropTypes.number,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default ReportBuilderPage;
