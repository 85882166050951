import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SNIPPET_LOCATION } from './UseConditionLocations.graphql';

const useConditionSnippetLocations = (options) => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? {};

  const [executeGetSnippetLocations, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getSnippetForms: conditionItemLocations },
        } = data;

        onCompleted(conditionItemLocations);
      },
      onError,
    },
    query: query ?? GET_SNIPPET_LOCATION,
    token,
  });

  const handleGetSnippetLocations = ({ contentGroupId }) => {
    executeGetSnippetLocations({
      variables: {
        contentGroupId,
      },
    });
  };

  return {
    handleGetSnippetLocations,
    isLoading,
  };
};

export { useConditionSnippetLocations };
