import gql from 'graphql-tag';

export const GET_CSS_STYLE_PER_PROJECT = gql`
  query($projectId: String!) {
    cssStyle {
      getPerProject(projectId: $projectId) {
        id
        name
      }
    }
  }
`;
