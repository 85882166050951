import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_ASSET = gql`
  mutation ($assetId: String!, $alt: String, $fileName: String, $tags: String, $isAnimatedImage: Boolean) {
    asset {
      updateAsset(asset: { assetId: $assetId, alt: $alt, fileName: $fileName, tags: $tags, isAnimatedImage: $isAnimatedImage }) {
        presignedUrl
        s3Key
        contentType
        assetId
        alt
        fileName
        tags
        transcript
        alt
        xAxisFocus
        uploaded
        isPublic
        publicS3Key
        fileName
        publicUrl
        isAnimatedImage
      }
    }
  }
`;

const updateAssetRequest = ({ assetId, fileName, tags, alt, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_ASSET}>
    {(updateAsset, { loading, error, data }) => {
      const variables = {
        assetId,
        fileName,
        tags,
        alt,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.asset.updateAsset);
      }

      return <MutationRequest isLoading={loading} mutation={updateAsset} variables={variables} />;
    }}
  </Mutation>
);

updateAssetRequest.propTypes = {
  assetId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  alt: PropTypes.string,
  tags: PropTypes.string,
};

updateAssetRequest.defaultProps = {
  alt: null,
  tags: null,
};

export default updateAssetRequest;
