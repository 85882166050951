import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ORG_MEMBERSHIP_LIST = gql`
  query getOrgMembership($organisationId: Int!) {
    membership {
      getOrganisationMembers(organisationId: $organisationId) {
        id
        permission: permision
        userId
        user {
          id
          firstname
          lastname
          status
          credential {
            email
          }
        }
      }
    }
  }
`;

const getAllOrgUsers = ({ organisationId, onError, onSuccessResult }) => (
  <Query
    client={Client()}
    query={GET_ORG_MEMBERSHIP_LIST}
    variables={{ organisationId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.membership.getOrganisationMembers
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAllOrgUsers.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  organisationId: PropTypes.number.isRequired,
};

export default getAllOrgUsers;
