import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';
import {
  CalendarFragment,
  CheckboxFragment,
  DropDownFragment,
  MatchFragment,
  MultilineFragment,
  MultiResponseFragment,
  NumberFragment,
  RadioFragment,
  SingleLineFragment,
  SliderFragment,
} from '../QuestionConfiguration/Fragments/Collection';

export const CREATE_QUESTION_CONTENT_SNIPPET = gql`
  mutation createQuestionContentSnippet(
    $containerId: Int!
    $majorNumber: String!
    $mandatory: Boolean!
    $minorNumber: String!
    $question: String!
    $questionConfigurationId: Int!
    $visible: Boolean!
    $identifier: String
    $title: String!
  ) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible, entityTitle: $title }
        contentSnippetQuestion: {
          majorNumber: $majorNumber
          mandatory: $mandatory
          minorNumber: $minorNumber
          question: $question
          questionConfigurationId: $questionConfigurationId
          identifier: $identifier
        }
      ) {
        id
        sort
        ... on ContentSnippetQuestionType {
          configuration {
            ...calendarConf
            ...checkboxConf
            ...dropDownListConf
            ...matchConf
            ...multiTextConf
            ...multiResponseConf
            ...numberConf
            ...radioConf
            ...singleTextConf
            ...sliderConf
            __typename
          }
          question
          identifier
        }
      }
    }
  }
  ${CalendarFragment}
  ${CheckboxFragment}
  ${DropDownFragment}
  ${MatchFragment}
  ${MultilineFragment}
  ${NumberFragment}
  ${RadioFragment}
  ${SingleLineFragment}
  ${SliderFragment}
  ${MultiResponseFragment}
`;

const createQuestionContentSnippetRequest = ({
  containerId,
  isMandatory,
  isVisible,
  majorNumber,
  minorNumber,
  onError,
  onSuccessResult,
  question,
  questionConfigurationId,
  questionIdentifier,
}) => (
  <Mutation mutation={CREATE_QUESTION_CONTENT_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        majorNumber,
        mandatory: isMandatory,
        minorNumber,
        question,
        questionConfigurationId,
        visible: isVisible,
        identifier: questionIdentifier,
        title: '',
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.contentSnippet.createContentSnippet.id,
          data.contentSnippet.createContentSnippet.sort,
          // eslint-disable-next-line no-underscore-dangle
          data.contentSnippet.createContentSnippet.configuration.__typename,
          containerId,
          {
            question,
            isMandatory,
            questionConfigurationId,
            majorNumber,
            minorNumber,
            identifier: questionIdentifier,
            ...data.contentSnippet.createContentSnippet,
          },
        );
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createQuestionContentSnippetRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  questionConfigurationId: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
  majorNumber: PropTypes.string,
  minorNumber: PropTypes.string,
  questionIdentifier: PropTypes.string,
};

createQuestionContentSnippetRequest.defaultProps = {
  isVisible: true,
  majorNumber: '',
  minorNumber: '',
};

export default createQuestionContentSnippetRequest;
