import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_GROUP_INVITATION_REQUEST = gql`
  mutation createInvitationRequest(
    $groupId: Int!
    # $permission: EMemberPermission!
    $emailInvited: String!
    $firstName: String
    $lastName: String
    $customMessage: String
    $userTypeId: Int
    $services: [ENetfrontService]
  ) {
    invitation {
      groupInvitation(
        invitation: {
          emailInvited: $emailInvited
          groupId: $groupId
          permission: READ
          firstName: $firstName
          lastName: $lastName
          customMessage: $customMessage
          userTypeId: $userTypeId
          services: $services
        }
      ) {
        id
        firstName
        lastName
        invitedUserEmail: emailUserInvited
        tokenId
      }
    }
  }
`;

const createPublicUserInvitation = ({
  emailInvited,
  groupId,
  //   permission,
  firstName,
  lastName,
  customMessage,
  userTypeId,
  services,
  onError,
  onSuccessResult,
}) => (
  <Mutation client={Client()} mutation={CREATE_GROUP_INVITATION_REQUEST}>
    {(createInvitationRequest, { loading, error, data }) => {
      const variables = {
        emailInvited,
        groupId,
        // permission,
        firstName,
        services,
        lastName,
        customMessage,
        userTypeId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.invitation.groupInvitation);
      }

      return <MutationRequest isLoading={loading} mutation={createInvitationRequest} variables={variables} />;
    }}
  </Mutation>
);

createPublicUserInvitation.propTypes = {
  emailInvited: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  services: PropTypes.array,
  lastName: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  //   permission: PropTypes.string.isRequired,
  userTypeId: PropTypes.number.isRequired,
  customMessage: PropTypes.string,
};

createPublicUserInvitation.defaultProps = {
  customMessage: '',
  services: [],
};

export default createPublicUserInvitation;
