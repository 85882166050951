export const getEnumValueDropdown = enums => {
  return enums.map(value => {
    const { name } = value;
    return {
      id: name,
      value: name,
      label: name,
    };
  });
};
