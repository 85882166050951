import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import SwitchBtn from '../UI/Button/SwitchButton';
import Dropdown from '../UI/Dropdown/Dropdown';
import Input from '../UI/Input/InputWhite';

import AdvancedTitleWithTooltip from './AdvancedTitleWithTooltip';
import { animationOptions } from './DropdownOptions';
import RightNavBar from './RightNavBar';
import { AdvanceItem, Wrapper } from './styled';

import UpdateSliderRequest from '../../middleware/Slider/updateSlider';

const SliderSideBar = props => {
  const { closeSideBar, detail, sliderId, getNewSetting } = props;
  const [updatedDetail, setUpdatedDetail] = useState({});
  const [updateRequest, setUpdateRequest] = useState(false);

  useEffect(() => {
    setUpdatedDetail(detail);
  }, [detail]);

  const handleSaveBtn = () => {
    setUpdateRequest(true);
    getNewSetting(updatedDetail);
  };

  const handleUpdate = (value, e) => {
    let newValue = value;

    if (e.target.name === 'speed' || e.target.name === 'autoplaySpeed') {
      newValue = value * 1000;
    }

    const newDetail = {
      ...updatedDetail,
      [e.target.name]: newValue,
    };

    setUpdatedDetail(newDetail);
  };

  const handleUpdateError = error => {
    setUpdateRequest(false);
    throw new Error(error);
  };

  const handleUpdateSuccess = () => {
    setUpdateRequest(false);
    closeSideBar();
  };

  return (
    <>
      <RightNavBar onCancel={closeSideBar} onSave={handleSaveBtn}>
        <h3>Edit Slider</h3>
        <Wrapper>
          <AdvanceItem>
            <AdvancedTitleWithTooltip title="Title" tooltipText="Slider title" />
            <Input isChanged={handleUpdate} name="title" text={detail.title} type="text" />
          </AdvanceItem>
          <AdvanceItem>
            <AdvancedTitleWithTooltip title="Animation" tooltipText="Slider animation type" />
            <Dropdown
              availableResponses={animationOptions}
              name="animation"
              selectedValue={updatedDetail.animation}
              onChangeHandler={(_, value, event) => handleUpdate(value, event)}
            />
          </AdvanceItem>
          <AdvanceItem>
            <AdvancedTitleWithTooltip title="Animation speed / seconds" tooltipText="Transition speed between slides / seconds" />
            <Input isChanged={handleUpdate} name="speed" text={detail.speed / 1000} type="number" />
          </AdvanceItem>
          <AdvanceItem>
            <AdvancedTitleWithTooltip title="Auto Play" tooltipText="Transition speed between slides" />
            <SwitchBtn defaultChecked={detail.autoplay} id="slider-autoplay" onChange={handleUpdate} name="autoplay" />
          </AdvanceItem>
          {updatedDetail.autoplay && (
            <AdvanceItem>
              <AdvancedTitleWithTooltip title="Auto play speed / seconds" tooltipText="Auto play speed / seconds" />
              <Input isChanged={handleUpdate} name="autoplaySpeed" text={detail.autoplaySpeed / 1000} type="number" />
            </AdvanceItem>
          )}
          <AdvanceItem>
            <AdvancedTitleWithTooltip title="Display controls" tooltipText="Show or hide controls" />
            <SwitchBtn defaultChecked={detail.arrows} id="slider-arrow-display" onChange={handleUpdate} name="arrows" />
          </AdvanceItem>
        </Wrapper>
      </RightNavBar>
      {updateRequest && (
        <UpdateSliderRequest
          animation={updatedDetail.animation}
          arrows={updatedDetail.arrows}
          autoplay={updatedDetail.autoplay}
          autoplaySpeed={updatedDetail.autoplaySpeed || 2000}
          dots={updatedDetail.dots}
          infinite={updatedDetail.infinite}
          onError={handleUpdateError}
          onSuccessResult={handleUpdateSuccess}
          sliderId={sliderId}
          slidesToScroll={updatedDetail.slidesToScroll}
          slidesToShow={updatedDetail.slidesToShow}
          speed={updatedDetail.speed}
          title={updatedDetail.title}
        />
      )}
    </>
  );
};

SliderSideBar.propTypes = {
  detail: PropTypes.shape({
    animation: PropTypes.string,
    arrows: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    dots: PropTypes.bool,
    speed: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  getNewSetting: PropTypes.func.isRequired,
  sliderId: PropTypes.number.isRequired,
  closeSideBar: PropTypes.func,
};

SliderSideBar.defaultProps = {
  closeSideBar: () => {},
};

export default SliderSideBar;
