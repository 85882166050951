import React from 'react';

import PropTypes from 'prop-types';

function Popup(props) {
  const { text, closePopup, children, saveText, cancelText, onSubmit } = props;

  return (
    <div className="popup">
      <div className="popup_inner">
        <h1>{text}</h1>
        {children}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: ' .6em 0 0em auto',
          }}
        >
          <button
            className="button--outline"
            onClick={onSubmit}
            style={{ display: 'flex' }}
            type="button"
          >
            {saveText}
          </button>
          <button
            className="button--blue"
            onClick={closePopup}
            style={{ display: 'flex', marginLeft: '0.2em' }}
            type="button"
          >
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  children: PropTypes.shape([]),
  onSubmit: PropTypes.func,
  saveText: PropTypes.string,
};
Popup.defaultProps = {
  cancelText: 'Reject',
  children: [],
  onSubmit: () => {},
  saveText: 'Accept',
};
export default Popup;
