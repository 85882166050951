const DAYS = [
  {
    id: 'Mon',
    name: 'Mon',
    value: 'Mon',
  },
  {
    id: 'Tue',
    name: 'Tue',
    value: 'Tue',
  },
  {
    id: 'Wed',
    name: 'Wed',
    value: 'Wed',
  },
  {
    id: 'Thur',
    name: 'Thur',
    value: 'Thur',
  },
  {
    id: 'Fri',
    name: 'Fri',
    value: 'Fri',
  },
  {
    id: 'Sat',
    name: 'Sat',
    value: 'Sat',
  },
  {
    id: 'Sun',
    name: 'Sun',
    value: 'Sun',
  },
];

export { DAYS };
