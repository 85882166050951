import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useDomain } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { CardsWrapper, CardAvatar, CardInner } from './LibraryImageMapPage.styled';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import ImageMapSideBar from '../../components/RightNavBar/ImageMapSideBar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import AddNewCard from '../../components/UI/Card/AddNewCard';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { useNewEkardoSiteUrls } from '../../hooks';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import client from '../../middleware/client';
import { getAllImageMapsQuery } from '../../middleware/ImageMap/getAllImageMaps';

const LibraryImageMapPage = (props) => {
  const { match } = props;
  const { projectId, orgId } = useParams();
  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  const [sideBarDetails, setSideBarDetails] = useState({
    isActive: false,
    selectedImageMap: null,
  });

  const { loading, data, refetch } = useQuery(getAllImageMapsQuery, {
    client,
    variables: {
      projectId,
    },
  });

  const handleGroupAvatar = (title) => {
    const splitTitle = title.split(' ');
    return splitTitle.length > 1 ? `${splitTitle[0].split('')[0]}${splitTitle[1].split('')[0]}` : title.split('')[0];
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setSideBarDetails({ isActive: true, selectedImageMap: item });
  };

  const handleUpdate = () => {
    setSideBarDetails({ isActive: false, selectedImageMap: null });
    refetch();
  };

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library`,
        title: 'Library',
        isExternal: true,
      },
      {
        id: 2,
        path: ``,
        title: 'Interactive Regions library',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage your Interactive Regions"
        pageDescription="Manage all your Interactive Regions"
        pageTitle="Interactive Regions library page"
        params={match.params}
      >
        <ProjectNavBar title="Interactive Regions" />
        {sideBarDetails.isActive && (
          <div>
            <ImageMapSideBar
              closeSideBar={() => setSideBarDetails({ isActive: false, selectedImageMap: null })}
              detail={sideBarDetails.selectedImageMap}
              onUpdate={handleUpdate}
            />
            <Backdrop isClicked={() => setSideBarDetails({ isActive: false, selectedImageMap: null })} />
          </div>
        )}
        <CardsWrapper>
          {!loading && data
            ? data.contentGroup.searchContentGroups.map((item) => (
                <MenuCard
                  key={item.id}
                  edit={(e) => handleEdit(e, item)}
                  href={`/dashboard/${orgId}/${projectId}/image-map-builder/${item.id}?library=true`}
                  name={item.title}
                  src={item.iconAsset ? getSignedUrl(item.iconAsset.s3Key, item.iconAsset.contentType) : ''}
                >
                  <CardInner>{!item.iconAsset ? <CardAvatar>{handleGroupAvatar(item.title)}</CardAvatar> : null}</CardInner>
                </MenuCard>
              ))
            : null}
          <AddNewCard onClick={() => setSideBarDetails({ isActive: true, selectedImageMap: null })} />
        </CardsWrapper>
      </PageWrapper>
    </>
  );
};

LibraryImageMapPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      org: PropTypes.string,
      projectId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default LibraryImageMapPage;
