import React from 'react';

import cx from 'classnames';
import GoogleMapReact from 'google-map-react';

import { MapSnippetProps } from './MapSnippet.types';

import './MapSnippet.css';

const MapSnippet = ({ draggable = false, grayscale = false, latitude = 0, longitude = 0, zoom = 0, visible }: MapSnippetProps) => {
  if (!visible) {
    return null;
  }

  return (
    <div
      className={cx('c-google-map', {
        'c-google-map--is-greyscale': grayscale,
        'c-google-map--is-no-scroll': !draggable,
      })}
      data-testid="qa-map-snippet"
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyBqXU8wNCH619eid2pnacRraqqyoTarW9U',
        }}
        center={{
          lat: latitude,
          lng: longitude,
        }}
        defaultZoom={zoom}
        options={{
          fullscreenControl: false,
        }}
      />
    </div>
  );
};

export { MapSnippet };
