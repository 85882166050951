import React, { forwardRef } from 'react';

import cx from 'classnames';

import { InputFieldWrapper } from '../InputFieldWrapper';
import { Skeleton } from '../Skeleton';

import { InputProps } from './Input.types';

import './Input.css';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      additionalClassNames,
      autoComplete,
      errorMessage,
      iconBefore,
      iconAfter,
      id,
      isCorrect = false,
      isReadOnly = false,
      isDisabled = false,
      isLabelHidden = false,
      isRequired = false,
      isEncouraged = false,
      labelText,
      maxLength,
      maxNumber,
      minLength,
      minNumber,
      name,
      onBlur,
      onClick,
      onChange,
      onFocus,
      onKeyPress,
      onMouseUp,
      placeholder,
      step,
      successMessage,
      tooltipText,
      type = 'text',
      value,
      hasPadding = true,
      tooltipPosition = 'end',
      labelFontWeight = 'light',
      hasLabelPadding = false,
      isLoading = false,
    }: InputProps,
    ref,
  ) => {
    const inputClassNames = cx('c-input', additionalClassNames, {
      'is-correct': isCorrect,
      'is-invalid': Boolean(errorMessage),
      'is-disabled': isDisabled,
      'c-input--icon-before': Boolean(iconBefore),
      'c-input--icon-after': Boolean(iconAfter),
    });

    const maxLengthMap = {
      email: 200,
      number: 200,
      password: 100,
      tel: 50,
      text: 2048, // This value is the maximum a web browser allows for urls, so if we are trying to type a url to an input this should be the maximum.
      time: 5,
    };

    if (isLoading)
      return (
        <>
          {!isLabelHidden && <Skeleton additionalClassNames="c-label" width="10rem" />}
          <Skeleton additionalClassNames={inputClassNames} height="2.5rem" />
        </>
      );

    return (
      <InputFieldWrapper
        charactersRemaining={maxLength && typeof value === 'string' ? (maxLength - (value ? value.length : 0)).toString() : null}
        hasLabelPadding={hasLabelPadding}
        hasPadding={hasPadding}
        iconAfter={iconAfter}
        iconBefore={iconBefore}
        id={id}
        isEncouraged={isEncouraged}
        isLabelHidden={isLabelHidden}
        isLoading={isLoading}
        isRequired={isRequired}
        label={labelText}
        labelFontWeight={labelFontWeight}
        message={{ error: errorMessage ?? '', success: successMessage ?? '' }}
        tooltipPosition={tooltipPosition}
        tooltipText={tooltipText}
        type="input"
      >
        <input
          ref={ref}
          aria-invalid={Boolean(errorMessage)}
          autoComplete={autoComplete}
          className={inputClassNames}
          data-testid="qa-input"
          disabled={isDisabled}
          id={id}
          max={maxNumber}
          maxLength={maxLength ?? maxLengthMap[type]}
          min={minNumber}
          minLength={minLength}
          name={name}
          placeholder={placeholder}
          readOnly={isReadOnly}
          required={isRequired}
          step={step}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onMouseUp={onMouseUp}
        />
      </InputFieldWrapper>
    );
  },
);

Input.displayName = 'Input';

export { Input };
