import cx from 'classnames';

import { ISetUserAnswerVariablesBase } from '../../contexts';
import {
  ICustomConfigurationOptions,
  IDBContentPage,
  IDBContentSnippet,
  IDBContentSnippetForm,
  IDBUserFlowStepTrack,
} from '../../interfaces';

const getAriaDescribedBy = (describedById: string | undefined, id: string, hasError: boolean): string | undefined => {
  return (
    cx({
      [`${describedById}`]: Boolean(describedById),
      [`${id}-error`]: hasError,
    }) || undefined
  );
};

const getInputFieldClassNames = (
  inputType: 'number' | 'text' | 'textarea',
  isDirty?: boolean,
  isInvalid?: boolean,
  isRequired?: boolean
): string => {
  return cx('c-input-field__input', `c-input-field__input--${inputType}`, {
    'is-dirty': Boolean(isDirty),
    'is-invalid': Boolean(isInvalid),
    'is-required': Boolean(isRequired),
  });
};

const getMutationBaseVariables = (
  contentPageId: IDBContentPage['id'] | undefined,
  contentSnippetFormId: IDBContentSnippetForm['id'],
  contentSnippetQuestionId: IDBContentSnippet['id'],
  userFlowStepTrackId: IDBUserFlowStepTrack['id']
): ISetUserAnswerVariablesBase => {
  return {
    contentPageId,
    contentSnippetFormId,
    contentSnippetQuestionId,
    userFlowStepTrackId,
  } as ISetUserAnswerVariablesBase;
};

const getHtmlElementOnChangeValueFromEvent = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
  const {
    target: { value },
  } = event;

  return value;
};

const replacePlaceholderText = (text: string, placeholders: Pick<ICustomConfigurationOptions, 'placeholders'> | undefined) => {
  let updatedText = text;

  if (placeholders) {
    updatedText = Object.keys(placeholders).reduce((accumulator, placeholder) => {
      if (!accumulator.includes(placeholder)) {
        return accumulator;
      }

      return accumulator.replaceAll(placeholder, placeholders[placeholder]);
    }, text);
  }

  return updatedText;
};

export {
  getAriaDescribedBy,
  getHtmlElementOnChangeValueFromEvent,
  getInputFieldClassNames,
  getMutationBaseVariables,
  replacePlaceholderText,
};
