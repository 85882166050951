import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  background: #fff;
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  color: #303941;
  display: block;
  line-height: 1rem;
  margin: 6px 0 1em 0;
  min-height: 9rem;
  padding: 0.8rem;
  resize: vertical;
  transition: 0.25s ease-in-out;
  width: 100%;
  font-weight: 200;
  &:focus {
    background: #fff;
    border-color: #44bed3 !important;
    outline: none;
  }
`;

const Textarea = props => {
  const { isChanged, isDisabled, name, text, onChange, id } = props;
  const [typed, setTyped] = useState(false);
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleText = e => {
    setTyped(true);
    setValue(e.target.value);
  };

  const handleBlur = e => {
    e.persist();
    onChange(e);
    isChanged(value, e);
  };

  useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <StyledTextarea disabled={isDisabled} id={id} name={name} onBlur={handleBlur} onChange={handleText} value={typed ? value : text} />
  );
};

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  isChanged: PropTypes.func,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  text: PropTypes.string,
};

Textarea.defaultProps = {
  isChanged: () => {},
  isDisabled: false,
  name: '',
  onChange: () => {},
  text: '',
};

export default Textarea;
