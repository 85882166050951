import React, { useState } from 'react';

import PropTypes from 'prop-types';

import SwitchButton from '../UI/Button/SwitchButton';
import Editor from '../UI/TextEditor/Editor';
import Tooltip from '../UI/Tooltip/Tooltips';

import { SnippetTypeConst } from './SnippetTypeConst';

import CreateQuoteContentSnippetRequest from '../../middleware/ContentSnippet/createQuoteContentSnippetRequest';
import UpdateQuoteContentSnippetRequest from '../../middleware/ContentSnippet/updateQuoteContentSnippetRequest';
import { StyledTextarea, SwitchBox, ModeTitle } from './TextSnippet';

function EditableQuoteSnippet(props) {
  const {
    containerId,
    onCancel,
    onCreate,
    snippet,
    onUpdate,
    isSnippetInForm,
  } = props;
  const [text, setText] = useState(snippet ? snippet.text : null);
  const [author, setAuthor] = useState(snippet ? snippet.author : '');
  const [sendRequest, setSendRequest] = useState(false);
  const [isSourceEditMode, setIsSourceEditMode] = useState(false);

  const handleChange = value => {
    setText(value);
  };

  const handleSourceChange = event => {
    setText(event.target.value);
  }

  const handleAuthorChange = event => {
    setAuthor(event.target.value);
  };

  const handleSaveButton = () => {
    setSendRequest(true);
  };

  const handleSnippetError = () => {
    setSendRequest(false);
  };

  const handleSnippetResponse = (id, sort) => {
    if (snippet) {
      onUpdate(id, snippet.sort, SnippetTypeConst.QUOTE, containerId, {
        text,
        author,
      });
    } else {
      onCreate(id, sort, SnippetTypeConst.QUOTE, containerId, { text, author });
    }

    setSendRequest(false);
  };

  return (
    <>
      <div>
        <SwitchBox>
          <ModeTitle>
            Source view
            <Tooltip
              text="Please note, inline styles added in advanced mode will be lost
            in basic mode."
            />
          </ModeTitle>
          <SwitchButton
            defaultChecked={false}
            id={snippet ? `${snippet.id}`: 'quote-switch'}
            name="mode"
            onChange={() => setIsSourceEditMode(isEdit => !isEdit)}
          />
        </SwitchBox>
        {isSourceEditMode ? (
          <StyledTextarea
            minRows={8}
            onChange={handleSourceChange}
            value={text}
          />
          ) : (
          <Editor getContent={handleChange} id="text" value={text} />
        )}
        <strong>Author</strong>
        <input
          className="w-100 bg-white border"
          onChange={handleAuthorChange}
          value={author}
        />
        {!isSnippetInForm && (
          <div className="text-right">
            <button
              className="button--black mt-4 mr-3"
              onClick={onCancel}
              type="button"
            >
              Cancel
            </button>
            <button
              className="button--blue mt-4"
              onClick={handleSaveButton}
              type="submit"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {sendRequest && !snippet && (
        <CreateQuoteContentSnippetRequest
          author={author}
          containerId={containerId}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          text={text}
        />
      )}
      {sendRequest && snippet && (
        <UpdateQuoteContentSnippetRequest
          author={author}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          snippetId={snippet.id}
          text={text}
        />
      )}
    </>
  );
}

EditableQuoteSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isSnippetInForm: PropTypes.bool,
  snippet: PropTypes.shape({
    author: PropTypes.any,
    id: PropTypes.number,
    sort: PropTypes.any,
    text: PropTypes.any,
  }),
};

EditableQuoteSnippet.defaultProps = {
  isSnippetInForm: false,
  snippet: null,
};

export default EditableQuoteSnippet;
