const WRAPPER_TYPE_ACTION_KEY_MAP = Object.freeze({
  container: 'containerId',
  section: 'sectionId',
  targetSnippet: 'targetSnippetId',
  page: 'contentPageId',
});

const QUESTION_CONDITION_TYPE_MAP = {
  QuestionConditionCheckboxSelectedType: 'checkbox',
  QuestionConditionRadioResponseSelectedType: 'radio',
  QuestionConditionTextType: 'text',
  QuestionConditionSliderValueSelectedType: 'slider',
  QuestionConditionDateSelectedType: 'calendar',
};

const ACTION_DEFAULT_TYPE = {
  targetSnippet: 'QuestionActionHideType',
  container: 'QuestionActionHideContainerType',
  section: 'QuestionActionHideSectionType',
  page: 'QuestionActionSkipPageType',
};

export { QUESTION_CONDITION_TYPE_MAP, WRAPPER_TYPE_ACTION_KEY_MAP, ACTION_DEFAULT_TYPE };
