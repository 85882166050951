import { useEkardoMutation } from '../useEkardoMutation';

import { CREATE_SUMMARY_FEEDBACK_RANK_AND_NOTE_MATCH_MUTATION } from './useCreateSummaryFeedbackRankAndNoteMatch.graphql';
import {
  ICreateSummaryFeedbackRankAndNoteMatchMutationGraphQLResponse,
  ICreateSummaryFeedbackRankAndNoteMatchMutationVariables,
  IHandleCreateSummaryFeedbackRankAndNoteMatchParams,
  IUseCreateSummaryFeedbackRankAndNoteMatchOptions,
  IUseCreateSummaryFeedbackRankAndNoteMatch,
} from './useCreateSummaryFeedbackRankAndNoteMatch.interfaces';

const useCreateSummaryFeedbackRankAndNoteMatch = (
  options?: IUseCreateSummaryFeedbackRankAndNoteMatchOptions
): IUseCreateSummaryFeedbackRankAndNoteMatch => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateSummaryFeedbackRankAndNoteMatchOptions);

  const [executeCreateSummaryFeedbackRankAndNoteMatch, { loading: isLoading }] = useEkardoMutation<
    ICreateSummaryFeedbackRankAndNoteMatchMutationGraphQLResponse,
    ICreateSummaryFeedbackRankAndNoteMatchMutationVariables
  >({
    mutation: mutation ?? CREATE_SUMMARY_FEEDBACK_RANK_AND_NOTE_MATCH_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { createSummaryFeedbackRankAndNoteMatch: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateSummaryFeedbackRankAndNoteMatch = async ({
    contentSnippetSummaryId,
    questionAnswerId,
    questionResponseId,
    note,
    rank,
  }: IHandleCreateSummaryFeedbackRankAndNoteMatchParams) => {
    await executeCreateSummaryFeedbackRankAndNoteMatch({
      variables: {
        contentSnippetSummaryId,
        questionAnswerId,
        questionResponseId,
        note,
        rank,
      },
    });
  };

  return {
    handleCreateSummaryFeedbackRankAndNoteMatch,
    isLoading,
  };
};

export { useCreateSummaryFeedbackRankAndNoteMatch };
