import s3 from './s3';

export const deleteObject = key =>
  s3.deleteObject(
    {
      Bucket: process.env.REACT_APP_BUCKET,
      Key: key,
    },
    error => {
      if (error) throw new Error(error);
    }
  );
