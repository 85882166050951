import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CODE_ASSET } from './UseGetCodeAsset.graphql';

const useGetCodeAsset = (options) => {
  const { fetchPolicy, query, onCompleted, onError, token } = options ?? {};

  const [executeGetCodeAsset, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { getAsset: returnedAsset },
        } = data;

        onCompleted({
          returnedAsset,
        });
      },
      onError,
    },
    query: query ?? GET_CODE_ASSET,
    token,
  });

  const handleGetCodeAsset = ({ assetId }) => {
    executeGetCodeAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleGetCodeAsset,
    isLoading,
  };
};

export { useGetCodeAsset };
