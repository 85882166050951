import React, { useRef, useState } from 'react';

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';
import { Client } from '@netfront/gelada-react-shared';
import { addMonths, isValid } from 'date-fns';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';

import ExportPage from './ExportPage';

import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import { downloadFileByUrl } from '../../../utils/utils';

const EXPORT_LOGIN_TRIALS = gql`
  query ($dateFrom: DateTime!, $dateTo: DateTime!, $ianaTimeZone: String!, $projectId: String!, $fileType: FileType!) {
    customQueries {
      ourfutures {
        trialLogins(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
          s3Key
          contentType
        }
      }
    }
  }
`;

const ExportLoginsTrialsPage = () => {
  const { projectId } = useParams();

  const client = useRef(Client()).current;

  const [apiError, setApiError] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(addMonths(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [isFormValid, setIsFormValid] = useState(true);

  const [sendExportLoginsQuery, { loading: isGeneratingReport }] = useLoggedGeladaLazyQuery({
    query: EXPORT_LOGIN_TRIALS,
    options: {
      client,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { contentType, s3Key } = data.customQueries.ourfutures.trialLogins;
        const signedUrl = getSignedUrl(s3Key, contentType);

        downloadFileByUrl(signedUrl);
      },
      onError: (error) => {
        setApiError(error);
      },
    },
  });

  const download = (fileType = 'EXCEL' | 'CSV') => {
    sendExportLoginsQuery({
      variables: {
        dateFrom,
        dateTo,
        ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        projectId,
        fileType,
      },
    });
  };

  const handleDateFromChange = (value) => {
    setDateFrom(value);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo));
  };

  const handleDateToChange = (value) => {
    setDateTo(value);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo));
  };

  const options = {
    apiError,
    downloadCSVCallback: () => download('CSV'),
    filtering: {
      date: {
        dateFrom,
        dateTo,
        handleDateFromChangeCallback: handleDateFromChange,
        handleDateToChangeCallback: handleDateToChange,
      },
    },
    flags: {
      isFormValid,
      preloader: {
        isGeneratingReport,
      },
    },
    infoBannerText: 'This page exports logins based on the selected filters',
  };

  return <ExportPage options={options} />;
};

export default ExportLoginsTrialsPage;
