import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_CONTENT_GROUPS_WITH_CHILDREN = gql`
  query GetContentGroups($projectId: String!) {
    contentGroup {
      searchContentGroups(
        projectId: $projectId
        status: [UNPUBLISHED, PUBLISHED]
      ) {
        id
        title
        type
        description
        status
        sort
      }
    }
  }
`;

const getContentGroupsWithChildren = ({
  projectId,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={GET_ALL_CONTENT_GROUPS_WITH_CHILDREN}
    variables={{ projectId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.contentGroup.searchContentGroups
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getContentGroupsWithChildren.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default getContentGroupsWithChildren;
