import React from 'react';

import './StatusIndicator.css';

const StatusIndicator = ({ status }) => (
  <div className="c-status-indicator">
    <div className={`c-status-indicator__color c-status-indicator__color--${status.toLowerCase()}`} />
    <div className="c-status-indicator__status">{status}</div>
  </div>
);

export { StatusIndicator };
