import gql from 'graphql-tag';

export const CREATE_TEMPLATE = gql`
  mutation(
    $name: String!
    $type: ETemplateType!
    $content: String!
    $projectId: String!
    $usage: EEmailUsage
    $smsUsage: ESmsUsage
    $subject: String!
  ) {
    templates {
      addTemplate(
        emailUsage: $usage
        smsUsage: $smsUsage
        template: { name: $name, type: $type, subject: $subject, content: $content, messageType: APPLICATION, projectId: $projectId }
      ) {
        id
      }
    }
  }
`;
