import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import SquareButton from '../UI/Button/SquareButton';
import NormalModal from '../UI/Modal/NormalModal';

const Wrapper = styled.div`
  animation: FadeIn 220ms cubic-bezier(0.2, 0, 0, 1) forwards;
  background: #fff;
  bottom: 3.7rem;
  box-shadow: 2px 0 10px 2px rgba(32, 33, 36, 0.28);
  padding: 2rem;
  position: fixed;
  right: 0;
  top: 4rem;
  width: 26rem;
  z-index: 10;

  @keyframes FadeIn {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const Container = styled.div`
  height: 100%;
  margin: 0 -2rem;
  overflow-y: auto;
  padding: 0 2rem;
`;

const ButtonGroup = styled.div`
  bottom: 2rem;
  margin: -2rem;
  position: absolute;
  width: 100%;
`;

export const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding-top: 0.3rem;
`;

const DeleteButton = styled.button`
  background-color: #6d7a8a;
  border: none;
  color: #fff;
  display: block;
  line-height: 3rem;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: #dc3545;
  }
`;

const RightNavBar = (props) => {
  const {
    children,
    confirmationMessage,
    confirmBtnName,
    hasConfirmButton,
    hasDelete,
    hasNoFooter,
    isConfirmButtonDisabled,
    deleteBtnName,
    deleteComps,
    onCancel,
    onDelete,
    onSave,
  } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleSave = () => {
    document.body.style.overflow = 'unset';
    onSave();
  };

  const handleCancel = () => {
    document.body.style.overflow = 'unset';
    onCancel();
  };

  const handleDelete = () => {
    onDelete();
    setIsDeleteModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <Wrapper className="animated slideInRight" hasNoFooter={hasNoFooter}>
      <Container hasDelete={hasDelete}>{children}</Container>
      <ButtonGroup>
        {hasConfirmButton && (
          <ButtonContainer>
            <SquareButton bgColor="#505354" color="#fff" isClicked={handleCancel}>
              Cancel
            </SquareButton>
            <SquareButton isClicked={handleSave} isDisabled={isConfirmButtonDisabled}>
              {confirmBtnName}
            </SquareButton>
          </ButtonContainer>
        )}
        {hasDelete && onDelete ? (
          <>
            <DeleteButton onClick={() => setIsDeleteModalOpen(true)}>{deleteBtnName}</DeleteButton>
            <NormalModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
              {confirmationMessage}
              {deleteComps}
              <ModalButtonGroup>
                <SquareButton isClicked={() => setIsDeleteModalOpen(false)}>No, cancel</SquareButton>
                <SquareButton bgColor="#dc3545" color="white" isClicked={handleDelete}>
                  Yes, delete
                </SquareButton>
              </ModalButtonGroup>
            </NormalModal>
          </>
        ) : null}
      </ButtonGroup>
    </Wrapper>
  );
};

RightNavBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  confirmationMessage: PropTypes.string,
  confirmBtnName: PropTypes.string,
  deleteBtnName: PropTypes.string,
  deleteComps: PropTypes.any,
  // eslint-disable-next-line react/boolean-prop-naming
  hasConfirmButton: PropTypes.bool,
  hasDelete: PropTypes.bool,
  hasNoFooter: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
};

RightNavBar.defaultProps = {
  confirmationMessage: 'Are you sure you want to delete this record?',
  confirmBtnName: 'Save and close',
  deleteBtnName: 'Delete',
  deleteComps: undefined,
  hasConfirmButton: true,
  hasDelete: true,
  hasNoFooter: false,
  onCancel: () => null,
  onDelete: undefined,
  onSave: () => null,
};

export default RightNavBar;
