import React, { useContext, useEffect, useState } from 'react';

import { removeItemFromLocalStorage, useCookie } from '@netfront/common-library';
import { useGetGeladaProjects } from '@netfront/gelada-identity-library';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ProjectSideBar from '../../components/RightNavBar/ProjectSideBar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import AddNewCard from '../../components/UI/Card/AddNewCard';
import { LoadingMenuCard } from '../../components/UI/Card/LoadingMenuCard';
import MenuCard from '../../components/UI/Card/MenuCard';
import { getAssetsUrl } from '../../config';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import ThemeContext from '../../context/ThemeContext';
import { useToast } from '../../hooks';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

const Avatar = styled.div`
  align-items: center;
  background-color: ${(props) => props.color || '#4bc1c9'};
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
`;

const AvatarContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto 0.5rem auto;
  width: 100%;
`;

const CardsBox = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-right: -1.7em;
`;

// TODO:
//    - Need to fix updating/deleting a project since it currently does not work

const ProjectsPage = () => {
  const { handleToastError } = useToast();

  const { getAccessTokenCookie } = useCookie();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const params = useParams();

  const { orgId: organisationId } = params;

  const { org: organisation, updateProject } = useContext(AppContext);
  const { updateLogo, updateTheme } = useContext(ThemeContext);

  const [allProjects, setAllProjects] = useState([]);
  const [isProjectSideBarOpen, setIsProjectSideBarOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);

  if (pathname.search('backoffice.ffsp') !== -1) {
    push('/404');
  }

  const token = getAccessTokenCookie();

  useEffect(() => {
    removeItemFromLocalStorage('project');
  }, []);

  const { handleGetGeladaProjects, isLoading: isGetGeladaProjectsLoading = false } = useGetGeladaProjects({
    onCompleted: ({ geladaProjects }) => {
      setAllProjects(geladaProjects);
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const generateColor = () => `#${Math.random().toString(16).substr(-6)}`;

  const getAvatarName = (name) =>
    name
      .split(' ')
      .map((item) => item.substring(0, 1))
      .splice(0, 2);

  const handleAddNewProject = () => {
    setIsProjectSideBarOpen(true);
  };

  const handleCloseProjectSideBar = () => {
    setIsProjectSideBarOpen(false);
    setSelectedProject(undefined);
  };

  const handleDeleteProject = () => {
    setIsProjectSideBarOpen(false);
    handleGetGeladaProjects({
      organisationKey: organisationId,
      shouldIncludeProjectTheme: true,
      shouldIncludeProjectLogo: true,
    });
  };

  const handleEditProject = (event, project) => {
    event.preventDefault();

    setIsProjectSideBarOpen(true);
    setSelectedProject(project);

    updateProject(project);
  };

  const handleSaveProject = () => {
    setIsProjectSideBarOpen(false);
    handleGetGeladaProjects({
      organisationKey: organisationId,
      shouldIncludeProjectTheme: true,
      shouldIncludeProjectLogo: true,
    });
  };

  useEffect(() => {
    if (!organisationId) {
      return;
    }

    handleGetGeladaProjects({
      organisationKey: organisationId,
      shouldIncludeProjectTheme: true,
      shouldIncludeProjectLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    updateLogo(`${getAssetsUrl()}/logo/logo-minimal.svg`);
    updateTheme({
      backGroundColor: null,
      buttonColor: null,
      footerColor: null,
      headerColor: null,
      linkColor: null,
      sideColor: null,
    });
  }, []);

  const isLoading = isGetGeladaProjectsLoading;

  const { name: organisationName } = organisation ?? {};
  const pageTitle = organisationName ? `Manage your ${organisationName} project` : '';

  return (
    <>
      <PageWrapper
        info={`Manage all your projects in ${organisationName}`}
        pageDescription={`Manage all your projects in ${organisationName}`}
        pageTitle={pageTitle}
        params={params}
        noTheme
      >
        <CardsBox>
          {isLoading && <LoadingMenuCard count={3} />}

          {!isLoading &&
            allProjects.map((project) => {
              const { id, logo, name } = project;
              const { contentType, s3Key } = logo ?? {};

              const avatarColor = generateColor();
              const avatarName = getAvatarName(name);
              const href = `${pathname}/project/${id}`;
              const logoUrl = contentType && s3Key ? getSignedUrl(s3Key, contentType) : '';

              return (
                <MenuCard key={`project-${id}`} edit={(event) => handleEditProject(event, project)} href={href} name={name} src={logoUrl}>
                  {!logoUrl && (
                    <AvatarContainer>
                      <Avatar color={avatarColor}>{avatarName}</Avatar>
                    </AvatarContainer>
                  )}
                </MenuCard>
              );
            })}

          <AddNewCard onClick={handleAddNewProject} />
        </CardsBox>

        {isProjectSideBarOpen && (
          <>
            <ProjectSideBar
              currentProject={selectedProject}
              onCancel={handleCloseProjectSideBar}
              onDelete={handleDeleteProject}
              onSave={handleSaveProject}
            />
            <Backdrop isClicked={handleCloseProjectSideBar} />
          </>
        )}
      </PageWrapper>
    </>
  );
};

export default ProjectsPage;
