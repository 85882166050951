import React, { useContext, useRef, useState, useEffect } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { Client } from '@netfront/gelada-react-shared';
import { Preloader } from '@netfront/ui-library';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';

import Field from '../Forms/Field';
import Form from '../Forms/Form';
import FormHeader from '../Forms/FormHeader';
import Note from '../Notes/Note';
import TextField from '../TextField/TextField';
import SwitchBtn from '../UI/Button/SwitchButton';
import Dropdown from '../UI/Dropdown/Dropdown';
import PopUpMsg from '../UI/PopUp/PopUpMsg';
import SidebarTab from '../UI/Tab/SidebarTab';

import RightNavBar from './RightNavBar';

import { EntityTypeKeys } from '../../constants/EntityTypeKeys';
import AppContext from '../../context/AppContext';
import client from '../../middleware/client';
import CreateGroup from '../../middleware/Group/createGroupRequest';
import { combineGroupData, SEARCH_GROUPS } from '../../middleware/Group/getAllGroupsRequest';
import { UPDATE_GROUP } from '../../middleware/Group/updateGroup';
import { UPDATE_USER_AS_ADMIN } from '../../middleware/User/updateUserAsAdmin';
import { DEFAULT_TOAST_OPTIONS } from '../../utils';
import { getApiErrorMessages } from '../../utils/utils';

const DELETE_GROUP_MUTATION = gql`
  mutation deleteGroupMutation($groupId: Int!) {
    group {
      deleteGroup(groupId: $groupId)
    }
  }
`;

const GROUP_REQUEST_MUTATION = gql`
  mutation groupRequestMutation($requestId: Int!, $approved: Boolean!, $reason: String, $deleteOnDecline: Boolean, $name: String) {
    group {
      acceptOrDeclineGroupCreation(
        requestId: $requestId
        status: $approved
        reason: $reason
        deleteOnDecline: $deleteOnDecline
        name: $name
      )
    }
  }
`;

const UPDATE_CUSTOM_FIELDS = gql`
  mutation updateCustomFields($responses: [CustomFieldResponseTextInputType]) {
    customFieldResponse {
      answerCustomFieldTexts(responses: $responses)
    }
  }
`;

export const GET_GROUP_TYPES = gql`
  query getGroupTypes($projectId: String!) {
    groupType {
      getGroupTypes(projectId: $projectId) {
        id
        name
      }
    }
  }
`;

const UPDATE_GROUP_TYPE = gql`
  mutation updateGroupType($groupId: Int!, $groupTypeId: Int!) {
    group {
      updateGroupType(groupId: $groupId, groupTypeId: $groupTypeId) {
        id
      }
    }
  }
`;

const UPDATE_SMART_CODES = gql`
  mutation updateSmartCodes($smartCodes: [SmartCodeUpdateInputType]!) {
    smartCode {
      bulkUpdate(smartCodes: $smartCodes)
    }
  }
`;

const GET_CUSTOM_FIELDS = gql`
  query getCustomFields($projectId: String!, $groupId: Int!) {
    customField {
      getCustomFields(projectId: $projectId, scope: GROUP) {
        id
        name
        __typename
        responses(groupId: $groupId) {
          id
          __typename
          ... on CustomFieldResponseNumberGraphType {
            number
            __typename
          }
          ... on CustomFieldResponseTextGraphType {
            text
            __typename
          }
          ... on CustomFieldResponseDateGraphType {
            dateTime
            __typename
          }
        }
      }
    }
  }
`;

const GET_GROUPS = gql`
  query searchGroups($projectId: String!) {
    group {
      searchGroups(projectId: $projectId, status: ACTIVE) {
        id
        name
      }
    }
  }
`;

const SET_USERFLOW_ACCESS_MODE = gql`
  mutation setUserFowAccessMode($groupId: Int!, $mode: EUserFlowAccessMode!) {
    userFlowAccessMode {
      set(groupId: $groupId, mode: $mode)
    }
  }
`;

const GET_GROUP = gql`
  query getGroup($groupId: Int!) {
    group {
      getGroup(groupId: $groupId) {
        id
        name
        smartCodes {
          code
          smartCodeId
          userType {
            name
          }
        }
      }
    }
  }
`;

const GET_USERTYPES = gql`
  query searchUserTypes($projectId: String!) {
    userType {
      searchUserTypes(projectId: $projectId) {
        id
        name
      }
    }
  }
`;

const MOVE_USER_TO_GROUP = gql`
  mutation ($userId: Int!, $groupId: Int!, $projectId: String!, $userTypeId: Int!) {
    membership {
      addUserInGroup(userId: $userId, groupId: $groupId, userTypeId: $userTypeId) {
        id
      }
      removeFromDefaultGroup(userId: $userId, projectId: $projectId)
    }
  }
`;

const ACTIVATE = gql`
  mutation ($userId: Int!, $projectId: String!) {
    user {
      updateUsersStatus(newUserStatus: ACTIVE, projectId: $projectId, userIds: [$userId])
    }
  }
`;

const GET_ACCESS_MODE = gql`
  query getUserFlowAccessMode($groupId: Int!) {
    userFlowAccessMode {
      get(groupId: $groupId) {
        mode
      }
    }
  }
`;

const TABS = {
  information: 0,
  contact: 1,
  notes: 2,
};

const TOAST_MESSAGES = Object.freeze({
  success: Object.freeze({
    groupUpdated: 'Group successfully updated',
    userUpdated: 'User successfully updated',
  }),
});

const ProjectUserGroupSideBar = ({ group, onCancel, onDelete }) => {
  const { project } = useContext(AppContext);

  const {
    id: projectId,
    settings: { hasModuleProgression },
  } = project;

  const geladaClient = useRef(Client(true));
  const notesRef = useRef();

  const [customFields, setCustomFields] = useState([]);
  const [declineReason, setDeclineReason] = useState('');
  const [groupTypes, setGroupTypes] = useState(undefined);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [newGroup, setNewGroup] = useState(group);
  const [newGroupType, setNewGroupType] = useState(undefined);
  const [selectedTab, setSelectedTab] = useState(TABS.information);
  const [sendRequest, setSendRequest] = useState(false);
  const [action, setAction] = useState('approve');
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableUserTypes, setAvailableUserTypes] = useState([]);
  const [selectedDestinationGroup, setSelectedDestinationGroup] = useState();
  const [selectedDestinationUserType, setSelectedDestinationUserType] = useState();
  const [groupAccessMode, setGroupAccessMode] = useState(undefined);
  const [smartCodes, setSmartCodes] = useState([]);
  const [primaryContactDetails, setPrimaryContactDetails] = useState(group.creator);

  const isCreateNewGroup = Object.keys(group).length === 0;
  const isEdit = Object.keys(newGroup).length !== 0;
  const isGroupCreationRequest = group.status === 'REQUEST';

  const {
    address,
    description,
    id: groupId,
    identifier,
    name: groupName,
    phoneNumber = '',
    status,
    state: requestState,
    city: requestCity,
    postCode: requestPostCode,
    userFlowId,
    website = '',
  } = newGroup || {};

  const { city = '', country = 'AU', line1 = '', line2 = '', postCode = '', state = '' } = address || {};
  const { communityName = '', credential, id: creatorId, firstname = '', lastname = '' } = primaryContactDetails || {};
  const { email = '', phoneNumber: primaryContactPhoneNumber = '' } = credential || {};

  let title = 'Add new group';

  if (isEdit && !isCreateNewGroup) {
    title = `Edit ${groupName}'s details`;
  }

  if (isGroupCreationRequest) {
    title = `${groupName}'s request`;
  }

  const handleApolloError = (error) => {
    getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));
  };

  const setErrorMessage = (errorMessage) => {
    let text;

    if (typeof errorMessage === 'object' && errorMessage.networkError) {
      text = `Error: ${errorMessage.networkError.result.errors[0].message}`;
    } else if (typeof errorMessage === 'object') {
      text = errorMessage.message;
    } else {
      text = errorMessage;
    }

    setMessage({ text, type: 'error' });
  };

  const [getAccessMode, { loading: isGetAccessModeLoading }] = useLazyQuery(GET_ACCESS_MODE, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      const {
        userFlowAccessMode: { get },
      } = data;

      if (!get) {
        return;
      }

      const { mode } = get;

      setGroupAccessMode(mode);
    },
    onError: handleApolloError,
  });

  useQuery(GET_USERTYPES, {
    client: geladaClient.current,
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      const {
        userType: { searchUserTypes },
      } = data;

      setAvailableUserTypes(
        searchUserTypes.map(({ id, name }) => ({
          id,
          value: id,
          label: name,
        })),
      );
    },
    onError: handleApolloError,
    skip: !isGroupCreationRequest,
    variables: {
      projectId,
    },
  });

  useQuery(GET_GROUPS, {
    client: geladaClient.current,
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      const {
        group: { searchGroups },
      } = data;

      setAvailableGroups(
        searchGroups.map(({ id, name }) => ({
          id,
          value: id,
          label: name,
        })),
      );
    },
    onError: handleApolloError,
    skip: !isGroupCreationRequest,
    variables: {
      projectId,
    },
  });

  const [activateUser, { loading: isActivateUserLoading }] = useMutation(ACTIVATE, {
    client: geladaClient.current,
    onCompleted: () => {
      onDelete('REQUEST');
    },
  });

  const [moveUser, { loading: isMoveUserLoading }] = useMutation(MOVE_USER_TO_GROUP, {
    client: geladaClient.current,
    onCompleted: () => {
      activateUser({
        variables: {
          userId: creatorId,
          projectId,
        },
      });
    },
  });

  const [getGroups, { loading: isGetGroupsLoading }] = useLazyQuery(SEARCH_GROUPS, {
    client: geladaClient.current,
    onCompleted: (data) => {
      onCancel('approve', combineGroupData(data.group));
    },
    onError: handleApolloError,
  });

  const [getGroup, { loading: isGetGroupLoading }] = useLazyQuery(GET_GROUP, {
    client: geladaClient.current,
    onCompleted: (data) => {
      const {
        group: {
          getGroup: { smartCodes: returnedSmartCodes },
        },
      } = data;

      setSmartCodes(returnedSmartCodes);
    },
    onError: handleApolloError,
  });

  const [updateSmartCodes, { loading: isUpdateSmartCodesLoading }] = useMutation(UPDATE_SMART_CODES, {
    client: geladaClient.current,
    onError: handleApolloError,
  });

  const [sendDeleteGroupMutation, { loading: isSendDeleteGroupMutationLoading }] = useMutation(DELETE_GROUP_MUTATION, {
    client: geladaClient.current,
    onError: handleApolloError,
  });

  const [sendGroupRequestMutation, { loading: isSendGroupRequestMutationLoading }] = useMutation(GROUP_REQUEST_MUTATION, {
    client: geladaClient.current,
    onError: handleApolloError,
  });

  const [updateUserFlowAccessMode, { loading: isUpdateUserFlowAccessModeLoading }] = useMutation(SET_USERFLOW_ACCESS_MODE, {
    client,
    onError: handleApolloError,
  });

  const [updateCustomFields, { loading: isUpdateCustomFieldsLoading }] = useMutation(UPDATE_CUSTOM_FIELDS, {
    client: geladaClient.current,
    onError: handleApolloError,
  });

  const [updateGroupType, { loading: isUpdateGroupTypeLoading }] = useMutation(UPDATE_GROUP_TYPE, {
    client: geladaClient.current,
    onError: handleApolloError,
  });

  const [getCustomFieldsRequest, { loading: isGetCustomFieldsRequestLoading }] = useLazyQuery(GET_CUSTOM_FIELDS, {
    client: geladaClient.current,
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      const {
        customField: { getCustomFields },
      } = data;

      const updatedCustomFields = getCustomFields.map((customField) => {
        const { id: customFieldId, name: customFieldName, responses } = customField;
        return {
          customFieldId,
          customFieldName,
          text: responses.length ? responses[0].text : '',
        };
      });

      setCustomFields(updatedCustomFields);
    },
    onError: handleApolloError,
    variables: {
      groupId,
      projectId,
    },
  });

  const [updateGroup, { loading: isUpdateGroupLoading }] = useMutation(UPDATE_GROUP, {
    client: geladaClient.current,
    onCompleted: () => {
      const updatedGroup = {
        ...newGroup,
        smartCodes,
      };

      onCancel('update group', updatedGroup);

      toast.success(TOAST_MESSAGES.success.groupUpdated);
    },
    onError: handleApolloError,
  });

  const [updateUserAsAdmin, { loading: isUpdateUserAsAdminLoading }] = useMutation(UPDATE_USER_AS_ADMIN, {
    client: geladaClient.current,
    onCompleted: () => {
      toast.success(TOAST_MESSAGES.success.userUpdated);
    },
  });

  const [getGroupTypesQuery, { loading: isGetGroupTypesQueryLoading }] = useLazyQuery(GET_GROUP_TYPES, {
    client: geladaClient.current,
    onCompleted: (data) => {
      const {
        groupType: { getGroupTypes: returnedGroupTypes = [] },
      } = data;

      setGroupTypes(
        returnedGroupTypes.map(({ id, name }) => ({
          id,
          value: id,
          label: name,
        })),
      );
    },
    variables: {
      projectId,
    },
  });

  const handleUpdateGroupAddress = (event) => {
    const {
      target: { name, value },
    } = event;

    setNewGroup((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };

  const handleUpdateActiveAddress = (event) => {
    const {
      target: { name, value },
    } = event;

    setNewGroup((currentState) => ({
      ...currentState,
      address: {
        ...address,
        [name]: value,
      },
    }));
  };

  const handleUpdatePrimaryContactDetails = (event) => {
    const {
      target: { name, value },
    } = event;

    setPrimaryContactDetails((currentState) => ({
      ...currentState,
      credential: {
        ...credential,
        [name]: value,
      },
    }));
  };

  const handleUpdatePrimaryContact = (event) => {
    const {
      target: { name, value },
    } = event;

    setPrimaryContactDetails((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };

  const confirmButtonText = () => {
    if (isCreateNewGroup) {
      return 'Add';
    }

    if (isEdit && !isGroupCreationRequest) {
      return 'Update';
    }

    if (isGroupCreationRequest) {
      return 'Ok';
    }
  };

  const handleGroupCreationRequest = () => {
    if (action === 'approve') {
      sendGroupRequestMutation({
        variables: {
          approved: true,
          requestId: groupId,
          name: newGroup.name,
        },
      }).then(() => {
        getGroups({
          variables: {
            projectId,
          },
        });
      });
    } else if (action === 'decline') {
      sendGroupRequestMutation({
        variables: {
          approved: false,
          deleteOnDecline: true,
          reason: declineReason,
          requestId: groupId,
        },
      }).then(() => {
        onDelete('REQUEST');
      });
    } else if (action === 'move') {
      sendGroupRequestMutation({
        variables: {
          approved: false,
          deleteOnDecline: false,
          reason: declineReason,
          requestId: groupId,
        },
      });

      moveUser({
        variables: {
          groupId: selectedDestinationGroup,
          projectId,
          userId: creatorId,
          userTypeId: selectedDestinationUserType,
        },
      }).then(() => {
        getGroups({
          variables: {
            projectId,
          },
        });
      });
    }
  };

  const handleUpdate = () => {
    if (isGroupCreationRequest) {
      handleGroupCreationRequest();
      return;
    }

    if (isCreateNewGroup) {
      setSendRequest(true);
      return;
    }

    if (selectedTab === TABS.notes) {
      notesRef.current.addOrUpdateNote();
      return;
    }

    if (newGroupType) {
      updateGroupType({
        variables: {
          groupId,
          groupTypeId: newGroupType,
        },
      });
    }

    if (isEdit) {
      updateUserAsAdmin({
        variables: {
          communityName,
          email,
          firstname,
          lastname,
          phoneNumber: primaryContactPhoneNumber,
          userId: creatorId,
        },
      });

      updateSmartCodes({
        variables: {
          smartCodes: smartCodes.map(({ code, smartCodeId: id }) => ({
            code,
            id,
          })),
        },
      });

      const newAddressDetails = {
        city,
        country,
        line1,
        line2,
        line3: '',
        postcode: postCode,
        state,
      };

      const newGroupDetails = {
        description,
        identifier,
        groupId,
        name: groupName,
        phoneNumber,
        status,
        userFlowId,
        website,
      };

      updateGroup({
        variables: {
          address: newAddressDetails,
          group: newGroupDetails,
        },
      });

      if (groupId && hasModuleProgression) {
        updateCustomFields({
          variables: {
            responses: customFields.map(({ customFieldId, text }) => ({
              customFieldId,
              groupId,
              text,
            })),
          },
        });

        if (groupAccessMode) {
          updateUserFlowAccessMode({
            variables: {
              groupId,
              mode: groupAccessMode,
            },
          });
        }
      }
    }
  };

  const handleCreateError = (error) => {
    setErrorMessage(error);
    setSendRequest(false);
  };

  const handleCreateSuccess = (data) => {
    setSendRequest(false);
    onCancel('create', data);
  };

  const handleCustomFieldValueInputs = (event) => {
    const {
      target: { name, value },
    } = event;

    setCustomFields((prevFields) =>
      prevFields.map((field) =>
        field.customFieldName === name
          ? {
              ...field,
              text: value,
            }
          : field,
      ),
    );
  };

  const handleDelete = () => {
    if (selectedTab === TABS.notes) {
      notesRef.current.deleteNote();
    } else {
      sendDeleteGroupMutation({
        variables: {
          groupId,
        },
      }).then(() => {
        onDelete('GROUP');
      });
    }
  };

  const handleMsgDisappear = () => {
    setTimeout(() => {
      setMessage({ text: '', type: '' });
    }, 500);
  };

  const handleChangeSmartCode = (event) => {
    const {
      target: { name, value },
    } = event;

    setSmartCodes((currentSmartCodes) =>
      currentSmartCodes.map((smartCode) =>
        String(smartCode.smartCodeId) === name
          ? {
              ...smartCode,
              code: value,
            }
          : smartCode,
      ),
    );
  };

  const handleStatusInput = (value) => {
    setNewGroup({
      ...newGroup,
      status: value ? 'ACTIVE' : 'INACTIVE',
    });
  };

  useEffect(() => {
    if (!groupId) {
      return;
    }

    getCustomFieldsRequest();
    getGroupTypesQuery();
    if (!isGroupCreationRequest) {
      getGroup({
        variables: {
          groupId,
        },
      });
    }
  }, [groupId]);

  const item0 = (
    <>
      <Field id="name" label="Title">
        <TextField
          feedbackText={newGroup.flag}
          isWarning={newGroup.flag}
          name="name"
          value={groupName}
          onChange={handleUpdateGroupAddress}
        />
      </Field>
      {isGroupCreationRequest && (
        <>
          <Field id="actions" label="Actions">
            <Dropdown
              availableResponses={[
                {
                  id: 0,
                  value: 'approve',
                  label: 'Approve',
                },
                {
                  id: 1,
                  value: 'decline',
                  label: 'Decline',
                },
                {
                  id: 2,
                  value: 'move',
                  label: 'Activate account in an existing group',
                },
              ]}
              name="actions"
              selectedValue={action}
              onChangeHandler={(_, value) => setAction(value)}
            />
          </Field>
          {action === 'decline' && (
            <Field id="declineReason" label="Reason">
              <TextField value={declineReason} onChange={(e) => setDeclineReason(e.target.value)} />
            </Field>
          )}
          {action === 'move' && (
            <>
              <Field id="existing-groups" label="Select the group">
                <Dropdown
                  availableResponses={availableGroups}
                  name="actions"
                  selectedValue={selectedDestinationGroup}
                  onChangeHandler={(_, value) => setSelectedDestinationGroup(value)}
                />
              </Field>
              <Field id="existing-types" label="Select the user type">
                <Dropdown
                  availableResponses={availableUserTypes}
                  name="actions"
                  selectedValue={selectedDestinationUserType}
                  onChangeHandler={(_, value) => setSelectedDestinationUserType(value)}
                />
              </Field>
            </>
          )}
        </>
      )}
      {isEdit &&
        !isGroupCreationRequest &&
        smartCodes.map((smartCode) => {
          const { smartCodeId, userType, code } = smartCode || {};
          const { name: userTypeName } = userType || {};

          return (
            <Field key={`smart-code-${smartCodeId}`} id={String(smartCodeId)} label={userTypeName}>
              <TextField name={String(smartCodeId)} value={code} onChange={handleChangeSmartCode} />
            </Field>
          );
        })}
      {!isGroupCreationRequest && !isCreateNewGroup && (
        <Field id="status" label="Active">
          <SwitchBtn defaultChecked={newGroup.status === 'ACTIVE'} onChange={handleStatusInput} name="status" />
        </Field>
      )}
      {!isGroupCreationRequest && groupTypes && groupTypes.length > 0 && (
        <Field id="identifier" label="Group type">
          <Dropdown
            availableResponses={groupTypes}
            name="group-types"
            selectedValue={newGroupType ?? (newGroup.groupType ? newGroup.groupType.id : 0)}
            onChangeHandler={(_, value) => setNewGroupType(value)}
          />
        </Field>
      )}
      {!isGroupCreationRequest &&
        customFields.map((customField) => {
          const { customFieldId, customFieldName, text } = customField;
          return (
            <Field key={`custom-field-${customFieldId}`} id={customFieldId} label={customFieldName}>
              <TextField name={customFieldName} value={text} onChange={handleCustomFieldValueInputs} />
            </Field>
          );
        })}
      {hasModuleProgression && Boolean(hasModuleProgression) && !isCreateNewGroup && (
        <Field id="module-access" label="Module access">
          <Dropdown
            availableResponses={[
              {
                id: 0,
                value: 'SEQUENTIAL',
                label: 'Sequential',
              },
              {
                id: 1,
                value: 'BROWSING',
                label: 'Browsing',
              },
            ]}
            name="access-mode"
            selectedValue={groupAccessMode}
            onChangeHandler={(_, value) => setGroupAccessMode(value)}
          />
        </Field>
      )}
    </>
  );

  const contactTab = isGroupCreationRequest ? (
    <>
      <Field id="line1" label="Line 1">
        <TextField name="line1" value={address} isDisabled />
      </Field>
      <Field id="city" label="City">
        <TextField name="city" value={requestCity} isDisabled />
      </Field>
      <Field id="state" label="State">
        <TextField name="state" value={requestState} isDisabled />
      </Field>
      <Field id="postCode" label="Postcode">
        <TextField name="postCode" value={requestPostCode} isDisabled />
      </Field>
      <Field id="website" label="Website">
        <TextField name="website" value={website} isDisabled />
      </Field>
      <Field id="phoneNumber" label="Phone number">
        <TextField name="phoneNumber" value={phoneNumber} isDisabled />
      </Field>
      <div style={{ fontWeight: 'bold', marginTop: '1rem' }}>Primary contact</div>
      <Field id="primaryContactFirstName" label="First name">
        <TextField name="firstname" value={firstname} onChange={handleUpdatePrimaryContact} />
      </Field>
      <Field id="primaryContactLastName" label="Last name">
        <TextField name="lastname" value={lastname} onChange={handleUpdatePrimaryContact} />
      </Field>
      <Field id="alias" label="Alias">
        <TextField name="communityName" value={communityName} onChange={handleUpdatePrimaryContact} />
      </Field>
      <Field id="primaryContactEmail" label="Email">
        <TextField name="email" value={email} onChange={handleUpdatePrimaryContactDetails} />
      </Field>
      <Field id="primaryContactPhoneNumber" label="Phone number">
        <TextField name="phoneNumber" value={primaryContactPhoneNumber} onChange={handleUpdatePrimaryContactDetails} />
      </Field>
    </>
  ) : (
    <>
      <Field id="line1" label="Line 1">
        <TextField name="line1" value={line1} onChange={handleUpdateActiveAddress} />
      </Field>
      <Field id="line2" label="Line 2">
        <TextField name="line2" value={line2} onChange={handleUpdateActiveAddress} />
      </Field>
      <Field id="city" label="City">
        <TextField name="city" value={city} onChange={handleUpdateActiveAddress} />
      </Field>
      <Field id="state" label="State">
        <TextField name="state" value={state} onChange={handleUpdateActiveAddress} />
      </Field>
      <Field id="postCode" label="Postcode">
        <TextField name="postCode" value={postCode} onChange={handleUpdateActiveAddress} />
      </Field>
      <Field id="website" label="Website">
        <TextField name="website" value={website} onChange={handleUpdateGroupAddress} />
      </Field>
      <Field id="phoneNumber" label="Phone number">
        <TextField name="phoneNumber" value={phoneNumber} onChange={handleUpdateGroupAddress} />
      </Field>
      <div style={{ fontWeight: 'bold', marginTop: '1rem' }}>Primary contact</div>
      <Field id="primaryContactFirstName" label="First name">
        <TextField name="firstname" value={firstname} onChange={handleUpdatePrimaryContact} />
      </Field>
      <Field id="primaryContactLastName" label="Last name">
        <TextField name="lastname" value={lastname} onChange={handleUpdatePrimaryContact} />
      </Field>
      <Field id="alias" label="Alias">
        <TextField name="communityName" value={communityName} onChange={handleUpdatePrimaryContact} />
      </Field>
      <Field id="primaryContactEmail" label="Email">
        <TextField name="email" value={email} onChange={handleUpdatePrimaryContactDetails} />
      </Field>
      <Field id="primaryContactPhoneNumber" label="Phone number">
        <TextField name="phoneNumber" value={primaryContactPhoneNumber} onChange={handleUpdatePrimaryContactDetails} />
      </Field>
    </>
  );

  const item2 = Boolean(groupId) && (
    <Note ref={notesRef} entityId={groupId} entityType={EntityTypeKeys.Group} errorMessageHandler={setMessage} />
  );

  const items = [
    {
      confirmButtonText: confirmButtonText(),
      content: item0,
      hasConfirmButton: true,
      id: 0,
      title: 'Information',
      visibleOnCreation: true,
      visibleOnRequest: true,
    },
    {
      confirmButtonText: confirmButtonText(),
      content: contactTab,
      hasConfirmButton: true,
      id: 1,
      title: 'Contact',
      visibleOnCreation: true,
      visibleOnRequest: true,
    },
    {
      confirmButtonText: 'Save',
      content: item2,
      hasConfirmButton: true,
      id: 2,
      title: 'Notes',
      visibleOnCreation: false,
      visibleOnRequest: false,
    },
  ];

  const groupCreationRequestDeleteComps = isGroupCreationRequest && (
    <Form name="member">
      <Field id="declineReason" label="Decline reason">
        <TextField value={declineReason} onChange={(e) => setDeclineReason(e.target.value)} />
      </Field>
    </Form>
  );

  useEffect(() => {
    if (!hasModuleProgression) {
      return;
    }

    if (!groupId) {
      return;
    }

    getAccessMode({
      variables: {
        groupId,
      },
    });
  }, [groupId, hasModuleProgression]);

  const isLoading =
    isActivateUserLoading ||
    isGetAccessModeLoading ||
    isGetCustomFieldsRequestLoading ||
    isGetGroupsLoading ||
    isGetGroupLoading ||
    isGetGroupTypesQueryLoading ||
    isMoveUserLoading ||
    isSendDeleteGroupMutationLoading ||
    isSendGroupRequestMutationLoading ||
    isUpdateSmartCodesLoading ||
    isUpdateUserFlowAccessModeLoading ||
    isUpdateCustomFieldsLoading ||
    isUpdateGroupTypeLoading ||
    isUpdateGroupLoading ||
    isUpdateUserAsAdminLoading;

  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }

  const deleteBtnName = isGroupCreationRequest ? 'Decline' : 'Delete';
  return (
    <>
      <Toaster toastOptions={DEFAULT_TOAST_OPTIONS} />
      <RightNavBar
        confirmationMessage={
          isGroupCreationRequest ? 'Are you sure you want to decline the request?' : 'Are you sure you want to delete this record?'
        }
        confirmBtnName={items.find((r) => r.id === selectedTab).confirmButtonText}
        deleteBtnName={isCreateNewGroup ? null : deleteBtnName}
        deleteComps={groupCreationRequestDeleteComps}
        hasConfirmButton={items.find((r) => r.id === selectedTab).hasConfirmButton}
        hasDelete={isEdit && !isGroupCreationRequest}
        onCancel={onCancel}
        onDelete={handleDelete}
        onSave={handleUpdate}
      >
        <Form name="member">
          <FormHeader title={title} />
          <SidebarTab items={isCreateNewGroup ? [items[0]] : items} selectedTab={setSelectedTab} />
        </Form>
        {message.type !== '' && <PopUpMsg message={message.text} msgDisappear={handleMsgDisappear} type={message.type} />}
      </RightNavBar>
      {sendRequest && isCreateNewGroup && (
        <CreateGroup
          description={newGroup.name}
          name={newGroup.name}
          projectId={projectId}
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
        />
      )}
    </>
  );
};

ProjectUserGroupSideBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      title: PropTypes.string,
    }),
    userId: PropTypes.number,
  }),
};

ProjectUserGroupSideBar.defaultProps = {
  group: {},
};

export default ProjectUserGroupSideBar;
