import React from 'react';

import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import MutationRequest from '../MutationRequest';

const executeReportRequest = ({
  mutation,
  reportId,
  onCreateError,
  onSuccessResult,
}) => (
  <Mutation mutation={mutation}>
    {(executeReport, { loading, error, data }) => {
      const variables = {
        reportId,
        onCreateError,
        onSuccessResult,
      };

      if (error) {
        if (error.networkError.result) {
          onCreateError(error.networkError.result.messages);
        } else {
          onCreateError(error.messages);
        }
      }
      if (data) {
        onSuccessResult(data.report.executeReport);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={executeReport}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

executeReportRequest.propTypes = {
  mutation: PropTypes.string.isRequired,
  onCreateError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  reportId: PropTypes.number.isRequired,
};

export default executeReportRequest;
