import React from 'react';

const Rectangle = ({ bubbleRef, top, left, width, bubbleShapeBaseStyles, isTailVisible, bubbleTailWidth, children, question, angle }) => (
  <div
    ref={bubbleRef}
    className="c-cartoon__image c-cartoon__rectangle"
    style={{
      ...bubbleShapeBaseStyles,
      top: `${top}%`,
      left: `${left}%`,
      width: `${width}%`,
    }}
  >
    {isTailVisible && (
      <div
        className="c-rectangle-tail"
        style={{
          borderTop: `${bubbleTailWidth / 2 + 50}px solid #fff`,
          transform: `translate(-50%, 0) rotate(${angle})`,
        }}
      />
    )}
    <div className="l-position--relative">
      {children}
      {Boolean(question) && (
        <p>
          <strong className="h-color--brand-secondary">{question}</strong>
        </p>
      )}
    </div>
  </div>
);
export { Rectangle };
