import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CONTACT_USER = gql`
  mutation ContactUser(
    $firstname: String!
    $lastname: String!
    $phoneNumber: String!
    $email: String!
    $content: String!
  ) {
    user {
      contactUser(
        contactUser: {
          firstname: $firstname
          lastname: $lastname
          phoneNumber: $phoneNumber
          email: $email
          content: $content
        }
      )
    }
  }
`;

const contactUserRequest = ({
  firstname,
  lastname,
  phoneNumber,
  email,
  content,
  onError,
}) => (
  <Mutation mutation={CONTACT_USER}>
    {(contactUser, { loading, error, data }) => {
      const variables = {
        firstname,
        lastname,
        phoneNumber,
        email,
        content,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        return <Redirect to="pricing-thank-you" />;
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={contactUser}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

contactUserRequest.propTypes = {
  content: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};
export default contactUserRequest;
