export const getAssetsUrl = () => {
  switch (window.location.hostname) {
    case 'dev.ekardo.com':
    case 'www.dev.ekardo.com':
    case 'd3qmyy32beirop.cloudfront.net':
      return 'https://ekardo-assets-dev.s3-ap-southeast-2.amazonaws.com';
    case 'staging.ekardo.com':
    case 'www.staging.ekardo.com':
    case 'd2vqyzkvyekhql.cloudfront.net':
      return 'https://ekardo-assets-stg.s3-ap-southeast-2.amazonaws.com';
    case 'www.ekardo.com':
    case 'ekardo.com':
    case 'd2db6xebd5azjy.cloudfront.net':
    case 'backoffice.ffsp.com.au':
      return 'https://ekardo-assets-prod.s3-ap-southeast-2.amazonaws.com';
    default:
      return 'https://dev.assets.ekardo.com';
  }
};

export const errorMessageEnterTimeout = 500;
export const errorMessageLeaveTimeout = 300;
