import gql from 'graphql-tag';

export const GET_CHARACTERS_BY_PROJECT = gql`
  query getCharacters($projectId: String!) {
    character {
      getByProject(projectId: $projectId) {
        id
        imageId
        name
      }
    }
  }
`;
