import moment from 'moment';

import { DateRangePresetsProps } from './DateRangePicker.types';

export const getRangePresets = () => {
  const today = moment();
  const yesterday = moment().subtract(1, 'day');

  const presets: DateRangePresetsProps[] = [
    {
      text: 'Yesterday',
      start: yesterday,
      end: yesterday,
    },
    {
      text: 'Last Week',
      start: moment().subtract(1, 'week'),
      end: today,
    },
    {
      text: 'Last Month',
      start: moment().subtract(1, 'month'),
      end: today,
    },
    {
      text: 'Last Year',
      start: moment().subtract(1, 'year'),
      end: today,
    },
  ];

  return presets;
};
