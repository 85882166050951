import React from 'react';

import { EmbedSnippetProps } from './EmbedSnippet.types';

import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

import './EmbedSnippet.css';

const EmbedSnippet = ({ embed = '', title }: EmbedSnippetProps) => {
  return (
    <div className="c-embed-snippet">
      <DOMPurifySanitizedHtmlContent
        config={{
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
        }}
        html={embed}
      />
      {title ? <div className="c-embed-snippet__title">{title}</div> : null}
    </div>
  );
};

export { EmbedSnippet };
