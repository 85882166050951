import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Dropdown from '../Dropdown/Dropdown';
import Editor from '../TextEditor/Editor';
import Tooltip from '../Tooltip/Tooltips';

import { Container, Item, Title } from '../../RightNavBar/styled';

const formResponseMessages = [
  {
    id: 1,
    label: 'Correct response message',
    tooltipText: 'Message defining the correct answer of the question',
    value: 'correct',
  },
  {
    id: 2,
    label: 'Incorrect response message',
    tooltipText: 'Message defining the incorrect answer of the question',
    value: 'incorrect',
  },
  {
    id: 3,
    label: 'Incomplete response message',
    tooltipText: 'Message defining the incomplete answer of the question',
    value: 'incomplete',
  },
  {
    id: 4,
    label: 'Example response message',
    tooltipText: 'Message defining the example answer of the question',
    value: 'example',
  },
];

const MessagePart = props => {
  const { handleUpdate, messages } = props;

  const [selectedValue, setSelectedValue] = useState(formResponseMessages[0].value);

  const handleSelectedMessageChanged = value => {
    setSelectedValue(value);
  };

  const getEditorComponent = id => {
    switch (id) {
      case 1:
        return <Editor getContent={value => handleUpdate(value, selectedValue)} value={messages.correct} />;
      case 2:
        return <Editor getContent={value => handleUpdate(value, selectedValue)} value={messages.incorrect} />;
      case 3:
        return <Editor getContent={value => handleUpdate(value, selectedValue)} value={messages.incomplete} />;
      case 4:
        return <Editor getContent={value => handleUpdate(value, selectedValue)} value={messages.example} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Dropdown
        availableResponses={formResponseMessages}
        name="formMessages"
        selectedValue={selectedValue}
        onChangeHandler={(_, value) => handleSelectedMessageChanged(value)}
      />
      <Container>
        {/* TODO: Need to do this differently as we shouldn't be using map to display just one item */}
        {formResponseMessages.map(
          item =>
            item.value === selectedValue && (
              <Item>
                <Title>
                  {item.label}
                  <Tooltip text={item.tooltipText} />
                </Title>
                {getEditorComponent(item.id)}
              </Item>
            ),
        )}
      </Container>
    </>
  );
};

MessagePart.propTypes = {
  messages: PropTypes.shape({}).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default MessagePart;
