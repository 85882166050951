import styled from 'styled-components';

export const AdvanceContainer = styled.div`
  overflow-y: scroll;
  padding: 0 1em;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 8px 4px 15px;
  position: relative;
`;

export const AdvanceItem = styled.div`
  margin-bottom: 20px;
  width: ${props => (props.width ? props.width : '50%')};
`;

export const ColorItem = styled(AdvanceItem)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const AdvanceTitle = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  width: 100%;
`;

export const ColorTitle = styled(AdvanceTitle)`
  margin-left: 1em;
`;

export const EditBtn = styled.button`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const Preview = styled.div`
  margin: 0 0 2em 35%;
  max-width: 30%;
  position: relative;

  &:hover {
    ${EditBtn} {
      display: block;
    }
  }
`;
