import gql from 'graphql-tag';

export const GET_PROJECT_STEPS = gql`
  query ($projectId: String!) {
    userFlowStep {
      getStepsOfProject(projectId: $projectId) {
        id
        contentGroupId
        stepName
      }
    }
  }
`;
