import React, { useEffect, useState } from 'react';

import AudioControls from './AudioControls';
import { FactCheck, StopAndThink } from './BubbleTypes';
import CartoonAsset from './CartoonAsset';
import QuizButton from './QuizButton';
import QuizFeedback from './QuizFeedback';
import SpeechBubble, { TextMessage } from './SpeechBubble';

import { useWindowSize } from '../../hooks/useWindowSize';
import { createKey } from '../../utils';

import './cartoonContent.css';
import gql from 'graphql-tag';

const CartoonPdf = (props) => {
  const { contentPage } = props;

  const MULTI_RESPONSE_SYMBOL = '_::?::_';
  const IS_CORRECT_RESPONSE = '_::C::_';
  const QUIZ_QUESTION_SPLIT = '_::Q::_';
  const QUIZ_ANSWER_SPLIT = '_::N::_';

  const { windowSize } = useWindowSize();

  const [isCartoonLoading, setIsCartoonLoading] = useState(true);
  const [hasQuizResponse, setHasQuizResponse] = useState(false);
  const [isLargerDevice, setIsLargerDevice] = useState(window.innerWidth);
  const [isMultiResponse, setIsMultiResponse] = useState(false);
  const [isQuizCorrect, setIsQuizCorrect] = useState(false);
  const [question, setQuestion] = useState();
  const [quizResponseFeedback, setQuizResponseFeedback] = useState('');
  const [selectedQuiz, setSelectedQuiz] = useState({
    index: null,
    text: '',
  });

  const getQuizIndex = (index) => String.fromCharCode('a'.charCodeAt(0) + index);

  const handleQuizSelect = (quizData, index, text, isCorrectResponse) => {
    let updatedIsQuizCorrect = false;

    if (isMultiResponse) {
      updatedIsQuizCorrect = isCorrectResponse;
    } else {
      updatedIsQuizCorrect = index + 1 === parseInt(quizData.parts[1], 10);
    }

    setHasQuizResponse(true);
    setIsQuizCorrect(updatedIsQuizCorrect);
    setSelectedQuiz({
      index,
      text,
    });
    setQuizResponseFeedback(updatedIsQuizCorrect ? quizData.parts[2] : quizData.parts[3]);
  };

  useEffect(() => {
    setIsLargerDevice(true);
  }, [windowSize]);

  const { asset, audio, bubbleTexts: bubbles = [] } = contentPage;
  const { contentType, s3Key, isAnimatedImage } = asset || {};
  const { presignedUrl } = audio || {};
  const bubble = bubbles[0] || {};

  const isQuiz = bubble.shape === 'QUIZ';

  const quizData = {
    options: [],
    parts: [],
    question: '',
    style: undefined,
  };

  if (isQuiz) {
    const quizParts = contentPage.bubbleTexts[0].text.split(QUIZ_ANSWER_SPLIT);
    const quizOptions = [...quizParts[0].split(QUIZ_QUESTION_SPLIT)];

    quizData.options = quizOptions.slice(1);
    quizData.parts = quizParts;
    quizData.question = quizOptions[0];
    quizData.style = quizParts[4] ? JSON.parse(quizParts[4].replace(/'/g, '"')) : undefined;
  }

  const quizButtons = React.Children.toArray(
    quizData.options.map((text, index) => {
      const isCorrectResponse = text.includes(IS_CORRECT_RESPONSE);
      const textWithoutSymbol = text.replace(IS_CORRECT_RESPONSE, '');

      return (
        <QuizButton
          identifier={getQuizIndex(index)}
          text={textWithoutSymbol}
          onClick={() => handleQuizSelect(quizData, index, textWithoutSymbol, isCorrectResponse)}
        />
      );
    }),
  );

  const quizFeedback = (
    <QuizFeedback
      feedback={quizResponseFeedback}
      identifier={getQuizIndex(selectedQuiz.index)}
      isCorrect={isQuizCorrect}
      text={selectedQuiz.text}
    />
  );

  const quizComponentToDisplay = hasQuizResponse ? quizFeedback : quizButtons;

  useEffect(() => {
    setIsMultiResponse(quizData.question.includes(MULTI_RESPONSE_SYMBOL));
    if (quizData.question) {
      setQuestion(quizData.question.split('_::_')[1]);
    }
  }, [quizData.question]);

  return (
    <>
      {asset ? (
        <section className="l-position--relative h-background--color-grey-300">
          <div className="l-position--relative h-with-2x-large-y-padding " style={{ pageBreakAfter: 'always' }}>
            <div className="l-position--relative h-with-y-margin">
              <div className="l-position--relative l-flex l-flex--align-center">
                {!isCartoonLoading && (
                  <>
                    {question && (
                      <SpeechBubble
                        key={bubble.sort}
                        hasShadow={bubble.hasShadow}
                        left={bubble.x}
                        question={question}
                        top={bubble.y}
                        type={bubble.shape}
                        width={bubble.w}
                      />
                    )}
                    {isLargerDevice && isQuiz && (
                      <div
                        className="c-quiz--desktop"
                        style={
                          quizData.style
                            ? {
                                left: `${quizData.style.x}%`,
                                top: `${quizData.style.y}%`,
                                width: `${quizData.style.w}%`,
                              }
                            : {
                                bottom: '1rem',
                                right: '2rem',
                                width: '40%',
                              }
                        }
                      >
                        {quizComponentToDisplay}
                      </div>
                    )}
                    {isLargerDevice &&
                      !isQuiz &&
                      bubbles.map(({ id, text, angle, y, x, shape, tailType, isTailVisible, w, hasShadow = false }) => (
                        <>
                          <SpeechBubble
                            key={`speech-bubble-${id}`}
                            angle={angle}
                            hasShadow={hasShadow}
                            isTailVisible={isTailVisible}
                            left={x}
                            tailType={tailType}
                            top={y}
                            type={shape}
                            width={w}
                          >
                            <div
                              className="c-quiz-button__text"
                              dangerouslySetInnerHTML={{
                                __html: text,
                              }}
                            />
                            {shape === 'FACT_CHECK' && <FactCheck text={text} />}
                            {(shape === 'STOP_AND_THINK' || shape === 'STOP_AND_THINK_NO_INPUT') && (
                              <StopAndThink shouldDisplayInputField={shape === 'STOP_AND_THINK'} text={text} />
                            )}
                          </SpeechBubble>
                        </>
                      ))}
                  </>
                )}
                {s3Key && contentType && (
                  <CartoonAsset
                    contentType={contentType}
                    isAnimatedImage={isAnimatedImage}
                    s3Key={s3Key}
                    onLoadHandler={() => setIsCartoonLoading(false)}
                  />
                )}
              </div>
            </div>
            {!isLargerDevice && isQuiz && quizComponentToDisplay}
            {!isLargerDevice &&
              !isQuiz &&
              bubbles.map(({ text }, key) => (
                <TextMessage key={`small-screen-text-bubble-${createKey(text)}`} isReceived={key % 2 === 1} text={text} />
              ))}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default CartoonPdf;
