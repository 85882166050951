import { useDomain } from '@netfront/gelada-identity-library';

import { USE_IDENTITY_SITE_URLS_CONSTANTS, LOCALHOST, PROTOCOLS } from './useIdentitySiteUrls.constants';

const useIdentitySiteUrls = (options) => {
  const { environment, port } = options ?? {};

  const { HTTP, HTTPS } = PROTOCOLS;
  const {
    ROUTES: { FORGOT_PASSWORD, IMPERSONATED_USER_LOGOUT, LOGIN, LOGOUT, PROFILE, REGISTER },
  } = USE_IDENTITY_SITE_URLS_CONSTANTS;

  const { getDomain } = useDomain();

  const getBaseUrl = () => {
    const domain = getDomain();

    if (domain === LOCALHOST) {
      const portSuffix = port ? `:${port}` : '';

      return `${HTTP}${domain}${portSuffix}`;
    }

    const environmentPrefix = environment ? `${environment}.` : '';

    return `${HTTPS}${environmentPrefix}identity.${domain}`;
  };

  const getForgotPasswordRoute = () => FORGOT_PASSWORD;

  const getForgotPasswordUrl = () => `${getBaseUrl()}${getForgotPasswordRoute()}`;

  const getImpersonatedUserLogoutRoute = () => IMPERSONATED_USER_LOGOUT;

  const getImpersonatedUserLogoutUrl = () => `${getBaseUrl()}${getImpersonatedUserLogoutRoute()}`;

  const getLoginRoute = () => LOGIN;

  const getLoginUrl = () => `${getBaseUrl()}${getLoginRoute()}`;

  const getLogoutRoute = () => LOGOUT;

  const getLogoutUrl = () => `${getBaseUrl()}${getLogoutRoute()}`;

  const getProfileRoute = () => PROFILE;

  const getProfileUrl = () => `${getBaseUrl()}${getProfileRoute()}`;

  const getRegistrationRoute = () => REGISTER;

  const getRegistrationUrl = () => `${getBaseUrl()}${getRegistrationRoute()}`;

  return {
    getBaseUrl,
    getForgotPasswordRoute,
    getForgotPasswordUrl,
    getImpersonatedUserLogoutRoute,
    getImpersonatedUserLogoutUrl,
    getLoginRoute,
    getLoginUrl,
    getLogoutRoute,
    getLogoutUrl,
    getProfileRoute,
    getProfileUrl,
    getRegistrationRoute,
    getRegistrationUrl,
  };
};

export { useIdentitySiteUrls };
