import gql from 'graphql-tag';

export const GET_ACTION_DETAILS = gql`
  query getActionDetails($actionId: Int!) {
    questionAction {
      get(actionId: $actionId) {
        id
        ... on QuestionActionShowSectionType {
          sectionId
          title
        }
        ... on QuestionActionHideSectionType {
          sectionId
          title
        }
        ... on QuestionActionHideContainerType {
          containerId
          title
        }
        ... on QuestionActionShowContainerType {
          containerId
          title
        }
        ... on QuestionActionShowType {
          targetSnippetId
          title
        }
        ... on QuestionActionHideType {
          targetSnippetId
          title
        }

        ... on QuestionActionSkipPageType {
          contentPageId
          title
        }

        ... on QuestionActionTriggerContentEventType {
          title
          contentEventId
          type
        }
        conditions {
          id
          title
          contentPageId
          contentSnippetQuestionId
          isTemplate
          ... on QuestionConditionRadioResponseSelectedType {
            questionResponseRadioId
            selection
          }
          ... on QuestionConditionTextType {
            text
          }
          ... on QuestionConditionSliderValueSelectedType {
            maxValue
            minValue
          }
          ... on QuestionConditionCheckboxSelectedType {
            questionResponseCheckboxId
            selection
          }

          ... on QuestionConditionDateSelectedType {
            dateRangeType: type
            minDate
            maxDate
            minTimeSpan
            maxTimeSpan
            selectedDate
          }
        }
      }
    }
  }
`;
