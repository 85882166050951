import React from 'react';

import { ConfirmationMessages, RangeInput } from '@netfront/ui-library';

import { getAriaDescribedBy } from '../Snippets.helpers';

import { RangeInputSnippetProps } from './RangeInputSnippet.interfaces';

const RangeInputSnippet = ({ describedById, id, message, maxValue, minValue, onChange, selectedValues, ticks }: RangeInputSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message ?? {};

  const initialValue = selectedValues instanceof Array ? selectedValues[0] : selectedValues;

  const ariaDescribedBy = getAriaDescribedBy(describedById, id, Boolean(errorMessage));

  return (
    <React.Fragment>
      <RangeInput
        aria-describedby={ariaDescribedBy}
        aria-invalid={Boolean(errorMessage)}
        id={id}
        initialValue={initialValue}
        maxValue={maxValue}
        minValue={minValue}
        ticks={ticks}
        onChange={(selectedValue: number) => {
          onChange([selectedValue]);
        }}
      />

      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </React.Fragment>
  );
};

export { RangeInputSnippet };
