import gql from 'graphql-tag';

export const DETACH_STYLE = gql`
  mutation(
    $containerId: Int
    $snippetId: Int
    $sectionId: Int
    $contentPageId: Int
    $contentGroupId: Int
  ) {
    cssStyle {
      detach(
        containerId: $containerId
        snippetId: $snippetId
        sectionId: $sectionId
        contentPageId: $contentPageId
        contentGroupId: $contentGroupId
      )
    }
  }
`;
