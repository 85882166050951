import React from 'react';

import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';

import Clients from '../../components/Clients/Clients';
import ContactConfirmation from '../../components/ContactConfirmation/ContactConfirmation';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import PopupMessage from '../../components/PopupMessage/PopupMessage';
import Registration from '../../components/Registration/Registration';
import {
  getAssetsUrl,
  errorMessageEnterTimeout,
  errorMessageLeaveTimeout,
} from '../../config';
import RegisterRequest from '../../middleware/Account/registerRequest';
import ResendEmailRequest from '../../middleware/Account/resendEmailRequest';
import GetEndpoint from '../../middleware/CustomBuild/getByBackofficeEndpoint';
import fbRed from '../../static/img/fb-red.svg';
import twitterRed from '../../static/img/twitter-red.svg';

const cookies = new Cookies();

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      repeatPassword: '',
      submitted: false,
      errorMessage: '',
      checkTerms: false,
      isThankyou: false,
      resendRequest: false,
      emailSent: false,
      endpoint: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  validate = () => {
    const {
      firstname,
      lastname,
      email,
      password,
      repeatPassword,
      checkTerms,
    } = this.state;

    return {
      FirstnameEmpty: {
        validation: firstname.trim().length > 0,
        errorMessage: 'Firstname must not be empty.',
      },
      LastnameEmpty: {
        validation: lastname.trim().length > 0,
        errorMessage: 'Lastname must not be empty.',
      },
      EmailEmpty: {
        validation: email.trim().length > 0,
        errorMessage: 'Email must not be empty.',
      },
      PasswordsMatch: {
        validation: password === repeatPassword,
        errorMessage: 'Passwords do not match.',
      },
      PasswordsMinLength: {
        validation: password.length >= 6,
        errorMessage: 'Password must have minimum 6 chars.',
      },
      PasswordEmpty: {
        validation: password.trim().length > 0,
        errorMessage: 'Password must not be empty.',
      },
      UnCheckedTerms: {
        validation: checkTerms,
        errorMessage: 'You must agree to the terms.',
      },
    };
  };

  onhandleClearErrorMessages = () => {
    this.setState({
      errorMessage: '',
    });
  };

  onhandleInputs = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onhandleCheck = () => {
    const { checkTerms } = this.state;
    this.setState({
      checkTerms: !checkTerms,
    });
  };

  onhandleSubmit = e => {
    e.preventDefault();
    const validationErrors = ValidationProcessor(this.validate());

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });
    } else {
      // var cookies=new Cookies();
      cookies.remove('_a', { path: '/' });

      this.setState({
        errorMessage: '',
        submitted: true,
      });
    }
  };

  handleRegisterError = message => {
    const { isThankyou } = this.state;

    this.setState({
      submitted: false,
      resendRequest: false,
      errorMessage: message,
    });

    if (isThankyou) {
      setTimeout(() => {
        this.setState({
          errorMessage: '',
        });
      }, 3000);
    }
  };

  onResendButton = () => {
    this.setState({
      resendRequest: true,
    });
  };

  handleRegisterRequest = () => {
    this.setState({
      isThankyou: true,
      submitted: false,
    });
  };

  handleEmailSend = () => {
    this.setState({
      emailSent: true,
      resendRequest: false,
    });

    setTimeout(() => {
      this.setState({
        emailSent: false,
      });
    }, 3000);
  };

  handleOnResponseGetInv = data => {
    this.setState({
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.invitedUserEmail,
    });
  };

  handleEndpointError = () => {
    this.setState({
      endpoint: true,
    });
  };

  handleEndpointSuccess = data => {
    cookies.remove('customBuild', { path: '/' });
    if (data.getByBackofficeEndpoint) {
      // this cookie only use for customBuild project,
      // will remove from Ekardo register
      cookies.set('customBuild', {
        isCustomBuild: true,
        projectId: data.project.getByBackofficeEndpoint.id,
        orgId: data.project.getByBackofficeEndpoint.organisationId,
      });
    }
    this.setState({
      endpoint: true,
    });
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      password,
      repeatPassword,
      submitted,
      errorMessage,
      isThankyou,
      resendRequest,
      emailSent,
      endpoint,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Ekardo: Building accessible and engaging educational content better
          </title>
          <meta
            content="Login to Ekardo to access and manage your projects"
            name="description"
          />
        </Helmet>
        <div className="login-page">
          <div className="login-page__fold">
            <Header location="/register" theme="red" />
            <div className="login-page__container">
              {!isThankyou && (
                <h2
                  className="heading-page"
                  style={{
                    fontSize: '3em',
                  }}
                >
                  GET STARTED
                </h2>
              )}
              <h2 className="heading-page">
                {!isThankyou ? 'TODAY' : 'THANK YOU'}
              </h2>
            </div>
            <div
              className="flex-column text-right pr-3 mb-3 d-none d-lg-flex"
              href="#"
            >
              <div className="mb-2">
                <img alt="fbLogin" height="24px" src={fbRed} width="24px" />
              </div>
              <div className="">
                <img
                  alt="twiterLogin"
                  height="24px"
                  src={twitterRed}
                  width="24px"
                />
              </div>
            </div>
          </div>

          <div className="login-page__card">
            <div className="login-page__col login-page__image">
              <img
                alt="Laptop locked"
                src={`${getAssetsUrl()}/graphics/register.svg`}
              />
            </div>
            <div className="login-page__col text-center d-flex align-items-center">
              <div className="w-100">
                {isThankyou ? (
                  <ContactConfirmation handleResend={this.onResendButton} />
                ) : (
                  <Registration
                    email={email}
                    firstname={firstname}
                    handleCheck={this.onhandleCheck}
                    handleClearErrorMessages={this.onhandleClearErrorMessages}
                    handleInputs={this.onhandleInputs}
                    handleSubmit={this.onhandleSubmit}
                    lastname={lastname}
                    password={password}
                    repeatPassword={repeatPassword}
                  />
                )}
                {errorMessage !== '' && <ErrorMessage message={errorMessage} />}
              </div>
            </div>
          </div>
        </div>
        <Clients />
        <Footer />
        {submitted && (
          <RegisterRequest
            email={email}
            firstname={firstname}
            lastname={lastname}
            onRegisterError={this.handleRegisterError}
            onSuccessRequest={this.handleRegisterRequest}
            password={password}
          />
        )}
        {resendRequest && (
          <ResendEmailRequest
            email={email}
            onError={this.handleRegisterError}
            onSuccessResult={this.handleEmailSend}
          />
        )}
        {!endpoint && (
          <GetEndpoint
            endpoint={window.location.hostname}
            onError={this.handleEndpointError}
            onSuccessResult={this.handleEndpointSuccess}
          />
        )}
        {
          <ReactCSSTransitionGroup
            transitionEnterTimeout={errorMessageEnterTimeout}
            transitionLeaveTimeout={errorMessageLeaveTimeout}
            transitionName="dialog-popup"
          >
            {emailSent && <PopupMessage message="Email Sent" />}
          </ReactCSSTransitionGroup>
        }
      </>
    );
  }
}

RegisterPage.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default RegisterPage;
