import React, { useContext } from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

import AppContext from '../../context/AppContext';

export const SEARCH_SLIDERS = gql`
  query getAllSliders($projectId: String!) {
    slider {
      searchSliders(projectId: $projectId) {
        id
        title
      }
    }
  }
`;

const GetAllSlidersInProject = ({ onError, onSuccessResult }) => {
  const {
    project: { id: projectId },
  } = useContext(AppContext);

  return (
    <Query
      fetchPolicy="network-only"
      query={SEARCH_SLIDERS}
      variables={{ projectId }}
    >
      {({ loading, error, data }) => (
        <QueryRequest
          data={Object.entries(data).length !== 0 && data.slider.searchSliders}
          error={error}
          isLoading={loading}
          onError={onError}
          onSuccessResult={onSuccessResult}
        />
      )}
    </Query>
  );
};
GetAllSlidersInProject.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

// getAllSlidersInProject.defaultProps = {
//   title: '',
// };

export default GetAllSlidersInProject;
