import gql from 'graphql-tag';

export const FRAGMENT_RESPONSE_SET = gql`
  fragment ResponseSetFragment on QuestionResponseSetType {
    id
    title
    availableResponses {
      id
      asset {
        assetId
        presignedUrl
          s3Key
        contentType
      }
      label
      questionResponseSetId
      value
    }
  }
`;
