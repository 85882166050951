import gql from 'graphql-tag';

import { SummaryFragment } from '../../../legacy/middleware/QuestionConfiguration/Fragments/Collection';

export const UPDATE_SUMMARY_SNIPPET = gql`
  mutation ($baseSnippet: UpdateContentSnippetInputType!, $summarySnippet: ContentSnippetSummaryInputType!) {
    contentSnippet {
      updateContentSnippet(baseSnippet: $baseSnippet, contentSnippetSummary: $summarySnippet) {
        id
        sort
        ...summary
      }
    }
  }
  ${SummaryFragment}
`;
