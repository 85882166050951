import { gql } from '@apollo/client';

const GET_USER_FLOW_STEP_CONTENT_PAGES_QUERY = gql`
  query getUserFlowStepContentPages(
    $projectId: String!
    $topLevelOnly: Boolean
    $userFlowStepTrackId: Int!
    $userFlowStepUrl: String!
    $userFlowUrl: String!
  ) {
    userFlowStep {
      getUserFlowStepByUrl: getStepByUrl(projectId: $projectId, url: $userFlowStepUrl, userFlowUrl: $userFlowUrl) {
        contentGroup {
          contentPages(status: [PUBLISHED, UNPUBLISHED], topLevelOnly: $topLevelOnly) {
            ...userFlowStepContentPage
            contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
              ...userFlowStepContentPage
              contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                ...userFlowStepContentPage
                contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                  ...userFlowStepContentPage
                }
              }
            }
          }
          groupingContentGroup {
            description
            id
            name
            url
          }
          id
          title
          url
          title
        }
        description
        id
        isComplete
        lastTracking {
          tag {
            id
            name
            sort
          }
        }
        url
      }
    }
  }

  fragment userFlowStepContentPage on ContentPageType {
    contentPageConfigurationNavigation {
      backButtonToolTip
      enableBackButton
      enableNextButton
      isEndContentGroup
      nextButtonToolTip
    }
    hasBeenViewed
    id
    isCompleted(stepTrackingId: $userFlowStepTrackId)
    sort
    title
    url
  }
`;

export { GET_USER_FLOW_STEP_CONTENT_PAGES_QUERY };
