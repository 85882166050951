import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_SNIPPET_STYLE = gql`
  mutation updateContentSnippetStyle(
    $id: Int!
    $containerId: Int!
    $sort: Int!
    $visible: Boolean!
    $borderColor: String
    $backgroundColor: String # $linkColor: String # $listIconColor: String
  ) {
    contentSnippet {
      updateContentSnippetBase(
        snippet: {
          id: $id
          containerId: $containerId
          sort: $sort
          visible: $visible
          borderColor: $borderColor
          backgroundColor: $backgroundColor
        }
      ) {
        borderColor
        backgroundColor
      }
    }
  }
`;

const updateSnippetStyle = ({ id, containerId, sort, visible, borderColor, backgroundColor, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_SNIPPET_STYLE}>
    {(updateContentSnippetStyle, { loading, error, data }) => {
      const variables = {
        id,
        containerId,
        sort,
        visible,
        borderColor,
        backgroundColor,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippetBase);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippetStyle} variables={variables} />;
    }}
  </Mutation>
);

updateSnippetStyle.propTypes = {
  containerId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  visible: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
};

updateSnippetStyle.defaultProps = {
  backgroundColor: null,
  borderColor: null,
};

export default updateSnippetStyle;
