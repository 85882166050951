import gql from 'graphql-tag';

export const GET_ALL_PROJECT_DISCOUNTS = gql`
  query ($projectGuid: String!) {
    discounts {
      getAllProjectDiscounts(projectGuid: $projectGuid) {
        code
        createdAtUtc
        description
        expiresAtUtc
        id
        maxUsage
        modifiedAtUtc
        percentage
        projectGuid
        startsAtUtc
        status
      }
    }
  }
`;
