import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { useDomain } from '@netfront/gelada-identity-library';
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Toolbar,
  SearchInput,
  FilterBox,
  FilterBtn,
  FilterOpt,
  IconWrapper,
  AddNewBtn,
  AssetsWrapper,
  ImgBox,
  AssetImg,
  VideoBox,
  AssetVideo,
  AudioBox,
  FileName,
  FileBox,
  EditBtnBox,
  DisplayAsset,
  FavBtn,
  FavSwitch,
  LoadingBox,
} from './styled';

import { DropDownMenu } from '../../components/DropDownMenu/DropDownMenu';
import Icon from '../../components/Icon/Icon';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import EditAssetSideBar from '../../components/RightNavBar/EditAssetSideBar';
import AudioPlayer from '../../components/UI/AudioPlayer/AudioPlayer';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import EditBtn from '../../components/UI/Button/EditButton';
import NoDataAlerts from '../../components/UI/NoDataComp/NoDataAlert';
import Pagination from '../../components/UI/Pagination/Pagination';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls } from '../../hooks';
import AddAsset from '../../middleware/Asset/createAssetRequest';
import AddAssetToFavRequest from '../../middleware/AssetLibrary/addAssetToFavRequest';
import GetAllFavAssetsRequest from '../../middleware/AssetLibrary/getAllFavAssetsRequest';
import { GET_PAGINATED_ASSETS } from '../../middleware/AssetLibrary/getPaginatedAssets';
import RemoveAssetToFavRequest from '../../middleware/AssetLibrary/removeAssetToFavRequest';
import { deleteObject } from '../../middleware/AWS/deleteAWSObject';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import client from '../../middleware/client';
import { capitalize, DEFAULT_PAGE_SIZE } from '../../utils/utils';

const LibraryAssetPageNew = () => {
  const params = useParams();
  const { projectId, type, orgId } = params;
  const { project } = useContext(AppContext);
  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const [breadCrumbPath, setBreadcrumbPath] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [getFavAssets, setGetFavAssets] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [addRequest, setAddRequest] = useState(false);
  const [file, setFile] = useState({});
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isDnd, setIsDnd] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [favAssets, setFavAssets] = useState([]);
  const [addFav, setAddFav] = useState(false);
  const [cancelFav, setCancelFav] = useState(false);
  const [favAssetId, setFavAssetId] = useState('');
  const [isOnlyFav, SetIsOnlyFav] = useState(false);
  const [totalAssetCount, setTotalAssetCount] = useState(0);
  const [assetItemCursor, setAssetItemCursor] = useState(null);
  const [isPaginationDisabled, setIsPaginationDisabled] = useState(false);

  const onUploadAsset = (e) => {
    setFile(e.target.files[0]);
    setAddRequest(true);
  };

  const [getPaginatedAssets, { fetchMore: fetchMoreAssets }] = useLazyQuery(GET_PAGINATED_ASSETS, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setLoaded(true);

      const {
        asset: {
          searchPaginatedAssets: { edges: assetsList, totalCount },
        },
      } = data;

      if (!totalCount) {
        return;
      }

      const filteredAssetsList = assetsList.map(({ node }) => {
        const { assetId, contentType, s3Key } = node;
        return {
          ...node,
          src: getSignedUrl(s3Key, contentType),
          isFav: favAssets.includes(assetId),
        };
      });

      if (!loaded) {
        setAssets(filteredAssetsList);
        setFilteredAssets(filteredAssetsList);
      }

      setAssetItemCursor(assetsList[assetsList.length - 1].cursor);
      setTotalAssetCount(assetsList.length);
      const isMoreAssets = isEmpty(assetsList) || totalAssetCount >= totalCount;
      setIsPaginationDisabled(isMoreAssets);
    },
  });

  const handleLoadMoreAssets = () => {
    fetchMoreAssets({
      variables: { after: assetItemCursor, first: pageSize, isPrevious: false },
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          asset: {
            searchPaginatedAssets: { edges: newAssetList },
          },
        } = fetchMoreResult;

        if (!newAssetList.length) {
          return previousQueryResult;
        }

        const { asset: previousQueryResultAsset } = previousQueryResult;
        const { searchPaginatedAssets: previousQuerySearchPaginatedAssets } = previousQueryResultAsset;
        const { edges: previousAssetList } = previousQuerySearchPaginatedAssets;

        const allFetchedAssets = [...previousAssetList, ...newAssetList];

        const updatedCache = {
          ...previousQueryResult,
          asset: {
            ...previousQueryResultAsset,
            searchPaginatedAssets: {
              ...previousQuerySearchPaginatedAssets,
              edges: allFetchedAssets,
            },
          },
        };

        const updatedFilteredAssetsList = allFetchedAssets.map(({ node }) => {
          const { assetId, contentType, s3Key } = node;
          return {
            ...node,
            src: getSignedUrl(s3Key, contentType),
            isFav: favAssets.includes(assetId),
          };
        });

        setFilteredAssets(updatedFilteredAssetsList);
        setAssets(updatedFilteredAssetsList);
        setAssetItemCursor(newAssetList[newAssetList.length - 1].cursor);
        return updatedCache;
      },
    });
  };

  const handleFilterAssets = (filter) => {
    getPaginatedAssets({
      variables: {
        first: Number(filter),
        projectId,
        type: type.toUpperCase(),
      },
    });

    setAssetItemCursor(null);
    setLoaded(false);
    setIsPaginationDisabled(pageSize >= totalAssetCount);
  };

  const handleSort = (type) => {
    let tmpAssets = [];
    if (type === 'latest') tmpAssets = filteredAssets.sort((a, b) => new Date(b.uploaded) - new Date(a.uploaded));
    else if (type === 'oldest') tmpAssets = filteredAssets.sort((a, b) => new Date(a.uploaded) - new Date(b.uploaded));
    setFilteredAssets(tmpAssets);
    setIsDnd(false);
  };

  const handleOnlyShowFav = () => {
    if (!isOnlyFav) {
      const tmpAssets = filteredAssets.filter((item) => item.isFav);
      setFilteredAssets(tmpAssets);
    } else {
      setFilteredAssets(assets);
    }
    SetIsOnlyFav(!isOnlyFav);
  };

  const handleSearch = (e) => {
    if (e.target.value === '') setFilteredAssets(assets);
    const filtered = assets.filter((item) => item.fileName.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredAssets(filtered);
  };

  const handleFavAsset = (asset) => {
    setFavAssetId(asset.assetId);
    const tmpAssets = filteredAssets.map((item) => {
      if (item.assetId === asset.assetId) {
        const newAss = {
          ...asset,
          isFav: !asset.isFav,
        };
        return newAss;
      }
      return item;
    });
    setAssets(tmpAssets);
    setFilteredAssets(tmpAssets);
    if (!asset.isFav) setAddFav(true);
    else setCancelFav(true);
  };

  const handleEdit = (item) => {
    setSelectedAsset(item);
    setIsEdit(true);
  };

  const handleDisplay = (item) => {
    setSelectedAsset(item);
    setIsDisplay(true);
  };

  const handleClose = () => {
    setIsDisplay(false);
    setIsEdit(false);
  };

  const updateListWithItem = (item) => {
    const temp = {
      ...item,
      src: getSignedUrl(item.s3Key, item.contentType),
      isFav: favAssets.includes(item.assetId),
    };
    const newAssets = assets.filter((asset) => asset.assetId !== item.assetId);

    newAssets.push(temp);
    setAssets(newAssets);
    setFilteredAssets(newAssets);
  };
  const handleOnPublicPropToggled = (item) => {
    updateListWithItem(item);
  };

  const handleEditSuccess = (item) => {
    updateListWithItem(item);
    setIsEdit(false);
  };

  const handleEditClose = (item) => {
    if (item !== undefined) {
      const newAssets = assets.filter((asset) => asset.assetId !== item.assetId);
      setAssets(newAssets);
      setFilteredAssets(newAssets);
      deleteObject(item.s3Key);
    }
    setIsEdit(false);
  };

  const handleGetFavAssetsError = () => {
    setGetFavAssets(true);
  };

  const handleGetFavAssetsSuccess = (data) => {
    setGetFavAssets(true);
    setFavAssets(data);
    setLoaded(false);
  };

  const handleFavAssetError = () => {
    if (addFav) setAddFav(false);
    if (cancelFav) setCancelFav(false);
  };

  const handleFavAssetSuccess = () => {
    if (addFav) setAddFav(false);
    if (cancelFav) setCancelFav(false);
  };

  const handleCreateError = () => {
    setAddRequest(false);
  };

  const handleCreateSuccess = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, asset },
      },
    } = data;

    Object.defineProperty(file, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    axios
      .put(signedUrl, file, { headers: { 'content-type': file.type } })
      .then(() => {
        setAddRequest(false);
        const newAssets = [
          ...assets,
          {
            assetId: asset.assetId,
            src: getSignedUrl(asset.s3Key, asset.contentType),
            fileName: asset.fileName,
            s3Key: asset.s3Key,
            contentType: asset.contentType,
          },
        ];
        setAssets(newAssets);
        setFilteredAssets(newAssets);
      })
      .catch(() => {
        setAddRequest(false);
      });
  };

  useEffect(() => {
    getPaginatedAssets({
      variables: {
        first: pageSize,
        projectId,
        type: type.toUpperCase(),
      },
    });
  }, []);

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library`,
        title: 'Social',
        isExternal: true,
      },
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library/${type}`,
        title: `${capitalize(type)} library`,
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const toolbar = (
    <Toolbar>
      <SearchInput onChange={handleSearch} />
      <FilterBox>
        <FilterBtn onClick={() => setIsDnd(!isDnd)} />
        <DropDownMenu open={isDnd} style={{ left: '40%', padding: '1em 0' }}>
          <li>
            <FilterOpt onClick={() => handleSort('latest')}>Latest</FilterOpt>
          </li>
          <li>
            <FilterOpt onClick={() => handleSort('oldest')}>Oldest</FilterOpt>
          </li>
        </DropDownMenu>
      </FilterBox>
      <FavSwitch isSwitched={handleOnlyShowFav} />
      <AddNewBtn>
        <input style={{ display: 'none' }} type="file" onChange={onUploadAsset} />
        <IconWrapper>
          <Icon id="icon_camera" style={{ height: '1rem', width: '1rem' }} />
        </IconWrapper>
        Add new {type}
      </AddNewBtn>
    </Toolbar>
  );

  const content = (
    <>
      <AssetsWrapper>
        {!loaded && <LoadingBox count={9} />}
        {loaded && filteredAssets.length === 0 ? (
          <NoDataAlerts />
        ) : (
          filteredAssets.map((asset) => {
            const { assetId, fileName, isFav, src, alt } = asset;

            switch (type) {
              case 'image':
                return (
                  <ImgBox key={assetId}>
                    <FavBtn isClicked={() => handleFavAsset(asset)} isFav={isFav} />
                    <EditBtnBox>
                      <EditBtn isClicked={() => handleEdit(asset)} />
                    </EditBtnBox>
                    <AssetImg alt={alt} src={src} onClick={() => handleDisplay(asset)} />
                  </ImgBox>
                );
              case 'video':
                return (
                  <VideoBox key={assetId}>
                    <FavBtn isClicked={() => handleFavAsset(asset)} isFav={isFav} />
                    <EditBtnBox>
                      <EditBtn isClicked={() => handleEdit(asset)} />
                    </EditBtnBox>
                    <AssetVideo src={src} onClick={() => handleDisplay(asset)}>
                      <track kind="captions" srcLang="en" />
                    </AssetVideo>
                  </VideoBox>
                );
              case 'audio':
                return (
                  <AudioBox key={assetId}>
                    <FavBtn isClicked={() => handleFavAsset(asset)} isFav={isFav} />
                    <EditBtnBox>
                      <EditBtn isClicked={() => handleEdit(asset)} />
                    </EditBtnBox>
                    <AudioPlayer selected={src} />
                    <FileName>{fileName}</FileName>
                  </AudioBox>
                );
              case 'document':
                return (
                  <FileBox key={assetId} asset={asset} isFav={isFav} onEdit={() => handleEdit(asset)} onFav={() => handleFavAsset(asset)} />
                );
              default:
                return null;
            }
          })
        )}
      </AssetsWrapper>

      <Pagination
        isDisabled={isPaginationDisabled}
        pageSize={pageSize}
        onFilterHandler={handleFilterAssets}
        onLoadMoreHandler={handleLoadMoreAssets}
        onPageSizeChangeHandler={setPageSize}
      />
    </>
  );

  return (
    <>
      <PageWrapper breadPath={breadCrumbPath} info={`Manage your ${type} assets`} params={params}>
        <ProjectNavBar title={project.name} />
        {isEdit && (
          <EditAssetSideBar
            asset={selectedAsset}
            type={type}
            onClose={handleEditClose}
            onPublicPropToggled={handleOnPublicPropToggled}
            onUpdateSuccess={handleEditSuccess}
          />
        )}
        {toolbar}
        {content}
        {isDisplay && <DisplayAsset asset={selectedAsset} type={type} />}
        {(isDisplay || isEdit) && <Backdrop isClicked={handleClose} />}
      </PageWrapper>
      {addRequest && (
        <AddAsset
          contentType={file.type}
          fileName={file.name}
          fileSizeInBytes={file.size}
          projectId={projectId}
          type={type.toUpperCase()}
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
        />
      )}
      {!getFavAssets && (
        <GetAllFavAssetsRequest
          assetType={type.toUpperCase()}
          onError={handleGetFavAssetsError}
          onSuccessResult={handleGetFavAssetsSuccess}
        />
      )}
      {addFav && <AddAssetToFavRequest assetId={favAssetId} onError={handleFavAssetError} onSuccessResult={handleFavAssetSuccess} />}
      {cancelFav && <RemoveAssetToFavRequest assetId={favAssetId} onError={handleFavAssetError} onSuccessResult={handleFavAssetSuccess} />}
    </>
  );
};

LibraryAssetPageNew.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      appId: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default LibraryAssetPageNew;
