import React, { useState } from 'react';

import { PostsTable } from './Tables/PostsTable';
import { UsersTable } from './Tables/UsersTable';

import { Tabs } from '../../Tabs/Tabs';

import './Moderation.scss';

const Moderation = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  const tabs = [
    {
      component: <PostsTable />,
      label: 'posts',
      id: 0,
    },
    {
      component: <UsersTable />,
      label: 'users',
      id: 1,
    },
  ];

  return (
    <div className="c-moderation-wrapper">
      <Tabs isInSideBar={false} items={tabs} selectedTabId={selectedTabIndex} onTabChangeHandler={handleTabChange} />
    </div>
  );
};

export default Moderation;
