import React, { useRef } from 'react';

import DropzoneArea from '../Dropzone/DropzoneArea';
import { ChooseFromLibraryButton } from '../styled';

import UploadedAudioTab from './UploadedAudioTab';

import Preloader from '../../Preloader/Preloader';

const AudioComponent = ({
  fileName,
  handleCloseLibrary,
  handleDeleteAsset,
  handleFileDrop,
  handleOpenLibrary,
  hasAudioData,
  isOpenLibrary,
  isLoading,
  presignedUrl,
  setHasAudioData,
}) => {
  const dropzoneRef = useRef();

  const handleEditAudio = () => {
    setHasAudioData(false);
  };

  if (isLoading) {
    return <Preloader />;
  }
  if (hasAudioData) {
    return (
      <UploadedAudioTab
        handleEditAudio={handleEditAudio}
        handleDeleteAsset={handleDeleteAsset}
        presignedUrl={presignedUrl}
        fileName={fileName}
      />
    );
  }

  return (
    <>
      <DropzoneArea onDrop={file => handleFileDrop(file)} fileType="audio/*" dropRef={dropzoneRef} />
      <ChooseFromLibraryButton className="button--green" onClick={isOpenLibrary ? handleCloseLibrary : handleOpenLibrary}>
        {isOpenLibrary ? 'Go back' : 'Choose from library'}
      </ChooseFromLibraryButton>
    </>
  );
};

export default AudioComponent;
