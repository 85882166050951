import { parseISO } from 'date-fns';
import orderBy from 'lodash.orderby';

import { TEXT } from '../GroupOrderDetailsPage.constants';

const getOrderModuleLicencedUsers = (orderId, orderDetails) => {
  const orderModuleLicencedUsers = orderDetails.reduce((orderAccumulator, orderDetail) => {
    const { id, items } = orderDetail;

    const userFlows = items.map((orderDetailItem) => orderDetailItem.userFlow);

    const userFlowLicencedUsers = items.reduce((orderItemAccumulator, orderDetailItem) => {
      const {
        licences,
        userFlow: { id: userFlowId },
      } = orderDetailItem;

      const updatedLicences = licences.map((licence) => {
        const {
          appliedToUser: { email = TEXT.Unknown, firstName, lastName },
          expiresAtUtc,
          id: licenceId,
        } = licence;

        const expiryDate = parseISO(expiresAtUtc);
        const fullName = `${firstName} ${lastName}`.trim() || TEXT.Unknown;

        const transformedLicence = {
          email,
          expiryDate,
          fullName,
          id: licenceId,
        };

        return {
          expiryDate,
          fullName,
          licence: transformedLicence,
        };
      });

      return {
        ...orderItemAccumulator,
        [userFlowId]: orderBy(updatedLicences, 'fullName'),
      };
    }, {});

    return {
      ...orderAccumulator,
      [id]: {
        userFlowLicencedUsers,
        userFlows,
      },
    };
  }, {});

  return orderModuleLicencedUsers[orderId];
};

export { getOrderModuleLicencedUsers };
