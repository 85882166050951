import React from 'react';

import { Helmet } from 'react-helmet';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

const styles = {
  textDecoration: 'underline',
};
export default function PrivacyPage() {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>Ekardo privacy statement</title>
        <meta
          content="Ekardo takes your privacy seriously, read our statement for more detail"
          name="description"
        />
      </Helmet>

      <div className="login-page">
        <div
          className="login-page__fold"
          style={{ maxHeight: '350px', minHeight: '320px' }}
        >
          <Header theme="red" />
          <div
            className="login-page__container"
            style={{
              margin: 'auto auto 3em auto',
              maxWidth: '700px',
            }}
          >
            <h2
              className="heading-page"
              style={{
                fontSize: '3em',
              }}
            >
              Privacy
            </h2>
            <h2 className="heading-page">statement</h2>
          </div>
        </div>
      </div>

      <div className="u-container" style={{ marginTop: '1em' }}>
        <p>
          Ekardo is committed to providing you with the best possible customer
          service experience and is bound by the Privacy Act 1988 (Cth), which
          sets out a number of principles concerning the privacy of individuals.
          In this Privacy Policy, &lsquo;us&rsquo; &lsquo;we&rsquo; or
          &lsquo;our&rsquo; means Netfront Pty Ltd (ABN 27 132 358 368). We are
          committed to respecting your privacy. Our Privacy Policy sets outs out
          how we collect, use, store and disclose your personal information. By
          providing personal information to us, you consent to our collection,
          use and disclosure of your personal information in accordance with
          this Privacy Policy and any other arrangements that apply between us.
          We may change our Privacy Policy from time to time by publishing
          changes to it on our website. We encourage you to check our website
          periodically to ensure that you are aware of our current Privacy
          Policy. Personal information includes information or an opinion about
          an individual that is reasonably identifiable. For example, this may
          include your name, age, gender, postcode and contact details.
        </p>
      </div>

      <div className="u-container">
        <strong>WHAT PERSONAL INFORMATION DO WE COLLECT?</strong>
        <p>We may collect the following types of personal information:</p>
        <ol>
          <li>name;</li>
          <li>mailing or street address;</li>
          <li>email address;</li>
          <li>telephone number and other contact details;</li>
          <li>age or date of birth;</li>

          <li>
            your device ID, device type, geo-location information, computer and
            connection information, statistics on page views, traffic to and
            from the sites, ad data, IP address and standard web log
            information;
          </li>
          <li>
            details of the products and services we have provided to you or that
            you have enquired about, including any additional information
            necessary to deliver those products and services and respond to your
            enquiries;
          </li>
          <li>
            any additional information relating to you that you provide to us
            directly through our website or indirectly through your use of our
            website or online presence or through other websites or accounts
            from which you permit us to collect information;
          </li>
          <li>information you provide to us through customer surveys; or</li>
          <li>
            any other personal information that may be required in order to
            facilitate your dealings with us.
          </li>
        </ol>
        <br />
        <p>
          We may collect these types of personal information either directly
          from you, or from third parties. We may collect this information when
          you:
        </p>
        <ol>
          <li>register on our website;</li>
          <li>
            interact with our sites, services, content and advertising; or
          </li>
          <li>
            invest in our business or enquire as to a potential purchase in our
            business.
          </li>
        </ol>

        <br />
        <p>
          In addition, if you apply for a job or position with us we may collect
          certain information from you (including your name, contact details,
          working history and relevant records checks) from any recruitment
          consultant, your previous employers and others who may be able to
          provide information to us to assist in our decision on whether or not
          to make you an offer of employment or engage you under a contract.
          This Privacy Policy does not apply to acts and practices in relation
          to employee records of our current and former employees, which are
          exempt from the Privacy Act.
        </p>
      </div>

      <div className="u-container">
        <strong>
          WHY DO WE COLLECT, USE AND DISCLOSE PERSONAL INFORMATION?
        </strong>
        <p>
          We may collect, hold, use and disclose your personal information for
          the following purposes:
        </p>
        <ol>
          <li>
            to enable you to access and use our website and products and
            services;
          </li>
          <li>
            to operate, protect, improve and optimise our website, products,
            services and business and our users’ experience, such as to perform
            analytics, conduct research and for advertising and marketing;
          </li>
          <li>
            to send you service, support and administrative messages, reminders,
            technical notices, updates, security alerts, and information
            requested by you;
          </li>
          <li>
            to comply with our legal obligations, resolve any disputes that we
            may have with any of our users, and enforce our agreements with
            third parties; and
          </li>
          <li>to consider your employment application.</li>
        </ol>
      </div>

      <div className="u-container">
        <strong>TO WHOM DO WE DISCLOSE YOUR PERSONAL INFORMATION?</strong>
        <p>
          We may disclose personal information for the purposes described in
          this privacy policy to:
        </p>
        <ol>
          <li>our employees and related bodies corporate;</li>
          <li>
            third party suppliers and service providers (including providers for
            the operation of our websites and/or our business or in connection
            with providing our products and services to you);
          </li>
          <li>professional advisers, dealers and agents;</li>
          <li>
            payment systems operators (e.g. merchants receiving card payments);
          </li>
          <li>
            our existing or potential agents, business partners or partners;
          </li>
          <li>
            anyone to whom our assets or businesses (or any part of them) are
            transferred;
          </li>
          <li>
            specific third parties authorised by you to receive information held
            by us; and/or
          </li>
          <li>
            other persons, including government agencies, regulatory bodies and
            law enforcement agencies, or as required, authorised or permitted by
            law.
          </li>
        </ol>
      </div>

      <div className="u-container">
        <strong>SECURITY</strong>
        <p>
          We may hold your personal information in either electronic or hard
          copy form. We take reasonable steps to protect your personal
          information from misuse, interference and loss, as well as
          unauthorised access, modification or disclosure and we use a number of
          physical, administrative, personnel and technical measures to protect
          your personal information. However, we cannot guarantee the security
          of your personal information.
        </p>
      </div>

      <div className="u-container">
        <strong>LINKS</strong>
        <p>
          Our website may contain links to websites operated by third parties.
          Those links are provided for convenience and may not remain current or
          be maintained. Unless expressly stated otherwise, we are not
          responsible for the privacy practices of, or any content on, those
          linked websites, and have no control over or rights in those linked
          websites. The privacy policies that apply to those other websites may
          differ substantially from our Privacy Policy, so we encourage
          individuals to read them before using those websites.
        </p>
      </div>

      <div className="u-container">
        <strong>ACCESSING OR CORRECTING YOUR PERSONAL INFORMATION</strong>
        <p>
          You can access the personal information we hold about you if you{' '}
          <a href="mailto:hello@netfront.com.au" style={{ ...styles }}>
            email us
          </a>
          . Sometimes, we may not be able to provide you with access to all of
          your personal information and, where this is the case, we will tell
          you why. We may also need to verify your identity when you request
          your personal information. If you think that any personal information
          we hold about you is inaccurate, please{' '}
          <a href="mailto:hello@netfront.com.au" style={{ ...styles }}>
            email us
          </a>{' '}
          and we will take reasonable steps to ensure that it is corrected.
        </p>
      </div>
      <div className="u-container">
        <strong>MAKING A COMPLAINT</strong>
        <p>
          If you think we have breached the Privacy Act, or you wish to make a
          complaint about the way we have handled your personal information, you
          can{' '}
          <a href="mailto:hello@netfront.com.au" style={{ ...styles }}>
            email us
          </a>
          . Please include your name, email address and/or telephone number and
          clearly describe your complaint. We will acknowledge your complaint
          and respond to you regarding your complaint within a reasonable period
          of time. If you think that we have failed to resolve the complaint
          satisfactorily, we will provide you with information about the further
          steps you can take.
        </p>
      </div>

      <div className="u-container">
        <strong>CONTACT US</strong>
        <p>
          For further information about our Privacy Policy or practices, or to
          access or correct your personal information, or make a complaint,
          please{' '}
          <a href="mailto:hello@netfront.com.au" style={{ ...styles }}>
            email us
          </a>
          .
        </p>
      </div>
      <div className="u-container" />

      <Footer />
    </div>
  );
}
