import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import AppComponent from '../ContentAppCard/AppComponent';
import ContentAppCard from '../ContentAppCard/ContentAppCard';

import { SnippetTypeConst } from './SnippetTypeConst';

import AppContext from '../../context/AppContext';
import CreateAppContentSnippetRequest from '../../middleware/ContentSnippet/createAppContentSnippetRequest';
import UpdateAppContentSnippetRequest from '../../middleware/ContentSnippet/updateAppContentSnippet';
import GetAppSnippetDetails from '../../middleware/Marketplace/GetAppSnippetDetails';
import GetAppList from '../../middleware/MyAppDashboard/getMyImportedAppList';
import 'react-quill/dist/quill.snow.css';

const NoAppText = styled.p`
  margin: 1em 0;

  a {
    color: #44bed3;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const CompWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 240px;
  overflow: auto;
`;

function EditableAppSnippet(props) {
  const {
    containerId,
    onCreate,
    onCancel,
    onUpdate,
    onRefreshPage,
    snippet,
  } = props;
  const { project } = useContext(AppContext);

  const [appLoaded, setAppLoaded] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [getComponentRequest, setGetComponentRequest] = useState(false);
  const [appList, setAppList] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [appIdentifier, setAppIdentifier] = useState(null);
  const [appVersion, setAppVersion] = useState(null);
  const [appComponentId, setAppComponentId] = useState(null);
  const [step, setStep] = useState(1);

  const NO_COMPONENT = Object.freeze({
    title: 'No Existing Component',
    description: 'Click me to go back',
    id: -99,
  });

  const handleSaveButton = () => {
    setSendRequest(true);
  };

  const handleClickCard = identifier => {
    setAppIdentifier(identifier);
    setGetComponentRequest(true);
    setStep(2);
  };

  const handleClickVersion = id => {
    if (id === NO_COMPONENT.id) {
      setStep(1);
    } else {
      setStep(3);
      setAppVersion(id);
      const comp = versionList.find(a => a.version === id);
      if (comp.components.length > 0) {
        setComponentList(comp.components);
      } else {
        setComponentList([NO_COMPONENT]);
      }
    }
  };

  const handleClickComponent = id => {
    if (id === NO_COMPONENT.id) {
      setStep(2);
    } else {
      setAppComponentId(id);
    }
  };

  const handleGetListOnError = () => {
    setAppLoaded(true);
  };

  const handleOnSuccessResultGetList = apps => {
    const tmpApps = apps.map(d => d.node);
    setAppLoaded(true);
    setAppList(tmpApps);
  };

  const handleGetLatestError = () => {
    setGetComponentRequest(false);
  };

  const handleGetLatestSuccess = data => {
    const publishedVersions = data.versions.filter(
      v => v.status === 'ACCEPTED'
    );
    if (publishedVersions.length > 0) {
      setVersionList(publishedVersions);
    } else {
      setVersionList([NO_COMPONENT]);
    }

    setGetComponentRequest(false);
  };

  const handleSnippetError = () => {
    setSendRequest(false);
  };

  const handleSnippetResponse = (id, sort) => {
    if (snippet) {
      onUpdate(id, sort, SnippetTypeConst.APP, containerId, { appComponentId });
      onRefreshPage();
    } else {
      onCreate(id, sort, SnippetTypeConst.APP, containerId, { appComponentId });
    }
    setSendRequest(false);
  };

  return (
    <>
      {step === 1 && (
        <div className="content-app-container">
          <div className="app-list">
            {appList.length > 0 ? (
              appList.map(app => (
                <ContentAppCard
                  byFrom={app.byFrom || 'Netfront'}
                  id={app.id}
                  key={app.id}
                  onClick={() => handleClickCard(app.id)}
                  title={app.title}
                />
              ))
            ) : (
              <div>
                <NoAppText>
                  No linked apps,{' '}
                  <a href={`/marketplace/${project.id}`}>
                    access the marketplace
                  </a>{' '}
                  to link one now.
                </NoAppText>
              </div>
            )}
          </div>
        </div>
      )}
      {step === 2 && (
        <CompWrapper>
          {versionList.map(item => (
            <AppComponent
              description={
                item.id === NO_COMPONENT.id ? item.description : item.version
              }
              id={item.id === NO_COMPONENT.id ? item.id : item.version}
              isClicked={handleClickVersion}
              isSelected={item.version === appVersion}
              key={item.version}
              title={item.id === NO_COMPONENT.id ? item.title : 'App version'}
            />
          ))}
        </CompWrapper>
      )}

      {step === 3 && (
        <CompWrapper>
          {componentList.map(item => (
            <AppComponent
              description={item.description}
              id={item.id}
              isClicked={handleClickComponent}
              isSelected={item.id === appComponentId}
              key={item.id}
              title={item.title}
            />
          ))}
        </CompWrapper>
      )}

      <div className="text-right">
        <button
          className="button--black mt-4 mr-3"
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="button--blue mt-4"
          onClick={handleSaveButton}
          type="submit"
        >
          Save
        </button>
      </div>

      {/* ************************* GraphQL Queries *********************** */}
      {!appLoaded && (
        <GetAppList
          onError={handleGetListOnError}
          onSuccessResult={handleOnSuccessResultGetList}
        />
      )}
      {getComponentRequest && (
        <GetAppSnippetDetails
          id={appIdentifier}
          onError={handleGetLatestError}
          onSuccess={handleGetLatestSuccess}
        />
      )}
      {sendRequest && !snippet && (
        <CreateAppContentSnippetRequest
          appComponentId={Number(appComponentId)}
          containerId={containerId}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
        />
      )}
      {sendRequest && snippet && (
        <UpdateAppContentSnippetRequest
          appComponentId={Number(appComponentId)}
          id={snippet.id}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
        />
      )}
    </>
  );
}

EditableAppSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape({
    id: PropTypes.number,
  }),
};

EditableAppSnippet.defaultProps = {
  snippet: null,
};

export default EditableAppSnippet;
