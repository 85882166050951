import gql from 'graphql-tag';

export const UPDATE_POST = gql`
  mutation updatePost($postId: Int!, $message: String!) {
    post {
      update(request: { id: $postId, message: $message, shareOption: EVERYONE, postType: FEED }) {
        id
        topic {
          id
          title
        }
        tags
        authorId
        author {
          id
          displayedName
        }
        message
        commentCount
        comments {
          edges {
            node {
              authorId
              author {
                id
                displayedName
                firstName
                lastName
                email
                postCount
              }
              message
              postId
            }
          }
        }
        createdDate
        createdDateTime
        assets {
          assetId
          presignedUrl
          s3Key
          contentType
          fileName
        }
      }
    }
  }
`;
