import React from 'react';

import PropTypes from 'prop-types';

const Form = ({ children, name }) => (
  <form action="submit" name={name}>
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string,
};

Form.defaultProps = {
  name: undefined,
};

export default Form;
