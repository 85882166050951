import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ImageUploader from '../UI/AssetUploader/ImageUploader';
import SquareButton from '../UI/Button/SquareButton';
import SwitchBtn from '../UI/Button/SwitchButton';
import ColorInput from '../UI/ColorPicker/ColorInput';
import Dropdown from '../UI/Dropdown/Dropdown';
import AnimationPart from '../UI/SidebarComp/AnimationPart';
import EventPart from '../UI/SidebarComp/EventPart';
import SidebarTab from '../UI/Tab/SidebarTab';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import { ColorItem, Container, EditBtn, Item, Logo, Preview, Title } from './styled';

import { getAssetsUrl } from '../../config';
import DeleteAssetRequest from '../../middleware/Asset/deleteAssetRequest';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import client from '../../middleware/client';
import CreateContentEventRequest from '../../middleware/ContentEvent/createContentEventRequest';
import DeleteContentEventRequest from '../../middleware/ContentEvent/deleteContentEventRequest';
import UpdateContentEventRequest from '../../middleware/ContentEvent/updateContentEventRequest';
import UpdateSectionStyle from '../../middleware/ContentSection/updateSectionStyle';
import { ATTACH_TO_SECTION } from '../../middleware/CssStyle/attachToSection';
import { DETACH_STYLE } from '../../middleware/CssStyle/detachStyle';
import { GET_CSS_STYLE_PER_PROJECT } from '../../middleware/CssStyle/getCssPerProject';

// Layout Icon
const halfHalf = `${getAssetsUrl()}/icons/container-options/half-half.svg`;
const oneThirdLeft = `${getAssetsUrl()}/icons/container-options/one-third-half-left.svg`;
const oneThirdRight = `${getAssetsUrl()}/icons/container-options/one-third-half-right.svg`;
const threeOneQuartersLeft = `${getAssetsUrl()}/icons/container-options/three-one-quarters-left.svg`;
const threeOneQuartersRight = `${getAssetsUrl()}/icons/container-options/three-one-quarters-right.svg`;
const threeThirds = `${getAssetsUrl()}/icons/container-options/three-thirds.svg`;
const threeTwoQuartersLeft = `${getAssetsUrl()}/icons/container-options/three-two-quarters-left.svg`;
const threeTwoQuartersRight = `${getAssetsUrl()}/icons/container-options/three-two-quarters-right.svg`;

const NewPreview = styled(Preview)`
  height: 8em;
  margin: 0.5em 0 0 0;
  max-width: unset;
  overflow-x: hidden;
`;

const BgImage = styled(Logo)`
  height: 100%;
  width: auto;
`;

export const DeleteBtn = styled.button`
  background-color: rgba(0, 0, 0, 0.6);
  color: red;
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const LayoutItem = styled.div`
  filter: ${(props) => (props.active ? 'brightness(45%) sepia(100%) hue-rotate(135deg) saturate(800%)' : 'unset')};
  margin: 1em 0;
  width: 8em;

  &:hover {
    filter: brightness(45%) sepia(100%) hue-rotate(135deg) saturate(800%);
  }
`;

const twoContainers = [
  {
    id: 0,
    type: 'halfHalf',
    icon: halfHalf,
    typeId: 2,
  },
  {
    id: 1,
    type: 'oneThirdRight',
    icon: oneThirdRight,
    typeId: 3,
  },
  {
    id: 2,
    type: 'oneThirdLeft',
    icon: oneThirdLeft,
    typeId: 4,
  },
  {
    id: 3,
    type: 'threeOneQuartersLeft',
    icon: threeOneQuartersLeft,
    typeId: 5,
  },
  {
    id: 4,
    type: 'threeOneQuartersRight',
    icon: threeOneQuartersRight,
    typeId: 6,
  },
];

const threeContainers = [
  {
    id: 5,
    type: 'threeThirds',
    icon: threeThirds,
    typeId: 7,
  },
  {
    id: 6,
    type: 'threeTwoQuartersRight',
    icon: threeTwoQuartersRight,
    typeId: 8,
  },
  {
    id: 7,
    type: 'threeTwoQuartersLeft',
    icon: threeTwoQuartersLeft,
    typeId: 9,
  },
];

const SectionSideBar = (props) => {
  const { containerNumber, onCancel, onRefreshPage, currentSection, onSectionDelete } = props;

  const { projectId } = useParams();

  const [layoutId, setLayoutId] = useState(currentSection.layoutId);
  const [select, setSelect] = useState(null);
  const [section, setSection] = useState(currentSection);
  const [updateRequest, setUpdateRequest] = useState(false);
  const [editBgImg, setEditBgImg] = useState(false);
  const [bgUrl, setBgUrl] = useState('');
  const [createRequest, setCreateRequest] = useState(false);
  const [edit, setEdit] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [deleteAssetRequest, setDeleteAssetRequest] = useState(false);
  const [eventDetail, setEventDetail] = useState({
    title: '',
    type: 'NONE',
    eventDirection: 'TOP',
    assetId: null,
    backgroundColor: null,
    borderColor: null,
    titleColor: null,
    style: 'NONE',
    triggerStyle: 'CIRCLE_ARROW',
    iconHorizontalAlignment: null,
    isDefaultOpen: false,
  });

  const [animationDetail, setAnimationDetail] = useState({
    animationType: 'NONE',
    delayTime: 1000,
    animationSpeed: 1000,
    infinite: false,
  });
  const [iconUrl, setIconUrl] = useState('');

  const [customThemes, setCustomThemes] = useState([]);
  const [selectedCustomTheme, setSelectedCustomTheme] = useState('NONE');

  const [detach] = useMutation(DETACH_STYLE, {
    client,
  });

  const [attachStyle] = useMutation(ATTACH_TO_SECTION, {
    client,
  });

  const hasLayout = containerNumber === 2 || containerNumber === 3;

  useEffect(() => {
    if (currentSection.event) {
      let tmpAssetId = null;
      let tmpUrl = null;
      if (currentSection.event && currentSection.event.asset && Object.keys(currentSection.event.asset).length > 0) {
        tmpUrl = getSignedUrl(currentSection.event.asset.s3Key, currentSection.event.asset.contentType);
        tmpAssetId = currentSection.event.asset.assetId;
      }
      setIconUrl(tmpUrl);
      setEventDetail({
        title: currentSection.event.eventTitle,
        type: currentSection.event.event,
        eventDirection: currentSection.event.eventDirection,
        borderColor: currentSection.event.borderColor,
        backgroundColor: currentSection.event.backgroundColor,
        titleColor: currentSection.event.titleColor,
        assetId: tmpAssetId,
        style: currentSection.event.style,
        triggerStyle: currentSection.event.triggerStyle,
        isDefaultOpen: currentSection.event.isDefaultOpen,
        iconHorizontalAlignment: currentSection.event.iconHorizontalAlignment,
      });
      setAnimationDetail({
        animationType: currentSection.event.animation,
        delayTime: currentSection.event.millisecondDelay,
        animationSpeed: currentSection.event.millisecondSpeed,
        infinite: currentSection.event.infinite,
      });
      setEventId(currentSection.event.id);
      setEdit(true);
    }
    if (currentSection.background && Object.keys(currentSection.background).length > 0) {
      const tmpUrl = getSignedUrl(currentSection.background.s3Key, currentSection.background.contentType);
      setBgUrl(tmpUrl);
      setSection({
        ...section,
        bgImgId: currentSection.background.assetId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  const handleStyleUpdate = (value, e) => {
    const newInfo = {
      ...section,
      [e.target.name]: value,
    };
    setSection(newInfo);
  };

  const handleSelectLayout = (typeId) => {
    setLayoutId(typeId);
    setSelect(typeId);
  };

  const handleSaveBtn = () => {
    if (selectedCustomTheme !== 'NONE') {
      attachStyle({
        variables: {
          sectionId: section.sectionId,
          styleId: customThemes.find((c) => c.value === selectedCustomTheme).id,
        },
      });
    } else {
      detach({
        variables: {
          sectionId: section.sectionId,
        },
      });
    }

    setUpdateRequest(true);
  };

  const handleEventUpdate = (value) => {
    setEventDetail(value);
  };

  const handleAnimationUpdate = (value) => {
    setAnimationDetail(value);
  };

  const getBgImgData = (value) => {
    const tmpURL = getSignedUrl(value.s3Key, value.contentType);
    setEditBgImg(false);
    setBgUrl(tmpURL);
    // setEditLogo(false);
    // setLogoUrl(tmpURL);
    setSection({
      ...section,
      bgImgId: value.assetId,
    });
  };

  const handleStyleUpdateError = () => {
    setUpdateRequest(false);
    setCreateRequest(true);
  };

  const handleStyleUpdateSuccess = () => {
    setUpdateRequest(false);
    setCreateRequest(true);
  };

  const handleCreateEventError = () => {
    setCreateRequest(false);
    onCancel();
  };

  const handleCreateEventSuccess = () => {
    setCreateRequest(false);
    onCancel();
    onRefreshPage();
  };

  const handleUpdateEventError = () => {
    setCreateRequest(false);
    onCancel();
  };

  const handleUpdateEventSuccess = () => {
    setCreateRequest(false);
    onCancel();
    onRefreshPage();
  };

  const handleDeleteEventError = () => {
    setDeleteRequest(true);
  };

  const handleDeleteEventSuccess = () => {
    setDeleteRequest(true);
  };

  const handleSectionDelete = () => {
    const updatedSection = {
      ...section,
      id: section.sectionId,
    };
    onCancel();
    onSectionDelete(updatedSection);
  };

  const handleDeleteAssetError = () => {
    setDeleteAssetRequest(false);
  };

  const handleDeleteAssetResponse = () => {
    setDeleteAssetRequest(false);
    setBgUrl('');
    setEditBgImg(false);
  };

  const handleSelectedThemeChanged = (id) => {
    const theme = customThemes.find((c) => c.id === Number(id));
    setSelectedCustomTheme(theme.value);
  };

  useQuery(GET_CSS_STYLE_PER_PROJECT, {
    client,
    skip: !section,
    fetchPolicy: 'no-cache',
    variables: {
      projectId,
    },
    onCompleted: (data) => {
      const defaultThemeOption = [
        {
          id: 0,
          value: 'NONE',
          label: 'Please select a custom theme',
        },
      ];

      const projectThemeOptions = data.cssStyle.getPerProject.map((style) => ({
        id: style.id,
        value: style.name,
        label: style.name,
      }));

      const themes = [...defaultThemeOption, ...projectThemeOptions];

      if (section.cssStyle) {
        const theme = themes.find((c) => c.id === section.cssStyle.id);
        setSelectedCustomTheme(theme.value);
      }
      setCustomThemes(themes);
    },
  });

  let LayoutOptions;
  if (containerNumber === 2) {
    LayoutOptions = twoContainers.map((item) => (
      <LayoutItem key={item.id} active={item.typeId === select} onClick={() => handleSelectLayout(item.typeId)}>
        <img alt="layout-icon" src={item.icon} />
      </LayoutItem>
    ));
  }

  if (containerNumber === 3) {
    LayoutOptions = threeContainers.map((item) => (
      <LayoutItem key={item.id} active={item.typeId === select} onClick={() => handleSelectLayout(item.typeId)}>
        <img alt="layout-icon" src={item.icon} />
      </LayoutItem>
    ));
  }

  // Event tab
  const eventTab = (
    <EventPart
      currentDetail={eventDetail}
      icon={iconUrl}
      projectId={projectId}
      setIcon={(value) => setIconUrl(value)}
      updateEventDetail={handleEventUpdate}
    />
  );

  // Animation tab
  const animationTab = <AnimationPart currentDetail={animationDetail} updateEventDetail={handleAnimationUpdate} />;

  // Style tab
  const styleTab = (
    <Container>
      {section && (
        <ColorItem>
          <Title>
            <b>Visible</b>
          </Title>
          <SwitchBtn defaultChecked={section.visible} id={1} name="visible" onChange={handleStyleUpdate} />
        </ColorItem>
      )}

      {section && customThemes.length > 0 && (
        <Item>
          <Title>Custom theme</Title>
          <Dropdown
            availableResponses={customThemes}
            name="customTheme"
            selectedValue={selectedCustomTheme}
            onChangeHandler={handleSelectedThemeChanged}
          />
        </Item>
      )}
      <ColorItem>
        <Title>
          <b>Border colour</b>
        </Title>
        <ColorInput currentColor={section.borderColor} getColor={handleStyleUpdate} id="color-1" name="borderColor" />
      </ColorItem>
      <ColorItem>
        <Title>
          <b>Background colour</b>
        </Title>
        <ColorInput currentColor={section.backgroundColor} getColor={handleStyleUpdate} id="color-2" name="backgroundColor" />
      </ColorItem>
      <ColorItem>
        <Title>
          <b>Full stretch</b>
        </Title>
        <SwitchBtn
          // defaultChecked={section.status !== 'STRETCHED'}
          defaultChecked={section.style}
          id={0}
          isChanged={handleStyleUpdate}
          name="style"
        />
      </ColorItem>

      <Item>
        <Title>
          <b>Background image</b>
          <Tooltip />
        </Title>
        {bgUrl && !editBgImg ? (
          <>
            <NewPreview>
              <EditBtn
                onClick={() => {
                  setEditBgImg(true);
                }}
              >
                Edit the image
              </EditBtn>
              <BgImage alt="bg image" src={bgUrl} />
            </NewPreview>
            <SquareButton
              bgColor="#dc3545"
              color="white"
              isClicked={() => {
                setDeleteAssetRequest(true);
              }}
            >
              Yes, delete
            </SquareButton>
          </>
        ) : (
          <ImageUploader getData={getBgImgData} name="bgImg" projectId={projectId} type="IMAGE" />
        )}
      </Item>
    </Container>
  );

  // Layout tab
  const layoutTab = (
    <Container>
      <LayoutContainer>{LayoutOptions}</LayoutContainer>
    </Container>
  );

  const items = [
    {
      content: eventTab,
      title: 'Event',
    },
    {
      content: animationTab,
      title: 'Animation',
    },
    {
      content: styleTab,
      title: 'Style',
    },
  ];

  if (hasLayout) {
    items.push({
      content: hasLayout ? layoutTab : undefined,
      title: 'Layout',
    });
  }

  return (
    <>
      <RightNavBar
        hasConfirmButton
        hasDelete
        onCancel={onCancel}
        onDelete={() => {
          setDeleteRequest(true);
          handleSectionDelete();
        }}
        onSave={handleSaveBtn}
      >
        <div>
          <h3>Section settings</h3>
          <SidebarTab items={items} />
        </div>
      </RightNavBar>
      {updateRequest && (
        <UpdateSectionStyle
          backgroundColor={section.backgroundColor}
          backgroundImage={section.bgImgId}
          borderColor={section.borderColor}
          contentLayoutId={layoutId}
          sectionId={section.sectionId}
          style={section.style ? 'STRETCHED' : 'NORMAL'}
          visible={section.visible}
          onError={handleStyleUpdateError}
          // eslint-disable-next-line react/style-prop-object
          onSuccessResult={handleStyleUpdateSuccess}
        />
      )}
      {createRequest && !edit && (
        <CreateContentEventRequest
          animation={animationDetail.animationType}
          animationDirection={animationDetail.animationDirection || null}
          assetId={eventDetail.assetId}
          backgroundColor={eventDetail.backgroundColor}
          borderColor={eventDetail.borderColor}
          contentEntity="SECTION"
          entityId={section.sectionId}
          event={eventDetail.type}
          eventDirection={eventDetail.eventDirection || 'TOP'}
          eventTitle={eventDetail.title}
          iconHorizontalAlignment={eventDetail.iconHorizontalAlignment}
          infinite={animationDetail.infinite}
          isDefaultOpen={eventDetail.isDefaultOpen}
          millisecondDelay={animationDetail.delayTime}
          millisecondSpeed={animationDetail.animationSpeed}
          style={eventDetail.style}
          titleColor={eventDetail.titleColor}
          triggerStyle={eventDetail.triggerStyle}
          onError={handleCreateEventError}
          onSuccessResult={handleCreateEventSuccess}
        />
      )}
      {createRequest && edit && eventId && (
        <UpdateContentEventRequest
          animation={animationDetail.animationType}
          animationDirection={animationDetail.animationDirection}
          assetId={eventDetail.assetId}
          backgroundColor={eventDetail.backgroundColor}
          borderColor={eventDetail.borderColor}
          contentEventId={eventId}
          event={eventDetail.type}
          eventDirection={eventDetail.eventDirection || 'TOP'}
          eventTitle={eventDetail.title}
          iconHorizontalAlignment={eventDetail.iconHorizontalAlignment}
          infinite={animationDetail.infinite}
          isDefaultOpen={eventDetail.isDefaultOpen}
          millisecondDelay={animationDetail.delayTime}
          millisecondSpeed={animationDetail.animationSpeed}
          style={eventDetail.style}
          titleColor={eventDetail.titleColor}
          triggerStyle={eventDetail.triggerStyle}
          onError={handleUpdateEventError}
          onSuccessResult={handleUpdateEventSuccess}
        />
      )}
      {deleteRequest && eventDetail.id && (
        <DeleteContentEventRequest
          contentEventId={eventDetail.id}
          onError={handleDeleteEventError}
          onSuccessResult={handleDeleteEventSuccess}
        />
      )}
      {deleteAssetRequest && section && (
        <DeleteAssetRequest assetId={section.bgImgId} nError={handleDeleteAssetError} onSuccessResult={handleDeleteAssetResponse} />
      )}
    </>
  );
};

SectionSideBar.propTypes = {
  containerNumber: PropTypes.number.isRequired,
  currentSection: PropTypes.shape({
    background: PropTypes.shape({
      assetId: PropTypes.string,
      contentType: PropTypes.string,
      s3Key: PropTypes.string,
    }),
    event: PropTypes.shape({
      animation: PropTypes.string,
      asset: PropTypes.shape({
        assetId: PropTypes.string,
        contentType: PropTypes.string,
        s3Key: PropTypes.string,
      }),

      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
      event: PropTypes.string,
      eventDetail: PropTypes.string,
      eventDirection: PropTypes.string,
      eventTitle: PropTypes.string,
      iconHorizontalAlignment: PropTypes.string,
      id: PropTypes.number,
      infinite: PropTypes.string,
      millisecondDelay: PropTypes.string,
      millisecondSpeed: PropTypes.string,
      style: PropTypes.string,
      titleColor: PropTypes.string,
      triggerStyle: PropTypes.string,
    }),
    layoutId: PropTypes.number,
    style: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
};

SectionSideBar.defaultProps = {};

export default SectionSideBar;
