import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import { NewIcon } from '../MarketplacePage/styled';

import {
  AppImage,
  Button,
  CenterContent,
  ContainerGrow,
  FlexContainer,
  Heading1,
  Heading3,
  Heading4,
  LinkButton,
  OptionsBanner,
  OptionsBannerInner,
  OutlineContainer,
  OverallRating,
  ProjectItem,
  PushRight,
  PushRight1,
  EditButton,
  RatingContainer,
  RatingKey,
  ReturnButton,
  SubTitle,
  BannerBG,
  OrgDropBox,
} from './styled';

import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import DropDownSelect from '../../components/DropDownSelect/DropDownSelect';
import AppCommentSideBar from '../../components/RightNavBar/AppCommentSideBar';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import StarRating from '../../components/StarRating/StarRating';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import EditBtn from '../../components/UI/Button/EditButton';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import GetLinkedProjects from '../../middleware/AppAssets/getAllLinkedProjects';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import AddAppToEntity from '../../middleware/Marketplace/AddAppToEntity';
import GetAppDetails from '../../middleware/Marketplace/GetAppDetails';
import RemoveAppFromEntity from '../../middleware/Marketplace/RemoveAppFromEntity';
import GetOrganisations from '../../middleware/Organisation/getOrganisations';
import GetAllProjects from '../../middleware/Project/getAllProjects';

const cookies = new Cookies();
export default function MarketplaceAppDetailsPage(props) {
  const { match } = props;

  const [appLoaded, setAppLoaded] = useState(false);
  const [orgLoaded, setOrgLoaded] = useState(false);
  const [projectLoaded, setProjectLoaded] = useState(true);
  const [linkLoaded, setLinkLoaded] = useState(false);
  const [linkedProjects, setLinkedProjects] = useState([]);
  const [payload, setPayload] = useState({});
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [commentSideOpen, setCommentSideOpen] = useState(false);
  const [assetSource, setAssetSource] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [userOrgs, setUserOrgs] = useState([]);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [projects, setProjects] = useState([]);

  const [rates, setRates] = useState([]);
  const [selectedComment, setSelectedComment] = useState({});
  const [linkAppRequest, setLinkAppRequest] = useState(false);
  const [linkOrUnlink, setLinkOrUnlink] = useState(true);
  const [linkProjectId, setLinkProjectId] = useState(null);

  const { averageRateValue, description, title } = payload;

  const handlePayload = (data) => {
    if (Object.entries(payload).length === 0) {
      setPayload(data);
      setRates(data.rates);
      setAssetSource(
        data.logo ? `${getSignedUrl(data.logo.s3Key, data.logo.contentType)}` : 'https://dev.assets.ekardo.com/logo/logo-dark.svg',
      );
      const curUser = cookies.get('userData');
      const creator = data.users.find((user) => user.status === 'CREATOR');
      if (creator.userId === curUser.id) setIsOwner(true);
    }
    setAppLoaded(true);
  };

  const currentAppID = parseInt(match.params.appId, 10);

  const handleUserOrgs = (data) => {
    setUserOrgs(data);
    setOrgLoaded(true);
    if (currentOrg === null) {
      setCurrentOrg(data[0]);
    }
  };

  const handleRightPanel = () => setIsPanelOpen(!isPanelOpen);

  const handlePanelOnClose = () => setIsPanelOpen(false);

  const handleOrgId = (value) => {
    setCurrentOrg(value);
    setProjectLoaded(false);
  };

  const handleToggles = (e, project) => {
    const { checked } = e.target;
    setLinkAppRequest(true);
    setLinkOrUnlink(checked);
    setLinkProjectId(project.id);
    const index = projects.findIndex((p) => p.id === project.id);
    const newProjects = projects;
    newProjects.splice(index, 1, {
      ...project,
      isActive: checked,
    });
    setProjects(newProjects);
    // setToggles({ ...toggles, [name]: checked });
  };

  const handleProjects = (data) => {
    const newProjects = data.map((pro) => ({
      ...pro,
      isActive: linkedProjects.find((lp) => lp.id === pro.id) !== undefined,
    }));
    setProjects(newProjects);
    setProjectLoaded(true);
  };

  const handleSidebarClose = (type, data) => {
    if (type === 'create') {
      const tmpUser = cookies.get('user');
      const tmp = rates;
      tmp.push({
        ...data,
        user: {
          firstname: tmpUser.firstname,
          lastname: tmpUser.lastname,
        },
      });
      setRates(tmp);
    }
    if (type === 'delete') {
      let tmp = rates;
      tmp = tmp.filter((t) => t.id !== data.id);
      setRates(tmp);
    }
    if (type === 'update') {
      const index = rates.findIndex((rate) => rate.id === data.id);
      const tmp = rates;
      tmp.splice(index, 1, data);
      setRates(tmp);
    }
    setCommentSideOpen(false);
    setSelectedComment({});
  };

  const handleEditComment = (value) => {
    setSelectedComment(value);
    setCommentSideOpen(true);
  };

  const handleLinkAppError = () => {
    setLinkAppRequest(false);
  };

  const handleLinkAppSuccess = () => {
    setLinkAppRequest(false);
  };

  const handleGetLinkedError = () => {
    setLinkLoaded(true);
  };

  const handleGetLinkedSuccess = (data) => {
    setLinkedProjects(data);
    setLinkLoaded(true);
    setProjectLoaded(false);
  };

  const breadPath = [
    {
      id: 1,
      path: '/marketplace',
      title: 'Marketplace',
    },

    {
      id: 2,
      path: '',
      title: title || 'app',
    },
  ];

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="Manage your app details"
        pageDescription="Manage your app details"
        pageTitle="App details management page"
        params={match.params}
      >
        {commentSideOpen && <AppCommentSideBar comment={selectedComment} identifier={payload.identifier} onClose={handleSidebarClose} />}
        {commentSideOpen && <Backdrop isClicked={handleSidebarClose} />}
        {Object.entries(payload).length !== 0 && (
          <>
            <PushRight1>
              <ReturnButton href="/marketplace">
                <NewIcon>
                  <span className="icon-chevron" />
                </NewIcon>
                Return to store
              </ReturnButton>
            </PushRight1>
            <OptionsBanner>
              <BannerBG />
              <ConsoleContainer>
                <OptionsBannerInner>
                  <FlexContainer>
                    <AppImage src={assetSource} />
                    <div className="u-flex-column">
                      <Heading1>{title}</Heading1>
                      <SubTitle>by</SubTitle>
                      <FlexContainer className="u-push-bottom">
                        <Button aria-haspopup="true" onClick={handleRightPanel}>
                          + Add app to project
                        </Button>
                        <Button aria-haspopup="true" onClick={() => setCommentSideOpen(true)}>
                          + Add comment
                        </Button>
                        {isOwner && (
                          <LinkButton
                            color="#972868"
                            href={`/marketplace/app/${parseInt(match.params.appId, 10)}/versions/${
                              payload.versions[payload.versions.length - 1].id
                            }/components`}
                          >
                            View code
                          </LinkButton>
                        )}
                        {isOwner && (
                          <LinkButton color="#972868" href={`/marketplace/app/${parseInt(match.params.appId, 10)}/versions`}>
                            Versions
                          </LinkButton>
                        )}
                      </FlexContainer>
                    </div>
                  </FlexContainer>

                  <div className="u-flex-column">
                    <OverallRating>
                      <Heading4>Overall rating </Heading4>
                      <StarRating amount={1} rating={averageRateValue} />
                    </OverallRating>
                    <FlexContainer className="u-push-bottom">
                      <PushRight>
                        <LinkButton color="#972868">Fork</LinkButton>
                      </PushRight>
                    </FlexContainer>
                  </div>
                </OptionsBannerInner>
              </ConsoleContainer>
            </OptionsBanner>
            <ConsoleContainer>
              <OutlineContainer>
                <Heading3>Description</Heading3>
                <p>{description}</p>
              </OutlineContainer>

              <FlexContainer>
                <RatingKey>
                  <OverallRating>
                    <Heading4>Overall rating </Heading4>
                    <StarRating amount={1} rating={averageRateValue} />
                  </OverallRating>

                  <StarRating hasNumber={false} rating={5} />
                  <StarRating hasNumber={false} rating={4} />
                  <StarRating hasNumber={false} rating={3} />
                  <StarRating hasNumber={false} rating={2} />
                  <StarRating hasNumber={false} rating={1} />
                </RatingKey>

                <ContainerGrow>
                  {rates.length === 0 && (
                    <CenterContent>
                      <Heading4>No comments available</Heading4>
                    </CenterContent>
                  )}
                  {rates.map((item) => (
                    <RatingContainer key={item.id}>
                      <div>
                        <Heading4>{item.title}</Heading4>
                        <SubTitle>
                          {item.user && Object.keys(item.user).length > 0 ? `by ${item.user.firstname} ${item.user.lastname}` : ''}
                        </SubTitle>
                        <p>{item.comment}</p>
                      </div>
                      <OverallRating>
                        <StarRating rating={item.rate} />
                      </OverallRating>
                      <EditButton>
                        <EditBtn isClicked={() => handleEditComment(item)} />
                      </EditButton>
                    </RatingContainer>
                  ))}
                </ContainerGrow>
              </FlexContainer>
            </ConsoleContainer>
          </>
        )}

        <SideDrawer isOpen={isPanelOpen} onClose={handlePanelOnClose}>
          <Heading4>Add to project/s</Heading4>

          {currentOrg !== null && (
            <>
              <OrgDropBox>
                <p>Organisation:</p>
                <DropDownSelect label="Version type" name="version" value={currentOrg.name}>
                  <ul>
                    {userOrgs.map((org) => (
                      <li key={org.id} role="menuitem" onClick={() => handleOrgId(org)} onKeyPress={() => handleOrgId(org)}>
                        {org.name}
                      </li>
                    ))}
                  </ul>
                </DropDownSelect>
              </OrgDropBox>

              <ul style={{ paddingLeft: '0' }}>
                {projects.length === 0 && <ProjectItem>No projects available.</ProjectItem>}
                {projects.map((project) => (
                  <ProjectItem key={project.id}>
                    {project.name}
                    <ToggleSwitch
                      checkedColor="#1e0059"
                      isChecked={project.isActive}
                      name={project.name}
                      uncheckedColor="#FA6980"
                      onChange={(e) => handleToggles(e, project)}
                    />
                  </ProjectItem>
                ))}
              </ul>
            </>
          )}
        </SideDrawer>
      </PageWrapper>
      {!appLoaded && <GetAppDetails id={currentAppID} onError={(error) => error} onSuccess={handlePayload} />}
      {!orgLoaded && <GetOrganisations onError={(err) => err} onSuccessResult={handleUserOrgs} />}
      {!linkLoaded && <GetLinkedProjects appId={currentAppID} onError={handleGetLinkedError} onSuccessResult={handleGetLinkedSuccess} />}
      {!projectLoaded && userOrgs.length > 0 && (
        <GetAllProjects id={currentOrg.id} onError={(err) => err} onSuccessResult={handleProjects} />
      )}
      {linkAppRequest && linkOrUnlink && (
        <AddAppToEntity
          appId={payload.identifier}
          eId={linkProjectId}
          eType="PROJECT"
          onError={handleLinkAppError}
          onSuccess={handleLinkAppSuccess}
        />
      )}
      {linkAppRequest && !linkOrUnlink && (
        <RemoveAppFromEntity
          appId={payload.identifier}
          eId={linkProjectId}
          eType="PROJECT"
          onError={handleLinkAppError}
          onSuccess={handleLinkAppSuccess}
        />
      )}
    </>
  );
}

MarketplaceAppDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      owner: PropTypes.bool,
    }),
  }),
};

MarketplaceAppDetailsPage.defaultProps = {
  location: undefined,
};
