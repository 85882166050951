import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  CREATE_CONDITION_ON_ACTION_CHECKBOX,
  CREATE_CONDITION_ON_ACTION_RADIO,
  CREATE_CONDITION_ON_ACTION_SLIDER,
  CREATE_CONDITION_ON_ACTION_TEXT,
  CREATE_CONDITION_ON_ACTION_DATE,
} from './UseCreateCondition.graphql';

const useCreateCondition = (options) => {
  const { mutation, onCompleted, onError, token, conditionType } = options ?? {};

  const addConditionMutationQueryMap = {
    checkbox: CREATE_CONDITION_ON_ACTION_CHECKBOX,
    radio: CREATE_CONDITION_ON_ACTION_RADIO,
    slider: CREATE_CONDITION_ON_ACTION_SLIDER,
    text: CREATE_CONDITION_ON_ACTION_TEXT,
    calendar: CREATE_CONDITION_ON_ACTION_DATE,
  };

  const [executeCreateCondition, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? addConditionMutationQueryMap[conditionType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { addConditionOnAction: condition },
        } = data;

        onCompleted({
          condition,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateCondition = ({ variables }) => {
    executeCreateCondition({
      variables,
    });
  };

  return {
    handleCreateCondition,
    isLoading,
  };
};

export { useCreateCondition };
