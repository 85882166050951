import gql from 'graphql-tag';

export const GET_COUNTRIES = gql`
  query getCountries {
    __type(name: "ECountries") {
      enumValues {
        name
        description
      }
    }
  }
`;
