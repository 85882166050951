// Slider Sidebar
export const animationOptions = [
  {
    id: 0,
    value: 'FADE',
    label: 'Fade',
  },
  {
    id: 1,
    value: 'LINEAR',
    label: 'Linear',
  },
];

// Content Event Sidebar
export const styleTypeOptions = [
  /*
  {
    id: 0,
    value: 'NONE',
    label: 'None',
  },
  */
  {
    id: 0,
    value: 'TEXT_ONLY',
    label: 'Text',
  },
  {
    id: 1,
    value: 'TEXT_AND_ICON',
    label: 'Text & custom icon',
  },
  {
    id: 2,
    value: 'ICON_ONLY',
    label: 'Custom icon',
  },
];

export const triggerStyleTypeOptions = [
  {
    id: 0,
    value: 'CIRCLE_ARROW',
    label: 'Circle arrow',
  },
  {
    id: 1,
    value: 'ARROW',
    label: 'Arrow',
  },
  {
    id: 2,
    value: 'CIRCLE_PLUS',
    label: 'Circle plus',
  },
  {
    id: 3,
    value: 'PLUS',
    label: 'Plus',
  },
];

export const typeOptions = [
  {
    id: 0,
    value: 'NONE',
    label: 'None',
  },
  {
    id: 1,
    value: 'REVEAL',
    label: 'Reveal',
  },
  {
    id: 2,
    value: 'POP_UP',
    label: 'Pop up',
  },
  {
    id: 3,
    value: 'FLIP',
    label: 'Flip',
  },
];

export const directionOptions = [
  {
    id: 0,
    value: 'TOP',
    label: 'Top',
  },
  {
    id: 1,
    value: 'RIGHT',
    label: 'Right',
  },
  {
    id: 2,
    value: 'LEFT',
    label: 'Left',
  },
  {
    id: 3,
    value: 'BOTTOM',
    label: 'Bottom',
  },
];

export const animationTypeOptions = [
  {
    id: 0,
    value: 'NONE',
    label: 'None',
  },
  {
    id: 1,
    value: 'BOUNCE_IN',
    label: 'Bounce In',
  },
  {
    id: 2,
    value: 'FADE_IN',
    label: 'Fade In',
  },
  {
    id: 3,
    value: 'ZOOM_IN',
    label: 'Zoom In',
  },
  {
    id: 4,
    value: 'SLIDE_IN',
    label: 'Slide In',
  },
  {
    id: 5,
    value: 'ROTATE_IN',
    label: 'Rotate In',
  },
  // The following types don't have direction.
  {
    id: 6,
    value: 'BOUNCE',
    label: 'Bounce',
  },
  {
    id: 7,
    value: 'FLASH',
    label: 'Flash',
  },
  {
    id: 8,
    value: 'PULSE',
    label: 'Pulse',
  },
  {
    id: 9,
    value: 'RUBBER_BAND',
    label: 'Rubber band',
  },
  {
    id: 10,
    value: 'SHAKE',
    label: 'Shake',
  },
  {
    id: 11,
    value: 'HEAD_SHAKE',
    label: 'Head Shake',
  },
  {
    id: 12,
    value: 'SWING',
    label: 'Swing',
  },
  {
    id: 13,
    value: 'TADA',
    label: 'Tada',
  },
  {
    id: 14,
    value: 'WOBBLE',
    label: 'Wobble',
  },
  {
    id: 15,
    value: 'JELLO',
    label: 'Jello',
  },
  {
    id: 16,
    value: 'HINGE',
    label: 'Hinge',
  },
  {
    id: 17,
    value: 'HEART_BEAT',
    label: 'Heart Beat',
  },
  {
    id: 18,
    value: 'JACK_IN_THE_BOX',
    label: 'Jack In The Box',
  },
];

export const delayTimeOptions = [
  {
    id: 0,
    value: 1000,
    label: '1s',
  },
  {
    id: 1,
    value: 2000,
    label: '2s',
  },
  {
    id: 2,
    value: 3000,
    label: '3s',
  },
  {
    id: 3,
    value: 4000,
    label: '4s',
  },
  {
    id: 4,
    value: 5000,
    label: '5s',
  },
];

export const animationSpeedOptions = [
  {
    id: 0,
    value: 500,
    label: 'faster',
  },
  {
    id: 1,
    value: 800,
    label: 'fast',
  },
  {
    id: 2,
    value: 1000,
    label: 'default',
  },
  {
    id: 3,
    value: 2000,
    label: 'slow',
  },
  {
    id: 4,
    value: 3000,
    label: 'slower',
  },
];

export const questionActions = [
  {
    id: 0,
    value: 'actionHide',
    label: 'hide snippet',
  },
  {
    id: 1,
    value: 'actionShow',
    label: 'show snippet',
  },
  {
    id: 2,
    value: 'skipToSnippet',
    label: 'skip to snippet',
  },
  {
    id: 3,
    value: 'skipToPage',
    label: 'skip to page',
  },
];
