import React from 'react';

const Chat = ({ text, top, left, width, isReceived = false }) => {
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);

  return (
    <div
      className={`c-cartoon-chat ${isReceived ? 'c-cartoon-chat--left-aligned' : ''}`}
      style={{
        top: isYAxisSpecified && `${top}%`,
        left: isXAxisSpecified && `${left}%`,
        width: isWidthSpecified && `${width}%`,
      }}
    >
      <div
        className="c-cartoon-chat__text"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </div>
  );
};

export { Chat };
