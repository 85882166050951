import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONDITION_TEMPLATES } from './UseGetConditionTemplates.graphql';

const useGetConditionTemplates = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};
  const [executeGetConditionTemplates, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { getConditions: conditions },
        } = data;

        onCompleted({
          conditions,
        });
      },
      onError,
    },
    query: query ?? GET_CONDITION_TEMPLATES,
    token,
  });

  const handleGetConditionTemplates = ({ variables }) => {
    executeGetConditionTemplates({
      variables,
    });
  };

  return {
    handleGetConditionTemplates,
    isLoading,
  };
};

export { useGetConditionTemplates };
