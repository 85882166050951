import React, { useEffect, useState } from 'react';

import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { useDebouncedCallback } from 'use-debounce';

import { DEFAULT_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_API_ERROR_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerMultiLineTextSnippetProps } from './QuestionAnswerMultiLineTextSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerMutationGraphQLResponse,
  ISetUserAnswerTextMutationVariables,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import { IContentPage, IQuestionAnswerMultiLineText, IQuestionConfigurationMultiLineText } from '../../../../interfaces';
import { MultiLineTextSnippet } from '../../MultiLineTextSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerMultiLineTextSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  message = DEFAULT_MESSAGE,
  userFlowStepTrackId,
}: QuestionAnswerMultiLineTextSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, mandatory: isMandatory = false, message: contentSnippetMessage } = contentSnippet ?? {};
  const { text = '' } = answer ?? ({} as IQuestionAnswerMultiLineText);
  const { maxLength, minLength } = configuration ?? ({} as IQuestionConfigurationMultiLineText);

  const [currentMessage, setCurrentMessage] = useState<IMessage>(message);
  const [savedText, setSavedText] = useState<string>(text);

  const [executeSetUserAnswerMultiLineText] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerTextMutationVariables>(
    {
      mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerMultiLineText,
      options: {
        onCompleted: () => {
          saveContentSnippetQuestionAndApplyQuestionActions({
            actions,
            answer,
            contentPage,
            contentSnippetId,
            dispatch,
            selectedQuestionAnswers: {
              text: savedText,
            },
          });

          setCurrentMessage({
            error: '',
            success: DEFAULT_API_SUCCESS_MESSAGE,
          });
        },
        onError: (error) => {
          handleApolloError(error);
          setCurrentMessage({
            error: DEFAULT_API_ERROR_MESSAGE,
            success: '',
          });
        },
      },
      token: accessToken,
    }
  );

  const clearMessage = () => {
    setCurrentMessage(DEFAULT_MESSAGE);
  };

  const debouncedMutation = useDebouncedCallback((inputText: string) => {
    if (!contentSnippetFormId) {
      return;
    }

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    const variables: ISetUserAnswerTextMutationVariables = {
      ...baseVariables,
      text: inputText,
    };

    executeSetUserAnswerMultiLineText({
      variables,
    });
  }, API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS);

  const handleTextChange = (inputText: string) => {
    clearMessage();
    debouncedMutation(inputText);
    setSavedText(inputText);
  };

  useEffect(() => {
    if (!contentSnippetMessage) {
      return;
    }

    setCurrentMessage(contentSnippetMessage);
  }, [contentSnippetMessage]);

  const isSummary = Boolean(contentSnippetQuestion);

  return (
    <MultiLineTextSnippet
      id={`multi-line-text-snippet-${contentSnippetId}`}
      isDisabled={isSummary}
      isRequired={isMandatory}
      maxLength={maxLength}
      message={currentMessage}
      minLength={minLength}
      value={savedText}
      onChange={(value) => handleTextChange(value)}
    />
  );
};

export { QuestionAnswerMultiLineTextSnippet };
