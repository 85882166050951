import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ORG_INVITATION_LIST_REQUEST = gql`
  query getOrgInvitationListRequest($projectId: String!) {
    invitation {
      getInvitationsWithinProject(projectId: $projectId) {
        id
        invitedUserEmail: emailUserInvited
        organisationId
        firstName
        lastName
        permission
      }
    }
  }
`;

const getProjectInvitationListRequest = ({
  projectId,
  onError,
  onSuccessResult,
  pending,
}) => (
  <Query
    client={Client()}
    fetchPolicy="network-only"
    query={GET_ORG_INVITATION_LIST_REQUEST}
    variables={{ projectId, pending }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.invitation.getInvitationsWithinProject
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getProjectInvitationListRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  pending: PropTypes.bool,
};

getProjectInvitationListRequest.defaultProps = {
  pending: true,
};

export default getProjectInvitationListRequest;
