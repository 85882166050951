import { subMonths } from 'date-fns';

// TODO: Move these message constants to a more suitable place
const ERROR_MESSAGE = 'Something went wrong! Please try again later.';
const NO_RECORDS_FOUND = 'No records found';

const DEFAULT_FILTER_DATES = {
  endDate: new Date(),
  startDate: subMonths(new Date(), 3),
};

const POST_TABLE_COLUMNS = [
  {
    Header: 'User / Alias',
    accessor: 'author',
    width: '15%',
  },
  {
    Header: 'Post',
    accessor: 'message',
    width: '35%',
  },
  {
    Header: 'Comments',
    accessor: 'commentCount',
    className: 'c-table-cell__text-align-right',
    width: '15%',
  },
  {
    Header: 'Attachment',
    accessor: 'attachment',
    className: 'c-table-cell__text-align-right',
    width: '15%',
  },
  {
    Header: 'Date',
    accessor: 'createdDate',
    width: '12.5%',
  },
  {
    Header: '',
    accessor: 'roundSettingsButton',
    width: '7.5%',
  },
];

const USER_TABLE_COLUMNS = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: '20%',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: '20%',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '40%',
  },
  {
    Header: 'Posts',
    accessor: 'postCount',
    className: 'c-table-cell__text-align-right',
    width: '12.5%',
  },
  {
    Header: '',
    accessor: 'roundSettingsButton',
    width: '7.5%',
  },
];

export { DEFAULT_FILTER_DATES, ERROR_MESSAGE, NO_RECORDS_FOUND, POST_TABLE_COLUMNS, USER_TABLE_COLUMNS };
