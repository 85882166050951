import React, { useEffect, useRef, useState } from 'react';

import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const AudioControls = ({ src }) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (!audioRef.current) return;

    const method = isAudioPlaying ? 'play' : 'pause';

    audioRef.current[method]();
  }, [isAudioPlaying]);

  const handleToggleAudio = () => {
    setIsAudioPlaying(!isAudioPlaying);
  };

  useEffect(() => {
    if (!audioRef.current) return;

    const handleSrcChange = () => {
      audioRef.current.pause(); // Pause the audio
      audioRef.current.currentTime = 0; // Reset the audio time
      audioRef.current.load(); // Load the new audio source
      setIsAudioPlaying(false); // Reset the playing state
    };

    handleSrcChange();
  }, [src]);

  const isAudioPlayingPrefix = isAudioPlaying ? 'Pause' : 'Play';

  return (
    <div className="flex-center-center h-with-2x-large-y-padding">
      {!src ? (
        <strong className="margin-right-1x">No audio</strong>
      ) : (
        <>
          <audio ref={audioRef} className="h-hide" controls>
            <source src={src} type="audio/mpeg" />
          </audio>
          <div className="flex-center-center space-between">
            <strong className="margin-right-1x">{isAudioPlayingPrefix} audio</strong>
            <ToggleSwitch
              checkedColor="#1e0059"
              isChecked={isAudioPlaying}
              name="Audio toggle"
              uncheckedColor="#b2b0a7"
              onChange={handleToggleAudio}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AudioControls;
