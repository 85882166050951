import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_IMAGE_SNIPPET = gql`
  mutation updateQuestionContentSnippet($snippetId: Int!, $width: Int!, $height: Int!, $assetId: String!, $title: String!) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId, entityTitle: $title }
        contentSnippetImage: { width: $width, height: $height, assetId: $assetId }
      ) {
        id
        sort
        visible
      }
    }
  }
`;

const updateContentImageSnippetReq = ({ snippetId, width, height, assetId, onSuccessResult, onError }) => (
  <Mutation mutation={UPDATE_CONTENT_IMAGE_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        width,
        height,
        assetId,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id, data.contentSnippet.updateContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateContentImageSnippetReq.propTypes = {
  assetId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default updateContentImageSnippetReq;
