import styled from 'styled-components';

export const CardsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -1.7em;
  @media (max-width: 768px) {
    padding-bottom: 8em;
  }
`;

export const CardAvatar = styled.div`
  align-items: center;
  background: transparent linear-gradient(65deg, #4bc1c9 0%, #4bc1d9 68%);
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.6em;
  font-weight: 600;
  height: 100%;
  max-height: 50px;
  justify-content: center;
  margin: 0 auto 0.6em auto;
  max-width: 50px;
  width: 100%;
`;

export const CardInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
`;
