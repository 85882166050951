/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  LandingImage,
  LandingDetails,
  PlusBtnWrapper1,
  PlusBtnWrapper2,
  AddFirstPage,
} from './styled';

import { FadeIn } from '../../components/UI/Animations/FadeIn/FadeIn';
import PlusBtn from '../../components/UI/Button/PlusButtonCircle';
import Input from '../../components/UI/Input/InputWhite';
import CreateContentPageRequest from '../../middleware/ContentPage/createContentPageRequest';
import ContentLanding from '../../static/img/content-landing.svg';
import { convertToURL } from '../../utils/utils';

class FirstPageOfContentGroup extends Component {
  constructor(props) {
    super(props);
    const { onFirstPageCreationSuccess, contentGroupId } = props;

    this.state = {
      onFirstPageCreationSuccess,
      contentGroupId,
      firstPage: {
        hasFirstPage: false,
        name: '',
        createRequest: false,
      },
    };
  }

  onFirstPageName = value => {
    const { firstPage } = this.state;
    this.setState({
      firstPage: {
        ...firstPage,
        name: value,
      },
    });
  };

  handleAddFirstPage = () => {
    const { firstPage } = this.state;
    this.setState({
      firstPage: {
        ...firstPage,
        hasFirstPage: true,
      },
    });
  };

  handleFirstPageName = value => {
    const { firstPage } = this.state;
    this.setState({
      firstPage: {
        ...firstPage,
        name: value,
      },
    });
  };

  handleCreateFirstPage = () => {
    const { firstPage } = this.state;
    this.setState({
      firstPage: {
        ...firstPage,
        createRequest: true,
      },
    });
  };

  handleFirstPageError = () => {
    const { firstPage } = this.state;
    this.setState({
      firstPage: {
        ...firstPage,
        createRequest: false,
      },
    });
  };

  handleFirstPage = data => {
    const { firstPage, activePageId } = this.state;
    if (!activePageId) {
      this.setState({
        firstPage: {
          ...firstPage,
          createRequest: false,
        },
        activePageId: data,
      });

      const { onFirstPageCreationSuccess } = this.state;
      onFirstPageCreationSuccess(data);
    }
  };

  render() {
    const { firstPage, contentGroupId } = this.state;
    return (
      <>
        <FadeIn>
          <LandingImage alt="add page" src={ContentLanding} />
          <LandingDetails>
            <h4>Getting started</h4>
            <p>
              Get building by hitting new page below. Need some help? Check out
              our help guide.
            </p>
            {firstPage.hasFirstPage ? (
              <AddFirstPage>
                <Input isChanged={this.onFirstPageName} />
                <PlusBtnWrapper1>
                  <PlusBtn onClicked={this.handleCreateFirstPage} />
                </PlusBtnWrapper1>
              </AddFirstPage>
            ) : (
              <PlusBtnWrapper2>
                <PlusBtn onClicked={this.handleAddFirstPage} />
              </PlusBtnWrapper2>
            )}
          </LandingDetails>
        </FadeIn>
        {firstPage.createRequest && (
          <CreateContentPageRequest
            contentGroupId={contentGroupId}
            onError={this.handleFirstPageError}
            onSuccessResult={this.handleFirstPage}
            title={firstPage.name}
            url={convertToURL(firstPage.name)}
          />
        )}
      </>
    );
  }
}

FirstPageOfContentGroup.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onFirstPageCreationSuccess: PropTypes.func.isRequired,
};

export default FirstPageOfContentGroup;
