import React from 'react';

import PropTypes from 'prop-types';

function PopupMessage(props) {
  const { message, isError } = props;
  return (
    <div
      className={`c-popup-message ${isError ? 'c-popup-message-error' : ''}`}
    >
      <p>{message}</p>
    </div>
  );
}

PopupMessage.propTypes = {
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool,
};

PopupMessage.defaultProps = {
  isError: false,
};

export default PopupMessage;
