import { NetfrontServices } from '../../../../constants/NetfrontServices';
import IconBounced from '../../../../static/img/invitationIcons/icon-bounced.svg';
import IconClicked from '../../../../static/img/invitationIcons/icon-clicked.svg';
import IconComplaint from '../../../../static/img/invitationIcons/icon-complaint.svg';
import IconDelivered from '../../../../static/img/invitationIcons/icon-delivered.svg';
import IconNotClicked from '../../../../static/img/invitationIcons/icon-not-clicked.svg';
import IconOpened from '../../../../static/img/invitationIcons/icon-opened.svg';
import IconRejected from '../../../../static/img/invitationIcons/icon-rejected.svg';
import IconSent from '../../../../static/img/invitationIcons/icon-sent.svg';

const { BONOBO, EKARDO } = NetfrontServices;

export const userTableHeadings = [
  {
    Header: 'First name',
    accessor: 'firstName',
    width: '14.2%',
    service: EKARDO,
  },
  {
    Header: 'Last name',
    accessor: 'lastName',
    width: '14.2%',
    service: EKARDO,
  },
  {
    Header: 'Alias',
    accessor: 'alias',
    width: '14.2%',
    service: BONOBO,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '14.2%',
    service: EKARDO,
  },
  {
    Header: 'Group',
    accessor: 'group',
    width: '14.2%',
    service: EKARDO,
  },

  {
    Header: 'User Type',
    accessor: 'userType',
    width: '14.2%',
    service: EKARDO,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '14.2%',
    service: EKARDO,
  },
  {
    Header: '',
    accessor: 'button',
    width: '14.2%',
    service: EKARDO,
  },
];

export const pendingStatusHeadings = [
  {
    Header: 'First name',
    accessor: 'firstName',
    width: '12.5%',
    service: EKARDO,
  },
  {
    Header: 'Last name',
    accessor: 'lastName',
    width: '12.5%',
    service: EKARDO,
  },
  {
    Header: 'Alias',
    accessor: 'alias',
    width: '12.5%',
    service: BONOBO,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '12.5%',
    service: EKARDO,
  },
  {
    Header: 'Group',
    accessor: 'group',
    width: '12.5%',
    service: EKARDO,
  },

  {
    Header: '',
    accessor: 'invitation',
    width: '12.5%',
    service: EKARDO,
  },
  {
    Header: 'User Type',
    accessor: 'userType',
    width: '12.5%',
    service: EKARDO,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '12.5%',
    service: EKARDO,
  },
  {
    Header: '',
    accessor: 'button',
    width: '12.5%',
    service: EKARDO,
  },
];

export const EMAIL_ICONS = {
  SENT: IconSent,
  DELIVERY: IconDelivered,
  COMPLAINT: IconComplaint,
  BOUNCE: IconBounced,
  OPEN: IconOpened,
  REJECT: IconRejected,
  CLICK: IconClicked,
  NOT_CLICKED: IconNotClicked,
};

export const EMAIL_ICON_TOOLTIPS = {
  SENT: 'Sent',
  DELIVERY: 'Delivered',
  COMPLAINT: 'Complaint',
  BOUNCE: 'Bounced',
  OPEN: 'Opened',
  REJECT: 'Rejected',
  CLICK: 'Clicked',
  NOT_CLICKED: 'Not Clicked',
};
