import React, { useState } from 'react';

import cx from 'classnames';

import { Icon } from '../Icon';
import { Skeleton } from '../Skeleton';

import { TooltipProps } from './Tooltip.types';

import './Tooltip.css';

const Tooltip = ({
  additionalClassNames,
  bubbleTheme = 'light',
  iconId = 'id_tooltip_icon',
  isShownOnHover = true,
  placement = 'left',
  text,
  isLoading = false,
}: TooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const isClickOnly = !isShownOnHover && isTooltipVisible;

  const handleTrigger = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  if (isLoading) return <Skeleton additionalClassNames={cx('c-tooltip', additionalClassNames)} />;

  return (
    <span className={cx('c-tooltip', additionalClassNames)} data-testid="qa-tooltip">
      <button
        aria-label="more info"
        className={cx('c-tooltip__trigger', `c-tooltip__trigger--is-${isShownOnHover ? 'hover-and-click' : 'click-only'}`)}
        type="button"
        onClick={handleTrigger}
      >
        <Icon className="c-tooltip__icon" id={iconId} />
      </button>
      <span
        className={cx('c-tooltip__bubble', `c-tooltip__bubble--${placement}`, `c-tooltip__bubble--${bubbleTheme}`, {
          'c-tooltip__trigger--is-click-only': isClickOnly,
        })}
        role="status"
      >
        {text}
      </span>
    </span>
  );
};

export { Tooltip };
