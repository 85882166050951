import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import VideoIcon from '../../static/img/videoIcon.png';

//   width: 100%;
//   height: auto;
// `;

const Wrapper = styled.div`
  /* display: flex;
  flex-flow: column;
  width: fit-content;
  margin: 0 auto; */
  background-image: url(${VideoIcon});
  background-repeat: no-repeat;
  background-size: contain;
  height: 3em;

  /* overflow: hidden; */
`;

const P = styled.p`
  margin-left: 4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
`;

const VideoSnippet = props => {
  const { asset } = props;
  const index = asset.s3Key.lastIndexOf('/') + 1;
  const filename = asset.s3Key.substr(index);

  // useEffect(() => {
  //   if (url !== tmpURL) {
  //     setUrl(tmpURL);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tmpURL]);
  return (
    // <Video controls src={tmpURL}>
    //   <track kind="captions" srcLang="en" />
    // </Video>
    <Wrapper>
      <P>{filename}</P>
    </Wrapper>
  );
};
// class VideoSnippet extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { url : '' };
//   }
//   componentDidMount() {
//     const {asset} = this.props;

//   }
// }

VideoSnippet.propTypes = {
  asset: PropTypes.shape({
    contentType: PropTypes.string,
    s3Key: PropTypes.string,
  }).isRequired,
};

export default VideoSnippet;
