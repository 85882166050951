import React from 'react';

import cx from 'classnames';

import './FlexContainer.css';

import { FlexContainerProps } from './FlexContainer.types';

const FlexContainer = ({
  additionalClassNames = '',
  alignItems = 'center',
  children,
  flexDirection = 'row',
  justifyContent = 'space-between',
  gap = 'none',
  isFullWidth = false,
  isWrappable = false,
  tag = 'div',
  isChildrenEvenlySized = false,
}: FlexContainerProps) => {
  const Tag = tag;
  return (
    <Tag
      className={cx('c-flex-container', additionalClassNames, {
        [`c-flex-container--gap-${gap}`]: gap,
        'c-flex-container--wrapped': isWrappable,
        'h-full-width': isFullWidth,
        'c-flex-container--even-children': isChildrenEvenlySized,
      })}
      style={{
        flexDirection,
        justifyContent,
        alignItems,
      }}
    >
      {children}
    </Tag>
  );
};

export { FlexContainer };
