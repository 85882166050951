import gql from 'graphql-tag';

export const EXPORT_GROUPS_CONTACTS = gql`
  query ExportGroupContacts($projectId: String!, $fileType: FileType, $ianaTimeZone: String!) {
    report {
      groupContacts(projectId: $projectId, fileType: $fileType, ianaTimeZone: $ianaTimeZone) {
        contentType
        s3Key
      }
    }
  }
`;
