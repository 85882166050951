import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_BUTTON_CONTENT_SNIPPET = gql`
  mutation createButtonContentSnippet(
    $containerId: Int!
    $visible: Boolean!
    $buttonBackgroundColor: String!
    $borderColor: String!
    $fontColor: String!
    $hoverColor: String!
    $hyperlink: String!
    $innerText: String!
    $pageId: Int
    $target: String!
    $style: EContentSnippetButtonStyle
    $title: String!
  ) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible, entityTitle: $title }
        contentSnippetButton: {
          buttonBackgroundColor: $buttonBackgroundColor
          fontColor: $fontColor
          hoverColor: $hoverColor
          hyperlink: $hyperlink
          innerText: $innerText
          pageId: $pageId
          target: $target
          style: $style
          backgroundColor: $buttonBackgroundColor
          borderColor: $borderColor
        }
      ) {
        id
        sort
      }
    }
  }
`;

const createButtonContentSnippetRequest = ({
  containerId,
  isVisible,
  buttonBackgroundColor,
  fontColor,
  hoverColor,
  hyperlink,
  innerText,
  pageId,
  target,
  borderColor,
  style,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_BUTTON_CONTENT_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        visible: isVisible,
        buttonBackgroundColor,
        fontColor,
        hoverColor,
        borderColor,
        hyperlink,
        innerText,
        pageId,
        target,
        style,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.createContentSnippet.id, data.contentSnippet.createContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createButtonContentSnippetRequest.propTypes = {
  borderColor: PropTypes.string.isRequired,
  buttonBackgroundColor: PropTypes.string.isRequired,
  containerId: PropTypes.number.isRequired,
  fontColor: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
  hyperlink: PropTypes.string.isRequired,
  innerText: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  style: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  pageId: PropTypes.number,
};

createButtonContentSnippetRequest.defaultProps = {
  pageId: null,
};

export default createButtonContentSnippetRequest;
