import React, { useContext } from 'react';

import { SideBar } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { UpsertCodeSnippetSidebar } from '../UpsertCodeSnippetSidebar';
import { UpsertSummarySnippetSidebar } from '../UpsertSummarySnippetSidebar';

import AppContext from '../../../../legacy/context/AppContext';
import { SnippetTypeConst } from '../../../constants';

const CustomSettingsSidebar = (props) => {
  const { containerId, onClose, onCreate, onRefreshPage, onDelete, snippet, isOpen = false } = props;
  const { customSettingsType, setCustomSettingsType } = useContext(AppContext);

  const SnippetTypeSidebarMap = {
    [SnippetTypeConst.SUMMARY]: UpsertSummarySnippetSidebar,
    [SnippetTypeConst.CODE]: UpsertCodeSnippetSidebar,
  };

  const CustomSidebar = SnippetTypeSidebarMap[customSettingsType];

  return (
    <SideBar
      isSideBarOpen={isOpen}
      onClose={() => {
        setCustomSettingsType(undefined);
        onClose();
      }}
    >
      {CustomSidebar && (
        <CustomSidebar
          containerId={containerId}
          isAddNew={Boolean(!snippet)}
          snippet={snippet}
          onClose={() => {
            setCustomSettingsType(undefined);
            onClose();
          }}
          onCreate={onCreate}
          onDelete={onDelete}
          onRefreshPage={onRefreshPage}
        />
      )}
    </SideBar>
  );
};

CustomSettingsSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  containerId: PropTypes.number,
  isOpen: PropTypes.bool,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  snippet: PropTypes.shape(),
};

export { CustomSettingsSidebar };
