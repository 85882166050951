import gql from 'graphql-tag';

export const EXPORT_TRIPLEE_LOG_ACCESS = gql`
  query exportLogAccess($dateFrom: DateTime!, $dateTo: DateTime!) {
    report {
      exportLogAccess(start: $dateFrom, end: $dateTo) {
        contentType
        s3Key
      }
    }
  }
`;
