import gql from 'graphql-tag';

export const GET_CONTENT_PAGE = gql`
  query getContentPage($contentPageId: Int!) {
    contentPage {
      getContentPage(contentPageId: $contentPageId) {
        actions {
          id
          ... on QuestionActionShowSectionType {
            sectionId
            title
          }
          ... on QuestionActionHideSectionType {
            sectionId
            title
          }
          ... on QuestionActionHideContainerType {
            containerId
            title
          }
          ... on QuestionActionShowContainerType {
            containerId
            title
          }
          ... on QuestionActionShowType {
            targetSnippetId
            title
          }
          ... on QuestionActionHideType {
            targetSnippetId
            title
          }

          ... on QuestionActionSkipPageType {
            contentPageId
            title
          }

          ... on QuestionActionTriggerContentEventType {
            title
            contentEventId
            type
          }
          conditions {
            id
            title
            contentPageId
            contentSnippetQuestionId
            ... on QuestionConditionRadioResponseSelectedType {
              questionResponseRadioId
              selection
            }
            ... on QuestionConditionTextType {
              text
            }
            ... on QuestionConditionSliderValueSelectedType {
              maxValue
              minValue
            }
            ... on QuestionConditionCheckboxSelectedType {
              questionResponseCheckboxId
              selection
            }

            ... on QuestionConditionDateSelectedType {
              dateRangeType: type
              minDate
              maxDate
              selectedDate
              minTimeSpan
              maxTimeSpan
            }
          }
        }
      }
    }
  }
`;
