import React, { useState } from 'react';

import { Textarea, ToggleSwitch, Spacing, InformationBox } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import ButtonGroup from '../../../../legacy/components/ButtonGroup/ButtonGroup';

const UpsertCodeSnippetGeneralTab = (props) => {
  const { onClose, onSave, onChange, onDelete, snippet, initialHtmlContent = '' } = props;

  const [htmlContent, setHtmlContent] = useState(initialHtmlContent);
  const [isShowPreview, setIsShowPreview] = useState(false);

  const handleChange = (e) => {
    onChange(e.target.value);
    setHtmlContent(e.target.value);
  };

  return (
    <>
      <Spacing>
        <InformationBox message="Edit your code below." />
      </Spacing>
      <Spacing>
        <ToggleSwitch
          additionalClassNames="c-code-toggle"
          id="id_code_preview"
          isChecked={isShowPreview}
          labelText="Show preview"
          isInline
          onChange={() => setIsShowPreview(!isShowPreview)}
        />
      </Spacing>
      {!isShowPreview ? (
        <Textarea id="id_code_snippet_textarea" labelText="Content" name="new_code_content" value={htmlContent} onChange={handleChange} />
      ) : (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
      )}

      {/* Switch ButtonGroup for TabsetButtons when switching to V3 */}
      <ButtonGroup
        hasConfirmButton
        onCancel={onClose}
        onDelete={
          snippet
            ? () => {
                onDelete(snippet);
              }
            : undefined
        }
        onSave={onSave}
      />
    </>
  );
};

UpsertCodeSnippetGeneralTab.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialHtmlContent: PropTypes.string,
  onDelete: PropTypes.func,
  snippet: PropTypes.shape(),
};

export { UpsertCodeSnippetGeneralTab };
