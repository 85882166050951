import React from 'react';

import cx from 'classnames';

import { Icon } from '../Icon';

import { InputAddOnProps } from './InputAddOn.types';

import './InputAddOn.css';

const InputAddOn = ({
  additionalClassNames,
  iconId,
  onClick,
  position,
  theme = 'default',
  helperText,
  isVisuallyHidden = false,
}: InputAddOnProps) => {
  const classNames = cx('c-add-on', `c-add-on--${position}`, `c-add-on--${theme}`, additionalClassNames);

  if (isVisuallyHidden)
    return (
      <div className={classNames}>
        <span className="c-add-on--placeholder"></span>
      </div>
    );

  if (iconId && onClick)
    return (
      <button className={classNames} type="button" onClick={onClick}>
        <span className="h-hide-visually">{helperText}</span>
        <Icon className="c-add-on__icon" id={iconId} />
      </button>
    );
  if (iconId)
    return (
      <div className={classNames}>
        <Icon className="c-add-on__icon" id={iconId} />
      </div>
    );

  return <></>;
};

export { InputAddOn };
