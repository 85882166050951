import React, { useState } from 'react';

import { Spacing, Select, Checkbox } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import './UpsertOperatingHours.css';

import { DAYS } from './UpsertOperatingHours.constants';

import ButtonGroup from '../../../../ButtonGroup/ButtonGroup';
import InputHolder from '../../../../InputHolder/InputHolder';
import Tooltip from '../../../../UI/Tooltip/Tooltips';
import { AdvanceTitle } from '../../../styled';

const FlexContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const UpsertOperatingHours = ({ onCancel, onCreate, onUpdate, operatingHoursItem, onDelete }) => {
  const [dayStart, setDayStart] = useState(operatingHoursItem?.dayStart ?? 'Mon');
  const [dayEnd, setDayEnd] = useState(operatingHoursItem?.dayEnd ?? 'Mon');
  const [timeStart, setTimeStart] = useState(operatingHoursItem?.timeStart ?? '00:00');
  const [timeEnd, setTimeEnd] = useState(operatingHoursItem?.timeEnd ?? '00:00');
  const [isSingleDay, setIsSingleDay] = useState(operatingHoursItem?.isSingleDay ?? true);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleUpdate = () => {
    const config = {
      id: operatingHoursItem?.id,
      dayStart,
      dayEnd: isSingleDay ? dayEnd : null,
      timeStart,
      timeEnd,
      isSingleDay,
    };

    onUpdate(config);
  };

  const handleSave = () => {
    if (dayStart === '' || (!isSingleDay && dayEnd === '') || timeStart === '' || timeEnd === '') return;
    const config = {
      id: operatingHoursItem?.id ?? `id_operating_hours_${(Math.random() + 1).toString(36).substring(7)}`,
      dayStart,
      dayEnd: !isSingleDay ? dayEnd : null,
      timeStart,
      timeEnd,
      isSingleDay,
    };

    onCreate(config);
  };

  return (
    <div className="c-view-wrapper">
      <h3 className="c-label">Configure operating hours</h3>
      <Spacing>
        <AdvanceTitle>
          <b>Configuration</b>
          <Tooltip text="This option allows you to configure consecutive days who share the same open and close times." />
        </AdvanceTitle>
        <Checkbox
          id="id_is_single_day"
          isChecked={isSingleDay}
          labelText="Configure by day?"
          name="isSingleDay"
          onChange={(e) => {
            const { checked } = e.target;
            setIsSingleDay(checked);
            setIsDisabled(timeStart === '' || timeEnd === '' || (!checked && dayEnd === '') || dayStart === '');
          }}
        />
      </Spacing>
      <Spacing>
        <FlexContainer>
          <Select
            id="id_start_day"
            labelText="Start day"
            name="dayStart"
            options={DAYS}
            value={dayStart ?? '00:00'}
            onChange={(e) => {
              const { value } = e.target;
              setDayStart(value);
              setIsDisabled(timeStart === '' || timeEnd === '' || (!isSingleDay && dayEnd === '') || value === '');
            }}
          />
          {!isSingleDay && (
            <Select
              id="id_end_day"
              labelText="End day"
              name="dayEnd"
              options={DAYS}
              value={dayEnd ?? '00:00'}
              onChange={(e) => {
                const { value } = e.target;
                setDayEnd(value);
                setIsDisabled(timeStart === '' || timeEnd === '' || (!isSingleDay && value === '') || dayStart === '');
              }}
            />
          )}
        </FlexContainer>
      </Spacing>
      <Spacing>
        <FlexContainer>
          <InputHolder
            label="Start time"
            name="timeStart"
            type="time"
            value={timeStart}
            required
            onChange={(e) => {
              const { value } = e.target;
              setTimeStart(value);
              setIsDisabled(value === '' || timeEnd === '' || (!isSingleDay && dayEnd === '') || dayStart === '');
            }}
          />
          <InputHolder
            label="End time"
            name="timeEnd"
            type="time"
            value={timeEnd}
            required
            onChange={(e) => {
              const { value } = e.target;
              setTimeEnd(value);
              setIsDisabled(timeStart === '' || value === '' || (!isSingleDay && dayEnd === '') || dayStart === '');
            }}
          />
        </FlexContainer>
      </Spacing>
      {/* Switch ButtonGroup for TabsetButtons when switching to V3 */}
      <ButtonGroup
        hasDelete={Boolean(operatingHoursItem)}
        isConfirmButtonDisabled={isDisabled}
        hasConfirmButton
        is
        onCancel={onCancel}
        onDelete={() => onDelete(operatingHoursItem?.id)}
        onSave={operatingHoursItem ? handleUpdate : handleSave}
      />
    </div>
  );
};
export { UpsertOperatingHours };

UpsertOperatingHours.propTypes = {
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  operatingHoursItem: PropTypes.shape({
    dayEnd: PropTypes.string,
    dayStart: PropTypes.string,
    id: PropTypes.string,
    isSingleDay: PropTypes.bool,
    timeEnd: PropTypes.string,
    timeStart: PropTypes.string,
  }),
};
