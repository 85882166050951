import React, { useEffect, useState } from 'react';

import { useCookie } from '@netfront/common-library';
import to from 'await-to-js';
import { Helmet } from 'react-helmet';

import { AppSnippetProps } from './AppSnippet.interfaces';

import { ERROR_MESSAGES } from '../../../core';
import { DangerouslySetHtmlContent } from '../../DangerouslySetHtmlContent';

const fileTypeToHtmlTagMap = {
  CSS: 'style',
  HTML: 'div',
  JAVASCRIPT: 'script',
};

const AppSnippet = ({ app: { files = [], appApiKey }, appComponentId, userFlowStepTrackId }: AppSnippetProps) => {
  const { getAccessTokenCookie } = useCookie();

  const [appHtml, setAppHtml] = useState<string>();

  useEffect(() => {
    if (!files.length) {
      return;
    }

    const contentPromises = files.map(async ({ fileType, location: { presignedUrl } }) => {
      if (!presignedUrl) {
        throw new Error(ERROR_MESSAGES.UNAVAILABLE_PRESIGNED_URL);
      }

      const [fetchError, response] = await to(fetch(presignedUrl));

      if (fetchError) {
        throw fetchError;
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!response) {
        throw new Error(`Unable to fetch app snippet with url=${presignedUrl}`);
      }

      const { ok: isOk, status } = response;

      if (!isOk) {
        throw new Error(`HTTP error with status: ${status}`);
      }

      const [error, code] = await to(response.text());

      if (error) {
        throw error;
      }

      if (!code) {
        throw new Error('Error serializing response');
      }

      const htmlTag = fileTypeToHtmlTagMap[fileType];

      return `
        <${htmlTag}>
          ${code}
        </${htmlTag}>
      `;
    });

    Promise.all(contentPromises).then((allHtml) => setAppHtml(allHtml.join('\n')));
  }, [files]);

  return (
    <React.Fragment>
      <Helmet>
        <script type="text/javascript">{`
          var apiAppDetails = {
            "appApiKey": "${appApiKey}",
            "componentId": "${appComponentId}",
            "token": "${getAccessTokenCookie()}",
            "trackingId": "${userFlowStepTrackId}",
            };
        `}</script>
      </Helmet>
      {appHtml ? <DangerouslySetHtmlContent html={appHtml} /> : null}
    </React.Fragment>
  );
};

export { AppSnippet };
