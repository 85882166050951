import React from 'react';

import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import Icon from '../../components/Icon/Icon';
import EditBtn from '../../components/UI/Button/EditButton';
import SwitchBtn from '../../components/UI/Button/SwitchButton';
import DocIcon from '../../static/img/FileType/DocIcon.svg';
import PdfIcon from '../../static/img/FileType/PdfIcon.svg';
import XlsIcon from '../../static/img/FileType/XlsIcon.svg';
import FilterIcon from '../../static/img/filter-icon.svg';
import PlayIcon from '../../static/img/Media/Play.svg';
import SearchIcon from '../../static/img/search-icon.svg';

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 1em;
  top: -4.4em;
`;

export const SearchInput = styled.input`
  background: url(${SearchIcon}) 1em center no-repeat;
  margin: 0;
  width: 3em;

  &:focus {
    background: #fff;
    width: 10em;
  }
`;

export const FilterBox = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  z-index: 2;

  li {
    &:hover {
      background-color: #dee1e1;
    }
  }
`;

export const FilterBtn = styled.button`
  background: url(${FilterIcon}) 1em center no-repeat;
  border: none;
  height: 2em;
  width: 3em;
`;

export const FilterOpt = styled.button`
  background: transparent;
  border: none;
  margin: 0 -1em;
  padding: 0 1em;
`;

const FavSwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 0 1em;
`;

const FavP = styled.p`
  margin-right: 5px;
`;

export const FavSwitch = props => {
  const { isSwitched } = props;
  return (
    <FavSwitchWrapper>
      <FavP>Favourites</FavP>
      <SwitchBtn id={1} onChange={isSwitched} />
    </FavSwitchWrapper>
  );
};

FavSwitch.propTypes = {
  isSwitched: PropTypes.func.isRequired,
};

export const IconWrapper = styled.div`
  align-items: center;
  background: #00c3c8;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 1.5rem;
  justify-content: center;
  margin-right: 0.25rem;
  width: 1.5rem;
`;

export const AddNewBtn = styled.label`
  align-items: center;
  background: #f6f8f9;
  border: #dadfe1 solid 1px;
  border-radius: 4px;
  color: #838383;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-left: 20px;
  padding: 5px 10px;
  text-decoration: none;
`;

export const AssetsWrapper = styled.div`
  border: 1px solid rgba(9, 30, 66, 0.08);
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  margin-top: -2.5em;
  min-height: 20em;
  padding: 15px;
  width: 100%;
`;

export const EditBtnBox = styled.div`
  display: none;
  position: absolute;
  right: 0;
  z-index: 5;
`;

const FBtn = styled.label`
  align-items: center;
  background-color: ${props => (props.isFav ? 'transparent' : '#000')};
  border: none;
  border-radius: 50%;
  color: ${props => (props.isFav ? 'red' : '#fff')};
  cursor: pointer;
  display: ${props => (props.isFav ? 'flex' : 'none')};
  flex-direction: column;
  height: 1.25rem;
  justify-content: center;
  left: 0.25rem;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0.25rem;
  width: 1.25rem;
  z-index: 5;

  &:hover {
    color: red;
  }
`;

export const FavBtn = props => {
  const { isFav, isClicked } = props;
  return (
    <FBtn isFav={isFav} onClick={isClicked}>
      <Icon id="icon_heart" style={{ height: '0.75rem', width: '0.75rem' }} />
    </FBtn>
  );
};

FavBtn.propTypes = {
  isClicked: PropTypes.func.isRequired,
  isFav: PropTypes.bool,
};
FavBtn.defaultProps = {
  isFav: null,
};

export const ImgBox = styled.div`
  background-color: #e1e1e1;
  border-radius: 4px;
  box-shadow: ${({ isSelected }) => (isSelected ? '0px 0px 6px 1px #44bed3' : 'unset')};
  list-style: none;
  margin: 8px;
  max-height: 7.56em;
  min-height: 7.56em;
  overflow: hidden;
  position: relative;
  width: 7.56em;

  &:hover {
    transform: scale(1.1);
    ${EditBtnBox} {
      display: block;
    }
    ${FBtn} {
      display: flex;
    }
  }
`;

export const AssetImg = styled.img`
  height: 7.56em;
  width: 7.56em;
`;

export const VideoBox = styled(ImgBox)`
  width: 14em;
`;

export const AssetVideo = styled.video`
  width: 14em;
`;

export const VideoPlayBtn = styled.button`
  background: url(${PlayIcon}) no-repeat 50% 50%;
  background-color: transparent;
  background-size: 2em;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 100%;
  outline: none;
  width: 100%;
`;

export const FileName = styled.p`
  bottom: 0;
  color: #a6adb4;
  font-size: 0.7em;
  margin: 7px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 9.4em;
`;

export const IconImg = styled.img`
  filter: grayscale(100%) brightness(125%) contrast(110%);
  margin: 0 auto;
  margin: 2em 2.6em;
  width: 30%;
`;

export const IconImgS = styled(IconImg)`
  margin: 2em;
  width: 40%;
`;

export const AudioBox = styled(ImgBox)`
  display: flex;
  justify-content: center;
  text-align: center;
  &:hover {
    ${FileName} {
      color: #000;
      white-space: unset;
    }
    ${IconImg} {
      filter: unset;
    }
  }
`;
export const LoadingBox = ({ count = 7 }) => {
  const menuItems = [];

  for (let i = 0; i < count; i += 1) {
    menuItems.push(
      <ImgBox key={`loading-box-${i}`}>
        <Skeleton width="100%" style={{ lineHeight: 2 }} height="100%" />
      </ImgBox>,
    );
  }
  return <>{menuItems}</>;
};

LoadingBox.propTypes = {
  count: PropTypes.number,
};

export const FileBox = props => {
  const { asset, onEdit, isFav, onFav } = props;
  const index = asset.s3Key.lastIndexOf('.') + 1;
  const type = asset.s3Key.substr(index);
  return (
    <AudioBox>
      <FavBtn isClicked={onFav} isFav={isFav} />
      <EditBtnBox>
        <EditBtn isClicked={onEdit} />
      </EditBtnBox>
      <a className="a_document" download href={asset.src} key={asset.assetId} rel="noopener noreferrer" target="_blank">
        {type === 'pdf' && <IconImg alt="file" src={PdfIcon} />}
        {type === 'docx' && <IconImgS alt="file" src={DocIcon} />}
        {type === 'xlsx' && <IconImgS alt="file" src={XlsIcon} />}
        <FileName>{asset.fileName}</FileName>
      </a>
    </AudioBox>
  );
};

FileBox.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.string,
    fileName: PropTypes.string,
    s3Key: PropTypes.string,
    src: PropTypes.string,
  }).isRequired,
  isFav: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onFav: PropTypes.func.isRequired,
};

const DisplayWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  left: 50%;
  padding: 1em;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const DisplayContainer = styled.div`
  margin: 0 auto;
  max-height: 30vh;
  max-width: 1024px;
  min-width: 300px;
  width: 40%;
`;

const Video = styled.video`
  height: fill-available;
`;

export const DisplayAsset = props => {
  const { asset, type } = props;
  return (
    <DisplayWrapper>
      <DisplayContainer>
        {type === 'image' && <img alt={asset.fileName} src={asset.src} width="100%" />}
        {type === 'video' && (
          <Video controls src={asset.src}>
            <track kind="captions" srcLang="en" />
          </Video>
        )}
      </DisplayContainer>
    </DisplayWrapper>
  );
};

DisplayAsset.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.string,
    fileName: PropTypes.string,
    s3Key: PropTypes.string,
    src: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};
