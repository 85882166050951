import styled from 'styled-components';

const FormHeaderTitle = styled.h2`
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  margin-right: 1em;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { FormHeaderTitle };
