import gql from 'graphql-tag';

export const EXPORT_MODULE_PAGES = gql`
  query ExportModulePages($ianaTimeZone: String!, $stepId: Int!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportModulePages(ianaTimeZone: $ianaTimeZone, stepId: $stepId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        s3Key
      }
    }
  }
`;
