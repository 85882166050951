import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const TEXT_EQUALS = gql`
  mutation TextEqual(
    $questionActionId: Int!
    $contentSnippetQuestionId: Int!
    $text: String!
  ) {
    contentGroup {
      addConditionOnAction(
        textEquals: {
          questionActionId: $questionActionId
          contentSnippetQuestionId: $contentSnippetQuestionId
          text: $text
        }
      ) {
        id
      }
    }
  }
`;

const TextEquals = ({
  questionActionId,
  contentSnippetQuestionId,
  text,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={TEXT_EQUALS}>
    {(TextEqual, { loading, error, data }) => {
      const variables = {
        questionActionId,
        contentSnippetQuestionId,
        text,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentGroup.addConditionOnAction.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={TextEqual}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

TextEquals.propTypes = {
  contentSnippetQuestionId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  questionActionId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default TextEquals;
