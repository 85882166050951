import React, { useEffect, useState } from 'react';

import {
  Label,
  RadioButtonGroup,
  CheckboxGroup,
  Input,
  Textarea,
  Select,
  Spacing,
  SingleDatePicker,
  RangeInput,
  CustomRating,
} from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useToast } from '../../../legacy/hooks';
import { responseTypeLongConst } from '../../constants';
import { useGetQuestionPreview } from '../../hooks';
import { getQuestionText } from '../../utils';

const Container = styled.div`
  padding: 0.75rem;
  border: 1px solid #cbccce;
  border-radius: 5px;
`;

const QuestionPreview = ({ questionId }) => {
  const { handleToastError } = useToast();

  const [labelText, setLabelText] = useState();
  const [questionType, setQuestionType] = useState();
  const [options, setOptions] = useState([]);
  const [config, setConfig] = useState();

  const { handleGetQuestionPreview } = useGetQuestionPreview({
    onCompleted: ({ config: { question, configuration } }) => {
      const { __typename: inputType, responseSet } = configuration;
      const { availableResponses = [] } = responseSet ?? {};

      const label = getQuestionText(question, 'text/html') || `Question-${questionId}`;

      setLabelText(label);
      setQuestionType(inputType);
      setOptions(availableResponses);
      setConfig(configuration);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!questionId) return;

    handleGetQuestionPreview({
      variables: {
        id: Number(questionId),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  return (
    <>
      <Label labelText="Question preview" />
      <Container>
        {questionType === responseTypeLongConst.CALENDAR && (
          <SingleDatePicker maxDate={config?.maxDate} minDate={config?.minDate} selectedDate={config?.defaultDate} />
        )}

        {questionType === responseTypeLongConst.CHECKBOX && (
          <CheckboxGroup
            items={options.map(({ value, label }) => ({
              id: value,
              labelText: label,
            }))}
            legendText={labelText}
            name="question-checkbox-preview"
            isDisabled
            onChange={() => undefined}
          />
        )}

        {questionType === responseTypeLongConst.RATING && (
          <>
            <p className="c-label">{labelText}</p>
            <CustomRating
              iconCount={config?.maxRating}
              isLabelTextVisible={config?.areLabelsVisible}
              precisionType={config?.precision?.toLowerCase()}
              rateValue={config?.initialValue}
              ratingIconType={config?.iconType?.toLowerCase()}
            />
          </>
        )}

        {questionType === responseTypeLongConst.DROPDOWNLIST && (
          <Select
            id="id_dropdown_preview"
            labelText={labelText}
            name="questions-single-select"
            options={options.map(({ id, label, value }) => ({
              id,
              name: label,
              value,
              isDisabled: true,
            }))}
            isDisabled
            onChange={() => undefined}
          />
        )}
        {questionType === responseTypeLongConst.MATCH && (
          <div>
            <ul style={{ listStyle: 'none', padding: '0' }}>
              {config?.matchItems.map((matchItem, matchItemIndex) => (
                <li key={matchItem.text}>
                  <p>
                    <strong>{matchItem.text}</strong>
                  </p>
                  <ul>
                    {config?.responseSet.availableResponses
                      .filter(({ correctPosition }) => correctPosition === matchItemIndex)
                      .map(({ label }) => (
                        <li>{label}</li>
                      ))}
                  </ul>
                </li>
              ))}
              {config?.responseSet.availableResponses.filter(({ correctPosition }) => correctPosition === null).length > 0 && (
                <li>
                  <p style={{ fontStyle: 'italic' }}>Uncategorised</p>
                  <ul>
                    {config?.responseSet.availableResponses
                      .filter(({ correctPosition }) => correctPosition == null)
                      .map(({ label }) => (
                        <li key={label}>{label}</li>
                      ))}
                  </ul>
                </li>
              )}
            </ul>
          </div>
        )}
        {questionType === responseTypeLongConst.MULTI_RESPONSE && (
          <>
            <p className="c-label">{labelText}</p>
            {Array.from({ length: config?.minResponse }).map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Spacing key={String(index)} size="x-small">
                <Input
                  id={`id_multi_response_preview_${index}`}
                  labelText={labelText}
                  name={`question-multi-response-text-preview-${index}`}
                  type="text"
                  isDisabled
                  isLabelHidden
                  onChange={() => undefined}
                />
              </Spacing>
            ))}
          </>
        )}
        {questionType === responseTypeLongConst.MULTI_TEXT && (
          <Textarea
            id="id_multi_text_preview"
            labelText={labelText}
            name="question-multi-text-preview"
            isDisabled
            onChange={() => undefined}
          />
        )}
        {questionType === responseTypeLongConst.NUMBER && (
          <Input
            id="id_number_preview"
            labelText={labelText}
            name="question-number-preview"
            type="number"
            isDisabled
            onChange={() => undefined}
          />
        )}
        {questionType === responseTypeLongConst.RADIO && (
          <RadioButtonGroup
            items={options.map(({ id, label, value }) => ({
              id,
              labelText: label,
              value,
              isDisabled: true,
            }))}
            name="question-radio-preview"
            title={labelText}
            isDisabled
            onChange={() => undefined}
          />
        )}
        {questionType === responseTypeLongConst.SINGLE_TEXT && (
          <Input
            id="id_single_text_preview"
            labelText={labelText}
            name="question-single-text-preview"
            type="text"
            isDisabled
            onChange={() => undefined}
          />
        )}
        {questionType === responseTypeLongConst.SLIDER && (
          <>
            <p className="c-label">{labelText}</p>
            <RangeInput maxValue={config?.maxValue} minValue={config?.minValue} step={config?.step} ticks={config?.ticks} />
          </>
        )}
      </Container>
    </>
  );
};

QuestionPreview.propTypes = {
  questionId: PropTypes.number,
};

export { QuestionPreview };
