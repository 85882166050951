import React from 'react';

const CartoonAsset = ({ isAnimatedImage = false, onLoadHandler, presignedUrl }) => {

  return isAnimatedImage ? (
    <object className="c-cartoon-image-asset" title="cartoon slide" onLoad={onLoadHandler} type="image/svg+xml" data={presignedUrl} />
  ) : (
    <img className="c-cartoon-image-asset" onLoad={onLoadHandler} alt="" src={presignedUrl} />
  );
};

export default CartoonAsset;
