import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

// **************styled Components********************
const Button = styled.button`
  background: ${(props) => props.bgc || '#E1E1E1'};
  border: none;
  border-radius: 0.3rem;
  color: ${(props) => props.color || '#505354'};
  cursor: pointer;
  font-size: ${(props) => props.size || '1em'};
  font-weight: 600;
  margin: 0.3rem;
  padding: 0.6em 1.4em;
  transition: 0.15s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #4bc2ca;
    color: #fff;
  }
`;

// **************styled Components********************

const SquareButton = (props) => {
  const { bgColor, color, size, children, isClicked, isDisabled, type } = props;

  return (
    <Button bgc={bgColor} color={color} onClick={isClicked} size={size} disabled={isDisabled} type={type}>
      {children}
    </Button>
  );
};

SquareButton.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.string,
  color: PropTypes.string,
  isClicked: PropTypes.func,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
};

SquareButton.defaultProps = {
  bgColor: '#E1E1E1',
  children: '',
  color: '#505354',
  isClicked: () => null,
  isDisabled: false,
  size: '1em',
  type: 'button',
};

export default SquareButton;
