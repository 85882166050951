import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CHECKBOX_FEEDBACK_CONFIG } from './UseUpsertSummaryFeedbackConfigCheckbox.graphql';

const useUpsertSummaryFeedbackConfigCheckbox = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeUpsertSummaryFeedbackConfigCheckbox, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? CREATE_CHECKBOX_FEEDBACK_CONFIG,
    options: {
      onCompleted: ({ data }) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { addSummaryFeedbackConfigurationCheckbox: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpsertSummaryFeedbackConfigCheckbox = ({ variables }) => {
    executeUpsertSummaryFeedbackConfigCheckbox({
      variables,
    });
  };

  return {
    handleUpsertSummaryFeedbackConfigCheckbox,
    isLoading,
  };
};

export { useUpsertSummaryFeedbackConfigCheckbox };
