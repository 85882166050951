import { gql } from '@apollo/client';

export const CREATE_SUMMARY_FEEDBACK_MULTI_RESPONSE_TO_MULTI_RESPONSE_MUTATION = gql`
  mutation addFeedbackMultiResponseToMultiResponse(
    $questionAnswerId: Int!
    $responseId: Int!
    $values: [SummaryFeedbackMultiResponseAnswerInputType]!
    $contentSnippetSummaryId: Int!
  ) {
    summaryFeedback {
      addFeedbackMultiResponseToMultiResponse(
        questionAnswerId: $questionAnswerId
        contentSnippetSummaryId: $contentSnippetSummaryId
        responseId: $responseId
        values: $values
      )
    }
  }
`;
