import React, { useContext } from 'react';

import { SideBar, TabSet } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import AppContext from '../../../../legacy/context/AppContext';
import { UpsertActionsTab } from '../../tabs';

const ActionsSidebar = (props) => {
  const { activePageId, onClose, onDelete, isOpen = false } = props;
  const { actionsSidebarDetails } = useContext(AppContext);

  const { activePageId: selectedPageId } = actionsSidebarDetails;

  const handleDelete = (data) => {
    onClose();
    onDelete(data);
  };

  return (
    <SideBar
      isSideBarOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <>
        <TabSet
          defaultActiveTabId="id_actions_tab"
          tabs={[
            {
              iconId: 'id_general_tab_icon',
              id: 'id_actions_tab',
              label: 'Actions',
              view: () => (
                <UpsertActionsTab
                  activePageId={selectedPageId ?? activePageId}
                  onClose={onClose}
                  onDelete={onDelete ? handleDelete : undefined}
                />
              ),
            },
          ]}
        />
      </>
    </SideBar>
  );
};

ActionsSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  activePageId: PropTypes.number,
  isOpen: PropTypes.bool,
  onDelete: PropTypes.func,
};

export { ActionsSidebar };
