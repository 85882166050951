import React from 'react';

import BubbleTail from '../BubbleTail';

const Ellipsis = ({ children, angle, hasShadow = false, isTailVisible = false, left, question, tailAngles, tailType, top, width }) => {
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);

  const tailWidthIncrement = 4;
  const ellipsisIncrement = 2;
  const maxTailWidth = 25;

  const getSmallerNumber = (a) => (a < maxTailWidth ? a : maxTailWidth);

  const tailWidth = getSmallerNumber(width + tailWidthIncrement);

  return (
    <>
      <div
        className={`c-cartoon-ellipsis ${hasShadow ? 'c-cartoon-ellipsis--shadowed' : ''}`}
        style={{
          top: isYAxisSpecified && `${top}%`,
          left: isXAxisSpecified && `${left}%`,
          width: isWidthSpecified && `${width + ellipsisIncrement}%`,
          minHeight: isWidthSpecified && `${width / ellipsisIncrement}%`,
          padding: isWidthSpecified && `${width}px`,
        }}
      >
        <div
          className="c-cartoon-ellipsis__inner"
          style={{
            margin: isWidthSpecified && `${width}px`,
          }}
        >
          {children}
          {Boolean(question) && (
            <p>
              <strong className="h-color--brand-secondary">{question}</strong>
            </p>
          )}
        </div>

        {isTailVisible && (
          <BubbleTail
            additionalClassNames="c-cartoon-ellipsis__tail"
            angle={angle}
            tailAngles={tailAngles}
            tailType={tailType}
            width={isWidthSpecified && tailWidth}
          />
        )}
      </div>
    </>
  );
};

export { Ellipsis };
