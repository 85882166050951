import React from 'react';

import { Quote } from '@netfront/ui-library';

import { QuoteSnippetProps } from './QuoteSnippet.types';

import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

import './QuoteSnippet.css';

const QuoteSnippet = ({ author, text = '' }: QuoteSnippetProps) => {
  return (
    <div className="c-quote-snippet">
      <Quote author={author}>{text ? <DOMPurifySanitizedHtmlContent html={text} /> : null}</Quote>
    </div>
  );
};

export { QuoteSnippet };
