import React, { useState } from 'react';

import { Spacing, Select } from '@netfront/ui-library';
import PropTypes from 'prop-types';

const ConditionTemplateSelector = ({ templateOptions, onSelectTemplate }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelect = (value) => {
    onSelectTemplate(value);
    setSelectedOption(value);
    setTimeout(() => setSelectedOption(''), 500);
  };

  return (
    <Spacing>
      <Select
        labelText="Quick add condition"
        name="action_type_dropdown"
        options={[
          {
            id: 0,
            name: 'Select an option',
            value: '',
          },
          ...templateOptions.map(({ id: optionId, title: name }) => ({
            id: optionId,
            value: optionId,
            name,
          })),
        ]}
        value={selectedOption}
        onChange={({ target: { value } }) => handleSelect(value)}
      />
    </Spacing>
  );
};

ConditionTemplateSelector.propTypes = {
  onSelectTemplate: PropTypes.func.isRequired,
  templateOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export { ConditionTemplateSelector };
