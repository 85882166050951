import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_EMBED_SNIPPET = gql`
  mutation createEmbedSnippet(
    $containerId: Int!
    $visible: Boolean!
    $code: String!
    $title: String!
    $type: EContentSnippetEmbedType
    $entityTitle: String!
  ) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible, entityTitle: $entityTitle }
        contentSnippetEmbed: { code: $code, title: $title, type: $type }
      ) {
        id
        sort
      }
    }
  }
`;

const createEmbedSnippetRequest = ({ containerId, isVisible, code, title, type, onError, onSuccessResult }) => (
  <Mutation mutation={CREATE_EMBED_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        visible: isVisible,
        code,
        title,
        type,
        entityTitle: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.createContentSnippet.id, data.contentSnippet.createContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createEmbedSnippetRequest.propTypes = {
  code: PropTypes.string.isRequired,
  containerId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  type: PropTypes.string,
};

createEmbedSnippetRequest.defaultProps = {
  isVisible: true,
  type: 'AUDIO',
};

export default createEmbedSnippetRequest;
