import gql from 'graphql-tag';

export const ATTACH_TO_SNIPPET = gql`
  mutation($snippetId: Int!, $styleId: Int!) {
    cssStyle {
      attachStyleToContentSnippet(
        contentSnippetId: $snippetId
        styleId: $styleId
      )
    }
  }
`;
