import React from 'react';

const Title = ({ text, top, left, width }) => {
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);
  return (
    <div
      className="c-cartoon-title"
      style={{
        top: isYAxisSpecified && `${top}%`,
        left: isXAxisSpecified && `${left}%`,
        width: isWidthSpecified && `${width}%`,
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
        className="c-cartoon-title__text"
      />
    </div>
  );
};

export { Title };
