import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownButton from '../UI/Button/ShowHideButton';
import SwitchButton from '../UI/Button/SwitchButton';

import { SnippetTypeConst } from './SnippetTypeConst';

import CreateSpacerSnippetRequest from '../../middleware/ContentSnippet/createSpacerSnippetRequest';
import UpdateSpacerSnippetRequest from '../../middleware/ContentSnippet/updateSpacerSnippetRequest';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: 1em 0;
`;

const Container2 = styled(Container)`
  align-items: center;
  background-color: #e4e4e3;
  border-radius: 5px;
  flex-flow: row;
  justify-content: space-between;
  padding: 0.2em 0.8em;

  &:hover {
    background-color: #fff;
  }
`;

const NumInput = styled.input`
  background-color: #fff;
  border: 1px solid #ebeded;
  border-radius: 7px;
  line-height: 0.8em;
  margin: 0.5em 1em 0 0;
  text-align: center;
  width: 6em;
`;

const AdvanceTitle = styled.div`
  display: flex;
  margin: 2em 0;
`;

const AdvanceWrapper = styled.div`
  background-color: #e4e4e3;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  padding: 1em;
`;

const AdvanceOpt = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0.2em 0.5em;

  &:hover {
    background-color: #fff;
  }
`;

const StyleSelect = styled.select`
  border: #e4e4e3 1px solid;
  border-radius: 5px;
  height: 2.6em;
  outline: none;
  padding: 0 0.8em;

  &:hover {
    border-color: #44bed3;
  }
`;

const StyleOption = styled.option``;

const EditableSpacerSnippet = props => {
  const { containerId, onCancel, onCreate, snippet, onUpdate } = props;
  const [height, setHeight] = useState(snippet ? snippet.height : 0);
  const [lineBreak, setLineBreak] = useState(
    snippet ? snippet.lineBreak : false
  );
  const [width, setWidth] = useState(snippet ? snippet.widthPercentage : 100);
  const [alignment, setAlignment] = useState(
    snippet ? snippet.alignment : 'CENTER'
  );
  const [showAdvance, setShowAdvance] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const handleHeight = e => {
    setHeight(parseInt(e.target.value, 10));
  };

  const handleLineBreak = value => {
    setLineBreak(value);
  };

  const handleWidth = e => {
    setWidth(parseInt(e.target.value, 10));
  };

  const handleAlignment = e => {
    setAlignment(e.target.value);
  };

  const handleSaveButton = () => {
    setSendRequest(true);
  };

  const handleSnippetError = () => {
    setSendRequest(false);
  };

  const handleSnippetResponse = (id, sort) => {
    if (snippet) {
      onUpdate(id, snippet.sort, SnippetTypeConst.SPACER, containerId, {
        height,
        lineBreak,
        widthPercentage: width,
        alignment,
      });
    } else {
      onCreate(id, sort, SnippetTypeConst.SPACER, containerId, {
        height,
        lineBreak,
        widthPercentage: width,
        alignment,
      });
    }
    setSendRequest(false);
  };

  return (
    <>
      <Container>
        <b>Height</b>
        <div>
          <NumInput
            onChange={e => handleHeight(e)}
            type="number"
            value={height}
          />
          <span>pixels</span>
        </div>
      </Container>
      <Container2>
        <b>Line Break</b>
        <SwitchButton
          defaultChecked={lineBreak}
          id={1}
          onChange={handleLineBreak}
        />
      </Container2>
      <AdvanceTitle>
        <b style={{ marginRight: '1em' }}>Advance</b>
        <DropdownButton
          direction="bottom"
          isClicked={() => {
            setShowAdvance(!showAdvance);
          }}
        />
      </AdvanceTitle>
      {showAdvance && (
        <AdvanceWrapper>
          <AdvanceOpt>
            <b>Width</b>
            <StyleSelect name="width" onChange={handleWidth} value={width}>
              <StyleOption value={25}>25%</StyleOption>
              <StyleOption value={50}>50%</StyleOption>
              <StyleOption value={100}>100%</StyleOption>
            </StyleSelect>
          </AdvanceOpt>
          <AdvanceOpt>
            <b>Alignment</b>
            <StyleSelect
              name="alignment"
              onChange={handleAlignment}
              value={alignment}
            >
              <StyleOption value="LEFT">Left</StyleOption>
              <StyleOption value="CENTER">Center</StyleOption>
              <StyleOption value="RIGHT">Right</StyleOption>
            </StyleSelect>
          </AdvanceOpt>
        </AdvanceWrapper>
      )}
      <div className="text-right">
        <button
          className="button--black mt-4 mr-3"
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="button--blue mt-4"
          onClick={handleSaveButton}
          type="submit"
        >
          Save
        </button>
      </div>
      {sendRequest && !snippet && (
        <CreateSpacerSnippetRequest
          alignment={alignment}
          containerId={containerId}
          height={height}
          lineBreak={lineBreak}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          widthPercentage={width}
        />
      )}
      {sendRequest && snippet && (
        <UpdateSpacerSnippetRequest
          alignment={alignment}
          height={height}
          lineBreak={lineBreak}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          snippetId={snippet.id}
          widthPercentage={width}
        />
      )}
    </>
  );
};

EditableSpacerSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape({
    alignment: PropTypes.string,
    height: PropTypes.number,
    id: PropTypes.number,
    lineBreak: PropTypes.bool,
    sort: PropTypes.number,
    widthPercentage: PropTypes.number,
  }),
};

EditableSpacerSnippet.defaultProps = {
  snippet: null,
};

export default EditableSpacerSnippet;
