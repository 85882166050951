import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

export const GET_APP_DETAILS = gql`
  query GET_APP_DETAILS($id: Int!) {
    app {
      getAppDetails(inputType: { appId: $id }) {
        description
        id
        identifier
        title
        averageRateValue
        rates {
          comment
          # user {
          #   firstname
          #   lastname
          # }
          id
          rate
          title
        }
        versions {
          id
          version
          components {
            id
            title
            description
            files {
              id
              locationId
              fileType
            }
          }
        }

        users {
          userId
          status
        }
      }
    }
  }
`;

export default function GetAppDetails(props) {
  const { id, onError, onSuccess } = props;

  return (
    <Query query={GET_APP_DETAILS} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) onError(error);

        if (data) {
          onSuccess(data.app.getAppDetails);
        }

        return null;
      }}
    </Query>
  );
}

GetAppDetails.propTypes = {
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
