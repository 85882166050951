import React, { useState } from 'react';

import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownButton from '../UI/Button/ShowHideButton';
import SwitchButton from '../UI/Button/SwitchButton';
import Input from '../UI/Input/InputWhite';

import { SnippetTypeConst } from './SnippetTypeConst';

import CreateMapContentSnippetRequest from '../../middleware/ContentSnippet/createMapContentSnippetRequest';
import UpdateMapContentSnippetRequest from '../../middleware/ContentSnippet/updateMapContentSnippetRequest';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMarker = styled.div`
  border: 4px solid red;
  border-radius: 50% 50% 50% 0;
  height: 20px;
  left: 50%;
  position: absolute;
  top: 40%;
  transform: rotate(-45deg);
  width: 20px;

  &::after {
    background-color: red;
    border-radius: 50%;
    content: '';
    height: 10px;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
  }

  &:hover {
    /* border-color: #fff; */
    border-color: transparent;
  }
`;

const MyWrapper = styled.div`
  border-radius: 5px;
  height: 190px;
  width: 350px;
`;

const AddressWrapper = styled.div`
  margin-right: 1em;
  width: 50%;
`;

const AdvanceTitle = styled.div`
  display: flex;
  margin-bottom: 1em;
`;

const AdvanceWrapper = styled.div`
  background-color: #e4e4e3;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  padding: 1em;
`;

const AdvanceOpt = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0.2em 0.5em;

  &:hover {
    background-color: #fff;
  }
`;

const NumInput = styled.input`
  background-color: #fff;
  border: 1px solid #ebeded;
  border-radius: 7px;
  line-height: 0.8em;
  margin-top: 0;
  text-align: center;
  width: 6em;
`;

function EditableMapSnippet(props) {
  const { containerId, onCancel, onCreate, snippet, onUpdate } = props;

  const key = 'AIzaSyBqXU8wNCH619eid2pnacRraqqyoTarW9U';
  const [address, setAddress] = useState('');
  const defaultCoo = { lat: -33.8688, lng: 151.2093 };
  const [coordinate, setCoordinate] = useState({
    lat: -33.8688,
    lng: 151.2093,
  });

  const [zoom, setZoom] = useState(snippet ? snippet.zoom : 12);
  const [draggable, setDraggable] = useState(
    snippet ? snippet.draggable : true
  );
  const [grayscale, setGrayscale] = useState(
    snippet ? snippet.grayscale : false
  );
  const [showAdvance, setShowAdvance] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const handleChange = value => {
    setAddress(value);
  };

  const handleZoom = e => {
    setZoom(parseInt(e.target.value, 10));
  };

  const handleDrag = value => {
    setDraggable(value);
  };

  const handleGreyscale = value => {
    setGrayscale(value);
  };

  const handleSubmit = () => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`
      )
      .then(res => {
        const results = res.data;
        setCoordinate(results.results[0].geometry.location);
      });
  };

  const handleSaveButton = () => {
    setSendRequest(true);
  };

  const handleSnippetError = () => {
    setSendRequest(false);
  };

  const handleSnippetResponse = (id, sort) => {
    if (snippet) {
      onUpdate(id, snippet.sort, SnippetTypeConst.MAP, containerId, {
        longitude: coordinate.lng,
        latitude: coordinate.lat,
        zoom,
        draggable,
        grayscale,
      });
    } else {
      onCreate(id, sort, SnippetTypeConst.MAP, containerId, {
        longitude: coordinate.lng,
        latitude: coordinate.lat,
        zoom,
        draggable,
        grayscale,
      });
    }

    setSendRequest(false);
  };

  return (
    <>
      <Wrapper>
        <AddressWrapper>
          <b>Address</b>
          <Input isAddressChanged={handleChange} isSubmit={handleSubmit} />
        </AddressWrapper>
        <div>
          <b style={{ lineHeight: '2em' }}>Preview</b>
          <MyWrapper>
            <GoogleMapReact
              bootstrapURLKeys={{
                key,
                language: 'en',
              }}
              center={coordinate}
              defaultCenter={defaultCoo}
              defaultZoom={12}
              options={{
                fullscreenControl: false,
              }}
              zoom={zoom}
            >
              <StyledMarker
                lat={coordinate.lat}
                lng={coordinate.lng}
                text="marker"
              />
            </GoogleMapReact>
          </MyWrapper>
        </div>
      </Wrapper>
      <AdvanceTitle>
        <b style={{ marginRight: '1em' }}>Advance</b>
        <DropdownButton
          direction="bottom"
          isClicked={() => {
            setShowAdvance(!showAdvance);
          }}
        />
      </AdvanceTitle>
      {showAdvance && (
        <AdvanceWrapper>
          <AdvanceOpt>
            <b>Enable drag</b>
            <SwitchButton
              defaultChecked={draggable}
              id={1}
              onChange={handleDrag}
            />
          </AdvanceOpt>
          <AdvanceOpt>
            <b>Greyscale</b>
            <SwitchButton
              defaultChecked={grayscale}
              id={2}
              onChange={handleGreyscale}
            />
          </AdvanceOpt>
          <AdvanceOpt>
            <b>Zoom level</b>
            <NumInput
              defaultValue={zoom}
              onChange={e => handleZoom(e)}
              type="number"
            />
          </AdvanceOpt>
        </AdvanceWrapper>
      )}
      <div className="text-right">
        <button
          className="button--black mt-4 mr-3"
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="button--blue mt-4"
          onClick={handleSaveButton}
          type="submit"
        >
          Save
        </button>
      </div>
      {sendRequest && !snippet && (
        <CreateMapContentSnippetRequest
          containerId={containerId}
          draggable={draggable}
          grayscale={grayscale}
          latitude={coordinate.lat}
          longitude={coordinate.lng}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          zoom={zoom}
        />
      )}
      {sendRequest && snippet && (
        <UpdateMapContentSnippetRequest
          draggable={draggable}
          grayscale={grayscale}
          latitude={coordinate.lat}
          longitude={coordinate.lng}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          snippetId={snippet.id}
          zoom={zoom}
        />
      )}
    </>
  );
}

EditableMapSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape({
    draggable: PropTypes.bool,
    grayscale: PropTypes.bool,
    id: PropTypes.number,
    sort: PropTypes.number,
    zoom: PropTypes.number,
  }),
};

EditableMapSnippet.defaultProps = {
  snippet: null,
};

export default EditableMapSnippet;
