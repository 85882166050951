import gql from 'graphql-tag';

export const UPDATE_CODE_CONTENT_SNIPPET = gql`
  mutation updateCodeContentSnippet($baseSnippet: UpdateContentSnippetInputType!, $codeSnippet: ContentSnippetCodeInputType!) {
    contentSnippet {
      updateContentSnippet(baseSnippet: $baseSnippet, contentSnippetCode: $codeSnippet) {
        id
      }
    }
  }
`;
