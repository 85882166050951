import React, { createContext, useContext } from 'react';

import { IContextProvider } from '@netfront/common-library';
import { useImmerReducer } from 'use-immer';

import { IUserFlowContext } from './UserFlowContext.interfaces';
import { userFlowReducer } from './UserFlowContext.reducer';

import { IUserFlow } from '../../interfaces';

const UserFlowContext = createContext<IUserFlowContext | undefined>(undefined);

const UserFlowProvider = ({ children, initialState }: IContextProvider<IUserFlow>) => {
  const [state, dispatch] = useImmerReducer(userFlowReducer, initialState);

  const value = { dispatch, state };

  return <UserFlowContext.Provider value={value}>{children}</UserFlowContext.Provider>;
};

const useUserFlowContext = (): IUserFlowContext => {
  const userFlowContext = useContext(UserFlowContext);
  if (!userFlowContext) {
    throw new Error('useUserFlowContext must be used within a UserFlowProvider');
  }

  return userFlowContext;
};

export { UserFlowProvider, useUserFlowContext };
