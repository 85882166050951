export const LayoutContainerConst = {
  PLANE: 'Plane',
  ONE_BY_TWO: 'One by two',
  ONE_BY_TWO_RIGHT: 'One by two right',
  ONE_BY_TWO_LEFT: 'One by two left',
  ONE_BY_THREE: 'One by three',
  ONE_BY_FOUR: 'One by four',
  ONE_BY_TWO_LEFT_BAR: 'One by two left bar',
  ONE_BY_TWO_RIGHT_BAR: 'One by two right bar',
  ONE_BY_THREE_RIGHT: 'One by three right', // be carefull while changing it is confusing in right and left
  ONE_BY_THREE_LEFT: 'One by three left', // be carefull while changing it is confusing in right and left
  TAB_LEFT: 'Tab left',
  TAB_TOP: 'Tab top',
};
