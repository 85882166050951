import React from 'react';

import PropTypes from 'prop-types';

import { Label, ToggleButton } from './styled';

const ToggleSwitch = props => {
  const { isChecked, checkedColor, uncheckedColor, name, onChange } = props;

  return (
    <Label>
      <input checked={isChecked} name={name} onChange={onChange} type="checkbox" />
      <ToggleButton checkedColor={checkedColor} uncheckedColor={uncheckedColor} isChecked={isChecked} />
    </Label>
  );
};

ToggleSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  checkedColor: PropTypes.string,
  uncheckedColor: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

ToggleSwitch.defaultProps = {
  name: '',
  onChange: () => undefined,
};

export default ToggleSwitch;
