import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: rgb(250, 251, 252);
  box-sizing: border-box;
  color: rgb(9, 30, 66);
  cursor: text;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  max-width: 100%;
  overflow-wrap: break-word;
  vertical-align: top;
  pointer-events: auto;
  flex: 1 1 100%;
  overflow: hidden;
`;

export const TextFieldFeedbackBody = styled.div`
  padding: 0.5rem;
  color: ${props => props.fontColor ?? 'white'};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;

export const TextFieldFeedbackContainer = styled.div`
  background: ${props => props.backgroundColor ?? '#ffe6e6'};
  border-radius: 0 0 6px 6px;
  margin-top: -4px;
  padding: 0.2rem;
`;

export const InputField = styled.input`
  background-color: transparent;
  border-color: ${props => (props.isWarning ? '#ffe6e6' : 'rgb(223, 225, 230)')};
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  font-size: 14px;
  margin-top: 0;
  min-width: 0px;
  width: 100%;
  height: 42px;
  line-height: 1.42857;
  border-image: initial;
  outline: none;
  padding: 8px 6px;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;

  &:focus {
    border-color: #00c4cc;
  }
`;
