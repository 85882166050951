import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_SINGLELINE_ANSWER = gql`
  mutation answerSlider(
    $value: Int!
    $contentSnippetQuestionId: Int!
    $status: EQuestionAnswerStatus
    $contentPageId: Int!
  ) {
    questionAnswer {
      setAnswer(
        answerSlider: {
          value: $value
          contentSnippetQuestionId: $contentSnippetQuestionId
          status: $status
          contentPageId: $contentPageId
        }
      ) {
        id
      }
    }
  }
`;

const createSliderAnswer = ({
  value,
  contentSnippetQuestionId,
  status,
  contentPageId,
  // userFlowStepId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_SINGLELINE_ANSWER}>
    {(AnswerSL, { loading, error, data }) => {
      const variables = {
        value,
        contentSnippetQuestionId,
        status,
        contentPageId,
        // userFlowStepId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.questionAnswer.setAnswer);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={AnswerSL}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createSliderAnswer.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  contentSnippetQuestionId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  status: PropTypes.string,
  // userFlowStepId: PropTypes.number,
};

createSliderAnswer.defaultProps = {
  status: 'NO_RESPONSE',
  // userFlowStepId: 1,
};

export default createSliderAnswer;
