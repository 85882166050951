import styled from 'styled-components';

import DeleteIcon from '../../static/img/delete.svg';

export const LandingImage = styled.img`
  display: block;
  margin: 1em auto;
  max-width: 950px;
  width: 90%;
`;

export const LandingDetails = styled.div`
  padding: 1.8em 0 0 0;
  text-align: center;

  h4 {
    color: #4bc2ca;
    font-size: 1.8em;
    font-weight: 600;
    margin: 0 0 0.6em 0;
  }
`;

export const PlusBtnWrapper1 = styled.div`
  margin-left: 1em;
`;

export const PlusBtnWrapper2 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`;

export const AddFirstPage = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 0 auto;
  margin-top: 0.5em;
  max-width: 20em;
  width: 50%;
`;

export const Container = styled.div`
  background-color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.isDraggingOver ? '#ffebe6' : props.draggingFromThisWith ? '#e6fcff' : '#fff'};
  width: 100%;
`;

export const Bin = styled.div`
  bottom: 8em;
  height: inherit;
  left: 20%;
  max-height: 5em;
  position: absolute;
  width: 60%;
  z-index: 10;
`;

export const BinContainer = styled.div`
  background-color: ${props => (props.isDraggingOver ? 'salmon' : 'lightslategray')};
  background-image: url(${DeleteIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2em;
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  height: 5em;
  margin: 0 auto;
  opacity: 0.8;
  width: 100%;
`;

export const EditBox = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ModalButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
