import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../../Icon/Icon';

const RadioInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
`;

const Label = styled.label`
  background-color: ${props => (props.isChecked ? '#A6ADB4' : '#fff')};
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-size: 1em;
  line-height: 25px;
  margin-bottom: 12px;
  padding: 6px;
  position: relative;
  user-select: none;
`;

const IconRadioButton = props => {
  const { htmlFor, iconId, name, onChange, isChecked } = props;

  return (
    <Label htmlFor={htmlFor} isChecked={isChecked}>
      <RadioInput
        defaultChecked={isChecked}
        id={htmlFor}
        name={name}
        onChange={onChange}
        type="radio"
      />
      <Icon id={iconId} style={{ height: '2rem', width: '2rem' }} />
    </Label>
  );
};

IconRadioButton.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  iconId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

IconRadioButton.defaultProps = {
  isChecked: false,
  onChange: () => {},
};

export default IconRadioButton;
