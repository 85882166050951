import React from 'react';

import PropTypes from 'prop-types';

const ErrorMessage = ({ message }) => {
  const array = [];

  try {
    const { errors } = JSON.parse(message);

    errors.forEach((property) => {
      array.push(<ul key={property}>{errors[property]}</ul>);
    });
  } catch (e) {
    array.push(<ul key={e.message}>{message}</ul>);
  }

  return <div className="error-message">{array}</div>;
};

ErrorMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string]),
};

ErrorMessage.defaultProps = {
  message: '',
};

export default ErrorMessage;
