import React from 'react';

import { Message } from '../Message';

import { ConfirmationMessagesProps } from './ConfirmationMessages.interfaces';

const ConfirmationMessages = ({ errorMessage, id, successMessage }: ConfirmationMessagesProps) => {
  return (
    <React.Fragment>
      {Boolean(errorMessage) && <Message id={`error-message-${id}`} text={errorMessage} type="ERROR" />}
      {Boolean(successMessage) && <Message id={`success-message-${id}`} text={successMessage} type="SUCCESS" />}
    </React.Fragment>
  );
};

export { ConfirmationMessages };
