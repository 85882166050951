import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    user {
      updatePassword(currentPassword: $oldPassword, newPassword: $newPassword)
    }
  }
`;

const changePasswordRequest = ({
  oldPassword,
  newPassword,
  onCreateError,
  onSuccessResult,
}) => (
  <Mutation client={Client()} mutation={CHANGE_PASSWORD}>
    {(changePassword, { loading, error, data }) => {
      const variables = {
        oldPassword,
        newPassword,
      };

      if (error) {
        return <ErrorModal error={error} onError={onCreateError} />;
      }
      if (data) {
        onSuccessResult();
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={changePassword}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

changePasswordRequest.propTypes = {
  newPassword: PropTypes.string.isRequired,
  oldPassword: PropTypes.string.isRequired,
  onCreateError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default changePasswordRequest;
