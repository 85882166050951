import React, { useState } from 'react';

import PropTypes from 'prop-types';

import SquareButton from '../UI/Button/SquareButton';
import NormalModal from '../UI/Modal/NormalModal';

import { ButtonGroupContainer, ModalButtonGroup, ButtonContainer, DeleteButton } from './styled';

const ButtonGroup = (props) => {
  const {
    confirmationMessage,
    confirmBtnName,
    hasConfirmButton,
    isConfirmButtonDisabled,
    deleteBtnName,
    deleteComps,
    onCancel,
    onDelete,
    onSave,
    confirmButtonType = 'button',
  } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDelete = () => {
    onDelete();
    setIsDeleteModalOpen(false);
  };

  return (
    <ButtonGroupContainer>
      <ButtonContainer>
        <SquareButton bgColor="#505354" color="#fff" isClicked={onCancel}>
          Cancel
        </SquareButton>
        {hasConfirmButton && (
          <SquareButton isClicked={onSave} isDisabled={isConfirmButtonDisabled} type={confirmButtonType}>
            {confirmBtnName}
          </SquareButton>
        )}
      </ButtonContainer>
      {onDelete ? (
        <>
          <DeleteButton onClick={() => setIsDeleteModalOpen(true)}>{deleteBtnName}</DeleteButton>
          <NormalModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
            {confirmationMessage}
            {deleteComps}
            <ModalButtonGroup>
              <SquareButton isClicked={() => setIsDeleteModalOpen(false)}>No, cancel</SquareButton>
              <SquareButton bgColor="#dc3545" color="white" isClicked={handleDelete}>
                Yes, delete
              </SquareButton>
            </ModalButtonGroup>
          </NormalModal>
        </>
      ) : null}
    </ButtonGroupContainer>
  );
};

ButtonGroup.propTypes = {
  confirmationMessage: PropTypes.string,
  confirmBtnName: PropTypes.string,
  deleteBtnName: PropTypes.string,
  hasConfirmButton: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  confirmButtonType: PropTypes.string,
};

ButtonGroup.defaultProps = {
  confirmationMessage: 'Are you sure you want to delete this record?',
  confirmBtnName: 'Save and close',
  deleteBtnName: 'Delete',
  hasConfirmButton: true,
  onCancel: () => null,
  onDelete: undefined,
  onSave: () => null,
  confirmButtonType: 'button',
};

export default ButtonGroup;
