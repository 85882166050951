import gql from 'graphql-tag';

export const CREATE_CODE_CONTENT_SNIPPET = gql`
  mutation createCodeContentSnippet($baseSnippet: CreateContentSnippetInputType!, $codeSnippet: ContentSnippetCodeInputType!) {
    contentSnippet {
      createContentSnippet(baseSnippet: $baseSnippet, contentSnippetCode: $codeSnippet) {
        id
        sort
      }
    }
  }
`;
