import React from 'react';

import PropTypes from 'prop-types';

import Preloader from '../components/Preloader/Preloader';

class MutationRequest extends React.Component {
  componentDidMount() {
    const { mutation, variables } = this.props;

    mutation({
      variables,
    });
  }

  render() {
    const { isLoading } = this.props;

    return isLoading ? <Preloader /> : <></>;
  }
}

MutationRequest.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  mutation: PropTypes.func.isRequired,
  variables: PropTypes.shape({}).isRequired,
};

export default MutationRequest;
