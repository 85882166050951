import React from 'react';

import { Textarea } from '@netfront/ui-library';

const SpeechInput = ({ text, top, left, width }) => {
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);
  return (
    <div
      className="c-cartoon-input"
      style={{
        top: isYAxisSpecified && `${top}%`,
        left: isXAxisSpecified && `${left}%`,
        width: isWidthSpecified && `${width}%`,
      }}
    >
      <Textarea id="id_new_text_area" placeholder={text} />
    </div>
  );
};

export { SpeechInput };
