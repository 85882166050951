import React, { useState } from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import InvoicePage from '../InvoicePage/InvoicePage';
import { ContainerScroll } from '../OrganisationUsersPage/styled';
import {
  RoundButton,
  StyledTableBase,
  StyledTBodyBordered,
  StyledTdBase,
  StyledTdHorizontalCentered,
  StyledTdRightAligned,
  StyledTdSpacer,
  StyledThBase,
  StyledThCentered,
  StyledTHeadBordered,
  StyledThRightAligned,
  StyledThSpacer,
  StyledTrBase,
} from '../styles';

import { LicencedUsersTable } from './LicencedUsersTable/LicencedUsersTable';

import { SideBar } from '../../components/Sidebar/SideBar';
import { getAmountInDollarFormat, UtcDateStringToCurrentTimeZone } from '../../utils';

function GroupOrdersDetailsTable(props) {
  const { onUpdateHandler, ordersDetails } = props;

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [sideBarTabViews, setSideBarTabViews] = useState(undefined);

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
    setSelectedTabIndex(0);
  };

  const handleSideBarOpen = (orderId) => {
    setIsSideBarOpen(true);
    setSideBarTabViews([
      {
        component: <LicencedUsersTable orderId={orderId} ordersDetails={ordersDetails} onUpdateHandler={onUpdateHandler} />,
        label: 'Licencees',
        id: 0,
      },
      {
        component: <InvoicePage orderId={orderId} />,
        label: 'Invoice',
        id: 1,
      },
    ]);
  };

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  return (
    <>
      <ContainerScroll>
        <StyledTableBase>
          <StyledTHeadBordered>
            <StyledTrBase key="table-head">
              <StyledThSpacer />
              <StyledThBase data-sort="licenses" scope="col">
                Modules licensed
              </StyledThBase>
              <StyledThCentered data-sort="date" scope="col">
                Purchase date
              </StyledThCentered>
              <StyledThCentered data-sort="total" scope="col">
                Total licence/s
              </StyledThCentered>
              <StyledThRightAligned data-sort="bill" scope="col">
                Billed amount
              </StyledThRightAligned>
              <StyledThRightAligned data-sort="invoice" scope="col">
                Invoice number
              </StyledThRightAligned>
              <StyledThRightAligned scope="col" />
            </StyledTrBase>
          </StyledTHeadBordered>
          <StyledTBodyBordered>
            {ordersDetails.map((orderDetails) => {
              const { id: orderId, items, modifiedAtUtc, transaction } = orderDetails;
              const { invoiceId = '', totalBilledAmountIncTaxInDollars: billedAmount } = transaction || {};

              const licenses = items.reduce((accumulator, item) => accumulator + item.licences.length, 0);

              return (
                <StyledTrBase key={`orders-details-${orderId}`}>
                  <StyledTdSpacer />
                  <StyledTdBase>
                    {items.map((orderDetailItem) => (
                      <div key={`order-details-item-${orderDetailItem.id}`}>{orderDetailItem.userFlow.title}</div>
                    ))}
                  </StyledTdBase>
                  <StyledTdHorizontalCentered>
                    {format(UtcDateStringToCurrentTimeZone(modifiedAtUtc), 'dd-MM-yyyy')}
                  </StyledTdHorizontalCentered>
                  <StyledTdHorizontalCentered>{licenses}</StyledTdHorizontalCentered>
                  <StyledTdRightAligned>{`$${getAmountInDollarFormat(billedAmount)}`}</StyledTdRightAligned>
                  <StyledTdRightAligned>{invoiceId}</StyledTdRightAligned>
                  <StyledTdRightAligned>
                    <RoundButton onClick={() => handleSideBarOpen(orderId)}>···</RoundButton>
                  </StyledTdRightAligned>
                </StyledTrBase>
              );
            })}
          </StyledTBodyBordered>
        </StyledTableBase>
      </ContainerScroll>
      {sideBarTabViews && (
        <SideBar
          isOpen={isSideBarOpen}
          selectedTabId={selectedTabIndex}
          tabViews={sideBarTabViews}
          onCloseHandler={handleSideBarClose}
          onTabChangeHandler={handleTabChange}
        />
      )}
    </>
  );
}

GroupOrdersDetailsTable.propTypes = {
  ordersDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default GroupOrdersDetailsTable;
