import React from 'react';

/**
 * All of the SVG icons an application needs are stored here.
 *
 * The 'Icon' component binds itself to its matching `<symbol>` element found
 * in this component.
 */
const Icons = () => (
  <svg hidden xmlns="http://www.w3.org/2000/svg">
    <symbol id="icon_camera" viewBox="0 0 24 24">
      <circle cx="12" cy="12" fill="currentColor" fillRule="evenodd" r="3" />
      <path
        d="M20 4h-3.17l-1.24-1.35A1.99 1.99 0 0014.12 2H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </symbol>
    <symbol id="icon_heart" viewBox="0 0 24 24">
      <path
        d="M13.35 20.13c-.76.69-1.93.69-2.69-.01l-.11-.1C5.3 15.27 1.87 12.16 2 8.28c.06-1.7.93-3.33 2.34-4.29 2.64-1.8 5.9-.96 7.66 1.1 1.76-2.06 5.02-2.91 7.66-1.1 1.41.96 2.28 2.59 2.34 4.29.14 3.88-3.3 6.99-8.55 11.76l-.1.09z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </symbol>
    <symbol id="icon_plus" viewBox="0 0 24 24">
      <path
        d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </symbol>
    <symbol id="icon_question_circle" viewBox="0 0 24 24">
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92c-.5.51-.86.97-1.04 1.69-.08.32-.13.68-.13 1.14h-2v-.5a3.997 3.997 0 011.17-2.83l1.24-1.26c.46-.44.68-1.1.55-1.8a1.99 1.99 0 00-1.39-1.53c-1.11-.31-2.14.32-2.47 1.27-.12.37-.43.65-.82.65h-.3C8.4 9 8 8.44 8.16 7.88a4.008 4.008 0 013.23-2.83c1.52-.24 2.97.55 3.87 1.8 1.18 1.63.83 3.38-.19 4.4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </symbol>

    <symbol id="chevron_icon" viewBox="0 0 40 40">
      <path d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" fill="#26305f" />
      <path
        d="M13.908,13.915l5.844-5.84a1.1,1.1,0,0,0,0-1.559,1.113,1.113,0,0,0-1.563,0l-6.621,6.617a1.1,1.1,0,0,0-.032,1.522l6.649,6.663a1.1,1.1,0,0,0,1.563-1.559Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        transform="translate(3.254 6.305)"
      />
    </symbol>
    <symbol id="SQUARE_PLUS" viewBox="0 0 32 32">
      <g transform="translate(-1668 -542)">
        <g fill="#fff" stroke="#262542" strokeWidth="2.5px" transform="translate(1668 542)">
          <rect stroke="none" />
          <rect fill="#fff" height="29.5" width="29.5" x="1.25" y="1.25" />
        </g>
        <g transform="translate(5823.5 10022.5)">
          <path d="M-4105.2-9473.5v16" fill="none" stroke="#262542" strokeWidth="3px" transform="translate(-34.3 1)" />
          <path d="M-4115.7-9463h16" fill="none" stroke="#262542" strokeWidth="3px" transform="translate(-31.8 -1.5)" />
        </g>
      </g>
    </symbol>
    <symbol id="CIRCLED_PLUS" viewBox="0 0 32 32">
      <g fill="#fff" stroke="#262542" strokeWidth="2.5px">
        <circle cx="16" cy="16" r="16" stroke="none" />
        <circle cx="16" cy="16" fill="none" r="14.75" />
      </g>
      <g transform="translate(4155.5 9480.5)">
        <path d="M-4105.2-9473.5v16" fill="none" stroke="#262542" strokeWidth="3px" transform="translate(-34.3 1)" />
        <path d="M-4115.7-9463h16" fill="none" stroke="#262542" strokeWidth="3px" transform="translate(-31.8 -1.5)" />
      </g>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" id="INFORMATION" viewBox="0 0 32 32.002">
      <g transform="translate(0 0.001)">
        <path className="a" fill="#fff" d="M14,0A14,14,0,1,1,0,14,14,14,0,0,1,14,0Z" transform="translate(1.854 1.822)" />
        <g transform="translate(-217.146 22279.822)">
          <path
            className="b"
            d="M387.955,103.079a15.751,15.751,0,0,1-11.242-4.692,16.224,16.224,0,0,1-4.462-14.02,15.916,15.916,0,0,1,27.989-7.5,1.339,1.339,0,0,1-.174,1.888,1.31,1.31,0,0,1-1.854-.172,13.22,13.22,0,0,0-23.3,6.237,13.451,13.451,0,0,0,3.709,11.731,13.092,13.092,0,0,0,11.648,3.72,13.443,13.443,0,0,0,10.952-11.617,12.85,12.85,0,0,0-.116-3.948c-.116-.515-.232-1.03-.348-1.488a1.325,1.325,0,0,1,.869-1.66,1.349,1.349,0,0,1,1.681.858c.174.572.348,1.2.464,1.774a16.265,16.265,0,0,1,.174,4.75,15.884,15.884,0,0,1-13.154,13.963,25.959,25.959,0,0,1-2.839.172"
            transform="translate(-154.9 -22350.9)"
          />
          <text className="c" fill="#3d3d3d" fontSize="16px" fontFamily="Raleway-Bold" fontWeight="700" transform="translate(233.5 -22258)">
            <tspan x="-2.04" y="0">
              i
            </tspan>
          </text>
        </g>
      </g>
    </symbol>
    <symbol id="CIRCLED_BORDER_INCOMPLETE_PLUS" viewBox="0 0 32 32.002">
      <g transform="translate(0 0.002)">
        <circle cx="14" cy="14" fill="#fff" r="14" transform="translate(1.854 1.822)" />
        <g transform="translate(-217.146 22279.822)">
          <path
            d="M399.449,101.023A1.369,1.369,0,0,1,398,99.707V86.316a1.455,1.455,0,0,1,2.9,0V99.649a1.379,1.379,0,0,1-1.449,1.373"
            fill="#262542"
            transform="translate(-165.814 -22356.855)"
          />
          <path
            d="M400.935,99.861h-13.5a1.434,1.434,0,0,1,0-2.861h13.5a1.434,1.434,0,0,1,0,2.861"
            fill="#262542"
            transform="translate(-160.81 -22361.988)"
          />
          <path
            d="M387.955,103.079a15.751,15.751,0,0,1-11.242-4.692,16.224,16.224,0,0,1-4.462-14.02,15.916,15.916,0,0,1,27.989-7.5,1.339,1.339,0,0,1-.174,1.888,1.31,1.31,0,0,1-1.854-.172,13.22,13.22,0,0,0-23.3,6.237,13.451,13.451,0,0,0,3.709,11.731,13.092,13.092,0,0,0,11.648,3.72,13.443,13.443,0,0,0,10.952-11.617,12.85,12.85,0,0,0-.116-3.948c-.116-.515-.232-1.03-.348-1.488a1.325,1.325,0,0,1,.869-1.66,1.349,1.349,0,0,1,1.681.858c.174.572.348,1.2.464,1.774a16.265,16.265,0,0,1,.174,4.75,15.884,15.884,0,0,1-13.154,13.963,25.959,25.959,0,0,1-2.839.172"
            fill="#262542"
            transform="translate(-154.9 -22350.9)"
          />
        </g>
      </g>
    </symbol>
    <symbol id="icon-trash" viewBox="0 0 22 28">
      <path
        fill="#e0546b"
        d="M8 21.5v-11c0-0.281-0.219-0.5-0.5-0.5h-1c-0.281 0-0.5 0.219-0.5 0.5v11c0 0.281 0.219 0.5 0.5 0.5h1c0.281 0 0.5-0.219 0.5-0.5zM12 21.5v-11c0-0.281-0.219-0.5-0.5-0.5h-1c-0.281 0-0.5 0.219-0.5 0.5v11c0 0.281 0.219 0.5 0.5 0.5h1c0.281 0 0.5-0.219 0.5-0.5zM16 21.5v-11c0-0.281-0.219-0.5-0.5-0.5h-1c-0.281 0-0.5 0.219-0.5 0.5v11c0 0.281 0.219 0.5 0.5 0.5h1c0.281 0 0.5-0.219 0.5-0.5zM7.5 6h7l-0.75-1.828c-0.047-0.063-0.187-0.156-0.266-0.172h-4.953c-0.094 0.016-0.219 0.109-0.266 0.172zM22 6.5v1c0 0.281-0.219 0.5-0.5 0.5h-1.5v14.812c0 1.719-1.125 3.187-2.5 3.187h-13c-1.375 0-2.5-1.406-2.5-3.125v-14.875h-1.5c-0.281 0-0.5-0.219-0.5-0.5v-1c0-0.281 0.219-0.5 0.5-0.5h4.828l1.094-2.609c0.313-0.766 1.25-1.391 2.078-1.391h5c0.828 0 1.766 0.625 2.078 1.391l1.094 2.609h4.828c0.281 0 0.5 0.219 0.5 0.5z"
      ></path>
    </symbol>
  </svg>
);

export default Icons;
