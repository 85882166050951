import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_CONTENT_GROUP = gql`
  mutation CreateContentGroup(
    $projectId: String!
    $title: String!
    $description: String
    $type: EContentGroupType
    $url: String!
    $contentType: EContentType!
  ) {
    contentGroup {
      createContentGroup(
        contentGroup: {
          projectId: $projectId
          title: $title
          description: $description
          type: $type
          url: $url
          contentType: $contentType
        }
      ) {
        id
      }
    }
  }
`;

const createContentGroupRequest = ({
  projectId,
  title,
  description,
  url,
  type,
  onError,
  onSuccessResult,
  contentType,
}) => (
  <Mutation mutation={CREATE_CONTENT_GROUP}>
    {(createContentGroup, { loading, error, data }) => {
      const variables = {
        projectId,
        title,
        description,
        type,
        url,
        contentType,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentGroup.createContentGroup.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createContentGroup}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createContentGroupRequest.propTypes = {
  contentType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default createContentGroupRequest;
