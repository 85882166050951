import React from 'react';

const SpeechLabel = ({ children, top, left, width }) => {
  const isYAxisSpecified = Boolean(top);
  const isXAxisSpecified = Boolean(left);
  const isWidthSpecified = Boolean(width);

  return (
    <div
      className="c-cartoon-label"
      style={{
        top: isYAxisSpecified && `${top}%`,
        left: isXAxisSpecified && `${left}%`,
        width: isWidthSpecified && `${width}%`,
      }}
    >
      <div className="c-cartoon-label__content">{children}</div>
    </div>
  );
};

export { SpeechLabel };
