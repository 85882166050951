import PropTypes from 'prop-types';

import DocIcon from '../../static/img/FileType/DocIcon.svg';
import PdfIcon from '../../static/img/FileType/PdfIcon.svg';
import XlsIcon from '../../static/img/FileType/XlsIcon.svg';

export const NOTES_DROPZONE_ACCEPTED_MIME_TYPES = {
  'application/msword': {
    icon: DocIcon,
  },
  'application/pdf': {
    icon: PdfIcon,
  },
  'application/vnd.ms-excel': {
    icon: XlsIcon,
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    icon: XlsIcon,
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    icon: DocIcon,
  },
};

export const NOTES_PROP_TYPES = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      asset: PropTypes.shape({
        contentType: PropTypes.string,
        fileName: PropTypes.string,
        fileSizeInBytes: PropTypes.number,
        presignedPutUrl: PropTypes.string,
        presignedUrl: PropTypes.string,
        s3Key: PropTypes.string,
      }),
      id: PropTypes.number,
    }),
  ),
  id: PropTypes.number,
  message: PropTypes.string,
  subject: PropTypes.string,
};

export const TAB_TYPE_KEYS = {
  addNewTab: 'addNewTab',
  editTab: 'editTab',
  listTab: 'listTab',
  viewTab: 'viewTab',
};
