import React, { Fragment, useEffect, useState } from 'react';

import { VideoSnippetProps } from './VideoSnippet.types';

const VideoSnippet = ({ figureCaption, video: { presignedUrl } }: VideoSnippetProps) => {
  const [videoUrl, setVideoUrl] = useState<string>();

  useEffect(() => {
    setVideoUrl(presignedUrl);
  }, [presignedUrl]);

  return (
    <Fragment>
      {videoUrl && (
        <div className="c-video-snippet">
          <figure className="c-video-snippet__figure">
            <video className="c-video-snippet__video" controls>
              <source src={videoUrl} />
              <track kind="captions" srcLang="en" />
              Sorry, your browser does not support embedded videos.
            </video>
            {figureCaption ? <figcaption className="c-video-snippet__figure-caption">{figureCaption}</figcaption> : null}
          </figure>
        </div>
      )}
    </Fragment>
  );
};

export { VideoSnippet };
