import React, { useState } from 'react';

import cx from 'classnames';
import Rating from 'react-rating';

import { customRatingConstants } from './CustomRating.constants';
import { CustomRatingProps } from './CustomRating.interfaces';

import './CustomRating.css';

const { defaultIconCount, ratingLabelTypesFull, ratingLabelTypesHalf } = customRatingConstants;

const CustomRating = ({
  additionalClassNames,
  iconColor = '#ffc107',
  iconCount = defaultIconCount,
  isLabelTextVisible,
  isReadOnly,
  name,
  precisionType = 'full',
  ratingIconType = 'star',
  size = 'medium',
  rateValue,
  onClick,
}: CustomRatingProps) => {
  const CUSTOM_RATING_ICON_TYPES = {
    star: (
      <span className="c-custom-rating__item" style={{ color: iconColor, fontSize: size }}>
        &#9733;
      </span>
    ),
    starEmpty: (
      <span className="c-custom-rating__item" style={{ color: iconColor, fontSize: size }}>
        &#9734;
      </span>
    ),
    heart: (
      <span className="c-custom-rating__item" style={{ color: iconColor, fontSize: size }}>
        ❤️️
      </span>
    ),
    heartEmpty: (
      <span className="c-custom-rating__item" style={{ color: iconColor, fontSize: size }}>
        🤍
      </span>
    ),
  };

  const [hover, setHover] = useState<number>(-1);

  const precision = precisionType === 'full' ? 1 : 2;

  return (
    <div className={cx('c-custom-rating', additionalClassNames)}>
      {/* eslint-disable @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Rating
        emptySymbol={CUSTOM_RATING_ICON_TYPES[`${ratingIconType}Empty`]}
        fractions={precision}
        fullSymbol={CUSTOM_RATING_ICON_TYPES[ratingIconType]}
        initialRating={rateValue}
        readonly={isReadOnly}
        stop={iconCount}
        onChange={(value) => setHover(value)}
        onClick={onClick}
      />

      {isLabelTextVisible && iconCount === defaultIconCount && (
        <label className="c-custom-rating__label" htmlFor={name}>
          {precision === 1
            ? ratingLabelTypesFull[hover !== -1 ? hover : rateValue]
            : ratingLabelTypesHalf[hover !== -1 ? hover : rateValue]}
        </label>
      )}
    </div>
  );
};

export { CustomRating };
