import gql from 'graphql-tag';

import { SummaryFragment } from '../../../legacy/middleware/QuestionConfiguration/Fragments/Collection';

export const CREATE_SUMMARY_SNIPPET = gql`
  mutation ($baseSnippet: CreateContentSnippetInputType!, $summarySnippet: ContentSnippetSummaryInputType!) {
    contentSnippet {
      createContentSnippet(baseSnippet: $baseSnippet, contentSnippetSummary: $summarySnippet) {
        id
        sort
        ...summary
      }
    }
  }
  ${SummaryFragment}
`;
