import React, { useEffect, useState } from 'react';

import { IMessage } from '@netfront/ui-library';

import { DEFAULT_API_ERROR_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerRatingSnippetProps } from './QuestionAnswerRatingSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerMutationGraphQLResponse,
  ISetUserAnswerRatingMutationVariables,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import { IContentPage, IQuestionAnswerRating } from '../../../../interfaces';
import { RatingSnippet } from '../../RatingSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';

const QuestionAnswerRatingSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  message = DEFAULT_MESSAGE,
  userFlowStepTrackId,
}: QuestionAnswerRatingSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, message: contentSnippetMessage } = contentSnippet ?? {};
  const { rate } = answer ?? ({} as IQuestionAnswerRating);

  const [currentMessage, setCurrentMessage] = useState<IMessage>(message);
  const [rateValue, setRateValue] = useState<number>(rate);

  const [executeSetUserAnswerRating] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerRatingMutationVariables>({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerRating,
    options: {
      onCompleted: () => {
        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            rate: rateValue,
          },
        });

        setCurrentMessage({
          error: '',
          success: DEFAULT_API_SUCCESS_MESSAGE,
        });
      },
      onError: (error) => {
        handleApolloError(error);

        setCurrentMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const clearMessage = () => {
    setCurrentMessage(DEFAULT_MESSAGE);
  };

  const handleRatingClick = (value: number) => {
    if (!contentSnippetFormId) {
      return;
    }

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    const variables: ISetUserAnswerRatingMutationVariables = {
      ...baseVariables,
      rate: value,
    };

    executeSetUserAnswerRating({
      variables,
    });

    setRateValue(value);
    clearMessage();
  };

  useEffect(() => {
    if (!contentSnippetMessage) {
      return;
    }

    setCurrentMessage(contentSnippetMessage);
  }, [contentSnippetMessage]);

  return (
    <RatingSnippet
      id={String(contentSnippetId)}
      message={currentMessage}
      name={`${String(contentSnippetId)}`}
      rateValue={rateValue}
      onClick={handleRatingClick}
    />
  );
};

export { QuestionAnswerRatingSnippet };
