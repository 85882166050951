import React, { useState, useContext, useEffect } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import SliderCreation from '../../components/Slider/SliderCreation';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import SnippetContainer from '../../containers/SnippetContainer/SnippetContainer';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls } from '../../hooks';
import GetSliderDetail from '../../middleware/Slider/getSlider';

const SliderBuilderPage = (props) => {
  const { match, history } = props;
  const { org, project } = useContext(AppContext);

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [detail, setDetail] = useState({});
  const handleLoadError = () => {
    setLoaded(true);
  };
  const handleLoadSuccess = (data) => {
    setDetail(data);
    setLoaded(true);
  };

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${org.key}/${project.id}/library`,
        title: 'Social',
        isExternal: true,
      },
      {
        id: 2,
        path: `/dashboard/${org.key}/${project.id}/library/slider`,
        title: 'Slider library',
      },
      {
        id: 3,
        path: ``,
        title: 'Slider builder',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <div>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Build your own slider"
        pageDescription="Build your own sliders"
        pageTitle="Slider builder page"
        params={match.params}
      >
        <ProjectNavBar title="Slider Builder" />
        <SnippetContainer>
          <SliderCreation history={history} match={match} sliderDetail={detail} />
        </SnippetContainer>
      </PageWrapper>
      {!loaded && (
        <GetSliderDetail sliderId={Number(match.params.sliderId)} onError={handleLoadError} onSuccessResult={handleLoadSuccess} />
      )}
    </div>
  );
};

SliderBuilderPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: { sliderId: PropTypes.string },
  }).isRequired,
};

export default SliderBuilderPage;
