import React from 'react';

import { ConfirmationMessages, CustomRating } from '@netfront/ui-library';

import { RatingSnippetProps } from './RatingSnippet.interfaces';

const RatingSnippet = ({ id, message, name, onClick, rateValue }: RatingSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message ?? {};

  return (
    <React.Fragment>
      <CustomRating name={name} rateValue={rateValue} onClick={onClick} />
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </React.Fragment>
  );
};

export { RatingSnippet };
