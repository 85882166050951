import gql from 'graphql-tag';

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus(
    $newUserStatus: EUserStatus!
    $projectId: String!
    $userIds: [Int]!
  ) {
    user {
      updateUsersStatus(
        newUserStatus: $newUserStatus
        projectId: $projectId
        userIds: $userIds
      )
    }
  }
`;
