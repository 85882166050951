import { useEkardoMutation } from '../useEkardoMutation';

import { START_USER_FLOW_STEP_USING_URLS_MUTATION } from './useStartUserFlowStepUsingUrls.graphql';
import {
  IHandleStartUserFlowStepUsingUrlsParams,
  IStartUserFlowStepUsingUrlsMutationGraphQLResponse,
  IStartUserFlowStepUsingUrlsMutationVariables,
  IUseStartUserFlowStepUsingUrlsOptions,
  IUseStartUserFlowStepUsingUrls,
} from './useStartUserFlowStepUsingUrls.interfaces';

const useStartUserFlowStepUsingUrls = (options?: IUseStartUserFlowStepUsingUrlsOptions): IUseStartUserFlowStepUsingUrls => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseStartUserFlowStepUsingUrlsOptions);

  const [executeStartUserFlowStepUsingUrls, { loading: isLoading }] = useEkardoMutation<
    IStartUserFlowStepUsingUrlsMutationGraphQLResponse,
    IStartUserFlowStepUsingUrlsMutationVariables
  >({
    mutation: mutation ?? START_USER_FLOW_STEP_USING_URLS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStepTrack: { startUserFlowStepUsingUrls: userFlowStepTrack },
        } = data;

        onCompleted({
          userFlowStepTrackId: userFlowStepTrack.id,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleStartUserFlowStepUsingUrls = async ({
    forceNewTracking,
    userFlowStepUrl,
    userFlowUrl,
  }: IHandleStartUserFlowStepUsingUrlsParams) => {
    await executeStartUserFlowStepUsingUrls({
      variables: {
        forceNewTracking,
        userFlowStepUrl,
        userFlowUrl,
      },
    });
  };

  return {
    handleStartUserFlowStepUsingUrls,
    isLoading,
  };
};

export { useStartUserFlowStepUsingUrls };
