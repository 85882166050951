import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../Buttons/Button';

import { SnippetTypeConst } from './SnippetTypeConst';

import client from '../../middleware/client';
import { CREATE_AVATAR_SNIPPET } from '../../middleware/ContentSnippet/createAvatarSnippet';
import { PushRight } from '../../pages/MarketplaceAppDetailsPage/styled';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: 1em 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px 8px 4px 15px;
`;
const EditableAvatarSnippet = forwardRef((props, ref) => {
  const { onCancel, containerId, onCreate } = props;

  const [createSnippet, { loading: createSnippetLoading }] = useMutation(CREATE_AVATAR_SNIPPET, {
    client,
  });

  const handleSave = () => {
    createSnippet({
      variables: {
        containerId,
        title: '',
      },
    }).then((e) => {
      onCreate(e.id, e.sort, SnippetTypeConst.AVATAR, containerId, e.data.contentSnippet.createContentSnippet);
    });
  };

  const isLoading = createSnippetLoading;

  return (
    <>
      <Container>
        <PushRight>
          <Row>
            <Button isDisabled={isLoading} styles="background-color: #505354;margin-right:10px" onClick={onCancel}>
              Cancel
            </Button>
            <Button isDisabled={isLoading} isLoading={isLoading} onClick={handleSave}>
              Save
            </Button>
          </Row>
        </PushRight>
      </Container>
    </>
  );
});

EditableAvatarSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditableAvatarSnippet;
