import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';
import { useRef } from 'react';
import { useApolloClient } from '../apollo/useApolloClient';

export const UPDATE_PROJECT_SETTING = gql`
  mutation createUpdateProjectSettings($projectId: String!, $settings: [ProjectSettingInputType]!) {
    project {
      createUpdateProjectSettings(projectSettings: { projectId: $projectId, settings: $settings })
    }
  }
`;

const updateProjectSettings = ({ projectId, settings, onError, onSuccessResult }) => {
  const client = useRef(useApolloClient('gelada')).current;
  return (
    <Mutation client={client} mutation={UPDATE_PROJECT_SETTING}>
      {(updateProject, { loading, error, data }) => {
        const variables = {
          projectId,
          settings,
        };

        if (error) {
          return <ErrorModal error={error} onError={onError} />;
        }
        if (data) {
          onSuccessResult(data.project.createUpdateProjectSettings.projectSettings);
        }

        return <MutationRequest isLoading={loading} mutation={updateProject} variables={variables} />;
      }}
    </Mutation>
  );
};

updateProjectSettings.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      setting: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default updateProjectSettings;
