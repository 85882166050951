import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ASSET = gql`
  query GetAsset($assetId: String!) {
    asset {
      getAsset(assetId: $assetId) {
        s3Key
        contentType
        assetId
        alt
        fileName
        tags
        transcript
        alt
        xAxisFocus
        uploaded
        presignedUrl
      }
    }
  }
`;

const getAsset = ({ assetId, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_ASSET} variables={{ assetId }}>
    {({ loading, error, data }) => {
      const onSuccessGetAsset = dataDetail => {
        onSuccessResult(
          dataDetail.asset.getAsset.s3Key,
          dataDetail.asset.getAsset.contentType,
          dataDetail.asset.getAsset
        );
      };
      return (
        <QueryRequest
          data={Object.entries(data).length !== 0 && data}
          error={error}
          isLoading={loading}
          onError={onError}
          onSuccessResult={onSuccessGetAsset}
        />
      );
    }}
  </Query>
);

getAsset.propTypes = {
  assetId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAsset;
