import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import InputHolder from '../../components/InputHolder/InputHolder';
import ResetPasswordRequest from '../../middleware/Account/resetPasswordRequest';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      repeatPassword: '',
      errorMessage: '',
      resetRequest: false,
      successResponse: false,
    };
  }

  handleInputs = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleReset = e => {
    const validationErrors = ValidationProcessor(this.validate());
    e.preventDefault();

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });
    } else {
      this.setState({
        errorMessage: '',
        resetRequest: true,
      });
    }
  };

  handleError = message => {
    this.setState({
      resetRequest: false,
      errorMessage: message,
    });
  };

  handleResponse = () => {
    this.setState({
      successResponse: true,
    });
  };

  validate() {
    const { password, repeatPassword } = this.state;
    return {
      PasswordsMatch: {
        validation: password === repeatPassword,
        errorMessage: 'Passwords do not match.',
      },
      PasswordsMinLength: {
        validation: password.length >= 6,
        errorMessage: 'Password must have minimum 6 chars.',
      },
      PasswordEmpty: {
        validation: password.length > 0,
        errorMessage: 'Password must not be empty.',
      },
    };
  }

  render() {
    const { token } = this.props;

    const {
      password,
      repeatPassword,
      errorMessage,
      resetRequest,
      successResponse,
    } = this.state;

    return (
      <div>
        <form className="login-page__form">
          <InputHolder
            label="Password"
            name="password"
            onChange={this.handleInputs}
            required
            type="password"
            value={password}
          />

          <InputHolder
            label="Repeat password"
            name="repeatPassword"
            onChange={this.handleInputs}
            required
            type="password"
            value={repeatPassword}
          />

          <button
            className="button--outline"
            onClick={this.handleReset}
            style={{
              margin: '1em 0 1em auto',
            }}
            type="button"
          >
            <span>RESET</span>
          </button>
        </form>

        {errorMessage !== '' && <ErrorMessage message={errorMessage} />}
        {resetRequest && (
          <ResetPasswordRequest
            newPassword={password}
            onError={this.handleError}
            onSuccessResult={this.handleResponse}
            token={token}
          />
        )}
        {successResponse && <Redirect to="/login" />}
      </div>
    );
  }
}

ResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ResetPassword;
