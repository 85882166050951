import React from 'react';

import { LayoutItemProps } from './LayoutItem.types';

/**
 * An item that lives inside a Layout component
 */
const LayoutItem = ({ as = 'div', children }: LayoutItemProps) => {
  /* `as` is already typed */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = as;

  return <Tag className="l-layout__item">{children}</Tag>;
};

export { LayoutItem };
