import gql from 'graphql-tag';

import { NOTE_ATTACHMENT_FRAGMENT, NOTE_FRAGMENT } from '../fragments/notes';

export const ADD_GROUP_NOTE = gql`
  mutation AddGroupNote($entityId: Int!, $subject: String!, $message: String!, $attachments: [CreateNoteAttachmentAssetInputType]) {
    note {
      addGroupNote(groupId: $entityId, subject: $subject, message: $message, attachments: $attachments) {
        ...note
      }
    }
  }
  ${NOTE_ATTACHMENT_FRAGMENT}
  ${NOTE_FRAGMENT}
`;

export const ADD_USER_NOTE = gql`
  mutation AddUserNote($entityId: Int!, $subject: String!, $message: String!, $attachments: [CreateNoteAttachmentAssetInputType]) {
    note {
      addUserNote(userId: $entityId, subject: $subject, message: $message, attachments: $attachments) {
        ...note
      }
    }
  }
  ${NOTE_ATTACHMENT_FRAGMENT}
  ${NOTE_FRAGMENT}
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($noteId: Int!) {
    note {
      removeNote(noteId: $noteId)
    }
  }
`;

export const EDIT_NOTE = gql`
  mutation EditNote($noteId: Int!, $subject: String!, $message: String!, $attachments: [CreateNoteAttachmentAssetInputType]) {
    note {
      editNote(noteId: $noteId, subject: $subject, message: $message, attachments: $attachments) {
        ...note
      }
    }
  }
  ${NOTE_ATTACHMENT_FRAGMENT}
  ${NOTE_FRAGMENT}
`;
