import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import GeladaClient from '../_Gelada/geladaClient';
import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    forgotPasswordUpdate(code: $token, newPassword: $newPassword)
  }
`;

const resetPasswordRequest = ({
  token,
  newPassword,
  onError,
  onSuccessResult,
}) => (
  <Mutation client={GeladaClient()} mutation={RESET_PASSWORD}>
    {(resetPassword, { loading, error, data }) => {
      const variables = {
        token,
        newPassword,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult();
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={resetPassword}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

resetPasswordRequest.propTypes = {
  newPassword: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default resetPasswordRequest;
