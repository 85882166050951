import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_VIDEO_SNIPPET = gql`
  mutation updateQuestionContentSnippet($snippetId: Int!, $startAt: Int!, $assetId: String!, $title: String!) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId, entityTitle: $title }
        contentSnippetVideo: { startAt: $startAt, videoId: $assetId }
      ) {
        id
        sort
        visible
      }
    }
  }
`;

const updateContentVideoSnippetReq = ({ snippetId, startAt, assetId, onSuccessResult, onError }) => (
  <Mutation mutation={UPDATE_CONTENT_VIDEO_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        startAt,
        assetId,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id, data.contentSnippet.updateContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateContentVideoSnippetReq.propTypes = {
  assetId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  startAt: PropTypes.number.isRequired,
};

export default updateContentVideoSnippetReq;
