/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import InputHolder from '../../components/InputHolder/InputHolder';
import PopupMessageSideBar from '../../components/PopupMessageSideBar/PopupMessageSideBar';
import SideBarRight from '../../components/SideBarRight/SideBarRight';
import { errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import UpdateUserRequest from '../../middleware/User/updateUserRequest';

class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      secondaryEmail: '',
      mobilePhone: '',
      errorMessage: '',
      requestSent: false,
    };
  }

  componentDidMount() {
    const { firstName, lastName, secondaryEmail, mobilePhone } = this.props;

    this.setState({
      firstName,
      lastName,
      secondaryEmail,
      mobilePhone,
    });
  }

  componentDidUpdate(prevProps) {
    const { firstName, lastName, secondaryEmail, mobilePhone, isPanelOpen } = this.props;

    if (isPanelOpen !== prevProps.isPanelOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        firstName,
        lastName,
        secondaryEmail,
        mobilePhone,
      });
    }
  }

  handleClearErrorMessages = () => {
    const { errorMessage } = this.state;

    if (errorMessage !== '') {
      this.setState({
        errorMessage: '',
      });
    }
  };

  handleInputs = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validate = () => {
    const { firstName, lastName, secondaryEmail, mobilePhone } = this.state;

    return {
      FirstnameEmpty: {
        validation: firstName.trim().length > 0,
        errorMessage: 'Firstname must not be empty.',
      },
      LastnameEmpty: {
        validation: lastName.trim().length > 0,
        errorMessage: 'Lastname must not be empty.',
      },
      SecondaryEmailEmpty: {
        validation: secondaryEmail.trim().length > 0,
        errorMessage: 'Secondary Email must not be empty.',
      },
    };
  };

  handleOnError = () => {
    this.setState({
      requestSent: false,
    });
  };

  handleOnUserData = () => {
    const { firstName, lastName, secondaryEmail, mobilePhone } = this.state;
    const { updateUserName, handleClosePanel } = this.props;
    handleClosePanel();

    this.setState({
      requestSent: false,
    });
    updateUserName(firstName, lastName, secondaryEmail, mobilePhone);
  };

  handleUpdateUser = (e) => {
    e.preventDefault();

    const validationErrors = ValidationProcessor(this.validate());

    if (!validationErrors.modelValid) {
      this.setState({ errorMessage: validationErrors.validations });
    } else {
      this.setState({
        requestSent: true,
      });
    }
  };

  render() {
    const { firstName, lastName, requestSent, errorMessage, mobilePhone, secondaryEmail } = this.state;

    const { id, isPanelOpen, handleClosePanel } = this.props;

    return (
      <SideBarRight open={isPanelOpen}>
        <button className="c-sidebar-right__close" onClick={handleClosePanel} onKeyDown={handleClosePanel} type="button">
          <span className="icon-plus" />
        </button>
        <div className="c-sidebar-right-form">
          <p className="c-sidebar-right-title">Update name</p>
          <form autoComplete="off">
            <InputHolder
              label="First name"
              name="firstName"
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
              required
              value={firstName}
            />

            <InputHolder
              label="Last name"
              name="lastName"
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
              required
              value={lastName}
            />

            <InputHolder
              label="Secondary Email"
              name="secondaryEmail"
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
              required
              value={secondaryEmail}
            />
          </form>

          <div className="text-right">
            <button className="button--black" onClick={handleClosePanel} style={{ margin: '1.5em 1em 1.5em 0' }} type="button">
              Cancel
            </button>
            <button
              className="button--blue"
              onClick={(e) => {
                // handleClosePanel();
                this.handleUpdateUser(e);
              }}
              style={{ margin: '1.5em 0' }}
              type="button"
            >
              Save
            </button>
          </div>
        </div>

        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
        </ReactCSSTransitionGroup>

        {requestSent && (
          <UpdateUserRequest
            firstname={firstName}
            id={id}
            lastname={lastName}
            mobilePhone={mobilePhone}
            onError={this.handleOnError}
            onSuccessResult={this.handleOnUserData}
            secondaryEmail={secondaryEmail}
          />
        )}
      </SideBarRight>
    );
  }
}

UpdateUser.propTypes = {
  firstName: PropTypes.string.isRequired,
  handleClosePanel: PropTypes.func.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
  mobilePhone: PropTypes.string.isRequired,
  secondaryEmail: PropTypes.string.isRequired,
  updateUserName: PropTypes.func.isRequired,
  id: PropTypes.number,
};

UpdateUser.defaultProps = {
  id: null,
};

export default UpdateUser;
