import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const AddIcon = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 0.8em;
  width: 0.8em;
  line-height: 0;
  font-size: ${props => props.fontSize ?? '32px'};
  color: ${props => props.color ?? '#fff'};
  background-color: ${props => props.backgroundColor ?? '#6d7a8a'};
  border: none;
  border-radius: ${props => props.borderRadius ?? '50%'};
  margin: 0;
  padding: ${props => props.padding ?? '0'};
  outline: none;
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    background-color: #4bc2ca;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }
`;

// **************styled Components********************

const PlusButtonCircle = props => {
  const { onClicked, fontSize, color, backgroundColor, borderRadius, padding } = props;

  return (
    <AddIcon
      onClick={onClicked}
      color={color}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      fontSize={fontSize}
      padding={padding}
    >
      <div style={{ marginBottom: '.2em' }}>+</div>
    </AddIcon>
  );
};

PlusButtonCircle.propTypes = {
  onClicked: PropTypes.func,
};

PlusButtonCircle.defaultProps = {
  onClicked: () => {},
};

export default PlusButtonCircle;
