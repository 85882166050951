/* eslint-disable @typescript-eslint/indent */
import camelCase from 'lodash.camelcase';

import {
  COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP,
  COOKIE_EXPIRED_DATE,
  COOKIE_MAX_SIZE,
  COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP,
} from './useCookie.constants';
import {
  ICookieOptionalAttributesInput,
  ICookieOptionalAttributes,
  ICookie,
  ICreateSpecificCookieParams,
  IDeleteCookieAttributesInput,
} from './useCookie.interfaces';

import { getByteSize } from '../../utils';

const createCookie = (name: string, value: string, optionalAttributesInput?: ICookieOptionalAttributesInput): ICookie => {
  const optionalAttributes = optionalAttributesInput
    ? ((): ICookieOptionalAttributes => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { path = '/', sameSite = 'Lax', secure, storageExpiryOptions, ...remainingOptionalAttributes } = optionalAttributesInput;

        const { expiryTime } = storageExpiryOptions ?? {};
        const { unit: expiryTimeUnit, value: expiryTimeValue } = expiryTime ?? {};

        const expires =
          expiryTimeUnit && expiryTimeValue
            ? COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP[expiryTimeUnit](new Date(), expiryTimeValue).toUTCString()
            : undefined;

        return {
          ...remainingOptionalAttributes,
          expires,
          path,
          sameSite,
          secure: sameSite === 'None' ? true : secure,
        };
      })()
    : ((): ICookieOptionalAttributes => {
        // Make sure cookies expire in one day by default
        const expires = COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP.days(new Date(), 1).toUTCString();

        return {
          expires,
          sameSite: 'Lax',
        };
      })();

  return {
    name,
    value,
    ...optionalAttributes,
  };
};

const getAllCookies = (): Map<string, string> | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return document.cookie.split('; ').reduce((accumulator, cookie) => {
    const [name, value] = cookie.split('=');

    accumulator.set(name, value);

    return accumulator;
  }, new Map<string, string>());
};

const getCookieValue = (name: string): string | undefined => {
  return getAllCookies()?.get(name);
};

const serializeCookie = (cookie: ICookie): string => {
  const { name, value, ...optionalAttributes } = cookie;
  const { httpOnly, maxAge, secure } = COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP;

  const pascalCase = (text: string) =>
    (text.match(/[a-zA-Z0-9]+/g) ?? []).map((w: string) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join('');

  const optionalAttributesMap = Object.keys(optionalAttributes).reduce((accumulator, key) => {
    const optionalCookieAttribute = cookie[key as keyof ICookie];

    const cookieKey = key === maxAge.interfaceKey ? maxAge.attributeName : pascalCase(key);
    const cookieAttributeValue = key === httpOnly.interfaceKey || key === secure.interfaceKey ? '' : `=${optionalCookieAttribute}`;

    if (optionalCookieAttribute) {
      accumulator.set(cookieKey, `${cookieKey}${cookieAttributeValue}`);
    }

    return accumulator;
  }, new Map());

  const optionalAttributesMapValues = Array.from<string>(optionalAttributesMap.values());
  const serializedOptionalAttributes = optionalAttributesMapValues.join('; ');
  const outputSerializedOptionalAttributes = serializedOptionalAttributes ? `; ${serializedOptionalAttributes}` : '';

  return `${name}=${value}${outputSerializedOptionalAttributes}`;
};

const setCookie = (cookie: ICookie): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const serializedCookie = serializeCookie(cookie);

  document.cookie = serializedCookie;
};

const deleteCookie = (name: string, deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const value = getCookieValue(name);

  if (!value) {
    return;
  }

  const updatedCookie: ICookie = {
    ...deleteCookieAttributesInput,
    expires: COOKIE_EXPIRED_DATE,
    maxAge: 0,
    name,
    value,
  };

  setCookie(updatedCookie);
};

const deserializeCookie = (cookie: string): ICookie => {
  const { expires, httpOnly, maxAge, secure } = COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP;

  return cookie.split('; ').reduce((accumulator, keyValuePair, index) => {
    const [key, value] = keyValuePair.split('=');

    const cookieKey = camelCase(key);
    const isFirstIndex = index === 0;

    let cookieValue;

    switch (cookieKey) {
      case expires.interfaceKey: {
        cookieValue = new Date(value).toISOString();
        break;
      }
      case httpOnly.interfaceKey: {
        cookieValue = true;
        break;
      }
      case maxAge.interfaceKey: {
        cookieValue = Number(value);
        break;
      }
      case secure.interfaceKey: {
        cookieValue = true;
        break;
      }
      default: {
        cookieValue = value;
      }
    }

    const partialCookie: Partial<ICookie> = isFirstIndex
      ? {
          name: key,
          value,
        }
      : {
          [cookieKey]: cookieValue,
        };

    return {
      ...accumulator,
      ...partialCookie,
    };
  }, {}) as ICookie;
};

const isSecureCookie = (secureCookieAttribute: string | undefined): boolean => {
  return (secureCookieAttribute ?? '').toUpperCase() === 'Y';
};

const isValidCookieSize = (cookie: string): boolean => {
  return getByteSize(cookie) <= COOKIE_MAX_SIZE;
};

const validateCookieSize = (cookie: ICookie): void => {
  const serializedCookie = serializeCookie(cookie);
  const cookieSize = getByteSize(serializedCookie);

  if (cookieSize > COOKIE_MAX_SIZE) {
    throw new Error(
      `The ${
        cookie.name
      } cookie is ${cookieSize.formatWithThousandsSeparator()} bytes and exceeded the maximum cookie size of ${COOKIE_MAX_SIZE.formatWithThousandsSeparator()} bytes`,
    );
  }
};

const createAccessTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('accessToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createCustomBuildProjectGuidCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('customBuildProjectGuid', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createImpersonatedUserAccessTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('impersonatedUserAccessToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createImpersonatedUserDataCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('impersonatedUserData', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createImpersonatedUserRefreshTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('impersonatedUserRefreshToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createImpersonatedUserTemporaryTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('impersonatedUserTemporaryToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createLastOrganisationVisitedCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('lastOrganisationVisited', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createLastProjectVisitedCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('lastProjectVisited', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createLastUrlBeforeAuthenticationExpiredCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('lastUrlBeforeAuthenticationExpired', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createOrganisationIdCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('organisationId', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createProjectDomainCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('projectDomain', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createProjectGuidCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('projectGuid', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createProjectLogoUrlCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('projectLogoUrl', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createProjectNameCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('projectName', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createRedirectAfterLoginUrlCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('redirectAfterLoginUrl', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(createCookie('redirectAfterLoginUrl', value, optionalCookieAttributesInput));
};

const createRefreshTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('refreshToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createReturnUrlCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('returnUrl', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createStopImpersonationReturnUrlCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('stopImpersonationReturnUrl', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createTemporaryTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('temporaryToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createUserDataCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  const cookie = createCookie('userData', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const deleteAccessTokenCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('accessToken', deleteCookieAttributesInput);
};

const deleteCustomBuildProjectGuidCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('customBuildProjectGuid', deleteCookieAttributesInput);
};

const deleteImpersonatedUserAccessTokenCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('impersonatedUserAccessToken', deleteCookieAttributesInput);
};

const deleteImpersonatedUserDataCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('impersonatedUserData', deleteCookieAttributesInput);
};

const deleteImpersonatedUserRefreshTokenCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('impersonatedUserRefreshToken', deleteCookieAttributesInput);
};

const deleteImpersonatedUserTemporaryTokenCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('impersonatedUserTemporaryToken', deleteCookieAttributesInput);
};

const deleteLastOrganisationVisitedCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('lastOrganisationVisited', deleteCookieAttributesInput);
};

const deleteLastProjectVisitedCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('lastProjectVisited', deleteCookieAttributesInput);
};

const deleteLastUrlBeforeAuthenticationExpiredCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('lastUrlBeforeAuthenticationExpired', deleteCookieAttributesInput);
};

const deleteOrganisationIdCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('organisationId', deleteCookieAttributesInput);
};

const deleteProjectDomainCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('projectDomain', deleteCookieAttributesInput);
};

const deleteProjectGuidCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('projectGuid', deleteCookieAttributesInput);
};

const deleteProjectLogoUrlCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('projectLogoUrl', deleteCookieAttributesInput);
};

const deleteProjectNameCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('projectName', deleteCookieAttributesInput);
};

const deleteRedirectAfterLoginUrlCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('redirectAfterLoginUrl', deleteCookieAttributesInput);
};

const deleteRefreshTokenCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('refreshToken', deleteCookieAttributesInput);
};

const deleteReturnUrlCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('returnUrl', deleteCookieAttributesInput);
};

const deleteStopImpersonationReturnUrlCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('stopImpersonationReturnUrl', deleteCookieAttributesInput);
};

const deleteTemporaryTokenCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('temporaryToken', deleteCookieAttributesInput);
};

const deleteUserDataCookie = (deleteCookieAttributesInput: IDeleteCookieAttributesInput): void => {
  deleteCookie('userData', deleteCookieAttributesInput);
};

const getAccessTokenCookie = (): string | undefined => {
  return getCookieValue('accessToken');
};

const getCustomBuildProjectGuidCookie = (): string | undefined => {
  return getCookieValue('customBuildProjectGuid');
};

const getImpersonatedUserAccessTokenCookie = (): string | undefined => {
  return getCookieValue('impersonatedUserAccessToken');
};

const getImpersonatedUserDataCookie = (): string | undefined => {
  return getCookieValue('impersonatedUserData');
};

const getImpersonatedUserRefreshTokenCookie = (): string | undefined => {
  return getCookieValue('impersonatedUserRefreshToken');
};

const getImpersonatedUserTemporaryTokenCookie = (): string | undefined => {
  return getCookieValue('impersonatedUserTemporaryToken');
};

const getLastOrganisationVisitedCookie = (): string | undefined => {
  return getCookieValue('lastOrganisationVisited');
};

const getLastProjectVisitedCookie = (): string | undefined => {
  const value = getCookieValue('lastProjectVisited');
  return value ? decodeURIComponent(value) : undefined;
};

const getLastUrlBeforeAuthenticationExpiredCookie = (): string | undefined => {
  const value = getCookieValue('lastUrlBeforeAuthenticationExpired');
  return value ? decodeURIComponent(value) : undefined;
};

const getOrganisationIdCookie = (): string | undefined => {
  return getCookieValue('organisationId');
};

const getProjectDomainCookie = (): string | undefined => {
  return getCookieValue('projectDomain');
};

const getProjectGuidCookie = (): string | undefined => {
  return getCookieValue('projectGuid');
};

const getProjectLogoUrlCookie = (): string | undefined => {
  const projectLogoUrlCookie = getCookieValue('projectLogoUrl');
  return projectLogoUrlCookie ? decodeURIComponent(projectLogoUrlCookie) : undefined;
};

const getProjectNameCookie = (): string | undefined => {
  return getCookieValue('projectName');
};

const getRedirectAfterLoginUrlCookie = (): string | undefined => {
  const redirectAfterLoginUrlCookie = getCookieValue('redirectAfterLoginUrl');
  return redirectAfterLoginUrlCookie ? decodeURIComponent(redirectAfterLoginUrlCookie) : undefined;
};

const getRefreshTokenCookie = (): string | undefined => {
  return getCookieValue('refreshToken');
};

const getReturnUrlCookie = (): string | undefined => {
  const value = getCookieValue('returnUrl');
  return value ? decodeURIComponent(value) : undefined;
};

const getStopImpersonationReturnUrlCookie = (): string | undefined => {
  return getCookieValue('stopImpersonationReturnUrl');
};

const getTemporaryTokenCookie = (): string | undefined => {
  return getCookieValue('temporaryToken');
};

const getUserDataCookie = (): string | undefined => {
  return getCookieValue('userData');
};

export {
  createAccessTokenCookie,
  createCookie,
  createCustomBuildProjectGuidCookie,
  createImpersonatedUserAccessTokenCookie,
  createImpersonatedUserDataCookie,
  createImpersonatedUserRefreshTokenCookie,
  createImpersonatedUserTemporaryTokenCookie,
  createLastOrganisationVisitedCookie,
  createLastProjectVisitedCookie,
  createLastUrlBeforeAuthenticationExpiredCookie,
  createOrganisationIdCookie,
  createProjectDomainCookie,
  createProjectGuidCookie,
  createProjectLogoUrlCookie,
  createProjectNameCookie,
  createRedirectAfterLoginUrlCookie,
  createRefreshTokenCookie,
  createReturnUrlCookie,
  createStopImpersonationReturnUrlCookie,
  createTemporaryTokenCookie,
  createUserDataCookie,
  deleteAccessTokenCookie,
  deleteCookie,
  deleteCustomBuildProjectGuidCookie,
  deleteImpersonatedUserAccessTokenCookie,
  deleteImpersonatedUserDataCookie,
  deleteImpersonatedUserRefreshTokenCookie,
  deleteImpersonatedUserTemporaryTokenCookie,
  deleteLastOrganisationVisitedCookie,
  deleteLastProjectVisitedCookie,
  deleteLastUrlBeforeAuthenticationExpiredCookie,
  deleteOrganisationIdCookie,
  deleteProjectDomainCookie,
  deleteProjectGuidCookie,
  deleteProjectLogoUrlCookie,
  deleteProjectNameCookie,
  deleteRedirectAfterLoginUrlCookie,
  deleteRefreshTokenCookie,
  deleteReturnUrlCookie,
  deleteStopImpersonationReturnUrlCookie,
  deleteTemporaryTokenCookie,
  deleteUserDataCookie,
  deserializeCookie,
  getAccessTokenCookie,
  getAllCookies,
  getCookieValue,
  getCustomBuildProjectGuidCookie,
  getImpersonatedUserAccessTokenCookie,
  getImpersonatedUserDataCookie,
  getImpersonatedUserRefreshTokenCookie,
  getImpersonatedUserTemporaryTokenCookie,
  getLastOrganisationVisitedCookie,
  getLastProjectVisitedCookie,
  getLastUrlBeforeAuthenticationExpiredCookie,
  getOrganisationIdCookie,
  getProjectDomainCookie,
  getProjectGuidCookie,
  getProjectLogoUrlCookie,
  getProjectNameCookie,
  getRedirectAfterLoginUrlCookie,
  getRefreshTokenCookie,
  getReturnUrlCookie,
  getStopImpersonationReturnUrlCookie,
  getTemporaryTokenCookie,
  getUserDataCookie,
  isSecureCookie,
  isValidCookieSize,
  serializeCookie,
  setCookie,
};
