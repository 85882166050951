import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

export const QuestionContainer = styled.div`
  display: flex;
  flex-flow: ${props => (props.isSidebar ? 'column' : 'row')};
  justify-content: space-between;
  margin: 1em 0;
`;

export const QuestionEditor = styled.div`
  width: ${props => (props.isSidebar || props.isUpdate ? '100%' : '75%')};
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: ${props => (props.isSidebar ? '1em' : '0')};
`;

export const Response = styled.div`
  width: ${props => (props.isSidebar ? '100%' : 'calc(25% - 1em)')};
  height: fit-content;
  border-radius: 5px;
`;

export const ResponseTypes = styled.div`
  display: flex;
  flex-flow: row wrap;
  border: none;
  margin-right: -1em;
`;

export const IconImg = styled.img`
  filter: ${({ isActive }) =>
    isActive ? '' : 'grayscale(100%) brightness(125%) contrast(110%)'};
`;

export const ResTooltip = styled.span`
  visibility: hidden;
  width: 6em;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  left: -2em;
  top: 2.4em;
  opacity: 0.8;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
`;

export const ResponseIcon = styled.button`
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  margin: 0 1em 0.5em 0;
  background-color: ${({ isActive }) => (isActive ? '#4bc2ca' : '#ebecec')};
  border: none;
  border-radius: 50%;
  text-align: center;
  font-size: 1em;
  color: #fff;
  line-height: 1em;
  padding: 0;
  cursor: pointer;
  &:hover {
    background-color: #4bc2ca;
    ${IconImg} {
      filter: unset;
    }
    ${ResTooltip} {
      visibility: visible;
    }
  }
  &:focus {
    outline: none;
  }
`;

export const InfoIcon = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 13px;
  text-align: center;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  margin: 0 1rem;
  background-color: #4bc1c9;
  color: #fff;
`;

export const AddNewResponse = styled.div`
  width: 100%;
  height: 3rem;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #e4e4e3;
  cursor: pointer;
`;

export const ResponseSet = styled.div`
  border-radius: 5px;
  margin: 0.2em 0;
  overflow: hidden;
`;

export const Search = styled.input`
  border: 1px solid #e4e4e3;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 3rem;
  background-color: unset;
  padding: 1rem;
`;

export const SearchSuggetionsRow = styled.button`
  background: none;
  padding: 5px;
  border: 1px solid #e1e1e1;
  text-align: left;
`;

export const SearchSuggetionsBox = styled.div`
  max-height: 108px;
  overflow-y: scroll;
`;

export const StyledTextarea = styled(TextareaAutosize)`
  display: block;
  width: calc(100% - 1em);
  background: #fff;
  line-height: 1rem;
  border: 0.8px solid #e4e4e3;
  border-radius: 5px;
  margin: 0.5rem 0;
  padding: 0.8em;
  font-family: inherit;
  font-size: inherit;
  &:focus {
    outline: none;
    background: #fff;
    border: 2px solid lightgray;
  }
`;

export const SwitchBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
`;

export const ModeTitle = styled.div`
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
