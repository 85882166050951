import gql from 'graphql-tag';

export const GET_SNIPPET_EVENT_DETAILS = gql`
  query getSnippetDetailRequest($contentSnippetId: Int!) {
    contentSnippet {
      getContentSnippet(contentSnippetId: $contentSnippetId) {
        id
        contentEventId
        contentEvent {
          id
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          assetId
          asset {
            assetId
            contentType
            s3Key
            presignedUrl
          }
          backgroundColor
          borderColor
          titleColor
          style
          isDefaultOpen
          triggerStyle
          iconHorizontalAlignment
        }
      }
    }
  }
`;
