import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip, { WithTooltipWrapper } from '../UI/Tooltip/Tooltips';

import { LayoutContainerConst } from './LayoutContainerConst';

import { getAssetsUrl } from '../../config';

const fullStretch = `${getAssetsUrl()}/icons/container-options/full-stretch.svg`;
const halfHalf = `${getAssetsUrl()}/icons/container-options/half-half.svg`;
const oneThirdLeft = `${getAssetsUrl()}/icons/container-options/one-third-half-left.svg`;
const oneThirdRight = `${getAssetsUrl()}/icons/container-options/one-third-half-right.svg`;
const threeThirds = `${getAssetsUrl()}/icons/container-options/three-thirds.svg`;
const fourQuarters = `${getAssetsUrl()}/icons/container-options/four-quarters.svg`;
const threeTwoQuartersLeft = `${getAssetsUrl()}/icons/container-options/three-two-quarters-left.svg`;
const threeTwoQuartersRight = `${getAssetsUrl()}/icons/container-options/three-two-quarters-right.svg`;
const threeOneQuartersLeft = `${getAssetsUrl()}/icons/container-options/three-one-quarters-left.svg`;
const threeOneQuartersRight = `${getAssetsUrl()}/icons/container-options/three-one-quarters-right.svg`;

const fullStretchActive = `${getAssetsUrl()}/icons/container-options/full-stretch_active.svg`;
const halfHalfActive = `${getAssetsUrl()}/icons/container-options/half-half_active.svg`;
const oneThirdLeftActive = `${getAssetsUrl()}/icons/container-options/one-third-half-left_active.svg`;
const oneThirdRightActive = `${getAssetsUrl()}/icons/container-options/one-third-half-right_active.svg`;
const threeThirdsActive = `${getAssetsUrl()}/icons/container-options/three-thirds_active.svg`;
const fourQuartersActive = `${getAssetsUrl()}/icons/container-options/four-quarters_active.svg`;
const threeTwoQuartersLeftActive = `${getAssetsUrl()}/icons/container-options/three-two-quarters-left_active.svg`;
const threeTwoQuartersRightActive = `${getAssetsUrl()}/icons/container-options/three-two-quarters-right_active.svg`;
const threeOneQuartersLeftActive = `${getAssetsUrl()}/icons/container-options/three-one-quarters-left_active.svg`;
const threeOneQuartersRightActive = `${getAssetsUrl()}/icons/container-options/three-one-quarters-right_active.svg`;

// `${getAssetsUrl()}/icons/container-options/tabs_active.svg`;

const Container = styled.div`
  border: 1px solid rgba(0, 42, 76, 0.1);
  border-radius: 5px;
  margin: calc(3em + 1vw) 0 0 0;
  padding: 1.4em 2em;
  position: relative;

  @media screen and (prefers-reduced-motion: reduce) {
    img {
      transition: none;

      &:hover {
        box-shadow: 0 7px 15px 0 rgba(0, 42, 76, 0.09);
        filter: brightness(45%) sepia(100%) hue-rotate(135deg) saturate(800%);
        transform: scale(1.05);
      }
    }
  }

  img {
    transition: 0.25s ease-in-out;

    &:hover {
      box-shadow: 0 7px 15px 0 rgba(0, 42, 76, 0.09);
      filter: brightness(45%) sepia(100%) hue-rotate(135deg) saturate(800%);
      transform: scale(1.05);
    }
  }
`;

const CloseBtn = styled.span`
  cursor: pointer;
  position: absolute;
  right: 1.5em;
  top: 1em;
`;

function LayoutContainer(props) {
  const { handleActiveLayout, activeLayout, onClose } = props;

  return (
    <>
      <Container>
        <WithTooltipWrapper style={{ marginBottom: '1rem' }}>
          <h4>Layout</h4>
          <Tooltip text="Select a section layout" />
        </WithTooltipWrapper>

        <CloseBtn onClick={onClose}>X</CloseBtn>
        <div>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(this, LayoutContainerConst.PLANE)}
            type="button"
          >
            <img
              alt="fullStretch"
              src={
                activeLayout !== LayoutContainerConst.PLANE
                  ? fullStretch
                  : fullStretchActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_TWO
            )}
            type="button"
          >
            <img
              alt="halfHalf"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_TWO
                  ? halfHalf
                  : halfHalfActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_TWO_LEFT
            )}
            type="button"
          >
            <img
              alt="oneThirdLeft"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_TWO_LEFT
                  ? oneThirdRight
                  : oneThirdRightActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_TWO_RIGHT
            )}
            type="button"
          >
            <img
              alt="oneThirdRight"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_TWO_RIGHT
                  ? oneThirdLeft
                  : oneThirdLeftActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_TWO_RIGHT_BAR
            )}
            type="button"
          >
            <img
              alt="threeOneQuartersLeft"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_TWO_LEFT_BAR
                  ? threeOneQuartersRight
                  : threeOneQuartersRightActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_TWO_LEFT_BAR
            )}
            type="button"
          >
            <img
              alt="threeOneQuartersRight"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_TWO_RIGHT_BAR
                  ? threeOneQuartersLeft
                  : threeOneQuartersLeftActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_THREE
            )}
            type="button"
          >
            <img
              alt="threeThirds"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_THREE
                  ? threeThirds
                  : threeThirdsActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_THREE_LEFT
            )}
            type="button"
          >
            <img
              alt="threeTwoQuartersRight"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_THREE_LEFT
                  ? threeTwoQuartersRight
                  : threeTwoQuartersRightActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_THREE_RIGHT
            )}
            type="button"
          >
            <img
              alt="threeTwoQuartersLeft"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_THREE_RIGHT
                  ? threeTwoQuartersLeft
                  : threeTwoQuartersLeftActive
              }
            />
          </button>
          <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.ONE_BY_FOUR
            )}
            type="button"
          >
            <img
              alt="fourQuarters"
              src={
                activeLayout !== LayoutContainerConst.ONE_BY_FOUR
                  ? fourQuarters
                  : fourQuartersActive
              }
            />
          </button>
          {/* <button
            className="button-none layout-icon"
            onClick={handleActiveLayout.bind(
              this,
              LayoutContainerConst.TAB_TOP
            )}
            type="button"
          >
            <img
              alt="tabs"
              src={
                activeLayout !== LayoutContainerConst.TAB_TOP
                  ? tabs
                  : tabsActive
              }
            />
          </button> */}
        </div>
      </Container>
    </>
  );
}

LayoutContainer.propTypes = {
  activeLayout: PropTypes.string.isRequired,
  handleActiveLayout: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LayoutContainer;
