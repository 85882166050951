import React, { useEffect, useState, useContext, useRef } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Dialog } from '@netfront/ui-library';
import sortby from 'lodash.sortby';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { ButtonReset, Filters, PushRight, SearchContainer } from '../OrganisationUsersPage/styled';

import { DELETE_INVITATION } from './ProjectUsersPage.graphql';
import GroupTable from './Tables/GroupTable/GroupTable';
import UserTable from './Tables/UserTable/UserTable';
import UserTypeTable from './Tables/UserTypeTable/UserTypeTable';

import Button from '../../components/Buttons/Button';
import DropDownSelect from '../../components/DropDownSelect/DropDownSelect';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import ProjectPublicUserSideBar from '../../components/RightNavBar/ProjectPublicUserSideBar';
import ProjectUserGroupSideBar from '../../components/RightNavBar/ProjectUserGroupSideBar';
import ProjectUserTypeSideBar from '../../components/RightNavBar/ProjectUserTypeSideBar';
import SearchInput from '../../components/SearchInput/SearchInput';
import SideTabs, { TabItem } from '../../components/SideTabs';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import NoDataAlerts from '../../components/UI/NoDataComp/NoDataAlert';
import PopUpMsg from '../../components/UI/PopUp/PopUpMsg';
import UserTypeCount from '../../components/UserTypeCount/UserTypeCount';
import { NetfrontServices } from '../../constants/NetfrontServices';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useApolloClient } from '../../middleware/apollo/useApolloClient';
import GetGroups from '../../middleware/Group/getAllGroupsRequest';
import GetPendingUsers from '../../middleware/UsersManagement/getGroupsInvitationsWithinProject';
import GetGroupUsers from '../../middleware/UsersManagement/getGroupsMemberWithinProject';
import ResendInvitation from '../../middleware/UsersManagement/resendInvitation';
import GetUserTypes from '../../middleware/UserType/getAllUserTypesRequest';
import { getBreadCrumbPath } from '../../utils/utils';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const PUBLIC_TAB = 0;
const GROUP_TAB = 1;
const USER_TYPES_TAB = 2;
const UNASSIGNED = 'Unassigned';

const SIDE_TABS = [
  {
    id: PUBLIC_TAB,
    label: 'Public',
  },
  {
    id: GROUP_TAB,
    label: 'Groups',
  },
  {
    id: USER_TYPES_TAB,
    label: 'User types',
  },
];

const USER_STATUSES = [
  {
    id: 0,
    label: 'Inactive',
  },
  {
    id: 1,
    label: 'Pending',
  },
];

const ProjectUsersPage = (props) => {
  const { match } = props;

  const {
    project: { services },
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const client = useRef(useApolloClient('gelada')).current;

  const [selectedTab, setSelectedTab] = useState(0);
  const [groupLoaded, setGroupLoaded] = useState(false);
  const [typeLoaded, setTypeLoaded] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [pendingUserLoaded, setPendingUserLoaded] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [groupFilter, setGroupFilter] = useState('Groups');
  const [selectedItem, setSelectedItem] = useState({});
  const [resendRequest, setResendRequest] = useState(false);
  const [invitationId, setInvitationId] = useState(null);
  const [message, setMessage] = useState('');
  const [groupStatus, setGroupStatus] = useState('Active');
  const [filterGroupDropDown, setFilterGroupDropDown] = useState('');
  const [filterText, setFilterText] = useState('');
  const [userStatusFilter, setUserStatusFilter] = useState('Active');
  const [groupTypeFilter, setGroupTypeFilter] = useState('Group types');
  const [userTypeFilter, setUserTypeFilter] = useState('User types');
  const [pendingInvitationId, setPendingInvitationId] = useState(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(undefined);

  const [deletePendingInvitation, { loading: isLoadingDeletePendingInvitation }] = useMutation(DELETE_INVITATION, {
    client,
    onCompleted: () => {
      const deletedUserList = filteredUsers.filter((user) => user.id !== pendingInvitationId);
      setFilteredUsers(deletedUserList);
    },
  });

  const handleClosePanel = (type, data) => {
    setSelectedItem({});
    setIsPanelOpen(false);

    if (selectedTab === PUBLIC_TAB) {
      if (type === 'create') {
        setAllUsers((oldValue) => [...oldValue, data]);
        setFilteredUsers((oldValue) => [...oldValue, data]);
      }

      if (type === 'update') {
        const updatedUsers = [...allUsers].map((user) => {
          const { id } = user || {};
          return data.id === id ? data : user;
        });

        setAllUsers(updatedUsers);

        const updatedFilteredUsers = [...updatedUsers].filter((user) => {
          const { status = '' } = user;
          return status.toUpperCase() === userStatusFilter.toUpperCase();
        });
        setFilteredUsers(sortby(updatedFilteredUsers, ['group.name']));
      }

      if (type === 'delete') {
        const tmpUser = [...allUsers].filter((user) => user.id !== data.id);

        setAllUsers(tmpUser);
        setFilteredUsers(tmpUser);
      }
    }

    if (selectedTab === GROUP_TAB) {
      if (type === 'create') {
        setGroups([...groups, data]);
        setFilteredGroups([...groups, data]);
      }

      if (type === 'update') {
        const tmpGroup = groups;
        const i = tmpGroup.findIndex((group) => group.id === data.id);
        tmpGroup.splice(i, 1, data);
        setGroups(tmpGroup);
        setFilteredGroups(tmpGroup);
      }

      if (type === 'approve') {
        setGroups(data);
        setFilteredGroups(data);
      }

      if (type === 'update group') {
        const updatedGroups = filteredGroups.map((group) => (group.id === data.id ? data : group));
        setGroups(updatedGroups);
        setFilteredGroups(updatedGroups);
      }
    }

    if (selectedTab === USER_TYPES_TAB) {
      if (type === 'create') {
        setUserTypes([...userTypes, data]);
        setFilteredTypes([...userTypes, data]);
      }

      if (type === 'update') {
        const tmpTypes = userTypes;
        const i = tmpTypes.findIndex((t) => t.id === data.id);
        tmpTypes.splice(i, 1, data);
        setUserTypes(tmpTypes);
        setFilteredTypes(tmpTypes);
      }
    }
  };

  const handleConfirmDeletePendingInvitation = () => {
    deletePendingInvitation({
      variables: {
        invitationId: pendingInvitationId,
      },
    });

    setIsDeleteModalOpen(false);
  };

  const handleDeletePendingInvitationRequest = (userInvitationId) => {
    const { firstName, invitedUserEmail, lastName } = filteredUsers.find((user) => user.id === userInvitationId);

    const userFullName = [firstName, lastName].join(' ').trim();

    setDialogMessage(`Do you really want to delete the invitation for ${userFullName || invitedUserEmail}?`);
    setIsDeleteModalOpen(true);
    setPendingInvitationId(userInvitationId);
  };

  const handleEditButton = (item) => {
    setIsPanelOpen(true);
    setSelectedItem(item);
  };

  const handleMsgDisappear = () => {
    setTimeout(() => {
      setMessage('');
    }, 500);
  };

  const handleGetGroupsError = () => {
    setGroupLoaded(true);
  };

  const handleGetGroupsSuccess = (data) => {
    setGroupLoaded(true);
    setGroups(data);
    setFilteredGroups(data);
  };

  const handleGetPendingUsersError = () => {
    setPendingUserLoaded(true);
  };

  const handleGetPendingUsersSuccess = (data) => {
    if (data.length) {
      const pendingUsers = data.map((pendingUser) => ({
        ...pendingUser,
        status: 'PENDING',
      }));

      setAllUsers((prevAllUsers) => [...prevAllUsers, ...pendingUsers]);
    }

    setPendingUserLoaded(true);
  };

  const handleGetTypesError = () => {
    setTypeLoaded(true);
  };

  const handleGetTypesSuccess = (data) => {
    setFilteredTypes(data);
    setTypeLoaded(true);
    setUserTypes(data);
  };

  const handleGetUsersError = () => {
    setUserLoaded(true);
  };

  const handleGetUsersSuccess = (data) => {
    if (data.length) {
      const groupUsers = data.map((groupUser) => ({
        ...groupUser,
        status: groupUser.user.status,
      }));

      const combinedUsers = [...allUsers, ...groupUsers];

      setFilteredUsers(combinedUsers.filter((item) => item.status === 'ACTIVE'));
      setAllUsers(combinedUsers);
    }

    setUserLoaded(true);
  };

  const handleGroupFilter = (value) => {
    setGroupFilter(value);
  };

  const handleResendError = () => {
    setResendRequest(false);
  };

  const handleResendSuccess = (data) => {
    setMessage(`Resend the invitation to ${data.firstName} (${data.invitedUserEmail})`);
    setResendRequest(false);
  };

  const handleResend = (user) => {
    setInvitationId(user.id);
    setResendRequest(true);
  };

  const handleSearchFilter = (event) => {
    const {
      target: { value },
    } = event;

    setFilterText(value);
  };

  const handleSelectedTab = (id) => {
    setSelectedTab(id);
  };

  const handleStatusFilter = (value) => {
    setUserStatusFilter(value);
  };

  const handleUserTypeFilter = (value) => {
    setUserTypeFilter(value);
  };

  useEffect(() => {
    const loweredSearchValue = filterText.toLowerCase();

    if (selectedTab === PUBLIC_TAB) {
      const userStatusFilterUpper = userStatusFilter.toUpperCase();
      const orgColumns = ['firstname', 'lastname', 'invitedUserEmail'];
      const credentialColumn = ['email', 'phoneNumber'];
      const pendingColumns = ['firstName', 'lastName', 'invitedUserEmail'];

      if (services?.includes(NetfrontServices.BONOBO)) {
        orgColumns.push('communityName');
      }

      const filteredOrg = allUsers
        .filter((item) => item.status === userStatusFilterUpper)
        .filter((item) => {
          if (userTypeFilter !== 'User types' && userTypeFilter !== UNASSIGNED) {
            if (!item.userType || item.userType.name !== userTypeFilter) {
              return false;
            }
          }

          if (userTypeFilter === UNASSIGNED) {
            if (item.userType) {
              return false;
            }
          }

          if (groupFilter !== 'Groups') {
            if (!item.group || item.group.name !== groupFilter) {
              return false;
            }
          }

          if (item.status !== 'PENDING') {
            return (
              orgColumns.some((key) => (item.user[key] || '').toLowerCase().includes(loweredSearchValue)) ||
              credentialColumn.some((key) => (item.user.credential?.[key] || '').toLowerCase().includes(loweredSearchValue))
            );
          }

          return pendingColumns.some((key) => (item[key] || '').toLowerCase().includes(loweredSearchValue));
        });

      setFilteredUsers(filteredOrg);
    } else if (selectedTab === GROUP_TAB) {
      const groupSearchList = groups.map((item) => ({
        ...item,
        smartCodesList: item.smartCodes?.map(({ code }) => code).join(', '),
      }));

      setFilteredGroups(
        groupSearchList
          .filter(
            (r) =>
              r.status.toLowerCase() === groupStatus.toLowerCase() &&
              (r.name.toLowerCase().includes(loweredSearchValue) ||
                r.groupType?.name.toLowerCase().includes(loweredSearchValue) ||
                r.smartCodesList?.toLowerCase().includes(loweredSearchValue) ||
                r.customFields?.some((customField) => customField.isSearchable && customField.text.includes(loweredSearchValue))),
          )
          .filter((item) => {
            if (groupTypeFilter !== 'Group types') {
              if (!item.groupType || item.groupType.name !== groupTypeFilter) {
                return false;
              }
            }

            return item;
          }),
      );
    } else if (selectedTab === USER_TYPES_TAB) {
      setFilteredTypes(userTypes.filter((r) => r.name.toLowerCase().includes(loweredSearchValue)));
    }
  }, [userStatusFilter, groupFilter, filterText, userTypeFilter, userStatusFilter, selectedTab, groupStatus, groupTypeFilter]);

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'User management'), ...getBreadCrumbPath(pathname, 0, 'Public users')];

  const groupTypes = groups.reduce((types, group) => {
    const { groupType } = group;

    if (groupType && !types.some((t) => t.id === groupType.id)) {
      const { id, name } = groupType;
      types.push({
        id,
        name,
      });
    }

    return types;
  }, []);

  const userTypesCount = allUsers?.reduce((types, user) => {
    if (user.userType && !types.some((t) => t.id === user?.userType?.id)) {
      const { id, name: label } = user.userType;
      types.push({
        id,
        label,
      });
    }

    return types;
  }, []);

  const toolbar = (
    <Filters>
      <SearchContainer>
        <SearchInput
          onChange={(e) => {
            handleSearchFilter(e);
          }}
        />
      </SearchContainer>
      {selectedTab === GROUP_TAB && (
        <>
          <SearchContainer>
            <DropDownSelect label="status" name="groupStatus" value={groupStatus}>
              <ul>
                <li key="all1">
                  <ButtonReset type="button" onClick={() => setGroupStatus('Active')}>
                    Active
                  </ButtonReset>
                </li>
                <li key="all1">
                  <ButtonReset type="button" onClick={() => setGroupStatus('Inactive')}>
                    Inactive
                  </ButtonReset>
                </li>
                <li key="all1">
                  <ButtonReset type="button" onClick={() => setGroupStatus('Request')}>
                    Request
                  </ButtonReset>
                </li>
              </ul>
            </DropDownSelect>
          </SearchContainer>
          {groupTypes && (
            <SearchContainer>
              <DropDownSelect label="Group type" name="groupType" value={groupTypeFilter}>
                <ul>
                  <li key="all1">
                    <ButtonReset type="button" onClick={() => setGroupTypeFilter('Group types')}>
                      Group types
                    </ButtonReset>
                  </li>
                  {groupTypes.map((groupType) => (
                    <li key={groupType.id}>
                      <ButtonReset type="button" onClick={() => setGroupTypeFilter(groupType.name)}>
                        <span>{groupType.name}</span>
                      </ButtonReset>
                    </li>
                  ))}
                </ul>
              </DropDownSelect>
            </SearchContainer>
          )}
        </>
      )}

      {selectedTab === PUBLIC_TAB && (
        <>
          <SearchContainer>
            <DropDownSelect label="groups" name="groups" value={groupFilter || 'Groups'} onClose={() => setFilterGroupDropDown('')}>
              <ul>
                <li disable-click="true">
                  <SearchInput
                    additionalAttributes="disable-click"
                    onChange={(e) => {
                      setFilterGroupDropDown(e.target.value.toLowerCase());
                    }}
                  />
                </li>
                <li key="all1">
                  <ButtonReset type="button" onClick={() => handleGroupFilter('Groups')}>
                    Groups
                  </ButtonReset>
                </li>
                {groups
                  .filter((r) => r.name.toLowerCase().includes(filterGroupDropDown))
                  .map((group) => (
                    <li key={group.id}>
                      <ButtonReset type="button" onClick={() => handleGroupFilter(group.name)}>
                        <span>{group.name}</span>
                      </ButtonReset>
                    </li>
                  ))}
              </ul>
            </DropDownSelect>
          </SearchContainer>
          {userTypesCount && (
            <SearchContainer>
              <DropDownSelect label="User type" name="usertype" value={userTypeFilter}>
                <ul>
                  <li key="all1">
                    <ButtonReset type="button" onClick={() => handleUserTypeFilter('User types')}>
                      User types
                    </ButtonReset>
                  </li>
                  <li key="li-unassigned-ut">
                    <ButtonReset type="button" onClick={() => handleUserTypeFilter(UNASSIGNED)}>
                      <span>Unassigned</span>
                    </ButtonReset>
                  </li>
                  {userTypesCount.map((userType) => (
                    <li key={userType.id}>
                      <ButtonReset type="button" onClick={() => handleUserTypeFilter(userType.label)}>
                        <span>{userType.label}</span>
                      </ButtonReset>
                    </li>
                  ))}
                </ul>
              </DropDownSelect>
            </SearchContainer>
          )}
          <SearchContainer>
            <DropDownSelect label="status" name="status" value={userStatusFilter || 'Active'}>
              <ul>
                <li key="all1">
                  <ButtonReset type="button" onClick={() => handleStatusFilter('Active')}>
                    Active
                  </ButtonReset>
                </li>
                {USER_STATUSES.map((status) => (
                  <li key={status.id}>
                    <ButtonReset type="button" onClick={() => handleStatusFilter(status.label)}>
                      <span>{status.label}</span>
                    </ButtonReset>
                  </li>
                ))}
              </ul>
            </DropDownSelect>
          </SearchContainer>
        </>
      )}

      <PushRight>
        <Button
          onClick={() => {
            setIsPanelOpen(true);
          }}
        >
          {selectedTab === PUBLIC_TAB && 'Invite members'}
          {selectedTab === GROUP_TAB && 'New group'}
          {selectedTab === USER_TYPES_TAB && 'New user type'}
        </Button>
      </PushRight>
    </Filters>
  );

  const handleDelete = (type) => {
    if (type === 'GROUP') {
      const newFilteredGroups = groups.filter(
        ({ id, __typename }) =>
          (id !== selectedItem.id && __typename === 'GroupGraphType') || __typename === 'GroupCreationRequestGraphType',
      );

      setFilteredGroups(newFilteredGroups);
    }

    if (type === 'REQUEST') {
      const newFilteredGroups = groups.filter(
        ({ id, __typename }) =>
          (id !== selectedItem.id && __typename === 'GroupCreationRequestGraphType') || __typename === 'GroupGraphType',
      );

      setFilteredGroups(newFilteredGroups);
    }

    setIsPanelOpen(false);
  };

  const groupTable = <GroupTable data={filteredGroups} onEdit={handleEditButton} />;

  const userTable = (
    <UserTable
      data={filteredUsers}
      status={userStatusFilter}
      onDeletePendingInvitation={handleDeletePendingInvitationRequest}
      onEdit={handleEditButton}
      onResend={handleResend}
    />
  );

  const userTypesTable = <UserTypeTable data={filteredTypes} onEdit={handleEditButton} />;

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage project team members"
        isLoaderVisible={isLoadingDeletePendingInvitation}
        pageDescription="Manage project team members "
        pageTitle="Project team page"
        params={match.params}
      >
        <Dialog
          isHideCancel={false}
          isOpen={isDeleteModalOpen}
          title="Delete invitation"
          type="warning"
          onConfirm={handleConfirmDeletePendingInvitation}
          onRequestClose={() => setIsDeleteModalOpen(false)}
        >
          {dialogMessage}
        </Dialog>
        <ProjectNavBar title="Project team" />

        {isPanelOpen && (
          <>
            {selectedTab === PUBLIC_TAB && selectedItem && (
              <ProjectPublicUserSideBar groups={groups} membership={selectedItem} userTypes={userTypes} onCancel={handleClosePanel} />
            )}
            {selectedTab === GROUP_TAB && selectedItem && (
              <ProjectUserGroupSideBar group={selectedItem} onCancel={handleClosePanel} onDelete={handleDelete} />
            )}
            {selectedTab === USER_TYPES_TAB && selectedItem && <ProjectUserTypeSideBar type={selectedItem} onCancel={handleClosePanel} />}
            <Backdrop isClicked={handleClosePanel} />
          </>
        )}

        {selectedTab === PUBLIC_TAB && (
          <ul className="flex padding-0">
            {userTypesCount.map((userType) => (
              <li key={userType.id} className="li-hide-marker">
                <UserTypeCount count={filteredUsers.filter((r) => r.userType?.id === userType.id).length} {...userType} />
              </li>
            ))}
            <li key="usertype__not-assigned" className="li-hide-marker">
              <UserTypeCount count={filteredUsers.filter((r) => !r.userType).length} label="Unassigned" />
            </li>
          </ul>
        )}

        {selectedTab === GROUP_TAB && (
          <ul className="flex padding-0">
            <div className="flex">
              <p className="count-container">{filteredGroups.length}</p>
              <p className="label-p">Groups</p>
            </div>
          </ul>
        )}

        {selectedTab === USER_TYPES_TAB && (
          <ul className="flex padding-0">
            <div className="flex">
              <p className="count-container">{filteredTypes.length}</p>
              <p className="label-p">User types</p>
            </div>
          </ul>
        )}

        {toolbar}

        <div style={{ position: 'relative' }}>
          <SideTabs>
            {SIDE_TABS.map((item) => (
              <TabItem key={item.id} id={item.id} isSelectd={item.id === selectedTab} onClick={() => handleSelectedTab(item.id)}>
                {item.label}
              </TabItem>
            ))}
          </SideTabs>
          {selectedTab === PUBLIC_TAB && (allUsers.length > 0 || filteredUsers.length > 0 ? userTable : <NoDataAlerts />)}
          {selectedTab === GROUP_TAB && (filteredGroups.length > 0 ? groupTable : <NoDataAlerts />)}
          {selectedTab === USER_TYPES_TAB && (filteredTypes.length > 0 ? userTypesTable : <NoDataAlerts />)}
        </div>
        {message !== '' && <PopUpMsg message={message} msgDisappear={handleMsgDisappear} />}
      </PageWrapper>

      {!userLoaded && (
        <GetGroupUsers projectId={match.params.projectId} onError={handleGetUsersError} onSuccessResult={handleGetUsersSuccess} />
      )}

      {!pendingUserLoaded && (
        <GetPendingUsers
          projectId={match.params.projectId}
          onError={handleGetPendingUsersError}
          onSuccessResult={handleGetPendingUsersSuccess}
        />
      )}

      {!groupLoaded && (
        <GetGroups projectId={match.params.projectId} onError={handleGetGroupsError} onSuccessResult={handleGetGroupsSuccess} />
      )}

      {!typeLoaded && (
        <GetUserTypes projectId={match.params.projectId} onError={handleGetTypesError} onSuccessResult={handleGetTypesSuccess} />
      )}

      {resendRequest && <ResendInvitation invitationId={invitationId} onError={handleResendError} onSuccessResult={handleResendSuccess} />}
    </>
  );
};

ProjectUsersPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
    }),
  }).isRequired,
};

export default ProjectUsersPage;
