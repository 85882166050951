import React, { useEffect, useState } from 'react';

import { Spacing, Select, Input } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { getAdditionalParameterOptions, getAdditionalParameters } from './AdditionalActionParametersSelector.helpers';

const AdditionalActionParametersSelector = ({ selectedActionType, onSelectParameters, actionDetails, selectedParamsId }) => {
  const [options, setOptions] = useState();
  const [actionDetailsLabel, setActionDetailsLabel] = useState('');
  const [additionalParametersValue, setAdditionalParametersValue] = useState();

  const handleSelectAdditionalParameters = (value) => {
    const params = getAdditionalParameters(value);
    onSelectParameters({
      params,
      value,
    });
    setAdditionalParametersValue(value);
  };

  useEffect(() => {
    if (!selectedActionType) return;
    setOptions(getAdditionalParameterOptions(selectedActionType));
  }, [selectedActionType]);

  useEffect(() => {
    setAdditionalParametersValue(selectedParamsId);
  }, [selectedParamsId]);

  useEffect(() => {
    if (!actionDetails) return;

    const { __typename: ActionType, type } = actionDetails;

    if (ActionType === 'QuestionActionTriggerContentEventType') {
      setActionDetailsLabel(type);
    }
  }, [actionDetails]);

  return (
    <>
      {actionDetails ? (
        <Spacing size="2x-large">
          <Input
            labelText="Additional Action details"
            name="additional_action_details"
            value={actionDetailsLabel}
            isDisabled
            onChange={() => {}}
          />
        </Spacing>
      ) : (
        <Spacing size="2x-large">
          <Select
            labelText="Additional Action details"
            name="additional_parameters_select"
            options={
              options
                ?.filter(({ isHidden = false }) => !isHidden)
                .map(({ id: optionId, value, name }) => ({
                  id: optionId,
                  value,
                  name,
                })) ?? []
            }
            value={additionalParametersValue}
            onChange={({ target: { value } }) => handleSelectAdditionalParameters(value)}
          />
        </Spacing>
      )}
    </>
  );
};

AdditionalActionParametersSelector.propTypes = {
  onSelectParameters: PropTypes.func.isRequired,
  selectedActionType: PropTypes.string.isRequired,
  actionDetails: PropTypes.shape({}),
  selectedParamsId: PropTypes.string,
};

export { AdditionalActionParametersSelector };
