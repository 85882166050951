/* eslint-disable no-extend-native */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dropdown from '../UI/Dropdown/Dropdown';
import CheckBox from '../UI/Input/CheckBox';
import RadioButton from '../UI/Input/RadioButton';

import { responseTypeShortConst, responseTypeLongConst } from './responseSetTypeConstant';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

const Question = styled.div`
  display: flex;
  justify-content: start;
`;

const ResContainer = styled.div`
  width: 100%;
`;

const ColumnLayout = styled.span`
  display: flex;
  flex-direction: column;
`;

// eslint-disable-next-line func-names
Array.prototype.remove = function() {
  let what;
  // eslint-disable-next-line prefer-rest-params
  const a = arguments;
  let L = a.length;
  let ax;
  while (L && this.length) {
    // eslint-disable-next-line no-plusplus
    what = a[--L];
    // eslint-disable-next-line no-cond-assign
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

const ResponsesInput = props => {
  const { availableResponses, resType, itemId, isChanged, submit, answerId, answerArr } = props;
  const [checkboxAnswer, setCheckboxAnswer] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const handleRadio = id => {
    isChanged(id);
  };

  const handleSubmit = () => {
    submit();
  };

  if (answerArr.length > 0 && firstLoad) {
    const loadedAns = checkboxAnswer;
    answerArr.forEach(item => {
      loadedAns.push(item.questionResponseCheckboxId);
    });
    setCheckboxAnswer(loadedAns);
    setFirstLoad(false);
  }

  const handleCheckbox = (id, check) => {
    const tmp = checkboxAnswer;
    if (check) {
      tmp.push(id);
      setCheckboxAnswer(tmp);
    } else {
      tmp.remove(id);
      setCheckboxAnswer(tmp);
    }
    isChanged(tmp);
  };

  const handleDropdown = id => {
    isChanged(id);
  };

  const ResPart = type => {
    switch (type) {
      case responseTypeShortConst.RADIO:
      case responseTypeLongConst.RADIO:
        return availableResponses && availableResponses.length
          ? availableResponses.map((item, index) => (
              <RadioButton
                htmlFor={`${item.id || index}_${itemId}`}
                isChanged={() => handleRadio(item.id)}
                isChecked={answerId === item.id}
                key={`radio${item.id || index}_${itemId}`}
                name={`radiogroup_${itemId}`}
              >
                <ColumnLayout>
                  {item.asset && (
                    <img
                      alt={item.asset.alt || item.label || ''}
                      src={getSignedUrl(item.asset.s3Key, item.asset.contentType)}
                      style={{ width: '10rem', height: 'auto' }}
                    />
                  )}
                  {item.label}
                </ColumnLayout>
              </RadioButton>
            ))
          : null;
      case responseTypeShortConst.CHECKBOX:
      case responseTypeLongConst.CHECKBOX:
        return availableResponses && availableResponses.length
          ? availableResponses.map((item, index) => {
              let tmpCheck = false;
              answerArr.forEach(i => {
                if (i.questionResponseCheckboxId === item.id) tmpCheck = true;
              });
              return (
                <CheckBox
                  htmlFor={`${item.id || index}_${itemId}`}
                  isChanged={check => handleCheckbox(item.id, check)}
                  isChecked={tmpCheck}
                  key={`checkbox${item.id || index}_${itemId}`}
                  name={item.label}
                  value={item.value}
                >
                  <ColumnLayout>
                    {item.asset && (
                      <img
                        alt={item.asset.alt || item.label || ''}
                        src={getSignedUrl(item.asset.s3Key, item.asset.contentType)}
                        style={{ width: '10rem', height: 'auto' }}
                      />
                    )}
                    {item.label}
                  </ColumnLayout>
                </CheckBox>
              );
            })
          : null;
      case responseTypeShortConst.DROPDOWNLIST:
      case responseTypeLongConst.DROPDOWNLIST:
        return (
          <Dropdown
            availableResponses={availableResponses}
            name={`dropdown-${itemId}`}
            selectedValue={answerId}
            onChangeHandler={handleDropdown}
          />
        );
      default:
        return null;
    }
  };

  const Response = ResPart(resType);

  return (
    <Question onMouseLeave={handleSubmit}>
      <ResContainer>{Response}</ResContainer>
    </Question>
  );
};

ResponsesInput.propTypes = {
  // getResponse: PropTypes.func.isRequired,
  availableResponses: PropTypes.instanceOf(Array).isRequired,
  resType: PropTypes.string.isRequired,
  answerArr: PropTypes.arrayOf(
    PropTypes.shape({
      questionResponseCheckboxId: PropTypes.number,
    }),
  ),
  answerId: PropTypes.number,
  isChanged: PropTypes.func,
  itemId: PropTypes.number,
  submit: PropTypes.func,
};

ResponsesInput.defaultProps = {
  answerArr: [],
  answerId: null,
  isChanged: () => {},
  itemId: -1,
  submit: () => {},
};

export default ResponsesInput;
