import React from 'react';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import client from '../client';
import QueryRequest from '../QueryRequest';
import { FRAGMENT_RESPONSE_SET } from '../QuestionConfiguration/Fragments/ResponseSetFragment';

import { TextFragment } from './Fragments/Collection';

export const GET_SNIPPET_DETAILS = gql`
  query getSnippetDetailRequest($contentSnippetId: Int!) {
    contentSnippet {
      getContentSnippet(contentSnippetId: $contentSnippetId) {
        id
        backgroundColor
        borderColor
        containerId
        sort
        visible
        __typename
        type: __typename
        ...text
        cssStyle {
          id
          name
        }
        contentEventId
        contentEvent {
          id
          animation
          animationDirection
          entityId
          event
          eventDirection
          eventTitle
          infinite
          millisecondDelay
          millisecondSpeed
          assetId
          asset {
            assetId
            contentType
            s3Key
            presignedUrl
          }
          backgroundColor
          borderColor
          titleColor
          style
          isDefaultOpen
          triggerStyle
          iconHorizontalAlignment
        }
      }
    }
  }
  ${TextFragment}
`;

export const GET_QUESTION_DETAILS = gql`
  query getQuestionDetailRequest($contentSnippetId: Int!, $contentGroupId: Int!) {
    contentSnippet {
      getContentSnippet(contentSnippetId: $contentSnippetId) {
        id
        ... on ContentSnippetQuestionType {
          identifier
          configuration {
            id
            messages {
              correct
              example
              incomplete
              incorrect
            }
            __typename
            type: __typename
            ... on Checkbox {
              style
              responseSet {
                ...ResponseSetFragment
              }
            }
            ... on Radio {
              style
              responseSet {
                ...ResponseSetFragment
              }
            }
            ... on DropDownList {
              responseSet {
                ...ResponseSetFragment
              }
            }
          }
          __typename
          type: __typename
        }
      }
    }
    contentGroup {
      getCorrectResponses(snippetId: $contentSnippetId, contentGroupId: $contentGroupId) {
        ... on CorrectResponseCheckboxType {
          correctResponses {
            correctResponseCheckboxId
          }
        }
        ... on CorrectResponseRadioType {
          value: questionResponseRadioId
        }
        ... on CorrectResponseDropDownListType {
          value: questionResponseDropDownListId
        }
      }
    }
  }
  ${FRAGMENT_RESPONSE_SET}
`;

export function useSnippetDetailQuery({ contentSnippetId, onError, onCompleted }) {
  return useQuery(GET_SNIPPET_DETAILS, {
    fetchPolicy: 'cache-and-network',
    client,
    onError,
    onCompleted,
    variables: {
      contentSnippetId,
    },
  });
}

export function useQuestionDetailQuery({ contentSnippetId, contentGroupId, onError, onCompleted }) {
  return useLazyQuery(GET_QUESTION_DETAILS, {
    fetchPolicy: 'cache-and-network',
    client,
    onError,
    onCompleted,
    variables: {
      contentSnippetId,
      contentGroupId,
    },
  });
}

const getContentSnippet = ({ contentSnippetId, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_SNIPPET_DETAILS} variables={{ contentSnippetId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.contentSnippet.getContentSnippet}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getContentSnippet.propTypes = {
  contentSnippetId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getContentSnippet;
