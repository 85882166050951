import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  border: ${props => props.borderColor || 'solid 0.8px #e4e4e3'};
  border-radius: 0.3rem;
  display: block;
  margin-bottom: 1rem;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const Select = styled.select`
  border: none;
  border-radius: 0.3rem;
  font-size: 1em;
  outline: none;
  padding: 0.6rem 0.6rem;
  width: 100%;
`;

export const Option = styled.option`
  line-height: 1rem;
`;
