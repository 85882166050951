import { gql } from '@apollo/client';

export const SEARCH_CONTENT_GROUPS_QUERY = gql`
  query searchContentGroups($projectId: String!, $type: [EContentGroupType], $shouldIncludeAssetPresignedUrl: Boolean = false) {
    contentGroup {
      searchContentGroups(projectId: $projectId, type: $type) {
        id
        sort
        status
        title
        type
        contentPages {
          id
          seoDescription
          status
          title
          url
          asset {
            contentType
            s3Key
            presignedUrl @include(if: $shouldIncludeAssetPresignedUrl)
          }
          tags {
            id
            name
            sort
          }
          tags {
            id
            name
            projectId
            sort
          }
        }
      }
    }
  }
`;
