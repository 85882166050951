import React from 'react';

import PropTypes from 'prop-types';

import { Tabs } from '../Tabs/Tabs';

import './SideBar.css';

const SideBar = ({ isOpen, onCloseHandler, onTabChangeHandler, selectedTabId, tabViews }) => {
  const overlayStyle = isOpen ? 'c-overlay-is-active' : 'c-overlay-is-active c-overlay-hide';
  const sideBarStyle = isOpen ? 'c-sidebar-is-active' : 'c-sidebar-is-active c-sidebar-hide';

  return (
    <>
      <div className={overlayStyle} role="presentation" onClick={onCloseHandler} />
      <div className={sideBarStyle}>
        <Tabs items={tabViews} selectedTabId={selectedTabId} isInSideBar onTabChangeHandler={onTabChangeHandler} />
      </div>
    </>
  );
};

SideBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  selectedTabId: PropTypes.number.isRequired,
  onTabChangeHandler: PropTypes.func,
  tabViews: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node,
      label: PropTypes.string,
    }).isRequired,
  ),
};

export { SideBar };
