import React, { useState, useEffect, useContext } from 'react';

import { Spacing, Input, NavigationButton, Select } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { UpsertResponse } from '../UpsertResponse';

import ButtonGroup from '../../../../../../legacy/components/ButtonGroup/ButtonGroup';
import {
  AddNewResponse,
  InfoIcon,
  ResponseSet,
  Search,
  SearchSuggetionsBox,
  SearchSuggetionsRow,
} from '../../../../../../legacy/components/Form/styled';
import Preloader from '../../../../../../legacy/components/Preloader/Preloader';
import AppContext from '../../../../../../legacy/context/AppContext';
import { useToast } from '../../../../../../legacy/hooks';
import { useGetResponseSetSuggestions } from '../../../../../hooks';
import {
  // useUpsertSummaryFeedbackConfigCheckbox,
  useUpsertSummaryFeedbackConfigRadio,
  useUpsertSummaryFeedbackConfigMultiLine,
  useUpsertSummaryFeedbackConfigSingleLine,
} from '../../../../../hooks/UpsertSummaryFeedbackConfig';
import { RESPONSE_TYPE } from '../../../../tabs/UpsertSummarySnippetFeedbackTab/UpsertSummarySnippetFeedbackTab.constants';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const UpsertFeedback = ({ onSave, onCancel, feedbackConfig, onDelete, snippetId }) => {
  const { project } = useContext(AppContext);
  const { handleToastError, handleToastSuccess } = useToast();

  const [label, setLabel] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [type, setType] = useState();
  const [isUpsertMode, setIsUpsertMode] = useState(false);

  // const [isSearchContentVisible, setSearchIsContentVisible] = useState(false);

  const [responseTitle, setResponseTitle] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const [responseSetId, setResponseSetId] = useState();

  const { handleGetResponseSetSuggestions, isLoading: isGetResponseSetSuggestionsLoading = false } = useGetResponseSetSuggestions({
    onCompleted: ({ searchResponseSet }) => {
      const suggestions = !responseSetId
        ? searchResponseSet.map(({ id, title }) => ({
            id,
            title,
          }))
        : [];
      setSearchSuggestions(suggestions);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  // NOTE: Commented out for now as we dont have a checkbox summary feedback api yet.
  // const { handleUpsertSummaryFeedbackConfigCheckbox, isLoading: isSummaryCheckboxLoading = false } = useUpsertSummaryFeedbackConfigCheckbox(
  //   {
  //     onCompleted: () => {
  //       onSave();
  //       handleToastSuccess({
  //         message: `Summary feedback successfully created`,
  //       });
  //     },
  //     onError: (error) => {
  //       handleToastError({
  //         error,
  //         shouldUseFriendlyErrorMessage: true,
  //       });
  //     },
  //   },
  // );

  const { handleUpsertSummaryFeedbackConfigRadio, isLoading: isSummaryRadioLoading = false } = useUpsertSummaryFeedbackConfigRadio({
    onCompleted: () => {
      onSave();
      handleToastSuccess({
        message: `Summary feedback successfully created`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpsertSummaryFeedbackConfigMultiLine, isLoading: isSummaryMultiLineLoading = false } =
    useUpsertSummaryFeedbackConfigMultiLine({
      onCompleted: () => {
        onSave();
        handleToastSuccess({
          message: `Summary feedback successfully created`,
        });
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleUpsertSummaryFeedbackConfigSingleLine, isLoading: isSummarySingleLineLoading = false } =
    useUpsertSummaryFeedbackConfigSingleLine({
      onCompleted: () => {
        onSave();
        handleToastSuccess({
          message: `Summary feedback successfully created`,
        });
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const saveMap = {
    // NOTE: Commented out for now as we dont have a checkbox summary feedback api yet.
    // CHECKBOX: handleUpsertSummaryFeedbackConfigCheckbox,
    RADIO: handleUpsertSummaryFeedbackConfigRadio,
    SINGLE_TEXT: handleUpsertSummaryFeedbackConfigSingleLine,
    MULTI_TEXT: handleUpsertSummaryFeedbackConfigMultiLine,
  };

  const handleSaveFeedback = () => {
    const typedSave = saveMap[type];

    typedSave({
      variables: {
        contentSnippetSummaryId: snippetId,
        questionResponseSetId: responseSetId,
        label: String(label),
      },
    });
  };

  const handleCloseResponse = () => {
    setIsUpsertMode(false);
  };

  const handleSaveResponse = ({ title, id }) => {
    const updatedSuggestions = [
      ...searchSuggestions,
      {
        title,
        id,
      },
    ];
    setResponseSetId(id);
    setResponseTitle(title);
    setSearchSuggestions(updatedSuggestions);
    handleCloseResponse();
  };

  const onSelectResponseSet = (id) => {
    setResponseSetId(id);
  };

  const onSuggestionClick = ({ title, id }) => {
    setResponseTitle(title);
    onSelectResponseSet(id);

    setSearchSuggestions([]);
  };

  const addResponse = () => {
    setResponseSetId();
    setIsUpsertMode(true);
  };

  const onSearchContentGroup = ({ target: { value } }) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setResponseSetId(null);
    setResponseTitle(value);
    setTypingTimeout(
      setTimeout(() => {
        handleGetResponseSetSuggestions({
          variables: {
            projectId: String(project.id),
            title: responseTitle,
            type: type ?? undefined,
          },
        });
      }, 300),
    );
  };

  // Old temporary logic end

  // New logic when switching to new ekardo & V3 netfront-ui
  // const handleSearchItemClick = (selectedId) => {
  //   const items = searchSuggestions
  //   .filter(({ id }) => id === selectedId)
  //   .flatMap(({ availableResponses }) =>
  //     availableResponses.map(({ label: responseLabel, value }) => ({
  //       label: responseLabel,
  //       scoreValue: Number.isNaN(value) ? 0 : value,
  //       value,
  //     })),
  //   );
  //   setResponseSetId(selectedId);
  //   setResponseSet(items);
  //   setSearchSuggestions([]);
  // };

  // const handleCustomButtonClick = () => {
  //   setIsUpsertMode(true);
  // };

  useEffect(() => {
    const { label: feedbackLabel, __typename: feedbackType, responseSetId: feedbackResponseSetId, responseSet } = feedbackConfig ?? {};

    const { title = '' } = responseSet ?? {};

    setLabel(feedbackLabel ?? '');
    setType(RESPONSE_TYPE[feedbackType] ?? undefined);
    setResponseSetId(feedbackResponseSetId);
    setResponseTitle(title ?? '');
  }, [feedbackConfig]);

  useEffect(() => {
    if (!['RADIO', 'CHECKBOX'].includes(type)) return;

    handleGetResponseSetSuggestions({
      variables: {
        projectId: String(project.id),
        title: responseTitle,
        type: String(type),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const isLoading = isSummarySingleLineLoading || isSummaryMultiLineLoading || isSummaryRadioLoading || isGetResponseSetSuggestionsLoading; // || isSummaryCheckboxLoading;

  return (
    <>
      {isUpsertMode ? (
        <UpsertResponse projectId={project.id} responseType={type} onCancel={handleCloseResponse} onSave={handleSaveResponse} />
      ) : (
        <Container>
          {isLoading && <Preloader />}
          <Spacing>
            <NavigationButton
              additionalClassNames="c-sidebar-navigation-button"
              direction="previous"
              iconId="id_enter_icon"
              rotationCssSuffix="180"
              text="Back to feedback"
              onClick={onCancel}
            />
          </Spacing>
          <Spacing>
            <Input
              labelText="Title"
              name="feedback_title"
              value={label}
              isRequired
              onChange={({ target: { value } }) => {
                setLabel(value);
                setIsSubmitDisabled(value === '' && type === '');
              }}
            />
          </Spacing>
          <Spacing size="2x-large">
            <Select
              id="id_question_type"
              labelText="Type"
              name="question_type"
              options={[
                {
                  id: 'SINGLE_TEXT',
                  value: 'SINGLE_TEXT',
                  name: 'Single line text',
                },
                {
                  id: 'MULTI_TEXT',
                  value: 'MULTI_TEXT',
                  name: 'Multi line text',
                },
                {
                  id: 'RADIO',
                  value: 'RADIO',
                  name: 'Single select',
                },
                // NOTE: Commented out for now as we dont have a checkbox summary feedback api yet.
                // {
                //   id: 'CHECKBOX',
                //   value: 'CHECKBOX',
                //   name: 'Multi select',
                // },
              ]}
              value={type}
              isRequired
              onChange={({ target: { value } }) => {
                setType(value);
                setResponseSetId(null);
                setIsSubmitDisabled(!value && label === '');
              }}
            />
          </Spacing>
          {['RADIO', 'CHECKBOX'].includes(type) && (
            <>
              {/* <SelectWithSearch
                customButtonIconId="id_add_circle_icon"
                customButtonText="Add new response set"
                isDisplaySearchContent={isSearchContentVisible}
                searchList={searchSuggestions.map((suggestion) => ({
                  id: suggestion.id,
                  label: suggestion.title,
                }))}
                isAvatarVisible
                onCustomButtonClick={handleCustomButtonClick}
                onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                onSearchItemClick={handleSearchItemClick}
              /> */}
              <ResponseSet>
                <Search
                  list="browsers"
                  name="browser"
                  placeholder="Search response type "
                  value={responseTitle}
                  onChange={onSearchContentGroup}
                />
                <SearchSuggetionsBox className="d-flex flex-column">
                  {searchSuggestions.map((item) => (
                    <SearchSuggetionsRow key={item.id} onClick={() => onSuggestionClick(item)}>
                      {item.title}
                    </SearchSuggetionsRow>
                  ))}
                </SearchSuggetionsBox>
                <AddNewResponse onClick={() => addResponse()}>
                  <InfoIcon>+</InfoIcon>
                  Add new {type === 'RADIO' ? 'single select' : null}
                  {type === 'CHECKBOX' ? 'multi select' : null}
                </AddNewResponse>
              </ResponseSet>
            </>
          )}
          {/* Switch ButtonGroup for TabsetButtons when switching to V3 */}
          <ButtonGroup
            isConfirmButtonDisabled={isSubmitDisabled}
            hasConfirmButton
            onCancel={onCancel}
            onDelete={feedbackConfig ? () => onDelete(feedbackConfig.id) : undefined}
            onSave={handleSaveFeedback}
          />
        </Container>
      )}
    </>
  );
};

UpsertFeedback.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  feedbackConfig: PropTypes.shape(),
};

export { UpsertFeedback };
