import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import SwitchButton from '../UI/Button/SwitchButton';
import Editor from '../UI/TextEditor/Editor';
import Tooltip from '../UI/Tooltip/Tooltips';

import { SnippetTypeConst } from './SnippetTypeConst';

import CreateTextContentSnippetRequest from '../../middleware/ContentSnippet/createTextContentSnippetRequest';
import UpdateTextContentSnippetRequest from '../../middleware/ContentSnippet/updateTextSnippetStyle';

export const StyledTextarea = styled(TextareaAutosize)`
  display: block;
  width: calc(100% - 1em);
  background: #fff;
  line-height: 1rem;
  border: 0.8px solid #e4e4e3;
  border-radius: 5px;
  margin: 0.5rem 0;
  padding: 0.8em;
  font-family: inherit;
  font-size: inherit;
  &:focus {
    outline: none;
    background: #fff;
    border: 2px solid lightgray;
  }
`;

export const SwitchBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
`;

export const ModeTitle = styled.div`
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextSnippet = forwardRef((props, ref) => {
  const {
    containerId,
    onCancel,
    onCreate,
    snippet,
    onUpdate,
    callGetText,
    getText,
    isSnippetInForm,
    onEditQuesSuccess = () => {},
    handleCloseEditMedia = () => {},
  } = props;

  const [text, setText] = useState(snippet ? snippet.text : null);
  const [isSourceEditMode, setIsSourceEditMode] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const handleChange = value => {
    setText(value);
  };

  const handleSourceChange = e => {
    setText(e.target.value);
  };

  if (callGetText) {
    getText(text);
  }

  const handleSaveButton = () => {
    setSendRequest(true);
  };

  const handleSnippetError = () => {
    setSendRequest(false);
  };

  const handleSnippetResponse = (id, sort) => {
    if (!isSnippetInForm) {
      if (snippet) {
        onUpdate(id, snippet.sort, SnippetTypeConst.TEXT, containerId, {
          text,
        });
      } else {
        onCreate(id, sort, SnippetTypeConst.TEXT, containerId, { text });
      }
    }
    handleCloseEditMedia();
    onEditQuesSuccess();
    setSendRequest(false);
  };

  useImperativeHandle(ref, () => ({
    handleSaveButton() {
      setSendRequest(true);
    },
  }));
  return (
    <>
      <div>
        <SwitchBox>
          <ModeTitle>
            Source view
            <Tooltip
              text="If adding inline style in advanced mode, inline style will be lost
            in basic mode."
            />
          </ModeTitle>
          <SwitchButton
            defaultChecked={false}
            id={1}
            name="mode"
            onChange={() => setIsSourceEditMode(isEdit => !isEdit)}
          />
        </SwitchBox>
        {isSourceEditMode ? (
          <StyledTextarea
            minRows={8}
            onChange={handleSourceChange}
            value={text}
          />
          ) : (
          <Editor getContent={handleChange} value={text} />

        )}

        {!isSnippetInForm && (
          <div className="text-right">
            <button
              className="button--black mt-4 mr-3"
              onClick={onCancel}
              type="button"
            >
              Cancel
            </button>
            <button
              className="button--blue mt-4"
              onClick={handleSaveButton}
              type="submit"
            >
              Save
            </button>
          </div>
        )}
      </div>
      {sendRequest && !snippet && (
        <CreateTextContentSnippetRequest
          containerId={containerId}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          text={text}
        />
      )}
      {sendRequest && snippet && (
        <UpdateTextContentSnippetRequest
          fontColor={snippet.fontColorSnippetText}
          linkColor={snippet.linkColor}
          listIconColor={snippet.listIconColor}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          snippetId={snippet.id}
          text={text}
        />
      )}
    </>
  );
});

TextSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  callGetText: PropTypes.bool,
  getText: PropTypes.func,
  handleCloseEditMedia: PropTypes.func,
  isSnippetInForm: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onEditQuesSuccess: PropTypes.func,
  onUpdate: PropTypes.func,
  snippet: PropTypes.shape(),
};

TextSnippet.defaultProps = {
  callGetText: false,
  getText: () => {},
  handleCloseEditMedia: () => {},
  isSnippetInForm: false,
  onCancel: () => {},
  onCreate: () => {},
  onEditQuesSuccess: () => {},
  onUpdate: () => {},
  snippet: null,
};

// export default forwardRef(TextSnippet);
export default TextSnippet;
