import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_CODE_CONTENT_SNIPPET } from './UseCreateCodeSnippet.graphql';

const useCreateCodeSnippet = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeCreateCodeSnippet, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? CREATE_CODE_CONTENT_SNIPPET,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { createContentSnippet: snippet },
        } = data;

        onCompleted({
          snippet,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateCodeSnippet = ({ locationId, containerId, isVisible }) => {
    executeCreateCodeSnippet({
      variables: {
        baseSnippet: {
          containerId,
          visible: isVisible,
          title: '',
        },
        codeSnippet: {
          locationId,
        },
      },
    });
  };

  return {
    handleCreateCodeSnippet,
    isLoading,
  };
};

export { useCreateCodeSnippet };
