import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import Preloader from '../../components/Preloader/Preloader';

export const GET_ENUM_VALUES = gql`
  query getEnumValues($enumValue: String!) {
    __type(name: $enumValue) {
      name
      enumValues {
        name
      }
    }
  }
`;

const getEnumValues = ({ enumValue, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_ENUM_VALUES}
    variables={{ enumValue }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Preloader />;
      if (error) {
        onError(error.networkError.result.messages);
        return <></>;
      }
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        onSuccessResult(data.__type.enumValues);
      }

      return <></>;
    }}
  </Query>
);

getEnumValues.propTypes = {
  enumValue: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getEnumValues;
