import React, { useState, useContext, useEffect, useRef } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import { useCookie } from '@netfront/common-library';
import PropTypes from 'prop-types';

import ValidationProcessor from '../ErrorMessage/ValidationProcessor';
import Preloader from '../Preloader/Preloader';
import Groups from '../SearchInput/SearchContentGroupings';
import ImageUploader from '../UI/AssetUploader/ImageUploader';
import SwitchButton from '../UI/Button/SwitchButton';
import DatePicker from '../UI/Input/DatePicker';
import Input from '../UI/Input/InputWhite';
import NumInput from '../UI/Input/NumberInput';
import PopUpMsg from '../UI/PopUp/PopUpMsg';
import SidebarTab from '../UI/Tab/SidebarTab';
import EditableTag from '../UI/Tag/EditableTag';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import {
  Container,
  Title,
  Item,
  EditBtn,
  Preview,
  Logo,
  AddNewTag,
  TagInput,
  Search,
  InfoIcon,
  SearchSuggetionsBox,
  SearchSuggetionsRow,
} from './styled';

import AppContext from '../../context/AppContext';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import client from '../../middleware/client';
import CreateContentGroupForm from '../../middleware/ContentGroup/createContentGroupForm';
import CreateContentGroupRequest from '../../middleware/ContentGroup/createContentGroupRequest';
import { SEARCH_CONTENT_GROUPINGS } from '../../middleware/ContentGroup/searchContentGroupings';
import UpdateContentGroupRequest from '../../middleware/ContentGroup/updateContentGroupRequest';
import UpdateContentGroupStatusRequest from '../../middleware/ContentGroup/updateContentGroupStatusRequest';
import AddTagInGroup from '../../middleware/Tag/addTagInContentGroup';
import AddTagInProject from '../../middleware/Tag/addTagInProject';
import RemoveTagInGroup from '../../middleware/Tag/removeTagInContentGroup';
import SearchTags from '../../middleware/Tag/searchTags';
import { isStringEmpty, urlGenerator } from '../../utils/utils';

const ContentGroupSideBar = (props) => {
  const { onCancel, onCreate, onDelete, onUpdate, contentGroup, type } = props;

  const { pathname } = useLocation();
  const { orgId, projectId } = useParams();
  const history = useHistory();

  const { getAccessTokenCookie } = useCookie();
  const { project } = useContext(AppContext);

  const [group, setGroup] = useState(contentGroup);
  const [sendRequest, setSendRequest] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [editLogo, setEditLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [addToProject, setAddToProject] = useState(false);
  const [addToGroup, setAddToGroup] = useState(false);
  const [removeToGroup, setRemoveToGroup] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [searchedTags, setSearchedTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [tagName, setTagName] = useState('');
  const [loadTags, setLoadTags] = useState(false);
  const [message, setMessage] = useState('');

  const textInput = useRef(null);

  useEffect(() => {
    if (contentGroup.iconAsset && Object.keys(contentGroup.iconAsset).length > 0) {
      const tmpURL = getSignedUrl(contentGroup.iconAsset.s3Key, contentGroup.iconAsset.contentType);
      setLogoUrl(tmpURL);
    }
  }, [contentGroup]);

  useEffect(() => {
    if (Object.keys(contentGroup).length > 0 && type === 'resource') textInput.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagName]);

  const isEdit = Object.keys(contentGroup).length > 0;

  function validate() {
    const { title, description, url } = group;
    return ValidationProcessor({
      TitleEmpty: {
        validation: !isStringEmpty(title),
        errorMessage: 'Title must not be empty',
      },
      DescriptionEmpty: {
        validation: !isStringEmpty(description),
        errorMessage: 'Description must not be empty',
      },
      UrlEmpty: {
        validation: !isStringEmpty(url),
        errorMessage: 'Url must not be empty',
      },
    });
  }

  const handleMsgDisappear = () => {
    setTimeout(() => {
      setMessage('');
    }, 2000);
  };

  const handleInfoUpdate = (value, e) => {
    const { name } = e.target;
    let newInfo = {};
    if (name === 'title') {
      const tmpUrl = urlGenerator(value);
      newInfo = {
        ...group,
        [name]: value,
        url: tmpUrl,
      };
    } else if (name === 'contentType') {
      newInfo = {
        ...group,
        contentType: value ? 'PUBLIC' : 'PRIVATE',
      };
    } else {
      newInfo = {
        ...group,
        [name]: value,
      };
    }
    setGroup(newInfo);
  };

  const handleDateUpdate = (data) => {
    setGroup({
      ...group,
      [data.target.name]: data.target.value,
    });
  };

  const handleAddNewTag = () => {
    setAddToProject(true);
  };

  const getLogoData = (value) => {
    const tmpURL = getSignedUrl(value.s3Key, value.contentType);
    setEditLogo(false);
    setLogoUrl(tmpURL);
    setGroup({
      ...group,
      iconAssetId: value.assetId,
    });
  };

  const handleClickSave = () => {
    const validationErrors = validate();

    if (!validationErrors.modelValid) {
      setMessage(validationErrors.validations);
      return;
    }

    setSendRequest(true);
  };

  const handleClickDelete = () => {
    setDeleteRequest(true);
  };

  const handleErrorMessage = () => {
    setSendRequest(false);
    setMessage('Something went wrong. Please try again later');
  };

  const handleCreateRequest = (id) => {
    setSendRequest(false);
    onCreate(id);
  };

  const handleUpdateRequest = (data) => {
    setSendRequest(false);
    onUpdate({
      ...contentGroup,
      ...data,
    });
  };

  const handleAddTagInGroup = (tag) => {
    setSelectedTag(tag);
    setSearchedTags([]);
    setAddToGroup(true);
  };

  const handleDeleteError = () => {
    setDeleteRequest(false);
    onCancel();
  };

  const handleDeleteSuccess = () => {
    setDeleteRequest(false);
    onDelete(group.id);
  };

  const handleSearchInput = (e) => {
    const filtered = allTags.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setSearchedTags(filtered);
    setTagName(e.target.value);
  };

  const handleSearchTagError = () => {
    setLoadTags(true);
  };
  const handleSearchTagSuccess = (data) => {
    setAllTags(data);
    setLoadTags(true);
  };

  const handleAddTagError = () => {
    setAddToGroup(false);
    setAddToProject(false);
    setSelectedTag({});
    setTagName('');
  };

  const handleAddTagToProjectSuccess = (data) => {
    setAddToProject(false);
    setLoadTags(false);
    setSelectedTag(data);
    setAddToGroup(true);
    setTagName('');
    setSearchedTags([]);
  };

  const handleAddTagToGroupSuccess = () => {
    setAddToGroup(false);
    const tmpTags = [...group.tags];
    tmpTags.push(selectedTag);
    setGroup({
      ...group,
      tags: tmpTags,
    });
    setSelectedTag({});
    setTagName('');
    setSearchedTags([]);
  };

  const handleRemoveTag = (item) => {
    setSelectedTag(item);
    setRemoveToGroup(true);
  };

  const handleRemoveTagError = () => {
    setRemoveToGroup(false);
  };

  const handleRemoveTagToGroupSuccess = () => {
    const tmp = group.tags.filter((t) => t.id !== selectedTag.id);
    setGroup({
      ...group,
      tags: tmp,
    });
    setRemoveToGroup(false);
  };

  const [isPrintingPdf, setIsPrintingPdf] = useState(false);
  const [searchGroupTitle, setSearchGroupTitle] = useState(contentGroup.groupingContentGroup && contentGroup.groupingContentGroup.name);

  const currentGroupingName = contentGroup.groupingContentGroup && contentGroup.groupingContentGroup.name;

  const { data } = useQuery(SEARCH_CONTENT_GROUPINGS, {
    client,
    variables: {
      projectId: project.id,
    },
  });

  const handlePrint = () => {
    window.open(`/dashboard/${orgId}/${projectId}/cartoon-pdf/${contentGroup.url}`, '_blank').focus();
  };

  const item0 = (
    <Container key={1}>
      <Item>
        <Title>
          <b>Title</b>
          <Tooltip />
        </Title>
        <Input id="input-1" isChanged={handleInfoUpdate} name="title" text={group.title} />
      </Item>
      <Item>
        <Title>
          <b>Description</b>
          <Tooltip />
        </Title>
        <Input id="input-2" isChanged={handleInfoUpdate} name="description" text={group.description} />
      </Item>
      <Item>
        <Title>
          <b>URL</b>
          <Tooltip />
        </Title>
        <Input id="input-3" isChanged={handleInfoUpdate} name="url" text={group.url} />
      </Item>
      <Item>
        <Title>
          <b>Public content</b>
          <Tooltip text="If turned on, a user can view the content with out needing to be signed in." />
        </Title>
        <SwitchButton name="contentType" value={group.contentType === 'PUBLIC'} onChange={handleInfoUpdate} />
      </Item>
      {contentGroup.type === 'CARTOON' && (
        <Item>
          <Title>
            <b>Content preview</b>
            <Tooltip text="Preview the pages altogether" />
          </Title>
          <button className="c-sidebar-button c-close" type="button" onClick={handlePrint}>
            Preview
          </button>
        </Item>
      )}
      {isEdit && data && data.contentGroup.getGroupings.length > 0 && (
        <Item>
          <Title>
            <b>Grouping</b>
            <Tooltip text="Add content group to a grouping" />
          </Title>
          <Search
            autoComplete="off"
            name="grouping"
            placeholder="search groupings"
            type="text"
            value={searchGroupTitle}
            onChange={({ target: { value } }) => setSearchGroupTitle(value)}
          />
          {searchGroupTitle !== currentGroupingName && (
            <Groups
              availibleGroupings={data.contentGroup.getGroupings}
              contentGroup={contentGroup}
              title={searchGroupTitle}
              onSelect={(grouping) => {
                contentGroup.groupingContentGroup = grouping;
                onUpdate(contentGroup);
              }}
            />
          )}
        </Item>
      )}
    </Container>
  );

  const item1 = (
    <Container key={2}>
      <Item>
        <Title>
          <b>Tags</b>
          <Tooltip />
        </Title>
        <TagInput>
          <Search
            ref={textInput}
            autoComplete="off"
            // onKeyPress={e => handleSearch(e)}
            name="tagName"
            placeholder="search tags "
            type="text"
            value={tagName}
            onChange={(e) => handleSearchInput(e)}
          />
          <SearchSuggetionsBox>
            {searchedTags.map((item) => (
              <SearchSuggetionsRow key={item.id} onClick={() => handleAddTagInGroup(item)}>
                {item.name}
              </SearchSuggetionsRow>
            ))}
          </SearchSuggetionsBox>
          <AddNewTag onClick={handleAddNewTag}>
            <InfoIcon>+</InfoIcon>
            Add new tag
          </AddNewTag>
        </TagInput>
      </Item>
      {group.tags &&
        group.tags.length > 0 &&
        group.tags.map((tag) => (
          <Item key={tag.id}>
            <EditableTag tag={tag} title={tag.name} onDelete={handleRemoveTag} />
          </Item>
        ))}
    </Container>
  );

  const item2 = (
    <Container key={3}>
      <Item>
        <Title>
          <b>Estimated time (minute)</b>
          <Tooltip />
        </Title>
        <NumInput isChanged={handleInfoUpdate} name="estimatedTime" number={group.estimatedTime || 0} />
      </Item>
      <Item>
        <Title>
          <b>Release date</b>
          <Tooltip />
        </Title>
        <DatePicker id="input-4" name="releaseDate" value={group.releaseDate || ''} onChange={handleDateUpdate} />
      </Item>
      <Item>
        <Title>
          <b>Thumbnail</b>
          <Tooltip />
        </Title>
        {logoUrl && !editLogo ? (
          <Preview>
            <EditBtn
              onClick={() => {
                setEditLogo(true);
              }}
            >
              Change the image
            </EditBtn>
            <Logo alt="logo" src={logoUrl} />
          </Preview>
        ) : (
          <ImageUploader getData={getLogoData} name="bgImg" projectId={project.id} type="IMAGE" />
        )}
      </Item>
    </Container>
  );

  const exItem = {
    resource: {
      label: 'Tags',
      component: item1,
    },
    news: {
      label: 'Tags',
      component: item1,
    },
    learning: {
      label: 'Advanced',
      component: item2,
    },
    questionnaire: {
      label: 'Advanced',
      component: item2,
    },
    cartoon: {
      label: 'Advanced',
      component: item2,
    },
  };

  const NoComp = <div />;

  return (
    <>
      {isPrintingPdf && <Preloader text="Generating pdf. Please wait." />}

      <RightNavBar hasDelete={isEdit} onCancel={onCancel} onDelete={handleClickDelete} onSave={handleClickSave}>
        <div>
          <h3 className="custom-h3">{isEdit ? `Edit Group - ${group.title}` : 'Create New Group'}</h3>
          <SidebarTab
            item0={item0}
            item1={isEdit && exItem[type] ? exItem[type].component : NoComp}
            label0="Basic"
            label1={isEdit && exItem[type] ? exItem[type].label : ''}
          />
        </div>

        <PopUpMsg message={message} msgDisappear={handleMsgDisappear} type="error" />
      </RightNavBar>
      {sendRequest && !isEdit && type !== 'FORM' && (
        <CreateContentGroupRequest
          contentType={group.contentType || 'PRIVATE'}
          description={group.description}
          projectId={project.id}
          title={group.title}
          type={type.toUpperCase()}
          url={group.url}
          onError={handleErrorMessage}
          onSuccessResult={handleCreateRequest}
        />
      )}
      {sendRequest && !isEdit && type === 'FORM' && (
        <CreateContentGroupForm
          description={group.title}
          projectId={project.id}
          title={group.title}
          onError={handleErrorMessage}
          onSuccessResult={handleCreateRequest}
        />
      )}
      {sendRequest && isEdit && (
        <UpdateContentGroupRequest
          contentGroupId={group.id}
          contentType={group.contentType || 'PRIVATE'}
          description={group.description}
          estimatedTime={Number(group.estimatedTime)}
          iconAssetId={group.iconAssetId}
          releaseDate={group.releaseDate ? new Date(group.releaseDate) : undefined}
          sort={group.sort}
          title={group.title}
          url={group.url}
          onError={handleErrorMessage}
          onSuccessResult={handleUpdateRequest}
        />
      )}
      {deleteRequest && (
        <UpdateContentGroupStatusRequest
          contentGroupId={group.id}
          status="DELETE"
          onError={handleDeleteError}
          onSuccessResult={handleDeleteSuccess}
          // status={isDelete ? 2 : published}
        />
      )}
      {!loadTags && isEdit && type === 'resource' && (
        <SearchTags projectId={project.id} text="" onError={handleSearchTagError} onSuccessResult={handleSearchTagSuccess} />
      )}
      {addToProject && (
        <AddTagInProject
          projectId={project.id}
          tagName={tagName}
          onError={handleAddTagError}
          onSuccessResult={handleAddTagToProjectSuccess}
        />
      )}
      {addToGroup && (
        <AddTagInGroup
          contentGroupId={group.id}
          tagId={selectedTag.id}
          onError={handleAddTagError}
          onSuccessResult={handleAddTagToGroupSuccess}
        />
      )}
      {removeToGroup && (
        <RemoveTagInGroup
          contentGroupId={group.id}
          tagId={selectedTag.id}
          onError={handleRemoveTagError}
          onSuccessResult={handleRemoveTagToGroupSuccess}
        />
      )}
    </>
  );
};

ContentGroupSideBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  contentGroup: PropTypes.shape({
    groupingContentGroup: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    iconAsset: PropTypes.shape({
      contentType: PropTypes.string,
      s3Key: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    projectThemeS3Key: PropTypes.string,
    theme: PropTypes.shape({
      backGroundColor: PropTypes.string,
      buttonColor: PropTypes.string,
      footerColor: PropTypes.string,
      headerColor: PropTypes.string,
      linkColor: PropTypes.string,
      sideColor: PropTypes.string,
    }),
  }),
};

ContentGroupSideBar.defaultProps = {
  contentGroup: {},
};

export default ContentGroupSideBar;
