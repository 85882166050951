import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ToggleBtn from '../UI/Button/ShowHideButton';

import ThemeContext from '../../context/ThemeContext';
import './styled.css';
//TODO: sugessted styles for sidebar bottom offset
const Outter = styled.div`
  left: 0;
  // bottom: 0;
  // top: 4rem;
  position: fixed;
  z-index: 9;
`;

const Wrapper = styled.div`
  background: ${(props) => props.bgColor};
  box-shadow: 2px 0 10px 2px rgba(32, 33, 36, 0.28);
  height: 100vh;
  min-height: calc(100vh - 66px);
  width: 18em;
  z-index: 10;
`;

const Content = styled.div`
  padding-top: 5em;
`;

const Title = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  height: 5em;
  justify-content: flex-start;
  left: 0;
  padding: 1em;
  position: absolute;
  top: 0;
  width: 100%;
`;

const H3 = styled.h3`
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
`;

const ToggleWrapper = styled.div`
  animation: ${(props) =>
    (props.dirc === 'out' && 'slideOut 800ms cubic-bezier(0.300, 0.650, 0.850, 1.000)') ||
    (props.dirc === 'in' && 'slideIn 800ms cubic-bezier(0.300, 0.650, 0.850, 1.000)')};
  position: absolute;
  right: -26px;
  top: 2em;
  visibility: visible;

  @keyframes slideOut {
    0% {
      left: 0;
    }

    100% {
      left: 18em;
    }
  }

  @keyframes slideIn {
    0% {
      left: 18em;
    }

    100% {
      left: 0;
    }
  }
`;

const LeftNavBar = (props) => {
  const { children, title, isShowed, isOpenOnLoad = false } = props;
  const { theme } = useContext(ThemeContext);
  const [showSidebar, setShowSidebar] = useState(isOpenOnLoad);
  const [animation, setAnimation] = useState('');

  const handleShowBar = () => {
    isShowed(!showSidebar);
    if (showSidebar) {
      setAnimation('animated slideOutLeft');
      setTimeout(() => {
        setShowSidebar(false);
      }, 800);
    } else {
      setShowSidebar(true);
      setAnimation('animated slideInLeft');
    }
  };

  return (
    <Outter className={`${animation} fast`}>
      {showSidebar && (
        // className={`${animation} fast`}
        <Wrapper bgColor={theme.sideColor}>
          <Title>
            <H3>{title}</H3>
          </Title>
          <Content>{children}</Content>
        </Wrapper>
      )}
      <ToggleWrapper>
        <ToggleBtn direction="right" isClicked={handleShowBar} />
      </ToggleWrapper>
    </Outter>
  );
};

LeftNavBar.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  isOpenOnLoad: PropTypes.bool,
  isShowed: PropTypes.func,
};

LeftNavBar.defaultProps = {
  isShowed: () => null,
};

export default LeftNavBar;
