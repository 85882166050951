import gql from 'graphql-tag';

export const CREATE_RESPONSE_SET = gql`
  mutation createResponseSet(
    $projectId: String!
    $title: String!
    $responsesRadio: [QuestionResponseRadioInputType]
    $responsesMatch: [QuestionResponseMatchInputType]
    $responsesDropDownList: [QuestionResponseDropDownListInputType]
    $responsesCheckbox: [QuestionResponseCheckboxInputType]
  ) {
    questionSet {
      add(
        projectId: $projectId
        title: $title
        responsesRadio: $responsesRadio
        responsesMatch: $responsesMatch
        responsesDropDownList: $responsesDropDownList
        responsesCheckbox: $responsesCheckbox
      ) {
        id
      }
    }
  }
`;
