/* eslint-disable react/boolean-prop-naming */
import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_MAP_CONTENT_SNIPPET = gql`
  mutation createMapContentSnippet(
    $containerId: Int!
    $visible: Boolean!
    $longitude: Float!
    $latitude: Float!
    $zoom: Int!
    $draggable: Boolean
    $grayscale: Boolean
    $title: String!
  ) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible, entityTitle: $title }
        contentSnippetMap: { longitude: $longitude, latitude: $latitude, zoom: $zoom, draggable: $draggable, grayscale: $grayscale }
      ) {
        id
        sort
      }
    }
  }
`;

const createMapContentSnippetRequest = ({
  containerId,
  isVisible,
  longitude,
  latitude,
  zoom,
  draggable,
  grayscale,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_MAP_CONTENT_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        visible: isVisible,
        longitude,
        latitude,
        zoom,
        draggable,
        grayscale,
        title: '',
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.createContentSnippet.id, data.contentSnippet.createContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createMapContentSnippetRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  draggable: PropTypes.bool,
  grayscale: PropTypes.bool,
  isVisible: PropTypes.bool,
};

createMapContentSnippetRequest.defaultProps = {
  draggable: true,
  grayscale: false,
  isVisible: true,
};

export default createMapContentSnippetRequest;
