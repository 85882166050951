import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.label`
  align-items: center;
  display: inline-flex;
  position: relative;
`;
const InputContainer = styled.span`
  border: 1px solid;
  border-color: rgb(223, 225, 230);
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  transition: 0.2s ease-in-out 0s;
  width: 20px;

  * {
    cursor: pointer;
  }
`;
const Input = styled.input`
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  margin: 0px;
  padding: 0px;
`;

const InputCheck = styled.span`
  background-color: #00c4cc;
  border-radius: 2px;
  height: 82%;
  left: 50%;
  opacity: ${({ isChecked }) => (isChecked ? '1' : '0')};
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  margin: 0px;
  padding: 0px;
  width: 82%;
  transition: 0.2s ease-in-out 0s;
`;
const InputValue = styled.span`
  font-size: initial;
  font-weight: 400;
  margin: 0 0.6em 0 0.2em;
`;

const Checkbox = (props) => {
  const { isSelected, name, onChange, value, displayText } = props;
  const [isChecked, setIsChecked] = useState(false);

  const checkRef = useRef(null);

  const handleChange = (e) => {
    onChange(e);
    setIsChecked(checkRef.current.checked);
  };

  return (
    <Container>
      <InputContainer isChecked={isSelected === undefined ? isChecked : isSelected}>
        <InputCheck isChecked={isSelected === undefined ? isChecked : isSelected} />
        <Input name={name} onChange={handleChange} ref={checkRef} type="checkbox" value={value} />
      </InputContainer>
      <InputValue>{displayText}</InputValue>
    </Container>
  );
};

Checkbox.defaultProps = {
  displayText: '',
  isSelected: undefined,
  name: '',
  value: '',
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  displayText: PropTypes.string,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default Checkbox;
