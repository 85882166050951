import gql from 'graphql-tag';

export const EXPORT_DIARIES = gql`
  query exportDiaries($dateFrom: DateTime!, $dateTo: DateTime!) {
    report {
      exportDiaries(start: $dateFrom, end: $dateTo) {
        contentType
        s3Key
      }
    }
  }
`;
