import React from 'react';

import { ConfirmationMessages, DatePicker } from '@netfront/ui-library';

import { isCalendarTypeSupported } from './CalendarSnippet.helpers';
import { CalendarSnippetProps } from './CalendarSnippet.interfaces';

const CalendarSnippet = ({
  id,
  maxDate,
  message,
  minDate,
  onDateRangeChange,
  onSingleDateChange,
  selectedDate,
  type,
}: CalendarSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message ?? {};

  if (!isCalendarTypeSupported(type)) {
    return <React.Fragment>Date type {type} is not supported</React.Fragment>;
  }

  return (
    <React.Fragment>
      <DatePicker
        id={id}
        isRange={type === 'DATE_RANGE'}
        labelText="Select date"
        maxDate={new Date(maxDate ?? '')}
        minDate={new Date(minDate ?? '')}
        selectedDate={selectedDate}
        isLabelHidden
        onDateRangeChangeHandler={onDateRangeChange}
        onSingleDateChangeHandler={onSingleDateChange}
      />
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </React.Fragment>
  );
};

export { CalendarSnippet };
