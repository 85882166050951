import React, { useEffect, useState } from 'react';

import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { useDebouncedCallback } from 'use-debounce';

import { DEFAULT_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_API_ERROR_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerMultiResponseTextSnippetProps } from './QuestionAnswerMultiResponseTextSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerMultiResponseTextMutationVariables,
  ISetUserAnswerMutationGraphQLResponse,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import {
  IContentPage,
  IQuestionAnswerMultiResponseText,
  IQuestionAnswerMultiResponseTextItem,
  IQuestionAnswerMultiResponseTextItemInputType,
  IQuestionConfigurationMultiResponseText,
} from '../../../../interfaces';
import { MultiResponseTextSnippet } from '../../MultiResponseTextSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerMultiResponseTextSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  message = DEFAULT_MESSAGE,
  summaryFeedbackConfiguration,
  summaryFeedbackMode,
  userFlowStepTrackId,
}: QuestionAnswerMultiResponseTextSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, message: contentSnippetMessage } = contentSnippet ?? {};
  const { responses = [] } = answer ?? ({} as IQuestionAnswerMultiResponseText);
  const { behavior, maxResponse, minResponse, headers } = configuration ?? ({} as IQuestionConfigurationMultiResponseText);

  const [currentMessage, setCurrentMessage] = useState<IMessage>(message);
  const [savedResponses, setSavedResponses] = useState<IQuestionAnswerMultiResponseTextItem[]>(responses);

  const [executeSetUserAnswerMultiResponseText] = useEkardoMutation<
    ISetUserAnswerMutationGraphQLResponse,
    ISetUserAnswerMultiResponseTextMutationVariables
  >({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerMultiResponseText,
    options: {
      onCompleted: (data) => {
        const {
          questionAnswer: {
            setUserAnswer: { responses: returnedResponses },
          },
        } = data;

        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            multiResponseTextItems: returnedResponses,
          },
        });

        setSavedResponses(returnedResponses);

        setCurrentMessage({
          error: '',
          success: DEFAULT_API_SUCCESS_MESSAGE,
        });
      },
      onError: (error) => {
        handleApolloError(error);
        setCurrentMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const clearMessage = () => {
    setCurrentMessage(DEFAULT_MESSAGE);
  };

  const debouncedMutation = useDebouncedCallback((inputResponses: IQuestionAnswerMultiResponseTextItemInputType[]) => {
    if (!contentSnippetFormId) {
      return;
    }

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    const variables: ISetUserAnswerMultiResponseTextMutationVariables = {
      ...baseVariables,
      responses: inputResponses,
    };

    executeSetUserAnswerMultiResponseText({
      variables,
    });
  }, API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS);

  const handleResponsesChange = (inputResponses: IQuestionAnswerMultiResponseTextItemInputType[]) => {
    clearMessage();
    debouncedMutation(inputResponses);
  };

  useEffect(() => {
    if (!contentSnippetMessage) {
      return;
    }

    setCurrentMessage(contentSnippetMessage);
  }, [contentSnippetMessage]);

  const isSummary = Boolean(contentSnippetQuestion);

  return (
    <MultiResponseTextSnippet
      accessToken={accessToken}
      behavior={behavior}
      headers={headers}
      id={`multi-response-text-snippet-${contentSnippetId}`}
      isDisabled={isSummary}
      maxResponse={maxResponse}
      message={currentMessage}
      minResponse={minResponse}
      responses={savedResponses}
      summaryFeedbackConfiguration={summaryFeedbackConfiguration}
      summaryFeedbackMode={summaryFeedbackMode}
      onChange={(inputResponses: IQuestionAnswerMultiResponseTextItemInputType[]) => handleResponsesChange(inputResponses)}
    />
  );
};

export { QuestionAnswerMultiResponseTextSnippet };
