import React, { useState, useEffect, useContext } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import SliderSideBar from '../RightNavBar/SliderSideBar';
import { EditBtn, Preview, Logo } from '../RightNavBar/styled';
import ImageSlider from '../UI/AssetUploader/ImageUploader';
import Backdrop from '../UI/Backdrop/Backdrop';
import EditButton from '../UI/Button/EditButton';
import Button from '../UI/Button/SquareButton';
import Editor from '../UI/TextEditor/Editor';
import Tooltip from '../UI/Tooltip/Tooltips';

import LiveSlider from './LiveSlider';

import { getAssetsUrl } from '../../config';
import AppContext from '../../context/AppContext';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateSliderContentSnippetRequest from '../../middleware/ContentSnippet/createSliderContentSnippetRequest';
import CreateSliderItemRequest from '../../middleware/Slider/createSliderItem';
import GetAllSliderItems from '../../middleware/Slider/searchSliderItems';

const NewPreview = styled(Preview)`
  display: flex;
  height: 12em;
  justify-content: center;
  margin: 0.5em 0 0 0;
  max-width: unset;
  overflow-x: hidden;
`;

const BgImage = styled(Logo)`
  height: 100%;
  width: auto;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const LiveContainer = styled.div`
  background-color: #fff;
  width: 100%;
`;

const SnippetContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 1em 0;
  width: 100%;
`;

const TypeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  height: 5em;
  justify-content: center;
  width: 5em;
`;

const TypeName = styled.p`
  color: #808080;
  font-size: 0.8em;
  margin-top: 1em;
`;

const TypeIcon = styled.button`
  align-items: center;
  background-color: #e1e1e1 !important;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 2.2em;
  justify-content: center;
  width: 2.2em;

  &:focus {
    background-color: #4bc2ca !important;
    outline: none;
  }
`;

const Hr = styled.hr`
  border: 0.5px solid #e1e1e1;
  border-radius: 5px;
  width: 100%;
`;

const AddNewResponse = styled.div`
  align-items: center;
  background: #e4e4e3;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  display: flex;
  height: 2.8em;
  justify-content: flex-start;
  width: 100%;
`;

const SaveAddButton = styled(AddNewResponse)`
  border-radius: 5px;
  margin: 1em 0;
`;

const InfoIcon = styled.span`
  background-color: #4bc1c9;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 1em;
  line-height: 13px;
  margin: 0 1em;
  text-align: center;
  width: 1em;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -0.5em;
`;

const SliderCreation = (props) => {
  const { match, sliderDetail } = props;
  const { org, project } = useContext(AppContext);

  const [quote, setQuote] = useState('');
  const [author, setAuthor] = useState('');
  const [code, setCode] = useState('');
  const [assetId, setAssetId] = useState(null);
  const [assetSrc, setAssetSrc] = useState(null);
  const [createItemRequest, setCreateItemRequest] = useState(false);
  const [sliderType, setSliderType] = useState('QUOTE');
  const [sliderData, setSliderData] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);
  const [loadedItems, setLoadedItems] = useState(false);
  const [sliderSetting, setSliderSetting] = useState({});
  const [editLogo, setEditLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [cleanText, setCleanText] = useState(false);
  const [createSnippet, setCreateSnippet] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    setSliderSetting(sliderDetail);
  }, [sliderDetail]);

  const onClickSliderType = (type) => {
    setSliderType(type);
  };

  const onClickFinishBtn = () => {
    if (match.params.containerId) setCreateSnippet(true);
    else setRedirectUrl(`/dashboard/${org.key}/${project.id}/library/slider`);
  };

  const handleQuoteChange = (value) => {
    setQuote(value);
    setCleanText(false);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };

  const handleCreateItem = () => {
    setCreateItemRequest(true);
  };

  const handleCreateItemError = () => {
    setCreateItemRequest(false);
    setAuthor('');
    setQuote('');
    setCleanText(true);
  };
  const handleCreateItemSuccess = (id) => {
    const tmp = sliderData;
    if (sliderType === 'QUOTE') {
      const tmpItem = {
        type: 'QUOTE',
        id,
        author,
        quote,
      };
      tmp.push(tmpItem);
    } else if (sliderType === 'CODE') {
      const tmpItem = {
        type: 'CODE',
        id,
        text: code,
      };
      tmp.push(tmpItem);
    } else {
      const tmpItem = {
        type: 'IMAGE',
        id,
        assetId,
        assetSrc,
      };
      tmp.push(tmpItem);
    }
    setSliderData(tmp);
    setCreateItemRequest(false);
    setAuthor('');
    setQuote('');
    setLogoUrl('');
    setEditLogo(false);
    setCleanText(true);
  };

  const handleGetItemsError = () => {
    setLoadedItems(true);
  };

  const handleGetItemsSuccess = (data) => {
    const tmp = sliderData;
    data.map((item) => {
      let tmpURL;
      if (item.type !== 'QUOTE' && item.type !== 'CODE') {
        tmpURL = getSignedUrl(item.asset.s3Key, item.asset.type);
      }
      const tmpItem = {
        id: item.id,
        type: item.type,
        assetId: item.assetId,
        assetSrc: tmpURL,
        author: item.author,
        quote: item.quote,
        text: item.text,
      };
      tmp.push(tmpItem);
      return null;
    });

    setSliderData(tmp);
    setLoadedItems(true);
  };

  const onErrorCreateSliderSnippet = () => {
    setCreateSnippet(false);
  };

  const onSuccessCreateSliderSnippet = () => {
    setCreateSnippet(false);
    setRedirectUrl(`/dashboard/${org.key}/${project.id}/group/${match.params.groupId}/content`);
    // history.push(
    //   `/dashboard/${org.key}/project
    // /${project.id}/group/${contentGroup.id}/content`
    // );
    // window.history.go(-1);
  };

  //   setAssetSrc(url);
  //   setAssetId(id);
  // };

  const handleAssetData = (value) => {
    const tmpURL = getSignedUrl(value.s3Key, value.contentType);
    setAssetSrc(tmpURL);
    setAssetId(value.assetId);
    setEditLogo(false);
    setLogoUrl(tmpURL);
  };

  const handleUpdateSetting = (setting) => {
    setSliderSetting(setting);
  };

  const handleDelete = (id) => {
    setSliderData(sliderData.filter((r) => r.id !== id));
  };
  return (
    <>
      <Wrapper>
        Slider <Tooltip />
        <EditButton isClicked={() => setShowSideBar(true)} />
        {showSideBar && (
          <div>
            <SliderSideBar
              closeSideBar={() => setShowSideBar(false)}
              detail={sliderSetting}
              getNewSetting={handleUpdateSetting}
              sliderId={Number(match.params.sliderId)}
            />
            <Backdrop isClicked={() => setShowSideBar(false)} />
          </div>
        )}
        <LiveContainer>
          <LiveSlider handleDelete={handleDelete} sliderData={sliderData} sliderSetting={sliderSetting} />
          <Hr />
        </LiveContainer>
        <SnippetContainer>
          <TypeContainer>
            <TypeContainer>
              <TypeIcon
                className={classnames('button-none', {
                  'bg-color-sky-blue': sliderType === 'QUOTE',
                })}
                onClick={() => onClickSliderType('QUOTE')}
              >
                <img alt="img" height="18px" src={`${getAssetsUrl()}/icons/content-snippet/quote.svg`} width="18px" />
              </TypeIcon>
              <TypeName>Quote</TypeName>
            </TypeContainer>
            <TypeContainer>
              <TypeIcon
                className={classnames('button-none', {
                  'bg-color-sky-blue': sliderType === 'IMAGE',
                })}
                onClick={() => onClickSliderType('IMAGE')}
              >
                <img alt="img" height="18px" src={`${getAssetsUrl()}/icons/content-snippet/image.svg`} width="18px" />
              </TypeIcon>
              <TypeName>Image</TypeName>
            </TypeContainer>
            <TypeContainer>
              <TypeIcon
                className={classnames('button-none', {
                  'bg-color-sky-blue': sliderType === 'CODE',
                })}
                onClick={() => onClickSliderType('CODE')}
              >
                <img alt="img" height="18px" src={`${getAssetsUrl()}/icons/content-snippet/code.svg`} width="18px" />
              </TypeIcon>
              <TypeName>Code</TypeName>
            </TypeContainer>
          </TypeContainer>
        </SnippetContainer>
        {sliderType === 'CODE' && (
          <div>
            <strong>Code</strong>
            <textarea className="w-100 bg-white border" rows="8" value={code} onChange={handleCodeChange} />
          </div>
        )}
        {sliderType === 'QUOTE' && (
          <div>
            <strong>Content</strong>
            <Editor getText={handleQuoteChange} id="text" isCleanText={cleanText} value={quote} />
            <strong>Author</strong>
            <input className="w-100 bg-white border" value={author} onChange={handleAuthorChange} />
          </div>
        )}
        {sliderType === 'IMAGE' &&
          (logoUrl && !editLogo ? (
            <NewPreview>
              <EditBtn
                onClick={() => {
                  setEditLogo(true);
                }}
              >
                Change the image
              </EditBtn>
              <BgImage alt="logo" src={logoUrl} />
            </NewPreview>
          ) : (
            <ImageSlider getData={handleAssetData} name="ImgSlider" projectId={match.params.projectId} type="IMAGE" />
          ))}
        <Hr />
        <SaveAddButton onClick={handleCreateItem}>
          <InfoIcon>+</InfoIcon>
          Save and add new {sliderType.toLowerCase()}
        </SaveAddButton>
        <ButtonGroup>
          <Button bgColor="#505354" color="#fff" isClicked={onClickFinishBtn}>
            Cancel
          </Button>
          <Button isClicked={onClickFinishBtn}>Save &amp; Close</Button>
        </ButtonGroup>
      </Wrapper>
      {redirectUrl !== '' && <Redirect to={redirectUrl} />}
      {createItemRequest && (
        <CreateSliderItemRequest
          assetId={assetId}
          author={author}
          quote={quote}
          sliderId={Number(match.params.sliderId)}
          text={code}
          type={sliderType}
          onError={handleCreateItemError}
          onSuccessResult={handleCreateItemSuccess}
        />
      )}
      {!loadedItems && (
        <GetAllSliderItems sliderId={Number(match.params.sliderId)} onError={handleGetItemsError} onSuccessResult={handleGetItemsSuccess} />
      )}
      {createSnippet && (
        <CreateSliderContentSnippetRequest
          containerId={Number(match.params.containerId)}
          sliderId={Number(match.params.sliderId)}
          isVisible
          onError={onErrorCreateSliderSnippet}
          onSuccessResult={onSuccessCreateSliderSnippet}
        />
      )}
    </>
  );
};

SliderCreation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      containerId: PropTypes.string,
      groupId: PropTypes.string,
      projectId: PropTypes.string,
      sliderId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  sliderDetail: PropTypes.shape({}).isRequired,
  // projectId: PropTypes.string,
};

SliderCreation.defaultProps = {
  // projectId: null,
};

export default SliderCreation;
