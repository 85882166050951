import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_QUESTION_PREVIEW } from './UseGetQuestionPreview.graphql';

const useGetQuestionPreview = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};

  const [executeGetQuestionPreview, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { question: config },
        } = data;

        onCompleted({
          config,
        });
      },
      onError,
    },
    query: query ?? GET_QUESTION_PREVIEW,
    token,
  });

  const handleGetQuestionPreview = ({ variables }) => {
    executeGetQuestionPreview({
      variables,
    });
  };

  return {
    handleGetQuestionPreview,
    isLoading,
  };
};

export { useGetQuestionPreview };
