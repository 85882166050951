import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_PAGE } from './UseGetContentPage.graphql';

const useGetContentPage = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};
  const [executeGetContentPage, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentPage: {
            getContentPage: { actions },
          },
        } = data;

        onCompleted({
          actions,
        });
      },
      onError,
    },
    query: query ?? GET_CONTENT_PAGE,
    token,
  });

  const handleGetContentPage = ({ variables }) => {
    executeGetContentPage({
      variables,
    });
  };

  return {
    handleGetContentPage,
    isLoading,
  };
};

export { useGetContentPage };
