import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const ListContainer = styled.ul`
  left: 0;
  list-style-type: none;
  max-width: 100px;
  padding: 0;
  position: ${props => (props.goTop ? 'relative' : 'absolute')};
  transform: ${props => (props.goTop ? 'unset' : 'translateX(-100%)')};
  display: ${props => (props.goTop ? 'flex' : 'block')};
  flex-flow: row;
  width: 100%;
`;

const SideTabs = props => {
  const { children } = props;
  const goTop = document.body.clientWidth < 1400;
  return (
    <ListContainer goTop={goTop} role="tablist">
      {children}
    </ListContainer>
  );
};

SideTabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SideTabs;
