import React from 'react';

import { useContentPageState } from '../../context/contentPage/ContentPageContext';
import arrowIcon from '../../static/img/icon-chevron.svg';

import './contentPageNavigation.css';

const ContentPageNavigation = ({ refreshTree, activePageId }) => {
  const [pageState] = useContentPageState();

  const { navTreeContentPageIds: pageIds } = pageState;

  const currentPagePosition = pageIds.indexOf(activePageId);

  const firstPage = pageIds[0];
  const lastPage = pageIds[pageIds.length - 1];
  const nextPage = pageIds[currentPagePosition + 1];
  const previousPage = pageIds[currentPagePosition - 1];

  const handleNext = () => {
    const nextActivePageId = activePageId !== lastPage ? nextPage : firstPage;
    refreshTree(nextActivePageId);
  };

  const handlePrevious = () => {
    const previousActivePageId = activePageId !== firstPage ? previousPage : lastPage;
    refreshTree(previousActivePageId);
  };

  return (
    <div className="l-flex-space-between c-navigation-container">
      <button className="c-navigation-button" type="button" onClick={() => handlePrevious()}>
        <div className="c-navigation-icon-container l-small-padding-right">
          <img alt="Previous button" className="c-navigation-icon l-transform-left" src={arrowIcon} />
        </div>
        <strong className="margin-left-1x">Previous</strong>
      </button>
      <button className="c-navigation-button" type="button" onClick={() => handleNext()}>
        <strong className="margin-right-1x">Next</strong>
        <div className="c-navigation-icon-container l-small-padding-left">
          <img alt="Next button" className="c-navigation-icon l-transform-right" src={arrowIcon} />
        </div>
      </button>
    </div>
  );
};

export default ContentPageNavigation;
