import React from 'react';

import { Form, FormButtons, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ClickableTag from '../../../UI/Tag/ClickableTag';
import { Item, Title, TitleText } from '../../styled';

import '../Tabs.css';

export const TagsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const TagsTab = ({ activeTags, onClickTag, onClose, onDelete, onSave, tags }) => {
  const activeTagsItems = activeTags ? activeTags.split(',') : [];

  return (
    <Form onSubmit={onSave}>
      <div className="c-view-wrapper">
        <Item>
          <Title>
            <TitleText>Tags</TitleText>
            <Tooltip text="Edit tag text" />
          </Title>
          <TagsWrapper>
            {tags.map((tag) => (
              <ClickableTag
                key={tag.id}
                isActive={Boolean(activeTagsItems.find((activeTag) => activeTag === tag.name))}
                isClicked={onClickTag}
                tag={tag}
              />
            ))}
          </TagsWrapper>
        </Item>
      </div>
      <FormButtons
        additionalClassNames="c-form-button__directory"
        onClose={onClose}
        onDelete={onDelete ? () => onDelete({ message: 'Are you sure you want to delete this directory?', type: 'directory' }) : undefined}
      />
    </Form>
  );
};
export { TagsTab };

TagsTab.propTypes = {
  activeTags: PropTypes.string,
  onClickTag: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};
