import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ColorPicker from './ColorPicker';

const EditColorBox = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  height: 100%;
  line-height: 1.5em;
  position: absolute;
  text-align: center;
  visibility: hidden;
  width: 100%;
`;

const BaseButton = styled.button`
  border-color: transparent;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 0.25rem 1.25rem;
`;

const ClearButton = styled(BaseButton)`
  background-color: #cbd0d3;
  color: #202124;
`;

const ColorBox = styled.div`
  background: ${props => props.color || '#fff'};
  border: #505354 1px solid;
  border-radius: 4px;
  cursor: pointer;
  height: 1.5em;
  margin: 0.5em 0;
  position: relative;
  width: 3em;

  &:hover {
    ${EditColorBox} {
      visibility: visible;
    }
  }
`;

const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColorPickerButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
  position: relative;
`;

const ColorPickerButtonContainer = styled.div`
  display: flex;
  margin-right: 0.25rem;
`;

const NoColorBox = styled.div`
  border: 1px solid #707070;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  height: 1.5em;
  margin: 0.5em 0;
  position: relative;
  width: 3em;

  @media screen and (prefers-reduced-motion: reduce) {
    &::before {
      animation: none;
      border-bottom: 1px solid deeppink;
      box-sizing: border-box;
      content: '';
      height: 1.5em;
      left: 0;
      position: absolute;
      top: -12px;
      transform: rotateZ(-24deg) scale(1.1);
      transform-origin: bottom center;
      width: 100%;
    }
  }

  &::before {
    animation: slash 5s infinite ease;
    border-bottom: 1px solid deeppink;
    box-sizing: border-box;
    content: '';
    height: 1.5em;
    left: 0;
    position: absolute;
    top: -12px;
    transform: rotateZ(-24deg) scale(1.1);
    transform-origin: bottom center;
    width: 100%;
  }

  &:hover {
    ${EditColorBox} {
      visibility: visible;
    }
  }
`;

const SaveButton = styled(BaseButton)`
  background-color: #4bc2ca;
`;

const ColorInput = props => {
  const { currentColor, getColor, name } = props;

  const [color, setColor] = useState(currentColor);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  useEffect(() => {
    setColor(currentColor);
  }, [currentColor]);

  const clearSelectedColor = (e, targetName) => {
    e.target.name = targetName;
    getColor(null, e);
    setIsColorPickerOpen(!isColorPickerOpen);
  };

  const handleColorSelected = value => {
    setColor(value);
  };

  const handleOpenColorPicker = () => {
    setIsColorPickerOpen(!isColorPickerOpen);
  };

  const saveSelectedColor = (e, targetName) => {
    e.target.name = targetName;
    getColor(color, e);
    setIsColorPickerOpen(!isColorPickerOpen);
  };

  return (
    <>
      {!isColorPickerOpen &&
        (color ? (
          <ColorBox color={color} name={name}>
            <EditColorBox onClick={e => handleOpenColorPicker(e)}>
              Edit
            </EditColorBox>
          </ColorBox>
        ) : (
          <NoColorBox>
            <EditColorBox onClick={e => handleOpenColorPicker(e)}>
              Edit
            </EditColorBox>
          </NoColorBox>
        ))}

      {isColorPickerOpen && (
        <ColorPickerContainer>
          <ColorPickerButtonGroupContainer>
            <ColorPickerButtonContainer>
              <SaveButton
                key={name}
                onClick={e => saveSelectedColor(e, name)}
                type="button"
              >
                Save
              </SaveButton>
            </ColorPickerButtonContainer>
            <ColorPickerButtonContainer>
              <ClearButton
                onClick={e => clearSelectedColor(e, name)}
                type="button"
              >
                Clear
              </ClearButton>
            </ColorPickerButtonContainer>
          </ColorPickerButtonGroupContainer>
          <ColorPicker currentColor={color} getColor={handleColorSelected} />
        </ColorPickerContainer>
      )}
    </>
  );
};

ColorInput.propTypes = {
  getColor: PropTypes.func.isRequired,
  currentColor: PropTypes.string,
  name: PropTypes.string,
};

ColorInput.defaultProps = {
  currentColor: '',
  name: '',
};

export default ColorInput;
