import gql from 'graphql-tag';

export const FRAGMENT_MULTI_RESPONSE = gql`
  fragment multiResponseConf on MultiResponseText {
    description
    id
    maxResponse
    messages {
      correct
      example
      incomplete
      incorrect
    }
    minResponse
    type
    __typename
  }
`;
