import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_CODE_CONTENT_SNIPPET } from './UseUpdateCodeSnippet.graphql';

const useUpdateCodeSnippet = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeUpdateCodeSnippet, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? UPDATE_CODE_CONTENT_SNIPPET,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { updateContentSnippet: snippet },
        } = data;

        onCompleted({
          snippet,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateCodeSnippet = ({ locationId, snippetId }) => {
    executeUpdateCodeSnippet({
      variables: {
        baseSnippet: {
          id: snippetId,
          title: '',
        },
        codeSnippet: {
          locationId,
        },
      },
    });
  };

  return {
    handleUpdateCodeSnippet,
    isLoading,
  };
};

export { useUpdateCodeSnippet };
