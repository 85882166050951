import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Container, DropDownContainer, InputContainer } from './styled';

const InputDropDown = props => {
  const { children, name, onChange, onKeyUp, placeholder, value } = props;

  const input = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleTarget = () => {
    input.current.focus();
    setIsOpen(!isOpen);
  };

  document.addEventListener('click', ({ target }) => {
    if (!input.current) {
      return;
    }

    setIsOpen(input.current.contains(target));
  });

  return (
    <Container>
      <InputContainer isOpen={isOpen} onClick={handleTarget} ref={input}>
        <input
          aria-haspopup="true"
          autoComplete="off"
          id={name}
          key={name}
          name={name}
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
        <svg focusable="false" height="24" role="presentation" viewBox="0 0 24 24" width="24">
          <path
            d="M6.744 8.744a1.053 1.053 0 0 0 0 1.49l4.547 4.557a1 1 0 0 0 1.416 0l4.55-4.558a1.051 1.051 0 1 0-1.488-1.488l-3.77 3.776-3.768-3.776a1.051 1.051 0 0 0-1.487 0z"
            fill="currentColor"
          />
        </svg>
      </InputContainer>

      {isOpen && <DropDownContainer aria-hidden={!isOpen}>{children}</DropDownContainer>}
    </Container>
  );
};

InputDropDown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

InputDropDown.defaultProps = {
  onChange: () => {},
  placeholder: undefined,
  value: '',
};

export default InputDropDown;
