import { useRef } from 'react';

import { useToast } from '../../../../../../legacy/hooks';
import { useCreateCondition, useUpdateCondition } from '../../../../../hooks';

const useUpsertConditions = ({ onUpdate }) => {
  const chainedRequestsCompletedCountRef = useRef({ value: 0 });
  const chainedRequestsCountRef = useRef({ value: 0 });
  const { handleToastError } = useToast();

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleUpsertConditionOnCompleted = () => {
    chainedRequestsCompletedCountRef.current.value += 1;

    if (chainedRequestsCompletedCountRef.current.value === chainedRequestsCountRef.current.value) {
      onUpdate();
      chainedRequestsCompletedCountRef.current.value = 0;
      chainedRequestsCountRef.current.value = 0;
    }
  };

  const { handleUpdateCondition: handleUpdateCheckboxCondition, isLoading: isUpdateCheckboxConditionLoading = false } = useUpdateCondition({
    conditionType: 'checkbox',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateCheckboxCondition, isLoading: isCreateCheckboxConditionLoading = false } = useCreateCondition({
    conditionType: 'checkbox',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateRadioCondition, isLoading: isUpdateRadioConditionLoading = false } = useUpdateCondition({
    conditionType: 'radio',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateRadioCondition, isLoading: isCreateRadioConditionLoading = false } = useCreateCondition({
    conditionType: 'radio',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateTextCondition, isLoading: isUpdateTextConditionLoading = false } = useUpdateCondition({
    conditionType: 'text',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateTextCondition, isLoading: isCreateTextConditionLoading = false } = useCreateCondition({
    conditionType: 'text',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateSliderCondition, isLoading: isUpdateSliderConditionLoading = false } = useUpdateCondition({
    conditionType: 'slider',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateSliderCondition, isLoading: isCreateSliderConditionLoading = false } = useCreateCondition({
    conditionType: 'slider',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateDateCondition, isLoading: isUpdateDateConditionLoading = false } = useUpdateCondition({
    conditionType: 'calendar',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateDateCondition, isLoading: isCreateDateConditionLoading = false } = useCreateCondition({
    conditionType: 'calendar',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const conditionTypeFunctionMap = {
    checkbox: {
      update: handleUpdateCheckboxCondition,
      create: handleCreateCheckboxCondition,
    },
    radio: {
      update: handleUpdateRadioCondition,
      create: handleCreateRadioCondition,
    },
    text: {
      update: handleUpdateTextCondition,
      create: handleCreateTextCondition,
    },
    slider: {
      update: handleUpdateSliderCondition,
      create: handleCreateSliderCondition,
    },
    calendar: {
      update: handleUpdateDateCondition,
      create: handleCreateDateCondition,
    },
  };

  const getConditionVariables = (condition, actionId) => {
    const { type } = condition;

    const sharedVariables = {
      contentPageId: condition.contentPageId,
      contentSnippetQuestionId: condition.contentSnippetQuestionId,
      isTemplate: condition.isTemplate,
      questionConditionId: condition.id,
      questionActionId: actionId,
      title: condition.title,
      type: condition.type,
    };

    const conditionVariableMap = {
      checkbox: {
        questionResponseCheckboxId: condition.questionResponseCheckboxId,
        selection: condition.selection,
      },
      radio: {
        questionResponseRadioId: condition.questionResponseRadioId,
        selection: condition.selection,
      },
      text: {
        text: condition.text,
      },
      slider: {
        minValue: condition.minValue,
        maxValue: condition.maxValue,
      },
      calendar: {
        dateRangeType: condition.dateRangeType,
        minDate: condition.minDate,
        maxDate: condition.maxDate,
        minTimeSpan: condition.minTimeSpan,
        maxTimeSpan: condition.maxTimeSpan,
        selectedDate: condition.selectedDate,
      },
    };

    return {
      ...sharedVariables,
      ...conditionVariableMap[type],
    };
  };

  const getConditionSettings = (condition, actionId) => {
    const { type } = condition;
    return {
      variables: getConditionVariables(condition, actionId),
      functions: conditionTypeFunctionMap[type],
    };
  };

  const upsertCondition = (condition, actionId) => {
    const { id } = condition;

    const {
      variables,
      functions: { update, create },
    } = getConditionSettings(condition, actionId);

    if (id) {
      update({
        variables,
      });
    } else {
      create({
        variables,
      });
    }
  };

  const handleUpsertConditions = ({ conditions, actionId }) => {
    chainedRequestsCountRef.current.value = conditions.length ?? 0;

    conditions.forEach((condition) => upsertCondition(condition, actionId));
  };

  return {
    isLoading:
      isUpdateCheckboxConditionLoading ||
      isCreateCheckboxConditionLoading ||
      isUpdateRadioConditionLoading ||
      isCreateRadioConditionLoading ||
      isUpdateTextConditionLoading ||
      isCreateTextConditionLoading ||
      isUpdateSliderConditionLoading ||
      isCreateSliderConditionLoading ||
      isUpdateDateConditionLoading ||
      isCreateDateConditionLoading,
    handleUpsertConditions,
  };
};
export { useUpsertConditions };
