import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_GROUP_FORM = gql`
  mutation updateContentGroupForm(
    $contentGroupId: Int!
    $sort: Int!
    $title: String!
    $description: String!
    $url: String!
  ) {
    contentGroup {
      updateContentGroup(
        contentGroup: {
          contentGroupId: $contentGroupId
          title: $title
          description: $description
          url: $url
          sort: $sort
        }
      ) {
        createdByUserId
        description
        id
        sort
        title
        url
      }
    }
  }
`;

const updateContentGroupForm = ({
  onError,
  onSuccessResult,
  contentGroupId,
  sort,
  title,
  description,
  url,
}) => (
  <Mutation mutation={UPDATE_CONTENT_GROUP_FORM}>
    {(updateContentForm, { loading, error, data }) => {
      const variables = {
        contentGroupId,
        sort,
        title,
        description,
        url,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentGroup.updateContentGroup);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentForm}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateContentGroupForm.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default updateContentGroupForm;
