import gql from 'graphql-tag';

export const ADD_CONTENT_PAGE_AUDIO = gql`
  mutation($contentPageId: Int!, $assetId: String!) {
    contentPage {
      attachAudio(contentPageId: $contentPageId, assetId: $assetId) {
        id
        assetId
      }
    }
  }
`;
