import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import AppContext from '../../context/AppContext';
import CreateSliderContentSnippetRequest from '../../middleware/ContentSnippet/createSliderContentSnippetRequest';
import UpdateSliderContentSnippetRequest from '../../middleware/ContentSnippet/updateSliderContentSnippet';
import CreateSliderRequest from '../../middleware/Slider/createSlider';
import GetAllSliders from '../../middleware/Slider/getAllSlidersInProject';

// **************styled Components********************
const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  width: 100%;
`;

const Search = styled.input`
  background-color: unset;
  border: none;
  height: 3em;
  width: 100%;
`;

const AddNew = styled.div`
  align-items: center;
  background: #e4e4e3;
  cursor: pointer;
  display: flex;
  height: 2.8em;
  justify-content: flex-start;
  width: 100%;
`;

const InfoIcon = styled.span`
  background-color: #4bc1c9;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 1em;
  line-height: 13px;
  margin: 0 1em;
  text-align: center;
  width: 1em;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em -0.5em 0 0;
`;

const SearchSuggetionsRow = styled.button`
  background: none;
  border: 1px solid #e1e1e1;
  padding: 5px;
  text-align: left;
`;

const SearchSuggetionsBox = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 108px;
  overflow-y: scroll;
`;

const EditableSliderSnippet = (props) => {
  const { containerId, onCancel, onRefreshPage, snippet } = props;

  const {
    org,
    contentGroup,
    project: { id: projectId },
  } = useContext(AppContext);

  const [title, setTitle] = useState(snippet ? snippet.slider.title : '');
  const [searchSuggetions, setSearchSuggetions] = useState([]);
  const [createRequest, setCreateRequest] = useState(false);
  const [loadedSliders, setLoadedSliders] = useState(false);
  const [allSliders, setAllSliders] = useState([]);
  const [sliderId, setSliderId] = useState(snippet ? snippet.sliderId : null);
  const [createSliderSnippetRequest, setCreateSliderSnippetRequest] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const onSearchContentGroup = (e) => {
    const { value } = e.target;
    setTitle(value);
    const filtered = allSliders.filter((item) => item.title.toLowerCase().includes(value.toLowerCase()));
    setSearchSuggetions(filtered);
  };

  const handleAddSlider = () => {
    setCreateRequest(true);
  };

  const onSuggetionClick = (item) => {
    setTitle(item.title);
    setSliderId(item.id);
    setSearchSuggetions([]);
  };

  const handleLoadedSliderError = () => {
    setLoadedSliders(true);
  };

  const handleLoadedSliderSuccess = (data) => {
    setLoadedSliders(true);
    setAllSliders(data);
  };

  const handleCreateError = () => {
    setCreateRequest(false);
  };
  const handleCreateSuccess = (data) => {
    setLoadedSliders(false);
    setCreateRequest(false);
    if (data) {
      setRedirectUrl(`/dashboard/${org.key}/${projectId}/slider-builder/${data}/${containerId}/${contentGroup.id}`);
    }
  };

  const handleOnSaveCreateSlider = () => {
    setCreateSliderSnippetRequest(true);
  };

  const onErrorCreateSliderSnippet = () => {
    setCreateSliderSnippetRequest(false);
  };

  const onSuccessCreateSliderSnippet = () => {
    setCreateSliderSnippetRequest(false);
    onRefreshPage();
  };

  return (
    <Wrapper>
      <Content>
        <Search placeholder="Begin typing to find your Slider" value={title} onChange={(e) => onSearchContentGroup(e)} />
        <SearchSuggetionsBox>
          {searchSuggetions.map((item) => (
            <SearchSuggetionsRow key={item.id} onClick={() => onSuggetionClick(item)}>
              {item.title}
            </SearchSuggetionsRow>
          ))}
        </SearchSuggetionsBox>
        {searchSuggetions.length === 0 && !sliderId && (
          <AddNew onClick={handleAddSlider}>
            <InfoIcon>+</InfoIcon>
            Add new slider
          </AddNew>
        )}
      </Content>
      <ButtonGroup>
        <button className="button--black mt-4 mr-3" type="button" onClick={onCancel}>
          Cancel
        </button>
        <button className="button--blue mt-4" type="submit" onClick={handleOnSaveCreateSlider}>
          Save
        </button>
      </ButtonGroup>
      {redirectUrl !== '' && <Redirect to={redirectUrl} />}
      {!loadedSliders && <GetAllSliders onError={handleLoadedSliderError} onSuccessResult={handleLoadedSliderSuccess} />}
      {createRequest && (
        <CreateSliderRequest
          // Animation: fade / linear
          animation="LINEAR"
          autoplay={false}
          autoplaySpeed={3000}
          slidesToScroll={1}
          slidesToShow={1} //	Direction navigation
          speed={3000} // Loop
          title={title || `new slider ${allSliders.length + 1}`}
          arrows
          dots //	Animation speed
          infinite //	Slide show speed
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
        />
      )}
      {createSliderSnippetRequest && !snippet && (
        <CreateSliderContentSnippetRequest
          containerId={containerId}
          sliderId={sliderId}
          isVisible
          onError={onErrorCreateSliderSnippet}
          onSuccessResult={onSuccessCreateSliderSnippet}
        />
      )}
      {createSliderSnippetRequest && snippet && Object.keys(snippet).length > 0 && (
        <UpdateSliderContentSnippetRequest
          id={snippet.id}
          sliderId={sliderId}
          onError={onErrorCreateSliderSnippet}
          onSuccessResult={onSuccessCreateSliderSnippet}
        />
      )}
    </Wrapper>
  );
};

EditableSliderSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  // onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape({
    id: PropTypes.number,
    slider: PropTypes.shape({
      title: PropTypes.string,
    }),
    sliderId: PropTypes.number,
    sort: PropTypes.number,
  }),
};

EditableSliderSnippet.defaultProps = {
  snippet: null,
};

export default EditableSliderSnippet;
