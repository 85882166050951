/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, createRef, useEffect } from 'react';

import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import CreateAssetRequest from '../../../middleware/Asset/createAssetRequest';
import { DELETE_ASSET } from '../../../middleware/Asset/deleteAssetRequest';
import client from '../../../middleware/client';
import LibraryAssets from '../../ContentSnippet/LibraryAssets';

const Wrapper = styled.div`
  margin: 1em 0;
`;

const Preview = styled.img`
  border-radius: 0.4rem;
  width: 100%;
  max-height: 14rem;
  object-fit: cover;
`;

const ImageUploader = (props) => {
  const { projectId, type, getData, assetId, onImageUpload, apiClient } = props;

  const [assetFromLib, setAssetFromLib] = useState(false);
  const [createAsset, setCreateAsset] = useState(false);
  const [file, setFile] = useState(null);
  const [openLib, setOpenLib] = useState(false);
  const [preview, setPreview] = useState(null);

  const [deleteAsset] = useMutation(DELETE_ASSET, {
    client,
  });

  const dropzoneRef = createRef();

  const handleFileDrop = (files) => {
    setFile(files[0]);
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSelectAsset = (asset) => {
    setFile(asset);
    setPreview(asset.src);
    setAssetFromLib(true);
  };

  const handleAssetError = () => {
    setCreateAsset(false);
  };

  const handleAssetResponse = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, asset },
      },
    } = data;
    const { assetId: droppedAssetId } = asset;

    setCreateAsset(false);

    Object.defineProperty(file, 'nase', {
      value: asset.s3Key,
      writable: true,
    });

    axios.put(signedUrl, file, { headers: { 'content-type': file.type } }).then(() => {
      getData(asset);
    });

    if (assetId) {
      deleteAsset({
        variables: {
          assetId,
        },
      });
    }

    onImageUpload?.(droppedAssetId);
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    if (!assetFromLib) setCreateAsset(true);
    else getData(file);
  }, [file]);

  return (
    <>
      {file && <Preview alt="preview" src={preview} />}
      {!file && (
        <Wrapper>
          {!openLib ? (
            <Dropzone ref={dropzoneRef} accept="image/*" noClick noKeyboard onDropAccepted={handleFileDrop}>
              {({ getInputProps, getRootProps, isDragActive }) => (
                <div className="container">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p style={{ marginBottom: '0.6em' }}>{isDragActive ? 'Drop here' : 'Drop image here or click to upload'}</p>
                    <p>
                      (<strong>Maximum</strong> 1 file)
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          ) : (
            <LibraryAssets goBack={() => setOpenLib(false)} isClicked={handleSelectAsset} type="IMAGE" />
          )}
        </Wrapper>
      )}
      {createAsset && (
        <CreateAssetRequest
          clientOverride={apiClient}
          contentType={file.type}
          fileName={file.name}
          fileSizeInBytes={file.size}
          projectId={projectId}
          type="IMAGE"
          onError={handleAssetError}
          onSuccessResult={handleAssetResponse}
        />
      )}
    </>
  );
};

ImageUploader.propTypes = {
  getData: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  apiClient: PropTypes.shape({}),
  onImageUpload: PropTypes.func,
};

ImageUploader.defaultProps = {
  apiClient: client,
};

export default ImageUploader;
