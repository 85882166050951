const USE_IDENTITY_SITE_URLS_CONSTANTS = Object.freeze({
  ROUTES: Object.freeze({
    FORGOT_PASSWORD: '/forgot-password',
    IMPERSONATED_USER_LOGOUT: '/impersonated-user-logout',
    LOGIN: '/login',
    LOGOUT: '/logout',
    PROFILE: '/profile',
    REGISTER: '/register',
  }),
});

const IDENTITY_ACTIONS = Object.freeze({
  LOGIN: 'LOGIN',
  REGISTRATION: 'REGISTRATION',
});

// eslint-disable-next-line detect-unwanted-words/detect-unwanted-words-in-code
const LOCALHOST = 'localhost';

const PROTOCOLS = Object.freeze({
  HTTP: 'http://',
  HTTPS: 'https://',
});


export { USE_IDENTITY_SITE_URLS_CONSTANTS, IDENTITY_ACTIONS, LOCALHOST, PROTOCOLS };
