import { gql } from '@apollo/client';

const ASSET_FRAGMENT = gql`
  fragment asset on AssetType {
    alt
    assetId
    contentType
    presignedUrl
    projectId
    s3Key
    type
  }
`;

const BUBBLE_TEXT_FRAGMENT = gql`
  fragment bubbleTexts on BubbleTextType {
    angle
    asset {
      ...asset
    }
    character {
      id
      image {
        ...asset
      }
      name
    }
    shape
    sort
    text
    w
    x
    y
  }
`;

const CONTENT_EVENT_FRAGMENT = gql`
  fragment contentEvent on ContentEventType {
    animation
    animationDirection
    asset {
      ...asset
    }
    backgroundColor
    borderColor
    entityId
    event
    eventDirection
    eventTitle
    id
    infinite
    millisecondDelay
    millisecondSpeed
    style
    titleColor
    triggerStyle
  }
`;

const CONTENT_EVENT_ON_PAGE_FRAGMENT = gql`
  fragment contentEventsOnPage on EventsOnPageType {
    contentEvents {
      ...contentEvent
    }
    type
  }
`;

const CONTENT_PAGE_FRAGMENT = gql`
  fragment contentPage on ContentPageType {
    actions {
      id
      conditions {
        id
        ... on QuestionConditionCheckboxSelectedType {
          answer: answers(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
            ... on QuestionAnswerCheckboxType {
              selectedAnswers {
                questionAnswerCheckboxId
                questionResponseCheckboxId
              }
            }
          }
          contentSnippetQuestionId
          questionResponseCheckboxId
        }
        ... on QuestionConditionDateSelectedType {
          answer: answers(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
            ... on QuestionAnswerCalendarType {
              dateAnswered
            }
          }
          contentSnippetQuestionId
          selectedDate
        }
        ... on QuestionConditionDropDownListItemSelectedType {
          answer: answers(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
            ... on QuestionAnswerDropDownListType {
              questionResponseDropDownListId
            }
          }
          contentSnippetQuestionId
          questionResponseDropDownListId
        }
        ... on QuestionConditionNumberSelectedType {
          answer: answers(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
            ... on QuestionAnswerNumberType {
              numberAnswered
            }
          }
          contentSnippetQuestionId
          numberSelected
        }
        ... on QuestionConditionRadioResponseSelectedType {
          answer: answers(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
            ... on QuestionAnswerRadioType {
              questionResponseRadioId
            }
          }
          contentSnippetQuestionId
          questionResponseRadioId
          selectedRadioResponse {
            contentSnippetId
            id
            label
            questionResponseSetId
            sort
            scoreValue
            userId
            value
            __typename
          }
          __typename
        }
        ... on QuestionConditionSliderValueSelectedType {
          answer: answers(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
            ... on QuestionAnswerSliderType {
              value
            }
          }
          contentSnippetQuestionId
          maxValue
          minValue
        }
        ... on QuestionConditionTextType {
          answer: answers(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
            ... on QuestionAnswerMultilineTextType {
              text
            }
            ... on QuestionAnswerSinglelineTextType {
              text
            }
          }
          contentSnippetQuestionId
          text
        }
        __typename
      }
      ... on QuestionActionHideContainerType {
        containerId
        __typename
      }
      ... on QuestionActionHideSectionType {
        sectionId
        __typename
      }
      ... on QuestionActionHideSectionGroupType {
        sectionGroupId
        __typename
      }
      ... on QuestionActionHideType {
        targetSnippetId
        __typename
      }
      ... on QuestionActionShowContainerType {
        containerId
        __typename
      }
      ... on QuestionActionShowSectionType {
        sectionId
        __typename
      }
      ... on QuestionActionShowSectionGroupType {
        sectionGroupId
        __typename
      }
      ... on QuestionActionShowType {
        targetSnippetId
        __typename
      }
      ... on QuestionActionHideContainerType {
        containerId
      }
      ... on QuestionActionHideSectionType {
        sectionId
      }
      ... on QuestionActionHideSectionGroupType {
        sectionGroupId
      }
      ... on QuestionActionHideType {
        targetSnippetId
      }
      ... on QuestionActionShowContainerType {
        containerId
      }
      ... on QuestionActionShowSectionType {
        sectionId
      }
      ... on QuestionActionShowSectionGroupType {
        sectionGroupId
      }
      ... on QuestionActionShowType {
        targetSnippetId
      }
    }
    asset {
      ...asset
    }
    bubbleTexts {
      ...bubbleTexts
    }
    contentEventsOnPage {
      ...contentEventsOnPage
    }
    contentGroup {
      description
      estimatedTime
      id
      tags {
        id
        name
        sort
      }
      title
      url
    }
    contentGroupId
    cssStyle {
      ...cssStyle
    }
    id
    relatedPage {
      asset {
        ...asset
      }
      id
      title
      url
    }
    sectionGroups {
      contentSections {
        contentEvent {
          ...contentEvent
        }
        contentLayoutId
        contentPageId
        cssStyle {
          ...cssStyle
        }
        id
        sectionContainers {
          contentEvent {
            ...contentEvent
          }
          cssStyle {
            ...cssStyle
          }
          id
          snippets {
            backgroundColor
            borderColor
            contentEvent {
              ...contentEvent
            }
            ...contentSnippetCluster
            ...contentSnippetApp
            ...contentSnippetAudio
            ...contentSnippetAvatar
            ...contentSnippetButton
            ...contentSnippetCode
            ...contentSnippetDocument
            ...contentSnippetEmbed
            ...contentSnippetForm
            ...contentSnippetImage
            ...contentSnippetImageMap
            ...contentSnippetMap
            ...contentSnippetQuestion
            ...contentSnippetQuote
            ...contentSnippetSlider
            ...contentSnippetSpacer
            ...contentSnippetSummary
            ...contentSnippetSummaryResult
            ...contentSnippetText
            ...contentSnippetVideo
            cssStyle {
              ...cssStyle
            }
            id
            sort
            visible
          }
          sort
          visible
        }
        sort
        visible
      }
      id
      ...sectionGroupTab
      sort
      visible
    }
    seoDescription
    seoTitle
    sort
    status
    tags {
      ...tag
    }
    title
    url
  }
`;

const CONTENT_SNIPPET_APP_FRAGMENT = gql`
  fragment contentSnippetApp on ContentSnippetAppType {
    app {
      appApiKey
      appVersionId
      files {
        fileType
        id
        location {
          contentType
          presignedUrl
          s3Key
        }
      }
      id
    }
    appComponentId
  }
`;

const CONTENT_SNIPPET_AUDIO_FRAGMENT = gql`
  fragment contentSnippetAudio on ContentSnippetAudioType {
    audio: asset {
      ...asset
    }
    duration
    startAt
  }
`;

const CONTENT_SNIPPET_AVATAR_FRAGMENT = gql`
  fragment contentSnippetAvatar on ContentSnippetAvatarType {
    avatar {
      configurations: configuration {
        key
        value
      }
      imageUrl
    }
  }
`;

const CONTENT_SNIPPET_BUTTON_FRAGMENT = gql`
  fragment contentSnippetButton on ContentSnippetButtonType {
    buttonBackgroundColor
    fontColor
    foregroundColor
    hoverColor
    hyperlink
    innerText
    page {
      url
    }
    style
    target
  }
`;

const CONTENT_SNIPPET_CLUSTER_FRAGMENT = gql`
  fragment contentSnippetCluster on ContentSnippetClusterResultType {
    resultBand(trackingId: $userFlowStepTrackId) {
      description
    }
  }
`;

const CONTENT_SNIPPET_CODE_FRAGMENT = gql`
  fragment contentSnippetCode on ContentSnippetCodeType {
    location {
      ...asset
    }
  }
`;

const CONTENT_SNIPPET_DOCUMENT_FRAGMENT = gql`
  fragment contentSnippetDocument on ContentSnippetDocumentType {
    document: asset {
      ...asset
    }
  }
`;

const CONTENT_SNIPPET_EMBED_FRAGMENT = gql`
  fragment contentSnippetEmbed on ContentSnippetEmbedType {
    embed: code
    title
    type
  }
`;

const CONTENT_SNIPPET_FORM_FRAGMENT = gql`
  fragment contentSnippetForm on ContentSnippetFormType {
    form {
      contentPages {
        bubbleTexts {
          ...bubbleTexts
        }
        contentEventsOnPage {
          ...contentEventsOnPage
        }
        cssStyle {
          ...cssStyle
        }
        sectionGroups {
          contentSections {
            contentEvent {
              ...contentEvent
            }
            cssStyle {
              ...cssStyle
            }
            id
            sectionContainers {
              contentEvent {
                ...contentEvent
              }
              id
              snippets {
                contentEvent {
                  ...contentEvent
                }
                ...contentSnippetApp
                ...contentSnippetAudio
                ...contentSnippetImage
                ...contentSnippetQuestion
                ...contentSnippetSummary
                ...contentSnippetText
                ...contentSnippetVideo
                id
                sort
                visible
              }
              sort
              visible
            }
            sort
            visible
          }
          id
          ...sectionGroupTab
          sort
          visible
        }
      }
      cssStyle {
        ...cssStyle
      }
      formType
    }
    formType
    id
    sort
    visible
  }
`;

const CONTENT_SNIPPET_IMAGE_FRAGMENT = gql`
  fragment contentSnippetImage on ContentSnippetImageType {
    asset {
      ...asset
    }
    height
    width
  }
`;

const CONTENT_SNIPPET_IMAGE_MAP_FRAGMENT = gql`
  fragment contentSnippetImageMap on ContentSnippetImageMapType {
    contentGroup {
      id
      iconAsset {
        ...asset
      }
      regions {
        iconSize
        iconType
        id
        x
        y
      }
      title
    }
  }
`;

const CONTENT_SNIPPET_MAP_FRAGMENT = gql`
  fragment contentSnippetMap on ContentSnippetMapType {
    draggable
    grayscale
    latitude
    longitude
    zoom
  }
`;

const CONTENT_SNIPPET_QUESTION_FRAGMENT = gql`
  fragment contentSnippetQuestion on ContentSnippetQuestionType {
    answer: answers(contentPageId: $contentPageId, userFlowStepTrackId: $userFlowStepTrackId) @include(if: $withAnswers) {
      ...questionAnswersInterface
    }
    behavior
    configuration {
      id
      ... on Calendar {
        defaultDate
        maxDate
        messages {
          ...questionConfigurationMessage
        }
        minDate
      }
      ... on Checkbox {
        allowUserGeneratedResponses
        maxCheckedResponse
        messages {
          ...questionConfigurationMessage
        }
        responseSet {
          ...responseSet
        }
      }
      ... on DropDownList {
        canModify
        messages {
          ...questionConfigurationMessage
        }
        responseSet {
          ...responseSet
        }
      }
      ... on Match {
        layout
        allowUserGeneratedResponses
        matchItems {
          id
          maxItemsCount
          minItemsCount
          questionConfigurationMatchId
          sort
          text
        }
        messages {
          ...questionConfigurationMessage
        }
        randomise
        responseSet {
          ...responseSet
        }
      }
      ... on MultiResponseText {
        behavior
        maxResponse
        messages {
          ...questionConfigurationMessage
        }
        headers {
          content
          sort
          id
          hint
          width
          inputType
          alignment
        }
        minResponse
        type
      }
      ... on MultiText {
        caseSensitive
        maxLength
        messages {
          ...questionConfigurationMessage
        }
        minLength
        regex
      }
      ... on Number {
        defaultValue
        maxValue
        messages {
          ...questionConfigurationMessage
        }
        minValue
      }
      ... on Radio {
        allowUserGeneratedResponses
        canModify
        messages {
          ...questionConfigurationMessage
        }
        responseSet {
          ...responseSet
        }
      }
      ... on SingleText {
        maxLength
        messages {
          ...questionConfigurationMessage
        }
        minLength
        regex
      }
      ... on Slider {
        defaultValue
        maxValue
        messages {
          ...questionConfigurationMessage
        }
        minValue
        step
        ticks {
          label
          sliderValue
        }
      }
    }
    id
    majorNumber
    mandatory
    minorNumber
    questionText: question
    visible
  }
`;

const CONTENT_SNIPPET_QUOTE_FRAGMENT = gql`
  fragment contentSnippetQuote on ContentSnippetQuoteType {
    author
    text
  }
`;

const CONTENT_SNIPPET_SLIDER_FRAGMENT = gql`
  fragment contentSnippetSlider on ContentSnippetSliderType {
    slider {
      animation
      arrows
      autoplay
      autoplaySpeed
      created
      description
      dots
      height
      id
      infinite
      projectId
      sliderItems {
        asset {
          ...asset
        }
        author
        id
        parentAsset {
          ...asset
        }
        quote
        text
        type
      }
      slidesToScroll
      slidesToShow
      speed
      title
      type
    }
  }
`;

const CONTENT_SNIPPET_SPACER_FRAGMENT = gql`
  fragment contentSnippetSpacer on ContentSnippetSpacerType {
    alignment
    height
    lineBreak
    widthPercentage
  }
`;

const CONTENT_SNIPPET_SUMMARY_RESULT_FRAGMENT = gql`
  fragment contentSnippetSummaryResult on ContentSnippetSummaryResultType {
    additionalMessage
    answer(trackingId: $userFlowStepTrackId) @include(if: $withAnswers) {
      ...questionAnswersInterface
    }
    isCorrect(trackingId: $userFlowStepTrackId) @include(if: $withAnswers)
    question {
      questionText: question
    }
  }
`;

const CONTENT_SNIPPET_TEXT_FRAGMENT = gql`
  fragment contentSnippetText on ContentSnippetTextType {
    linkColor
    listIconColor
    margins {
      bottom {
        ...margin
      }
      left {
        ...margin
      }
      right {
        ...margin
      }
      top {
        ...margin
      }
    }
    text
  }
`;

const CONTENT_SNIPPET_VIDEO_FRAGMENT = gql`
  fragment contentSnippetVideo on ContentSnippetVideoType {
    video: asset {
      ...asset
    }
    assetId
  }
`;

const CSS_STYLE_FRAGMENT = gql`
  fragment cssStyle on CssStyleType {
    alignItems
    backgroundColor
    borderColor
    borderRadius
    borderStyle
    class
    display
    color: fontColor
    fontSize
    icons {
      asset {
        ...asset
      }
      x
      y
    }
    id
    name
    padding
    primaryColor
    projectId
    secondaryColor
    tertiaryColor
    verticalAlign
  }
`;

const MARGIN_FRAGMENT = gql`
  fragment margin on MarginType {
    type
    value
  }
`;

const QUESTION_ANSWER_INTERFACE_FRAGMENT = gql`
  fragment questionAnswersInterface on QuestionAnswerInterface {
    id
    contentPageId
    contentSnippetQuestionId
    ...questionAnswerCalendar
    ...questionAnswerCheckbox
    ...questionAnswerDecimal
    ...questionAnswerDropDownList
    ...questionAnswerMatch
    ...questionAnswerMultiLineText
    ...questionAnswerMultiResponseText
    ...questionAnswerRadio
    ...questionAnswerRating
    ...questionAnswerSingleLineText
    ...questionAnswerSlider
    ...questionAnswerNumber
  }
`;

const QUESTION_ANSWER_CALENDAR_FRAGMENT = gql`
  fragment questionAnswerCalendar on QuestionAnswerCalendarType {
    dateAnswered
  }
`;

const QUESTION_ANSWER_CHECKBOX_FRAGMENT = gql`
  fragment questionAnswerCheckbox on QuestionAnswerCheckboxType {
    contentPageId
    contentSnippetQuestionId
    dateStamp
    feedback
    id
    isMarked
    selectedAnswers {
      ...questionAnswerCheckboxResponseCheckbox
    }
    status
    userFlowStepTrackId
  }
`;

const QUESTION_ANSWER_CHECKBOX_RESPONSE_CHECKBOX_FRAGMENT = gql`
  fragment questionAnswerCheckboxResponseCheckbox on QuestionAnswerCheckboxResponseCheckboxType {
    summaryFeedback {
      ... on SummaryFeedbackRankAndNoteType {
        id
        note
        rank
      }
      ... on SummaryFeedbackRadioType {
        id
        questionAnswerId
        questionResponseRadioId
      }
    }
    questionResponseCheckboxId
    questionResponse {
      label
    }
  }
`;

const QUESTION_ANSWER_DROPDOWN_LIST_FRAGMENT = gql`
  fragment questionAnswerDropDownList on QuestionAnswerDropDownListType {
    questionResponseDropDownListId
    questionResponse {
      label
    }
  }
`;

const QUESTION_ANSWER_MATCH_FRAGMENT = gql`
  fragment questionAnswerMatch on QuestionAnswerMatchType {
    answers {
      definedPosition
      questionAnswerMatchId
      questionResponseMatchId
      summaryFeedback {
        ... on SummaryFeedbackMultiResponseType {
          answers {
            multiResponseCategoryId
            values
          }
        }
        ... on SummaryFeedbackRankAndNoteType {
          id
          note
          rank
        }
      }
    }
  }
`;

const QUESTION_ANSWER_MULTI_LINE_TEXT_FRAGMENT = gql`
  fragment questionAnswerMultiLineText on QuestionAnswerMultilineTextType {
    text
  }
`;

const QUESTION_ANSWER_MULTI_RESPONSE_TEXT_FRAGMENT = gql`
  fragment questionAnswerMultiResponseText on QuestionAnswerMultiResponseTextType {
    responses {
      ...questionAnswerMultiResponseTextItem
    }
  }
`;

const QUESTION_ANSWER_MULTI_RESPONSE_TEXT_ITEM_FRAGMENT = gql`
  fragment questionAnswerMultiResponseTextItem on QuestionAnswerMultiResponseTextItemType {
    id
    status
    value
    questionAnswerMultiResponseTextId
    summaryFeedback {
      ... on SummaryFeedbackMultiResponseType {
        answers {
          multiResponseCategoryId
          values
        }
      }
      ... on SummaryFeedbackRangeType {
        value
      }
    }
  }
`;

const QUESTION_ANSWER_NUMBER_FRAGMENT = gql`
  fragment questionAnswerNumber on QuestionAnswerNumberType {
    numberAnswered
  }
`;

const QUESTION_ANSWER_DECIMAL_FRAGMENT = gql`
  fragment questionAnswerDecimal on QuestionAnswerDecimalType {
    numberAnswered
  }
`;

const QUESTION_ANSWER_RADIO_FRAGMENT = gql`
  fragment questionAnswerRadio on QuestionAnswerRadioType {
    questionResponseRadioId
    questionResponse {
      label
    }
  }
`;

const QUESTION_ANSWER_RATING_FRAGMENT = gql`
  fragment questionAnswerRating on QuestionAnswerRatingType {
    rate
  }
`;

const QUESTION_ANSWER_SINGLE_LINE_TEXT_FRAGMENT = gql`
  fragment questionAnswerSingleLineText on QuestionAnswerSinglelineTextType {
    text
  }
`;

const QUESTION_ANSWER_SLIDER_FRAGMENT = gql`
  fragment questionAnswerSlider on QuestionAnswerSliderType {
    value
  }
`;

const QUESTION_CONFIGURATION_MESSAGE_FRAGMENT = gql`
  fragment questionConfigurationMessage on QuestionConfigurationMessageType {
    correct
    example
    incomplete
    incorrect
  }
`;

const RESPONSE_SET_FRAGMENT = gql`
  fragment responseSet on QuestionResponseSetType {
    availableResponses(fetchUserGeneratedResponses: true) {
      asset {
        ...asset
      }
      id
      label
      ... on QuestionResponseCheckboxType {
        scoreValue
        sort
        userId
      }
      ... on QuestionResponseDropDownListType {
        scoreValue
        sort
        userId
      }
      ... on QuestionResponseMatchType {
        correctPosition
        sort
        startPosition
        static
        userId
      }
      ... on QuestionResponseRadioType {
        scoreValue
        sort
        userId
      }
      value
    }
    id
    title
  }
`;

const SECTION_GROUP_TAB_FRAGMENT = gql`
  fragment sectionGroupTab on SectionGroupTabType {
    ... on SectionGroupTabType {
      asset {
        ...asset
      }
      configuration {
        maxTabs
        tabsAlignment
      }
      configurationId
      tabSort
      text
      visible
    }
  }
`;

const SET_USER_ANSWER_CALENDAR_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $dateAnswered: DateTime
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerCalendar: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          dateAnswered: $dateAnswered
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_CHECKBOX_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $selectedAnswers: [QuestionAnswerCheckboxResponseCheckboxInputType]
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerCheckbox: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          selectedAnswers: $selectedAnswers
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_DROPDOWN_LIST_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $questionResponseDropDownListId: Int!
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerDropDownList: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          questionResponseDropDownListId: $questionResponseDropDownListId
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_MULTI_LINE_TEXT_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $status: EQuestionAnswerStatus
    $text: String!
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerMultilineText: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          status: $status
          text: $text
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_MULTI_RESPONSE_TEXT_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $responses: [QuestionAnswerMultiResponseTextItemInputType]
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerMultiResponseText: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          responses: $responses
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
        ... on QuestionAnswerMultiResponseTextType {
          responses {
            id
            status
            value
          }
        }
      }
    }
  }
`;

const SET_USER_ANSWER_NUMBER_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $numberAnswered: Int!
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerNumber: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          numberAnswered: $numberAnswered
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_DECIMAL_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $numberAnswered: Decimal!
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerDecimal: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          answer: $numberAnswered
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_RADIO_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $questionResponseRadioId: Int!
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerRadio: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          questionResponseRadioId: $questionResponseRadioId
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
        ... on QuestionAnswerRadioType {
          questionResponseRadioId
        }
      }
    }
  }
`;

const SET_USER_ANSWER_SINGLE_LINE_TEXT_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $status: EQuestionAnswerStatus
    $text: String!
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerSinglelineText: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          status: $status
          text: $text
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_SLIDER_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
    $value: Int!
  ) {
    questionAnswer {
      setUserAnswer(
        answerSlider: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
          value: $value
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SET_USER_ANSWER_RATING_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
    $rate: Int!
  ) {
    questionAnswer {
      setUserAnswer(
        answerRating: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
          rate: $rate
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        id
      }
    }
  }
`;

const SUMMARY_FEEDBACK_CONFIGURATION_MULTI_RESPONSE_FRAGMENT = gql`
  fragment summaryFeedbackConfigurationMultiResponse on SummaryFeedbackConfigurationMultiResponseType {
    categories {
      label
      sort
      id
    }
  }
`;

const SUMMARY_FEEDBACK_CONFIGURATION_RANGE_FRAGMENT = gql`
  fragment summaryFeedbackConfigurationRange on SummaryFeedbackConfigurationRangeType {
    contentSnippetSummaryId
    maxRange
    minRange
  }
`;

const SUMMARY_FEEDBACK_CONFIGURATION_RADIO = gql`
  fragment summaryFeedbackConfigurationRadio on SummaryFeedbackConfigurationRadioType {
    contentSnippetSummaryId
    isReadOnly
    responseSet {
      availableResponses {
        label
        value
        id
      }
      id
      title
      type
    }
    responseSetId
  }
`;

const SUMMARY_FEEDBACK_CONFIGURATION_RANK_AND_NOTE_FRAGMENT = gql`
  fragment summaryFeedbackConfigurationRankAndNote on SummaryFeedbackConfigurationRankAndNoteType {
    configuration
    contentSnippetSummaryId
    itemsToRank
    noteMaxLength
  }
`;

const TAG_FRAGMENT = gql`
  fragment tag on TagType {
    id
    name
    projectId
    sort
  }
`;

const CONTENT_SNIPPET_SUMMARY_FRAGMENT = gql`
  fragment contentSnippetSummary on ContentSnippetSummaryType {
    contentSnippetQuestion(fetchAnswers: true) {
      ...contentSnippetQuestion
    }
    matchItemId
    summaryFeedbackConfiguration {
      label
      ...summaryFeedbackConfigurationMultiResponse
      ...summaryFeedbackConfigurationRange
      ...summaryFeedbackConfigurationRankAndNote
      ...summaryFeedbackConfigurationRadio
    }
    summaryFeedbackMode
  }
`;

const CONTENT_PAGE_CONTEXT_GRAPHQL = Object.freeze({
  fragments: Object.freeze({
    asset: ASSET_FRAGMENT,
    bubbleText: BUBBLE_TEXT_FRAGMENT,
    contentEvent: CONTENT_EVENT_FRAGMENT,
    contentEventOnPage: CONTENT_EVENT_ON_PAGE_FRAGMENT,
    contentPage: CONTENT_PAGE_FRAGMENT,
    contentSnippet: Object.freeze({
      app: CONTENT_SNIPPET_APP_FRAGMENT,
      audio: CONTENT_SNIPPET_AUDIO_FRAGMENT,
      avatar: CONTENT_SNIPPET_AVATAR_FRAGMENT,
      button: CONTENT_SNIPPET_BUTTON_FRAGMENT,
      cluster: CONTENT_SNIPPET_CLUSTER_FRAGMENT,
      code: CONTENT_SNIPPET_CODE_FRAGMENT,
      document: CONTENT_SNIPPET_DOCUMENT_FRAGMENT,
      embed: CONTENT_SNIPPET_EMBED_FRAGMENT,
      form: CONTENT_SNIPPET_FORM_FRAGMENT,
      image: CONTENT_SNIPPET_IMAGE_FRAGMENT,
      imageMap: CONTENT_SNIPPET_IMAGE_MAP_FRAGMENT,
      map: CONTENT_SNIPPET_MAP_FRAGMENT,
      question: CONTENT_SNIPPET_QUESTION_FRAGMENT,
      quote: CONTENT_SNIPPET_QUOTE_FRAGMENT,
      slider: CONTENT_SNIPPET_SLIDER_FRAGMENT,
      spacer: CONTENT_SNIPPET_SPACER_FRAGMENT,
      summary: CONTENT_SNIPPET_SUMMARY_FRAGMENT,
      summaryResult: CONTENT_SNIPPET_SUMMARY_RESULT_FRAGMENT,
      text: CONTENT_SNIPPET_TEXT_FRAGMENT,
      video: CONTENT_SNIPPET_VIDEO_FRAGMENT,
    }),
    cssStyle: CSS_STYLE_FRAGMENT,
    margin: MARGIN_FRAGMENT,
    questionAnswer: Object.freeze({
      calendar: QUESTION_ANSWER_CALENDAR_FRAGMENT,
      checkbox: QUESTION_ANSWER_CHECKBOX_FRAGMENT,
      checkboxResponseCheckbox: QUESTION_ANSWER_CHECKBOX_RESPONSE_CHECKBOX_FRAGMENT,
      decimal: QUESTION_ANSWER_DECIMAL_FRAGMENT,
      dropdownList: QUESTION_ANSWER_DROPDOWN_LIST_FRAGMENT,
      interface: QUESTION_ANSWER_INTERFACE_FRAGMENT,
      match: QUESTION_ANSWER_MATCH_FRAGMENT,
      multiLineText: QUESTION_ANSWER_MULTI_LINE_TEXT_FRAGMENT,
      multiResponseText: QUESTION_ANSWER_MULTI_RESPONSE_TEXT_FRAGMENT,
      multiResponseTextItem: QUESTION_ANSWER_MULTI_RESPONSE_TEXT_ITEM_FRAGMENT,
      number: QUESTION_ANSWER_NUMBER_FRAGMENT,
      radio: QUESTION_ANSWER_RADIO_FRAGMENT,
      rating: QUESTION_ANSWER_RATING_FRAGMENT,
      singleLineText: QUESTION_ANSWER_SINGLE_LINE_TEXT_FRAGMENT,
      slider: QUESTION_ANSWER_SLIDER_FRAGMENT,
    }),
    questionConfigurationMessage: QUESTION_CONFIGURATION_MESSAGE_FRAGMENT,
    responseSet: RESPONSE_SET_FRAGMENT,
    sectionGroupTab: SECTION_GROUP_TAB_FRAGMENT,
    summaryFeedbackConfigurationMultiResponse: SUMMARY_FEEDBACK_CONFIGURATION_MULTI_RESPONSE_FRAGMENT,
    summaryFeedbackConfigurationRange: SUMMARY_FEEDBACK_CONFIGURATION_RANGE_FRAGMENT,
    summaryFeedbackConfigurationRadio: SUMMARY_FEEDBACK_CONFIGURATION_RADIO,
    summaryFeedbackConfigurationRankAndNote: SUMMARY_FEEDBACK_CONFIGURATION_RANK_AND_NOTE_FRAGMENT,
    tag: TAG_FRAGMENT,
  }),
  mutations: Object.freeze({
    setUserAnswerCalendar: SET_USER_ANSWER_CALENDAR_MUTATION,
    setUserAnswerCheckbox: SET_USER_ANSWER_CHECKBOX_MUTATION,
    setUserAnswerDecimal: SET_USER_ANSWER_DECIMAL_MUTATION,
    setUserAnswerDropDownList: SET_USER_ANSWER_DROPDOWN_LIST_MUTATION,
    setUserAnswerMultiLineText: SET_USER_ANSWER_MULTI_LINE_TEXT_MUTATION,
    setUserAnswerMultiResponseText: SET_USER_ANSWER_MULTI_RESPONSE_TEXT_MUTATION,
    setUserAnswerNumber: SET_USER_ANSWER_NUMBER_MUTATION,
    setUserAnswerRadio: SET_USER_ANSWER_RADIO_MUTATION,
    setUserAnswerSingleLineText: SET_USER_ANSWER_SINGLE_LINE_TEXT_MUTATION,
    setUserAnswerSlider: SET_USER_ANSWER_SLIDER_MUTATION,
    setUserAnswerRating: SET_USER_ANSWER_RATING_MUTATION,
  }),
});

export { CONTENT_PAGE_CONTEXT_GRAPHQL };
