import { gql } from '@apollo/client';

export const GET_USER_FLOW_ACCESS_MODE_QUERY = gql`
  query getUserFlowAccessMode($groupId: Int!) {
    userFlowAccessMode {
      getUserFlowAccessMode: get(groupId: $groupId) {
        mode
      }
    }
  }
`;
