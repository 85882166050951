import React from 'react';

import { Icon, SettingsButton } from '@netfront/ui-library';
import cx from 'classnames';

import LogoAvatar from '../../../../components/LogoAvatar/LogoAvatar';
import './DirectoryTable.css';

export const getDirectoryTableData = (directories, onEdit) =>
  directories.map((directory) => {
    const { id, title, url, phoneNumber, status, asset } = directory;

    const { presignedUrl } = asset ?? {};
    const statusClassNames = cx(`c-status-icon--${status.toLowerCase()}`);

    return {
      title: (
        <div className="h-display-inline-block">
          <div className="h-flex h-align-items-center h-flex-gap">
            <LogoAvatar logoUrl={presignedUrl} title={title} />
            {title}
          </div>
        </div>
      ),
      link: url ? (
        <a className="c-link" href={url}>
          {url}
        </a>
      ) : (
        <Icon className="c-minus-icon" id="id_minus_icon" />
      ),
      phone: phoneNumber ? (
        <a href={`tel:${phoneNumber.replace(/\s/g, '')}`}>{phoneNumber}</a>
      ) : (
        <div>
          <Icon className="c-minus-icon" id="id_minus_icon" />
        </div>
      ),
      status: (
        <button className="c-status-button" type="button" onClick={() => onEdit(directory, true)}>
          <span className={statusClassNames} />
          {status}
        </button>
      ),
      settingsButton: <SettingsButton onClick={() => onEdit(directory)} />,
      id,
    };
  });
