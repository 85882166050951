import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

export const GET_APP_SNIPPET_DETAILS = gql`
  query GET_APP_DETAILS($appId: Int!) {
    app {
      getAppDetails(inputType: { appId: $appId }) {
        id
        title
        versions {
          status
          version
          components {
            id
            title
            description
            files {
              id
              locationId
              fileType
            }
          }
        }
      }
    }
  }
`;

export default function GetAppSnippetDetails(props) {
  const { id, onError, onSuccess } = props;

  return (
    <Query query={GET_APP_SNIPPET_DETAILS} variables={{ appId: id }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) onError(error);

        if (data) {
          onSuccess(data.app.getAppDetails);
        }

        return null;
      }}
    </Query>
  );
}

GetAppSnippetDetails.propTypes = {
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
