import React from 'react';

import cx from 'classnames';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { Table, Tbody, Th, Tr } from 'react-super-responsive-table';
import { useTable, useSortBy } from 'react-table';

import downSortIcon from '../../../static/img/down-sort-icon.svg';
import upSortIcon from '../../../static/img/up-sort-icon.svg';
import { createKey } from '../../../utils';
import { EKTableFilterBar } from '../../EKTableFilterBar/EKTableFilterBar';
import { NO_RECORDS_FOUND } from '../../Social/Moderation/Moderation.constants';
import Pagination from '../../UI/Pagination/Pagination';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './EKReactTable.scss';

const EKReactTable = ({ columns, data, errorMessage, filter, pagination }) => {
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const {
    date,
    dropdown,
    isApplied: isFilterApplied = false,
    isDisabled: isFilteringDisabled = false,
    onApplyFiltersHandler,
    onClearFiltersHandler,
    searchInput,
  } = filter || {};

  const { isDisabled: isPaginationDisabled, onLoadMoreHandler, onPageSizeChangeHandler, pageSize } = pagination || {};

  const hasData = !isEmpty(data);
  const hasErrorMessage = Boolean(errorMessage);

  const isNoTableDataComponentVisible = !hasData && !hasErrorMessage;
  const isTableComponentVisible = hasData && !hasErrorMessage;

  return (
    <>
      {filter && (
        <EKTableFilterBar
          date={date}
          dropdown={dropdown}
          isDisabled={isFilteringDisabled}
          isFilterApplied={isFilterApplied}
          searchInput={searchInput}
          onApplyFiltersHandler={onApplyFiltersHandler}
          onClearFiltersHandler={onClearFiltersHandler}
        />
      )}
      {hasErrorMessage && <div className="c-error-message__container">{errorMessage}</div>}
      {isNoTableDataComponentVisible && <div className="c-no-table-data-message__container">{NO_RECORDS_FOUND}</div>}
      {isTableComponentVisible && (
        <div className="c-table-wrapper">
          <Table className="c-table" {...getTableProps()}>
            {/* TODO: Work out why Thead from react-super-responsive-table doesn't work */}
            <thead className="c-table-header">
              {headerGroups.map((headerGroup) => (
                <Tr className="c-table-row h-animation-fade-in" {...headerGroup.getHeaderGroupProps()}>
                  <Th className="c-table-row-th-spacer" />
                  {headerGroup.headers.map((column) => {
                    const { className, isSorted, isSortedDesc, style, width } = column;
                    return (
                      <Th
                        {...column.getHeaderProps([
                          {
                            className: cx(className, 'c-table-row-th'),
                            style,
                            width,
                          },
                        ])}
                        {...column.getSortByToggleProps()}
                      >
                        {column.render('Header')}
                        {isSorted && (
                          <span className="c-table-row-th-sort">
                            {isSortedDesc ? (
                              <img alt="Sort descending" src={downSortIcon} width="15" />
                            ) : (
                              <img alt="Sort ascending" src={upSortIcon} width="15" />
                            )}
                          </span>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </thead>
            <Tbody className="c-table-body" {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr className="c-table-row h-animation-fade-in" {...row.getRowProps()}>
                    <td className="c-table-row-cell-spacer" />
                    {row.cells.map((cell, index) => {
                      const {
                        column: { Header, className },
                      } = cell;
                      const isMobileHeading = Header === '';

                      return (
                        <td
                          key={`table-row-${createKey(String(index))}`}
                          className={`${className} c-table-row-cell`}
                          data-hr={isMobileHeading ? '' : `${Header}:`}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      )}
      {pagination && (
        <Pagination
          isDisabled={isPaginationDisabled}
          pageSize={pageSize}
          onLoadMoreHandler={onLoadMoreHandler}
          onPageSizeChangeHandler={onPageSizeChangeHandler}
        />
      )}
    </>
  );
};

EKReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorMessage: PropTypes.string,
  filter: PropTypes.shape({
    date: PropTypes.shape({
      dates: PropTypes.shape({
        endDate: PropTypes.instanceOf(Date).isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
      }).isRequired,
      onDateChangeHandler: PropTypes.func.isRequired,
    }),
    dropdown: PropTypes.shape({
      items: PropTypes.instanceOf(Array).isRequired,
      onDropDownChangeHandler: PropTypes.func.isRequired,
      selectedValue: PropTypes.string.isRequired,
    }),
    isApplied: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onApplyFiltersHandler: PropTypes.func,
    onClearFiltersHandler: PropTypes.func,
    searchInput: PropTypes.shape({
      onSearchInputChangeHandler: PropTypes.func.isRequired,
      searchInputValue: PropTypes.string.isRequired,
    }),
  }),
  pagination: PropTypes.shape({
    onLoadMoreHandler: PropTypes.func.isRequired,
    onPageSizeChangeHandler: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    isDisabled: PropTypes.bool,
  }),
};

export { EKReactTable };
