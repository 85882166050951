import React, { useState } from 'react';

import gql from 'graphql-tag';
import CsvDownload from 'react-json-to-csv';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { TableContainer, PushRight } from '../OrganisationUsersPage/styled';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import ExecuteReport from '../../middleware/Report/executeReportRequest';
import GetReport from '../../middleware/Report/getReport';
import GetReportTypesAvailable from '../../middleware/Report/getReportTypesAvailable';

const breadPath = [
  {
    id: 1,
    path: `../reporting`,
    title: 'Reporting',
  },
];
const ReportViewerPage = props => {
  const {
    match: { params },
  } = props;

  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [isReportTypesLoaded, setIsReportTypesLoaded] = useState(false);
  const [report, setReport] = useState(undefined);
  const [mutation, setMutation] = useState(undefined);
  const [mustPerformExecute, setMustPerformExecute] = useState(false);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [reportData, setReportData] = useState(undefined);
  const [headers, setHeaders] = useState(undefined);

  const handleExecuteReport = fragment => {
    const fieldsString = report.queriedFields.map(c => c.value).join(' ');

    setMutation(
      gql(
        `mutation executeReport($reportId: Int!)      {       report{         executeReport(reportId:$reportId) {             ... on ${fragment}{ ${fieldsString} }        }       }    }`
      )
    );
    setMustPerformExecute(true);
  };

  const handleReportExecuted = data => {
    setMustPerformExecute(false);
    setReportData(data);
    data.forEach(e => {
      delete e.__typename;
    });

    setHeaders((data && [0]) || []);
    setIsDownloadReady(true);
  };
  const handleGetReport = data => {
    setIsReportLoaded(true);
    setReport(data);
  };

  const handleGetReportTypes = data => {
    setIsReportTypesLoaded(true);
    const items = [];
    data.map(item => {
      const type = {};
      type.value = item.description;
      type.label = item.name;
      items.push(type);
      return items;
    });

    handleExecuteReport(items.find(e => e.label === report.pivot).value);
  };

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="Export the data of your report"
        pageDescription="Report"
        pageTitle="Report viewer"
        params={params}
      >
        <ProjectNavBar title="Library" />
        {isDownloadReady && (
          <PushRight>
            <CsvDownload
              data={reportData}
              style={{
                backgroundColor: '#4bc1d5',
                border: '1px solid #4bc1d5',
                display: 'inline-block',
                cursor: 'pointer',
                color: '#fff',
                fontSize: '15px',
                fontWeight: 'bold',
                padding: '6px 24px',
                textDecoration: 'none',
              }}
            />
          </PushRight>
        )}
        {isDownloadReady && reportData !== undefined && (
          <>
            <TableContainer>
              <Thead>
                <Tr key="table-head">
                  {headers.map(h => (
                    <Th
                      data-sort="firstname"
                      scope="col"
                      style={{ paddingLeft: '2.3em' }}
                    >
                      {h}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {reportData.length !== 0 &&
                  reportData.map(rd => (
                    <Tr>
                      {headers.map(h => (
                        <Td>{rd[h]}</Td>
                      ))}
                    </Tr>
                  ))}
              </Tbody>
            </TableContainer>
          </>
        )}
      </PageWrapper>
      {!isReportLoaded && (
        <GetReport
          onError={() => setIsReportLoaded(true)}
          onSuccessResult={handleGetReport}
          projectId={params.projectId}
          reportId={params.reportId}
        />
      )}
      {!isReportTypesLoaded && isReportLoaded && (
        <GetReportTypesAvailable
          onError={() => setIsReportTypesLoaded(true)}
          onSuccessResult={handleGetReportTypes}
        />
      )}
      {mustPerformExecute && mutation != null && (
        <ExecuteReport
          mutation={mutation}
          onError={() => setMustPerformExecute(false)}
          onSuccessResult={handleReportExecuted}
          reportId={params.reportId}
        />
      )}
    </>
  );
};

ReportViewerPage.propTypes = {};

export default ReportViewerPage;
