import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import NoDataAlerts from '../../components/UI/NoDataComp/NoDataAlert';
import CopyIcon from '../../static/img/copyIcon.svg';
import { ContainerScroll } from '../OrganisationUsersPage/styled';
import {
  RoundButton,
  StyledTableBase,
  StyledTBodyBordered,
  StyledTdBase,
  StyledTdHorizontalCentered,
  StyledTdRightAligned,
  StyledTdSpacer,
  StyledThBase,
  StyledThCentered,
  StyledTHeadBordered,
  StyledThRightAligned,
  StyledThSpacer,
  StyledTrBase
} from '../styles';

const BaseButton = styled.button`
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;

  &:focus {
    outline: none;
  }
`;

const CopyButton = styled(BaseButton)`
  background-image: url(${CopyIcon});
`;

const DiscountTable = props => {
  const { discounts, onCopy, onEdit } = props;

  // TODO: Get the status values from GraphQL
  const filteredDiscounts = discounts.filter(discount => discount.status.toUpperCase() === 'ACTIVE');
  const hasDiscounts = Boolean(filteredDiscounts.length);

  return (
    <ContainerScroll>
      {!hasDiscounts && <NoDataAlerts />}
      {hasDiscounts && (
        <StyledTableBase>
          <StyledTHeadBordered>
            <StyledTrBase key="table-head">
              <StyledThSpacer />
              <StyledThBase data-sort="description" scope="col">
                Title
              </StyledThBase>
              <StyledThBase data-sort="code" scope="col">
                Discount code
              </StyledThBase>
              <StyledThCentered data-sort="status" scope="col">
                Status
              </StyledThCentered>
              <StyledThCentered data-sort="startsAtUtc" scope="col">
                Start date
              </StyledThCentered>
              <StyledThCentered data-sort="expiresAtUtc" scope="col">
                Expiry date
              </StyledThCentered>
              <StyledThCentered scope="col">Copy</StyledThCentered>
              <StyledThRightAligned scope="col"></StyledThRightAligned>
            </StyledTrBase>
          </StyledTHeadBordered>
          <StyledTBodyBordered>
            {filteredDiscounts.map(discount => (
              <StyledTrBase key={`discount-${discount.id}`}>
                <StyledTdSpacer />
                <StyledTdBase>{discount.description}</StyledTdBase>
                <StyledTdBase>{discount.code}</StyledTdBase>
                <StyledTdHorizontalCentered>{discount.status}</StyledTdHorizontalCentered>
                <StyledTdHorizontalCentered>{format(new Date(discount.startsAtUtc), 'dd-MM-yyyy')}</StyledTdHorizontalCentered>
                <StyledTdHorizontalCentered>{format(new Date(discount.expiresAtUtc), 'dd-MM-yyyy')}</StyledTdHorizontalCentered>
                <StyledTdHorizontalCentered>
                  <CopyButton onClick={() => onCopy(discount)} />
                </StyledTdHorizontalCentered>
                <StyledTdRightAligned>
                  <RoundButton onClick={() => onEdit(discount)}>···</RoundButton>
                </StyledTdRightAligned>
              </StyledTrBase>
            ))}
          </StyledTBodyBordered>
        </StyledTableBase>
      )}
    </ContainerScroll>
  );
};

DiscountTable.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCopy: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default DiscountTable;
