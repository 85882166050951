import React from 'react';

import cx from 'classnames';

import { SpacerSnippetProps } from './SpacerSnippet.types';

import './SpacerSnippet.css';

const SpacerSnippet = ({ alignment = 'LEFT', height, lineBreak = false, widthPercentage }: SpacerSnippetProps) => {
  const remHeight = height ? `${height / 16}rem` : undefined;
  const width = widthPercentage ? `${widthPercentage}%` : undefined;

  return (
    <div
      aria-hidden={!lineBreak ? true : undefined}
      className={cx('c-spacer-snippet', `c-spacer-snippet--align-${alignment.toLowerCase()}`, {
        'c-spacer-snippet--has-line-break': lineBreak,
      })}
      style={{
        /* stylelint-disable-line value-keyword-case */
        height: remHeight,
      }}
    >
      {lineBreak && (
        <hr
          className="c-spacer-snippet__line-break"
          style={{
            width,
          }}
        />
      )}
    </div>
  );
};

export { SpacerSnippet };
