import styled from 'styled-components';

export const DropDownMenu = styled.ul`
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid;
  border-color: rgba(0, 42, 76, 0.1);
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 42, 76, 0.15);
  color: #303941;
  display: ${({ open }) => (open ? 'block' : 'none')};
  font-size: 16px;
  left: 14%;
  list-style-type: none;
  min-width: 150px;
  padding: 0;
  position: absolute;
  top: 120%;
  transform: translateX(-63%);
  transition: 0.2s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    transform: rotate(45deg) translateX(-50%);
    background-color: #fff;
    top: -3px;
    left: 66%;
    height: 1em;
    width: 1em;
    border-left: 1px solid;
    border-top: 1px solid;
    border-color: rgba(0, 42, 76, 0.1);
  }

  ul {
    padding: 0;
  }

  li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;
    text-align: left;
    transition: 0.2s ease-in-out;
    white-space: nowrap;

    &:hover {
      background-color: #e1e1e1;
    }

    ul {
      padding: 0;

      li {
        background-color: rgba(0, 42, 76, 0.1);
      }
    }

    span {
      color: #a6adb4;
      font-size: 0.8em;
    }
  }

  a {
    color: #303941;

    &:hover {
      color: #303941;
    }
  }
`;
