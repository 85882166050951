import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckBoxWrapper = styled.div`
  position: relative;
  margin-top: 0.5em;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 24px;
  border-radius: 15px;
  border: 1px solid #e4e4e3;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: -1px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  border-radius: 15px;
  cursor: pointer;
  height: 24px;
  opacity: 0;
  position: relative;
  width: 48px;
  z-index: 1;
  &:checked + ${CheckBoxLabel} {
    background: #4bc1c9;
    &::after {
      border-radius: 50%;
      content: '';
      display: block;
      height: 24px;
      margin-left: 23px;
      transition: 0.2s;
      width: 24px;
    }
  }
`;

const SwitchButton = (props) => {
  const { defaultChecked, id, name, onChange, value } = props;
  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = (e) => {
    setChecked(!checked);
    onChange(!checked, e);
  };

  return (
    <CheckBoxWrapper>
      <CheckBox
        checked={value !== undefined ? value : checked}
        id={`checkbox${id}`}
        name={name}
        onChange={(e) => handleChange(e)}
        type="checkbox"
      />
      <CheckBoxLabel htmlFor={`checkbox${id}`} />
    </CheckBoxWrapper>
  );
};

SwitchButton.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  defaultChecked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/boolean-prop-naming
  value: PropTypes.bool,
};

SwitchButton.defaultProps = {
  defaultChecked: false,
  id: '',
  name: '',
  onChange: () => {},
  value: undefined,
};

export default SwitchButton;
