import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import GeladaClient from '../_Gelada/geladaClient';
import QueryRequest from '../QueryRequest';

export const GET_INVITATION_DETAILS_REQUEST = gql`
  query getInvitationDetailRequest($token: String!) {
    getInvitation(token: $token) {
      id
      invitedUserEmail: emailUserInvited
      firstName
      lastName
      userExists
      type
      invitedUserId
      project {
        name
        guid
      }
      organisation {
        name
        id
      }
      group {
        name
      }
    }
  }
`;

const getInvitationDetailRequest = ({ token, onError, onSuccessResult }) => (
  <Query
    client={GeladaClient(false)}
    query={GET_INVITATION_DETAILS_REQUEST}
    variables={{ token }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.getInvitation}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getInvitationDetailRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default getInvitationDetailRequest;
