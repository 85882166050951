import gql from 'graphql-tag';

export const CREATE_COMMENT = gql`
  mutation createComment($postId: Int, $message: String) {
    comment {
      create(request: { postId: $postId, message: $message }) {
        author {
          displayedName
        }
        id
        authorId
        author {
          firstName
          lastName
        }
        message
        createdDate
        createdDateTime
      }
    }
  }
`;
