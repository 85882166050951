import AWS from 'aws-sdk';

const credentials = {
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

AWS.config.update({ credentials, region: process.env.REACT_APP_AWS_REGION });

const s3 = new AWS.S3();

export default s3;
