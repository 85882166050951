import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_PAGES_TYPES = gql`
  query getTypes($projectId: String!) {
    contentPage {
      getContentPageTypes(projectId: $projectId) {
        id
        name
      }
    }
  }
`;

const getContentPageTypes = ({ projectId, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_PAGES_TYPES}
    variables={{ projectId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.contentPage.getContentPageTypes
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getContentPageTypes.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

// getContentPageTypes.defaultProps = {
//   contentPageId: null,
// };

export default getContentPageTypes;
