import React from 'react';

import cx from 'classnames';

import { InputPromptProps } from './InputPrompt.types';

import './InputPrompt.css';

const InputPrompt = ({ text, isInvalid = false, isCorrect = false }: InputPromptProps) => (
  <div className={cx('c-input-prompt', { 'is-invalid': isInvalid, 'is-correct': isCorrect })}>
    <span className="c-input-prompt__text">{text}</span>
  </div>
);

export { InputPrompt };
