import React, { Component } from 'react';

import Axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ActionHeading, ImageDropContainer } from '../MarketplacePage/styled';
import { versionStatus } from '../VersionListPage/versionStatus';

import Button from '../../components/Buttons/Button';
import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import ConsoleHeader from '../../components/Header/ConsoleHeader';
import InfoBanner from '../../components/InfoBanner/InfoBanner';
import MyAppDetailPageSideBar from '../../components/MyAppDetailPageSideBar/MyAppDetailPageSideBar';
import Preloader from '../../components/Preloader/Preloader';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import AppContext from '../../context/AppContext';
import CreateAppAssetRequest from '../../middleware/AppAssets/createAppAssetRequest';
import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateFilesIntoComponentRequest from '../../middleware/MyAppDashboard/createFilesIntoComponentRequest';
import GetMyAppComponentDetailRequest from '../../middleware/MyAppDashboard/getMyAppComponentDetailRequest';

const AppDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em 0 2em 0;
`;

const AppOverview = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;

    img {
      margin-right: 1em;
    }

    h5 {
      margin: 0 0 0 0;
    }
  }

  h5 {
    font-weight: 600;
    margin: 1em 0 0 0;
  }

  img {
    max-width: 160px;
  }
`;

//   background-color: #4bc1c9;
//   border: none;
//   border-radius: 6px;
//   color: #fff;
//   height: 2.28571em;
//   line-height: 2.28571em;
//   padding: 0 12px;
//   outline: none;
// `;

const ActionContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0.6em 0 0 0;

  button + button {
    margin-left: 0.4em;
  }
`;
class AppBuildPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationId: null,
      projectId: null,
      fileHtml: '',
      fileCss: '',
      fileJs: '',
      createHtmlAsset: false,
      createJsAsset: false,
      htmlAssetId: '',
      jsAssetId: '',
      getHtmlAssetSent: true,
      getJsAssetSent: true,
      loader: false,
      cssContent: '',
      htmlContent: '',
      jsContent: '',
      getComponentDetailRequest: false,
      appComponentId: '',
      createFilesIntoComponentRequest: false,
      htmlAssetCreated: false,
      jsAssetCreated: false,
      cssAssetCreated: false,
      createCssAsset: false,
      getCssAssetSent: true,
      appTitle: '',
      componentTitle: '',
      assetId: '',
      getAssetSent: true,
      fileUrl: '',
      isPanelOpen: false,
      sendingAppAssetRequest: false,
      imagePreview: '',
      files: [],
      vID: null,
    };
    this.iframRef = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { org: orgId, projectId } = params;

    const { versionId } = params;

    this.setState({
      organisationId: Number(orgId),
      projectId,
      vID: Number(versionId),
    });
  }

  handleOrgError = () => {};

  handleProjectError = () => {};

  handleGetOrganisation = (name) => {
    const { updateOrgName } = this.context;
    updateOrgName(name);
  };

  handleGetProject = (name) => {
    const { updateProjectName } = this.context;
    updateProjectName(name);
  };

  handleInputs = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleClearErrorMessages = () => {
    const { errorMessage } = this.state;
    if (errorMessage !== '') {
      this.setState({
        errorMessage: '',
      });
    }
  };

  handleAssetError = () => {
    this.setState({
      createHtmlAsset: false,
      createCssAsset: false,
      createJsAsset: false,
      getAssetSent: false,
    });
  };

  handleHtmlAssetResponse = (signedUrl, asset) => {
    const { fileHtml } = this.state;
    this.setState({
      createHtmlAsset: false,
      loader: true,
      createJsAsset: true,
    });
    Object.defineProperty(fileHtml, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, fileHtml, { headers: { 'content-type': fileHtml.type } })
      .then(() => {
        this.setState({
          // createMyAppRequest: true,
          htmlAssetCreated: true,
          htmlAssetId: asset.assetId,
          loader: false,
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  };

  handleJsAssetResponse = (signedUrl, asset) => {
    const { fileJs } = this.state;

    this.setState({
      createJsAsset: false,
      loader: true,
      createCssAsset: true,
    });
    Object.defineProperty(fileJs, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, fileJs, { headers: { 'content-type': fileJs.type } })
      .then(() => {
        this.setState({
          // createMyAppRequest: true,
          jsAssetCreated: true,
          jsAssetId: asset.assetId,
          loader: false,
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  };

  handleCssAssetResponse = (signedUrl, asset) => {
    const { fileCss } = this.state;
    this.setState({
      createCssAsset: false,
      loader: true,
    });
    Object.defineProperty(fileCss, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, fileCss, { headers: { 'content-type': fileCss.type } })
      .then(() => {
        this.setState({
          // createMyAppRequest: true,
          cssAssetCreated: true,
          cssAssetId: asset.assetId,
          loader: false,
          createFilesIntoComponentRequest: true,
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  };

  handleOnSaveComponent = () => {
    const { htmlContent, cssContent, jsContent } = this.state;
    const fileHtml = new File([htmlContent], 'foo.html', {
      type: 'text/html',
    });
    const fileCss = new File([cssContent], 'foo.css', {
      type: 'text/css',
    });
    const fileJs = new File([jsContent], 'foo.js', {
      type: 'text/javascript',
    });
    this.setState({
      fileHtml,
      fileCss,
      fileJs,
      createHtmlAsset: true,
    });
  };

  handleGetAssetError = () => {
    this.setState({
      getHtmlAssetSent: true,
    });
  };

  handleGetHtmlAssetResponse = (s3Key, contentType) => {
    this.setState({
      getHtmlAssetSent: true,
      getJsAssetSent: false,
    });
    this.readTextFile(getSignedUrl(s3Key, contentType), 'htmlContent');
  };

  handleGetJsAssetResponse = (s3Key, contentType) => {
    this.setState({
      getJsAssetSent: true,
      getCssAssetSent: false,
    });
    this.readTextFile(getSignedUrl(s3Key, contentType), 'jsContent');
  };

  handleGetCssAssetResponse = (s3Key, contentType) => {
    this.setState({
      getCssAssetSent: true,
    });
    this.readTextFile(getSignedUrl(s3Key, contentType), 'cssContent');
  };

  readTextFile = (file, type) => {
    const rawFile = new XMLHttpRequest();
    rawFile.open('GET', file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          const allText = rawFile.responseText;
          this.setState({
            [type]: allText,
          });
        }
      }
    };
    rawFile.send(null);
  };

  handleGetComponentDetailError = () => {
    this.setState({
      getComponentDetailRequest: false,
    });
  };

  handleGetComponentDetailResponse = (data) => {
    this.setState({
      getComponentDetailRequest: false,
      htmlContent: '',
      jsContent: '',
      cssContent: '',
    });
    if (data.files.length) {
      this.setState({
        htmlAssetId: data.files[0].locationId,
        jsAssetId: data.files[1].locationId,
        cssAssetId: data.files[2].locationId,
        getHtmlAssetSent: false,
      });
    }
  };

  handleCreateFileOnError = () => {
    this.setState({
      createFilesIntoComponentRequest: false,
    });
  };

  handleCreateFileOnResponse = () => {
    this.setState({
      createFilesIntoComponentRequest: false,
    });
  };

  handleOnCreateComponentResponse = () => {
    this.setState({
      htmlContent: '',
      jsContent: '',
      cssContent: '',
    });
  };

  handleOnGetVersionDetail = (appTitle, appVersion, assetId) => {
    this.setState({
      appTitle,
      assetId,
    });
  };

  handleOnClickComponent = (id, title) => {
    this.setState({
      appComponentId: id,
      componentTitle: title,
      getComponentDetailRequest: true,
    });
  };

  renderStatusComponent = (status = '', publishStatus = '') => (
    <div style={{ padding: '2em 0 1em 0' }}>
      <span
        className={classnames(
          'c-tag',
          {
            'c-tag-danger is-filled': status.toUpperCase() === versionStatus.REJECTED,
          },
          {
            'c-tag-success is-filled': status.toUpperCase() === versionStatus.ACCEPTED,
          },
          {
            'c-tag-info is-filled': status.toUpperCase() === versionStatus.DRAFT,
          },
          {
            'c-tag-info is-filled': status.toUpperCase() === versionStatus.PENDING,
          },
        )}
      >
        {status}
      </span>
      {publishStatus === 'PUBLISHED' ? (
        <span className="c-tag c-tag-info ml-1 is-filled">{publishStatus}</span>
      ) : (
        <span className="c-tag c-tag-danger ml-1 is-filled">{publishStatus}</span>
      )}
    </div>
  );

  handleOnClickVersions = () => {
    const {
      history,
      match: {
        params: { appId },
      },
    } = this.props;
    const { organisationId, projectId } = this.state;
    history.push(`/dashboard/${organisationId}/${projectId}/myApps/${appId}/versions`);
  };

  handleRightPanel = () => {
    const { isPanelOpen } = this.state;
    this.setState({
      isPanelOpen: !isPanelOpen,
    });
  };

  handlePanelOnClose = () => {
    this.setState({
      isPanelOpen: false,
    });
  };

  handleOnDrop = (image) => {
    this.setState({
      files: image[0],
      imagePreview: URL.createObjectURL(image[0]),
      sendingAppAssetRequest: true,
    });
  };

  handleAssetResponse = (signedUrl, asset) => {
    const { files } = this.state;

    this.setState({
      loader: true,
      sendingAppAssetRequest: false,
    });

    Object.defineProperty(files, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, files, { headers: { 'content-type': files.type } })
      .then(() => {
        this.setState({
          // assetId: asset.assetId,
          loader: false,
          // sendingAppAssetRequest: false
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
          sendingAppAssetRequest: false,
        });
      });
  };

  handleOnSubmit = () => {
    this.setState({
      sendingAppAssetRequest: true,
    });
  };

  render() {
    const {
      cssContent,
      htmlContent,
      jsContent,
      createHtmlAsset,
      loader,
      getHtmlAssetSent,
      getComponentDetailRequest,
      appComponentId,
      fileHtml,
      fileCss,
      fileJs,
      htmlAssetId,
      cssAssetId,
      jsAssetId,
      createJsAsset,
      createCssAsset,
      createFilesIntoComponentRequest,
      htmlAssetCreated,
      jsAssetCreated,
      cssAssetCreated,
      getJsAssetSent,
      getCssAssetSent,
      appTitle,
      componentTitle,
      assetId,
      getAssetSent,
      fileUrl,
      isPanelOpen,
      sendingAppAssetRequest,
      imagePreview,
      files,
      vID,
    } = this.state;
    const {
      match: {
        params: { appID, versionId },
      },
    } = this.props;
    const iframe = this.iframRef;
    let document = '';
    if (iframe.current) {
      document = iframe.current.contentDocument;
    }

    const documentContents = `
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <title>Document</title>
        <style>
          ${cssContent}
        </style>
        <script>
          ${jsContent}
        </script>
      </head>
      <body>
          ${htmlContent}
      </body>
      </html>
    `;
    if (document) {
      document.open();
      document.write(documentContents);
      document.close();
    }

    return (
      <div>
        <ConsoleHeader />

        <MyAppDetailPageSideBar
          appName={appTitle}
          versionId={Number(versionId)}
          onClickComponent={this.handleOnClickComponent}
          onComponentCreate={this.handleOnCreateComponentResponse}
          onVersionDetailResponse={this.handleOnGetVersionDetail}
        />

        <ConsoleContainer>
          <InfoBanner text="Build a new app" />

          {!appComponentId && <span>Add a new component to begin editing</span>}
          {appComponentId && (
            <>
              <AppDetails>
                <AppOverview>
                  <img alt="app avatar" src={fileUrl || 'https://dev.assets.ekardo.com//logo/logo-dark.svg'} />
                  <div style={{ flexGrow: 1 }}>
                    <h5>{componentTitle}</h5>
                    <small>by Nefront</small>
                    <ActionContainer>
                      <Link to={`/marketplace/app/${appID}/versions`}>
                        <Button>Versions</Button>
                      </Link>

                      <div style={{ marginLeft: 'auto' }}>
                        <Button aria-haspopup="true" onClick={this.handleRightPanel}>
                          Assets
                        </Button>
                      </div>
                    </ActionContainer>
                  </div>
                </AppOverview>
              </AppDetails>

              <div className="row">
                <div className="col-md-4">
                  <div className="c-editor">
                    <div className="c-editor-header">HTML</div>
                    <div className="c-editor-body  p-0 overflow-hidden">
                      <textarea
                        aria-label="Name"
                        className="w-100 bg-transparent c-editor-body outline-none border-none"
                        name="htmlContent"
                        required=""
                        value={htmlContent}
                        onChange={this.handleInputs}
                        onClick={this.handleClearErrorMessages}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="c-editor">
                    <div className="c-editor-header">CSS</div>
                    <div className="c-editor-body p-0 overflow-hidden">
                      <textarea
                        aria-label="Name"
                        className="w-100 bg-transparent c-editor-body outline-none border-none"
                        name="cssContent"
                        required=""
                        value={cssContent}
                        onChange={this.handleInputs}
                        onClick={this.handleClearErrorMessages}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="c-editor">
                    <div className="c-editor-header">JS</div>
                    <div className="c-editor-body p-0 overflow-hidden">
                      <textarea
                        aria-label="Name"
                        className="w-100 bg-transparent c-editor-body outline-none border-none"
                        name="jsContent"
                        required=""
                        value={jsContent}
                        onChange={this.handleInputs}
                        onClick={this.handleClearErrorMessages}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="c-editor">
                    <div className="c-editor-header">Preview</div>
                    <div className="c-editor-body c-editor-preview" id="htmlContent">
                      <iframe ref={this.iframRef} className=" border-none" title="result" />
                    </div>
                  </div>
                </div>

                <div className="col-12 text-right mb-3">
                  <button className="button--black px-4 mr-3" type="submit">
                    Cancel
                  </button>
                  <button className="button--blue px-4" type="submit" onClick={this.handleOnSaveComponent}>
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        </ConsoleContainer>

        <SideDrawer isOpen={isPanelOpen} onClose={this.handlePanelOnClose}>
          <ActionHeading>Assets</ActionHeading>

          <ImageDropContainer name="thumbnail">
            <Dropzone accept="image/*" className="dropzone" multiple={false} noKeyboard onDrop={this.handleOnDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {!sendingAppAssetRequest && (
                    <>
                      {isDragActive ? 'Drop now' : 'Drop image here or click to upload'}
                      <p>
                        (<strong>Maximum</strong> 1 file)
                      </p>
                    </>
                  )}

                  {sendingAppAssetRequest && <img alt="App thumbnail" src={imagePreview} />}
                </div>
              )}
            </Dropzone>
          </ImageDropContainer>

          <Button styles="justify-content: center;" onClick={() => this.handleOnSubmit()}>
            Add to app
          </Button>
        </SideDrawer>

        {createHtmlAsset && (
          <CreateAppAssetRequest
            appVersionId={vID}
            contentType={fileHtml.type}
            fileName={fileHtml.name}
            fileSizeInBytes={fileHtml.size}
            type="DOCUMENT"
            onError={this.handleAssetError}
            onSuccessResult={this.handleHtmlAssetResponse}
          />
        )}

        {createJsAsset && (
          <CreateAppAssetRequest
            appVersionId={vID}
            contentType={fileJs.type}
            fileName={fileJs.name}
            fileSizeInBytes={fileJs.size}
            type="DOCUMENT"
            onError={this.handleAssetError}
            onSuccessResult={this.handleJsAssetResponse}
          />
        )}

        {createCssAsset && (
          <CreateAppAssetRequest
            appVersionId={vID}
            contentType={fileCss.type}
            fileName={fileCss.type}
            fileSizeInBytes={fileCss.size}
            type="DOCUMENT"
            onError={this.handleAssetError}
            onSuccessResult={this.handleCssAssetResponse}
          />
        )}

        {/* {sendingAppAssetRequest && (
          <CreateAppAssetRequest
            contentType={files.type}
            fileName={files.name}
            fileSizeInBytes={files.size}
            onError={err => err}
            onSuccessResult={this.handleAddAsset}
            appVersionId={vID}
            type="IMAGE"
          />
        )} */}

        {/* {sendingAppAssetRequest && (
            <CreateAppAssetRequest
                contentType={files.type}
                fileName={files.name}
                fileSizeInBytes={files.size}
                onError={err => err}
                onSuccessResult={this.handleAssetResponse}
                appVersionId={vID}
                type="IMAGE"
              />
          )} */}

        {sendingAppAssetRequest && (
          <CreateAppAssetRequest
            appVersionId={vID}
            contentType={files.type}
            fileName={files.name}
            fileSizeInBytes={files.size}
            type="IMAGE"
            onError={this.handleAssetError}
            onSuccessResult={this.handleAssetResponse}
          />
        )}

        {!getHtmlAssetSent && (
          <GetAsset assetId={htmlAssetId} onError={this.handleGetAssetError} onSuccessResult={this.handleGetHtmlAssetResponse} />
        )}
        {!getJsAssetSent && (
          <GetAsset assetId={jsAssetId} onError={this.handleGetAssetError} onSuccessResult={this.handleGetJsAssetResponse} />
        )}
        {!getCssAssetSent && (
          <GetAsset assetId={cssAssetId} onError={this.handleGetAssetError} onSuccessResult={this.handleGetCssAssetResponse} />
        )}
        {getComponentDetailRequest && (
          <GetMyAppComponentDetailRequest
            appComponentId={appComponentId}
            onError={this.handleGetComponentDetailError}
            onSuccessResult={this.handleGetComponentDetailResponse}
          />
        )}
        {htmlAssetCreated && jsAssetCreated && cssAssetCreated && createFilesIntoComponentRequest && (
          <CreateFilesIntoComponentRequest
            componentId={appComponentId}
            cssLocationId={cssAssetId}
            htmlLocationId={htmlAssetId}
            jsLocationId={jsAssetId}
            onError={this.handleCreateFileOnError}
            onSuccessResult={this.handleCreateFileOnResponse}
          />
        )}
        {getAssetSent && assetId && (
          <GetAsset assetId={assetId} onError={this.handleAssetError} onSuccessResult={this.handleAssetResponse} />
        )}
        {loader && <Preloader text="Uploading Files. Please wait!" />}
      </div>
    );
  }
}
AppBuildPage.contextType = AppContext;

AppBuildPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: {
      appId: PropTypes.string.isRequired,
      org: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
      versionId: PropTypes.string.isRequired,
    },
  }).isRequired,
};

export default AppBuildPage;
