import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import AppContext from '../context/AppContext';

function QueryRequest({ error, data, onError, onSuccessResult, isLoading, refetch }) {
  const { setIsLoading } = useContext(AppContext);

  useEffect(() => {
    setIsLoading(isLoading);
    if (error) {
      if (error && error.networkError) {
        onError(error.networkError.message);
      } else if (error) {
        onError(error.message);
      }
    } else if (data) {
      onSuccessResult(data);
    }
  }, [data, error, isLoading, refetch]);

  return <></>;
}

QueryRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.shape({}), PropTypes.array]),
  error: PropTypes.shape(),
  isLoading: PropTypes.bool,
};

QueryRequest.defaultProps = {
  data: {},
  error: null,
  isLoading: false,
};

export default QueryRequest;
