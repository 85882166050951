import gql from 'graphql-tag';

export const UPDATE_QUESTION_CONFIGURATION_RADIO = gql`
  mutation updateQuestionConfigurationRadio(
    $allowUserGeneratedResponses: Boolean!
    $canModify: Boolean!
    $questionConfigurationId: Int!
  ) {
    questionConfiguration {
      updateQuestionConfiguration(
        questionConfigurationId: $questionConfigurationId
        questionConfigurationRadio: {
          allowUserGeneratedResponses: $allowUserGeneratedResponses
          canModify: $canModify
        }
      ) {
        ... on Radio {
          allowUserGeneratedResponses
        }
      }
    }
  }
`;
