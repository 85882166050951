import React from 'react';

import cx from 'classnames';

import { Skeleton } from '../Skeleton';
import { Spacing } from '../Spacing';
import { Tooltip } from '../Tooltip';

import { LabelProps } from './Label.types';

import './Label.css';

const Label = ({
  children,
  additionalClassNames,
  forId,
  isHidden,
  spacing = '2x-small',
  labelText,
  labelType = 'label',
  fontWeight = 'light',
  isLoading = false,
  hasChildren = false,
  tooltipText,
  tooltipPosition = 'end',
}: LabelProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag = labelType;

  if (isLoading && !isHidden) return <Skeleton additionalClassNames="c-label" width="10rem" />;
  return (
    <Tag
      className={cx('c-label', additionalClassNames, {
        'h-hide-visually': isHidden && !hasChildren,
        [`c-label--${fontWeight}`]: true,
      })}
      data-testid="qa-label"
      htmlFor={labelType === 'label' ? forId : ''}
    >
      <Spacing additionalClassNames="c-label__spacing" size={spacing} isFullWidth>
        <span
          className={cx('c-label__text', {
            'h-hide-visually': isHidden,
            'c-label__text--tooltip-start': tooltipPosition === 'start',
          })}
        >
          <>{labelText}</>
          {tooltipText && (
            <Tooltip
              additionalClassNames="c-label__tooltip"
              iconId="id_tooltip_icon"
              isLoading={isLoading}
              placement={tooltipPosition === 'start' ? 'right' : 'left'}
              text={tooltipText}
            />
          )}
        </span>
      </Spacing>
      {children}
    </Tag>
  );
};

export { Label };
