import React, { Fragment, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IRadioButtonItem, RadioButtonGroup } from '@netfront/ui-library';
import { handleApolloError } from 'components/Snippets/QuestionSnippet';
import { useEkardoMutation } from 'hooks';

import { ADD_FEEDBACK_RADIO_ON_CHECKBOX } from './SummaryFeedbackConfigurationMultiResponseText.graphql';
import { ISummaryFeedbackConfigurationRadioProps } from './SummaryFeedbackConfigurationRadio.interfaces';

import {
  IDBQuestionAnswerCheckboxResponseCheckbox,
  IDBQuestionResponseSet,
  IDBSummaryFeedbackConfigurationRadio,
} from '../../../../interfaces';

interface RadioGroupProps {
  answer: IDBQuestionAnswerCheckboxResponseCheckbox;
  answerId: number;
  contentSnippetSummaryId: number;
  label?: string;
  placeholders?: Record<string, string>;
  responseSet: IDBQuestionResponseSet;
}

const questionPlaceholder = '[QUESTION_RESPONSE_LABEL]';

const RadioGroup = ({ answer, answerId, responseSet, contentSnippetSummaryId, placeholders, label }: RadioGroupProps) => {
  let updatedText = label;

  if (updatedText) {
    if (answer.questionResponse?.label) {
      updatedText = updatedText.replaceAll(questionPlaceholder, answer.questionResponse.label);
    }

    if (placeholders) {
      updatedText = Object.keys(placeholders).reduce((accumulator, placeholder) => {
        if (!accumulator.includes(placeholder)) {
          return accumulator;
        }

        return accumulator.replaceAll(placeholder, placeholders[placeholder]);
      }, updatedText);
    }
  }

  const options = responseSet.availableResponses.map((r) => {
    const radio: IRadioButtonItem = {
      id: `${r.id}-${contentSnippetSummaryId}-${answer.questionResponseCheckboxId}`,
      labelText: r.label ?? '',
      value: r.id,
    };
    return radio;
  });

  const [selectedRadioId, setSelectedRadioId] = useState<number | undefined>();

  const [executeAddFeedbackRadioOnCheckbox] = useEkardoMutation({
    mutation: ADD_FEEDBACK_RADIO_ON_CHECKBOX,
    options: {
      onError: (error: ApolloError) => {
        handleApolloError(error);
      },
    },
  });

  useEffect(() => {
    setSelectedRadioId(answer.summaryFeedback?.questionResponseRadioId);
  }, [answer]);

  return (
    <div className="c-summary-snippet__container">
      {updatedText && <p>{updatedText}</p>}
      <RadioButtonGroup
        items={options}
        name=""
        selectedValue={selectedRadioId}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          const radioResponseId = Number(value);
          executeAddFeedbackRadioOnCheckbox({
            variables: {
              questionAnswerId: answerId,
              radioResponseId,
              snippetSummaryId: contentSnippetSummaryId,
              checkboxResponseId: answer.questionResponseCheckboxId,
            },
          });
          setSelectedRadioId(radioResponseId);
        }}
      />
    </div>
  );
};

const SummaryFeedbackConfigurationRadio = ({
  selectedAnswers,
  summaryFeedbackConfiguration,
  questionAnswerId,
  contentSnippetSummaryId,
  placeholders,
}: ISummaryFeedbackConfigurationRadioProps) => {
  const { responseSet, label } = summaryFeedbackConfiguration as IDBSummaryFeedbackConfigurationRadio;

  return (
    <Fragment>
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {selectedAnswers?.map((r) => (
        <RadioGroup
          key={`response-${r.id}-${contentSnippetSummaryId}`}
          answer={r}
          answerId={questionAnswerId}
          contentSnippetSummaryId={contentSnippetSummaryId}
          label={label}
          placeholders={placeholders}
          responseSet={responseSet}
        />
      ))}
    </Fragment>
  );
};

export { SummaryFeedbackConfigurationRadio };
