import React from 'react';

import PropTypes from 'prop-types';

import { Tab } from './Tab/Tab';

import { createKey } from '../../utils';

import './Tabs.scss';

function Tabs({ isInSideBar = false, items = [], selectedTabId = 0, onTabChangeHandler }) {
  const { component } = items.find(({ id }) => id === selectedTabId) || {};

  return (
    <>
      <div className="tab-panel">
        {items.map(({ id, label }) => (
          <Tab
            key={`tab-item-${createKey(label)}`}
            id={id}
            isInSideBar={isInSideBar}
            isSelected={id === selectedTabId}
            label={label}
            onTabChangeHandler={onTabChangeHandler}
          />
        ))}
      </div>
      {component}
    </>
  );
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node,
      label: PropTypes.string,
    }),
  ).isRequired,
  isInSideBar: PropTypes.bool,
  onTabChangeHandler: PropTypes.func,
  selectedTabId: PropTypes.number,
};

export { Tabs };
