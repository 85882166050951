/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import ContentContainer from '../ContentContainer/ContentContainer';
import EditableSnippet from '../ContentSnippet/EditableSnippet';
import Icon from '../Icon/Icon';
import SectionSideBar from '../RightNavBar/SectionSideBar';
import Backdrop from '../UI/Backdrop/Backdrop';
import Tooltip from '../UI/Tooltip/Tooltips';

import { ActionsSidebar } from '../../../new-ekardo';
import AppContext from '../../context/AppContext';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import AddContentContainerRequest from '../../middleware/ContentContainer/addContainerRequest';
import ActionIcon from '../../static/img/contentSnippet/actions.svg';
import AddIcon from '../../static/img/contentSnippet/add.svg';
import MoveIcon from '../../static/img/contentSnippet/advanced.svg';

const AddBtn = styled.div`
  background-color: #33878d;
  border-radius: 0 5px 5px 0;
  color: white;
  cursor: pointer;
  display: none;
  font-size: 20px;
  height: 50px;
  padding-top: 8px;
  position: absolute;
  right: -21px;
  text-align: center;
  text-decoration: none;
  top: 4.32rem;
  width: 20px;

  @media screen and (prefers-reduced-motion: reduce) {
    &:hover {
      right: -41px;
      transition: none;
      width: 40px;

      /* background-color: #44bed3; */
    }
  }

  img {
    filter: brightness(0) invert(1);
    width: 14px;
  }

  &:hover {
    right: -41px;
    transition: right width 4s linear;
    width: 40px;

    /* background-color: #44bed3; */
  }
`;

const LayoutBtn = styled(AddBtn)`
  background-color: #4bc2ca;
  top: 1.1rem;
`;

const ActionBtn = styled(AddBtn)`
  background-color: #4bc2ca;
  top: 7.51rem;
`;

const DeleteBtn = styled(AddBtn)`
  background-color: #4bc2ca;
  top: 10.75rem;
`;

const SectionContainer = styled.div`
  background-color: ${(props) => (props.isDragging ? '#e3fcef' : props.bgColor)};
  background-image: ${(props) => (props.bgImg ? `url(${props.bgImg}) ` : 'unset')};
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid;
  border-color: ${(props) => props.borderColor || 'transparent'};
  border-radius: 5px;
  margin: 1em;
  padding: 1em;
  position: relative;

  &:hover {
    background: #f6f8f9 0% 0% no-repeat padding-box;
    border: 1px solid #f4f5f7;
    ${AddBtn} {
      display: block;
    }
  }
`;

const SnippetTypeContainer = styled.div`
  /* padding: 1em;
  border: 1px solid #f4f5f7; */
  border-radius: 5px;
  margin: 1em;
`;

const InnerContainer = styled.div`
  border-radius: 5px;
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  background-color: ${({ isDraggingOver, draggingFromThisWith }) => {
    const draggingFromThisWithColor = draggingFromThisWith ? '#e6fcff' : 'transparent';
    return isDraggingOver ? '#ffebe6' : draggingFromThisWithColor;
  }};
`;

const ContentSection = (props) => {
  const {
    activePageId,
    section,
    index,
    answers,
    addContainer,
    addSnippet,
    updateSnippet,
    deleteSnippet,
    deleteContainer,
    deleteSection,
    identities,
    onRefreshPage,
    // getSelectedContainerId,
  } = props;

  const { setActionsSidebarDetails } = useContext(AppContext);

  const [showLayout, setShowLayout] = useState(false);
  const [isShowActionsSidebar, setIsShowActionsSidebar] = useState(false);
  const [containerNumber, setContainerNumber] = useState(0);
  const [LayoutId, setLayoutId] = useState(section.contentLayoutId);
  const [newContainer, setNewContainer] = useState({});
  const [createRequest, setCreateRequest] = useState(false);
  const [selectedContainerId, setSelectedContainerId] = useState(null);
  const [selectedSnippet, setSelectedSnippet] = useState(null);

  const bgUrl =
    section.background && Object.keys(section.background).length > 0
      ? getSignedUrl(section.background.s3Key, section.background.contentType)
      : null;

  useEffect(() => {
    setLayoutId(section.contentLayoutId);
  }, [section.contentLayoutId]);

  const handleCancel = () => {
    setSelectedContainerId(null);
    setSelectedSnippet(null);
  };

  const handleCreate = (id, sort, snippetType, containerId, data) => {
    const newSnippet = {
      sectionId: section.id,
      containerId,
      snippetId: id,
      snippet: {
        id,
        dndId: `snippet-${id}`,
        sort,
        type: snippetType,
        ...data,
      },
    };
    addSnippet(newSnippet);
    setSelectedContainerId(null);
  };

  const handleUpdate = (id, sort, snippetType, containerId, data) => {
    const updatedSnippet = {
      sectionId: section.id,
      containerId,
      snippetId: id,
      snippet: {
        id,
        dndId: `snippet-${id}`,
        sort,
        type: snippetType,
        ...data,
      },
    };
    updateSnippet(updatedSnippet);
    setSelectedContainerId(null);
  };

  const onAddSnippet = (id) => {
    setSelectedContainerId(id);
  };

  const onUpdateSnippet = (id, snippet) => {
    setSelectedContainerId(id);
    setSelectedSnippet(snippet);
  };

  const onDeleteSnippet = (id, snippet) => {
    const updatedSnippet = {
      ...snippet,
      containerId: id,
    };
    deleteSnippet(updatedSnippet);
  };

  const onDeleteContainer = (container) => {
    deleteContainer(container);
  };

  const onDeleteSection = (section, showConfirm) => {
    deleteSection(section, showConfirm);
  };

  const handleLayout = () => {
    setShowLayout(true);
    setContainerNumber(section.containerOrder.length);
  };

  const handleOpenActionsSidebar = () => {
    setActionsSidebarDetails({
      type: 'section',
      id: section.id,
      config: {
        sectionId: section.id,
        layoutId: section.contentLayoutId,
        backgroundColor: section.backgroundColor,
        borderColor: section.borderColor,
        cssStyle: section.cssStyle,
        style: section.style === 'STRETCHED',
        event: section.contentEvent,
        background: section.background,
        visible: section.visible,
      },
    });
    setIsShowActionsSidebar(true);
  };

  const handleCloseActionsSidebar = () => {
    setActionsSidebarDetails({});
    setIsShowActionsSidebar(false);
  };

  const handleCloseSideBar = () => {
    setShowLayout(false);
    document.body.style.overflow = 'unset';
  };

  const handleAddContainer = (id, length) => {
    let layoutId;
    if (length === 1) layoutId = 2;
    if (length === 2) layoutId = 7;
    if (length === 3) layoutId = 10;
    const newCon = {
      id,
      layoutId,
    };
    setNewContainer(newCon);
    setCreateRequest(true);
  };

  const handleAddContainerError = () => {
    setCreateRequest(false);
  };

  const handleAddContainerSuccess = (data) => {
    const containerNumber = data.sectionContainers.length;
    const newContainerData = data.sectionContainers[containerNumber - 1];
    let layoutId;
    if (containerNumber === 2) layoutId = 2;
    if (containerNumber === 3) layoutId = 7;
    if (containerNumber === 4) layoutId = 10;

    const newContainer = {
      sectionId: `section-${data.id}`,
      layoutId,
      newContainer: {
        id: newContainerData.id,
        dndId: `container-${newContainerData.id}`,
        sort: newContainerData.sort,
      },
    };
    addContainer(newContainer);
    setCreateRequest(false);
  };

  const getContainerWidth = (typeId, index) => {
    switch (typeId) {
      case 1:
        return '100%';
      case 2:
        return '50%';
      case 3:
        return index === 0 ? '66.66%' : '33.33%';
      case 4:
        return index === 0 ? '33.33%' : '66.66%';
      case 5:
        return index === 0 ? '25%' : '75%';
      case 6:
        return index === 0 ? '75%' : '25%';
      case 7:
        return '33.33%';
      case 8:
        return index === 0 ? '50%' : '25%';
      case 9:
        return index === 2 ? '50%' : '25%';
      case 10:
        return '25%';
      default:
        return '100%';
    }
  };

  const containers = section.containerOrder.map((containerId, index) => {
    const currentContainer = section.containers[containerId];
    const currentWidth = getContainerWidth(LayoutId, index);
    return (
      <ContentContainer
        key={containerId}
        answers={answers}
        container={currentContainer}
        identities={identities}
        index={index}
        layoutId={section.contentLayoutId}
        sectionId={section.id}
        width={currentWidth}
        onAddSnippet={onAddSnippet}
        onDeleteContainer={onDeleteContainer}
        onDeleteSnippet={onDeleteSnippet}
        onUpdateSnippet={onUpdateSnippet}
      />
    );
  });

  return (
    <>
      <Draggable draggableId={section.dndId} index={index}>
        {(provided, snapshot) => (
          <SectionContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            bgColor={section.backgroundColor || '#fff'}
            bgImg={bgUrl}
            borderColor={section.borderColor}
            isDragging={snapshot.isDragging}
          >
            <Tooltip
              text="Section settings"
              triggerElement={() => (
                <LayoutBtn onClick={handleLayout}>
                  <img alt="layout icon" src={MoveIcon} />
                </LayoutBtn>
              )}
            />
            {section.containerOrder.length < 4 && (
              <Tooltip
                text="Add container"
                triggerElement={() => (
                  <AddBtn onClick={() => handleAddContainer(section.id, section.containerOrder.length)}>
                    <img alt="add icon" src={AddIcon} />
                  </AddBtn>
                )}
              />
            )}
            <Tooltip
              text="Delete section"
              triggerElement={() => (
                <DeleteBtn onClick={() => onDeleteSection(section, true)}>
                  <Icon id="icon-trash" style={{ width: '0.9rem', height: '1rem' }} title="trash" />
                </DeleteBtn>
              )}
            />

            <Tooltip
              text="Section actions"
              triggerElement={() => (
                <ActionBtn onClick={handleOpenActionsSidebar}>
                  <img alt="actions icon" src={ActionIcon} />
                </ActionBtn>
              )}
            />
            <Droppable direction="horizontal" droppableId={section.dndId} type="container">
              {(provided, snapshot) => (
                <InnerContainer
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  draggingFromThisWith={snapshot.draggingFromThisWith}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {containers}
                  {provided.placeholder}
                </InnerContainer>
              )}
            </Droppable>
          </SectionContainer>
        )}
      </Draggable>
      {showLayout && (
        <div>
          <SectionSideBar
            containerNumber={containerNumber}
            currentSection={{
              sectionId: section.id,
              layoutId: section.contentLayoutId,
              backgroundColor: section.backgroundColor,
              borderColor: section.borderColor,
              cssStyle: section.cssStyle,
              style: section.style === 'STRETCHED',
              event: section.contentEvent,
              background: section.background,
              visible: section.visible,
            }}
            onCancel={handleCloseSideBar}
            onRefreshPage={onRefreshPage}
            onSectionDelete={(sectionData) => onDeleteSection(sectionData, false)}
          />
          <Backdrop isClicked={handleCloseSideBar} />
        </div>
      )}
      {isShowActionsSidebar && (
        <ActionsSidebar
          activePageId={activePageId}
          isOpen
          onClose={handleCloseActionsSidebar}
          onDelete={(data) => onDeleteSection(data, false)}
        />
      )}
      {selectedContainerId && (
        <SnippetTypeContainer>
          <EditableSnippet
            containerId={selectedContainerId}
            snippet={selectedSnippet}
            onCancel={handleCancel}
            onCreate={handleCreate}
            // onSortSection={onSortSection}
            onRefreshPage={onRefreshPage}
            onUpdate={handleUpdate}
          />
        </SnippetTypeContainer>
      )}

      {/* ********************* GraphQL Queries ************************ */}
      {createRequest && newContainer && (
        <AddContentContainerRequest
          contentLayoutId={newContainer.layoutId}
          sectionId={newContainer.id}
          onError={handleAddContainerError}
          onSuccessResult={handleAddContainerSuccess}
        />
      )}
    </>
  );
};

ContentSection.propTypes = {
  addContainer: PropTypes.func.isRequired,
  addSnippet: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  section: PropTypes.shape({
    background: PropTypes.shape({
      contentType: PropTypes.string,
      s3Key: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    containerNumber: PropTypes.number,
    containerOrder: PropTypes.array,
    containers: PropTypes.object,
    contentEvent: PropTypes.shape({
      asset: PropTypes.shape({
        contentType: PropTypes.string,
        s3Key: PropTypes.string,
      }),
      isDefaultOpen: PropTypes.bool,
    }),
    contentLayoutId: PropTypes.number,
    contentPageId: PropTypes.number,
    dndId: PropTypes.string,
    groupId: PropTypes.number,
    groupType: PropTypes.string,
    id: PropTypes.number,
    organisationId: PropTypes.number,
    projectId: PropTypes.string,
    sort: PropTypes.number,
    style: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  updateSnippet: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      questionId: PropTypes.number,
    }),
  ),
  identities: PropTypes.shape({}),
  // getSelectedContainerId: PropTypes.func,
};

ContentSection.defaultProps = {
  answers: [],
  identities: {},
  // getSelectedContainerId: () => {},
};

export default ContentSection;
