import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const TagBtn = styled.label`
  align-items: center;
  background-color: #ebeded;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 10px;
  width: 100%;
`;

//   margin-left: 1em;
//   text-decoration: underline;
//   color: #4bc1c9;
//   cursor: pointer;
// `;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const GroupTitle = styled.span`
  color: #a6adb4;
  font-size: 12px;
`;

const TagTitle = styled.span``;

const TagDelete = styled.span`
  cursor: pointer;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

const EditableTag = props => {
  const { title, groupTitle, tag, onDelete } = props;

  //   setTagTitle(value);
  // };

  const handleDelete = () => {
    onDelete(tag);
  };

  return (
    <Wrapper>
      <TagBtn>
        <TitleContainer>
          {groupTitle && <GroupTitle>{groupTitle}</GroupTitle>}
          <TagTitle>{title}</TagTitle>
        </TitleContainer>
        <TagDelete onClick={handleDelete}>&#10006;</TagDelete>
      </TagBtn>
      {/* {!isEdit ? (
        <TagBtn>
          {tagTitle}
          <TagDelete>&#10006;</TagDelete>
        </TagBtn>
      ) : (
        <Input
          isChanged={handleInfoUpdate}
          name="title"
          style={{ margin: '0', padding: '10px' }}
          text={tagTitle}
        />
      )}
      <TagEdit onClick={() => setIsEdit(!isEdit)}>
        {!isEdit ? 'Edit' : 'Done'}
      </TagEdit> */}
    </Wrapper>
  );
};

EditableTag.propTypes = {
  title: PropTypes.string.isRequired,
  groupTitle: PropTypes.string,
  onDelete: PropTypes.func,
  tag: PropTypes.shape({}),
};

EditableTag.defaultProps = {
  groupTitle: null,
  onDelete: () => {},
  tag: null,
};

export default EditableTag;
