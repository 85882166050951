import React, { useEffect, useRef, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';

import { DEFAULT_FILTER_DATES, POST_TABLE_COLUMNS } from '../Moderation.constants';
import { getPostTableData } from '../Moderation.handlers';

import { useSocialState } from '../../../../context/socialPage/SocialPageContext';
import { useApolloClient } from '../../../../middleware/apollo/useApolloClient';
import { GET_FEED_POSTS } from '../../../../middleware/Social/getFeedPosts';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/utils';
import Preloader from '../../../Preloader/Preloader';
import { SideBar } from '../../../Sidebar/SideBar';
import { Post } from '../../../Sidebar/Views/Post';
import { EKReactTable } from '../../../Table/EKReactTable/EKReactTable';

const PostsTable = () => {
  const client = useRef(useApolloClient('bonobo')).current;

  const [pageState, dispatch] = useSocialState();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [filterDates, setFilterDates] = useState(DEFAULT_FILTER_DATES);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilteringDisabled, setIsFilteringDisabled] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isPaginationDisabled, setIsPaginationDisabled] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [lastPostCursor, setLastPostCursor] = useState(undefined);
  const [nextFetchedPosts, setNextFetchedPosts] = useState(undefined);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [totalPosts, setTotalPosts] = useState(0);

  const [getFeedPosts, { fetchMore, loading: isLoading }] = useLazyQuery(GET_FEED_POSTS, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const {
        post: {
          getFeedPosts: { edges: postEdges, totalCount },
        },
      } = data;

      if (totalCount === 0) {
        setLastPostCursor(undefined);
        setIsPaginationDisabled(true);
        setTotalPosts(totalCount);

        return;
      }

      const posts = postEdges.map((postEdge) => postEdge.node);
      const { cursor } = postEdges[postEdges.length - 1];

      setLastPostCursor(cursor);
      setIsPaginationDisabled(posts.length >= totalCount);
      setTotalPosts(totalCount);

      dispatch({
        newState: posts,
        type: 'setPosts',
      });
    },
    onError() {
      setErrorMessage('Something went wrong! Please try again later.');
      setIsFilteringDisabled(true);
      setIsPaginationDisabled(true);
    },
  });

  const handleApplyFilters = (filters) => {
    const { endDate, searchInputValue: message, startDate } = filters;

    getFeedPosts({
      variables: {
        first: pageSize,
        from: startDate,
        message: message.toLowerCase(),
        to: endDate,
      },
    });

    setIsFilterApplied(true);
  };

  const handleClearFilters = () => {
    getFeedPosts({
      variables: {
        first: pageSize,
        from: DEFAULT_FILTER_DATES.startDate,
        to: DEFAULT_FILTER_DATES.endDate,
      },
    });

    setFilterDates(DEFAULT_FILTER_DATES);
    setIsFilterApplied(false);
    setSearchInputValue('');
  };

  const handleFilterDateChange = (dates) => {
    setFilterDates(dates);
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    setNextFetchedPosts(undefined);

    const data = await fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          post: {
            getFeedPosts: { edges: currentPostEdges },
          },
        } = fetchMoreResult;

        if (!currentPostEdges.length) {
          return previousQueryResult;
        }

        const { post: previousQueryResultPost } = previousQueryResult;
        const { getFeedPosts: previousQueryResultGetFeedPosts } = previousQueryResultPost;
        const { edges: previousPostEdges } = previousQueryResultGetFeedPosts;

        const allFetchedPostEdges = [...previousPostEdges, ...currentPostEdges];

        return {
          ...previousQueryResult,
          post: {
            ...previousQueryResultPost,
            getFeedPosts: {
              ...previousQueryResultGetFeedPosts,
              edges: allFetchedPostEdges,
            },
          },
        };
      },
      variables: {
        after: lastPostCursor,
        first: pageSize,
        isPrevious: false,
      },
    });

    const {
      data: {
        post: {
          getFeedPosts: { edges: currentPostEdges },
        },
      },
    } = data;

    const currentFetchedPosts = currentPostEdges.map((currentPostEdge) => currentPostEdge.node);
    const allFetchedPosts = [...pageState.posts, ...currentFetchedPosts];

    setIsLoadingMore(false);
    setIsPaginationDisabled(allFetchedPosts.length >= totalPosts);
    setLastPostCursor(currentPostEdges[currentPostEdges.length - 1].cursor);
    setNextFetchedPosts(currentFetchedPosts);

    dispatch({
      newState: allFetchedPosts,
      type: 'setPosts',
    });
  };

  const handleSearchInputChange = (value) => {
    setSearchInputValue(value);
  };

  const handleSideBarClose = () => {
    dispatch({
      newState: null,
      type: 'setActivePostId',
    });
    setIsSideBarOpen(false);
  };

  const handleSideBarOpen = (postId) => {
    dispatch({
      newState: postId,
      type: 'setActivePostId',
    });
    setIsSideBarOpen(true);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
  };

  useEffect(() => {
    getFeedPosts({
      variables: {
        first: pageSize,
        from: filterDates.startDate,
        to: filterDates.endDate,
      },
    });
  }, [getFeedPosts, pageSize]);

  const { posts } = pageState;

  if (isLoading || !posts || (isLoadingMore && !nextFetchedPosts)) {
    return <Preloader />;
  }

  const filter = {
    date: {
      dates: filterDates,
      onDateChangeHandler: handleFilterDateChange,
    },
    isApplied: isFilterApplied,
    isDisabled: isFilteringDisabled,
    onApplyFiltersHandler: handleApplyFilters,
    onClearFiltersHandler: handleClearFilters,
    searchInput: {
      onSearchInputChangeHandler: handleSearchInputChange,
      searchInputValue,
    },
  };

  const pagination = {
    isDisabled: isPaginationDisabled,
    onLoadMoreHandler: handleLoadMore,
    onPageSizeChangeHandler: handlePageSizeChange,
    pageSize,
  };

  const sideBarTabViews = [
    {
      component: <Post onCloseHandler={handleSideBarClose} />,
      label: 'Posts',
      id: 0,
    },
  ];

  return (
    <>
      <EKReactTable
        columns={POST_TABLE_COLUMNS}
        data={getPostTableData(posts, (postId) => handleSideBarOpen(postId))}
        errorMessage={errorMessage}
        filter={filter}
        pagination={pagination}
      />
      <SideBar isOpen={isSideBarOpen} selectedTabId={0} tabViews={sideBarTabViews} onCloseHandler={handleSideBarClose} />
    </>
  );
};

export { PostsTable };
