import React from 'react';

import PropTypes from 'prop-types';

import { CloseButton, Container, Overlay, SidePanel } from './styled';

export default function SideDrawer(props) {
  const { children, isOpen, onClose } = props;

  return (
    <Container aria-hidden={!isOpen} isOpen={isOpen}>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <SidePanel isOpen={isOpen}>
        <CloseButton onClick={onClose}>
          <span aria-label="Close menu">
            <svg
              height="12"
              role="presentation"
              viewBox="0 0 47.971 47.971"
              width="12"
            >
              <g>
                <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
              </g>
            </svg>
          </span>
        </CloseButton>
        {children}
      </SidePanel>
    </Container>
  );
}

SideDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

SideDrawer.defaultProps = {
  children: undefined,
  isOpen: false,
  onClose: () => {},
};
