import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../../ErrorModal';
import MutationRequest from '../../MutationRequest';

export const CREATE_CHECKBOX_RESPONSE_ANS_REQUEST = gql`
  mutation createCheckboxResponsesAnsRequest(
    $responseSetId: Int!
    $responsesCheckbox: [QuestionResponseCheckboxInputType]
  ) {
    questionSet {
      addAvailableAnswers(
        responseSetId: $responseSetId
        responsesCheckbox: $responsesCheckbox
      ) {
        id
      }
    }
  }
`;

const createCheckboxResponsesAnsRequest = ({
  responseSetId,
  responsesCheckbox,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_CHECKBOX_RESPONSE_ANS_REQUEST}>
    {(createCheckboxResponseAns, { loading, error, data }) => {
      const variables = {
        responseSetId,
        responsesCheckbox,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createCheckboxResponseAns}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createCheckboxResponsesAnsRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  responseSetId: PropTypes.number.isRequired,
  responsesCheckbox: PropTypes.instanceOf(Array).isRequired,
};

createCheckboxResponsesAnsRequest.defaultProps = {};

export default createCheckboxResponsesAnsRequest;
