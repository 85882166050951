import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_QUERYABLE_FIELDS = gql`
  query getQueryableFields($reportType: String!) {
    __type(name: $reportType) {
      fields {
        name
        type {
          name
          kind
          ofType {
            name
          }
        }
      }
    }
  }
`;

const getQueryableFields = ({ reportType, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_QUERYABLE_FIELDS}
    variables={{ reportType }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          // eslint-disable-next-line no-underscore-dangle
          data.__type.fields
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getQueryableFields.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  reportType: PropTypes.number.isRequired,
};

export default getQueryableFields;
