import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { StripeProvider, injectStripe, Elements } from 'react-stripe-elements';
import Cookies from 'universal-cookie';

import Form from './Form';

import AddCard from '../../components/AddCard/AddCard';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import LayoutConsole from '../../components/Layout/LayoutConsole';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import SideBarNav from '../../components/SideBarNav/SideBarNav';
import UpdatePayment from '../../containers/UpdatePayment/UpdatePayment';
import AppContext from '../../context/AppContext';
import GetOrganisation from '../../middleware/Organisation/getOrganisation';

const cookies = new Cookies();

export const StripeForm = injectStripe(Form);

export default class PaymentPage extends Component {
  // eslint-disable-next-line react/sort-comp
  // eslint-disable-next-line react/static-property-placement
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      openPanel: false,
      cards: [],
      orgLoaded: true,
    };
  }

  componentDidMount() {
    const { orgName } = this.context;

    this.setState({
      orgLoaded: orgName !== '',
    });
  }

  onHandleFormClose = () => {
    this.setState({
      showForm: false,
    });

    return this.fetchCards();
  };

  onHandleDeleteCard = () => {
    this.setState({});
  };

  onHandleDefaultPaymentHandler = () => {
    this.setState({});
  };

  handleGetOrganisation = (name) => {
    const { updateOrgName } = this.context;

    updateOrgName(name);
    this.setState({
      orgLoaded: true,
    });
  };

  handleError = () => {
    this.setState({
      orgLoaded: true,
    });
  };

  billingBreadcrumbClickHandler = () => {
    this.setState({
      showForm: false,
    });
  };

  handleFormShow = () => {
    this.setState({
      showForm: true,
    });
  };

  hadlePanelClose = () => {
    this.setState({
      openPanel: false,
    });
  };

  hadlePanelOpen = () => {
    this.setState({
      openPanel: true,
    });
  };

  fetchCards = () => {
    this.setState({});
  };

  render() {
    const orgId = Number(cookies.get('orgId'));
    const { showForm, cards, openPanel, orgLoaded } = this.state;
    const { orgName } = this.context;
    let breads = [
      { id: 1, path: '/dashboard', title: 'Dashboard' },
      { id: 2, path: `/dashboard/${orgId}`, title: orgName },
      { id: 3, path: '', title: 'Billing' },
    ];
    if (showForm) {
      breads = [
        { id: 1, path: '/dashboard', title: 'Dashboard' },
        { id: 2, path: `/dashboard/${orgId}`, title: orgName },
        {
          id: 3,
          path: '/payment',
          title: 'Billing',
          handleOnClick: this.billingBreadcrumbClickHandler,
        },
        { id: 4, path: '', title: 'Add Card' },
      ];
    }
    return (
      <LayoutConsole>
        <Helmet script={[{ src: 'https://js.stripe.com/v3/', type: 'text/javascript' }]} />
        <Breadcrumb breads={breads}>
          <h3>{showForm ? 'New payment method' : 'Billing'}</h3>
        </Breadcrumb>

        <SideBarNav />
        <ConsoleContainer styles={{ marginTop: '1em' }}>
          {!showForm && (
            <div className="container-console" style={{ width: '100%' }}>
              {cards.map((item) => (
                <div key={item.stripeSourceId} className="c-project-card">
                  <ProjectCard
                    defaultCard={item.default}
                    deleteCard={this.onHandleDeleteCard}
                    expires={`${item.expMonth}/${item.expYear.toString().substring(2, 4)}`}
                    hadlePanelOpen={this.hadlePanelOpen}
                    name={item.lastFourDigits}
                    paymentId={item.stripeSourceId}
                    status
                  />
                </div>
              ))}

              <AddCard text="New payment method" onClick={this.handleFormShow} />

              <UpdatePayment
                closePanel={this.hadlePanelClose}
                openPanel={openPanel}
                saveDefaultPayment={this.onHandleDefaultPaymentHandler}
              />
            </div>
          )}

          {showForm && (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
              <Elements>
                <StripeForm handleFormClose={this.onHandleFormClose} showForm={showForm} />
              </Elements>
            </StripeProvider>
          )}
        </ConsoleContainer>
        {!orgLoaded && <GetOrganisation id={orgId} onError={this.handleError} onSuccessResult={this.handleGetOrganisation} />}
      </LayoutConsole>
    );
  }
}
