import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ASSET = gql`
  query getAppAssets($appVersionId: Int) {
    asset {
      searchAssets(appVersionId: $appVersionId) {
        assetId
        contentType
        fileName
        projectId
        presignedUrl
        s3Key
        uploaded
        alt
        tags
        xAxisFocus
        fileName
        transcript
      }
    }
  }
`;

const getAllAssetsInAppVersion = ({
  appVersionId,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={GET_ASSET}
    variables={{ appVersionId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data && Object.entries(data).length !== 0 && data.asset.searchAssets
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAllAssetsInAppVersion.propTypes = {
  appVersionId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAllAssetsInAppVersion;
