import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';

import CartoonPdf from '../../components/Cartoon/CartoonPdf';
import client from '../../middleware/client';

const QUERY = gql`
  query getContentGroup($projectId: String!, $url: String!) {
    contentGroup {
      getContentGroupByUrl(projectId: $projectId, url: $url) {
        contentPages(status: [PUBLISHED]) {
          id
          title
          url
          status
          sort
          parentId
          contentGroupId

          bubbleTexts {
            text
            angle
            x
            y
            w
            shape
            tailType
            isTailVisible
            assetId
            id
            hasShadow
            character {
              id
              name
              projectId
              __typename
            }
          }

          asset {
            isAnimatedImage
            assetId
            s3Key
            contentType
          }

          featured
          releaseDate
          seoDescription
          seoTitle
        }
      }
    }
  }
`;

const CartoonPdfPage = () => {
  const [contentGroup, setContentGroup] = useState(null);

  const { contentGroupUrl, projectId } = useParams();

  useQuery(QUERY, {
    client,
    skip: !contentGroupUrl,
    variables: {
      projectId,
      url: contentGroupUrl,
    },
    onCompleted: (data) => {
      setContentGroup(data.contentGroup.getContentGroupByUrl);
    },
  });

  return (
    <>
      {contentGroup?.contentPages.map((r) => (
        <CartoonPdf contentPage={r} />
      ))}
    </>
  );
};

export default CartoonPdfPage;
