import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const SEARCH_GROUPS = gql`
  query GetAllGroups($projectId: String!) {
    group {
      searchGroups(projectId: $projectId) {
        id
        name
        smartCodes {
          code
        }
        identifier
        customFields {
          id
          customField {
            isSearchable
            name
          }
          ... on CustomFieldResponseTextGraphType {
            text
          }
        }
        creator: createdByUser {
          id
          credential {
            email
            phoneNumber
          }
          communityName
          lastname
          firstname
        }
        address {
          ...address
        }
        status
        userFlowId
        website
        contactEmail
        phoneNumber
        groupType {
          ...groupType
        }
        __typename
      }
      getRequestsPerProject(projectId: $projectId) {
        id
        name
        address
        complementAddress
        creator {
          id
          credential {
            email
            phoneNumber
          }
          communityName
          firstname
          lastname
        }
        email
        groupTypeId
        flag
        phoneNumber
        state
        city
        website
        postCode
        __typename
      }
      __typename
    }
  }

  fragment groupType on GroupTypeGraphType {
    id
    name
  }

  fragment address on AddressGraphType {
    city
    country
    line1
    line2
    line3
    postCode
    state
  }
`;

export function combineGroupData(groupQueryResponse) {
  const data = [
    ...groupQueryResponse.searchGroups,
    ...groupQueryResponse.getRequestsPerProject.map((request) => ({
      ...request,
      status: 'REQUEST',
    })),
  ];
  return data;
}

const getAllGroupsRequest = ({ projectId, onError, onSuccessResult }) => (
  <Query client={Client()} fetchPolicy="network-only" query={SEARCH_GROUPS} variables={{ projectId }}>
    {({ error, data }) => {
      let groups = false;
      if (data && data.group) {
        groups = combineGroupData(data.group);
      }
      return <QueryRequest data={groups} error={error} onError={onError} onSuccessResult={onSuccessResult} />;
    }}
  </Query>
);

getAllGroupsRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default getAllGroupsRequest;
