import React, { useRef, useEffect, useState } from 'react';

import BubbleTail from './BubbleTail';
import { Blast, Para, SpeechInput, Chat, SpeechLabel, Ellipsis, Rectangle, Title } from './BubbleTypes';

const tailAngles = {
  1: '-150deg',
  2: '-120deg',
  3: '-90deg',
  4: '-60deg',
  5: '-30deg',
  6: '0deg',
  7: '30deg',
  8: '60deg',
  9: '90deg',
  10: '120deg',
  11: '150deg',
  12: '180deg',
};

const bubbleShapeBaseStyles = {
  fill: '#fff',
  top: '50%',
  left: '50%',
  width: 'calc(100% + 2rem)',
};

const svgBased = ['THINK'];

export const TextMessage = ({ isReceived, text }) => (
  <div className={`c-text-message c-text-message--${isReceived ? 'right-aligned' : 'left-aligned'}`}>
    <div className="h-background--color-brand-secondary c-text-message__avatar" />
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      className="c-text-message__bubble"
    />
  </div>
);

const BubbleShape = () => (
  <svg className="c-cartoon__image" style={bubbleShapeBaseStyles} viewBox="0 0 205 132" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g data-name="Layer 2" id="Layer_2">
      <g data-name="Layer 1" id="Layer_1-2">
        <circle className="cls-1" cx="118" cy="43" r="43" />
        <circle className="cls-1" cx="63" cy="45" r="32" />
        <circle className="cls-1" cx="35" cy="89" r="29" />
        <circle className="cls-1" cx="82.5" cy="97.5" r="34.5" />
        <circle className="cls-1" cx="132" cy="97" r="25" />
        <circle className="cls-1" cx="170" cy="71" r="35" />
        <circle className="cls-1" cx="25" cy="58" r="25" />
      </g>
    </g>
  </svg>
);

const SpeechBubble = ({ children, angle, top, left, type, tailType, isTailVisible, width, question, bubbleText, hasShadow = false }) => {
  const bubbleRef = useRef(null);
  const isSVGBased = svgBased.includes(type);
  const [bubbleTailWidth, setBubbleTailWidth] = useState(0);

  useEffect(() => {
    if (bubbleRef.current) {
      setBubbleTailWidth(bubbleRef.current.clientWidth);
    }
  }, []);

  const ShapeNode = {
    OVAL: (
      <Ellipsis
        angle={angle}
        hasShadow={hasShadow}
        isTailVisible={isTailVisible}
        left={left}
        question={question}
        tailAngles={tailAngles}
        tailType={tailType}
        top={top}
        width={width}
      >
        {children}
      </Ellipsis>
    ),

    BLAST: <Blast angle={angle} isTailVisible={isTailVisible} left={left} text={bubbleText} top={top} width={width} />,
    THINK: <BubbleShape bubbleShapeBaseStyles={bubbleShapeBaseStyles} />,
    PARA: <Para left={left} text={bubbleText} top={top} width={width} />,
    CHAT_TO: <Chat left={left} text={bubbleText} top={top} width={width} />,
    INPUT: <SpeechInput left={left} text={bubbleText} top={top} width={width} />,
    QUIZ: (
      <Rectangle
        angle={tailAngles[Math.round(angle)]}
        bubbleRef={bubbleRef}
        bubbleShapeBaseStyles={bubbleShapeBaseStyles}
        bubbleTailWidth={bubbleTailWidth}
        isTailVisible={isTailVisible}
        left={left}
        question={question}
        top={top}
        width={width}
      >
        {children}
      </Rectangle>
    ),
    TITLE: <Title left={left} text={bubbleText} top={top} width={width} />,
    RECTANGLE: (
      <Rectangle
        angle={tailAngles[Math.round(angle)]}
        bubbleRef={bubbleRef}
        bubbleShapeBaseStyles={bubbleShapeBaseStyles}
        bubbleTailWidth={bubbleTailWidth}
        isTailVisible={isTailVisible}
        left={left}
        question={question}
        top={top}
        width={width}
      >
        {children}
      </Rectangle>
    ),
    CHAT_FROM: <Chat left={left} text={bubbleText} top={top} width={width} isReceived />,
    LABEL: (
      <SpeechLabel left={left} top={top} width={width}>
        {children}
      </SpeechLabel>
    ),
  };

  return (
    <>
      {isSVGBased ? (
        <div
          ref={bubbleRef}
          className="c-cartoon__bubble"
          style={{
            top: `${top}%`,
            left: `${left}%`,
            width: `${width}%`,
          }}
        >
          <div className="l-position--relative">
            {ShapeNode[type]}
            {isTailVisible && <BubbleTail angle={angle} tailAngles={tailAngles} tailType={tailType} />}
            <div className="l-position--relative">
              {children}
              {Boolean(question) && (
                <p>
                  <strong className="h-color--brand-secondary">{question}</strong>
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>{ShapeNode[type]}</>
      )}
    </>
  );
};

export default SpeechBubble;
