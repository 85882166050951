import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { Input, Form, FormButtons, Preloader, Select, Spacing, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import client from '../../../../middleware/client';
import { GET_COUNTRIES } from '../../../../middleware/Directory/getCountries';

const AddressTab = ({ address, onClose, onDelete, onSave, onUpdateCountryDropdown, onUpdateAddress }) => {
  const { city, country, latitude, longitude, line1, postcode, state, suburb } = address || {};
  const [countryDropdownListOptions, setCountryDropdownListOptions] = useState([]);

  const [getCountries, { loading: isGetCountriesLoading }] = useLazyQuery(GET_COUNTRIES, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const {
        __type: { enumValues },
      } = response;

      setCountryDropdownListOptions(
        enumValues.map((value, id) => {
          const { name } = value;
          return {
            id,
            name,
            value: name,
          };
        }),
      );
    },
  });

  const handleDelete = () => {
    if (!onDelete) {
      return;
    }

    onDelete({
      message: 'Are you sure you want to delete this directory?',
      type: 'directory',
    });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Preloader isLoading={isGetCountriesLoading} />
      {Boolean(countryDropdownListOptions.length) && (
        <Form onSubmit={onSave}>
          <div className="c-view-wrapper">
            <fieldset>
              <legend className="h-hide">Address</legend>

              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The first address line" />
                </div>
                <Input id="line1" labelText="Address line 1" name="line1" type="text" value={line1} isRequired onChange={onUpdateAddress} />
              </Spacing>
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The suburb / town eg: Potts Point" />
                </div>
                <Input id="suburb" labelText="Suburb" name="suburb" type="text" value={suburb} isRequired onChange={onUpdateAddress} />
              </Spacing>
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The postcode of your area eg: 2050" />
                </div>
                <Input
                  id="postcode"
                  labelText="Postcode"
                  name="postcode"
                  type="text"
                  value={postcode}
                  isRequired
                  onChange={onUpdateAddress}
                />
              </Spacing>
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The city eg: Sydney" />
                </div>
                <Input id="city" labelText="City" name="city" type="text" value={city} onChange={onUpdateAddress} />
              </Spacing>
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The state or territory eg: NSW" />
                </div>
                <Input id="state" labelText="State" name="state" type="text" value={state} isRequired onChange={onUpdateAddress} />
              </Spacing>
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The country eg: Australia" />
                </div>
                <Select
                  id="country"
                  labelText="Country"
                  name="country"
                  options={countryDropdownListOptions}
                  value={country}
                  isRequired
                  onChange={onUpdateCountryDropdown}
                />
              </Spacing>
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The the distance north or south of the equator measured in degrees" />
                </div>
                <Input id="latitude" labelText="Latitude" name="latitude" type="number" value={latitude} onChange={onUpdateAddress} />
              </Spacing>
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The the distance measured in degrees east or west of an imaginary line that runs from the north pole to the south pole and passes through Greenwich, England." />
                </div>
                <Input id="longitude" labelText="Longitude" name="longitude" type="number" value={longitude} onChange={onUpdateAddress} />
              </Spacing>
            </fieldset>
          </div>
          <FormButtons additionalClassNames="c-form-button__directory" onClose={onClose} onDelete={handleDelete} />
        </Form>
      )}
    </>
  );
};

export { AddressTab };

AddressTab.propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    latitude: PropTypes.number,
    line1: PropTypes.string,
    longitude: PropTypes.number,
    postcode: PropTypes.string,
    state: PropTypes.string,
    suburb: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onUpdateAddress: PropTypes.func,
  onUpdateCountryDropdown: PropTypes.func,
};
