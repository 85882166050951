import gql from 'graphql-tag';

import { SummaryFragment } from '../QuestionConfiguration/Fragments/Collection';

export const CREATE_SUMMARY_SNIPPET = gql`
  mutation ($containerId: Int!, $stepId: Int!, $questionId: Int!, $formId: Int!, $title: String!) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: true, entityTitle: $title }
        contentSnippetSummary: { userFlowStepId: $stepId, contentSnippetQuestionId: $questionId, contentSnippetFormId: $formId }
      ) {
        id
        sort
        ...summary
      }
    }
  }
  ${SummaryFragment}
`;
