import React, { useState, useEffect } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { useNewEkardoSiteUrls } from '../../hooks';
import IconDiscount from '../../static/img/discounts.svg';
import IconOrder from '../../static/img/subscriptions.svg';

const CardsBox = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-right: -1.7em;
`;

const subscriptionsCards = [
  {
    image: IconOrder,
    key: 'orders',
    path: 'groups-order-summary',
    title: 'Orders',
  },
  {
    image: IconDiscount,
    key: 'discount-codes',
    path: 'discounts',
    title: 'Discount codes',
  },
];

function SubscriptionsPage() {
  const { pathname } = useLocation();
  const params = useParams();
  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/subscriptions`,
        title: 'Subscriptions',
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info="Review orders and manage discount codes"
      pageDescription=""
      pageTitle="Subscriptions"
      params={params}
    >
      <ProjectNavBar title="Subscriptions" />
      <CardsBox>
        {subscriptionsCards.map((subscriptionsCard) => {
          const { image, key, path, title } = subscriptionsCard;
          return <MenuCard key={`subscriptions-card-${key}`} href={`${pathname}/${path}`} name={title} src={image} />;
        })}
      </CardsBox>
    </PageWrapper>
  );
}

export default SubscriptionsPage;
