import gql from 'graphql-tag';

export const GET_USERFLOWS = gql`
  query($projectId: String!) {
    userFlow {
      getUserFlows(projectId: $projectId) {
        id
        title
      }
    }
  }
`;
