import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import SwitchButton from '../UI/Button/SwitchButton';
import DatePicker from '../UI/Input/DatePicker';
import Tooltip from '../UI/Tooltip/Tooltips';

const Outter = styled.div`
  display: flex;
  flex-flow: ${(props) => (props.isSidebar ? 'column' : 'row')};
  justify-content: space-between;
  padding-bottom: 0.5em;
`;

const SectionLeft = styled.div`
  width: 100%;
`;

const Section = styled.div`
  margin-left: ${(props) => (props.isSidebar ? '0px' : '2em')};
  margin-top: ${(props) => (props.isSidebar ? '2em' : '0px')};
  min-width: 48%;
`;

const AdvanceWrapper = styled.div`
  background-color: #e4e4e3;
  border-radius: 5px;
  min-height: 90%;
  padding: 0.5em;
  width: 100%;
`;

const AdvanceContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const AdvanceSwitch = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  line-height: 0.6em;
  margin-top: 0.5em;
  padding: 0 0.5em;
`;

const B = styled.b`
  line-height: 2em;
`;

const Input = styled.input`
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  line-height: 1em;
  margin-right: 0.5em;
`;

const Textarea = styled.textarea`
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px 5px 0 5px;
  line-height: 1em;
  margin-right: 0.5em;
  resize: vertical;
`;

//   & {
//     background: #fff 97% 50% no-repeat;
//   }
//   &::-webkit-inner-spin-button {
//     display: none;
//   }
//   &::-webkit-calendar-picker-indicator {
//     opacity: 0;
//   }
// `;

const DateWrapper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 0 0.5em;
  width: 10em;
`;

const FormAdvance = (props) => {
  const {
    allowUserGeneratedResponses,
    correctResponseMessage,
    customNumber,
    defaultDate,
    defaultValue,
    exampleResponseMessage,
    incompleteResponseMessage,
    incorrectResponseMessage,
    isMandatory,
    isSidebar,
    isVisible,
    majorNumber,
    maxDate,
    maxLength,
    maxResponseChecked,
    maxResponses,
    maxValue,
    minDate,
    minLength,
    minResponses,
    minValue,
    onChangeFormAdvanceInput,
    regex,
    resType,
    questionIdentifier,
  } = props;

  const showAllowUserGeneratedResponseToggle = resType.toUpperCase() === 'CB' || resType.toUpperCase() === 'RB';

  return (
    <Outter isSidebar={isSidebar}>
      <SectionLeft>
        <B>Questions</B>
        <AdvanceWrapper>
          <AdvanceSwitch>
            <span>
              <b>Question number (major)</b>
              <Tooltip text="If this question should have a custom number other than it order" />
            </span>
            <Input name="majorNumber" onChange={onChangeFormAdvanceInput} style={{ maxWidth: '6em' }} type="text" value={majorNumber} />
          </AdvanceSwitch>
          <AdvanceSwitch>
            <span>
              <b>Question identifier</b>
              <Tooltip text="If used, this identifier will be exported as the column header in all project reports " />
            </span>
            <Input name="questionIdentifier" onChange={onChangeFormAdvanceInput} type="text" value={questionIdentifier} />
          </AdvanceSwitch>
          <AdvanceSwitch>
            {showAllowUserGeneratedResponseToggle && (
              <>
                Allow user generated responses
                <Tooltip text="Allow the user to generate their own responses" />
                <SwitchButton
                  defaultChecked={allowUserGeneratedResponses}
                  id={5}
                  name="allowUserGeneratedResponses"
                  onChange={() =>
                    onChangeFormAdvanceInput({
                      target: {
                        name: 'allowUserGeneratedResponses',
                        value: !allowUserGeneratedResponses,
                      },
                    })
                  }
                />
              </>
            )}
          </AdvanceSwitch>
          <AdvanceSwitch>
            <div>
              Mandatory
              <Tooltip text="Is the question optional or must it be answered" />
            </div>
            <SwitchButton
              defaultChecked={isMandatory}
              id={2}
              name="isMandatory"
              onChange={() =>
                onChangeFormAdvanceInput({
                  target: { name: 'isMandatory', value: !isMandatory },
                })
              }
            />
          </AdvanceSwitch>
          {/* <AdvanceSwitch>
            <div>
              Hide question number <Tooltip />
            </div>
            <SwitchButton id={3} />
          </AdvanceSwitch> */}
          <AdvanceSwitch>
            <div>
              Hide question
              <Tooltip text="Should the question be hidden from the user" />
            </div>
            <SwitchButton
              defaultChecked={!isVisible}
              id={4}
              name="isVisible"
              onChange={() =>
                onChangeFormAdvanceInput({
                  target: { name: 'isVisible', value: isVisible },
                })
              }
            />
          </AdvanceSwitch>
        </AdvanceWrapper>
      </SectionLeft>

      {resType === 'SL' || resType === 'ML' ? (
        <Section isSidebar={isSidebar}>
          <B>Response</B>
          <AdvanceWrapper>
            <AdvanceContainer>
              <div>
                <div>
                  <b>Minimum length</b> <Tooltip />
                </div>
                <div>
                  <Input name="minLength" onChange={onChangeFormAdvanceInput} style={{ maxWidth: '9em' }} type="number" value={minLength} />
                </div>
              </div>
              <div>
                <div>
                  <b>Maximum length</b> <Tooltip />
                </div>
                <Input name="maxLength" onChange={onChangeFormAdvanceInput} style={{ maxWidth: '9em' }} type="number" value={maxLength} />
              </div>
            </AdvanceContainer>
            <div>
              <div>
                Regex <Tooltip />
              </div>
              <Input name="regex" onChange={onChangeFormAdvanceInput} style={{ width: '19em' }} value={regex} />
            </div>
          </AdvanceWrapper>
        </Section>
      ) : null}
      {resType === 'CB' ? (
        <Section isSidebar={isSidebar}>
          <B>Response</B>
          <AdvanceWrapper>
            <div>
              <div>
                <b>Max checked responses</b> <Tooltip />
              </div>
              <div>
                <Input
                  name="maxResponseChecked"
                  onChange={onChangeFormAdvanceInput}
                  style={{ width: '12em' }}
                  type="number"
                  value={maxResponseChecked}
                />
              </div>
            </div>
          </AdvanceWrapper>
        </Section>
      ) : null}
      {resType === 'Nu' ? (
        <Section isSidebar={isSidebar}>
          <B>Response</B>
          <AdvanceWrapper>
            <AdvanceContainer>
              <div>
                <div>
                  <b>Minimum Value</b>
                  <Tooltip />
                </div>
                <div>
                  <Input name="minValue" onChange={onChangeFormAdvanceInput} style={{ maxWidth: '9em' }} type="number" value={minValue} />
                </div>
              </div>
              <div>
                <div>
                  <b>Maximum Value</b>
                  <Tooltip />
                </div>
                <div>
                  <Input name="maxValue" onChange={onChangeFormAdvanceInput} style={{ maxWidth: '9em' }} type="number" value={maxValue} />
                </div>
              </div>
              <div>
                <div>
                  <b>Default Value</b>
                  <Tooltip />
                </div>
                <div>
                  <Input
                    name="defaultValue"
                    onChange={onChangeFormAdvanceInput}
                    style={{ width: '9em' }}
                    type="number"
                    value={defaultValue}
                  />
                </div>
              </div>
            </AdvanceContainer>
          </AdvanceWrapper>
        </Section>
      ) : null}
      {resType === 'Ca' ? (
        <Section isSidebar={isSidebar}>
          <B>Response</B>
          <AdvanceWrapper>
            <AdvanceContainer style={{ justifyContent: 'space-between' }}>
              <div>
                <div>
                  <b>Minimum Date</b>
                  <Tooltip />
                </div>
                <DateWrapper>
                  <DatePicker name="minDate" onChange={onChangeFormAdvanceInput} value={minDate} />
                </DateWrapper>
              </div>
              <div>
                <div>
                  <b>Maximum Date</b>
                  <Tooltip />
                </div>
                <DateWrapper>
                  <DatePicker name="maxDate" onChange={onChangeFormAdvanceInput} value={maxDate} />
                </DateWrapper>
              </div>
              <div>
                <div>
                  <b>Default Date</b>
                  <Tooltip />
                </div>
                <DateWrapper>
                  <DatePicker name="defaultDate" onChange={onChangeFormAdvanceInput} value={defaultDate} />
                </DateWrapper>
              </div>
            </AdvanceContainer>
          </AdvanceWrapper>
        </Section>
      ) : null}
      {resType === 'MR' ? (
        <Section isSidebar={isSidebar}>
          <B>Response</B>
          <AdvanceWrapper>
            <AdvanceSwitch>
              <span>
                <b>Minimum Responses</b>
                <Tooltip text="The lease amount of responses possible" />
              </span>
              <Input
                name="minResponses"
                onChange={onChangeFormAdvanceInput}
                style={{ maxWidth: '6em' }}
                type="number"
                value={minResponses}
              />
            </AdvanceSwitch>
            <AdvanceSwitch>
              <span>
                <b>Maximum Responses</b>
                <Tooltip text="The most amount of responses possible" />
              </span>
              <Input
                name="maxResponses"
                onChange={onChangeFormAdvanceInput}
                style={{ maxWidth: '6em' }}
                type="number"
                value={maxResponses}
              />
            </AdvanceSwitch>

            {/* <AdvanceContainer style={{ justifyContent: 'space-between' }}>

            </AdvanceContainer> */}
          </AdvanceWrapper>
        </Section>
      ) : null}
    </Outter>
  );
};

FormAdvance.propTypes = {
  correctResponseMessage: PropTypes.string.isRequired,
  defaultDate: PropTypes.instanceOf(Date).isRequired,
  defaultValue: PropTypes.number.isRequired,
  exampleResponseMessage: PropTypes.string.isRequired,
  incompleteResponseMessage: PropTypes.string.isRequired,
  incorrectResponseMessage: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  maxLength: PropTypes.number.isRequired,
  maxResponses: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  minLength: PropTypes.number.isRequired,
  minResponses: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onChangeFormAdvanceInput: PropTypes.func.isRequired,
  regex: PropTypes.string.isRequired,
  resType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  allowUserGeneratedResponses: PropTypes.bool,
  // majorNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // minorNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSidebar: PropTypes.bool,
  maxResponseChecked: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  questionIdentifier: PropTypes.string,
};

FormAdvance.defaultProps = {
  allowUserGeneratedResponses: false,
  customNumber: '',
  isSidebar: false,
  // majorNumber: '',
  maxResponseChecked: '',
  // minorNumber: '',
};

export default FormAdvance;
