import gql from 'graphql-tag';

// TODO: @ash add conditions type to conditions array
// TODO: @ash add conditions type to various actions
// would come as follows
// {
//   questionActionId: Int! // i dont think this would be needed
//   questionConditionId: Int // optional to determine whether its create or update (if present then update)
//   title: String!
//   contentSnippetQuestionId: Int!
//   contentPageId: Int!
//   isTemplate: Boolean!
//   type: String! 'checkbox' | 'radio' | 'text' | 'slider'

//   // slider
//   minValue: Int
//   maxValue: Int

//   // checkbox
//   questionResponseCheckboxId: Int

//   // radio
//   questionResponseRadioId: Int

//   // text
//   text: String

// }

export const CREATE_ACTION_SHOW_SNIPPET = gql`
  mutation questionActionShowTargetSnippet($contentPageId: Int!, $wrapperId: Int!, $title: String!) {
    questionAction {
      addAction(actionShow: { contentPageId: $contentPageId, targetSnippetId: $wrapperId, title: $title }) {
        id
        ... on QuestionActionShowType {
          targetSnippetId
        }
      }
    }
  }
`;

export const CREATE_ACTION_HIDE_SNIPPET = gql`
  mutation questionActionHideTargetSnippet($contentPageId: Int!, $wrapperId: Int!, $title: String!) {
    questionAction {
      addAction(actionHide: { contentPageId: $contentPageId, targetSnippetId: $wrapperId, title: $title }) {
        id
        ... on QuestionActionHideType {
          targetSnippetId
        }
      }
    }
  }
`;

// TODO: @ash add $type: EActionTriggerContentEventType! into query

export const CREATE_ACTION_TRIGGER_EVENT = gql`
  mutation actionTriggerContentEvent($contentPageId: Int!, $wrapperId: Int!, $title: String!, $type: EActionTriggerContentEventType!) {
    questionAction {
      addAction(actionTriggerContentEvent: { contentPageId: $contentPageId, contentEventId: $wrapperId, title: $title, type: $type }) {
        id
        ... on QuestionActionTriggerContentEventType {
          contentEventId
        }
      }
    }
  }
`;

export const CREATE_ACTION_SHOW_SECTION = gql`
  mutation questionActionShowSection($contentPageId: Int!, $wrapperId: Int!, $title: String!) {
    questionAction {
      addAction(actionShowSection: { contentPageId: $contentPageId, sectionId: $wrapperId, title: $title }) {
        id
        ... on QuestionActionShowSectionType {
          sectionId
        }
      }
    }
  }
`;

export const CREATE_ACTION_HIDE_SECTION = gql`
  mutation questionActionHideSection($contentPageId: Int!, $wrapperId: Int!, $title: String!) {
    questionAction {
      addAction(actionHideSection: { contentPageId: $contentPageId, sectionId: $wrapperId, title: $title }) {
        id
        ... on QuestionActionHideSectionType {
          sectionId
        }
      }
    }
  }
`;

export const CREATE_ACTION_SHOW_CONTAINER = gql`
  mutation questionActionShowContainer($contentPageId: Int!, $wrapperId: Int!, $title: String!) {
    questionAction {
      addAction(actionShowContainer: { contentPageId: $contentPageId, containerId: $wrapperId, title: $title }) {
        id
        ... on QuestionActionShowContainerType {
          containerId
        }
      }
    }
  }
`;

export const CREATE_ACTION_HIDE_CONTAINER = gql`
  mutation questionActionHideContainer($contentPageId: Int!, $wrapperId: Int!, $title: String!) {
    questionAction {
      addAction(actionHideContainer: { contentPageId: $contentPageId, containerId: $wrapperId, title: $title }) {
        id
        ... on QuestionActionHideContainerType {
          containerId
        }
      }
    }
  }
`;

export const CREATE_ACTION_SKIP_PAGE = gql`
  mutation questionActionSkipPage($contentPageId: Int!, $title: String!) {
    questionAction {
      addAction(skipPage: { contentPageId: $contentPageId, title: $title }) {
        id
        ... on QuestionActionSkipPageType {
          contentPageId
        }
      }
    }
  }
`;
