import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_GROUP = gql`
  query GetContentGroup($contentGroupId: Int!) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        id
        title
        type
        formType
        tags {
          id
          name
        }
        contentPages(topLevelOnly: true, status: [PUBLISHED, UNPUBLISHED]) {
          id
          sort
        }
      }
    }
  }
`;

const getContentGroup = ({ contentGroupId, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_GROUP}
    variables={{ contentGroupId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          Object.entries(data).length !== 0 && data.contentGroup.getContentGroup
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);
getContentGroup.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getContentGroup;
