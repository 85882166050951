import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CODE_ASSET } from './UseDeleteCodeAsset.graphql';

const useDeleteCodeAsset = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeDeleteCodeAsset, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? DELETE_CODE_ASSET,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: { deleteAsset: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteCodeAsset = ({ assetId }) => {
    executeDeleteCodeAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleDeleteCodeAsset,
    isLoading,
  };
};

export { useDeleteCodeAsset };
