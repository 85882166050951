import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  cursor: pointer;
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  padding: 1em;
  background-color: ${(props) => props.color || '#4bc1c9'};
  border-radius: 50%;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
`;

const useColorAvatar = ({ firstName, isClicked, lastName = '' }) => {
  const [color, setColor] = useState();

  const getUserInitials = () => {
    const firstNameInitial = firstName.substring(0, 1);
    const lastNameInitial = lastName.substring(0, 1);

    return `${firstNameInitial} ${lastNameInitial}`.replaceAll(' ', '').toUpperCase();
  };

  const generateColor = () => `#${Math.random().toString(16).substr(-6)}`;

  if (!color) {
    setColor(generateColor());
  }

  return (
    <Wrapper onClick={isClicked}>
      <Avatar color={color}>{getUserInitials()}</Avatar>
    </Wrapper>
  );
};

useColorAvatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  isClicked: PropTypes.func,
  lastName: PropTypes.string,
};

useColorAvatar.defaultProps = {
  isClicked: () => {},
};

export default useColorAvatar;
