import React from 'react';

import { ClusterSnippetProps } from './ClusterSnippet.types';

import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

const ClusterSnippet = ({ resultBand: { description } }: ClusterSnippetProps) => {
  return (
    <React.Fragment>
      {description && (
        <div className="ek-cluster-snippet c-cluster-snippet">
          <DOMPurifySanitizedHtmlContent html={description} />
        </div>
      )}
    </React.Fragment>
  );
};

export { ClusterSnippet };
