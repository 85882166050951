import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SUMMARY_SNIPPET } from './UseCreateSummarySnippet.graphql';

const useCreateSummarySnippet = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeCreateSummarySnippet, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? CREATE_SUMMARY_SNIPPET,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { createContentSnippet: contentSnippet },
        } = data;

        onCompleted({
          contentSnippet,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateSummarySnippet = ({ variables }) => {
    executeCreateSummarySnippet({
      variables,
    });
  };

  return {
    handleCreateSummarySnippet,
    isLoading,
  };
};

export { useCreateSummarySnippet };
