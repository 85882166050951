import styled from 'styled-components';

export const ButtonGroupContainer = styled.div`
  background-color: var(--g-color-white);
  bottom: 0;
  margin-bottom: 0;
  position: fixed;
  right: 0;
  width: 38rem;
}
`;

export const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding-top: 0.3rem;
`;

export const DeleteButton = styled.button`
  background-color: #6d7a8a;
  border: none;
  color: #fff;
  display: block;
  line-height: 3rem;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: #dc3545;
  }
`;
