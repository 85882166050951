import gql from 'graphql-tag';

export const GET_PROJECT_FEATURES = gql`
  query($projectId: String!) {
    project {
      getProject(projectId: $projectId) {
        features {
          key
          value
        }
      }
    }
  }
`;
