import React, { useState } from 'react';

import { TextItem, AudioContainer, EditButton, DeleteButton, ButtonContainer, AudioItemContainer } from '../styled';

import deleteIcon from '../../../static/img/icon-bin.svg';
import editSVG from '../../../static/img/icon-edit.svg';
import AudioPlayer from '../../UI/AudioPlayer/AudioPlayer';

const UploadAudioTab = ({ handleDeleteAsset, handleEditAudio, presignedUrl, fileName }) => {
  const [duration, setDuration] = useState(0);

  return (
    <AudioContainer>
      <AudioItemContainer justifyContent="space-between">
        <AudioPlayer margin="0 1rem 0 1rem" selected={presignedUrl} sideLengths="2.5rem" onDurationLoaded={(value) => setDuration(value)} />
        <div>
          <TextItem lineHeight="1.8">{fileName}</TextItem>
          <p>{duration}s</p>
        </div>
      </AudioItemContainer>
      <ButtonContainer>
        <DeleteButton onClick={handleDeleteAsset}>
          <img alt="Delete the audio" src={deleteIcon} />
        </DeleteButton>
        <EditButton onClick={handleEditAudio}>
          <img alt="Edit the audio" src={editSVG} />
        </EditButton>
      </ButtonContainer>
    </AudioContainer>
  );
};

export default UploadAudioTab;
