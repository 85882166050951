import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../Buttons/Button';
import Dropdown from '../UI/Dropdown/Dropdown';

import { SnippetTypeConst } from './SnippetTypeConst';

import AppContext from '../../context/AppContext';
import client from '../../middleware/client';
import { CREATE_SUMMARY_SNIPPET } from '../../middleware/ContentSnippet/createSummarySnippet';
import { GET_QUESTION_IN_CONTENT_GROUP } from '../../middleware/ContentSnippet/getQuestionInContentGroup';
import { GET_PROJECT_STEPS } from '../../middleware/UserFlow/UserFlowStep/getProjectSteps';
import { PushRight } from '../../pages/MarketplaceAppDetailsPage/styled';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: 1em 0;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 8px 4px 15px;
  position: relative;
`;

const EditableSummarySnippet = (props) => {
  const { containerId, onCancel, onCreate } = props;

  const { project } = useContext(AppContext);

  const [questionOptions, setQuestionOptions] = useState(undefined);
  const [selectedQuestionId, setSelectedQuestionId] = useState(undefined);
  const [selectedSnippetFormId, setSelectedSnippetFormId] = useState(undefined);
  const [selectedUserFlowStepId, setSelectedUserFlowStepId] = useState(undefined);
  const [userFlowStepOptions, setUserFlowStepOptions] = useState(undefined);

  const [executeCreateSummarySnippet, { loading: isCreateSummarySnippetLoading }] = useMutation(CREATE_SUMMARY_SNIPPET, {
    client,
  });

  const [executeGetQuestions, { loading: isGetQuestionsLoading }] = useLazyQuery(GET_QUESTION_IN_CONTENT_GROUP, {
    client,
    onCompleted(data) {
      const {
        contentSnippet: { getAllQuestionInStep: groupedContentSnippetQuestions },
      } = data;

      const parser = new DOMParser();

      const newQuestionOptions = groupedContentSnippetQuestions.flatMap((groupedContentSnippetQuestion) => {
        const { contentSnippetFormId, questions: contentSnippetQuestions } = groupedContentSnippetQuestion;

        return contentSnippetQuestions
          .map((contentSnippetQuestion) => {
            const { id, question } = contentSnippetQuestion;

            const newDocument = parser.parseFromString(question, 'text/html');
            let label = newDocument.documentElement.textContent || `Question-${id}`;

            return {
              id: `${contentSnippetFormId}-${id}`,
              label,
              value: id,
            };
          })
          .filter((questionOption) => questionOption);
      });

      setQuestionOptions(newQuestionOptions);
    },
  });

  const { loading: isGetProjectStepsLoading } = useQuery(GET_PROJECT_STEPS, {
    client,
    onCompleted(data) {
      const {
        userFlowStep: { getStepsOfProject: userFlowSteps },
      } = data;

      if (!userFlowSteps.length) {
        return;
      }

      setUserFlowStepOptions(
        userFlowSteps.map((userFlowStep) => {
          const { id, stepName } = userFlowStep;

          return {
            id,
            label: stepName,
            value: id,
          };
        }),
      );
    },
    variables: {
      projectId: project.id,
    },
  });

  const handleQuestionChange = (id) => {
    const [contentSnippetFormId, questionId] = id.split('-');
    setSelectedSnippetFormId(Number(contentSnippetFormId));
    setSelectedQuestionId(Number(questionId));
  };

  const handleSave = () => {
    executeCreateSummarySnippet({
      variables: {
        containerId,
        formId: selectedSnippetFormId,
        questionId: selectedQuestionId,
        stepId: selectedUserFlowStepId,
        title: '',
      },
    }).then((data) => {
      const {
        data: {
          contentSnippet: { createContentSnippet: contentSnippet },
        },
      } = data;
      const { id, sort } = contentSnippet;

      onCreate(id, sort, SnippetTypeConst.SUMMARY, containerId, contentSnippet);
    });
  };

  const handleUserFlowStepChange = (id) => {
    executeGetQuestions({
      variables: {
        userFlowStepId: id,
      },
    });
    setSelectedQuestionId(undefined);
    setSelectedUserFlowStepId(id);
  };

  const isLoading = isGetProjectStepsLoading || isGetQuestionsLoading || isCreateSummarySnippetLoading;

  return (
    <>
      <Container>
        {userFlowStepOptions && (
          <>
            <b>Select a module</b>
            <Dropdown
              availableResponses={userFlowStepOptions}
              name="modules"
              selectedValue={selectedUserFlowStepId}
              onChangeHandler={(id) => handleUserFlowStepChange(Number(id))}
            />
          </>
        )}
        {questionOptions && (
          <>
            <b>Select a question</b>
            <Dropdown
              availableResponses={questionOptions}
              name="questions"
              selectedValue={selectedQuestionId}
              onChangeHandler={handleQuestionChange}
            />
          </>
        )}
        <PushRight>
          <Row>
            <Button isDisabled={isLoading} onClick={onCancel} styles="background-color: #505354; margin-right:10px">
              Cancel
            </Button>
            <Button isDisabled={isLoading} isLoading={isLoading} onClick={handleSave}>
              Save
            </Button>
          </Row>
        </PushRight>
      </Container>
    </>
  );
};

EditableSummarySnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default EditableSummarySnippet;
