import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import AudioPlayer from '../UI/AudioPlayer/AudioPlayer';

import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;

const P = styled.p`
  max-width: 100%;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function AudioSnippet(props) {
  const { assetId } = props;

  const [getAssetSent, setGetAssetSent] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [filename, setFileName] = useState('');

  const handleAssetError = () => {
    setGetAssetSent(true);
  };

  const handleAssetResponse = (s3Key, contentType) => {
    const index = s3Key.lastIndexOf('/') + 1;
    const fname = s3Key.substr(index);
    setFileName(fname);
    setGetAssetSent(true);
    setFileUrl(getSignedUrl(s3Key, contentType));
  };

  const handleAudio = () => {};

  return (
    <>
      <Wrapper>
        <AudioPlayer isClicked={() => handleAudio()} selected={fileUrl} style={{ margin: '-1.8em 0.5em 0.2em 0' }} />
        <P>{filename}</P>
      </Wrapper>
      {!getAssetSent && <GetAsset assetId={assetId} onError={handleAssetError} onSuccessResult={handleAssetResponse} />}
    </>
  );
}

AudioSnippet.propTypes = {
  assetId: PropTypes.string.isRequired,
};

export default AudioSnippet;
