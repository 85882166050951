import React, { Fragment } from 'react';

import { ImageSnippetProps } from './ImageSnippet.types';

import './ImageSnippet.css';

const ImageSnippet = ({ asset: { alt, presignedUrl } }: ImageSnippetProps) => {
  return (
    <Fragment>
      {presignedUrl && (
        <div className="c-image-snippet">
          <img alt={alt} className="c-image-snippet__image" src={presignedUrl} />
        </div>
      )}
    </Fragment>
  );
};

export { ImageSnippet };
