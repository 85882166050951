import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ResponsesInput from '../Form/ResponsesInput';
import DatePicker from '../UI/Input/DatePicker';
import Input from '../UI/Input/InputWhite';
import NumberInput from '../UI/Input/NumberInput';
import Slider from '../UI/Slider/ResSlider';
import Textarea from '../UI/Textarea/Textarea';

// questionAnswer middleware
import CalendarAnswer from '../../middleware/QuestionAnswer/createCalendarAnswer';
import CheckboxAnswer from '../../middleware/QuestionAnswer/createCheckboxAnswer';
import DropdownAnswer from '../../middleware/QuestionAnswer/createDropdownAnswer';
import MultiLineAnswer from '../../middleware/QuestionAnswer/createMultiLineAnswer';
import NumberAnswer from '../../middleware/QuestionAnswer/createNumAnswer';
import RadioAnswer from '../../middleware/QuestionAnswer/createRadioAnswer';
import SingleLineAnswer from '../../middleware/QuestionAnswer/createSingleLineAnswer';
import SliderAnswer from '../../middleware/QuestionAnswer/createSliderAnswer';
// Cotext

const EditBox = styled.div`
  display: none;
  position: absolute;
  right: -1em;
  top: 8px;
`;

const Question = styled.div`
  color: #000;
  display: flex;
  justify-content: start;
  margin: 0.5em;

  &:hover {
    ${EditBox} {
      display: block;
    }
  }
`;

const Q = styled.div`
  text-align: left;
  width: 40%;
`;

const ResContainer = styled.div`
  padding-right: 1em;
  width: 60%;
`;

const Ul = styled.ul`
  list-style-type: disk;
`;

const QuestionSnippet = (props) => {
  const { answers, contentPageId, isSummary, questionList, snippet } = props;

  const [answerRequest, setAnswerRequest] = useState(false);
  const [text, setText] = useState(null);
  const [radio, setRadio] = useState(0);
  const [checkbox, setCheckbox] = useState([]);
  const [calendar, setCalendar] = useState({});
  const [dropdown, setDropdown] = useState(0);
  const [slider, setSlider] = useState(0);
  const [changed, setChanged] = useState(false);

  //  ********************* handle Answer functions ***************************
  const handleTextChange = (value) => {
    setText(value);
    setAnswerRequest(true);
  };

  const handleRadioChange = (value) => {
    setRadio(value);
    setChanged(true);
  };

  const handleSubmit = () => {
    if (changed) setAnswerRequest(true);
  };

  const handleCheckboxChange = (value) => {
    const tmp = [];
    value.forEach((item) => {
      tmp.push({
        questionResponseCheckboxId: item,
      });
    });
    setCheckbox(tmp);
    setChanged(true);
    // setAnswerRequest(true);
  };

  const handleCalendarChange = (value) => {
    setCalendar(value.target.value);
    setAnswerRequest(true);
  };

  const handleDropdownChange = (value) => {
    setDropdown(value);
    setAnswerRequest(true);
  };

  const handleSliderChange = (value) => {
    setSlider(value);
    setChanged(true);
  };

  const handleCreateAnswerError = () => {
    setAnswerRequest(false);
  };

  const handleCreateAnswerSuccess = () => {
    setAnswerRequest(false);
  };

  const ResPart = (
    type,
    availableResponses,
    sliderConf = {
      defaultValue: 0,
      max: 0,
      min: 0,
      step: 0,
      ticks: [
        {
          label: 'start',
          sliderValue: 0,
        },
      ],
      title: 'Slider',
    },
    questionId,
  ) => {
    let answer;
    switch (type) {
      case 'SL':
      case 'SingleText':
        answers.forEach((item) => {
          if (item.questionId === questionId) answer = item.text;
        });
        return <Input isChanged={handleTextChange} text={answer} />;
      case 'ML':
      case 'MultiText':
        answers.forEach((item) => {
          if (item.questionId === questionId) answer = item.text;
        });
        return <Textarea isChanged={handleTextChange} text={answer} />;
      case 'RB':
      case 'Radio':
        answers.forEach((item) => {
          if (item.questionId === questionId) {
            answer = item.questionResponseRadioId;
          }
        });
        return (
          <ResponsesInput
            answerId={answer}
            availableResponses={availableResponses}
            isChanged={handleRadioChange}
            itemId={questionId}
            resType={type}
            submit={handleSubmit}
          />
        );
      case 'CB':
      case 'Checkbox':
        answers.forEach((item) => {
          if (item.questionId === questionId) answer = item.selectedAnswers;
        });
        return (
          <ResponsesInput
            answerArr={answer}
            availableResponses={availableResponses}
            isChanged={handleCheckboxChange}
            itemId={questionId}
            resType={type}
            submit={handleSubmit}
          />
        );
      case 'DropDownList':
      case 'Dd':
        answers.forEach((item) => {
          if (item.questionId === questionId) {
            answer = item.questionResponseDropDownListId;
          }
        });
        return <ResponsesInput answerId={answer} availableResponses={availableResponses} isChanged={handleDropdownChange} resType={type} />;
      case 'Nu':
      case 'Number':
        answers.forEach((item) => {
          if (item.questionId === questionId) answer = item.numberAnswered;
        });
        return <NumberInput isChanged={handleTextChange} number={answer} />;
      case 'Slider':
      case 'Sl':
        answers.forEach((item) => {
          if (item.questionId === questionId) {
            answer = item.value;
          }
        });
        return (
          <Slider
            defaultValue={answer || sliderConf.defaultValue}
            isChanged={handleSliderChange}
            max={sliderConf.maxValue}
            min={sliderConf.minValue}
            step={sliderConf.step}
            submit={handleSubmit}
            ticks={sliderConf.ticks}
            title={sliderConf.description}
          />
        );
      case 'Match':
      case 'Ma':
        // eslint-disable-next-line no-case-declarations
        const uncategorised = sliderConf.responseSet.availableResponses.filter(({ correctPosition }) => correctPosition == null);
        return (
          <div>
            <Ul style={{ listStyle: 'none', padding: '0' }}>
              {sliderConf.matchItems.map((matchItem, matchItemIndex) => (
                <li>
                  <p>
                    <strong>{matchItem.text}</strong>
                  </p>
                  <Ul>
                    {sliderConf.responseSet.availableResponses
                      .filter(({ correctPosition }) => String(correctPosition) === String(matchItemIndex))
                      .map((availableResponse) => (
                        <li>
                          <p>{availableResponse.label}</p>
                        </li>
                      ))}
                  </Ul>
                </li>
              ))}
              {uncategorised.length > 0 && (
                <li>
                  <p style={{ fontStyle: 'italic' }}>Uncategorised</p>
                  <Ul>
                    {uncategorised.map((availableResponse) => (
                      <li>
                        <p>{availableResponse.label}</p>
                      </li>
                    ))}
                  </Ul>
                </li>
              )}
            </Ul>
          </div>
        );
      case 'Calendar':
      case 'Ca':
        answers.forEach((item) => {
          if (item.questionId === questionId) answer = item.dateAnswered;
        });
        return <DatePicker answer={answer} onChange={handleCalendarChange} />;
      case 'MultiResponseText':
      case 'MR':
        // eslint-disable-next-line no-case-declarations
        const responseNumber = Array.from({ length: sliderConf.minResponse });
        return (
          <>
            {responseNumber.map(() => (
              <Input />
            ))}
            <span
              styles={{
                width: '100%',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              <p style={{ textAlign: 'end' }}>{sliderConf.maxResponse - sliderConf.minResponse} responses remaining.</p>
            </span>
          </>
        );

      default:
        return null;
    }
  };

  const Response = ResPart(
    // eslint-disable-next-line no-underscore-dangle
    questionList.configuration.__typename,
    questionList.configuration && questionList.configuration.responseSet && questionList.configuration.responseSet.availableResponses,
    questionList.configuration,
    questionList.id,
  );

  return (
    <>
      <Question>
        <Q>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: questionList.question }} />
          <div style={{ color: 'grey' }}> {isSummary ? 'Snippet summary' : `{{Question.${questionList.id}}}`}</div>
          {questionList.identifier && <div style={{ color: 'grey' }}> {`{{identifier.${questionList.identifier}}}`}</div>}
          {isSummary && (
            <>
              <div style={{ color: 'grey' }}> {`{{Module.UserFlowStep.${snippet.userFlowStepId}}}`}</div>
              <div style={{ color: 'grey' }}> {`{{Question.${snippet.contentSnippetQuestionId}}}`}</div>
            </>
          )}
        </Q>
        <ResContainer>{Response}</ResContainer>
      </Question>
      {answerRequest && questionList.configuration.type === 'SingleText' && (
        <SingleLineAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          text={text}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
      {answerRequest && questionList.configuration.type === 'MultiText' && (
        <MultiLineAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          text={text}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
      {answerRequest && questionList.configuration.type === 'Number' && (
        <NumberAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          numberAnswered={text}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
      {answerRequest && questionList.configuration.type === 'Radio' && (
        <RadioAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          questionResponseRadioId={radio}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
      {answerRequest && questionList.configuration.type === 'Checkbox' && (
        <CheckboxAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          selectedAnswers={checkbox}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
      {answerRequest && questionList.configuration.type === 'Calendar' && (
        <CalendarAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          dateAnswered={calendar}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
      {answerRequest && questionList.configuration.type === 'DropDownList' && (
        <DropdownAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          questionResponseDropDownListId={dropdown}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
      {answerRequest && questionList.configuration.type === 'Slider' && (
        <SliderAnswer
          contentPageId={contentPageId}
          contentSnippetQuestionId={questionList.id}
          value={slider}
          onError={handleCreateAnswerError}
          onSuccessResult={handleCreateAnswerSuccess}
        />
      )}
    </>
  );
};

QuestionSnippet.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  questionList: PropTypes.instanceOf(Object).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      questionId: PropTypes.number,
    }),
  ),
  isSummary: PropTypes.bool,
  snippet: PropTypes.shape(),
};

QuestionSnippet.defaultProps = {
  answers: [],
  isSummary: false,
};

export default QuestionSnippet;
