import React from 'react';

import cx from 'classnames';

import { ConfirmationMessages } from '../ConfirmationMessages';
import { InputAddOn } from '../InputAddOn';
import { InputPrompt } from '../InputPrompt';
import { Label } from '../Label';

import { IMessage, InputFieldWrapperProps } from './InputFieldWrapper.interfaces';

import { getPromptText } from '../../helpers/validation/getPromptText';
import './InputFieldWrapper.css';

const DEFAULT_MESSAGE: IMessage = {
  error: '',
  success: '',
};

const InputFieldWrapper = ({
  children,
  id,
  isLabelHidden = false,
  label = '',
  labelFontWeight = 'light',
  message = DEFAULT_MESSAGE,
  charactersRemaining = '',
  iconBefore,
  iconAfter,
  type = 'input',
  labelType = 'label',
  tooltipText,
  isRequired = false,
  isEncouraged = false,
  hasPadding = true,
  isContentStacked = false,
  tooltipPosition = 'end',
  hasLabelPadding = false,
  isLoading = false,
}: InputFieldWrapperProps) => {
  const { error: errorMessage, success: successMessage } = message;

  const promptText = getPromptText(isRequired, isEncouraged);

  const isOutlined = !!promptText && ['radio', 'checkbox', 'toggle', 'custom', 'range'].includes(type);

  return (
    <div className={cx('c-input-field', { 'c-input-field--padding-bottom': hasPadding })}>
      <div
        className={cx('c-input-field__details', {
          'c-input-field__details--margin-bottom': !!tooltipText && !!promptText,
          'c-input-field__details--margin-bottom--small': hasLabelPadding,
          'c-input-field__details--tooltip-start': tooltipPosition === 'start',
        })}
      >
        <Label
          fontWeight={labelFontWeight}
          forId={id}
          isHidden={isLabelHidden}
          isLoading={isLoading}
          labelText={label}
          labelType={labelType}
          spacing={tooltipPosition === 'start' ? 'none' : undefined}
          tooltipPosition={tooltipPosition}
          tooltipText={tooltipText}
        />
      </div>
      <div
        className={cx('c-input-field__inner', {
          'c-input-field__inner--outlined': isOutlined,
          'is-invalid': Boolean(errorMessage),
          'is-correct': Boolean(successMessage),
        })}
        data-testid="qa-input-container"
      >
        {isContentStacked ? <div className="c-input-field__inner--stacked">{children}</div> : <>{children}</>}
        {/* For css targeting purposes we list this after but then use css ordering to pull to the front of the input */}
        {iconBefore && <InputAddOn position="before" {...iconBefore} />}
        {iconAfter && <InputAddOn position="after" {...iconAfter} />}
        {promptText && (
          <InputPrompt
            isCorrect={isOutlined && Boolean(successMessage)}
            isInvalid={isOutlined && Boolean(errorMessage)}
            text={promptText}
          />
        )}
      </div>
      <div className="c-input__condition">
        <div>
          <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
        </div>
        {charactersRemaining ? <span className="c-input__character-count">{charactersRemaining} characters left</span> : null}
      </div>
    </div>
  );
};

export { InputFieldWrapper };
