import { useEkardoMutation } from '../useEkardoMutation';

import { UPDATE_USER_FLOW_ACCESS_MODE_MUTATION } from './useUpdateUserFlowAccessMode.graphql';
import {
  IUpdateUserFlowAccessModeMutationGraphQLResponse,
  IUpdateUserFlowAccessModeMutationVariables,
  IHandleUpdateUserAccessModeParams,
  IUseUpdateUserAccessModeOptions,
  IUseUpdateUserAccessMode,
} from './useUpdateUserFlowAccessMode.interfaces';

const useUpdateUserFlowAccessMode = (options?: IUseUpdateUserAccessModeOptions): IUseUpdateUserAccessMode => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateUserAccessModeOptions);

  const [executeUpdateUserFlowAccessMode, { loading: isLoading }] = useEkardoMutation<
    IUpdateUserFlowAccessModeMutationGraphQLResponse,
    IUpdateUserFlowAccessModeMutationVariables
  >({
    mutation: mutation ?? UPDATE_USER_FLOW_ACCESS_MODE_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowAccessMode: { updateUserFlowAccessMode: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateUserFlowAccessMode = async ({ groupId, mode }: IHandleUpdateUserAccessModeParams) => {
    await executeUpdateUserFlowAccessMode({
      variables: {
        groupId,
        mode,
      },
    });
  };

  return {
    handleUpdateUserFlowAccessMode,
    isLoading,
  };
};

export { useUpdateUserFlowAccessMode };
