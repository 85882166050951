import React from 'react';

import PropTypes from 'prop-types';

import { FormHeaderTitle } from './styled/FormHeader';

const FormHeader = props => {
  const { title } = props;

  return <FormHeaderTitle>{title && title}</FormHeaderTitle>;
};

FormHeader.propTypes = {
  title: PropTypes.string,
};

FormHeader.defaultProps = {
  title: undefined,
};

export default FormHeader;
