import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

const GET_LINKED_PROJECTS = gql`
  query getAppLinkedProjects($appId: Int!) {
    app {
      authorisedProjects(appId: $appId) {
        items {
          id
        }
      }
    }
  }
`;

const getAllLinkedProjects = props => {
  const { appId, onError, onSuccessResult } = props;

  return (
    <Query
      fetchPolicy="network-only"
      query={GET_LINKED_PROJECTS}
      variables={{ appId }}
    >
      {({ loading, error, data }) => (
        <QueryRequest
          data={
            data &&
            Object.entries(data).length !== 0 &&
            data.app.authorisedProjects.items
          }
          error={error}
          isLoading={loading}
          onError={onError}
          onSuccessResult={onSuccessResult}
        />
      )}
    </Query>
  );
};

getAllLinkedProjects.propTypes = {
  appId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAllLinkedProjects;
