import React from 'react';

import PropTypes from 'prop-types';

import { Input } from './styled';

const SearchInput = (props) => {
  const { additionalAttributes = '', context = '', isDisabled = false, onChange, placeholder, value } = props;

  return (
    <Input>
      <input
        additionalattribute={additionalAttributes}
        disabled={isDisabled}
        placeholder={`${placeholder ?? 'Search'}${context && context}`}
        spellCheck="true"
        type="text"
        value={value}
        onChange={onChange}
      />
      <svg focusable="false" height="24" role="presentation" viewBox="0 0 24 24" width="24">
        <path
          d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
          fill="#b3b3b3"
        />
      </svg>
    </Input>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  additionalAttributes: PropTypes.string,
  context: PropTypes.string,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
};

export default SearchInput;
