export const RESPONSE_TYPE_ICON = {
  SummaryFeedbackConfigurationRadioType: 'radio',
};

// multi response, checkbox & range are yet to be implemented
// SummaryFeedbackConfigurationRankAndNoteType is for both single & multi line text - we need a way to differentiate between the two
export const RESPONSE_TYPE = {
  SummaryFeedbackConfigurationRadioType: 'RADIO',
  SummaryFeedbackConfigurationRankAndNoteType: 'SINGLE_TEXT',
  SummaryFeedbackConfigurationMultiResponseType: 'MULTI_RESPONSE',
  SummaryFeedbackConfigurationRangeType: 'RANGE',
};
