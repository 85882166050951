import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_BUTTON_CONTENT_SNIPPET = gql`
  mutation updateButtonContentSnippet(
    $snippetId: Int!
    $buttonBackgroundColor: String!
    $fontColor: String!
    $borderColor: String!
    $hoverColor: String!
    $hyperlink: String!
    $innerText: String!
    $pageId: Int
    $target: String!
    $style: EContentSnippetButtonStyle
    $title: String!
  ) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId, entityTitle: $title }
        contentSnippetButton: {
          buttonBackgroundColor: $buttonBackgroundColor
          fontColor: $fontColor
          hoverColor: $hoverColor
          hyperlink: $hyperlink
          innerText: $innerText
          pageId: $pageId
          target: $target
          style: $style
          backgroundColor: $buttonBackgroundColor
          borderColor: $borderColor
        }
      ) {
        id
      }
    }
  }
`;

const updateButtonContentSnippetRequest = ({
  snippetId,
  isVisible,
  buttonBackgroundColor,
  fontColor,
  hoverColor,
  hyperlink,
  innerText,
  pageId,
  style,
  target,
  borderColor,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_BUTTON_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        isVisible,
        buttonBackgroundColor,
        fontColor,
        hoverColor,
        hyperlink,
        innerText,
        pageId,
        target,
        style,
        borderColor,
        onError,
        onSuccessResult,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateButtonContentSnippetRequest.propTypes = {
  borderColor: PropTypes.string.isRequired,
  buttonBackgroundColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
  hyperlink: PropTypes.string.isRequired,
  innerText: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  style: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  pageId: PropTypes.number,
};

updateButtonContentSnippetRequest.defaultProps = {
  pageId: null,
};

export default updateButtonContentSnippetRequest;
