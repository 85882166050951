const animationTypeMap = Object.freeze({
  NONE: '',
  BOUNCE_IN: 'bounceIn',
  FADE_IN: 'fadeIn',
  ZOOM_IN: 'zoomIn',
  SLIDE_IN: 'slideIn',
  // The following animations do not have a direction property
  ROTATE_IN: 'rollIn',
  BOUNCE: 'bounce',
  FLASH: 'flash',
  PULSE: 'pulse',
  RUBBER_BAND: 'rubberBand',
  SHAKE: 'shake',
  HEAD_SHAKE: 'headShake',
  SWING: 'swing',
  TADA: 'tada',
  WOBBLE: 'wobble',
  JELLO: 'jello',
  HINGE: 'hinge',
  HEART_BEAT: 'heartBeat',
  JACK_IN_THE_BOX: 'jackInTheBox',
});

const animationDirectionMap = Object.freeze({
  TOP: 'Down',
  RIGHT: 'Right',
  BOTTOM: 'Up',
  LEFT: 'Left',
  NONE: '',
});

const animationSpeedMap = Object.freeze({
  500: 'faster',
  800: 'fast',
  2000: 'slow',
  3000: 'slower',
});

export { animationTypeMap, animationDirectionMap, animationSpeedMap };
