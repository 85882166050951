import React from 'react';

import PropTypes from 'prop-types';

import starImage from '../../static/img/star-gray.svg';
import starFilledImage from '../../static/img/star-yellow.svg';

function PopUpRating(props) {
  const {
    closePopup,
    onSubmit,
    appRating,
    onClickStar,
    handleInputs,
    comment,
  } = props;
  const starsArray = [
    {
      id: 1,
      icon: Math.round(appRating) < 1 ? starImage : starFilledImage,
    },
    {
      id: 2,
      icon: Math.round(appRating) < 2 ? starImage : starFilledImage,
    },
    {
      id: 3,
      icon: Math.round(appRating) < 3 ? starImage : starFilledImage,
    },
    {
      id: 4,
      icon: Math.round(appRating) < 4 ? starImage : starFilledImage,
    },
    {
      id: 5,
      icon: Math.round(appRating) < 5 ? starImage : starFilledImage,
    },
  ];
  return (
    <div className="popup">
      <div className="popup_inner">
        <h1>Give Rating</h1>
        <div className="flex-column align-items-start">
          <span>
            Rating<span className="required">*</span>
          </span>
          <div className="mb-2">
            {starsArray.map(star => (
              <button
                className="popup-app-rating button-none"
                onClick={onClickStar.bind(this, star.id)}
                type="button"
              >
                <img alt="Star" className="star" src={star.icon} />
              </button>
            ))}
          </div>
          <span>
            Comment<span className="required">*</span>
          </span>
          <textarea
            autoComplete="off"
            className="w-100"
            name="comment"
            onChange={handleInputs}
            style={{ padding: '1em', marginRight: '5px' }}
            value={comment}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: ' .6em 0 0em auto',
          }}
        >
          <button
            className="button--blue"
            onClick={onSubmit}
            style={{ display: 'flex' }}
            type="button"
          >
            SAVE
          </button>
          <button
            className="button--black"
            onClick={closePopup}
            style={{ display: 'flex', marginLeft: '0.2em' }}
            type="button"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
}

PopUpRating.propTypes = {
  closePopup: PropTypes.func.isRequired,
  appRating: PropTypes.string,
  comment: PropTypes.string,
  handleInputs: PropTypes.func,
  onClickStar: PropTypes.func,
  onSubmit: PropTypes.func,
};
PopUpRating.defaultProps = {
  appRating: 0,
  comment: '',
  handleInputs: () => {},
  onClickStar: () => {},
  onSubmit: () => {},
};
export default PopUpRating;
