import styled from 'styled-components';

export const Container = styled.button`
  align-items: center;
  background-color: ${({ disabled, theme }) => {
    let color;
    if (disabled) {
      color = '#e1e1e1';
    }
    if (!disabled) {
      color = theme === 'secondary' ? '#6d7a8a' : '#00c4cc';
    }
    return color;
  }};
  border: none;
  border-radius: 6px;
  color: ${({ disabled }) => (disabled ? '#000' : '#fff')};
  /*cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')}; */
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 2.6em;
  margin: ${({ isMarginUsed }) => (isMarginUsed ? '1em 0' : 0)};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  padding: 0 1.6em;
  position: relative;
  transition: 0.25s ease-in-out;
  ${({ styles }) => styles};

  &:hover {
    background-color: ${({ disabled, theme }) => {
      let color;
      if (!disabled) {
        color = theme === 'secondary' ? '#56616e' : '#00cfd6';
      }
      return color;
    }};
  }
  &:focus {
    outline:none;
  }
`;

export const ButtonContent = styled.div`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
`;

export const LoadingContainer = styled.span`
  left: 50%;
  margin-right: 16px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    fill: none;
    stroke: rgb(255, 255, 255);
    stroke-dasharray: 69.115px;
    stroke-dashoffset: 55.292px;
    stroke-linecap: round;
    stroke-width: 2px;
    transform-origin: center center;
    animation: loadSpin 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite
      normal none running;
  }

  @keyframes loadSpin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonGroup = styled.div`
  display: inline-flex;
  ${Container} + ${Container} {
    margin-left: 8px;

  }
`;
