import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  position: fixed;
  right: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  top: 0;
  width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  z-index: 3;
`;

export const Overlay = styled.div`
  background-color: rgba(99, 114, 130, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  height: 100%;
  opacity: ${({ isOpen }) => (isOpen ? 0.5 : 0)};
  position: absolute;
  top: 0;
  transition: opacity 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
  width: ${({ isOpen }) => (isOpen ? '100vw' : 0)};
  z-index: 2;
`;

export const SidePanel = styled.div`
  background-color: #f7f9fa;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 400px;
  overflow-y: scroll;
  position: fixed;
  padding: calc(71px + 1em) 1.2em 0.8em 1.2em;
  right: 0;
  transform: translateX(${({ isOpen }) => (isOpen ? 0 : '400px')});
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s,
    width 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
  width: 80%;
  z-index: 3;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-left: auto;
  width: 32px;

  svg {
    fill: rgb(66, 82, 110);
  }
  &:hover {
    background-color: rgba(9, 30, 66, 0.08);
  }
`;
