import gql from 'graphql-tag';

export const EXPORT_SESSIONS = gql`
  query ExportSessions($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    reportQueries {
      sessions(projectId: $projectId, dateTo: $dateTo, dateFrom: $dateFrom, ianaTimeZone: $ianaTimeZone, fileType: $fileType) {
        contentType
        s3Key
      }
    }
  }
`;
