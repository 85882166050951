import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';

import { AppCard } from '../MarketplacePage/styled';

import {
  Toolbar,
  LinkBtn,
  LinkIcon,
  SearchBar,
  SearchInput,
  StyleView,
  ListViewBtn,
  GridViewBtn,
  AppContainer,
} from './styled';

import NoDataAlerts from '../../components/UI/NoDataComp/NoDataAlert';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import GetMyAppListRequest from '../../middleware/MyAppDashboard/getMyImportedAppList';

const MyAppPage = props => {
  const {
    match: { params },
  } = props;
  const { project } = useContext(AppContext);

  const [appLoaded, setAppLoaded] = useState(false);
  const [importedApps, setApps] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);

  const handleGetAppsError = () => {
    setAppLoaded(true);
  };

  const handleGetAppsSuccess = data => {
    const tmpApps = data.map(d => d.node);
    setApps(tmpApps);
    setFilteredApps(tmpApps);
    setAppLoaded(true);
  };

  const handleSearch = e => {
    const { value } = e.target;
    const filtered = importedApps.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredApps(filtered);
  };
  const appList = (
    <AppContainer>
      {filteredApps.length > 0 ? (
        filteredApps.map(item => (
          <AppCard
            href={`/marketplace/app/${item.id}`}
            id={item.id}
            img="https://dev.assets.ekardo.com/logo/logo-dark.svg"
            key={item.id}
            name={item.title}
            owner=""
            rating={item.averageRateValue}
          />
        ))
      ) : (
        <NoDataAlerts />
      )}
    </AppContainer>
  );

  const toolbar = (
    <Toolbar>
      <LinkBtn href={`/marketplace/${project.id}`}>
        <LinkIcon>
          <span className="icon-chevron" />
        </LinkIcon>
        See more in marketplace
      </LinkBtn>
      <SearchBar>
        <SearchInput onChange={e => handleSearch(e)} placeholder="Search" />
      </SearchBar>
    </Toolbar>
  );

  const breadPath = [
    {
      id: 1,
      path: ``,
      title: 'My apps',
    },
  ];

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="Manage your apps"
        pageDescription="Manage all your apps"
        pageTitle="App management page"
        params={params}
      >
        {toolbar}
        <StyleView>
          <ListViewBtn />
          <GridViewBtn />
        </StyleView>
        {appList}
      </PageWrapper>
      {!appLoaded && Object.keys(project).length > 0 && (
        <GetMyAppListRequest
          onError={handleGetAppsError}
          onSuccessResult={handleGetAppsSuccess}
          projectId={project.id}
        />
      )}
    </>
  );
};

MyAppPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
};

export default MyAppPage;
