import React from 'react';

import BraftEditor from 'braft-editor';
import ColorPicker from 'braft-extensions/dist/color-picker';
import { ContentUtils } from 'braft-utils';
import PropTypes from 'prop-types';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/color-picker.css';
import './Editor.css';

const options = {
  theme: 'light',
  clearButtonText: 'Clear',
  closeButtonText: 'Close',
};

BraftEditor.use(ColorPicker(options));

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: BraftEditor.createEditorState(null),
    };
  }

  componentDidMount = () => {
    const { value } = this.props;
    this.setState({
      editorState: BraftEditor.createEditorState(value),
    });
  };

  componentDidUpdate = () => {
    const { isCleanText } = this.props;
    if (isCleanText) {
      this.clearContent();
    }
  };

  clearContent = () => {
    const { editorState } = this.state;
    this.setState({
      editorState: ContentUtils.clear(editorState),
    });
  };

  handleEditorChange = editorState => {
    const { getText, getContent } = this.props;
    this.setState({ editorState });
    getContent(editorState.toHTML());
    getText(editorState.getCurrentContent().getPlainText());
  };

  render() {
    const { editorState } = this.state;
    const controls = [
      'undo',
      'redo',
      'separator',
      'headings',
      'separator',
      'text-color',
      'bold',
      'italic',
      'underline',
      'strike-through',
      'emoji',
      'separator',
      'text-indent',
      'text-align',
      'separator',
      'list-ul',
      'list-ol',
      'link',
    ];

    return (
      <div className="my-component">
        <BraftEditor
          controls={controls}
          // extendControls={extendControls}
          language="en"
          onChange={this.handleEditorChange}
          value={editorState}
        />
      </div>
    );
  }
}

Editor.propTypes = {
  getContent: PropTypes.func,
  getText: PropTypes.func,
  isCleanText: PropTypes.bool,
  value: PropTypes.string,
};

Editor.defaultProps = {
  getContent: () => {},
  getText: () => {},
  isCleanText: false,
  value: '',
};

export default Editor;

// If we want to output styled content, we need to use this:

// <div className="braft-output-content"
// dangerouslySetInnerHTML={{__html: outputContent}}></div>
