import React from 'react';

function AccountMenu() {
  return (
    <nav className="account-menu">
      <ul>
        <li className="active" key="Profile">
          Profile
        </li>
      </ul>
    </nav>
  );
}

export default AccountMenu;
