export const userTypeTableHeadings = [
  {
    Header: 'Type',
    accessor: 'type',
    width: '40%',
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '40%',
  },
  {
    Header: '',
    accessor: 'button',
    width: '20%',
  },
];
