import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';

// slick-carousel css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LiveSlider.css';
import ImageIcon from '../Icon/ImageIcon';
import deleteIcon from '../../static/img/bin-icon.svg';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import client from '../../middleware/client';

const DELETE_SLIDE = gql`
  mutation deleteSliderItem($slideId: Int!) {
    slider {
      deleteSliderItem(sliderItemId: $slideId)
    }
  }
`;

const Wrapper = styled.div`
  padding: 2em;
`;

const SliderItem = styled.div``;

const Author = styled.p`
  font-size: 1.2em;
  font-style: italic;
  font-weight: bold;
  text-align: right;
`;

const Image = styled.img`
  max-height: 400px;
  width: 100%;
`;

const Quote = styled.div``;

const QuoteMarker = styled.span`
  bottom: -30px;
  font-size: 5em;
  line-height: 0em;
  margin-bottom: 0;
  position: relative;
`;

const QuoteMarker2 = styled(QuoteMarker)`
  bottom: -40px;
  left: calc(100% - 30px);
`;

const LiveSlider = (props) => {
  const { sliderData, sliderSetting, handleDelete } = props;

  const [executeDeleteSliderItem, { loading: isDeleteSliderItemLoading }] = useMutation(DELETE_SLIDE, {
    client,
  });

  const [settings, setSettings] = useState({});
  const AutoplayRef = useRef();
  const onDelete = (id) => {
    executeDeleteSliderItem({
      variables: {
        slideId: id,
      },
    });
    handleDelete(id);
  };

  useEffect(() => {
    const tmp = {
      // autoplay: true, //	Slideshow
      autoplay: sliderSetting.autoplay, //	Slideshow
      // autoplaySpeed: 2000, //	Slide show speed
      autoplaySpeed: sliderSetting.autoplaySpeed, //	Slide show speed
      arrows: sliderSetting.arrows, //	Control navigation
      dots: sliderSetting.dots, //	Direction navigation
      adaptiveHeight: false,
      infinite: sliderSetting.infinite, // Loop
      slidesToShow: sliderSetting.slidesToShow,
      slidesToScroll: sliderSetting.slidesToScroll,
      speed: sliderSetting.speed, //	Animation speed
      // Animation: fade / linear
      fade: sliderSetting.animation === 'FADE',
      // easing: true,
    };
    setSettings(tmp);
    if (sliderSetting.autoplay) {
      AutoplayRef.current.slickPlay();
    } else {
      AutoplayRef.current.slickPause();
    }
  }, [sliderSetting]);

  const DeleteButton = ({ id }) => {
    return (
      <button style={{ float: 'right' }} className="l-button" type="button" onClick={() => onDelete(id)}>
        <ImageIcon src={deleteIcon} width="1" />
      </button>
    );
  };

  const sliderContent = sliderData.map((item) => {
    if (item.type === 'QUOTE') {
      return (
        <>
          <DeleteButton id={item.id} />
          <SliderItem key={item.id}>
            <QuoteMarker>&ldquo;</QuoteMarker>
            <Quote>{item.quote}</Quote>
            <QuoteMarker2>&rdquo;</QuoteMarker2>
            <Author>{item.author ? `- ${item.author}` : null}</Author>
          </SliderItem>
        </>
      );
    }
    if (item.type === 'CODE') {
      return (
        <>
          <DeleteButton id={item.id} />
          <SliderItem key={item.id}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.text,
              }}
            />
          </SliderItem>
        </>
      );
    }
    if (item.type === 'IMAGE') {
      return (
        <>
          <DeleteButton id={item.id} />
          <SliderItem key={item.id}>
            <Image alt="asset" src={item.assetSrc} />
          </SliderItem>
        </>
      );
    }
    return null;
  });

  return (
    <Wrapper>
      <Slider {...settings} ref={AutoplayRef}>
        {sliderContent}
      </Slider>
    </Wrapper>
  );
};

LiveSlider.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  sliderData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  sliderSetting: PropTypes.shape({
    animation: PropTypes.string,
    arrows: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
    speed: PropTypes.number,
  }).isRequired,
};

LiveSlider.defaultProps = {};

export default LiveSlider;
