import { getAccessTokenCookie } from '@netfront/common-library';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import Cookies from 'universal-cookie';

export default function GeladaClient(checkToken = true, accessToken = '') {
  const cookies = new Cookies();
  const custom = cookies.get('customBuild');
  const projectId = custom === undefined ? null : custom.projectId;

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GELADA_API_URL,
  });

  const authLink = setContext((_, { headers }) => {
    let token = '';

    if (checkToken) {
      token = accessToken ?? getAccessTokenCookie();
    }

    return {
      headers: {
        ...headers,
        authorization: token,
        custom_build_guid: projectId || '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return client;
}
