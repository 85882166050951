import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import cx from 'classnames';

import { Icon } from '../Icon';
import { InputFieldWrapper } from '../InputFieldWrapper';
import { InputPrompt } from '../InputPrompt';
import { Skeleton } from '../Skeleton';

import { SelectProps } from './Select.types';

import { getPromptText } from '../../helpers/validation/getPromptText';

import './Select.css';

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      additionalClassNames,
      errorMessage,
      id,
      isDisabled = false,
      isEncouraged = false,
      isLabelHidden = false,
      isRequired = false,
      labelDefaultText = 'Select an option',
      labelText,
      name,
      onChange,
      options,
      successMessage,
      value,
      defaultOptionValue,
      ariaDescribedBy,
      tooltipText,
      hasPadding = true,
      tooltipPosition = 'end',
      labelFontWeight = 'light',
      hasLabelPadding = false,
      isLoading = false,
      resetValueAfterChange = false,
    }: SelectProps,
    ref,
  ) => {
    const [selectedValue, setSelectedValue] = useState<number | string>(value ?? '');
    const selectClassNames = cx(
      'c-select-list__input',
      additionalClassNames,
      { 'is-disabled': isDisabled },
      { 'is-required': isRequired && !value },
      { 'is-invalid': Boolean(errorMessage) },
    );

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value: updatedValue },
      } = event;
      onChange(event);
      setSelectedValue(updatedValue);
      if (resetValueAfterChange) {
        setTimeout(() => setSelectedValue(''), 500);
      }
    };

    useEffect(() => {
      setSelectedValue(value ?? '');
    }, [value]);

    if (isLoading)
      return (
        <>
          {!isLabelHidden && <Skeleton additionalClassNames="c-label" width="10rem" />}
          <Skeleton additionalClassNames={selectClassNames} height="2.5rem" />
        </>
      );

    return (
      <InputFieldWrapper
        hasLabelPadding={hasLabelPadding}
        hasPadding={hasPadding}
        id={id}
        isLabelHidden={isLabelHidden}
        label={labelText}
        labelFontWeight={labelFontWeight}
        message={{ error: errorMessage ?? '', success: successMessage ?? '' }}
        tooltipPosition={tooltipPosition}
        tooltipText={tooltipText}
        type="select"
      >
        <span className="c-select-list">
          <select
            ref={ref}
            aria-describedby={ariaDescribedBy ?? (errorMessage && `id_${name}_error`)}
            aria-invalid={Boolean(errorMessage)}
            className={selectClassNames}
            data-testid="qa-select"
            disabled={isDisabled}
            id={id}
            name={name}
            required={isRequired}
            value={selectedValue}
            onChange={handleChange}
          >
            {!defaultOptionValue && <option data-testid="qa-select-default-option" label={labelDefaultText} />}
            {labelDefaultText && defaultOptionValue && (
              <option
                data-testid="qa-select-default-option"
                disabled={selectedValue !== defaultOptionValue}
                id={defaultOptionValue}
                value={defaultOptionValue}
              >
                {labelDefaultText}
              </option>
            )}
            {options.map(({ isDisabled: isOptionDisabled, id: optionId, name: optionName, value: optionValue }) => (
              <option
                key={`${optionName}-${optionId}`}
                data-testid="qa-select-options"
                disabled={isOptionDisabled}
                id={String(optionId)}
                value={optionValue}
              >
                {optionName}
              </option>
            ))}
          </select>
          <Icon className="c-select-list__icon" id="id_caret_icon" />
          {getPromptText(isRequired, isEncouraged) && <InputPrompt text={getPromptText(isRequired, isEncouraged)} />}
        </span>
      </InputFieldWrapper>
    );
  },
);

Select.displayName = 'Select';

export { Select };
