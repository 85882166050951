import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CODE_CONTENT_SNIPPET = gql`
  mutation updateCodeContentSnippet($snippetId: Int!, $locationId: String!, $title: String!) {
    contentSnippet {
      updateContentSnippet(baseSnippet: { id: $snippetId, entityTitle: $title }, contentSnippetCode: { locationId: $locationId }) {
        id
      }
    }
  }
`;

const updateCodeContentSnippetRequest = ({ snippetId, locationId, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_CODE_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        locationId,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateCodeContentSnippetRequest.propTypes = {
  locationId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
};

export default updateCodeContentSnippetRequest;
