import React, { CSSProperties } from 'react';

import { IDBAsset } from '@netfront/common-library';
import cx from 'classnames';

import { CMSEventWrapper } from '../CMSEventWrapper/CMSEventWrapper';

import { CMSSectionProps } from './CMSSection.interfaces';

import './CMSSection.css';
import { getContentSection, useContentPageContext } from '../../contexts';
import { DBCssStyleIconXPositionType, DBCssStyleIconYPositionType } from '../../interfaces';

const CMSSection = ({ children, contentSectionId }: CMSSectionProps) => {
  const { state: contentPage } = useContentPageContext();

  const contentSection = getContentSection(contentSectionId, contentPage);

  const { background, contentEvent, cssStyle, visible: isVisible = true } = contentSection ?? {};
  const { class: cssStyleClass, icons = [] } = cssStyle ?? {};
  const backgroundImage: CSSProperties['backgroundImage'] = background ? `url("${background.presignedUrl}")` : undefined;

  const getIconPositionClass = (x: DBCssStyleIconXPositionType = 'LEFT', y: DBCssStyleIconYPositionType = 'TOP') =>
    `c-snippet__icon--${x.toLowerCase()}-${y.toLowerCase()}`;

  return (
    <React.Fragment>
      {isVisible && (
        <CMSEventWrapper contentEvent={contentEvent} id={`ek-accordion-${contentSectionId}`}>
          <div
            className={cx('c-cms-section', 'ek-section', cssStyleClass, {
              'c-cms-section--has-background-image': Boolean(background),
              [`ek-section-${contentSectionId}`]: contentSectionId,
            })}
            id={`ek-section-${contentSectionId}`}
            style={{
              backgroundImage,
              ...cssStyle,
            }}
          >
            {icons.map(({ asset, x, y }, index) => {
              const { alt, assetId, presignedUrl } = asset ?? ({} as IDBAsset);

              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`snippet-${contentSectionId}-icon-${assetId}-${index}`}
                  className={cx('c-snippet__icon', getIconPositionClass(x, y))}
                >
                  <img alt={alt} src={presignedUrl} />
                </div>
              );
            })}
            {children}
          </div>
        </CMSEventWrapper>
      )}
    </React.Fragment>
  );
};

export { CMSSection };
