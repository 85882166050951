import React, { useState, useEffect } from 'react';

import { NavigationButton } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { ConditionQuestionDetails } from '../ConditionQuestionTypes';

const UpsertCondition = ({ onUpdate, onCancel, onDelete, selectedCondition }) => {
  const [currentCondition, setCurrentCondition] = useState({
    contentPageId: undefined,
    contentSnippetQuestionId: undefined,
    isTemplate: false,
    questionActionId: undefined,
    id: undefined,
    questionResponseCheckboxId: undefined,
    questionResponseRadioId: undefined,
    title: '',
    type: 'checkbox',
    tempId: undefined,
    minValue: undefined,
    maxValue: undefined,
    selection: undefined,
    text: '',
    dateRangeType: undefined,
    minDate: undefined,
    maxDate: undefined,
    minTimeSpan: 0,
    maxTimeSpan: 0,
    selectedDate: undefined,
  });

  useEffect(() => {
    if (!selectedCondition) return;

    setCurrentCondition({
      ...selectedCondition,
      minTimeSpan: selectedCondition.minTimeSpan ?? 0,
      maxTimeSpan: selectedCondition.maxTimeSpan ?? 0,
    });
  }, [selectedCondition]);

  return (
    <>
      <NavigationButton
        additionalClassNames="c-action__back-to-action"
        direction="previous"
        iconId="id_caret_icon"
        rotationCssSuffix="90"
        text="Action"
        onClick={onCancel}
      />
      <ConditionQuestionDetails selectedCondition={currentCondition} onCancel={onCancel} onDelete={onDelete} onUpdate={onUpdate} />
    </>
  );
};

UpsertCondition.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  selectedCondition: PropTypes.shape({
    contentPageId: PropTypes.number,
    contentPageSnippetQuestionId: PropTypes.number,
    dateRangeType: PropTypes.string,
    id: PropTypes.number,
    isTemplate: PropTypes.bool,
    maxDate: PropTypes.instanceOf(Date),
    maxTimeSpan: PropTypes.number,
    maxValue: PropTypes.number,
    minDate: PropTypes.instanceOf(Date),
    minTimeSpan: PropTypes.number,
    minValue: PropTypes.number,
    questionResponseCheckboxId: PropTypes.number,
    questionResponseRadioId: PropTypes.number,
    selectedDate: PropTypes.instanceOf(Date),
    selection: PropTypes.string,
    tempId: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

export { UpsertCondition };
