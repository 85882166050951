import React, { useState } from 'react';

import { ConfirmationMessages } from '@netfront/ui-library';

import { getAriaDescribedBy, getInputFieldClassNames } from '../Snippets.helpers';

import { DecimalInputSnippetProps } from './DecimalInputSnippet.interfaces';

const DecimalInputSnippet = ({
  describedById,
  id,
  isDirty,
  isDisabled,
  isRequired,
  placeholder,
  message,
  maxValue,
  minValue,
  onChange,
  value,
}: DecimalInputSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message ?? {};

  const ariaDescribedBy = getAriaDescribedBy(describedById, id, Boolean(errorMessage));
  const classNames = getInputFieldClassNames('number', isDirty, Boolean(errorMessage), isRequired);

  const [currentValue, setCurrentValue] = useState<number>(value);
  return (
    <React.Fragment>
      <input
        aria-describedby={ariaDescribedBy}
        aria-invalid={Boolean(errorMessage)}
        className={classNames}
        disabled={isDisabled}
        id={id}
        max={maxValue}
        min={minValue}
        placeholder={placeholder}
        required={isRequired}
        step="0.01"
        type="number"
        value={currentValue}
        onChange={(event) => {
          const updatedValue = Number(event.currentTarget.value);

          setCurrentValue(updatedValue);
          if (!onChange) {
            return;
          }

          onChange(updatedValue);
        }}
        onClick={(event) => {
          event.currentTarget.focus();
        }}
        onMouseUp={(event) => event.currentTarget.blur()}
      />
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </React.Fragment>
  );
};

export { DecimalInputSnippet };
