import React, { useContext, useEffect, useRef, useState } from 'react';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import XLSX from 'xlsx';

import DropzoneArea from '../Dropzone/DropzoneArea';
import Field from '../Forms/Field';
import Form from '../Forms/Form';
import ImageIcon from '../Icon/ImageIcon';
import InputDropDown from '../InputDropDown/InputDropDown';
import Note from '../Notes/Note';
import { EKReactTable } from '../Table/EKReactTable/EKReactTable';
import TextArea from '../TextArea/TextArea';
import TextField from '../TextField/TextField';
import SwitchButton from '../UI/Button/SwitchButton';
import NotificationMessage from '../UI/NotificationMessage/NotificationMessage';
import PopUpMsg from '../UI/PopUp/PopUpMsg';
import SidebarTab from '../UI/Tab/SidebarTab';
import Tooltip from '../UI/Tooltip/Tooltips';

import { studentInviteTableColumns } from './ProjectPublicUserSideBar.constants';
import RightNavBar from './RightNavBar';
import { PasswordField, PasswordFieldButton, PasswordFieldTitle } from './styled';

import { EntityTypeKeys } from '../../constants/EntityTypeKeys';
import { NetfrontServices } from '../../constants/NetfrontServices';
import AppContext from '../../context/AppContext';
import CreateGroup from '../../middleware/Group/createGroupRequest';
import { RESET_PASSWORD } from '../../middleware/User/resetPassword';
import { SEND_FORGET_PASSWORD } from '../../middleware/User/sendForgetPasswordEmail';
import { UPDATE_USER_AS_ADMIN } from '../../middleware/User/updateUserAsAdmin';
import { UPDATE_USER_STATUS } from '../../middleware/User/updateUserStatus';
import InviteNewPublicUser from '../../middleware/UsersManagement/createPublicUserInvitation';
import UpdatePublicUserInfo from '../../middleware/UsersManagement/updatePublicUserDetail';
import { UPDATE_USER_SERVICES } from '../../middleware/UsersManagement/updateUserServices';
import CreateUserType from '../../middleware/UserType/createUserTypeRequest';
import addIcon from '../../static/img/close-icon.svg';
import { isEmpty } from '../../utils/object';
import { getApiErrorMessages } from '../../utils/utils';

const IMPERSONATE_USER = gql`
  mutation ($userId: Int!) {
    user {
      impersonateUser(userId: $userId)
    }
  }
`;

const GET_CUSTOM_FIELDS = gql`
  query getCustomFields($projectId: String!, $userId: Int!, $userTypeId: Int) {
    customField {
      getCustomFields(projectId: $projectId, scope: USER, userTypeId: $userTypeId) {
        id
        name
        __typename
        responses(userId: $userId) {
          id
          __typename
          ... on CustomFieldResponseNumberGraphType {
            value: number
            __typename
          }
          ... on CustomFieldResponseTextGraphType {
            value: text
            __typename
          }
          ... on CustomFieldResponseDateGraphType {
            value: dateTime
            __typename
          }
        }
      }
    }
  }
`;

const CREATE_MULTIPLE_USER_GROUP_INVITATION_REQUEST = gql`
  mutation createBulkInvitationRequest(
    $groupId: Int!
    $userTypeId: Int
    $customMessage: String
    $users: [UserInput]!
    $services: [ENetfrontService]
  ) {
    invitation {
      groupInvitationForMultipleUsers(
        invitation: {
          groupId: $groupId
          userTypeId: $userTypeId
          permission: READ
          customMessage: $customMessage
          users: $users
          services: $services
        }
      )
    }
  }
`;

const BulkUploadLink = styled.a`
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
`;

const DropdownListItemButton = styled.button`
  background-color: #fff !important;
  border: none;
  text-align: left;

  &:hover {
    background-color: #f4f5f7 !important;
  }
`;

const DropdownListItem = styled.li`
  padding: 0 0.5rem !important;
`;

const TABS = {
  profile: 0,
  password: 1,
  access: 2,
  notes: 3,
};

const ProjectPublicUserSideBar = (props) => {
  const { groups, membership, userTypes, onCancel } = props;
  const { project } = useContext(AppContext);
  const {
    settings: { impersonateUserLink },
  } = project;

  const [updateUserStatus] = useMutation(UPDATE_USER_STATUS, {
    client: Client(),
  });

  const [impersonateUser] = useMutation(IMPERSONATE_USER, {
    client: Client(),
    onCompleted: (data) => {
      const {
        user: { impersonateUser },
      } = data;
      window.open(`${impersonateUserLink}?t=${impersonateUser}`);
    },
  });

  const [sendForgotPassword] = useMutation(SEND_FORGET_PASSWORD, {
    client: Client(),
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    client: Client(),
  });

  const [updateUserAsAdmin, { loading: isUpdateUserAsAdminLoading }] = useMutation(UPDATE_USER_AS_ADMIN, {
    client: Client(),
    onCompleted(data) {
      updateUserServices({
        variables: {
          userIds: [selectedUser.id],
          services: newMember.services,
        },
      }).then(() => {
        setSendRequest(true);
      });
    },
    onError(error) {
      setMessage({ text: error.message, type: 'error' });
    },
  });

  const [updateUserServices] = useMutation(UPDATE_USER_SERVICES, {
    client: Client(),
  });

  const client = useRef(Client(true));
  const dropzoneRef = useRef();

  const { BONOBO, EKARDO, HOWLER, VERVET } = NetfrontServices;

  const [canInviteMember, setCanInviteMember] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState('');
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
  const [isSocialServices, setIsSocialServices] = useState(false);
  const [groupServices, setGroupServices] = useState([EKARDO, HOWLER, VERVET]);
  const [newMember, setNewMember] = useState({});
  const [newMembers, setNewMembers] = useState(undefined);
  const [notificationType, setNotificationType] = useState(undefined);
  const [notificationAlerts, setNotificationAlerts] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedBulkGroup, setSelectedBulkGroup] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [selectedBulkType, setSelectedBulkType] = useState({});
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [sendRequest, setSendRequest] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const [createType, setCreateType] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState(groups);
  const [filteredTypes, setFilteredTypes] = useState(userTypes);
  const [newGroup, setNewGroup] = useState('');
  const [newUserType, setNewUserType] = useState('');
  const [message, setMessage] = useState({ text: '', type: 'error' });
  const [password, setPassword] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [customFields, setCustomFields] = useState([]);
  const notesRef = useRef();

  const params = useParams();
  const { projectId } = params;

  // Bulk invite tab selected helper function
  const isBulkInviteTabSelected = () => !isEdit && selectedTab === 1;

  const [callGetCustomFields] = useLazyQuery(GET_CUSTOM_FIELDS, {
    client: client.current,
    onCompleted(data) {
      if (!data) {
        return;
      }
      const {
        customField: { getCustomFields },
      } = data;

      const updatedCustomFields = getCustomFields.map((customField) => {
        const { id: customFieldId, name: customFieldName, responses } = customField;
        return {
          customFieldId,
          customFieldName,
          text: responses.length ? responses[0].value : '',
        };
      });

      setCustomFields(updatedCustomFields);
    },
    variables: {
      projectId: project.id,
    },
  });

  const [bulkInviteUsers] = useMutation(CREATE_MULTIPLE_USER_GROUP_INVITATION_REQUEST, {
    client: client.current,
    onCompleted(data) {
      if (!data) {
        return;
      }
      setNewMembers(null);
      setSelectedBulkGroup({});
      setSelectedBulkType({});
      setInvitationMessage('');
      setNotificationType('success');
      setNotificationAlerts('Students invited successfully!');
    },
    onError(error) {
      setNotificationType('error');
      const errorMessage = getApiErrorMessages(error);
      setNotificationAlerts(errorMessage);
    },
  });

  const getButtonName = () => {
    if (selectedTab === TABS.notes) {
      return 'Save';
    }

    return isEdit ? 'Update' : 'Invite';
  };

  //  TODO: Will be updated after fix
  // const handleAddGroup = () => {
  //   if (newGroup === '') setMessage({ text: "Group name can't be empty", type: 'error' });
  //   else setCreateGroup(true);
  // };

  // TODO: Will be updated after fix
  // const handleAddType = () => {
  //   if (newUserType === '') setMessage({ text: "User type name can't be empty", type: 'error' });
  //   else setCreateType(true);
  // };

  const handleCreateError = (err) => {
    setSendRequest(false);
    setMessage({ text: err, type: 'error' });
  };

  const handleCreateGroupError = () => {
    setCreateGroup(false);
  };

  const handleCreateGroupSuccess = (data) => {
    setFilteredGroups([...groups, data]);
    setSelectedGroup(data);
    setCreateGroup(false);
  };

  const handleCreateSuccess = (data) => {
    setSendRequest(false);
    onCancel('create', {
      ...data,
      group: selectedGroup,
      userType: selectedType,
    });
  };

  const handleCreateTypeError = () => {
    setCreateType(false);
  };

  const handleCreateTypeSuccess = (data) => {
    setFilteredTypes([...userTypes, data]);
    setCreateType(false);
  };

  const handleDelete = () => {
    updateUserStatus({
      variables: {
        newUserStatus: 'DELETED',
        projectId: project.id,
        userIds: [selectedUser.id],
      },
    })
      .then(() => {
        onCancel('delete', { id: selectedUser.id });
      })
      .catch((error) => {
        const errorMessages = getApiErrorMessages(error);
        setMessage({
          text: errorMessages,
          type: 'error',
        });
      });
  };

  const handleDropDowns = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === 'groups') {
      const filtered = groups.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

      setNewGroup(value);

      if (value) {
        setFilteredGroups(filtered);
      } else {
        setFilteredGroups(groups);
      }
    }

    if (name === 'userTypes') {
      const filtered = userTypes.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

      setNewUserType(value);
      setSelectedType({ name: value });

      if (value) {
        setFilteredTypes(filtered);
      } else {
        setFilteredTypes(userTypes);
      }
    }

    if (!value) {
      setFilteredGroups(groups);
      setFilteredTypes(userTypes);
    }
  };

  const handleGroupUpdate = (group) => {
    // Bulk invite tab selected
    if (isBulkInviteTabSelected()) {
      setSelectedBulkGroup(group);
    } else {
      setSelectedGroup(group);
      setNewMember((member) => ({ ...member, ...(group.name ? { group } : {}) }));
    }
  };

  const handleInvitation = () => {
    const { email, firstname, group, lastname } = newMember;

    if (!firstname) {
      setMessage({
        text: 'First name is required',
        type: 'error',
      });
      return;
    }

    if (!lastname) {
      setMessage({
        text: 'Last name is required',
        type: 'error',
      });
      return;
    }

    if (!email) {
      setMessage({
        text: 'Email is required',
        type: 'error',
      });
      return;
    }

    if (!group) {
      setMessage({
        text: 'Group is required',
        type: 'error',
      });
      return;
    }

    setCanInviteMember(true);
    setSendRequest(true);
  };

  const handleBulkInvitation = () => {
    if (!isConfirmButtonDisabled) {
      if (!selectedBulkGroup.id) {
        setMessage({
          text: 'Group is required',
          type: 'error',
        });
        return;
      }

      bulkInviteUsers({
        variables: {
          groupId: selectedBulkGroup.id,
          userTypeId: selectedBulkType.id,
          customMessage: invitationMessage,
          users: newMembers,
          services: groupServices,
        },
      });
    } else {
      setNotificationType('info');
      setNotificationAlerts('Please upload your document!');
    }
  };

  const handleEnableDisableBulkInvitationConfirmButton = () => {
    setIsConfirmButtonDisabled(Boolean(newMembers));
  };

  const handleMsgDisappear = () => {
    setTimeout(() => {
      setMessage({ text: '', type: 'error' });
    }, 500);
  };

  const handleNewMemberInputs = (e) => {
    const { name, value } = e.target;
    // Bulk invite tab selected
    if (isBulkInviteTabSelected()) {
      setInvitationMessage(value);
    } else {
      setNewMember({ ...newMember, [name]: value });
    }
  };

  const handleOnDelete = () => {
    if (selectedTab === TABS.notes) {
      notesRef.current.deleteNote();
    } else {
      handleDelete();
    }
  };

  const handleEdit = () => {
    updateUserAsAdmin({
      variables: {
        communityName: newMember.communityName,
        email: newMember.email,
        firstname: newMember.firstname,
        lastname: newMember.lastname,
        phoneNumber: newMember.phoneNumber,
        userId: newMember.id,
      },
    });
  };

  const handleSave = () => {
    if (selectedTab === TABS.notes) {
      notesRef.current.addOrUpdateNote();
    }
    // Bulk invite tab selected
    else if (isBulkInviteTabSelected()) {
      handleBulkInvitation();
    } else if (isEdit) {
      handleEdit();
    } else {
      handleInvitation();
    }
  };

  const handleSendForgotPassword = () => {
    sendForgotPassword({
      variables: {
        userId: selectedUser.id,
        projectId: project.id,
      },
    })
      .then(() => {
        setMessage({
          text: `The reset password email has sent to ${newMember.email}.`,
          type: 'success',
        });
      })
      .catch((error) => {
        const errorMessages = getApiErrorMessages(error).join(', ');
        setMessage({
          text: errorMessages,
          type: 'error',
        });
      });
  };

  const handleSelectedTab = (tabId) => {
    setSelectedTab(tabId);
  };

  const handleToggleActive = (isActive) => {
    updateUserStatus({
      variables: {
        newUserStatus: isActive ? 'ACTIVE' : 'INACTIVE',
        projectId: project.id,
        userIds: [selectedUser.id],
      },
    })
      .then(() => {
        setNewMember({
          ...newMember,
          status: isActive ? 'ACTIVE' : 'INACTIVE',
        });
        onCancel('update', {
          ...newMember,
          status: isActive ? 'ACTIVE' : 'INACTIVE',
        });
      })
      .catch((error) => {
        const errorMessages = getApiErrorMessages(error).join(', ');
        setMessage({
          text: errorMessages,
          type: 'error',
        });
      });
  };

  const handleToggleServices = (value) => {
    if (isBulkInviteTabSelected()) {
      setGroupServices(value ? [...groupServices, BONOBO] : [EKARDO, HOWLER, VERVET]);
      setIsSocialServices(!isSocialServices);
      return;
    }

    const { services } = project;

    const updatedServices = !value ? services.filter((service) => service !== BONOBO) : services;

    setNewMember({ ...newMember, services: updatedServices });
  };

  const handleTypeUpdate = (userType) => {
    if (isBulkInviteTabSelected()) {
      setSelectedBulkType(userType);
      return;
    }

    setSelectedType(userType);
    setNewMember((member) => {
      const updatedMember = { ...member, userType };

      if (!userType.name) {
        delete updatedMember.userType;
      }

      return updatedMember;
    });
  };

  const handleImpersonateUser = () => {
    impersonateUser({
      variables: {
        userId: selectedUser.id,
      },
    });
  };

  const handleUpdateError = (err) => {
    setSendRequest(false);
    setMessage({ text: err, type: 'error' });
  };

  const handleUpdatePassword = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdatePasswordBtn = () => {
    const { newPassword = '', repeatPassword = '' } = password;

    if (newPassword.length < 6) {
      setMessage({
        text: 'New passwords must have minimun 6 characters.',
        type: 'error',
      });
      return;
    }

    if (!repeatPassword.length) {
      setMessage({
        text: 'Repeat password is required',
        type: 'error',
      });
      return;
    }

    if (newPassword !== repeatPassword) {
      setMessage({
        text: 'Passwords do not match',
        type: 'error',
      });
      return;
    }

    resetPassword({
      variables: {
        newPassword: password.newPassword,
        projectId: project.id,
        userId: selectedUser.id,
      },
    })
      .then(() => {
        setMessage({
          text: `${newMember.firstname}'s password has been reset.`,
          type: 'success',
        });
      })
      .catch((error) => {
        const errorMessages = getApiErrorMessages(error).join(', ');
        setMessage({
          text: errorMessages,
          type: 'error',
        });
      });
  };

  const handleUpdateSuccess = (data) => {
    setSendRequest(false);
    data.status = data.user.status;
    data.userId = data.user.id;
    onCancel('update', data);
  };

  function handleReadExcel(input) {
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, {
        type: 'binary',
      });

      const sheet = Object.values(workbook.Sheets)[0];
      const contentAsObject = XLSX.utils.sheet_to_json(sheet);
      setNewMembers(contentAsObject);
    };

    reader.readAsBinaryString(input);
  }

  const handleFileDrop = (files) => {
    handleReadExcel(files[0]);
    handleEnableDisableBulkInvitationConfirmButton();
  };

  const handleResetUpload = () => {
    setNewMembers(null);
    handleEnableDisableBulkInvitationConfirmButton();
  };

  const handleHideNotification = () => {
    setNotificationAlerts('');
  };

  useEffect(() => {
    if (isEmpty(membership)) {
      return;
    }

    const { group, user, userType } = membership;
    const { firstname, id, status, communityName, credential, lastname, title: position } = user;
    const { email, phoneNumber, services } = credential || {};
    const { name: groupName } = group || {};

    setIsEdit(true);

    setNewMember({
      communityName,
      email,
      firstname,
      group: groupName,
      id,
      lastname,
      phoneNumber,
      position,
      services,
      status: status === 'ACTIVE',
    });

    if (group) {
      setSelectedGroup(group);
    }

    if (user) {
      setSelectedUser(user);
      callGetCustomFields({
        variables: {
          userId: user.id,
          userTypeId: userType?.id,
        },
      });
    }

    if (userType) {
      setSelectedType(userType);
    }
  }, [membership]);

  const item0 = (
    <Form name="member">
      <Field id="userId" label="User Id">
        <TextField name="userId" value={newMember.id} isDisabled />
      </Field>

      <Field id="firstname" label="First name">
        <TextField name="firstname" value={newMember.firstname} onChange={handleNewMemberInputs} />
      </Field>

      <Field id="lastname" label="Last name">
        <TextField name="lastname" value={newMember.lastname} onChange={handleNewMemberInputs} />
      </Field>

      <Field id="email" label="Email">
        <TextField name="email" value={newMember.email} onChange={handleNewMemberInputs} />
      </Field>

      <Field id="phonenumber" label="Phone Number">
        <TextField name="phoneNumber" value={newMember.phoneNumber} onChange={handleNewMemberInputs} />
      </Field>

      <Field id="group" label="Group">
        <InputDropDown
          name="group"
          placeholder={selectedGroup.name}
          value={!isEmpty(selectedGroup) ? selectedGroup.name : newMember.group}
          onChange={handleDropDowns}
          onKeyUp={() => handleGroupUpdate(selectedGroup)}
        >
          <ul>
            {filteredGroups.map((group) => (
              <DropdownListItem key={group.id}>
                <DropdownListItemButton onClick={() => handleGroupUpdate(group)}>{group.name}</DropdownListItemButton>
              </DropdownListItem>
            ))}
          </ul>
          {/* TODO: Add new button is not working */}
          {/* <button onClick={handleAddGroup} type="button">
            Add new group
          </button> */}
        </InputDropDown>
      </Field>

      <Field id="userTypes" label="User type">
        <InputDropDown
          name="userTypes"
          placeholder={selectedType.name}
          value={!isEmpty(selectedType) ? selectedType.name : newMember.userType}
          onChange={handleDropDowns}
          onKeyUp={() => handleTypeUpdate(selectedType)}
        >
          <ul>
            {filteredTypes.map((type) => (
              <DropdownListItem key={type.id}>
                <DropdownListItemButton onClick={() => handleTypeUpdate(type)}>{type.name}</DropdownListItemButton>
              </DropdownListItem>
            ))}
          </ul>
          {/* TODO: Add new button is not working */}
          {/* <button onClick={handleAddType} type="button">
            Add new user type
          </button> */}
        </InputDropDown>
      </Field>
      {customFields.map((customField) => {
        const { customFieldId, customFieldName, text } = customField;
        return (
          <Field key={`custom-field-${customFieldId}`} id={customFieldId} label={customFieldName}>
            <TextField name={customFieldName} value={text} isDisabled />
          </Field>
        );
      })}
      {isEdit && (
        <Field id="status" label="Active">
          <SwitchButton defaultChecked={newMember.status} id={0} onChange={() => handleToggleActive(!newMember.status)} />
        </Field>
      )}
      {!isEdit && project.services.includes(NetfrontServices.BONOBO) && (
        <Field id="access" label="Forum | Social access">
          <SwitchButton defaultChecked={false} id={1} onChange={handleToggleServices} />
        </Field>
      )}
      {impersonateUserLink && isEdit && (
        <Field id="impersonate" label="Impersonate">
          <button className="button--blue mt-4 mr-3" type="button" onClick={handleImpersonateUser}>
            Impersonate the user
          </button>
        </Field>
      )}
      {!isEdit && (
        <Field id="message" label="Invite message (optional)">
          <TextArea name="message" onChange={handleNewMemberInputs} />
        </Field>
      )}
    </Form>
  );

  const item1 = (
    <Form>
      {isEdit && (
        <>
          <Field id="resetPasswordEmail" label="Send reset password email">
            <PasswordFieldButton type="button" onClick={handleSendForgotPassword}>
              Send email
            </PasswordFieldButton>
          </Field>
          <Field id="updatePassword" label="Update user's password">
            <PasswordField>
              <PasswordFieldTitle htmlFor="newPassword">New password</PasswordFieldTitle>
              <TextField
                id="newPassword"
                name="newPassword"
                value={password.newPassword}
                isPassword
                onChange={(e) => handleUpdatePassword(e)}
              />
              <PasswordFieldTitle htmlFor="repeatPassword">Repeat password</PasswordFieldTitle>
              <TextField
                id="repeatPassword"
                name="repeatPassword"
                value={password.repeatPassword}
                isPassword
                onChange={(e) => handleUpdatePassword(e)}
              />
              <PasswordFieldButton type="button" onClick={handleUpdatePasswordBtn}>
                Update
              </PasswordFieldButton>
            </PasswordField>
          </Field>
        </>
      )}
      {!isEdit && (
        <>
          <div className="h-flex h-justify-content-between h-align-items-center">
            <label className="c-input-title h-margin-top-sm h-margin-bottom-sm h-margin-right-sm" htmlFor="download-template">
              Download template
            </label>
            <Tooltip text="To invite multiple users, download and complete the Excel document" />
          </div>
          <div className="h-flex h-align-items-center" id="download-template">
            <BulkUploadLink
              href="https://ekardo-assets-prod.s3.ap-southeast-2.amazonaws.com/templates/user_multiple_invite_template.xlsx"
              download
            >
              Click to download your Excel template
            </BulkUploadLink>
          </div>
          <div className="h-flex h-justify-content-between h-align-items-center">
            <div className="h-flex h-align-items-center">
              <label className="c-input-title h-margin-top-sm h-margin-bottom-sm h-margin-right-sm" htmlFor="bulk-upload-dropzone">
                Upload users
              </label>
            </div>
            {!newMembers ? (
              <Tooltip text="To preview the users, upload your completed template into dropzone" />
            ) : (
              <button className="c-icon-button" type="button" onClick={handleResetUpload}>
                <ImageIcon src={addIcon} />
              </button>
            )}
          </div>
          {!newMembers ? (
            <DropzoneArea dropRef={dropzoneRef} fileType="" id="bulk-upload-dropzone" onDrop={(file) => handleFileDrop(file)} />
          ) : (
            <EKReactTable columns={studentInviteTableColumns} data={newMembers} />
          )}
          {notificationAlerts && notificationType && (
            <NotificationMessage message={notificationAlerts} type={notificationType} onHideNotificationMessage={handleHideNotification} />
          )}
          <Field id="groups" label="Groups">
            <InputDropDown
              name="groups"
              placeholder={selectedBulkGroup.name}
              value={!isEmpty(selectedBulkGroup) ? selectedBulkGroup.name : ''}
              onChange={handleDropDowns}
              onKeyUp={() => handleGroupUpdate(selectedBulkGroup)}
            >
              <ul>
                {filteredGroups.map((group) => (
                  <DropdownListItem key={group.id}>
                    <DropdownListItemButton onClick={() => handleGroupUpdate(group)}>{group.name}</DropdownListItemButton>
                  </DropdownListItem>
                ))}
              </ul>
              {/* TODO: Add new button is not working */}
              {/* <button onClick={handleAddGroup} type="button">
                Add new group
              </button> */}
            </InputDropDown>
          </Field>
          <Field id="userTypes" label="User types">
            <InputDropDown
              name="userTypes"
              placeholder={selectedBulkType.name}
              value={!isEmpty(selectedBulkType) ? selectedBulkType.name : ''}
              onChange={handleDropDowns}
              onKeyUp={() => handleTypeUpdate(selectedBulkType)}
            >
              <ul>
                {filteredTypes.map((type) => (
                  <DropdownListItem key={type.id}>
                    <DropdownListItemButton onClick={() => handleTypeUpdate(type)}>{type.name}</DropdownListItemButton>
                  </DropdownListItem>
                ))}
              </ul>
              {/* TODO: Add new button is not working */}
              {/* <button onClick={handleAddType} type="button">
                Add new user type
              </button> */}
            </InputDropDown>
          </Field>
          <Field id="message" label="Invite message (optional)">
            <TextArea name="message" value={invitationMessage} onChange={handleNewMemberInputs} />
          </Field>
          <Field id="access" label="Forum | Social access">
            <SwitchButton defaultChecked={false} id={1} onChange={handleToggleServices} />
          </Field>
        </>
      )}
    </Form>
  );

  const item2 = (
    <Form>
      <Field id="access" label="Forum | Social access">
        {newMember.services !== undefined && (
          <SwitchButton defaultChecked={newMember.services.includes('BONOBO')} id={1} onChange={handleToggleServices} />
        )}
      </Field>
      <Field id="communityName" label="Display name">
        <TextField name="communityName" value={newMember.communityName} onChange={handleNewMemberInputs} />
      </Field>
    </Form>
  );

  return (
    <>
      <RightNavBar confirmBtnName={getButtonName()} hasDelete={isEdit} onCancel={onCancel} onDelete={handleOnDelete} onSave={handleSave}>
        <div>
          <h3>{!isEdit ? 'Invite members' : `Edit ${newMember.firstname}'s details`}</h3>
          <SidebarTab
            item0={item0}
            item1={item1}
            item2={isEdit && newMember.services ? item2 : null}
            item3={
              isEdit &&
              selectedUser && (
                <Note ref={notesRef} entityId={selectedUser.id} entityType={EntityTypeKeys.User} errorMessageHandler={setMessage} />
              )
            }
            label0={isEdit ? 'Profile' : 'Individual'}
            label1={isEdit ? 'Password' : 'Bulk upload'}
            label2={isEdit && newMember.services ? 'Access' : ''}
            label3={isEdit ? 'Notes' : ''}
            selectedTab={handleSelectedTab}
          />
          {message.text !== '' && <PopUpMsg message={message.text} msgDisappear={handleMsgDisappear} type={message.type} />}
        </div>
      </RightNavBar>
      {canInviteMember && !isEdit && sendRequest && (
        <InviteNewPublicUser
          customMessage={newMember.message}
          emailInvited={newMember.email}
          firstName={newMember.firstname}
          groupId={selectedGroup.id}
          lastName={newMember.lastname}
          projectId={project.id}
          services={newMember.services}
          userTypeId={selectedType.id}
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
        />
      )}
      {isEdit && sendRequest && (
        <UpdatePublicUserInfo
          groupId={selectedGroup.id}
          membershipId={membership.id}
          userTypeId={selectedType.id}
          onError={handleUpdateError}
          onSuccessResult={handleUpdateSuccess}
        />
      )}
      {createGroup && (
        <CreateGroup
          description={newGroup}
          name={newGroup}
          projectId={project.id}
          onError={handleCreateGroupError}
          onSuccessResult={handleCreateGroupSuccess}
        />
      )}
      {createType && (
        <CreateUserType
          name={newUserType}
          permission="READ"
          projectId={project.id}
          userTypeCode={newUserType.toUpperCase()}
          approvalRequired
          onError={handleCreateTypeError}
          onSuccessResult={handleCreateTypeSuccess}
        />
      )}
    </>
  );
};

ProjectPublicUserSideBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    group: PropTypes.shape({
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    projectPermission: PropTypes.string,
    user: PropTypes.shape({
      communityName: PropTypes.string,
      credential: PropTypes.shape({
        email: PropTypes.string,
        services: PropTypes.arrayOf(PropTypes.string),
      }),
      email: PropTypes.string,
      firstname: PropTypes.string,
      id: PropTypes.number,
      lastname: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
    }),
    userId: PropTypes.number,
    userType: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  userTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

ProjectPublicUserSideBar.defaultProps = {
  groups: [],
  user: {},
  userTypes: [],
};

export default ProjectPublicUserSideBar;
