/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';

import { capitalCase } from 'change-case';
import cx from 'classnames';
import { useSortBy, useTable, useRowSelect, ColumnInstance } from 'react-table';

import { Checkbox } from '../CheckboxGroup/Checkbox';
import { FlexContainer } from '../FlexContainer';
import { Icon } from '../Icon';
import { IOption, Select } from '../Select';
import { Skeleton } from '../Skeleton';
import { Spacing } from '../Spacing';

import { ROW_SELECT_COLUMN_WIDTH } from './Table.constants';
import { TableProps, ITableRequiredParameters } from './Table.interfaces';

import './Table.css';

// eslint-disable-next-line @typescript-eslint/ban-types
function Table<T extends ITableRequiredParameters>({
  addIconUpToHeaderNumber,
  additionalClassNames,
  areColumnHeadingsSortable = true,
  bulkActions,
  bulkActionPlaceHolder = 'Select a bulk action',
  cellAlignment = 'left',
  columnNumbers = [],
  columns,
  columnWidth = 'auto',
  data,
  emptyTableMessage = 'No matching records found in the table',
  hasButton = false,
  headerAlignment = 'left',
  headerIcon,
  isLinkRow,
  isStripedRows,
  tableName = 'more',
  onSelectRows,
  url,
  legend,
  isLoading = false,
}: TableProps<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { selectedRowIds },
    prepareRow,
    toggleAllRowsSelected,
  } = useTable<T>(
    {
      columns,
      data,
    },
    useSortBy,
    useRowSelect,
    ({ visibleColumns }) => {
      return onSelectRows
        ? visibleColumns.push((allColumns: ColumnInstance<T>[]) => [
            {
              width: ROW_SELECT_COLUMN_WIDTH,
              id: 'selection',
              Header: () => <></>,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // This is not typed in react tables and is having issues... Update to new library https://tanstack.com/table/v8 table V8 new react table... a lot faster
              Cell: ({ row }) => (
                <Checkbox
                  iconId="id_minus_icon"
                  id={`select-row-${row.id}`}
                  labelText="Select row"
                  name="selectRow"
                  value=""
                  isLabelHidden
                  onChange={() => null}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  {...row.getToggleRowSelectedProps()}
                />
              ),
            },
            ...allColumns,
          ])
        : null;
    },
  );

  const [isSelectedRows, setIsSelectedRows] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const hasSelectRowOption = !!onSelectRows;
  const tableClassNames = cx('c-table', additionalClassNames);
  const selectedColumns = columnNumbers.map((item) => `c-table__tbody-td--${item}--${columnWidth}`).join(' ');

  const handleUpdateRowSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    if (!bulkActions) {
      return;
    }

    const ROW_SELECT_ACTIONS = bulkActions.reduce((accumulator, current) => ({ ...accumulator, [current.id]: current.action }), {});

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const handleBulkAction = ROW_SELECT_ACTIONS[value] as () => void;

    handleBulkAction();
  };

  const handleToggleSelectAll = (isSelectAll: boolean) => {
    setIsAllSelected(isSelectAll);
    toggleAllRowsSelected(isSelectAll);
  };

  useEffect(() => {
    if (!onSelectRows) {
      return;
    }

    const selectedRowIdKeys = Object.keys(selectedRowIds);

    setSelectedIds(selectedRowIdKeys);
    setIsSelectedRows(Boolean(selectedRowIdKeys.length));
    setIsAllSelected(selectedRowIdKeys.length === rows.length);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const selectedDataIds = selectedRowIdKeys.map((selectedRowId: string) => String(data[Number(selectedRowId)]?.id));
    onSelectRows(selectedDataIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  const ROW_SELECT_ACTIONS_OPTIONS: IOption[] | undefined = bulkActions?.map(({ id, label }) => {
    return {
      id,
      name: label,
      value: id,
    };
  });

  if (isLoading)
    return (
      <>
        <Skeleton height="2.5rem" />
        <Skeleton count={5} height="2rem" />
      </>
    );

  return (
    <div>
      {ROW_SELECT_ACTIONS_OPTIONS ?? legend ? (
        <Spacing size="large">
          <FlexContainer justifyContent={ROW_SELECT_ACTIONS_OPTIONS && selectedIds.length ? 'space-between' : 'flex-end'}>
            {ROW_SELECT_ACTIONS_OPTIONS && selectedIds.length ? (
              <FlexContainer gap="small" justifyContent="start">
                <FlexContainer justifyContent="start">
                  <button
                    className="c-table-row-action c-table-row-action__deselect-all"
                    disabled={!isSelectedRows}
                    type="button"
                    onClick={() => handleToggleSelectAll(false)}
                  >
                    <div className={`c-table-row-action__icon-container ${isSelectedRows ? 'has-selected-rows' : ''}`}>
                      <Icon id="id_minus_icon" />
                    </div>
                    <span className="c-table-row-action__text">{selectedIds.length} selected</span>
                  </button>

                  <button className="c-table-row-action" disabled={isAllSelected} type="button" onClick={() => handleToggleSelectAll(true)}>
                    <span className="c-table-row-action__text">Select all</span>
                  </button>
                </FlexContainer>
                <form>
                  <Select
                    hasPadding={false}
                    id="row-select-actions"
                    labelDefaultText={bulkActionPlaceHolder}
                    labelText="More actions"
                    name={`${tableName}Actions`}
                    options={ROW_SELECT_ACTIONS_OPTIONS}
                    isLabelHidden
                    resetValueAfterChange
                    onChange={handleUpdateRowSelection}
                  />
                </form>
              </FlexContainer>
            ) : (
              <></>
            )}
            {legend && <div className="c-table-row-action">{legend}</div>}
          </FlexContainer>
        </Spacing>
      ) : null}
      <div className="c-table-container">
        <table className={tableClassNames} {...getTableProps()}>
          <thead className="c-table__thead">
            {headerGroups.map(({ getHeaderGroupProps, headers }) => (
              <tr className="c-table__thead-tr" {...getHeaderGroupProps()}>
                {headers.map(
                  ({ getHeaderProps, getSortByToggleProps, isSorted, isSortedDesc, minWidth, render, width = undefined }, index) => {
                    const tableSortByToggleProps = areColumnHeadingsSortable ? getSortByToggleProps() : undefined;

                    return (
                      <th
                        className={`c-table__thead-th ${hasSelectRowOption && index === 0 ? 'c-table__thead-th--select' : ''}`}
                        {...getHeaderProps({
                          style: {
                            minWidth,
                            width,
                          },
                        })}
                        {...tableSortByToggleProps}
                      >
                        <div className="c-table__thead-th-contents-container">
                          <div
                            className={cx('c-table__thead-th-contents-text', {
                              [`c-table__thead-th-contents-text--${headerAlignment}`]: headerAlignment,
                            })}
                          >
                            {headerIcon && addIconUpToHeaderNumber && index + 1 <= addIconUpToHeaderNumber ? (
                              <Icon className="c-table__thead-th__icon" id={headerIcon} />
                            ) : null}
                            {render('Header')}
                          </div>
                          {isSorted && (
                            <div
                              className={`c-table__thead-th-contents-sort ${isSortedDesc ? '' : 'c-table__thead-th-contents-sort--asc'}`}
                            >
                              <Icon className="c-table__sort-icon" id={isSortedDesc ? 'id_caret_down_icon' : 'id_caret_up_icon'} />
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  },
                )}
              </tr>
            ))}
          </thead>
          {Boolean(rows.length) && (
            <tbody className="c-table__tbody" {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                const { cells, getRowProps } = row;

                return (
                  <tr
                    className={cx('c-table__tbody-tr', {
                      ['c-table__tbody-tr--striped']: isStripedRows,
                    })}
                    {...getRowProps()}
                  >
                    {cells.map(({ column: { id, minWidth, width = undefined }, getCellProps, render }, index) => {
                      const classNames = cx('c-table__tbody-td', {
                        ['c-table__tbody-td--has-button']: hasButton,
                        [selectedColumns]: Boolean(columnNumbers.length) && columnWidth,
                        [`c-table__tbody-td--${cellAlignment}`]: cellAlignment,
                        'c-table__tbody-td--select': hasSelectRowOption && index === 0,
                      });

                      const linkCell = (
                        <td
                          className={classNames}
                          data-hr={capitalCase(id)}
                          {...getCellProps({
                            style: {
                              minWidth,
                              width: width,
                            },
                          })}
                        >
                          <div className="c-table__tbody__td-content">
                            <a className="c-table-row-link" href={url}>
                              {render('Cell')}
                            </a>
                          </div>
                        </td>
                      );

                      const textCell = (
                        <td
                          className={classNames}
                          data-hr={capitalCase(id)}
                          {...getCellProps({
                            style: {
                              minWidth,
                              width: width,
                            },
                          })}
                        >
                          <div className="c-table__tbody__td-content">{render('Cell')}</div>
                        </td>
                      );

                      if (!isLinkRow) {
                        return textCell;
                      }

                      if (hasButton && index + 1 === cells.length) {
                        return textCell;
                      }

                      return linkCell;
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>

        {Boolean(!rows.length) && (
          <div className="c-table__empty">
            <span className="c-table__empty-message">{emptyTableMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export { Table };
