import gql from 'graphql-tag';

const DELETE_LICENCE = gql`
  mutation deleteLicence($licenceId: Int!) {
    licences {
      delete(licenceId: $licenceId)
    }
  }
`;

const UPDATE_LICENCE_EXPIRY_DATE = gql`
  mutation updateLicenceExpiryDate($expiresAtUtc: DateTime!, $licenceId: Int!) {
    licences {
      updateExpiryDate(expiresAtUtc: $expiresAtUtc, licenceId: $licenceId) {
        expiresAtUtc
        id
      }
    }
  }
`;

export { DELETE_LICENCE, UPDATE_LICENCE_EXPIRY_DATE };
