import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

export default function() {
  return (
    <div>
      <Helmet>
        <title>Ekardo terms and conditions</title>
        <meta
          content="Ekardo is here to make your information accessible, to let us do this you must agree to our terms"
          name="description"
        />
      </Helmet>

      <div className="login-page">
        <div
          className="login-page__fold"
          style={{ maxHeight: '350px', minHeight: '320px' }}
        >
          <Header theme="red" />
          <div
            className="login-page__container"
            style={{
              margin: 'auto',
              maxWidth: '700px',
            }}
          >
            <h2
              className="heading-page"
              style={{
                fontSize: '3em',
              }}
            >
              Oops!
            </h2>
            <h4 className="heading-page">Not found</h4>
          </div>
        </div>
      </div>

      <div className="login-page__card">
        <div className="u-container" style={{ marginTop: '1em' }}>
          <p>Sorry, this page is likely not found because:</p>
          <p>&nbsp;</p>
          <ul>
            <li>The page was moved</li>
            <li>The page no longer exists</li>
            <li>The URL is slightly incorrect</li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <a
              href="mailto:andrew@netfront.com.au?subject=Feedback for
                        webdevelopersnotes.com&body=The Tips and Tricks section is great"
            >
              Send me an email
            </a>
          </p>
          <Link to="/">
            <button className="button--red" type="button">
              Return home
            </button>
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
}
