import gql from 'graphql-tag';

export const GET_QUESTION_IN_CONTENT_GROUP = gql`
  query ($userFlowStepId: Int!) {
    contentSnippet {
      getAllQuestionInStep(stepId: $userFlowStepId) {
        contentSnippetFormId
        questions {
          id
          question
          contentPage {
            contentGroupId
          }
          configuration {
            id
          }
        }
      }
    }
  }
`;
