import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_IMAGE_CONTENT_SNIPPET = gql`
  mutation createContentSnippet($containerId: Int!, $visible: Boolean!, $width: Int!, $height: Int!, $assetId: String!, $title: String!) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible, entityTitle: $title }
        contentSnippetImage: { width: $width, height: $height, imageId: $assetId }
      ) {
        id
        sort
      }
    }
  }
`;

const createImageContentSnippetRequest = ({
  containerId,
  isVisible,
  width,
  height,
  assetId,
  // isBanner,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_IMAGE_CONTENT_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        visible: isVisible,
        width,
        height,
        // isBanner,
        assetId,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.createContentSnippet.id, data.contentSnippet.createContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createImageContentSnippetRequest.propTypes = {
  assetId: PropTypes.string.isRequired,
  containerId: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  // isBanner: PropTypes.bool,
};

createImageContentSnippetRequest.defaultProps = {
  // isBanner: false,
};

export default createImageContentSnippetRequest;
