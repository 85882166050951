import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import client from '../client';

const setCheckboxCorrectResponseMutation = gql`
  mutation setCheckboxCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      setCorrectResponse(
        checkbox: {
          questionResponseCheckboxId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;
const setRadioCorrectResponseMutation = gql`
  mutation setRadioCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      setCorrectResponse(
        radio: {
          questionResponseRadioId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

const setDropDownListCorrectResponseMutation = gql`
  mutation setDropDownListCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      setCorrectResponse(
        dropdownlist: {
          questionResponseDropDownListId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

const unsetCheckboxCorrectResponseMutation = gql`
  mutation unsetCheckboxCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      unsetCorrectResponse(
        checkbox: {
          questionResponseCheckboxId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;
const unsetRadioCorrectResponseMutation = gql`
  mutation unsetRadioCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      unsetCorrectResponse(
        radio: {
          questionResponseRadioId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

const unsetDropDownListCorrectResponseMutation = gql`
  mutation unsetDropDownListCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      unsetCorrectResponse(
        dropdownlist: {
          questionResponseDropDownListId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

export function useUpdateCorrectResponsesMutation() {
  return {
    Checkbox: {
      set: useMutation(setCheckboxCorrectResponseMutation, {
        client,
      })[0],
      unset: useMutation(unsetCheckboxCorrectResponseMutation, {
        client,
      })[0],
    },
    Radio: {
      set: useMutation(setRadioCorrectResponseMutation, {
        client,
      })[0],
      unset: useMutation(unsetRadioCorrectResponseMutation, {
        client,
      })[0],
    },
    DropDownList: {
      set: useMutation(setDropDownListCorrectResponseMutation, {
        client,
      })[0],
      unset: useMutation(unsetDropDownListCorrectResponseMutation, {
        client,
      })[0],
    },
  };
}
