import React from 'react';

import { AutoPlay, Arrow, Pagination } from '@egjs/flicking-plugins';
import Flicking, { MoveEndEvent, MoveStartEvent, ViewportSlot } from '@egjs/react-flicking';
import { Quote } from '@netfront/ui-library';

import { SliderSnippetProps } from './SliderSnippet.interfaces';

import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

import './SliderSnippet.css';

const SliderSnippet = ({
  slider: {
    arrows: shouldUseArrows = false,
    autoplay: shouldAutoplay = false,
    autoplaySpeed,
    dots: shouldUseDots = false,
    infinite: isInfinite = false,
    sliderItems,
    slidesToShow,
    speed,
  },
  onMoveEnd,
  onMoveStart,
}: SliderSnippetProps) => {
  const plugins = [
    ...(shouldAutoplay ? [new AutoPlay({ duration: autoplaySpeed, direction: 'NEXT', stopOnHover: false })] : []),
    ...(shouldUseArrows ? [new Arrow({ parentEl: document.body })] : []),
    ...(shouldUseDots ? [new Pagination({ type: 'bullet' })] : []),
  ];

  return (
    <React.Fragment>
      <div className="c-slider-snippet__container">
        <Flicking
          align="prev"
          circular={isInfinite}
          duration={speed}
          panelsPerView={slidesToShow}
          plugins={plugins}
          onMoveEnd={(event: MoveEndEvent) => {
            if (!onMoveEnd) {
              return;
            }

            const { direction } = event;

            onMoveEnd(direction);
          }}
          onMoveStart={(event: MoveStartEvent) => {
            if (!onMoveStart) {
              return;
            }

            const { direction } = event;

            onMoveStart(direction);
          }}
        >
          {sliderItems.map(({ author, headerTitle = '', id, quote = '', subHeaderText = '', text = '', type }) => {
            const sliderContent = type === 'CODE' ? text : quote;

            return (
              <div key={`slider-item-${id}`} className="flicking-panel">
                <Quote author={author}>
                  {headerTitle ? <h1 className="c-slider-item__header">{headerTitle}</h1> : null}
                  {subHeaderText ? <h2 className="c-slider-item__sub-header">{subHeaderText}</h2> : null}
                  <DOMPurifySanitizedHtmlContent html={sliderContent} />
                </Quote>
              </div>
            );
          })}
          <ViewportSlot>
            <React.Fragment>
              {shouldUseArrows && (
                <React.Fragment>
                  <div className="flicking-arrow-prev" />
                  <div className="flicking-arrow-next" />
                </React.Fragment>
              )}
            </React.Fragment>
            <div className="flicking-pagination" />
          </ViewportSlot>
        </Flicking>
      </div>
    </React.Fragment>
  );
};

export { SliderSnippet };
