import gql from 'graphql-tag';

export const EXPORT_COMMENTS = gql`
  query ExportComments($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime) {
    report {
      exportComments(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo) {
        contentType
        presignedUrl
        s3Key
      }
    }
  }
`;
