import gql from 'graphql-tag';

export const EXPORT_QUESTIONNAIRES = gql`
  query ExportQuestionnaires(
    $columnHeadingType: EExportQuestionnaireColumnHeadingType!
    $ianaTimeZone: String!
    $includeNonAccountData: Boolean!
    $stepId: Int!
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $receiver: String!
    $fileType: FileType
  ) {
    report {
      exportQuestionnaires(
        columnHeadingType: $columnHeadingType
        dateFrom: $dateFrom
        dateTo: $dateTo
        ianaTimeZone: $ianaTimeZone
        includeNonAccountData: $includeNonAccountData
        stepId: $stepId
        fileType: $fileType
        receiver: $receiver
      )
    }
  }
`;
