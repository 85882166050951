import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import Input from '../UI/Input/InputWhite';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import { Container, Item, Title, TitleText } from './styled';

import client from '../../middleware/client';
import { EDIT_TAG } from '../../middleware/Tag/editTag';
import { REMOVE_TAG } from '../../middleware/Tag/removeTagInProject';

const EditTagSideBar = props => {
  const { tag, onCancel, onUpdate, onDelete } = props;
  const [newTag, setNewTag] = useState(tag);
  const [errorMessage, setErrorMessage] = useState('');

  const [editTag] = useMutation(EDIT_TAG, {
    client,
  });

  const [removeTag] = useMutation(REMOVE_TAG, {
    client,
  });

  const handleInfoUpdate = (value, e) => {
    const newInfo = {
      ...newTag,
      [e.target.name]: value,
    };
    setNewTag(newInfo);
  };

  const handleError = err => {
    setErrorMessage(err.toString());
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const handleEditTag = () => {
    editTag({
      variables: {
        value: newTag.name,
        tagId: newTag.id,
      },
    })
      .then(() => {
        onUpdate(newTag);
      })
      .catch(err => {
        handleError(err);
      });
  };

  const handleDeleteTag = () => {
    removeTag({
      variables: {
        tagId: newTag.id,
      },
    })
      .then(() => {
        onDelete(newTag);
      })
      .catch(err => {
        handleError(err);
      });
  };

  return (
    <RightNavBar
      onCancel={onCancel}
      onDelete={handleDeleteTag}
      onSave={handleEditTag}
    >
      <div>
        <h2>Edit Tag</h2>
        <Container>
          <Item>
            <Title>
              <TitleText>Name</TitleText>
              <Tooltip text="Edit tag text" />
            </Title>
            <Input
              isChanged={handleInfoUpdate}
              name="name"
              text={newTag.name}
            />
          </Item>
        </Container>
      </div>
      {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
    </RightNavBar>
  );
};

EditTagSideBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    assetId: PropTypes.string,
    fileName: PropTypes.string,
  }).isRequired,
};

export default EditTagSideBar;
