import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_RESPONSE_SET_SUGGESTIONS } from './UseGetResponseSetSuggestions.graphql';

const useGetResponseSetSuggestions = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};

  const [executeGetResponseSetSuggestions, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionResponseSet: { searchResponseSet },
        } = data;

        onCompleted({
          searchResponseSet,
        });
      },
      onError,
    },
    query: query ?? GET_RESPONSE_SET_SUGGESTIONS,
    token,
  });

  const handleGetResponseSetSuggestions = ({ variables }) => {
    executeGetResponseSetSuggestions({
      variables,
    });
  };

  return {
    handleGetResponseSetSuggestions,
    isLoading,
  };
};

export { useGetResponseSetSuggestions };
