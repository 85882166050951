import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_MYAPP_COMPONENT_DETAIL = gql`
  query getMyAppComponentDetailRequest($appComponentId: Int!) {
    app {
      getAppComponentDetails(inputType: { appComponentId: $appComponentId }) {
        id
        files {
          appComponentId
          locationId
          id
        }
      }
    }
  }
`;

const getMyAppComponentDetailRequest = ({
  appComponentId,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={GET_ALL_MYAPP_COMPONENT_DETAIL}
    variables={{ appComponentId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.app.getAppComponentDetails
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getMyAppComponentDetailRequest.propTypes = {
  appComponentId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getMyAppComponentDetailRequest;
