import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Preloader from '../Preloader/Preloader';

import AppContext from '../../context/AppContext';
import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import GetAppSnippetDetailRequest from '../../middleware/ContentSnippet/getAppSnippetDetailRequest';
import { versionStatus } from '../../pages/VersionListPage/versionStatus';

class AppSnippet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlAssetId: '',
      jsAssetId: '',
      getHtmlAssetSent: true,
      getJsAssetSent: true,
      loader: false,
      cssContent: '',
      htmlContent: '',
      jsContent: '',
      getCssAssetSent: true,
      getAppRequest: true,
    };
    this.iframRef = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props;
    const script = document.createElement('script');
    const style = document.createElement('style');
    script.id = `app-js-${id}`;
    style.id = `app-css-${id}`;

    this.appendAssetToDOM(script, `#app-js-${id}`);
    this.appendAssetToDOM(style, `#app-css-${id}`);
  }

  componentDidUpdate() {
    const { id } = this.props;
    const { cssContent, jsContent } = this.state;
    const script = document.querySelector(`#app-js-${id}`);
    const style = document.querySelector(`#app-css-${id}`);

    script.innerHTML = jsContent;
    style.innerHTML = cssContent;
  }

  componentWillUnmount() {
    const { id } = this.props;

    const script = document.querySelector(`#app-js-${id}`);
    const style = document.querySelector(`#app-css-${id}`);

    script.parentNode.removeChild(script);
    style.parentNode.removeChild(style);
  }

  appendAssetToDOM = (node, selector) => {
    const tagLocations = {
      SCRIPT: 'body',
      STYLE: 'head',
    };

    if (document.querySelector(selector) == null) {
      document[tagLocations[node.tagName]].appendChild(node);
    }
  };

  handleGetAssetError = () => {
    this.setState({
      getHtmlAssetSent: true,
    });
  };

  handleGetHtmlAssetResponse = (s3Key, contentType) => {
    this.setState({
      getHtmlAssetSent: true,
      getJsAssetSent: false,
    });
    this.readTextFile(getSignedUrl(s3Key, contentType), 'htmlContent');
  };

  handleGetJsAssetResponse = (s3Key, contentType) => {
    this.setState({
      getJsAssetSent: true,
      getCssAssetSent: false,
    });
    this.readTextFile(getSignedUrl(s3Key, contentType), 'jsContent');
  };

  handleGetCssAssetResponse = (s3Key, contentType) => {
    this.setState({
      getCssAssetSent: true,
    });
    this.readTextFile(getSignedUrl(s3Key, contentType), 'cssContent');
  };

  readTextFile = (file, type) => {
    const rawFile = new XMLHttpRequest();
    rawFile.open('GET', file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          const allText = rawFile.responseText;
          this.setState({
            [type]: allText,
          });
        }
      }
    };
    rawFile.send(null);
  };

  handleOnGetAppSnippetDetailError = () => {
    this.setState({
      getAppRequest: false,
    });
  };

  handleOnGetAppSnippetDetailResponse = data => {
    this.setState({
      htmlContent: '',
      jsContent: '',
      cssContent: '',
      getAppRequest: false,
    });
    if (data && data.files.length) {
      this.setState({
        htmlAssetId: data.files[0].locationId,
        jsAssetId: data.files[1].locationId,
        cssAssetId: data.files[2].locationId,
        getHtmlAssetSent: false,
      });
    }
  };

  handleOnCreateComponentResponse = () => {
    this.setState({
      htmlContent: '',
      jsContent: '',
      cssContent: '',
    });
  };

  renderStatusComponent = (status = '', publishStatus = '') => (
    <div style={{ padding: '1em 0' }}>
      <span
        className={classnames(
          'c-tag',
          {
            'c-tag-danger is-filled':
              status.toUpperCase() === versionStatus.REJECTED,
          },
          {
            'c-tag-success is-filled':
              status.toUpperCase() === versionStatus.ACCEPTED,
          },
          {
            'c-tag-info is-filled':
              status.toUpperCase() === versionStatus.DRAFT,
          },
          {
            'c-tag-info is-filled':
              status.toUpperCase() === versionStatus.PENDING,
          }
        )}
      >
        {status}
      </span>
      {publishStatus === 'PUBLISHED' ? (
        <span className="c-tag c-tag-info ml-1 is-filled">{publishStatus}</span>
      ) : (
        <span className="c-tag c-tag-danger ml-1 is-filled">
          {publishStatus}
        </span>
      )}
    </div>
  );

  render() {
    const {
      htmlContent,
      loader,
      getHtmlAssetSent,
      htmlAssetId,
      cssAssetId,
      jsAssetId,
      getJsAssetSent,
      getCssAssetSent,
      getAppRequest,
    } = this.state;
    const { id } = this.props;

    // let document = '';
    // if (iframe.current) {
    //   document = iframe.current.contentDocument;
    // }
    const documentContents = `
      <body>
        ${htmlContent}
      </body>
    `;

    // if (document) {
    //   document.open();
    //   document.write(documentContents);
    //   document.close();
    // }

    return (
      <div>
        <>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: documentContents }} />
          {/* <Iframe ref={this.iframRef} title="app snippet" /> */}
        </>
        {!getHtmlAssetSent && (
          <GetAsset
            assetId={htmlAssetId}
            onError={this.handleGetAssetError}
            onSuccessResult={this.handleGetHtmlAssetResponse}
          />
        )}
        {!getJsAssetSent && (
          <GetAsset
            assetId={jsAssetId}
            onError={this.handleGetAssetError}
            onSuccessResult={this.handleGetJsAssetResponse}
          />
        )}
        {!getCssAssetSent && (
          <GetAsset
            assetId={cssAssetId}
            onError={this.handleGetAssetError}
            onSuccessResult={this.handleGetCssAssetResponse}
          />
        )}
        {loader && <Preloader text="Uploading Files. Please wait!" />}
        {getAppRequest && (
          <GetAppSnippetDetailRequest
            id={id}
            onError={this.handleOnGetAppSnippetDetailError}
            onSuccessResult={this.handleOnGetAppSnippetDetailResponse}
          />
        )}
      </div>
    );
  }
}

AppSnippet.contextType = AppContext;

AppSnippet.propTypes = {
  id: PropTypes.number.isRequired,
};

export default AppSnippet;
