import styled from 'styled-components';

export const AccordianMenuContainer = styled.div`
  font-size: 0.75rem;
  grid-column: span 2 / span 2;
  margin-right: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;

  & {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;

  & {
    div:first-child {
      margin-right: 1rem;
    }
  }
`;

export const DatePickerGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DownloadButton = styled.button`
  background-color: rgba(66, 153, 225);
  border-color: rgba(43, 108, 176);
  border-radius: 0.25rem;
  border-width: 1px;
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  padding: 0.5rem 1.5rem;
`;

export const DropdownListOptionContainer = styled.div`
  display: flex;
`;

export const DropdownListOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

export const ErrorContainer = styled.div`
  border: 1px solid rgba(197, 48, 48);
  border-radius: 0.25rem;
  color: rgba(197, 48, 48);
  display: flex;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
`;

export const FilterContentsContainer = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  grid-column: span 10 / span 10;
  padding: 1rem;

  & {
    h1 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    h2 {
      font-size: 1.125rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }
  }
`;

export const InputFieldFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

export const NumberInputFieldWithNoArrows = styled.input`
  background-color: #fff;
  border: 1px solid #dee2e6;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const PageContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
`;

export const SwitchButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
`;

export const SwitchButtonItemContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 0.75rem;
`;
