import { conditionDateTimespanConstants } from './ConditionDateTimespan.constants';

export const convertSecondsToDaysAndHours = (seconds) => {
  const { secondsInDay, secondsInHour } = conditionDateTimespanConstants;
  const days = Math.floor(seconds / secondsInDay);

  const remainingSeconds = seconds % secondsInDay;

  const hours = Math.floor(remainingSeconds / secondsInHour);

  return {
    days,
    hours,
  };
};

export const convertDaysAndHoursToSeconds = (days, hours) => {
  const { secondsInDay, secondsInHour } = conditionDateTimespanConstants;

  const totalSeconds = days * secondsInDay + hours * secondsInHour;

  return totalSeconds;
};
