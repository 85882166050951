import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import DropzoneArea from '../RightNavBar/Dropzone/DropzoneArea';
import ImageButton, { ICONS } from '../UI/Button/ImageButton';
import Input from '../UI/Input/InputWhite';
import Textarea from '../UI/Textarea/Textarea';
import Tooltip from '../UI/Tooltip/Tooltips';

import { NOTES_DROPZONE_ACCEPTED_MIME_TYPES, NOTES_PROP_TYPES, TAB_TYPE_KEYS } from './Notes.constants';
import {
  AttachmentIconImage,
  AttachmentLink,
  AttachmentLinkContainer,
  AdvanceContainer,
  AdvanceItem,
  AdvanceTitle,
  DropZoneAreaContainer,
  Flex,
  FlexFull,
  FlexHorizontal,
  Header,
} from './styled';

const PREFIX = 'note-add-or-update-';

function NoteAddOrUpdate(props) {
  const { currentNote, onCancelHandler, onDeleteHandler, onDragDropHandler, onUpdateHandler, tabType } = props;
  const { attachments: currentAttachments, id, message, subject } = currentNote;

  const dropzoneRef = useRef();
  const dropZoneAcceptedFileTypes = Object.keys(NOTES_DROPZONE_ACCEPTED_MIME_TYPES);

  function handleButtonClick(selectedTabType) {
    if (selectedTabType === TAB_TYPE_KEYS.addNewTab) {
      onCancelHandler();
    } else if (selectedTabType === TAB_TYPE_KEYS.editTab) {
      onDeleteHandler(id);
    }
  }

  function handleFileDrop(files) {
    onDragDropHandler(files, currentNote);
  }

  return (
    <>
      <FlexHorizontal>
        <Flex>
          <ImageButton borderRadius="15%" iconType={ICONS.back} onClicked={onCancelHandler} />
        </Flex>
        <FlexFull isCentered>
          <Header>Notes</Header>
        </FlexFull>
        <Flex>
          <ImageButton borderRadius="15%" iconType={id ? ICONS.bin : ICONS.cross} onClicked={() => handleButtonClick(tabType)} />
        </Flex>
      </FlexHorizontal>
      <AdvanceContainer>
        <AdvanceItem>
          <AdvanceTitle>
            Subject
            <Tooltip text="Subject of the note" />
          </AdvanceTitle>
          <Input id={`${PREFIX}subject`} isChanged={(value, event) => onUpdateHandler(event, value)} name="subject" text={subject} />
        </AdvanceItem>
        <AdvanceItem>
          <AdvanceTitle>
            Note
            <Tooltip text="Details about the note" />
          </AdvanceTitle>
          <Textarea id={`${PREFIX}message`} isChanged={(value, event) => onUpdateHandler(event, value)} name="message" text={message} />
        </AdvanceItem>
        <AdvanceItem>
          <AdvanceTitle>
            Attachments (optional)
            <Tooltip text="Attachments for the note" />
          </AdvanceTitle>
          {currentAttachments.map(currentAttachment => {
            const {
              asset: { contentType, fileName, presignedDownloadUrl },
              file,
              id: noteAttachmentId,
            } = currentAttachment;

            const attachmentIcon = NOTES_DROPZONE_ACCEPTED_MIME_TYPES[contentType].icon;

            return (
              <AttachmentLinkContainer key={`note-attachment-${noteAttachmentId}`}>
                <AttachmentLink href={presignedDownloadUrl} rel="noreferrer" target="_blank">
                  <AttachmentIconImage src={attachmentIcon} alt="" />
                  {fileName}
                  {file ? ' (Pending upload)' : ''}
                </AttachmentLink>
              </AttachmentLinkContainer>
            );
          })}
          <DropZoneAreaContainer>
            <DropzoneArea
              dropRef={dropzoneRef}
              fileType={dropZoneAcceptedFileTypes.join(', ')}
              onDrop={files => handleFileDrop(files)}
              multiple
            />
          </DropZoneAreaContainer>
        </AdvanceItem>
      </AdvanceContainer>
    </>
  );
}

NoteAddOrUpdate.propTypes = {
  currentNote: PropTypes.shape(NOTES_PROP_TYPES).isRequired,
  onCancelHandler: PropTypes.func.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  onUpdateHandler: PropTypes.func.isRequired,
  tabType: PropTypes.string.isRequired,
};

export default NoteAddOrUpdate;
