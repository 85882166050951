import React, { useState, useEffect } from 'react';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useParams, Redirect, Link } from 'react-router-dom';
import styled from 'styled-components';

import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import ImageUploader from '../UI/AssetUploader/ImageUploader';
import IconRadioButton from '../UI/Input/IconRadioButton';
import Input from '../UI/Input/InputWhite';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import { Container, Item, Title, TitleText, Preview, EditBtn, Logo } from './styled';

import { GET_ASSET } from '../../middleware/Asset/getAsset';
import client from '../../middleware/client';
import { createImageMapRegionMutation } from '../../middleware/ImageMap/createImageMapRegion';
import { deleteImageMapRegionMutation } from '../../middleware/ImageMap/deleteImageMapRegion';
import { updateImageMapRegionMutation } from '../../middleware/ImageMap/updateImageMapRegion';

const LinkContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StyleSelect = styled.select`
  border: #e4e4e3 1px solid;
  border-radius: 5px;
  height: 2.6rem;
  outline: none;
  padding: 0 0.8rem;
  width: 100%;

  &:hover {
    border-color: #44bed3;
  }
`;

const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MarginLeft = styled.div`
  margin-left: 0.5rem;
`;

const MarginRight = styled.div`
  margin-left: 0.5rem;
`;

const StyleOption = styled.option``;

const ImageMapRegionSideBar = (props) => {
  const { closeSideBar, detail, onDelete, onUpdate, imageMapId, onChangeRegion } = props;
  const isEditMode = Boolean(detail.id > 0);
  const [hasErrors, setHasErrors] = useState({
    name: false,
    x: false,
    y: false,
    iconType: false,
    iconSize: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const { projectId, orgId } = useParams();
  const [isEditLogoMode, setIsEditLogoMode] = useState(false);
  const [createdRegionId, setCreatedRegionId] = useState(null);
  const [item, setItem] = useState({
    name: '',
    iconType: 'CIRCLED_PLUS',
    iconSize: 'SMALL',
    ...detail,
  });

  const [update] = useMutation(updateImageMapRegionMutation, {
    client,
  });

  const [remove] = useMutation(deleteImageMapRegionMutation, {
    client,
  });

  const [create] = useMutation(createImageMapRegionMutation, {
    client,
  });

  const [getAssetInfo, { data: assetData }] = useLazyQuery(GET_ASSET, {
    client,
  });

  useEffect(() => {
    if (item.alternativeImageId) getAssetInfo({ variables: { assetId: item.alternativeImageId } });
  }, []);

  const handleError = (err) => {
    setErrorMessage(err.toString());
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const isValid = () => {
    setHasErrors({
      ...hasErrors,
      name: item.name === '',
      x: item.x === '',
      y: item.y === '',
      iconSize: item.iconSize === '',
      iconType: item.iconType === '',
    });
    return item.name !== '' && item.x !== '' && item.y !== '' && item.iconSize !== '' && item.iconType !== '';
  };

  const createRegion = () => {
    if (isValid()) {
      create({
        variables: {
          name: item.name,
          x: item.x,
          y: item.y,
          alternativeImageId: item.alternativeImageId,
          imageMapId,
          size: item.iconSize,
          iconType: item.iconType,
        },
      })
        .then(({ data }) => {
          setCreatedRegionId(data.region.addRegion.contentPage.id);
        })
        .catch(handleError);
    }
  };

  const updateRegion = () => {
    if (isValid()) {
      update({
        variables: {
          name: item.name,
          alternativeImageId: item.alternativeImageId,
          x: item.x,
          y: item.y,
          size: item.iconSize,
          iconType: item.iconType,
          regionId: item.id,
        },
      })
        .then(() => {
          onUpdate(item);
        })
        .catch(handleError);
    }
  };

  const handleSave = () => (!isEditMode ? createRegion() : updateRegion());

  const handleUpdate = (value, e) => {
    setItem({
      ...item,
      [e.target.name]: value,
    });
  };

  const handleDelete = () => {
    remove({
      variables: {
        regionId: item.id,
      },
    })
      .then(() => {
        onDelete(item.id);
      })
      .catch(handleError);
  };

  const getLogoData = (asset) => {
    setIsEditLogoMode(false);
    if (item.alternativeImageId !== asset.assetId) getAssetInfo({ variables: { assetId: asset.assetId } });
    setItem((currentState) => ({
      ...currentState,
      alternativeImageId: asset.assetId,
    }));
  };

  const updateRegionField = (value, e) => {
    onChangeRegion(e.target.name, Number(value));
    setItem({
      ...item,
      [e.target.name]: Number(value),
    });
  };

  const updateSelect = (e) => {
    onChangeRegion(e.target.name, e.target.options[e.target.selectedIndex].value);

    setItem({
      ...item,
      [e.target.name]: e.target.options[e.target.selectedIndex].value,
    });
  };

  const handleRadio = (value) => {
    onChangeRegion('iconType', value);

    setItem({
      ...item,
      iconType: value,
    });
  };

  return (
    <>
      {createdRegionId ? (
        <Redirect to={`/dashboard/${orgId}/${projectId}/group/regions/${imageMapId}/content/${createdRegionId}`} />
      ) : (
        <RightNavBar
          confirmationMessage="Deleting this region will delete the page linked to it, are you sure you want to continue?"
          hasDelete={item.id > 0}
          onCancel={closeSideBar}
          onDelete={handleDelete}
          onSave={handleSave}
        >
          <div>
            <h3 className="h-hide-visually">{isEditMode ? 'Edit' : 'Create'} region</h3>
            <Container>
              <Item>
                <Input isChanged={handleUpdate} name="name" text={item.name} />
                {hasErrors.name ? <p className="error-message">Title cannot be empty</p> : null}
              </Item>
              <InlineContainer>
                <MarginRight>
                  <Item>
                    <Title>
                      <TitleText>X position</TitleText>
                      <Tooltip text="Position of button along horizontal axis" />
                    </Title>

                    <Input isChanged={updateRegionField} name="x" text={String(item.x)} />
                    {hasErrors.x ? <p className="error-message">Horizontal axis cannot be empty</p> : null}
                  </Item>
                </MarginRight>
                <MarginLeft>
                  <Item>
                    <Title>
                      <TitleText>Y position</TitleText>
                      <Tooltip text="Position of button along vertical axis" />
                    </Title>

                    <Input isChanged={updateRegionField} name="y" text={String(item.y)} />
                    {hasErrors.y ? <p className="error-message">Vertical axis cannot be empty</p> : null}
                  </Item>
                </MarginLeft>
              </InlineContainer>
              <Item>
                <Title>
                  <TitleText>Button style</TitleText>
                  <Tooltip text="Style of the button" />
                </Title>
                <fieldset>
                  <InlineContainer>
                    <IconRadioButton
                      htmlFor="id_CIRCLED_PLUS"
                      iconId="CIRCLED_PLUS"
                      isChecked={item.iconType === 'CIRCLED_PLUS'}
                      name="iconType"
                      onChange={() => handleRadio('CIRCLED_PLUS')}
                    />
                    <IconRadioButton
                      htmlFor="id_SQUARE_PLUS"
                      iconId="SQUARE_PLUS"
                      isChecked={item.iconType === 'SQUARE_PLUS'}
                      name="iconType"
                      onChange={() => handleRadio('SQUARE_PLUS')}
                    />
                    <IconRadioButton
                      htmlFor="id_CIRCLED_BORDER_INCOMPLETE_PLUS"
                      iconId="CIRCLED_BORDER_INCOMPLETE_PLUS"
                      isChecked={item.iconType === 'CIRCLED_BORDER_INCOMPLETE_PLUS'}
                      name="iconType"
                      onChange={() => handleRadio('CIRCLED_BORDER_INCOMPLETE_PLUS')}
                    />
                    <IconRadioButton
                      htmlFor="id_INFORMATION"
                      iconId="INFORMATION"
                      isChecked={item.iconType === 'INFORMATION'}
                      name="iconType"
                      onChange={() => handleRadio('INFORMATION')}
                    />
                  </InlineContainer>
                  {hasErrors.iconType ? <p className="error-message">Please select a button style</p> : null}
                </fieldset>
              </Item>

              <Item>
                <Title>
                  <TitleText>Button size</TitleText>
                  <Tooltip text="Size of the button" />
                </Title>
                <StyleSelect defaultValue={item.iconSize || 'SMALL'} name="iconSize" onChange={updateSelect}>
                  <StyleOption value="SMALL">Small</StyleOption>
                  <StyleOption value="MEDIUM">Medium</StyleOption>
                  <StyleOption value="LARGE">Large</StyleOption>
                </StyleSelect>
                {hasErrors.iconSize ? <p className="error-message">Please select a size</p> : null}
              </Item>
              <Item>
                <Title>
                  <TitleText>Replacement Image (optional)</TitleText>
                  <Tooltip text="The image you would like to use when the region is selected" />
                </Title>
                {item.alternativeImageId && !isEditLogoMode ? (
                  <Preview>
                    <EditBtn
                      onClick={() => {
                        setIsEditLogoMode(true);
                      }}
                    >
                      Change the image
                    </EditBtn>
                    <Logo src={assetData && assetData.asset.getAsset.presignedUrl} />
                  </Preview>
                ) : (
                  <ImageUploader getData={getLogoData} name="bgImg" projectId={projectId} type="IMAGE" />
                )}
                {hasErrors.asset ? <p className="error-message">Please upload an image</p> : null}
              </Item>
            </Container>
          </div>
          {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}

          {item.id > 0 ? (
            <LinkContainer>
              <Link
                className="button--blue"
                to={`/dashboard/${orgId}/${projectId}/group/regions/${imageMapId}/content/${item.contentPage.id}`}
              >
                Go to content page
              </Link>
            </LinkContainer>
          ) : null}
        </RightNavBar>
      )}
    </>
  );
};

ImageMapRegionSideBar.propTypes = {
  closeSideBar: PropTypes.func.isRequired,
  imageMapId: PropTypes.number.isRequired,
  detail: PropTypes.shape({
    alternativeImageId: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onChangeRegion: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

ImageMapRegionSideBar.defaultProps = {
  detail: null,
  onChangeRegion: () => undefined,
  onDelete: () => undefined,
  onUpdate: () => undefined,
};

export default ImageMapRegionSideBar;
