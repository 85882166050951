import { Checkbox } from '@netfront/ui-library';

import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import Dropdown from '../../UI/Dropdown/Dropdown';
import Input from '../../UI/Input/InputWhite';
import Textarea from '../../UI/Textarea/Textarea';

export const inputTypeMap = {
  text: Textarea,
  input: Input,
  dropdown: Dropdown,
  toggle: ToggleSwitch,
  checkbox: Checkbox,
};
