import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_PROJECT_STEPS } from './UseGetProjectSteps.graphql';

const useGetProjectSteps = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};
  const [executeGetProjectSteps, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStep: { getStepsOfProject: userFlowSteps },
        } = data;

        onCompleted({
          userFlowSteps,
        });
      },
      onError,
    },
    query: query ?? GET_PROJECT_STEPS,
    token,
  });

  const handleGetProjectSteps = ({ variables }) => {
    executeGetProjectSteps({
      variables,
    });
  };

  return {
    handleGetProjectSteps,
    isLoading,
  };
};

export { useGetProjectSteps };
