const RATING_LABEL_TYPES_HALF: { [index: string]: string } = {
  0.5: 'Useless',
  1: 'Bad',
  1.5: 'Bad',
  2: 'Poor',
  2.5: 'Poor',
  3: 'Ok',
  3.5: 'Good',
  4: 'Nice',
  4.5: 'Excellent',
  5: 'Amazing',
};

const RATING_LABEL_TYPES_FULL: { [index: string]: string } = {
  1: 'Bad',
  2: 'Poor',
  3: 'Ok',
  4: 'Good',
  5: 'Excellent',
};

const DEFAULT_ICON_COUNT = 5;

export const customRatingConstants = Object.freeze({
  defaultIconCount: DEFAULT_ICON_COUNT,
  ratingLabelTypesFull: RATING_LABEL_TYPES_FULL,
  ratingLabelTypesHalf: RATING_LABEL_TYPES_HALF,
});
