import React, { useState, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import Checkbox from '../Checkbox/Checkbox';
import Field from '../Forms/Field';
import Form from '../Forms/Form';
import FormHeader from '../Forms/FormHeader';
import TextArea from '../TextArea/TextArea';
import TextField from '../TextField/TextField';
import PopUpMsg from '../UI/PopUp/PopUpMsg';

import RightNavBar from './RightNavBar';

import AppContext from '../../context/AppContext';
import InviteNewOrgUser from '../../middleware/UsersManagement/createOrgInvitationRequest';
import UpdateOrgUserInfo from '../../middleware/UsersManagement/updateOrgMembershipDetail';

const permissionTypes = {
  MANAGE_USERS: 'Manage users',
  EDIT: 'Edit',
  READ: 'View',
};

const OrgUserMgmtSideBar = props => {
  const { user, onCancel } = props;
  const { org } = useContext(AppContext);

  const [newMember, setNewMember] = useState({});
  const [sendRequest, setSendRequest] = useState(false);
  const [permission, setPermission] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setIsEdit(true);
      setNewMember({
        firstname: user.user.firstname || '',
        lastname: user.user.lastname || '',
        position: user.user.title || '',
        email: user.user.credential.email || '',
        permission: user.permission || '',
      });
      setPermission(user.permission);
    }
  }, [user]);

  const handleNewMemberInputs = e => {
    const { name, value } = e.target;
    setNewMember({ ...newMember, [name]: value });
    if (name === 'permission') setPermission(value);
  };

  const handleCreateError = err => {
    setMessage(err);
    setSendRequest(false);
  };

  const handleCreateSuccess = () => {
    setSendRequest(false);
    onCancel('pending');
  };

  const handleUpdateError = err => {
    setMessage(err);
    setSendRequest(false);
  };

  const handleUpdateSuccess = () => {
    setSendRequest(false);
    onCancel([user.userId, permission]);
  };

  const handleInvitation = () => {
    setSendRequest(true);
  };

  const handleMsgDisappear = () => {
    setTimeout(() => {
      setMessage('');
    }, 500);
  };

  return (
    <>
      <RightNavBar
        confirmBtnName={!isEdit ? 'Invite' : 'Update'}
        hasDelete={isEdit}
        onCancel={onCancel}
        onSave={handleInvitation}
      >
        <Form name="member">
          <FormHeader
            title={
              !isEdit
                ? 'Add new member'
                : `Edit ${user.user.firstname}'s details`
            }
          />
          <Field id="firstname" label="First name">
            <TextField
              isDisabled={isEdit}
              name="firstname"
              onChange={handleNewMemberInputs}
              value={newMember.firstname}
            />
          </Field>

          <Field id="lastname" label="Last name">
            <TextField
              isDisabled={isEdit}
              name="lastname"
              onChange={handleNewMemberInputs}
              value={newMember.lastname}
            />
          </Field>

          <Field id="email" label="Email">
            <TextField
              isDisabled={isEdit}
              name="email"
              onChange={handleNewMemberInputs}
              value={newMember.email}
            />
          </Field>

          <Field id="position" label="Position (optional)">
            <TextField
              isDisabled={isEdit}
              name="position"
              onChange={handleNewMemberInputs}
              value={newMember.position}
            />
          </Field>
          <Field id="permissions" label="Permissions">
            {Object.keys(permissionTypes).map(type => (
              <Checkbox
                displayText={permissionTypes[type]}
                isSelected={type === permission}
                key={type}
                name="permission"
                onChange={handleNewMemberInputs}
                value={type}
              />
            ))}
          </Field>
          {!isEdit && (
            <Field id="message" label="Invite message (optional)">
              <TextArea name="message" onChange={handleNewMemberInputs} />
            </Field>
          )}
          {message !== '' && (
            <PopUpMsg
              message={message}
              msgDisappear={handleMsgDisappear}
              type="error"
            />
          )}
        </Form>
      </RightNavBar>
      {sendRequest && !isEdit && (
        <InviteNewOrgUser
          emailInvited={newMember.email}
          firstName={newMember.firstname}
          lastName={newMember.lastname}
          message={newMember.message}
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
          organisationId={org.id}
          permission={newMember.permission}
          position={newMember.position}
        />
      )}
      {sendRequest && isEdit && (
        <UpdateOrgUserInfo
          membershipType="ORGANISATION"
          onError={handleUpdateError}
          onSuccessResult={handleUpdateSuccess}
          organisationId={org.id}
          permission={permission}
          userId={user.userId}
        />
      )}
    </>
  );
};

OrgUserMgmtSideBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    permission: PropTypes.string,
    user: PropTypes.shape({
      credential: PropTypes.shape({
        email: PropTypes.string,
      }),
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      title: PropTypes.string,
    }),
    userId: PropTypes.number,
  }),
};

OrgUserMgmtSideBar.defaultProps = {
  user: {},
};

export default OrgUserMgmtSideBar;
