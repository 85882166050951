import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SwitchBtn from '../UI/Button/SwitchButton';
import Input from '../UI/Input/InputWhite';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';

import { DELETE_ASSET } from '../../middleware/Asset/deleteAssetRequest';
import ToggleAssetPublic from '../../middleware/Asset/toggleAssetPublicRequest';
import UpdateAsset from '../../middleware/Asset/updateAssetRequest';
import client from '../../middleware/client';

const AdvanceContainer = styled.div`
  overflow-y: scroll;
  padding: 0 1em;
`;

const AdvanceItem = styled.div`
  margin: 1em auto;
  width: 100%;
`;

const AdvanceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Image = styled.img``;

const EditAssetSideBar = props => {
  const { type, asset, onClose, onUpdateSuccess, onPublicPropToggled } = props;
  const [newAsset, setNewAsset] = useState(asset);
  const [toggleRequest, setToggleRequest] = useState(false);
  const [updateRequest, setUpdateRequest] = useState(false);

  const handleInfoUpdate = (value, e) => {
    let newValue = value;
    if (e.target.name === 'status') {
      newValue = newValue ? 'ACTIVE' : 'INACTIVE';
    }
    const newInfo = {
      ...newAsset,
      [e.target.name]: newValue,
    };
    setNewAsset(newInfo);
  };

  const handleDeleteError = () => {
    onClose();
  };

  const handleDeleteSuccess = () => {
    onClose(asset);
  };

  const handleUpdateSuccess = item => {
    setUpdateRequest(false);
    onUpdateSuccess(item);
  };

  const [deleteAsset] = useMutation(DELETE_ASSET, {
    client,
    onCompleted() {
      handleDeleteSuccess();
    },
    onError() {
      handleDeleteError();
    },
  });

  const content = (
    <AdvanceContainer>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Name</b>
          <Tooltip text="File name" />
        </AdvanceTitle>
        <Input isChanged={handleInfoUpdate} name="fileName" text={newAsset.fileName} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Alt text</b>
          <Tooltip text="Alternative text that will show if the file hasn't correctly loaded" />
        </AdvanceTitle>
        <Input isChanged={handleInfoUpdate} name="alt" text={newAsset.alt} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Tags</b>
          <Tooltip text="Tags of the file" />
        </AdvanceTitle>
        <Input isChanged={handleInfoUpdate} name="tags" text={newAsset.tags} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Is public</b>
          <Tooltip text="Define if the file is public or not" />
        </AdvanceTitle>
        <SwitchBtn
          defaultChecked={asset.isPublic}
          id={1}
          onChange={() => {
            setToggleRequest(true);
          }}
        />
        {newAsset.isPublic && (
          <AdvanceItem>
            <AdvanceTitle>
              <b>Public link</b>
              <Tooltip text="Public link of the file that never expires" />
            </AdvanceTitle>
            <Input isDisabled text={newAsset.publicUrl} />
          </AdvanceItem>
        )}
      </AdvanceItem>

      {asset.type === 'IMAGE' && (
        <AdvanceItem>
          <AdvanceTitle>
            <b>Image</b>
            <Tooltip />
          </AdvanceTitle>
          <Image alt="image" src={newAsset.src} />
        </AdvanceItem>
      )}
    </AdvanceContainer>
  );

  return (
    <RightNavBar
      onCancel={() => onClose()}
      onDelete={() =>
        deleteAsset({
          variables: {
            assetId: asset.assetId,
          },
        })
      }
      onSave={() => setUpdateRequest(true)}
    >
      <div>
        <h2>Edit {type}</h2>
        {content}
      </div>
      {toggleRequest && (
        <ToggleAssetPublic
          assetId={asset.assetId}
          onError={handleDeleteError}
          onSuccessResult={item => {
            setToggleRequest(false);
            onPublicPropToggled(item);
            setNewAsset(item);
          }}
        />
      )}

      {updateRequest && (
        <UpdateAsset
          alt={newAsset.alt}
          assetId={asset.assetId}
          fileName={newAsset.fileName}
          onError={handleDeleteError}
          onSuccessResult={handleUpdateSuccess}
          tags={newAsset.tags}
        />
      )}
    </RightNavBar>
  );
};

EditAssetSideBar.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.string,
    fileName: PropTypes.string,
    isPublic: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onPublicPropToggled: PropTypes.func.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default EditAssetSideBar;
