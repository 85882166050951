import gql from 'graphql-tag';

import { SummaryFragment } from '../QuestionConfiguration/Fragments/Collection';

export const CREATE_AVATAR_SNIPPET = gql`
  mutation ($containerId: Int!, $title: String!) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: true, entityTitle: $title }
        contentSnippetAvatar: { value: true }
      ) {
        id
        sort
      }
    }
  }
`;
