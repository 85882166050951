import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import {
  AppImage,
  FlexContainer,
  Heading1,
  Heading4,
  OptionsBanner,
  OptionsBannerInner,
  OverallRating,
  PushRight,
  PushRight1,
  ReturnButton,
  SubTitle,
  BannerBG,
} from '../MarketplaceAppDetailsPage/styled';
import { NewAppBtn, NewIcon } from '../MarketplacePage/styled';
import { TableContainer } from '../OrganisationUsersPage/styled';

import { EditBox, OptBtn, VersionTr, EditTd } from './styled';

import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import { DropDownMenu } from '../../components/DropDownMenu/DropDownMenu';
import Icon from '../../components/Icon/Icon';
import Lozenge from '../../components/Lozenge/Lozenge';
import StarRating from '../../components/StarRating/StarRating';
import EditBtn from '../../components/UI/Button/EditButton';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateAppVersionRequest from '../../middleware/MyAppDashboard/createAppVersionRequest';
import GetAppVersionList from '../../middleware/MyAppDashboard/getMyAppVersionList';
import SubmitAppForApproval from '../../middleware/MyAppDashboard/submitAppForApprovalRequest';

const statusTypes = {
  ACCEPTED: 'success',
  DRAFT: 'default',
  REJECTED: 'danger',
  PENDING: 'warning',
};

const AppVersionPage = (props) => {
  const { match, history } = props;
  const { app, updateApp } = useContext(AppContext);

  const [isDnd, setIsDnd] = useState(false);
  const [appLoaded, setAppLoaded] = useState(false);
  const [appDetails, setAppDetails] = useState({});
  const [appImage, setAppImage] = useState('');
  const [sendCreateVersion, requestCreateVersion] = useState(false);
  const [versionName, setVersionName] = useState('');
  const [newVersionName, setNewVersionName] = useState('');
  const [submitRequest, setSubmitRequest] = useState(false);
  const [submitVersion, setSubmitVersion] = useState('');

  const getAppDetailsError = () => {
    setAppLoaded(true);
  };

  const getAppDetailsSuccess = (data) => {
    updateApp({ id: data.id, title: data.title, identifier: data.identifier });
    setAppDetails(data);
    setAppImage(data.logo ? `${getSignedUrl(data.logo.s3Key, data.logo.contentType)}` : 'https://dev.assets.ekardo.com/logo/logo-dark.svg');
    setAppLoaded(true);
  };

  const handleCreateVersion = () => {
    const i = versionName.lastIndexOf('.');
    const newNum = Number(versionName.substr(i + 1)) + 1;
    const tmp = versionName.substr(0, i + 1) + newNum.toString();
    setNewVersionName(tmp);
    requestCreateVersion(true);
  };

  const handleCreateVersionError = () => {
    requestCreateVersion(false);
    setNewVersionName('');
  };

  const onCreateVersionSuccess = (data) => {
    const date = new Date();

    const fdate = date.toISOString();
    const cur = appDetails.versions;
    cur.push({
      id: data.id,
      version: newVersionName,
      appId: Number(match.params.appId),
      uploadedDateTime: fdate,
      usageCount: 0,
      status: 'DRAFT',
      publishStatus: 'UNPUBLISHED',
      components: [],
    });
    const newDetails = {
      ...appDetails,
      versions: cur,
    };
    setAppDetails(newDetails);
    requestCreateVersion(false);
    setNewVersionName('');
  };

  const handleSortId = (e) => {
    if (e.target.dataset.sort === 'users') {
      return;
    }

    appDetails.versions.sort((a, b) => a[e.target.dataset.sort].localeCompare(b[e.target.dataset.sort]));
  };

  const handleEditBtn = (version) => {
    updateApp({
      ...app,
      version: { id: version.id, version: version.version },
    });
    history.push(`${match.url}/${version.id}/components`);
  };

  const handleSubmitApproval = (version) => {
    const tmpVersions = appDetails.versions;
    const i = tmpVersions.findIndex((v) => v.id === version.id);
    tmpVersions.splice(i, 1, {
      ...version,
      status: 'PENDING',
    });
    setAppLoaded({
      ...appDetails,
      versions: tmpVersions,
    });
    setSubmitVersion(version.version);
    setSubmitRequest(true);
  };

  const handleSubmitError = () => {
    setSubmitRequest(false);
  };

  const handleSubmitSuccess = () => {
    setSubmitRequest(false);
  };

  const currentAppID = parseInt(match.params.appId, 10);

  const { averageRateValue, identifier, title } = appDetails;

  const breadPath = [
    {
      id: 1,
      path: '/marketplace',
      title: 'Marketplace',
    },

    {
      id: 2,
      path: `/marketplace/app/${currentAppID}`,
      title: title || 'app',
    },
    {
      id: 3,
      path: '',
      title: 'versions',
    },
  ];

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="Manage your app version"
        pageDescription="Manage all your assets"
        pageTitle="app version management page"
        params={match.params}
      >
        <PushRight1>
          <ReturnButton href={`/marketplace/app/${parseInt(match.params.appId, 10)}`}>
            <NewIcon>
              <span className="icon-chevron" />
            </NewIcon>
            Return to app
          </ReturnButton>
        </PushRight1>

        {Object.entries(appDetails).length !== 0 && (
          <OptionsBanner>
            <BannerBG />
            <ConsoleContainer>
              <OptionsBannerInner>
                <FlexContainer>
                  <AppImage src={appImage} />
                  <div className="u-flex-column">
                    <Heading1>{title}</Heading1>
                    <SubTitle>by</SubTitle>
                  </div>
                </FlexContainer>

                <div className="u-flex-column">
                  <OverallRating>
                    <Heading4>Overall rating</Heading4>
                    <StarRating amount={1} rating={averageRateValue} />
                  </OverallRating>
                </div>
              </OptionsBannerInner>
            </ConsoleContainer>
          </OptionsBanner>
        )}

        <ConsoleContainer>
          <FlexContainer style={{ alignItems: 'center' }}>
            <PushRight>
              <NewAppBtn aria-haspopup="true" onClick={handleCreateVersion}>
                <NewIcon>
                  <Icon id="icon_plus" />
                </NewIcon>
                Create new version
              </NewAppBtn>
            </PushRight>
          </FlexContainer>
        </ConsoleContainer>

        <ConsoleContainer styles={{ marginTop: '1em' }}>
          {Object.entries(appDetails).length !== 0 && (
            <TableContainer>
              <Thead>
                <Tr>
                  <Th data-sort="version" onClick={handleSortId} scope="col">
                    Version
                  </Th>
                  <Th data-sort="components" scope="col">
                    Components
                  </Th>
                  <Th data-sort="uploadedDateTime" onClick={handleSortId} scope="col">
                    Creation date
                  </Th>
                  <Th data-sort="status" scope="col">
                    Status
                  </Th>
                  <Th data-sort="linkedProjects" scope="col">
                    Linked content snippet
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(appDetails).length !== 0 &&
                  appDetails.versions
                    .sort((a, b) => {
                      const dateA = new Date(a.uploadedDateTime);
                      const dateB = new Date(b.uploadedDateTime);
                      return dateB - dateA;
                    })
                    .map((version, index) => {
                      if (index === 0 && version.version !== versionName) setVersionName(version.version);
                      return (
                        <VersionTr key={version.id}>
                          <Td>{version.version}</Td>
                          <Td>{version.components.length} components</Td>
                          <Td>{version.uploadedDateTime.split('T')[0]}</Td>
                          <Td>
                            <Lozenge appearance={statusTypes[version.status]}>
                              {version.status === 'ACCEPTED' ? 'PUBLISHED' : version.status}
                            </Lozenge>
                            {/* {version.status === 'ACCEPTED' && (
                              <Lozenge appearance={statusTypes[version.status]}>
                                {version.status === 'ACCEPTED'
                                  ? 'PUBLISHED'
                                  : version.status}
                              </Lozenge>
                            )} */}
                          </Td>
                          <Td>{version.usageCount}</Td>
                          <EditTd>
                            <EditBox>
                              <EditBtn isClicked={() => setIsDnd(!isDnd)} />
                              <DropDownMenu
                                onMouseLeave={() => setIsDnd(!isDnd)}
                                // onBlur={() => setIsDnd(!isDnd)}
                                open={isDnd}
                                style={{
                                  top: '2em',
                                  left: '-0.7em',
                                  padding: '0.6em 0',
                                }}
                              >
                                <li>
                                  <OptBtn onClick={() => handleEditBtn(version)}>View code</OptBtn>
                                </li>
                                {version.components.length > 0 && version.status === 'DRAFT' && (
                                  <li>
                                    <OptBtn onClick={() => handleSubmitApproval(version)}>Submit for approval</OptBtn>
                                  </li>
                                )}
                              </DropDownMenu>
                            </EditBox>
                          </EditTd>
                        </VersionTr>
                      );
                    })}
              </Tbody>
            </TableContainer>
          )}
        </ConsoleContainer>
      </PageWrapper>
      {!appLoaded && <GetAppVersionList appId={currentAppID} onError={getAppDetailsError} onSuccessResult={getAppDetailsSuccess} />}
      {sendCreateVersion && (
        <CreateAppVersionRequest
          identifier={identifier}
          onError={handleCreateVersionError}
          onSuccessResult={onCreateVersionSuccess}
          type="BETA"
          version={newVersionName}
        />
      )}
      {submitRequest && (
        <SubmitAppForApproval
          identifier={appDetails.identifier}
          onError={handleSubmitError}
          onSuccessResult={handleSubmitSuccess}
          version={submitVersion}
        />
      )}
    </>
  );
};

AppVersionPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appId: PropTypes.string.isRequired,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default AppVersionPage;
