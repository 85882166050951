import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_SECTION = gql`
  mutation UpdateContentPage($sectionId: Int!, $contentLayoutId: Int!, $sort: Int, $title: String!) {
    contentSection {
      updateSection(contentSection: { sectionId: $sectionId, contentLayoutId: $contentLayoutId, sort: $sort, title: $title }) {
        id
        sort
        sectionContainers {
          id
          sort
        }
      }
    }
  }
`;

const updateContentSectionRequest = ({ sectionId, contentLayoutId, sort, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_CONTENT_SECTION}>
    {(updateSection, { loading, error, data }) => {
      const variables = {
        sectionId,
        contentLayoutId,
        sort,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.contentSection.updateSection.id,
          data.contentSection.updateSection.sort,
          data.contentSection.updateSection.sectionContainers,
        );
      }

      return <MutationRequest isLoading={loading} mutation={updateSection} variables={variables} />;
    }}
  </Mutation>
);

updateContentSectionRequest.propTypes = {
  contentLayoutId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  sort: PropTypes.number,
};

updateContentSectionRequest.defaultProps = {
  sort: null,
};

export default updateContentSectionRequest;
