/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { useParams } from 'react-router-dom';

import Buttons from '../Buttons/Buttons';

import Comment from './Comment';
import Comments from './Comments';

import { useSocialState } from '../../../context/socialPage/SocialPageContext';
import { useApolloClient } from '../../../middleware/apollo/useApolloClient';
import { CREATE_ASSET } from '../../../middleware/Social/createPostAsset';
import { DELETE_ASSET } from '../../../middleware/Social/deleteAsset';
import { DELETE_POST } from '../../../middleware/Social/deletePost';
import { GET_TOPICS_PER_PROJECT } from '../../../middleware/Social/getAllTopics';
import { UPDATE_POST } from '../../../middleware/Social/updatePost';
import { UPDATE_TOPIC } from '../../../middleware/Social/updateTopic';
import addIcon from '../../../static/img/add-icon.svg';
import goBackIcon from '../../../static/img/back-icon.svg';
import closeIcon from '../../../static/img/close-icon.svg';
import attachmentIcon from '../../../static/img/file-icon.svg';
import { getDropdown, createKey } from '../../../utils';
import DropzoneArea from '../../Dropzone/DropzoneArea';
import ImageIcon from '../../Icon/ImageIcon';
import ListItemEditDelete from '../../List/ListItemEditDelete';
import { ModalButtonGroup } from '../../RightNavBar/RightNavBar';
import SquareButton from '../../UI/Button/SquareButton';
import Dropdown from '../../UI/Dropdown/Dropdown';
import NormalModal from '../../UI/Modal/NormalModal';
import NotificationMessage from '../../UI/NotificationMessage/NotificationMessage';
import Textarea from '../../UI/Textarea/Textarea';
import Tooltip from '../../UI/Tooltip/Tooltips';

import './views.scss';

const Post = ({ onCloseHandler }) => {
  const params = useParams();

  const { projectId } = params;
  const client = useRef(useApolloClient('bonobo')).current;
  const dropzoneRef = useRef();
  const [pageState, dispatch] = useSocialState();
  const { posts, activeUserId, activePostId: postId, activeCommentId, isAddNewComment, activeUserPost } = pageState;
  const [currentPost, setCurrentPost] = useState({});
  const [message, setMessage] = useState(undefined);
  const [topicId, setTopicId] = useState(undefined);
  const [topics, setTopics] = useState([]);
  const [droppedFile, setDroppedFile] = useState('');
  const [dropzoneAssets, setDropzoneAssets] = useState([]);
  const [isAddNewAttachment, setIsAddNewAttachment] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState(undefined);

  const [notifications, setNotifications] = useState([]);

  const handleClearNotifications = () => {
    setNotifications([]);
  };

  const dispatchPostUpdate = (newState, type) => {
    dispatch({
      type,
      newState,
    });
  };

  const getPostErrorToast = (error) => {
    setNotifications([
      ...notifications,
      {
        group: 'post',
        message: error,
        type: 'error',
      },
    ]);
  };

  const [updatePostRequest] = useMutation(UPDATE_POST, {
    client,
    onCompleted({ post: { update } }) {
      setNotifications([
        ...notifications,
        {
          group: 'post',
          message: 'Post successfully updated',
          type: 'success',
        },
      ]);

      dispatchPostUpdate(update, 'updatePost');
    },
    onError(error) {
      getPostErrorToast(error.message);
    },
  });

  const [deletePostRequest] = useMutation(DELETE_POST, {
    client,
    onCompleted() {
      setModal(undefined);
      setIsModalOpen(false);
      dispatch({
        type: 'deletePost',
        newState: postId,
      });

      setNotifications([
        ...notifications,
        {
          group: 'generic',
          message: 'Post successfully deleted',
          type: 'info',
        },
      ]);

      setTimeout(() => {
        dispatch({
          type: 'setActivePostId',
          newState: null,
        });
        onCloseHandler();
      }, 1000);
    },
    onError(error) {
      getPostErrorToast(error.message);
    },
  });

  const [getTopicsRequest] = useLazyQuery(GET_TOPICS_PER_PROJECT, {
    client,
    onCompleted({ topic: { getTopicsPerProject } }) {
      setTopics(getDropdown(getTopicsPerProject));
    },
  });

  const [updateTopicRequest] = useMutation(UPDATE_TOPIC, {
    client,
    onCompleted({ post: { updateTopic } }) {
      dispatchPostUpdate(updateTopic, 'updatePost');
      setNotifications([
        ...notifications,
        {
          group: 'topics',
          message: 'Topic successfully updated',
          type: 'success',
        },
      ]);
    },
    onError() {
      setNotifications([
        ...notifications,
        {
          group: 'topics',
          message: 'Topic did not update',
          type: 'error',
        },
      ]);
    },
  });

  const getAssetErrorToast = (error) => {
    setNotifications([
      ...notifications,
      {
        group: 'attachment',
        message: error,
        type: 'error',
      },
    ]);
  };

  const [createPostAssetRequest] = useMutation(CREATE_ASSET, {
    client,
    onCompleted({ asset: { createPostAssets } }) {
      const { signedUrl, asset } = createPostAssets[0];
      axios.put(signedUrl, droppedFile, { headers: { 'content-type': droppedFile.type } });
      setDropzoneAssets([...dropzoneAssets, asset]);
      setIsAddNewAttachment(false);
      setNotifications([
        ...notifications,
        {
          group: 'attachment',
          message: 'File successfully uploaded',
          type: 'success',
        },
      ]);
    },
    onError(error) {
      getAssetErrorToast(error.message);
    },
  });

  const [deletePostAssetRequest] = useMutation(DELETE_ASSET, {
    client,
    onCompleted({ asset: { deleteAsset } }) {
      setIsModalOpen(false);
      setModal(undefined);
      if (deleteAsset) {
        setNotifications([
          ...notifications,
          {
            group: 'attachment',
            message: 'Asset successfully deleted',
            type: 'info',
          },
        ]);
      }
    },
    onError(error) {
      getAssetErrorToast(error.message);
    },
  });

  const handleFileDrop = (files) => {
    setDroppedFile(files[0]);
  };

  const handleAddNewAttachment = (value) => {
    setIsAddNewAttachment(value);
  };

  const handleDeleteAttachment = () => {
    if (!modal) {
      return;
    }
    const { assetId } = modal;
    deletePostAssetRequest({
      variables: {
        assetId,
      },
    });
    const updatedAssets = dropzoneAssets.filter((item) => item.assetId !== assetId);
    setDropzoneAssets(updatedAssets);
  };

  const handlePreviousView = () => {
    dispatch({
      type: 'setActivePostId',
      newState: null,
    });
  };

  const handleSaveFile = () => {
    createPostAssetRequest({
      variables: {
        postId,
        type: 'DOCUMENT',
        alt: 'asset',
        fileName: droppedFile.name,
        contentType: droppedFile.type,
        fileSizeInBytes: droppedFile.size,
      },
    });
  };

  const handleSave = () => {
    const {
      message: activeMessage,
      topic: { id: activeTopic },
    } = currentPost;

    if (message !== activeMessage) {
      updatePostRequest({ variables: { postId, message } });
    } else if (Number(topicId) !== Number(activeTopic)) {
      updateTopicRequest({ variables: { postId, topicId: Number(topicId) } });
    } else {
      setNotifications([...notifications, { group: 'generic', message: 'Make changes to update post', type: 'warning' }]);
    }
  };

  const handleDeletePost = () => {
    deletePostRequest({
      variables: {
        postId,
      },
    });
  };

  useEffect(() => {
    getTopicsRequest({ variables: { projectId } });
  }, []);

  useEffect(() => {
    if (isEmpty(currentPost)) {
      return;
    }
    const {
      message: activeMessage,
      topic: { id: activeTopic = {} },
      assets,
    } = currentPost;
    setMessage(activeMessage);
    setTopicId(activeTopic);
    setDropzoneAssets(assets);
  }, [setMessage, setTopicId, currentPost, postId]);

  useEffect(() => {
    if (isEmpty(posts)) {
      return;
    }
    if (activeUserPost) {
      setCurrentPost(activeUserPost);
    } else {
      const selectedPost = posts.find((item) => item.id === postId);
      setCurrentPost(selectedPost);
    }
  }, [setCurrentPost, posts, postId, activeUserPost]);

  useEffect(() => {
    if (droppedFile) {
      handleSaveFile();
    }
  }, [droppedFile]);

  useEffect(() => {
    setIsAddNewAttachment(false);
  }, [onCloseHandler]);

  const showDropZoneIcon = isAddNewAttachment ? closeIcon : addIcon;

  const confirmTypeMap = {
    post: handleDeletePost,
    attachment: handleDeleteAttachment,
  };

  const handleConfirm = (type) => confirmTypeMap[type];

  const handleOpenModal = (info) => {
    setIsModalOpen(true);
    setModal(info);
  };

  return activeCommentId || isAddNewComment ? (
    <Comment onClose={onCloseHandler} />
  ) : (
    <>
      {modal && (
        <NormalModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {modal.message}
          <ModalButtonGroup>
            <SquareButton isClicked={() => setIsModalOpen(false)}>No, cancel</SquareButton>
            <SquareButton bgColor="#dc3545" color="white" isClicked={handleConfirm(modal.type)}>
              Yes, delete
            </SquareButton>
          </ModalButtonGroup>
        </NormalModal>
      )}
      <div className="c-view-wrapper">
        <fieldset>
          <legend className="h-hide">POST</legend>
          <div className="h-flex h-justify-content-between h-align-items-center">
            <div>
              {activeUserId && (
                <div className="h-flex h-align-items-center h-margin-bottom-sm">
                  <button className="c-icon-button h-margin-right-sm" type="button" onClick={handlePreviousView}>
                    <ImageIcon src={goBackIcon} />
                  </button>
                  <strong className="c-view-title">Posts</strong>
                </div>
              )}
              <label className="c-input-title h-margin-bottom-sm" htmlFor="post-text-area">
                Post
              </label>
            </div>
            <Tooltip text="Post text in textfield" />
          </div>
          <Textarea id="post-text-area" isChanged={(value) => setMessage(value)} text={message} />
          {notifications.map(({ group, message, type }) => {
            if (!group.includes('post')) {
              return null;
            }

            return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
          })}
          <div className="h-flex h-justify-content-between h-align-items-center">
            <div className="h-flex h-align-items-center">
              <label className="c-input-title h-margin-top-sm h-margin-bottom-sm h-margin-right-sm" htmlFor="attachment-dropzone">
                Attachments
              </label>
              {!isEmpty(dropzoneAssets) && (
                <div>
                  <button className="c-icon-button" type="button" onClick={() => handleAddNewAttachment(!isAddNewAttachment)}>
                    <ImageIcon src={showDropZoneIcon} />
                  </button>
                </div>
              )}
            </div>
            <Tooltip text="Upload an attachment" />
          </div>
          {isAddNewAttachment && (
            <DropzoneArea dropRef={dropzoneRef} fileType="image/*" id="attachment-dropzone" onDrop={(file) => handleFileDrop(file)} />
          )}
          {!isEmpty(dropzoneAssets) ? (
            <ul className="c-list-container">
              {dropzoneAssets.map(({ assetId, fileName, s3Key, contentType }) => (
                <ListItemEditDelete
                  key={`asset-buttons-${createKey(assetId)}`}
                  asset={{ s3Key, contentType }}
                  icon={attachmentIcon}
                  text={fileName}
                  isBorder
                  onDelete={() =>
                    handleOpenModal({ message: 'Are you sure you want to delete this attachment?', type: 'attachment', assetId })
                  }
                />
              ))}
            </ul>
          ) : (
            <DropzoneArea dropRef={dropzoneRef} fileType="" id="attachment-dropzone" onDrop={(file) => handleFileDrop(file)} />
          )}
          {notifications.map(({ group, message, type }) => {
            if (!group.includes('attachment')) {
              return null;
            }

            return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
          })}
          <div className="h-flex h-justify-content-between h-align-items-center">
            <label className="c-input-title h-margin-top-sm h-margin-bottom-sm" htmlFor="topic-input">
              Topic
            </label>
            <Tooltip text="Change post topic" />
          </div>
          <Dropdown
            availableResponses={topics}
            id="topic-input"
            name="topics"
            selectedValue={topicId}
            onChangeHandler={(_, value) => setTopicId(value)}
          />
          {notifications.map(({ group, message, type }) => {
            if (!group.includes('topics')) {
              return null;
            }

            return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
          })}
          <Comments />
        </fieldset>
      </div>
      <div className="c-toast-container">
        {notifications.map(({ group, message, type }) => {
          if (!group.includes('generic')) {
            return null;
          }

          return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
        })}
      </div>
      <div className="c-sidebar-button-container c-flex-center">
        <Buttons
          onClose={onCloseHandler}
          onDelete={() => handleOpenModal({ message: 'Are you sure you want to delete this post?', type: 'post' })}
          onSave={handleSave}
        />
      </div>
    </>
  );
};

export { Post };
