/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import Icon from '../Icon/Icon';
import ImageMap from '../ImageMap/ImageMap';
import AppSnippet from '../Snippet/AppSnippet';
import AudioSnippet from '../Snippet/AudioSnippet';
import ButtonSnippet from '../Snippet/ButtonSnippet';
import CodeSnippet from '../Snippet/CodeSnippet';
import DocumentSnippet from '../Snippet/DocumentSnippet';
import EmbedSnippet from '../Snippet/EmbedSnippet';
import ImageSnippet from '../Snippet/ImageSnippet';
import MapSnippet from '../Snippet/MapSnippet';
import QuestionSnippet from '../Snippet/QuestionSnippet';
import QuoteSnippet from '../Snippet/QuoteSnippet';
import SliderSnippet from '../Snippet/SliderSnippet';
import SpacerSnippet from '../Snippet/SpacerSnippet';
import TextSnippet from '../Snippet/TextSnippet';
import VideoSnippet from '../Snippet/VideoSnippet';
import Tooltip from '../UI/Tooltip/Tooltips';

import { SnippetTypeConst } from './SnippetTypeConst';

import { AllowedCustomSettingsSnippetTypes } from '../../../new-ekardo';
import AppContext from '../../context/AppContext';
import defaultAvatar from '../../static/img/avataaars-ekardo.svg';
import ActionIcon from '../../static/img/contentSnippet/actions.svg';
import AdvIcon from '../../static/img/contentSnippet/advanced.svg';
import EditIcon from '../../static/img/contentSnippet/edit.svg';

import 'animate.css';

const EditBtn = styled.div`
  background-color: #4bc2ca;
  border-radius: 5px 5px 0 0;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 14px;
  height: 20px;
  position: absolute;
  right: 1.1rem;
  text-align: center;
  text-decoration: none;
  top: -21px;
  width: 40px;
  z-index: 10;

  @media screen and (prefers-reduced-motion: reduce) {
    &:hover {
      height: 30px;
      padding-top: 5px;
      top: -31px;
      transition: none;

      /* background-color: #44bed3; */
    }
  }

  img {
    filter: brightness(0) invert(1);
    width: 14px;
  }

  &:hover {
    height: 30px;
    padding-top: 5px;
    top: -31px;
    transition: top height font-size 4s ease;

    /* background-color: #44bed3; */
  }
`;

const AdvBtn = styled(EditBtn)`
  background-color: #33878d;
  right: 3.7rem;
`;
const DeleteBtn = styled(EditBtn)`
  background-color: #4bc2ca;
  right: 6.25rem;
`;

const CustomBtn = styled(EditBtn)`
  background-color: #33878d;
  right: 11.4rem;
`;

const ActionBtn = styled(EditBtn)`
  background-color: #33878d;
  right: 8.8rem;
`;

const Container = styled.div`
  background-color: ${(props) => (props.isDragging ? '#e3fcef' : props.bgColor)};
  border: 1px solid;
  border-color: ${(props) => props.borderColor || 'transparent'};
  border-radius: 5px;
  color: ${(props) => (props.isDragging ? 'red' : '#000')};
  margin-bottom: 1em;
  padding: 1em;
  position: relative;

  /* overflow: auto; */
  &:hover {
    background: #f6f8f9 0% 0% no-repeat padding-box;
    border: 1px solid #f4f5f7;
    ${EditBtn} {
      display: block;
    }
  }
`;

const FormItem = styled.div`
  margin-bottom: 1em;
`;

//   position: absolute;
//   top: 0;
//   left: 0;
// `;

const getContent = (snippet, answers, identities, sectionId) => {
  switch (snippet.type) {
    case SnippetTypeConst.SUMMARY:
      return (
        <QuestionSnippet
          key={snippet.id}
          answers={answers}
          questionList={snippet.contentSnippetQuestion}
          sectionId={sectionId}
          snippet={snippet}
          isSummary
        />
      );
    case SnippetTypeConst.TEXT:
      return (
        <TextSnippet
          fontColor={snippet.fontColorSnippetText}
          linkColor={snippet.linkColor}
          listIconColor={snippet.listIconColor}
          text={snippet.text}
        />
      );
    case SnippetTypeConst.IMAGE:
      return <ImageSnippet key={snippet.assetId} asset={snippet.asset} assetId={snippet.assetId} />;
    case SnippetTypeConst.VIDEO:
      return <VideoSnippet key={snippet.assetId} asset={snippet.asset} assetId={snippet.assetId} />;
    case SnippetTypeConst.AUDIO:
      return <AudioSnippet key={snippet.assetId} assetId={snippet.assetId} />;
    case SnippetTypeConst.DOCUMENT:
      return <DocumentSnippet key={snippet.assetId} asset={snippet.asset} assetId={snippet.assetId} />;
    case SnippetTypeConst.BUTTON:
      return <ButtonSnippet key={snippet.id} page={snippet.page} snippet={snippet} />;
    case SnippetTypeConst.QUOTE:
      return <QuoteSnippet author={snippet.author} text={snippet.text} />;
    case SnippetTypeConst.CODE:
      return <CodeSnippet key={snippet.id} location={snippet.location} />;
    case SnippetTypeConst.APP:
      return <AppSnippet key={snippet.id} id={snippet.id} />;
    case SnippetTypeConst.MAP:
      return (
        <MapSnippet
          key={snippet.id}
          draggable={snippet.draggable}
          grayscale={snippet.grayscale}
          latitude={snippet.latitude}
          longitude={snippet.longitude}
          zoom={snippet.zoom}
        />
      );

    case SnippetTypeConst.QUESTION:
      return (
        <QuestionSnippet
          key={snippet.id}
          answers={answers}
          contentPageId={identities.pageId}
          groupId={identities.groupId}
          organisationId={identities.organisationId}
          projectId={identities.projectId}
          questionList={snippet}
          sectionId={sectionId}
        />
      );
    case SnippetTypeConst.SPACER:
      return (
        <SpacerSnippet
          key={snippet.id}
          alignment={snippet.alignment}
          height={snippet.height}
          id={snippet.id}
          lineBreak={snippet.lineBreak}
          widthPercentage={snippet.widthPercentage}
        />
      );
    case SnippetTypeConst.AVATAR:
      return <img src={defaultAvatar} />;
    case SnippetTypeConst.SLIDER:
      return <SliderSnippet id={snippet.sliderId} sliderData={snippet.slider} />;
    case SnippetTypeConst.EMBED:
      return <EmbedSnippet code={snippet.code} id={snippet.sliderId} />;
    case SnippetTypeConst.IMAGE_MAP:
      return <ImageMap imageMapDetail={snippet.contentGroup} regions={snippet.contentGroup.regions} />;

    default:
      return null;
  }
};

const ContentSnippet = (props) => {
  const { snippet, index, answers, identities, sectionId, containerId, onUpdateSnippet, onDeleteSnippet } = props;
  const { handleShowSidebar, setCustomSettingsType, setActionsSidebarDetails } = useContext(AppContext);

  const handleDeleteSnippet = () => {
    onDeleteSnippet(containerId, snippet);
  };
  const handleUpdateSnippet = () => {
    onUpdateSnippet(containerId, snippet);
  };

  const handleAdvanceSnippet = () => {
    handleShowSidebar([true, 'SNIPPET', snippet.id]);
  };

  const handleAdvanceSnippetActions = () => {
    setActionsSidebarDetails({
      type: 'targetSnippet',
      id: snippet.id,
      config: snippet,
    });
    handleShowSidebar([true, 'ACTIONS', snippet.id]);
  };

  const handleCustomSettingsSnippet = () => {
    setCustomSettingsType(snippet.type);
    handleShowSidebar([true, 'CUSTOM_SETTINGS', snippet.id]);
  };

  let content;
  if (snippet.type === SnippetTypeConst.FORM) {
    // eslint-disable-next-line max-len

    const questionContainers = snippet.form.contentPages[0].contentSections.map((sections) =>
      sections.sectionContainers.map((question) =>
        question.snippets
          .sort((a, b) => a.sort - b.sort)
          .map((item) => <FormItem key={item.id}>{getContent(item, answers, identities, sectionId)}</FormItem>),
      ),
    );
    content = questionContainers;
  } else if (snippet.type === SnippetTypeConst.QUESTION) {
    content = getContent(snippet, answers, identities, sectionId);
  } else {
    content = getContent(snippet);
  }

  return (
    <Draggable draggableId={snippet.dndId} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          bgColor={snippet.type !== SnippetTypeConst.BUTTON ? snippet.backgroundColor : 'transparent'}
          borderColor={snippet.type !== SnippetTypeConst.BUTTON ? snippet.borderColor : null}
          isDragging={snapshot.isDragging}
        >
          {/* <Index>{snippet.index}</Index> */}
          <Tooltip
            text="Snippet settings"
            triggerElement={() => (
              <AdvBtn onClick={handleAdvanceSnippet}>
                <img alt="advance icon" src={AdvIcon} />
              </AdvBtn>
            )}
          />

          <Tooltip
            text="Snippet actions"
            triggerElement={() => (
              <ActionBtn onClick={handleAdvanceSnippetActions}>
                <img alt="actions icon" src={ActionIcon} />
              </ActionBtn>
            )}
          />

          {AllowedCustomSettingsSnippetTypes.includes(snippet.type) && (
            <Tooltip
              text="Custom settings"
              triggerElement={() => (
                <CustomBtn onClick={handleCustomSettingsSnippet}>
                  <img alt="advance icon" src={AdvIcon} />
                </CustomBtn>
              )}
            />
          )}
          <Tooltip
            text="Edit content"
            triggerElement={() => (
              <EditBtn onClick={handleUpdateSnippet}>
                <img alt="edit icon" src={EditIcon} />
              </EditBtn>
            )}
          />
          <Tooltip
            text="Delete snippet"
            triggerElement={() => (
              <DeleteBtn onClick={handleDeleteSnippet}>
                <Icon id="icon-trash" style={{ width: '1.9rem', height: '1.0rem' }} title="trash" />
              </DeleteBtn>
            )}
          />
          {content}
        </Container>
      )}
    </Draggable>
  );
};

ContentSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onUpdateSnippet: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  snippet: PropTypes.shape({
    __typename: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    dndId: PropTypes.string,
    form: PropTypes.shape([]),
    id: PropTypes.number,
    index: PropTypes.number,
    text: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      questionId: PropTypes.number,
    }),
  ),
  identities: PropTypes.shape({
    containerId: PropTypes.number,
  }),
};

ContentSnippet.defaultProps = {
  answers: [],
  identities: {},
};

export default ContentSnippet;
