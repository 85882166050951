import React from 'react';

import PropTypes from 'prop-types';

import CreateQuestionContentSnippetRequest from '../../middleware/ContentSnippet/createQuestionContentSnippetRequest';
import UpdateQuestionContentSnippetRequest from '../../middleware/ContentSnippet/updateQuestionContentSnippetRequest';
import CreateCalenderQuesConfigRequest from '../../middleware/FormContainer/createCalenderQuesConfigRequest';
import CreateCheckboxQuesConfigRequest from '../../middleware/FormContainer/createCheckboxQuesConfigRequest';
import CreateDropDownQuesConfigRequest from '../../middleware/FormContainer/createDropDownQuesConfigRequest';
import CreateMultilineTextQuesConfigRequest from '../../middleware/FormContainer/createMultilineTextQuesConfigRequest';
import CreateMultiResponseQuesConfigRequest from '../../middleware/FormContainer/createMultiResponseQuesConfigRequest';
import CreateNumberQuesConfigRequest from '../../middleware/FormContainer/createNumberQuesConfigRequest';
import CreateQuestionConfigMessageRequest from '../../middleware/FormContainer/createQuestionConfigMessageRequest';
import CreateRadioButtonQuesConfigRequest from '../../middleware/FormContainer/createRadioButtonQuesConfigRequest';
import CreateSingleTextQuesConfigRequest from '../../middleware/FormContainer/createSingleTextQuesConfigRequest';
import CreateSliderQuesConfigRequest from '../../middleware/FormContainer/createSliderQuesConfigRequest';

export const CreateQuestion = (props) => {
  const {
    allowUserGeneratedResponses,
    containerId,
    correctResponseMessage,
    defaultDate,
    defaultValue,
    defaultValueSl,
    exampleResponseMessage,
    hasQuestionSnippet,
    handleCreateSection,
    handleCreateSectionError,
    hasCreateQuestionReq,
    hasUpdateQuestionSnippet,
    incompleteResponseMessage,
    incorrectResponseMessage,
    isMandatory,
    isVisible,
    majorNumber,
    maxDate,
    maxLength,
    maxResponses,
    maxResponseChecked,
    maxValue,
    maxValueSl,
    minDate,
    minLength,
    minResponses,
    minValue,
    minValueSl,
    onErrorCreateQueSnippetRequest,
    onSuccessResult,
    projectId,
    questConfId,
    question,
    regex,
    responseSet,
    resType,
    snippetId,
    stepSl,
    text,
    title,
    questionIdentifier,
  } = props;

  return (
    <>
      {questConfId && (
        <>
          <CreateQuestionConfigMessageRequest
            message={correctResponseMessage}
            onError={() => {}}
            onSuccessResult={() => {}}
            questionConfigurationId={questConfId}
            type="CORRECT"
          />
          <CreateQuestionConfigMessageRequest
            message={incorrectResponseMessage}
            onError={() => {}}
            onSuccessResult={() => {}}
            questionConfigurationId={questConfId}
            type="INCORRECT"
          />
          <CreateQuestionConfigMessageRequest
            message={exampleResponseMessage}
            onError={() => {}}
            onSuccessResult={() => {}}
            questionConfigurationId={questConfId}
            type="EXAMPLE"
          />
          <CreateQuestionConfigMessageRequest
            message={incompleteResponseMessage}
            onError={() => {}}
            onSuccessResult={() => {}}
            questionConfigurationId={questConfId}
            type="INCOMPLETE"
          />
        </>
      )}
      {hasCreateQuestionReq &&
        {
          ML: (
            <CreateMultilineTextQuesConfigRequest
              caseSensitive
              correctAnswer="Correct Answer Static"
              description={text}
              maxLength={maxLength}
              minLength={minLength}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
              regex={regex}
            />
          ),
          SL: (
            <CreateSingleTextQuesConfigRequest
              description={text}
              maxLength={maxLength}
              minLength={minLength}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
              regex={regex}
            />
          ),
          RB: (
            <CreateRadioButtonQuesConfigRequest
              allowUserGeneratedResponses={allowUserGeneratedResponses}
              canModify
              description={text}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
              scoreValue={500}
              title={title}
            />
          ),
          CB: (
            <CreateCheckboxQuesConfigRequest
              allowUserGeneratedResponses={allowUserGeneratedResponses}
              description={text}
              maxCheckedResponse={maxResponseChecked}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
              title={title}
            />
          ),
          Dd: (
            <CreateDropDownQuesConfigRequest
              canModify
              description={text}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
              title={title}
            />
          ),
          Nu: (
            <CreateNumberQuesConfigRequest
              defaultValue={defaultValue}
              description={text}
              maxValue={maxValue}
              minValue={minValue}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
            />
          ),
          Ca: (
            <CreateCalenderQuesConfigRequest
              defaultDate={defaultDate}
              description={text}
              maxDate={maxDate}
              minDate={minDate}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
            />
          ),
          Sl: (
            <CreateSliderQuesConfigRequest
              correctValue={1}
              defaultValue={defaultValueSl}
              description={text}
              maxValue={maxValueSl}
              minValue={minValueSl}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
              step={stepSl}
              ticks={responseSet}
              title={title}
            />
          ),
          MR: (
            <CreateMultiResponseQuesConfigRequest
              description={text}
              maxResponses={maxResponses}
              minResponses={minResponses}
              onError={handleCreateSectionError}
              onSuccessResult={handleCreateSection}
              projectId={projectId}
            />
          ),
        }[resType]}
      {hasQuestionSnippet && (
        <CreateQuestionContentSnippetRequest
          containerId={containerId}
          isMandatory={isMandatory}
          isVisible={isVisible}
          // majorNumber={customNumber}
          onError={onErrorCreateQueSnippetRequest}
          onSuccessResult={onSuccessResult}
          question={question}
          questionConfigurationId={questConfId}
          visible={isVisible}
          questionIdentifier={questionIdentifier}
        />
      )}
      {hasUpdateQuestionSnippet && (
        <UpdateQuestionContentSnippetRequest
          containerId={containerId}
          isMandatory={isMandatory}
          isVisible={isVisible}
          majorNumber={String(majorNumber)}
          onError={onErrorCreateQueSnippetRequest}
          onSuccessResult={onSuccessResult}
          question={question}
          questionConfigurationId={questConfId}
          snippetId={snippetId}
          visible={isVisible}
          questionIdentifier={questionIdentifier}
        />
      )}
    </>
  );
};

CreateQuestion.propTypes = {
  containerId: PropTypes.number.isRequired,
  correctResponseMessage: PropTypes.string.isRequired,
  defaultDate: PropTypes.instanceOf(Date).isRequired,
  defaultValue: PropTypes.number.isRequired,
  defaultValueSl: PropTypes.number.isRequired,
  exampleResponseMessage: PropTypes.string.isRequired,
  handleCreateSection: PropTypes.func.isRequired,
  handleCreateSectionError: PropTypes.func.isRequired,
  hasCreateQuestionReq: PropTypes.bool.isRequired,
  hasQuestionSnippet: PropTypes.bool.isRequired,
  hasUpdateQuestionSnippet: PropTypes.bool.isRequired,
  incompleteResponseMessage: PropTypes.string.isRequired,
  incorrectResponseMessage: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  maxLength: PropTypes.number.isRequired,
  maxResponseChecked: PropTypes.number.isRequired,
  maxResponses: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  maxValueSl: PropTypes.number.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  minLength: PropTypes.number.isRequired,
  minResponses: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  minValueSl: PropTypes.number.isRequired,
  onErrorCreateQueSnippetRequest: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  regex: PropTypes.string.isRequired,
  resType: PropTypes.string.isRequired,
  responseSet: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      id: PropTypes.number,
    }),
  ).isRequired,
  stepSl: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  allowUserGeneratedResponses: PropTypes.bool,
  questConfId: PropTypes.number,
  snippetId: PropTypes.number,
  text: PropTypes.string,
  questionIdentifier: PropTypes.string,
};

CreateQuestion.defaultProps = {
  allowUserGeneratedResponses: false,
  questConfId: null,
  snippetId: null,
  text: '',
};
