import React from 'react';

import { ButtonIconOnly, Spacing, SettingsButton } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import './DirectoryOperatingHours.css';

const DirectoryOperatingHours = ({ setIsUpsertMode, operatingHours }) => (
  <Spacing>
    <h3 className="c-label">Operating hours</h3>
    <ol className="c-operating-hours">
      {operatingHours.map((item) => {
        const { id, isSingleDay = true, dayStart, dayEnd, timeStart, timeEnd } = item;
        return (
          <li key={id} className="c-operating-hours__item">
            <div className="c-operating-hours__details">
              <p className="c-operating-hours__text">
                {dayStart}
                {!isSingleDay ? ` - ${dayEnd}` : ''}
              </p>
              <p className="c-operating-hours__text">
                {timeStart} - {timeEnd}
              </p>
            </div>
            <SettingsButton onClick={() => setIsUpsertMode(id)} />
          </li>
        );
      })}
    </ol>
    <div className="c-operating-hours-container">
      <ButtonIconOnly iconId="id_plus_icon" text="Add response" onClick={setIsUpsertMode} />
    </div>
  </Spacing>
);

export { DirectoryOperatingHours };

DirectoryOperatingHours.propTypes = {
  operatingHours: PropTypes.arrayOf(
    PropTypes.shape({
      dayEnd: PropTypes.string,
      dayStart: PropTypes.string,
      id: PropTypes.string,
      isSingleDay: PropTypes.bool,
      timeEnd: PropTypes.string,
      timeStart: PropTypes.string,
    }),
  ),
  setIsUpsertMode: PropTypes.func,
};
