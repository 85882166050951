import React from 'react';

import cx from 'classnames';

import { EventTriggerProps } from './EventTrigger.types';
import { EventTriggerIcon } from './EventTriggerIcon';

import './EventTrigger.css';

const EventTrigger = ({
  asset,
  backgroundColor,
  borderColor,
  color,
  handleClick,
  iconType,
  iconUrl,
  isExpanded,
  triggerType,
  triggerText,
}: EventTriggerProps) => {
  const isIconOnly = triggerType === 'ICON_ONLY';
  const isTextOnly = triggerType === 'TEXT_ONLY' || triggerType === 'NONE';

  return (
    <button
      aria-expanded={isExpanded}
      className={cx('c-event-trigger', {
        'c-event-trigger--text-only': isTextOnly,
        'c-event-trigger--icon-only': isIconOnly,
        'c-event-trigger--text-and-icon': !isIconOnly && !isTextOnly,
      })}
      style={{ backgroundColor, borderColor, color }}
      type="button"
      onClick={handleClick}
    >
      <span className="c-event-trigger__details">
        {asset && <img alt={asset.alt} className="c-event-trigger__image" src={asset.presignedUrl ?? ''} />}
        <span
          className={cx('c-event-trigger__title', {
            'h-hide-visually': isIconOnly,
          })}
        >
          {triggerText}
        </span>
      </span>

      {!isTextOnly && (
        <EventTriggerIcon iconType={iconType} iconUrl={iconUrl} isExpanded={isExpanded} triggerBackgroundColor={backgroundColor} />
      )}
    </button>
  );
};

export { EventTrigger };
