/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import { IDBAsset } from '@netfront/common-library';
import cx from 'classnames';

import { CMSEventWrapper } from '../CMSEventWrapper';
import { AppSnippet } from '../Snippets/AppSnippet';
import { AudioSnippet } from '../Snippets/AudioSnippet';
import { AvatarSnippet } from '../Snippets/AvatarSnippet';
import { ButtonSnippet } from '../Snippets/ButtonSnippet';
import { ClusterSnippet } from '../Snippets/ClusterSnippet';
import { CodeSnippet } from '../Snippets/CodeSnippet';
import { EmbedSnippet } from '../Snippets/EmbedSnippet';
import { FormSnippet } from '../Snippets/FormSnippet';
import { ImageSnippet } from '../Snippets/ImageSnippet';
import { MapSnippet } from '../Snippets/MapSnippet';
import { QuestionSnippet } from '../Snippets/QuestionSnippet';
import { QuoteSnippet } from '../Snippets/QuoteSnippet';
import { SliderSnippet } from '../Snippets/SliderSnippet';
import { SpacerSnippet } from '../Snippets/SpacerSnippet';
import { SummaryResultSnippet } from '../Snippets/SummaryResultSnippet';
import { SummarySnippet } from '../Snippets/SummarySnippet';
import { TextSnippet } from '../Snippets/TextSnippet';
import { VideoSnippet } from '../Snippets/VideoSnippet';

import { SnippetProps } from './Snippet.interface';

import { makeComponentMap } from '../../components/makeComponentMap/makeComponentMap';
import { getContentSnippet, useContentPageContext } from '../../contexts';
import { ERROR_MESSAGES } from '../../core';
import { DBCssStyleIconXPositionType, DBCssStyleIconYPositionType, IAssetIdS3SignedUrlType, IContentPage } from '../../interfaces';

import './Snippet.css';

const getComponentByType = makeComponentMap({
  ContentSnippetAppType: AppSnippet,
  ContentSnippetAudioType: AudioSnippet,
  ContentSnippetAvatarType: AvatarSnippet,
  ContentSnippetButtonType: ButtonSnippet,
  ContentSnippetClusterResultType: ClusterSnippet,
  ContentSnippetCodeType: CodeSnippet,
  ContentSnippetEmbedType: EmbedSnippet,
  ContentSnippetFormType: FormSnippet,
  ContentSnippetImageType: ImageSnippet,
  ContentSnippetMapType: MapSnippet,
  ContentSnippetQuestionType: QuestionSnippet,
  ContentSnippetQuoteType: QuoteSnippet,
  ContentSnippetSliderType: SliderSnippet,
  ContentSnippetSpacerType: SpacerSnippet,
  ContentSnippetSummaryResultType: SummaryResultSnippet,
  ContentSnippetSummaryType: SummarySnippet,
  ContentSnippetTextType: TextSnippet,
  ContentSnippetVideoType: VideoSnippet,
  QuestionResponseSetType: QuestionSnippet,
});

const Snippet = ({ accessToken, children, contentSnippetId, userFlowStepTrackId }: SnippetProps) => {
  const { state: contentPage } = useContentPageContext();

  const [assetIdS3SignedUrls, setAssetIdS3SignedUrls] = useState<IAssetIdS3SignedUrlType[]>([]);

  const { actions, customConfigurationOptions } = contentPage as IContentPage;

  const contentSnippet = getContentSnippet(contentSnippetId, contentPage);

  const { contentEvent, cssStyle, __typename: typename, visible: isVisible } = contentSnippet ?? {};
  const { class: cssStyleClass, icons = [] } = cssStyle ?? {};
  const { placeholders } = customConfigurationOptions ?? {};

  const Component = getComponentByType(typename);

  const getIconPositionClass = (x: DBCssStyleIconXPositionType = 'LEFT', y: DBCssStyleIconYPositionType = 'TOP') =>
    `c-snippet__icon--${x.toLowerCase()}-${y.toLowerCase()}`;

  useEffect(() => {
    Promise.all(
      icons.map(async ({ asset }): Promise<IAssetIdS3SignedUrlType> => {
        const { assetId, presignedUrl } = asset ?? ({} as IDBAsset);

        if (!presignedUrl) {
          throw new Error(ERROR_MESSAGES.UNAVAILABLE_PRESIGNED_URL);
        }

        return {
          assetId,
          s3SignedUrl: presignedUrl,
        };
      })
    ).then(setAssetIdS3SignedUrls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isVisible && (
        <CMSEventWrapper contentEvent={contentEvent}>
          <div
            className={cx('c-snippet', `qa-content-snippet-id-${contentSnippetId}`, {
              [`${cssStyleClass}`]: Boolean(cssStyleClass),
            })}
            style={{
              ...cssStyle,
            }}
          >
            {icons.map(({ asset, x, y }, index) => {
              const { alt, assetId } = asset ?? ({} as IDBAsset);
              const { s3SignedUrl } = assetIdS3SignedUrls.find((item) => item.assetId === assetId) ?? {};

              return s3SignedUrl ? (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`snippet-${contentSnippetId}-icon-${assetId}-${index}`}
                  className={cx('c-snippet__icon', getIconPositionClass(x, y))}
                >
                  <img alt={alt} src={s3SignedUrl} />
                </div>
              ) : null;
            })}

            {children ?? (
              <Component
                accessToken={accessToken}
                actions={actions}
                contentSnippetId={contentSnippetId}
                placeholders={placeholders}
                userFlowStepTrackId={userFlowStepTrackId}
                {...contentSnippet}
              />
            )}
          </div>
        </CMSEventWrapper>
      )}
    </React.Fragment>
  );
};

export { Snippet };
