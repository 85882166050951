import React, { useEffect, useState } from 'react';

import { Input, Label } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { convertDaysAndHoursToSeconds, convertSecondsToDaysAndHours } from './ConditionDateTimespan.helpers';

const ConditionDateTimespan = ({ id, onChange, name, value, labelText }) => {
  const [days, setDays] = useState(0);

  const handleDaysUpdate = ({ target: { value: updatedValue } }) => {
    setDays();
    onChange({ name, value: convertDaysAndHoursToSeconds(Number(updatedValue), 0) });
  };

  useEffect(() => {
    const { days: initialDays } = convertSecondsToDaysAndHours(value);

    setDays(initialDays);
  }, [value]);

  return (
    <section className="c-condition-timespan">
      <Label forId={`id_${id}_days`} labelText={labelText} />

      <div className="c-condition-timespan__inputs">
        <div className="c-condition-timespan__input-wrapper">
          <Input
            additionalClassNames="c-condition-timespan__input"
            id={`id_${id}_days`}
            labelText="Days"
            name={`days_${id}`}
            type="number"
            value={days}
            isLabelHidden
            onChange={handleDaysUpdate}
          />
          <span>Days</span>
        </div>
      </div>
    </section>
  );
};

ConditionDateTimespan.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export { ConditionDateTimespan };
