import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_BACKOFFICE_ENDPOINT = gql`
  query GetByBackofficeEndpoint($endpoint: String!) {
    getByBackofficeEndpoint(url: $endpoint) {
      id
      guid
      organisationId
    }
  }
`;

const getByBackofficeEndpoint = ({ onError, onSuccessResult, endpoint }) => (
  <Query
    client={Client(false)}
    query={GET_BACKOFFICE_ENDPOINT}
    variables={{ endpoint }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getByBackofficeEndpoint.propTypes = {
  endpoint: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getByBackofficeEndpoint;
