import React from 'react';

import { Label, Input, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

const ConditionTextQuestion = ({ text, onInputChange }) => (
  <>
    <Label additionalClassNames="c-action__item__label h-flex" forId="question response" labelText="Question response" isRequired>
      <Tooltip placement="left" text="This action will execute if the text added is as defined below" />
    </Label>
    <Input
      id="id_condition_text"
      name="text"
      type="text"
      value={text}
      onChange={({ target: { name, value } }) => onInputChange({ name, value })}
    />
  </>
);

ConditionTextQuestion.propTypes = {
  onInputChange: PropTypes.func,
  text: PropTypes.string,
};

export { ConditionTextQuestion };
