import React, { useState, useEffect } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { useParams } from 'react-router-dom';

import Moderation from '../../components/Social/Moderation/Moderation';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import SocialPageState from '../../context/socialPage/SocialPageState';
import { useNewEkardoSiteUrls } from '../../hooks';

const SocialPage = (props) => {
  const { match } = props;

  const params = useParams();
  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/social`,
        title: 'Social',
        isExternal: true,
      },
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/social/moderation`,
        title: 'Moderation',
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <SocialPageState>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Moderate social content"
        pageDescription="Moderate social content"
        pageTitle="Social media"
        params={match.params}
      >
        <Moderation />
      </PageWrapper>
    </SocialPageState>
  );
};

export default SocialPage;
