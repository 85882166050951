import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_ASSET_LIBRARY = gql`
  mutation UpdateAssetLibraryRequest(
    $xAxisFocus: Int
    $assetId: String!
    $alt: String
    $tags: String
    $transcript: String
  ) {
    asset {
      updateAsset(
        asset: {
          alt: $alt
          assetId: $assetId
          xAxisFocus: $xAxisFocus
          tags: $tags
          transcript: $transcript
        }
      ) {
        assetId
        contentType
        fileName
        fileSizeInBytes
        projectId
        presignedUrl
        s3Key
        transcript
      }
    }
  }
`;

const updateAssetLibraryRequest = ({
  alt,
  assetId,
  xAxisFocus,
  tags,
  transcript,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_ASSET_LIBRARY}>
    {(updateAssetLibrary, { loading, error, data }) => {
      const variables = {
        alt,
        assetId,
        xAxisFocus,
        tags,
        transcript,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.asset.updateAsset);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateAssetLibrary}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateAssetLibraryRequest.propTypes = {
  alt: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  tags: PropTypes.string.isRequired,
  transcript: PropTypes.string.isRequired,
  xAxisFocus: PropTypes.number.isRequired,
};

export default updateAssetLibraryRequest;
