import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { useDomain } from '@netfront/gelada-identity-library';
import { ButtonIconOnly, Label, Spacing } from '@netfront/ui-library';
import sortBy from 'lodash.sortby';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';

import { DirectoryTable } from './Tables/Directory/DirectoryTable';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import DirectorySideBar from '../../components/RightNavBar/DirectorySideBar';
import { Tabs } from '../../components/Tabs/Tabs';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { useNewEkardoSiteUrls } from '../../hooks';
import client from '../../middleware/client';
import { GET_DIRECTORY_TYPES } from '../../middleware/Project/getDirectoryType';
import { SEARCH_DIRECTORIES } from '../../middleware/Project/searchDirectories';
import { DEFAULT_TOAST_OPTIONS } from '../../utils';
import { getApiErrorMessages, getBreadCrumbPath } from '../../utils/utils';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './LibraryDirectoryPage.scss';

const LibraryDirectoryPage = () => {
  const { pathname } = useLocation();
  const { projectId, orgId } = useParams();
  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const [breadCrumbPath, setBreadcrumbPath] = useState([]);
  const [directories, setDirectories] = useState([]);
  const [directoryFilter, setDirectoryFilter] = useState(null);
  const [directoryTypeId, setDirectoryTypeId] = useState();
  const [directoryTypeOptions, setDirectoryTypeOptions] = useState([]);
  const [directoryTypeTabs, setDirectoryTypeTabs] = useState(null);
  const [isApplyFilterButtonClicked, setIsApplyFilterButtonClicked] = useState(false);
  const [isClearFilterButtonClicked, setIsClearFilterButtonClicked] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState(undefined);
  const [selectedDirectory, setSelectedDirectory] = useState(null);

  const handleApplyFilters = (filters) => {
    const { searchInputValue: title } = filters;

    setIsApplyFilterButtonClicked(true);
    setSearchInputValue(title);
  };

  const handleClearFilters = () => {
    setDirectoryFilter((currentState) => ({
      ...currentState,
      isApplied: false,
      searchInput: {
        ...currentState.searchInput,
        searchInputValue: undefined,
      },
    }));

    setIsClearFilterButtonClicked(true);
    setSearchInputValue(undefined);
  };

  const handleSearchInputChange = (value) => {
    setSearchInputValue(value);
  };

  const [searchDirectories, { loading: isSearchDirectoriesLoading }] = useLazyQuery(SEARCH_DIRECTORIES, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted({ directory: { searchDirectories: returnedDirectories } }) {
      setDirectories(sortBy(returnedDirectories, 'sort'));

      if (!isApplyFilterButtonClicked) {
        return;
      }

      setDirectoryFilter((currentDirectoryFilter) => ({
        ...currentDirectoryFilter,
        isApplied: true,
      }));

      setIsApplyFilterButtonClicked(false);
    },
    onError(error) {
      getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));
    },
  });

  const [getDirectoryTypes, { loading: isGetDirectoryTypeLoading }] = useLazyQuery(GET_DIRECTORY_TYPES, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const {
        directoryType: { get: directoryTypes },
      } = response;

      if (!directoryTypes.length) {
        return;
      }

      const firstDirectoryType = directoryTypes[0];

      const directoryOptions = directoryTypes.map(({ name, id }) => ({
        id,
        name,
        value: id,
      }));

      setDirectoryTypeId(firstDirectoryType.id);
      setDirectoryTypeOptions(directoryOptions);
    },
    onError(error) {
      getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));
    },
  });

  const handleCloseSideBar = () => {
    setIsSideBarOpen(false);
    setSelectedDirectory(null);
  };

  const handleAddDirectoryItem = (itemToCreate) => {
    setDirectories((currentState) => [...currentState, itemToCreate]);
    handleCloseSideBar();
  };

  const handleRemoveDirectoryItem = (itemToRemove) => {
    setDirectories((currentState) => currentState.filter((item) => item.id !== itemToRemove.id));
    handleCloseSideBar();
  };

  const handleSelectTableTab = (id) => {
    setDirectoryTypeId(id);
  };

  const handleShowCreateDirectorySideBar = () => {
    setIsCreateNew(true);
    setIsSideBarOpen(true);
  };

  const handleShowUpdateDirectorySideBar = (directory) => {
    setIsCreateNew(false);
    setIsSideBarOpen(true);
    setSelectedDirectory(directory);
  };

  const handleUpdateDirectoryItem = (itemToUpdate) => {
    setDirectories((currentState) =>
      currentState
        .map((item) => (item.id === itemToUpdate.id ? itemToUpdate : item))
        .filter(({ directoryTypeId: currentDirectoryTypeId }) => currentDirectoryTypeId === directoryTypeId),
    );
    handleCloseSideBar();
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    getDirectoryTypes({
      variables: {
        projectId,
      },
    });
  }, [projectId]);

  useEffect(() => {
    if (!directoryTypeId && !directoryTypeOptions.length) {
      return;
    }

    searchDirectories({
      variables: {
        directoryTypeId,
        projectId,
      },
    });
  }, [directoryTypeId, directoryTypeOptions]);

  useEffect(() => {
    if (!isApplyFilterButtonClicked) {
      return;
    }

    searchDirectories({
      variables: {
        directoryTypeId,
        projectId,
        title: searchInputValue,
      },
    });
  }, [isApplyFilterButtonClicked, searchInputValue]);

  useEffect(() => {
    if (!isClearFilterButtonClicked) {
      return;
    }

    searchDirectories({
      variables: {
        directoryTypeId,
        projectId,
      },
    });
    setIsClearFilterButtonClicked(false);
  }, [isClearFilterButtonClicked]);

  useEffect(() => {
    if (searchInputValue === undefined) {
      return;
    }

    setDirectoryFilter((currentState) => ({
      ...currentState,
      searchInput: {
        ...currentState.searchInput,
        searchInputValue,
      },
    }));
  }, [searchInputValue]);

  useEffect(() => {
    if (!(directoryTypeOptions.length && directoryFilter)) {
      return;
    }

    setDirectoryTypeTabs(
      directoryTypeOptions.map(({ id, name }) => ({
        component: <DirectoryTable directories={directories || []} filter={directoryFilter} onEdit={handleShowUpdateDirectorySideBar} />,
        id,
        label: name,
      })),
    );
  }, [directories, directoryTypeOptions, directoryFilter]);

  useEffect(() => {
    setDirectoryFilter({
      isApplied: false,
      onApplyFiltersHandler: handleApplyFilters,
      onClearFiltersHandler: handleClearFilters,
      searchInput: {
        onSearchInputChangeHandler: handleSearchInputChange,
        searchInputValue,
      },
    });
  }, []);

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library`,
        title: 'Library',
        isExternal: true,
      },
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library/directory`,
        title: 'Directory',
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Directory"
        isLoaderVisible={isGetDirectoryTypeLoading || isSearchDirectoriesLoading}
        pageDescription="Manage the project directory"
        pageTitle="Manage the project directory"
      >
        <ProjectNavBar title="Project team" />
        <Spacing>
          <div className="h-flex h-align-items-center h-flex-gap h-justify-content-end">
            <Label
              additionalClassNames="c-input-title h-flex h-align-items-center h-flex-gap "
              forId="add new directory"
              labelText="Add New"
              spacing="none"
            />
            <ButtonIconOnly
              additionalClassNames="c-add-new-button"
              iconId="id_plus_icon"
              id="add new directory"
              onClick={handleShowCreateDirectorySideBar}
            />
          </div>
        </Spacing>
        <div className="c-directories-wrapper">
          {directoryTypeTabs ? (
            <Tabs isInSideBar={false} items={directoryTypeTabs} selectedTabId={directoryTypeId} onTabChangeHandler={handleSelectTableTab} />
          ) : null}
        </div>
        {isSideBarOpen && (
          <DirectorySideBar
            directoryTypeOptions={directoryTypeOptions}
            isCreateNew={isCreateNew}
            isSideBarOpen={isSideBarOpen}
            item={selectedDirectory}
            onClose={handleCloseSideBar}
            onCreate={handleAddDirectoryItem}
            onDelete={handleRemoveDirectoryItem}
            onUpdate={handleUpdateDirectoryItem}
          />
        )}
      </PageWrapper>
      <Toaster toastOptions={DEFAULT_TOAST_OPTIONS} />
    </>
  );
};

export default LibraryDirectoryPage;
