import gql from 'graphql-tag';

export const CREATE_DISCOUNT = gql`
  mutation createDiscount(
    $code: String!
    $description: String!
    $maxUsage: Int!
    $percentage: Decimal!
    $period: DiscountPeriodInput!
    $projectGuid: String!
  ) {
    discounts {
      create(
        request: {
          code: $code
          description: $description
          maxUsage: $maxUsage
          percentage: $percentage
          period: $period
          projectGuid: $projectGuid
        }
      ) {
        code
        createdAtUtc
        description
        expiresAtUtc
        id
        maxUsage
        modifiedAtUtc
        percentage
        projectGuid
        startsAtUtc
        status
      }
    }
  }
`;
