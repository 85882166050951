import { gql } from '@apollo/client';

const GET_USER_FLOW_STEPS_BY_URL_QUERY = gql`
  query getUserFlowSteps($projectId: String!, $stepType: EUserFlowStepType) {
    userFlowStep {
      getUserFlowSteps(projectId: $projectId, stepType: $stepType) {
        contentGroup {
          contentPages(status: [PUBLISHED, UNPUBLISHED], topLevelOnly: true) {
            ...userFlowStepContentPage
          }
          description
          groupingContentGroup {
            description
            id
            name
            url
          }
          id
          title
        }
        description
        id
        isComplete
        lastTracking {
          completedDate
        }
        lastViewedContentPage {
          ...userFlowStepContentPage
        }
        percentageCompleted
        sort
        stepName
        url
        userRepetition {
          completedDate
          id
          isCompleted
          startedDate
        }
        userStatus
      }
    }
  }

  fragment userFlowStepContentPage on ContentPageType {
    hasBeenViewed
    id
    sort
    title
    url
  }
`;

export { GET_USER_FLOW_STEPS_BY_URL_QUERY };
