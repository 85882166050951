import React, { useState, useContext } from 'react';

import { ToggleSwitch } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import Dropdown from '../UI/Dropdown/Dropdown';
import Input from '../UI/Input/InputWhite';
import SidebarTab from '../UI/Tab/SidebarTab';
import Textarea from '../UI/Textarea/Textarea';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';

import AppContext from '../../context/AppContext';
import CreateContentGroupForm from '../../middleware/ContentGroup/createContentGroupForm';
import { useFormDetailQuery } from '../../middleware/ContentGroup/getContentGroupFormDetailReq';
import UpdateContentGroupForm from '../../middleware/ContentGroup/updateContentGroupForm';
import { useUpdateCorrectResponsesMutation } from '../../middleware/Form/updateCorrectResponsesMutations';

const Container = styled.div`
  margin: 1em 0;
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const markableQuestionTypes = ['Radio', 'Checkbox', 'DropDownList'];

const FormSideBar = (props) => {
  const { project, org } = useContext(AppContext);

  const { isEdit, isPublishForm, groupId, closeFromBar, isLibraryForm, onDelete, onUpdate, onCreate, onPublishForm } = props;

  const correctResponseMutations = useUpdateCorrectResponsesMutation();

  const [sendRequestEdit, setSendRequestEdit] = useState(false);
  const history = useHistory();

  const [form, setForm] = useState({
    title: '',
    description: '',
    markableQuestions: '',
    formType: 'LIST',
    status: '',
  });

  function updateFormField(key, value) {
    setForm((currentForm) => ({ ...currentForm, [key]: value }));
  }
  const [errMsg, setErrMsg] = useState('');
  const [sendRequest, setSendRequest] = useState(false);

  const handleErrorMessage = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg('');
    }, 3000);
    setSendRequest(false);
  };

  const handleCreateRequest = (data) => {
    setSendRequest(false);
    history.push(`/dashboard/${org.key}/${project.id}/form-builder/form/${data.id}?library=${isLibraryForm}`);
  };

  const handleCreateOrUpdate = () => {
    if (!isEdit) {
      onCreate();
      setSendRequest(true);
    } else {
      onUpdate({ group: { title: form.title, description: form.description } });
      setSendRequestEdit(true);
    }
    document.body.style.overflow = 'unset';
  };

  const handleCancel = () => {
    document.body.style.overflow = 'unset';
    closeFromBar();
  };

  const onSuccessGetFormDetail = (data) => {
    const tempForm = data.contentGroup.getContentGroup;

    const savedCorrectResponses = tempForm.correctResponses;
    let markableQuestions = [];
    tempForm.contentPages[0].contentSections.forEach((contentSection) => {
      contentSection.sectionContainers.forEach((sectionContainer) => {
        markableQuestions = sectionContainer.snippets
          .filter(
            (snippet) =>
              // eslint-disable-next-line no-underscore-dangle
              snippet.__typename === 'ContentSnippetQuestionType' &&
              // eslint-disable-next-line no-underscore-dangle
              markableQuestionTypes.includes(snippet.configuration.__typename),
          )
          .map((question) => {
            const correctResponse = savedCorrectResponses.find(
              (savedCorrectResponse) => savedCorrectResponse.contentSnippetId === question.id,
            );

            const correctResponseId =
              (correctResponse &&
                (correctResponse.questionResponseCheckboxId ||
                  correctResponse.questionResponseRadioId ||
                  correctResponse.questionResponseDropDownListId)) ||
              null;

            return {
              ...question,
              correctResponseId,
            };
          });
      });
    });

    setForm({
      title: tempForm.title,
      description: tempForm.description,
      url: tempForm.url,
      sort: tempForm.sort,
      status: tempForm.status,
      markableQuestions,
    });
  };

  const handleUpdateFormRequest = () => {
    setSendRequestEdit(false);
    closeFromBar();
  };
  const formTypeOptions = [
    {
      id: 0,
      value: 'LIST',
      label: 'List',
    },
    {
      id: 1,
      value: 'TABLE',
      label: 'Table',
    },
    {
      id: 2,
      value: 'QUIZ',
      label: 'Quiz',
    },
  ];

  const handleErrorMessageUpdateForm = () => {
    setSendRequestEdit(false);
    closeFromBar();
  };

  useFormDetailQuery({
    contentGroupId: groupId,
    onCompleted: onSuccessGetFormDetail,
  });

  const tabItems = [
    {
      title: 'General',
      content: (
        <Container>
          <Label htmlFor="form_title">
            Title
            <Tooltip text="Form title" />
          </Label>
          <Input
            id="form_title"
            text={form.title}
            onChange={({ target: { value } }) => {
              updateFormField('title', value);
            }}
          />
          <Label htmlFor="form_description">
            Description (Optional)
            <Tooltip text="Description of the form" />
          </Label>
          <Textarea
            id="form_description"
            text={form.description}
            onChange={({ target: { value } }) => {
              updateFormField('description', value);
            }}
          />
          <Label htmlFor="form_description">
            Type
            <Tooltip text="Type of the form" />
          </Label>
          <Dropdown
            availableResponses={formTypeOptions}
            name="style"
            onChangeHandler={(_, value) => {
              updateFormField('formType', value);
            }}
          />
          <Label htmlFor="form_description">
            Status
            <Tooltip text="Status of the form" />
          </Label>
          <ToggleSwitch
            id="forms-toggle"
            isChecked={form.status === 'PUBLISHED'}
            labelText="Activated"
            value={form.status === 'PUBLISHED'}
            onChange={() => {
              updateFormField('status', isPublishForm ? 'PUBLISHED' : 'UNPUBLISHED');
              onPublishForm(!isPublishForm);
            }}
          />
        </Container>
      ),
    },
  ];

  function selectCorrectResponse(questionId, responseId) {
    setForm((currentForm) => {
      const temp = [...currentForm.markableQuestions];
      const editedQuestionIndex = temp.findIndex((question) => question.id === questionId);
      const currentCorrectResponseId = temp[editedQuestionIndex].correctResponseId;
      temp[editedQuestionIndex].correctResponseId = responseId;

      // eslint-disable-next-line no-underscore-dangle
      const questionType = temp[editedQuestionIndex].configuration.__typename;

      const { unset, set } = correctResponseMutations[questionType];

      if (currentCorrectResponseId) {
        unset({
          variables: {
            questionResponseId: currentCorrectResponseId,
            contentGroupId: groupId,
            contentSnippetId: questionId,
          },
        });
      }
      if (responseId) {
        set({
          variables: {
            questionResponseId: parseInt(responseId, 10),
            contentGroupId: groupId,
            contentSnippetId: questionId,
          },
        });
      }

      return {
        ...currentForm,
        markableQuestions: temp,
      };
    });
  }

  if (form.markableQuestions.length > 0) {
    tabItems.push({
      title: 'Correct answers',
      content: (
        <Container>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {form.markableQuestions.map((question) => {
              // TODO: there is some strange conflict of
              // rules that needs investigation
              // eslint-disable-next-line max-len
              const availableResponses = question.configuration.responseSet.availableResponses.map((response) => ({
                id: response.id,
                label: `${response.label} (value: ${response.value})`,
                value: response.id,
              }));

              availableResponses.unshift({
                id: 'No selection',
                label: 'No selection',
                value: null,
              });
              return (
                <li>
                  <Label>
                    <span dangerouslySetInnerHTML={{ __html: question.question }} />
                    <Dropdown
                      availableResponses={availableResponses}
                      selectedValue={question.correctResponseId}
                      onChangeHandler={(_, value) => selectCorrectResponse(question.id, value)}
                    />
                  </Label>
                </li>
              );
            })}
          </ul>
        </Container>
      ),
    });
  }

  return (
    <>
      <RightNavBar
        confirmBtnName={isEdit ? 'Update' : 'Create'}
        hasDelete={isEdit}
        onCancel={handleCancel}
        onDelete={onDelete}
        onSave={handleCreateOrUpdate}
      >
        <div>
          <h2>{isEdit ? 'Edit' : 'Create'} form</h2>
          <SidebarTab items={tabItems} />
        </div>
        {errMsg !== '' && <PopupMessageSideBar message={errMsg} />}
      </RightNavBar>
      {sendRequest && (
        <CreateContentGroupForm
          description={form.description || form.title}
          formType={form.formType}
          projectId={project.id}
          title={form.title}
          onError={handleErrorMessage}
          onSuccessResult={handleCreateRequest}
        />
      )}
      {sendRequestEdit && (
        <UpdateContentGroupForm
          contentGroupId={groupId}
          description={form.description}
          projectId={project.id}
          sort={form.sort}
          title={form.title}
          url={form.url}
          onError={handleErrorMessageUpdateForm}
          onSuccessResult={handleUpdateFormRequest}
        />
      )}
    </>
  );
};

FormSideBar.propTypes = {
  closeFromBar: PropTypes.func,
  groupId: PropTypes.number,
  isEdit: PropTypes.bool,
  isLibraryForm: PropTypes.bool,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

FormSideBar.defaultProps = {
  closeFromBar: () => {},
  groupId: null,
  isEdit: false,
  isLibraryForm: false,
  onDelete: () => {},
  onCreate: () => {},
  onUpdate: () => {},
};

export default FormSideBar;
