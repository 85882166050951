import React, { useContext, useState, useEffect } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { useLocation, useParams } from 'react-router-dom';

import { CardBox } from '../ProjectConsolePage/ProjectConsolePage';

import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import SocialPageState from '../../context/socialPage/SocialPageState';
import { useNewEkardoSiteUrls } from '../../hooks';
import moderationIcon from '../../static/img/moderation.svg';
import topicIcon from '../../static/img/topic-icon.svg';

const SocialPage = () => {
  const { project } = useContext(AppContext);
  const { pathname } = useLocation();
  const params = useParams();
  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  const items = [
    {
      image: moderationIcon,
      key: 'moderation',
      path: 'moderation',
      title: 'Moderation',
      visible: true,
    },
    {
      image: topicIcon,
      key: 'topics',
      path: 'topics',
      title: 'Topics',
      visible: project?.features?.includes('HAS_BONOBO_TOPIC'),
    },
  ];

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/social`,
        title: 'Social',
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <SocialPageState>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage social content"
        pageDescription="Manage social content"
        pageTitle="Social media"
        params={params}
      >
        <CardBox>
          {items
            .filter((r) => r.visible)
            .map((featureCard) => {
              const { image, key, path, title } = featureCard;
              return <MenuCard key={`feature-card-${key}`} href={`${pathname}/${path}`} name={title} src={image} />;
            })}
        </CardBox>
      </PageWrapper>
    </SocialPageState>
  );
};

export default SocialPage;
