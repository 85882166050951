import React, { useState, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import { ActionsOverview, UpsertAction } from './Components';
import { WRAPPER_TYPE_ACTION_KEY_MAP } from './UpsertActionsTab.constants';

import { ModalButtonGroup } from '../../../../legacy/components/ButtonGroup/styled';
import Preloader from '../../../../legacy/components/Preloader/Preloader';
import SquareButton from '../../../../legacy/components/UI/Button/SquareButton';
import NormalModal from '../../../../legacy/components/UI/Modal/NormalModal';
import AppContext from '../../../../legacy/context/AppContext';
import { useToast } from '../../../../legacy/hooks';
import { useGetContentPage, useDeleteAction, useGetSnippetEventDetails } from '../../../hooks';

const UpsertActionsTab = ({ onClose, onDelete, activePageId }) => {
  const { actionsSidebarDetails } = useContext(AppContext);
  const { handleToastError } = useToast();

  const [allActions, setAllActions] = useState([]);
  const [selectedActionId, setSelectedActionId] = useState(undefined);
  const [isUpsertMode, setIsUpsertMode] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteActionId, setDeleteActionId] = useState();
  const [contentEventId, setContentEventId] = useState();

  const { type, id, config } = actionsSidebarDetails;
  const actionKey = WRAPPER_TYPE_ACTION_KEY_MAP[type];

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetSnippetEventDetailsOnCompleted = ({ snippet }) => {
    const { contentEvent } = snippet;

    if (!contentEvent) return;
    const { event, id: eventId } = contentEvent ?? {};

    if (['REVEAL', 'POP_UP'].includes(event)) {
      setContentEventId(eventId);
    }
  };

  // get event details for the snippet to allow us to determine whether relevant actions are available for selection
  const { handleGetSnippetEventDetails, isLoading: isGetSnippetEventDetailsLoading = false } = useGetSnippetEventDetails({
    onCompleted: handleGetSnippetEventDetailsOnCompleted,
    onError: handleGetError,
  });

  const handleGetContentPageOnCompleted = ({ actions }) => {
    setAllActions(actions);
  };

  const { handleGetContentPage, isLoading: isGetContentPageLoading = false } = useGetContentPage({
    onCompleted: handleGetContentPageOnCompleted,
    onError: handleGetError,
  });

  const handleDeleteActionOnCompleted = () => {
    setSelectedActionId(undefined);
    setIsUpsertMode(false);
    handleGetContentPage({
      variables: {
        contentPageId: activePageId,
      },
    });
  };

  const { handleDeleteAction: executeDeleteAction, isLoading: isDeleteActionLoading = false } = useDeleteAction({
    onCompleted: handleDeleteActionOnCompleted,
    onError: handleGetError,
  });

  const handleCancelUpsertMode = () => {
    setSelectedActionId(undefined);
    setIsUpsertMode(false);
  };

  const handleEditAction = (actionId) => {
    setSelectedActionId(actionId);
    setIsUpsertMode(true);
  };

  const handleUpdate = () => {
    handleCancelUpsertMode();
    handleGetContentPage({
      variables: {
        contentPageId: activePageId,
      },
    });
  };

  const handleDeleteAction = (actionId) => {
    executeDeleteAction({
      variables: {
        actionId: Number(actionId),
      },
    });
    setIsDeleteDialogOpen(false);
    setDeleteActionId(undefined);
  };

  const cancelDeleteAction = () => {
    setIsDeleteDialogOpen(false);
    setDeleteActionId(undefined);
  };

  useEffect(() => {
    if (!activePageId) {
      return;
    }

    handleGetContentPage({
      variables: {
        contentPageId: activePageId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageId]);

  // Determine whether the wrapper has an event attached to it.
  useEffect(() => {
    if (selectedActionId) return;
    if (!config) return;

    if (type === 'targetSnippet') {
      handleGetSnippetEventDetails({
        variables: {
          contentSnippetId: id,
        },
      });
    } else if (type === 'container') {
      const { contentEventId: eventId, contentEvent } = config;
      const { event } = contentEvent ?? {};

      if (['REVEAL', 'POP_UP'].includes(event)) {
        setContentEventId(eventId);
      }
    } else if (type === 'section') {
      const { event } = config;
      const { event: eventType, id: eventId } = event ?? {};
      if (['REVEAL', 'POP_UP'].includes(eventType)) {
        setContentEventId(eventId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, config, selectedActionId]);

  const isLoading = isGetContentPageLoading || isDeleteActionLoading || isGetSnippetEventDetailsLoading;

  return (
    <>
      {isLoading && <Preloader />}
      <>
        {isUpsertMode ? (
          <UpsertAction
            activePageId={activePageId}
            selectedActionId={selectedActionId}
            type={type}
            onCancel={handleCancelUpsertMode}
            onDelete={handleDeleteAction}
            onUpdate={handleUpdate}
          />
        ) : (
          <ActionsOverview
            actionItems={allActions.filter(
              (item) => item[actionKey] === id || (Boolean(contentEventId) && item.contentEventId === contentEventId),
            )}
            hasDelete
            onClose={onClose}
            onDelete={onDelete ? () => onDelete(config) : undefined}
            onDeleteClick={(actionId) => {
              setIsDeleteDialogOpen(true);
              setDeleteActionId(actionId);
            }}
            onEditClick={handleEditAction}
          />
        )}

        <NormalModal isOpen={isDeleteDialogOpen} onClose={cancelDeleteAction}>
          <p>Delete action?</p>
          <ModalButtonGroup>
            <SquareButton isClicked={cancelDeleteAction}>No, cancel</SquareButton>
            <SquareButton bgColor="#dc3545" color="white" isClicked={() => handleDeleteAction(deleteActionId)}>
              Yes, delete
            </SquareButton>
          </ModalButtonGroup>
        </NormalModal>
      </>
    </>
  );
};

UpsertActionsTab.propTypes = {
  onClose: PropTypes.func.isRequired,
  activePageId: PropTypes.number,
  onDelete: PropTypes.func,
};

export { UpsertActionsTab };
