import React from 'react';

import { Label, Tooltip, Select, Spacing } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import DatePicker from '../../../../../../../legacy/components/UI/Input/DatePicker';
import { ConditionDateTimespan } from '../../ConditionDateTimespan';

const ConditionDateQuestion = ({ dateRangeType, minDate, maxDate, minTimeSpan = '', maxTimeSpan = '', selectedDate, onInputChange }) => (
  <>
    <Spacing>
      <Label additionalClassNames="c-action__item__label h-flex" forId="id_date_range_type" labelText="Type" isRequired>
        <Tooltip
          placement="right"
          text="Fixed: A set point in time between two given dates, Fluid: A fluid moving time span based on the supplied date in the past or future, Specific: A specific fixed date must match the date given in the condition"
        />
      </Label>
      <Select
        id="id_date_range_type"
        labelText="Type"
        name="dateRangeType"
        options={[
          {
            id: 0,
            name: 'Please select',
            value: '',
          },
          {
            id: 1,
            name: 'Fluid',
            value: 'FLUID',
          },
          {
            id: 2,
            name: 'Fixed',
            value: 'FIXED',
          },
          {
            id: 2,
            name: 'Specific',
            value: 'SPECIFIC',
          },
        ]}
        value={dateRangeType}
        isLabelHidden
        onChange={({ target: { value, name } }) => onInputChange({ value, name })}
      />
    </Spacing>

    {dateRangeType === 'FIXED' && (
      <>
        <Spacing>
          <Label additionalClassNames="c-action__item__label h-flex" forId="date-picker-inline-min-date" labelText="Min date" isRequired>
            <Tooltip placement="right" text="The minimum date to be used to meet the condition" />
          </Label>
          <DatePicker
            id="min-date"
            name="minDate"
            value={minDate}
            onChange={({ target: { value, name } }) => onInputChange({ value, name })}
          />
        </Spacing>

        <Spacing>
          <Label additionalClassNames="c-action__item__label h-flex" forId="date-picker-inline-max-date" labelText="Max date" isRequired>
            <Tooltip placement="right" text="The maximum date to be used to meet the condition" />
          </Label>
          <DatePicker
            id="max-date"
            name="maxDate"
            value={maxDate}
            onChange={({ target: { value, name } }) => onInputChange({ value, name })}
          />
        </Spacing>
      </>
    )}
    {dateRangeType === 'FLUID' && (
      <>
        <Spacing>
          <ConditionDateTimespan
            id="minTimeSpan"
            labelText="Minimum time span"
            name="minTimeSpan"
            value={minTimeSpan}
            onChange={onInputChange}
          />
        </Spacing>
        <Spacing>
          <ConditionDateTimespan
            id="maxTimeSpan"
            labelText="Maximum time span"
            name="maxTimeSpan"
            value={maxTimeSpan}
            onChange={onInputChange}
          />
        </Spacing>
      </>
    )}
    {dateRangeType === 'SPECIFIC' && (
      <>
        <Spacing>
          <Label additionalClassNames="c-action__item__label h-flex" forId="date-picker-inline-selected-date" labelText="Date" isRequired>
            <Tooltip placement="right" text="The specific date required to meet the condition" />
          </Label>
          <DatePicker
            id="selected-date"
            name="selectedDate"
            value={selectedDate}
            onChange={({ target: { value, name } }) => onInputChange({ value, name })}
          />
        </Spacing>
      </>
    )}
  </>
);

ConditionDateQuestion.propTypes = {
  dateRangeType: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  maxTimeSpan: PropTypes.number,
  minDate: PropTypes.instanceOf(Date),
  minTimeSpan: PropTypes.number,
  onInputChange: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
};

export { ConditionDateQuestion };
