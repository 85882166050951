import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${props => `${props.height}px` || '10px'};
  justify-content: ${props => props.alignment || 'center'};
`;

const HR = styled.hr`
  border: 1px solid #e4e4e3;
  margin: 0;
  width: ${props => `${props.widthPercentage}%` || '100%'};
`;

const SpacerSnippet = props => {
  const { height, lineBreak, widthPercentage, alignment } = props;
  let ali;
  switch (alignment) {
    case 'LEFT':
      ali = 'flex-start';
      break;
    case 'CENTER':
      ali = 'center';
      break;
    case 'RIGHT':
      ali = 'flex-end';
      break;
    default:
      ali = 'center';
      break;
  }
  return (
    <Wrapper alignment={ali} height={height}>
      {lineBreak && <HR widthPercentage={widthPercentage} />}
    </Wrapper>
  );
};

SpacerSnippet.propTypes = {
  alignment: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  lineBreak: PropTypes.bool.isRequired,
  widthPercentage: PropTypes.number.isRequired,
};

export default SpacerSnippet;
