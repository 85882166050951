import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_SUMMARY_SNIPPET } from './UseUpdateSummarySnippet.graphql';

const useUpdateSummarySnippet = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeUpdateSummarySnippet, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? UPDATE_SUMMARY_SNIPPET,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { updateContentSnippet: contentSnippet },
        } = data;

        onCompleted({
          contentSnippet,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateSummarySnippet = ({ variables }) => {
    executeUpdateSummarySnippet({
      variables,
    });
  };

  return {
    handleUpdateSummarySnippet,
    isLoading,
  };
};

export { useUpdateSummarySnippet };
