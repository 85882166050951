import React, { useState, useContext, useRef } from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { useCookie } from '@netfront/common-library';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { SnippetTypeConst } from '../ContentSnippet/SnippetTypeConst';
import SwitchBtn from '../UI/Button/SwitchButton';
import ColorInput from '../UI/ColorPicker/ColorInput';
import Dropdown from '../UI/Dropdown/Dropdown';
import RadioInput from '../UI/Input/RadioButton';
import AnimationPart from '../UI/SidebarComp/AnimationPart';
import EventPart from '../UI/SidebarComp/EventPart';
import MessagePart from '../UI/SidebarComp/MessagePart';
import SidebarTab from '../UI/Tab/SidebarTab';

import RightNavBar from './RightNavBar';
import { Item, Container, Title, ColorItem } from './styled';

import AppContext from '../../context/AppContext';
import client from '../../middleware/client';
import CreateContentEventRequest from '../../middleware/ContentEvent/createContentEventRequest';
import DeleteContentEventRequest from '../../middleware/ContentEvent/deleteContentEventRequest';
import UpdateContentEventRequest from '../../middleware/ContentEvent/updateContentEventRequest';
import { useSnippetDetailQuery, useQuestionDetailQuery } from '../../middleware/ContentSnippet/getContentSnippet';
import UpdataSnippetStyle from '../../middleware/ContentSnippet/updateSnippetStyle';
import UpdataTextSnippetStyle from '../../middleware/ContentSnippet/updateTextSnippetStyle';
import { ATTACH_TO_SNIPPET } from '../../middleware/CssStyle/attachToSnippet';
import { DETACH_STYLE } from '../../middleware/CssStyle/detachStyle';
import { GET_CSS_STYLE_PER_PROJECT } from '../../middleware/CssStyle/getCssPerProject';
import { useUpdateCorrectResponsesMutation } from '../../middleware/Form/updateCorrectResponsesMutations';
import { DEFINE_MESSAGES } from '../../middleware/QuestionConfiguration/DefineMessage';
import { UPDATE_QUESTION_CONFIGURATION_STYLE } from '../../middleware/QuestionConfiguration/UpdateQuestionConfigurationStyle';

const displayStyleOptions = [
  {
    id: 0,
    value: 'DEFAULT',
    label: 'Default',
  },
  {
    id: 1,
    value: 'CARD',
    label: 'Cards',
  },
];

const markableQuestionTypes = ['Radio', 'Checkbox', 'DropDownList'];

const ContentEventSideBar = (props) => {
  const { getAccessTokenCookie } = useCookie();
  const { onRefreshPage, onSnippetDelete } = props;
  const { showRightSidebar, handleShowSidebar } = useContext(AppContext);
  const { projectId } = useParams();

  const [messages, setMessages] = useState({});
  const [eventDetail, setEventDetail] = useState({
    eventTitle: '',
    style: 'NONE',
    event: 'NONE',
    eventDirection: 'TOP',
    assetId: null,
    backgroundColor: null,
    borderColor: null,
    titleColor: null,
    triggerStyle: 'CIRCLE_ARROW',
    type: 'NONE',
    iconHorizontalAlignment: null,
    isDefaultOpen: false,
  });
  const [displayStyle, setDisplayStyle] = useState('DEFAULT');

  const [correctResponseId, setCorrectResponseId] = useState('');
  const savedCorrectResposeId = useRef('');
  const [createRequest, setCreateRequest] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [snippet, setSnippet] = useState({});
  const [updateStyle, setUpdateStyle] = useState(false);
  const [updateTextStyle, setUpdateTextStyle] = useState(false);
  const [customThemes, SetCustomThemes] = useState([]);
  const [selectedCustomTheme, setSelectedCustomTheme] = useState('NONE');

  const context = {
    headers: {
      authorization: getAccessTokenCookie(),
    },
  };

  const [updateConfigurationStyle] = useMutation(UPDATE_QUESTION_CONFIGURATION_STYLE, {
    context,
    client,
  });

  const handleCloseSideBar = () => {
    handleShowSidebar([false, '', null]);
  };
  const correctResponseMutations = useUpdateCorrectResponsesMutation();

  const [detach] = useMutation(DETACH_STYLE, {
    client,
  });

  const [defineMessages] = useMutation(DEFINE_MESSAGES, {
    client,
  });

  const [attachStyle] = useMutation(ATTACH_TO_SNIPPET, {
    client,
  });

  const handleSaveBtn = () => {
    if (snippet.configuration && !isEqual(snippet.configuration.messages, messages)) {
      defineMessages({
        variables: {
          configurationId: snippet.configuration.id,
          correct: messages.correct ?? '',
          incomplete: messages.incomplete ?? '',
          incorrect: messages.incorrect ?? '',
          example: messages.example ?? '',
        },
      });
    }
    if (selectedCustomTheme !== 'NONE') {
      attachStyle({
        variables: {
          snippetId: snippet.id,
          styleId: customThemes.find((c) => c.value === selectedCustomTheme).id,
        },
      });
    } else {
      detach({
        variables: {
          snippetId: snippet.id,
        },
      });
    }

    if (snippet.type === SnippetTypeConst.QUESTION && snippet.configuration) {
      if (snippet.configuration.style && snippet.configuration.style !== displayStyle) {
        updateConfigurationStyle({
          variables: {
            style: displayStyle,
            configurationId: snippet.configuration.id,
          },
        });
      }

      const questionType = snippet.configuration.type;
      const { unset, set } = correctResponseMutations[questionType];
      if (savedCorrectResposeId.current !== correctResponseId) {
        if (savedCorrectResposeId.current) {
          unset({
            variables: {
              questionResponseId: savedCorrectResposeId.current,
              contentGroupId: snippet.containerId,
              contentSnippetId: snippet.id,
            },
          });
        }
        if (correctResponseId) {
          set({
            variables: {
              questionResponseId: correctResponseId ? parseInt(correctResponseId, 10) : null,
              contentGroupId: snippet.containerId,
              contentSnippetId: snippet.id,
            },
          });
        }
      }
    }
    setUpdateStyle(true);
  };

  const handleMessageUpdate = (value, e) => {
    const newMessages = {
      ...messages,
      [e]: value,
    };

    setMessages(newMessages);
  };

  const handleEventUpdate = (value) => {
    setEventDetail(value);
  };

  const handleStyleUpdate = (value, e) => {
    const newInfo = {
      ...snippet,
      [e.target.name]: value,
    };
    setSnippet(newInfo);
  };

  const handleUpdateStyleError = () => {
    setUpdateStyle(false);
    if (snippet.type === 'ContentSnippetTextType') {
      setUpdateTextStyle(true);
    } else {
      setCreateRequest(true);
    }
  };

  const handleUpdateStyleSuccess = () => {
    setUpdateStyle(false);
    if (snippet.type === 'ContentSnippetTextType') {
      setUpdateTextStyle(true);
    } else {
      setCreateRequest(true);
    }
  };

  const handleUpdateTextStyleError = () => {
    setUpdateTextStyle(false);
    setCreateRequest(true);
  };

  const handleUpdateTextStyleSuccess = () => {
    setUpdateTextStyle(false);
    setCreateRequest(true);
  };

  const handleCreateEventError = () => {
    setCreateRequest(false);
  };

  const handleCreateEventSuccess = () => {
    setCreateRequest(false);
    onRefreshPage();
    handleCloseSideBar();
  };

  const handleUpdateEventError = () => {
    setCreateRequest(false);
  };

  const handleUpdateEventSuccess = () => {
    setCreateRequest(false);
    onRefreshPage();
    handleCloseSideBar();
  };

  const handleSnippetDelete = () => {
    handleCloseSideBar();
    onSnippetDelete(snippet);
  };

  const [getQuestionDetails, { called }] = useQuestionDetailQuery({
    onCompleted(data) {
      const questionDetails = data.contentSnippet.getContentSnippet;
      const correctResponseIdDetails = (data.contentGroup.getCorrectResponses[0] || {}).value || '';
      setCorrectResponseId(correctResponseIdDetails);
      savedCorrectResposeId.current = correctResponseIdDetails;

      setDisplayStyle(displayStyleOptions[displayStyleOptions.findIndex((x) => x.value === questionDetails?.configuration?.style)]?.value);

      setMessages(questionDetails.configuration.messages);
      setSnippet((currentSnippet) => ({
        ...currentSnippet,
        ...questionDetails,
      }));
    },
  });

  useQuery(GET_CSS_STYLE_PER_PROJECT, {
    client,
    skip: !snippet,
    fetchPolicy: 'no-cache',
    variables: {
      projectId,
    },
    onCompleted: (data) => {
      const defaultThemeOption = [
        {
          id: 0,
          value: 'NONE',
          label: 'Please select a custom theme',
        },
      ];

      const projectThemeOptions = data.cssStyle.getPerProject.map((style) => ({
        id: style.id,
        value: style.name,
        label: style.name,
      }));

      const themes = [...defaultThemeOption, ...projectThemeOptions];

      if (snippet.cssStyle) {
        const theme = themes.find((c) => c.id === snippet.cssStyle.id);
        setSelectedCustomTheme(theme.value);
      }
      SetCustomThemes(themes);
    },
  });

  function handleGetSnippetSuccess({
    contentSnippet: {
      getContentSnippet: snippetData,
      getContentSnippet: { contentEvent: eventData },
    },
  }) {
    setSnippet(snippetData);
    const eventDetailData = eventData
      ? {
          ...eventData,
          title: eventData.eventTitle,
          animationType: eventData.animation || 'NONE',
          type: eventData.event || 1000,
          delayTime: eventData.millisecondDelay || 1000,
          animationSpeed: eventData.millisecondSpeed || false,
          isDefaultOpen: eventData.isDefaultOpen || false,
        }
      : {
          animationType: 'NONE',
          delayTime: 1000,
          animationSpeed: 1000,
          infinite: false,
          eventTitle: '',
          style: 'NONE',
          event: 'NONE',
          eventDirection: 'TOP',
          assetId: null,
          backgroundColor: null,
          borderColor: null,
          titleColor: null,
          type: 'NONE',
          isDefaultOpen: false,
        };

    setEventDetail(eventDetailData);
  }

  if (snippet.type === SnippetTypeConst.QUESTION && !called) {
    getQuestionDetails({
      variables: {
        contentSnippetId: snippet.id,
        contentGroupId: snippet.containerId,
      },
    });
  }

  const handleSelectedThemeChanged = (id) => {
    const theme = customThemes.find((c) => c.id === Number(id));
    setSelectedCustomTheme(theme.value);
  };

  const handleDisplayStyleUpdate = (value) => {
    setDisplayStyle(value);
  };

  const handleDeleteEventError = () => {
    setDeleteRequest(true);
  };

  const handleDeleteEventSuccess = () => {
    setDeleteRequest(true);
  };

  const handleUpdateSnippetVisibility = () => {
    setSnippet({
      ...snippet,
      visible: !snippet.visible,
    });
  };

  useSnippetDetailQuery({
    contentSnippetId: showRightSidebar[2],
    onCompleted: handleGetSnippetSuccess,
  });

  const eventTab = <EventPart currentDetail={eventDetail} projectId={projectId} updateEventDetail={handleEventUpdate} />;

  const animationTab = <AnimationPart currentDetail={eventDetail} updateEventDetail={handleEventUpdate} />;

  const styleTab = (
    <Container>
      {snippet && customThemes.length > 0 && (
        <>
          <Item>
            <Title>Custom theme</Title>
            <Dropdown
              availableResponses={customThemes}
              name="customTheme"
              selectedValue={selectedCustomTheme}
              onChangeHandler={handleSelectedThemeChanged}
            />
          </Item>
          <Item>
            <Title>Visible</Title>
            <SwitchBtn id={1} name="infinite" value={snippet.visible} onChange={handleUpdateSnippetVisibility} />
          </Item>
        </>
      )}
      <ColorItem>
        <Title>Border colour</Title>
        <ColorInput currentColor={snippet.borderColor} getColor={handleStyleUpdate} id="color-1" name="borderColor" />
      </ColorItem>
      <ColorItem>
        <Title>Background colour</Title>
        <ColorInput currentColor={snippet.backgroundColor} getColor={handleStyleUpdate} id="color-2" name="backgroundColor" />
      </ColorItem>
      {snippet.configuration && (snippet.configuration.type === 'Radio' || snippet.configuration.type === 'Checkbox') && (
        <ColorItem>
          <Title>Display style</Title>
          <Dropdown
            availableResponses={displayStyleOptions}
            name="displayStyle"
            selectedValue={displayStyle}
            onChangeHandler={(_, value) => handleDisplayStyleUpdate(value)}
          />
        </ColorItem>
      )}
      {snippet.type === SnippetTypeConst.TEXT && (
        <div>
          <ColorItem>
            <Title>Link colour</Title>
            <ColorInput currentColor={snippet.linkColor} getColor={handleStyleUpdate} id="color-4" name="linkColor" />
          </ColorItem>
          <ColorItem>
            <Title>List icon colour</Title>
            <ColorInput currentColor={snippet.listIconColor} getColor={handleStyleUpdate} id="color-5" name="listIconColor" />
          </ColorItem>
        </div>
      )}
    </Container>
  );

  const tabItems = [
    {
      title: 'Event',
      content: eventTab,
    },
    {
      title: 'Animation',
      content: animationTab,
    },
    {
      title: 'Style',
      content: styleTab,
    },
  ];
  if (snippet.type === SnippetTypeConst.QUESTION && snippet.configuration) {
    tabItems.push({
      title: 'Messages',
      content: <MessagePart handleUpdate={handleMessageUpdate} messages={snippet.configuration.messages} />,
    });

    if (markableQuestionTypes.includes(snippet.configuration.type)) {
      const availableResponses = snippet.configuration.responseSet.availableResponses.map((response) => ({
        id: response.id,
        label: `${response.label} (value: ${response.value})`,
      }));

      availableResponses.unshift({
        id: '',
        label: 'No selection',
      });
      tabItems.push({
        title: 'Correct answer',
        content: (
          <Container>
            {availableResponses.map((response) => (
              <RadioInput
                htmlFor={`${snippet.id}_${response.id}`}
                isChanged={() => setCorrectResponseId(String(response.id))}
                isChecked={correctResponseId === response.id}
                name={snippet.id}
              >
                {response.label}
              </RadioInput>
            ))}
          </Container>
        ),
      });
    }
  }
  const isEdit = Boolean(showRightSidebar[2]);

  return (
    <>
      <RightNavBar
        hasDelete={isEdit}
        hasConfirmButton
        onCancel={handleCloseSideBar}
        onDelete={() => {
          setDeleteRequest(true);
          handleSnippetDelete();
        }}
        onSave={handleSaveBtn}
      >
        <div>
          <h3>Snippet settings</h3>
          <SidebarTab items={tabItems} />
        </div>
      </RightNavBar>
      {updateStyle && (
        <UpdataSnippetStyle
          backgroundColor={snippet.backgroundColor || null}
          borderColor={snippet.borderColor || null}
          containerId={snippet.containerId}
          id={snippet.id}
          sort={snippet.sort}
          visible={snippet.visible}
          onError={handleUpdateStyleError}
          onSuccessResult={handleUpdateStyleSuccess}
        />
      )}
      {updateTextStyle && snippet.type === 'ContentSnippetTextType' && (
        <UpdataTextSnippetStyle
          fontColor={snippet.fontColorSnippetText || null}
          linkColor={snippet.linkColor || null}
          listIconColor={snippet.listIconColor || null}
          snippetId={snippet.id}
          text={snippet.text}
          onError={handleUpdateTextStyleError}
          onSuccessResult={handleUpdateTextStyleSuccess}
        />
      )}
      {createRequest && !eventDetail.id && (
        <CreateContentEventRequest
          animation={eventDetail.animationType}
          animationDirection={eventDetail.animationDirection || null}
          assetId={eventDetail.assetId}
          backgroundColor={eventDetail.backgroundColor}
          borderColor={eventDetail.borderColor}
          contentEntity={showRightSidebar[1]}
          entityId={showRightSidebar[2]}
          event={eventDetail.type}
          eventDirection={eventDetail.eventDirection || 'TOP'}
          eventTitle={eventDetail.title}
          iconHorizontalAlignment={eventDetail.iconHorizontalAlignment}
          infinite={eventDetail.infinite}
          isDefaultOpen={eventDetail.isDefaultOpen}
          millisecondDelay={eventDetail.delayTime}
          millisecondSpeed={eventDetail.animationSpeed}
          style={eventDetail.style}
          titleColor={eventDetail.titleColor}
          triggerStyle={eventDetail.triggerStyle}
          onError={handleCreateEventError}
          onSuccessResult={handleCreateEventSuccess}
        />
      )}
      {createRequest && eventDetail.id && (
        <UpdateContentEventRequest
          animation={eventDetail.animationType}
          animationDirection={eventDetail.animationDirection}
          assetId={eventDetail.assetId}
          backgroundColor={eventDetail.backgroundColor}
          borderColor={eventDetail.borderColor}
          contentEventId={eventDetail.id}
          event={eventDetail.type}
          eventDirection={eventDetail.eventDirection || 'TOP'}
          eventTitle={eventDetail.title}
          iconHorizontalAlignment={eventDetail.iconHorizontalAlignment}
          infinite={eventDetail.infinite}
          isDefaultOpen={eventDetail.isDefaultOpen}
          millisecondDelay={eventDetail.delayTime}
          millisecondSpeed={eventDetail.animationSpeed}
          style={eventDetail.style}
          titleColor={eventDetail.titleColor}
          triggerStyle={eventDetail.triggerStyle}
          onError={handleUpdateEventError}
          onSuccessResult={handleUpdateEventSuccess}
        />
      )}

      {deleteRequest && eventDetail.id && (
        <DeleteContentEventRequest
          contentEventId={eventDetail.id}
          onError={handleDeleteEventError}
          onSuccessResult={handleDeleteEventSuccess}
        />
      )}
    </>
  );
};

ContentEventSideBar.propTypes = {
  onRefreshPage: PropTypes.func.isRequired,
  onSnippetDelete: PropTypes.func.isRequired,
};

export default ContentEventSideBar;
