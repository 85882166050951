import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_CONDITIONS = gql`
  query getAllConditions($contentGroupId: Int!) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        actions {
          conditions {
            ... on QuestionConditionNumberSelectedType {
              contentSnippetQuestionId
              numberSelected
            }
            ... on QuestionConditionTextType {
              contentSnippetQuestionId
              text
            }
            ... on QuestionConditionDateSelectedType {
              contentSnippetQuestionId
              dateRangeType: type
              minDate
              maxDate
              minTimeSpan
              maxTimeSpan
              selectedDate
            }
            ... on QuestionConditionRadioResponseSelectedType {
              contentSnippetQuestionId
              questionResponseRadioId
              selection
              selectedRadioResponse {
                id
              }
            }
          }
          ... on QuestionActionHideType {
            targetSnippetId
          }
          ... on QuestionActionShowType {
            targetSnippetId
          }
          ... on QuestionActionSkipToPageType {
            contentPageId
          }
          ... on QuestionActionSkipToSnippetType {
            targetSnippetId
          }
        }
      }
    }
  }
`;

const getAllConditionsOnContentGroup = ({ contentGroupId, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_ALL_CONDITIONS} variables={{ contentGroupId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.contentGroup.getContentGroup.actions}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAllConditionsOnContentGroup.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAllConditionsOnContentGroup;
