import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const SORT_CONTENT_PAGE = gql`
  mutation SortContentPage($contentPageId: Int!, $parentId: Int, $sort: Int!) {
    contentPage {
      orderContentPage(
        contentPage: {
          contentPageId: $contentPageId
          parentId: $parentId
          sort: $sort
        }
      ) {
        id
      }
    }
  }
`;

const sortContentPage = ({
  contentPageId,
  parentId,
  sort,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={SORT_CONTENT_PAGE}>
    {(updateContentPage, { loading, error, data }) => {
      const variables = {
        contentPageId,
        parentId,
        sort,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentPage.orderContentPage.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentPage}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

sortContentPage.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
  parentId: PropTypes.number,
};
sortContentPage.defaultProps = {
  parentId: null,
};

export default sortContentPage;
