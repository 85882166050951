import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import CreateImageMapFormContentSnippetRequest from '../../middleware/ContentSnippet/createImageMapContentSnippetRequest';
import UpdateImageMapContentSnippetRequest from '../../middleware/ContentSnippet/updateImageMapContentSnippet';
import SearchContentGroupRequest from '../../middleware/FormContainer/searchContentGroupRequest';

// **************styled Components********************
const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  width: 100%;
`;

const Search = styled.input`
  background-color: unset;
  border: none;
  height: 3em;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em -0.5em 0 0;
`;

const SearchSuggetionsRow = styled.button`
  background: none;
  border: 1px solid #e1e1e1;
  padding: 5px;
  text-align: left;
`;

const SearchSuggetionsBox = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 108px;
  overflow-y: scroll;
`;

// **************styled Components********************

const ImageMapSnippet = props => {
  const { projectId, containerId, onCancel, onRefreshPage, snippet } = props;

  const [loaded, setLoaded] = useState(false);
  const [allForms, setAllForms] = useState([]);
  const [title, setTitle] = useState(snippet ? snippet.form.title : '');
  const [searchSuggetions, setSearchSuggetions] = useState([]);
  const [contentSnippet, setContentSnippet] = useState(
    snippet
      ? {
          ...snippet,
          id: snippet.form.id,
          recommendedFormType: snippet.form.recommendedFormType,
        }
      : null
  );
  const [createSnippetRequest, setCreateSnippetRequest] = useState(false);
  const onSearchContentGroup = e => {
    const { value } = e.target;
    setTitle(value);
    const filtered = allForms.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setSearchSuggetions(filtered);
  };

  const onSearchContentGroupError = () => {
    setLoaded(true);
  };

  const onSearchContentGroupSuccessResult = data => {
    setLoaded(true);
    setAllForms(data);
  };

  const onSuggetionClick = item => {
    setTitle(item.title);
    setContentSnippet(item);
    setSearchSuggetions([]);
  };

  const onErrorCreateSnippet = () => {
    setCreateSnippetRequest(false);
  };

  const onSuccessCreateSnippet = () => {
    setCreateSnippetRequest(false);
    onRefreshPage();
  };

  const handleOnSaveCreate = () => {
    setCreateSnippetRequest(true);
  };

  return (
    <Wrapper>
      <Content>
        <Search
          onChange={onSearchContentGroup}
          placeholder="Begin typing to find your Interactive Regions"
          value={title}
        />
        <SearchSuggetionsBox>
          {searchSuggetions.map(item => (
            <SearchSuggetionsRow
              key={item.id}
              onClick={() => onSuggetionClick(item)}
            >
              {item.title}
            </SearchSuggetionsRow>
          ))}
        </SearchSuggetionsBox>
      </Content>
      <ButtonGroup>
        <button
          className="button--black mt-4 mr-3"
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="button--blue mt-4"
          onClick={handleOnSaveCreate}
          type="submit"
        >
          Save
        </button>
      </ButtonGroup>
      {!loaded && (
        <SearchContentGroupRequest
          contentGroupType="IMAGE_MAP"
          onError={onSearchContentGroupError}
          onSuccessResult={onSearchContentGroupSuccessResult}
          projectId={projectId}
        />
      )}
      {createSnippetRequest && !snippet && (
        <CreateImageMapFormContentSnippetRequest
          containerId={containerId}
          contentSnippetId={contentSnippet.id}
          isVisible
          onError={onErrorCreateSnippet}
          onSuccessResult={onSuccessCreateSnippet}
        />
      )}
      {createSnippetRequest && snippet && Object.keys(snippet).length > 0 && (
        <UpdateImageMapContentSnippetRequest
          contentSnippetId={contentSnippet.id}
          id={snippet.id}
          onError={onErrorCreateSnippet}
          onSuccessResult={onSuccessCreateSnippet}
        />
      )}
    </Wrapper>
  );
};

ImageMapSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  snippet: PropTypes.shape({
    form: PropTypes.shape({
      id: PropTypes.number,
      recommendedFormType: PropTypes.string,
      title: PropTypes.string,
    }),
    formType: PropTypes.string,
    id: PropTypes.number,
    sliderId: PropTypes.number,
    sort: PropTypes.number,
  }),
};

ImageMapSnippet.defaultProps = {
  snippet: null,
};

export default ImageMapSnippet;
