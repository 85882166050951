import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const SAVE_DATA = gql`
  mutation saveData(
    $appApiKey: String
    $appComponentId: Int
    $content: String
  ) {
    appUserData {
      saveUserData(
        appApiKey: $appApiKey
        appComponentId: $appComponentId
        content: $content
      )
    }
  }
`;

const saveUserData = ({
  appApiKey,
  appComponentId,
  content,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={SAVE_DATA}>
    {(createAsset, { loading, error, data }) => {
      const variables = {
        appApiKey,
        appComponentId,
        content,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.appUserData.saveUserData);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAsset}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

saveUserData.propTypes = {
  appApiKey: PropTypes.string.isRequired,
  appComponentId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  content: PropTypes.string,
};

saveUserData.defaultProps = {
  content: '',
};

export default saveUserData;
