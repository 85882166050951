import React, { useState, useEffect, useContext } from 'react';

import { Spacing, Input, NavigationButton, Select, InformationBox } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AdditionalActionParametersSelector } from '../AdditionalActionParametersSelector';
import { ConditionsOverview } from '../ConditionsOverview';
import { ConditionTemplateSelector } from '../ConditionTemplateSelector';
import { UpsertCondition } from '../UpsertCondition';

import ButtonGroup from '../../../../../../legacy/components/ButtonGroup/ButtonGroup';
import { ModalButtonGroup } from '../../../../../../legacy/components/ButtonGroup/styled';
import Preloader from '../../../../../../legacy/components/Preloader/Preloader';
import SquareButton from '../../../../../../legacy/components/UI/Button/SquareButton';
import NormalModal from '../../../../../../legacy/components/UI/Modal/NormalModal';
import AppContext from '../../../../../../legacy/context/AppContext';
import { useToast } from '../../../../../../legacy/hooks';
import {
  useGetActionDetails,
  useCreateAction,
  useDeleteCondition,
  useUpdateAction,
  useGetSnippetEventDetails,
  useGetConditionTemplates,
} from '../../../../../hooks';
import { generateRandomId, sortObjectArrayAlphabetically } from '../../../../../utils';
import { useUpsertConditions } from '../../Hooks';
import { QUESTION_CONDITION_TYPE_MAP, ACTION_DEFAULT_TYPE } from '../../UpsertActionsTab.constants';
import { getActionOptions, getActionTypeName } from '../../UpsertActionsTab.helpers';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const UpsertAction = ({ onUpdate, onCancel, onDelete, activePageId, selectedActionId }) => {
  const { projectId } = useParams();
  const { actionsSidebarDetails } = useContext(AppContext);
  const { handleToastError, handleToastSuccess } = useToast();
  const { type, id, config } = actionsSidebarDetails;

  const [title, setTitle] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUpsertMode, setIsUpsertMode] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [selectedActionType, setSelectedActionType] = useState();
  const [selectedCondition, setSelectedCondition] = useState();
  const [deletedConditionIds, setDeletedConditionIds] = useState([]);
  const [additionalParameters, setAdditionalParameters] = useState({});
  const [actionDetails, setActionDetails] = useState();
  const [hasContentEvent, setHasContentEvent] = useState(false);
  const [contentEventId, setContentEventId] = useState();
  const [conditionTemplates, setConditionTemplates] = useState([]);
  const [deleteConditionId, setDeleteConditionId] = useState();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedParamsId, setSelectedParamsId] = useState();

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetActionDetailsOnCompleted = ({ actionDetails: returnedActionDetails }) => {
    const {
      title: actionTitle = '',
      conditions: actionConditions = [],
      __typename: returnedActionType,
      contentEventId: returnedContentEventId,
    } = returnedActionDetails ?? {};
    setSelectedActionType(returnedActionType);
    setActionDetails(returnedActionDetails);
    setConditions(
      actionConditions.map((condition) => ({
        tempId: `${generateRandomId()}-${condition.id}`,
        type: QUESTION_CONDITION_TYPE_MAP[condition.__typename],
        ...condition,
      })),
    );
    setTitle(actionTitle);
    if (returnedContentEventId) {
      setHasContentEvent(true);
      setContentEventId(returnedContentEventId);
    }
  };

  const { handleGetActionDetails, isLoading: isGetActionDetailsLoading = false } = useGetActionDetails({
    onCompleted: handleGetActionDetailsOnCompleted,
    onError: handleGetError,
  });

  const handleGetSnippetEventDetailsOnCompleted = ({ snippet }) => {
    const { contentEvent } = snippet;

    if (!contentEvent) return;

    const { event, id: eventId } = contentEvent ?? {};
    if (['REVEAL', 'POP_UP'].includes(event)) {
      setHasContentEvent(Boolean(event));
      setContentEventId(eventId);
    }
  };

  // get event details for the snippet to allow us to determine whether relevant actions are available for selection
  const { handleGetSnippetEventDetails, isLoading: isGetSnippetEventDetailsLoading = false } = useGetSnippetEventDetails({
    onCompleted: handleGetSnippetEventDetailsOnCompleted,
    onError: handleGetError,
  });

  const handleGetConditionTemplatesOnCompleted = ({ conditions: returnedTemplates }) => {
    setConditionTemplates(sortObjectArrayAlphabetically(returnedTemplates, 'title'));
  };

  // get condition templates to be able to quick add a condition to an action
  const { handleGetConditionTemplates, isLoading: isGetConditionTemplatesLoading = false } = useGetConditionTemplates({
    onCompleted: handleGetConditionTemplatesOnCompleted,
    onError: handleGetError,
  });

  // TODO: @ash remove this when conditions logic is handled by createAction / updateAction
  const { handleUpsertConditions, isLoading: isUpsertConditionsLoading = false } = useUpsertConditions({
    onUpdate,
  });

  const handleUpsertActionOnCompleted = ({ action }) => {
    const { id: actionId } = action;
    // TODO: @ash this should be handled on create / update of actions
    // TODO: @ash remove this when conditions logic is handled by createAction / updateAction
    handleUpsertConditions({
      conditions,
      actionId,
    });

    handleToastSuccess({ message: 'Action successfully created.' });
  };

  const { handleCreateAction, isLoading: isCreateActionLoading = false } = useCreateAction({
    type: selectedActionType ?? ACTION_DEFAULT_TYPE[type],
    onCompleted: handleUpsertActionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateAction, isLoading: isUpdateActionLoading = false } = useUpdateAction({
    onCompleted: handleUpsertActionOnCompleted,
    onError: handleGetError,
  });

  // TODO: remove this once delete conditions is handled by updateAction
  const { handleDeleteCondition: executeDeleteCondition, isLoading: isDeleteConditionLoading = false } = useDeleteCondition({
    onError: handleGetError,
  });

  const handleSaveAction = () => {
    if (!selectedActionId) {
      handleCreateAction({
        variables: {
          contentPageId: activePageId,
          // send the contentEventId if we want to use the open / close actions
          wrapperId: ['QuestionActionTriggerContentEventType'].includes(selectedActionType) ? contentEventId : id,
          title,
          conditions,
          ...additionalParameters,
        },
      });
    } else {
      handleUpdateAction({
        variables: {
          contentPageId: activePageId,
          actionId: selectedActionId,
          title,
          conditions,
          deletedConditionIds,
          ...additionalParameters,
        },
      });
    }
  };

  const handleUpsertCondition = (conditionId) => {
    setSelectedCondition(conditions.find(({ tempId }) => tempId === conditionId));
    setIsUpsertMode(true);
  };

  const handleCloseUpsertCondition = () => {
    setSelectedCondition(undefined);
    setIsUpsertMode(false);
  };

  const handleAddTemplate = (value) => {
    const template = conditionTemplates.find(({ id: templateId }) => templateId === Number(value));

    // check if the condition has already been added to the list
    const isAlreadyAdded = conditions.some(({ conditionTemplateId: existingId }) => template.id === existingId);

    if (!isAlreadyAdded) {
      const newCondition = {
        ...template,
        tempId: `${generateRandomId()}`,
        type: QUESTION_CONDITION_TYPE_MAP[template.__typename],
        isTemplate: false,
        conditionTemplateId: template.id,
      };

      delete newCondition.id;

      setConditions([...conditions, newCondition]);
    }
  };

  const handleCancelDeleteCondition = () => {
    setIsDeleteDialogOpen(false);
    setDeleteConditionId(undefined);
  };

  const onUpsertCondition = (returnedCondition) => {
    const isExistingCondition = conditions.some((condition) => condition.tempId === returnedCondition.tempId);
    let updatedConditions = [...conditions];
    if (isExistingCondition) {
      // if the condition is already part of the list, then update
      updatedConditions = updatedConditions.map((condition) => {
        if (condition.tempId === returnedCondition.tempId) return returnedCondition;
        return condition;
      });
    } else {
      // add condition to list
      updatedConditions.push(returnedCondition);
    }
    setConditions(updatedConditions);
    handleCloseUpsertCondition();
  };

  const handleDeleteCondition = (conditionTempId) => {
    const condition = conditions.find(({ tempId }) => tempId === conditionTempId);

    if (condition) {
      const { id: questionConditionId } = condition ?? {};
      // The condition exists in the database, so we need to remove it
      if (questionConditionId) {
        setDeletedConditionIds([...deletedConditionIds, questionConditionId]);
      }

      // TODO: @ash temporarily delete, this will be handled in the updateAction request,
      // remove once this functionality is available
      if (questionConditionId) {
        executeDeleteCondition({
          variables: {
            questionConditionId,
          },
        });
      }
      setConditions(conditions.filter(({ tempId }) => tempId !== conditionTempId));
      handleCloseUpsertCondition();
      setIsDeleteDialogOpen(false);
      setDeleteConditionId(undefined);
    }
  };

  const handleAdditionalParameters = ({ params, value }) => {
    setAdditionalParameters(params);
    setSelectedParamsId(value);
  };

  // fetch details if it is an existing action
  useEffect(() => {
    if (!selectedActionId) return;

    setIsSubmitDisabled(false);

    handleGetActionDetails({
      variables: {
        actionId: selectedActionId,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionId]);

  // Determine whether the wrapper has an event attached to it.
  useEffect(() => {
    if (selectedActionId) return;
    if (!config) return;

    if (type === 'targetSnippet') {
      handleGetSnippetEventDetails({
        variables: {
          contentSnippetId: id,
        },
      });
    } else if (type === 'container') {
      const { contentEventId: eventId, contentEvent } = config;
      const { event } = contentEvent ?? {};
      setHasContentEvent(Boolean(['REVEAL', 'POP_UP'].includes(event)));
      setContentEventId(eventId);
    } else if (type === 'section') {
      const { event } = config;
      const { event: eventType, id: eventId } = event ?? {};
      setHasContentEvent(Boolean(['REVEAL', 'POP_UP'].includes(eventType)));
      setContentEventId(eventId);
    } else {
      setHasContentEvent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, config, selectedActionId]);

  useEffect(() => {
    if (!projectId) return;

    handleGetConditionTemplates({
      variables: {
        projectId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const isLoading =
    isGetActionDetailsLoading ||
    isCreateActionLoading ||
    isDeleteConditionLoading ||
    isUpsertConditionsLoading ||
    isUpdateActionLoading ||
    isGetSnippetEventDetailsLoading ||
    isGetConditionTemplatesLoading;

  return (
    <>
      {isLoading && <Preloader />}
      {isUpsertMode ? (
        <UpsertCondition
          selectedCondition={selectedCondition}
          onCancel={handleCloseUpsertCondition}
          onDelete={selectedCondition ? () => handleDeleteCondition(selectedCondition.tempId) : undefined}
          onUpdate={onUpsertCondition}
        />
      ) : (
        <Container>
          <Spacing>
            <NavigationButton
              additionalClassNames="c-sidebar-navigation-button"
              direction="back"
              iconId="id_enter_icon"
              rotationCssSuffix="180"
              text=" Back to actions"
              onClick={onCancel}
            />
          </Spacing>
          <Spacing>
            <InformationBox message="Name this action for future reference and configure the required conditions to execute the action" />
          </Spacing>

          <Spacing>
            <Input
              labelText="Title"
              name="action_title"
              value={title}
              isRequired
              onChange={({ target: { value } }) => {
                setTitle(value);
                setIsSubmitDisabled(value === '' && selectedActionType === '');
              }}
            />
          </Spacing>
          {selectedActionId ? (
            <Spacing size="2x-large">
              <Input
                labelText="Action type"
                name="action_type"
                value={getActionTypeName(type, selectedActionType)}
                isDisabled
                onChange={() => {}}
              />
            </Spacing>
          ) : (
            <Spacing size="2x-large">
              <Select
                labelText="Action type"
                name="action_type_dropdown"
                options={getActionOptions(type, hasContentEvent)
                  .filter(({ isHidden = false }) => !isHidden)
                  .map(({ id: optionId, value, name }) => ({
                    id: optionId,
                    value,
                    name,
                  }))}
                value={selectedActionType}
                isRequired
                onChange={({ target: { value } }) => setSelectedActionType(value)}
              />
            </Spacing>
          )}
          {['QuestionActionTriggerContentEventType'].includes(selectedActionType) && (
            // Additional parameters are required for certain actions, here we can define the required parameters
            <AdditionalActionParametersSelector
              actionDetails={actionDetails}
              selectedActionType={selectedActionType}
              selectedParamsId={selectedParamsId}
              onSelectParameters={handleAdditionalParameters}
            />
          )}

          {conditionTemplates.length > 0 && (
            <ConditionTemplateSelector templateOptions={conditionTemplates} onSelectTemplate={handleAddTemplate} />
          )}

          <Spacing size="6x-large">
            <ConditionsOverview
              conditionItems={conditions}
              hasDelete
              onDelete={onDelete ? () => onDelete(config) : undefined}
              onDeleteClick={(tempId) => {
                setIsDeleteDialogOpen(true);
                setDeleteConditionId(tempId);
              }}
              onEditClick={handleUpsertCondition}
            />
          </Spacing>
          {/* Switch ButtonGroup for TabsetButtons when switching to V3 */}
          <ButtonGroup
            confirmationMessage={`Delete ${title} action?`}
            isConfirmButtonDisabled={isSubmitDisabled}
            hasConfirmButton
            onCancel={onCancel}
            onDelete={selectedActionId ? () => onDelete(selectedActionId) : undefined}
            onSave={handleSaveAction}
          />
          <NormalModal isOpen={isDeleteDialogOpen} onClose={handleCancelDeleteCondition}>
            <p>Delete condition?</p>
            <ModalButtonGroup>
              <SquareButton isClicked={handleCancelDeleteCondition}>No, cancel</SquareButton>
              <SquareButton bgColor="#dc3545" color="white" isClicked={() => handleDeleteCondition(deleteConditionId)}>
                Yes, delete
              </SquareButton>
            </ModalButtonGroup>
          </NormalModal>
        </Container>
      )}
    </>
  );
};

UpsertAction.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  activePageId: PropTypes.number,
  selectedActionId: PropTypes.number,
};

export { UpsertAction };
