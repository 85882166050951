import React from 'react';

import PropTypes from 'prop-types';

export default function SideBarRight(props) {
  const { open, children } = props;

  return (
    <div
      className="c-sidebar-right"
      style={{ display: open ? 'flex' : 'none' }}
    >
      {children}
    </div>
  );
}

SideBarRight.propTypes = {
  /* eslint react/forbid-prop-types: 0 */
  children: PropTypes.any,
  // eslint-disable-next-line react/boolean-prop-naming
  open: PropTypes.bool,
};

SideBarRight.defaultProps = {
  children: undefined,
  open: undefined,
};
