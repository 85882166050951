import React from 'react';

import { groupTableHeadings } from './GroupTable.constants';
import { createGroupTableData } from './GroupTable.helpers';

import { EKReactTable } from '../../../../components/Table/EKReactTable/EKReactTable';

const GroupTable = ({ data, onEdit }) => <EKReactTable columns={groupTableHeadings} data={createGroupTableData(data, onEdit)} />;

export default GroupTable;
