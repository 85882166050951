import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_DROPDOWN_LIST_QUE_CONFIGURATION = gql`
  mutation createDropDownQuesConfigRequest(
    $projectId: String!
    $description: String!
    $title: String!
    $canModify: Boolean!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationDropDownList: {
          canModify: $canModify
          questionResponseSet: { title: $title }
        }
      ) {
        id
        ... on DropDownList {
          id
          responseSet {
            id
            availableResponses {
              id
              label
              questionResponseSetId
              value
            }
          }
        }
      }
    }
  }
`;

const createDropDownQuesConfigRequest = ({
  projectId,
  description,
  canModify,
  title,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_DROPDOWN_LIST_QUE_CONFIGURATION}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        projectId,
        description,
        canModify,
        title,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.questionConfiguration.createQuestionConfiguration.id,
          data
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createDropDownQuesConfigRequest.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  canModify: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

createDropDownQuesConfigRequest.defaultProps = {};

export default createDropDownQuesConfigRequest;
