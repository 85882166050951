import React, { useEffect, useState } from 'react';

import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { useDebouncedCallback } from 'use-debounce';

import { DEFAULT_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_API_ERROR_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerRangeInputSnippetProps } from './QuestionAnswerRangeInputSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerMutationGraphQLResponse,
  ISetUserAnswerSliderMutationVariables,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import { IContentPage, IQuestionAnswerSlider, IQuestionConfigurationSlider } from '../../../../interfaces';
import { RangeInputSnippet } from '../../RangeInputSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerRangeInputSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  message = DEFAULT_MESSAGE,
  userFlowStepTrackId,
}: QuestionAnswerRangeInputSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, message: contentSnippetMessage } = contentSnippet ?? {};
  const { value: sliderValue } = answer ?? ({} as IQuestionAnswerSlider);
  const { defaultValue, maxValue, minValue, step, ticks } = configuration ?? ({} as IQuestionConfigurationSlider);

  const [currentMessage, setCurrentMessage] = useState<IMessage>(message);
  const [savedSliderValue, setSavedSliderValue] = useState<number | undefined>(sliderValue);

  const [executeSetUserAnswerSlider] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerSliderMutationVariables>({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerSlider,
    options: {
      onCompleted: () => {
        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            value: savedSliderValue,
          },
        });

        setCurrentMessage({
          error: '',
          success: DEFAULT_API_SUCCESS_MESSAGE,
        });
      },
      onError: (error) => {
        handleApolloError(error);

        setCurrentMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const clearMessage = () => {
    setCurrentMessage(DEFAULT_MESSAGE);
  };

  const debouncedMutation = useDebouncedCallback((inputValue: number) => {
    if (!contentSnippetFormId) {
      return;
    }

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    const variables: ISetUserAnswerSliderMutationVariables = {
      ...baseVariables,
      value: inputValue,
    };

    executeSetUserAnswerSlider({
      variables,
    });
  }, API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS);

  const handleSliderChange = (inputValues: number[]) => {
    clearMessage();
    debouncedMutation(inputValues[0]);
    setSavedSliderValue(inputValues[0]);
  };

  useEffect(() => {
    if (!contentSnippetMessage) {
      return;
    }

    setCurrentMessage(contentSnippetMessage);
  }, [contentSnippetMessage]);

  return (
    <RangeInputSnippet
      id={String(contentSnippetId)}
      maxValue={maxValue}
      message={currentMessage}
      minValue={minValue}
      selectedValues={[savedSliderValue ?? defaultValue]}
      step={step}
      ticks={ticks}
      onChange={handleSliderChange}
    />
  );
};

export { QuestionAnswerRangeInputSnippet };
