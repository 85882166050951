import React, { useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PopupMessage from '../PopupMessage/PopupMessage';

import { SnippetTypeConst } from './SnippetTypeConst';

import CreateAssetRequest from '../../middleware/Asset/createAssetRequest';
import DeleteAssetRequest from '../../middleware/Asset/deleteAssetRequest';
import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateCodeContentSnippetRequest from '../../middleware/ContentSnippet/createCodeContentSnippetRequest';
import UpdateCodeContentSnippetRequest from '../../middleware/ContentSnippet/updateCodeContentSnippetRequest';
import { validURL } from '../../utils/utils';

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5em;
  width: 100%;
`;

const ContentArea = styled.textarea`
  width: 100%;
  height: 10em;
  background-color: #fff;
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  outline: none;
  overflow-y: scroll;
`;

const EditableCodeSnippet = (props) => {
  const { containerId, onCancel, onCreate, onUpdate, snippet } = props;
  const [sendRequest, setSendRequest] = useState(false);
  const [content, setContent] = useState('');
  const [createAssetHtml, setCreateAssetHtml] = useState(false);
  const [htmlFile, setHtmlFile] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [htmlAssetId, setHtmlAssetId] = useState(snippet ? snippet.locationId : null);
  const [deleteAssetRequest, setDeleteAssetRequest] = useState(false);
  const [getAssetSent, setGetAssetSent] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [hasError, setHasError] = useState(false);
  const [userFeedback, setUserFeedback] = useState('');

  const handleErrorFeedback = (isError, message) => {
    setHasError(isError);
    setUserFeedback(message);
  };

  const handleChange = (e) => {
    setContent(e.target.value);
    setHtmlContent(e.target.value);

    if (hasError) {
      handleErrorFeedback(false, '');
    }
  };

  const handleAssetResponse = (s3Key, contentType) => {
    const tmpURL = getSignedUrl(s3Key, contentType);
    setGetAssetSent(true);
    axios
      .get(tmpURL)
      .then((res) => {
        setHtmlContent(res.data);
      })
      .catch(() => {});
  };

  const handleHtmlAssetResponse = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, asset },
      },
    } = data;

    setCreateAssetHtml(false);
    Object.defineProperty(htmlFile, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    axios
      .put(signedUrl, htmlFile, { headers: { 'content-type': htmlFile.type } })
      .then(() => {
        if (htmlAssetId) {
          setAssetId(htmlAssetId);
          setDeleteAssetRequest(true);
        }
        setHtmlAssetId(asset.assetId);
      })
      .catch(() => {});
  };
  const handleAssetError = () => {
    setGetAssetSent(true);
  };

  const handleAssetHtmlError = () => {
    setCreateAssetHtml(false);
  };

  const handleSnippetResponse = (id, sort) => {
    if (snippet) {
      onUpdate(id, snippet.sort, SnippetTypeConst.CODE, containerId, {
        location: { assetId: htmlAssetId, contentType: 'application/json' },
      });
    } else {
      onCreate(id, sort, SnippetTypeConst.CODE, containerId, {
        location: { assetId: htmlAssetId, contentType: 'application/json' },
      });
    }
    setSendRequest(false);
  };

  const handleSnippetError = () => {};

  const handleDeleteSnippetError = () => {
    setDeleteAssetRequest(false);
  };

  const handleDeleteSnippetResponse = () => {
    setDeleteAssetRequest(false);
  };

  const hasValidSource = () => {
    const contentWrapper = document.createElement('div');
    contentWrapper.innerHTML = content;
    const contentIframe = contentWrapper.querySelector('iframe');

    return contentIframe == null ? true : validURL(contentIframe.src);
  };

  const handleSaveButton = () => {
    if (!hasValidSource()) {
      handleErrorFeedback(true, 'Whoops, it looks like a url is invalid');
      return;
    }

    const fileHtml = new File([content], 'code.json', {
      type: 'application/json',
      lastModified: new Date(0),
    });
    setHtmlFile(fileHtml);
    if (htmlFile) {
      setCreateAssetHtml(true);
      setHtmlAssetId(null);
    }
    setCreateAssetHtml(true);
    setSendRequest(true);
  };

  return (
    <>
      {hasError && <PopupMessage message={userFeedback} isError />}
      <Label>
        Content
        <ContentArea name="content" value={htmlContent} onChange={handleChange} />
      </Label>
      <div className="text-right">
        <button className="button--black mt-4 mr-3" type="button" onClick={onCancel}>
          Cancel
        </button>
        <button className="button--blue" type="submit" onClick={handleSaveButton}>
          Save
        </button>
      </div>
      {!getAssetSent && snippet && (
        <GetAsset assetId={snippet.location.assetId} onError={handleAssetError} onSuccessResult={handleAssetResponse} />
      )}
      {createAssetHtml && !htmlAssetId && (
        <CreateAssetRequest
          contentType={htmlFile.type}
          fileName={htmlFile.name}
          fileSizeInBytes={htmlFile.size}
          type="CODE"
          onError={handleAssetHtmlError}
          onSuccessResult={handleHtmlAssetResponse}
        />
      )}
      {htmlAssetId && sendRequest && !snippet && (
        <CreateCodeContentSnippetRequest
          containerId={containerId}
          locationId={htmlAssetId}
          isVisible
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
        />
      )}
      {htmlAssetId && sendRequest && snippet && (
        <UpdateCodeContentSnippetRequest
          locationId={htmlAssetId}
          snippetId={snippet.id}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
        />
      )}
      {deleteAssetRequest && (
        <DeleteAssetRequest assetId={assetId} nError={handleDeleteSnippetError} onSuccessResult={handleDeleteSnippetResponse} />
      )}
    </>
  );
};

EditableCodeSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape(),
};
EditableCodeSnippet.defaultProps = {
  snippet: null,
};

export default EditableCodeSnippet;
