import React, { useContext } from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

import AppContext from '../../context/AppContext';

export const GET_ALL_MYAPP_SECTIONS = gql`
  query getMyAppListRequest($projectId: String) {
    app {
      searchApps(projectId: $projectId) {
        edges {
          node {
            id
            description
            identifier
            title
            averageRateValue
          }
        }
      }
    }
  }
`;

const GetMyImportedAppList = ({ onError, onSuccessResult }) => {
  const {
    project: { id: projectId },
  } = useContext(AppContext);

  return (
    <Query
      fetchPolicy="network-only"
      query={GET_ALL_MYAPP_SECTIONS}
      variables={{ projectId }}
    >
      {({ loading, error, data }) => (
        <QueryRequest
          data={
            data &&
            Object.entries(data).length !== 0 &&
            data.app.searchApps.edges
          }
          error={error}
          isLoading={loading}
          onError={onError}
          onSuccessResult={onSuccessResult}
        />
      )}
    </Query>
  );
};

GetMyImportedAppList.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default GetMyImportedAppList;
