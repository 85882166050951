import gql from 'graphql-tag';

export const CREATE_CODE_ASSET = gql`
  mutation createAsset($asset: CreateAssetInput!) {
    asset {
      createAsset(asset: $asset) {
        signedUrl
        asset {
          assetId
          s3Key
          fileName
          contentType
          presignedUrl
        }
      }
    }
  }
`;
