import React, { useState } from 'react';

import { Spacing, Input, NavigationButton, ButtonIconOnly } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonGroup from '../../../../../../legacy/components/ButtonGroup/ButtonGroup';
import Preloader from '../../../../../../legacy/components/Preloader/Preloader';
import { useToast } from '../../../../../../legacy/hooks';
import { useCreateResponseSet } from '../../../../../hooks/UseCreateResponseSet';
import { generateRandomId, getIncrementValueByKey } from '../../../../../utils';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const ResRow = styled.div`
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
`;

const SettingsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
  color: #000;
`;

const ResItem = ({ item, index, onEditLabel, onEditValue }) => {
  const [localLabel, setLocalLabel] = useState(item.label);
  const [localValue, setLocalValue] = useState(item.value);

  return (
    <ResRow key={index}>
      <div style={{ flexGrow: 1 }}>
        <Input
          isLabelHidden={index !== 0}
          labelText="Label"
          name="response_label"
          placeHolder="Add a label here"
          value={localLabel}
          isRequired
          onBlur={({ target: { value } }) => {
            onEditLabel(value, item.id);
          }}
          onChange={({ target: { value } }) => {
            setLocalLabel(value);
          }}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <Input
          isLabelHidden={index !== 0}
          labelText="Value"
          name="response_title"
          value={localValue}
          isRequired
          onBlur={({ target: { value } }) => {
            onEditValue(value, item.id);
          }}
          onChange={({ target: { value } }) => {
            setLocalValue(value);
          }}
        />
      </div>
    </ResRow>
  );
};

const UpsertResponse = ({ onSave, onCancel, projectId, responseType }) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [title, setTitle] = useState('');

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [responseGroup, setResponseGroup] = useState([
    {
      id: generateRandomId(),
      label: '',
      value: '0',
      scoreValue: 0,
    },
  ]);

  const { handleCreateResponseSet, isLoading = false } = useCreateResponseSet({
    onCompleted: ({ config }) => {
      onSave({
        ...config,
        title,
      });
      handleToastSuccess({
        message: `Response set successfully created`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSaveResponse = () => {
    const formattedResponseGroup = responseGroup.map(({ label, scoreValue, value }) => ({
      label,
      scoreValue,
      value,
    }));
    handleCreateResponseSet({
      variables: {
        title,
        projectId,
        responsesRadio: responseType === 'RADIO' ? formattedResponseGroup : null,
        responsesMatch: null,
        responsesDropDownList: null,
        responsesCheckbox: responseType === 'CHECKBOX' ? formattedResponseGroup : null,
      },
    });
  };

  const editLabel = (val, id) => {
    const editedResponseGroup = responseGroup.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          label: val,
        };
      }
      return item;
    });
    setResponseGroup(editedResponseGroup);
  };

  const editValue = (val, id) => {
    const editedResponseGroup = responseGroup.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value: val,
          scoreValue: Number.isNaN(val) ? 0 : Number(val),
        };
      }
      return item;
    });

    setResponseGroup(editedResponseGroup);
  };

  const handleNewRes = () => {
    const res = [
      ...responseGroup,
      {
        label: '',
        value: getIncrementValueByKey(responseGroup, 'value').toString(),
        scoreValue: 0,
        id: generateRandomId(),
      },
    ];

    setResponseGroup(res);
  };

  return (
    <Container>
      {isLoading && <Preloader />}
      <Spacing>
        <NavigationButton
          additionalClassNames="c-sidebar-navigation-button"
          direction="previous"
          iconId="id_enter_icon"
          rotationCssSuffix="180"
          text="Back to feedback"
          onClick={onCancel}
        />
      </Spacing>
      <Spacing>
        <Input
          labelText="Response title"
          name="response_title"
          value={title}
          isRequired
          onChange={({ target: { value: titleValue } }) => {
            setTitle(titleValue);
            setIsSubmitDisabled(titleValue === '');
          }}
        />
      </Spacing>

      {responseGroup.map((item) => (
        <ResItem key={item.id} index={item.id} item={item} onEditLabel={editLabel} onEditValue={editValue} />
      ))}

      <SettingsContainer>
        <ButtonIconOnly iconId="id_plus_icon" text="Add Response" onClick={handleNewRes} />
      </SettingsContainer>

      {/* Switch ButtonGroup for TabsetButtons when switching to V3 */}
      <ButtonGroup isConfirmButtonDisabled={isSubmitDisabled} hasConfirmButton onCancel={onCancel} onSave={handleSaveResponse} />
    </Container>
  );
};

UpsertResponse.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  responseType: PropTypes.string.isRequired,
};

export { UpsertResponse };
