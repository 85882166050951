import React, { useEffect, useState, useContext } from 'react';

import { Spacing, InformationBox, Table, ButtonIconOnly } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonGroup from '../../../../../../legacy/components/ButtonGroup/ButtonGroup';
import AppContext from '../../../../../../legacy/context/AppContext';
import { getActionTypeName } from '../../UpsertActionsTab.helpers';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const SettingsContainer = styled.div`
  display: flex;
  justify-content: end !important;
  align-items: center;
  gap: 0.5rem;
  color: #000;
`;

const ActionsOverview = ({ onEditClick, onDeleteClick, actionItems, onDelete, onClose, hasDelete }) => {
  const { actionsSidebarDetails } = useContext(AppContext);
  const { type } = actionsSidebarDetails;

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(actionItems);
  }, [actionItems]);
  return (
    <Container>
      <Spacing>
        <InformationBox message="Create one or more actions to personalise the selected content. Multiple actions will be treated with the 'OR' operator." />
      </Spacing>

      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'type',
                Cell: ({ value }) => <span>{value}</span>,
                Header: () => <div>Type</div>,
              },
              {
                accessor: 'title',
                Cell: ({ value }) => <span>{value}</span>,
                Header: () => <div>Title</div>,
              },
              {
                accessor: 'settingsButtonData',
                Cell: ({ value: { id, handleEdit, handleDelete } }) => (
                  <SettingsContainer>
                    <ButtonIconOnly
                      additionalClassNames=""
                      iconId="id_bin_icon"
                      isIconBorderVisible={false}
                      onClick={() => handleDelete(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      iconId="id_caret_down_icon"
                      isIconBorderVisible={false}
                      onClick={() => handleEdit(id)}
                    />
                  </SettingsContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              type: getActionTypeName(type, item.__typename),
              title: item.title ?? '',
              settingsButtonData: {
                id: item.id,
                handleEdit: onEditClick,
                handleDelete: onDeleteClick,
              },
            }))}
          />
        </Spacing>
      )}

      <Spacing>
        <SettingsContainer>
          <ButtonIconOnly iconId="id_plus_icon" text="Add action" onClick={() => onEditClick(undefined)} />
        </SettingsContainer>
      </Spacing>
      <ButtonGroup hasConfirmButton={false} isConfirmButtonDisabled onCancel={onClose} onDelete={hasDelete ? onDelete : undefined} />
    </Container>
  );
};

ActionsOverview.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  actionItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  hasDelete: PropTypes.bool,
};

export { ActionsOverview };
