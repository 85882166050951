import React from 'react';

import PropTypes from 'prop-types';

import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import ImageButton, { ICONS } from '../UI/Button/ImageButton';

import { NOTES_PROP_TYPES } from './Notes.constants';
import { Flex, FlexFull, FlexHorizontal, Header, Icon, Text } from './styled';

const PREFIX = 'notes-list-';

function NotesList(props) {
  const { allNotes, onAddHandler, onViewHandler } = props;

  const icon = <Icon className="icon-chevron" title="Edit" />;

  return (
    <>
      <FlexHorizontal>
        <FlexFull>
          <Header>Notes</Header>
        </FlexFull>
        <Flex>
          <ImageButton borderRadius="15%" iconType={ICONS.add} onClicked={() => onAddHandler()} animate />
        </Flex>
      </FlexHorizontal>

      {!allNotes.length && <Text>No notes available. Please add a note!</Text>}

      {Boolean(allNotes.length) && (
        <div style={{ marginTop: '1rem' }}>
          {allNotes.map(note => {
            const { id, subject } = note;
            return (
              <div
                key={`${PREFIX}${id}`}
                style={{
                  marginBottom: '0.5rem',
                }}
              >
                <ButtonWithIcon icon={icon} text={subject} onClick={() => onViewHandler(note)} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

NotesList.propTypes = {
  allNotes: PropTypes.arrayOf(PropTypes.shape(NOTES_PROP_TYPES)).isRequired,
  onAddHandler: PropTypes.func.isRequired,
  onViewHandler: PropTypes.func.isRequired,
};

export default NotesList;
