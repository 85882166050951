import React from 'react';

import cx from 'classnames';

import { CheckboxProps } from './Checkbox.types';

import { Icon } from '../../Icon';

import './Checkbox.css';

const Checkbox = ({
  additionalClassNames,
  checkboxLabelClassName,
  deleteButton,
  id,
  imageSrc,
  isChecked = false,
  isDisabled = false,
  isLabelHidden = false,
  labelText,
  name,
  onChange,
  iconId = 'id_tick_icon',
  value,
  ...rest
}: CheckboxProps) => (
  <div className="c-checkbox">
    <input
      checked={isChecked}
      className={cx('c-checkbox__input', 'h-hide-visually', additionalClassNames)}
      disabled={isDisabled}
      id={id}
      name={name}
      type="checkbox"
      value={value}
      onChange={onChange}
      {...rest}
    />
    {imageSrc && <img alt="" className="c-checkbox__image" src={imageSrc} />}
    <label className={cx('c-checkbox__label', checkboxLabelClassName)} data-testid="qa-label" htmlFor={id}>
      <span
        className={cx('c-label__text', {
          'h-hide-visually': isLabelHidden,
        })}
      >
        {labelText}
      </span>
      <span aria-hidden="true" className="c-checkbox__icon-container">
        <Icon className="c-checkbox__icon" id={iconId} />
      </span>
    </label>
    {deleteButton}
  </div>
);

export { Checkbox };
