export const filterDuplicatesByKey = (array, key) => {
  const seenKeys = {};
  return array.filter((obj) => {
    const keyValue = obj[key];
    if (!seenKeys[keyValue]) {
      seenKeys[keyValue] = true;
      return true;
    }
    return false;
  });
};
