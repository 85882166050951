import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TEXT_POSITION = {
  left: 'L',
  right: 'R',
};

const TEXT_POSITION_DEFAULT = TEXT_POSITION.left;

const SpanFlex = styled.span`
  align-items: center;
  display: flex;
`;

const Button = styled.button`
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  height: fit-content;
  min-width: 100%;
  padding: 0.5rem;
  text-align: left;
  width: fit-content;

  &:hover {
    background-color: #f4f5f6;
  }
`;

const ButtonContentContainer = styled(SpanFlex)`
  flex-direction: row;
`;

const ButtonContentTextContainer = styled(SpanFlex)`
  flex: 1;
  margin-left: ${props => (props.textPosition === TEXT_POSITION.right ? '0.3rem' : '0')};
  margin-right: ${props => (props.textPosition === TEXT_POSITION.left ? '0.3rem' : '0')};
  padding-top: 0.15rem;
`;

const ButtonContentIconContainer = styled(SpanFlex)`
  height: fit-content;
  width: fit-content;
`;

const ButtonWithIcon = ({ icon, onClick, options: { textPosition }, text }) => {
  const isTextPositionValueValid = Object.values(TEXT_POSITION).includes(textPosition);
  const actualTextPosition = isTextPositionValueValid ? textPosition : TEXT_POSITION_DEFAULT;

  const textComponent = <ButtonContentTextContainer textPosition={actualTextPosition}>{text}</ButtonContentTextContainer>;

  return (
    <Button onClick={onClick}>
      <ButtonContentContainer>
        {actualTextPosition === TEXT_POSITION.left && textComponent}
        {icon && <ButtonContentIconContainer>{icon}</ButtonContentIconContainer>}
        {actualTextPosition === TEXT_POSITION.right && textComponent}
      </ButtonContentContainer>
    </Button>
  );
};

ButtonWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  options: PropTypes.shape({
    textPosition: PropTypes.oneOf([TEXT_POSITION.left, TEXT_POSITION.right]),
  }),
};

ButtonWithIcon.defaultProps = {
  icon: undefined,
  onClick: () => {},
  options: {
    textPosition: TEXT_POSITION_DEFAULT,
  },
};

export default ButtonWithIcon;
