import gql from 'graphql-tag';

export const APPLY_CONDITION_TEMPLATE = gql`
  mutation applyTemplate($templateId: Int!, $actionId: Int!) {
    questionAction {
      applyTemplate(templateId: $templateId, actionId: $actionId) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionRadioResponseSelectedType {
          questionResponseRadioId
          selection
        }
        ... on QuestionConditionTextType {
          text
        }
        ... on QuestionConditionSliderValueSelectedType {
          minValue
          maxValue
        }
        ... on QuestionConditionCheckboxSelectedType {
          questionResponseCheckboxId
          selection
        }

        ... on QuestionConditionDateSelectedType {
          dateRangeType: type
          minDate
          maxDate
          selectedDate
          minTimeSpan
          maxTimeSpan
        }
      }
    }
  }
`;
