import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Footer from '../../ConsoleFooter/Footer';

const Wrapper = styled.div`
  background: rgba(9, 30, 66, 0.54);
  height: 100%;
  left: 0;
  position: fixed;
  top: 4em;
  transition: none;
  width: 100%;
  z-index: 9;
`;

const Backdrop = props => {
  const { isClicked } = props;

  document.body.style.overflow = 'hidden';

  const handleScroll = Clicked => {
    document.body.style.overflow = 'unset';
    Clicked();
  };
  return (
    <Wrapper onClick={() => handleScroll(isClicked)}>
      <div style={{ position: 'absolute', bottom: '3.9rem', width: '100%' }}>
        <Footer />
      </div>
    </Wrapper>
  );
};

Backdrop.propTypes = {
  isClicked: PropTypes.func.isRequired,
};

export default Backdrop;
