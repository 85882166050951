import React from 'react';

import { Link } from 'react-router-dom';

function ResetConfirmation() {
  return (
    <>
      <div className="w-100">
        <img
          alt="tick"
          src="https://netfront.com.au/img/kanzi/static/tick.svg"
          style={{ width: '40px', marginTop: '1em' }}
        />
        <p
          style={{
            margin: '1em 0',
            padding: '0 1em',
            textAlign: 'center',
          }}
        >
          Thank you, please check your email for your reset link.
        </p>
        <div>
          <Link to="/login">
            <button className="button--red" type="button">
              LOGIN
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default ResetConfirmation;
