import React from 'react';

import PropTypes from 'prop-types';

import ConsoleHeader from '../Header/ConsoleHeader';

function LayoutConsole(props) {
  const { children } = props;

  return (
    <>
      <header>
        <ConsoleHeader />
      </header>
      {children}
    </>
  );
}

LayoutConsole.propTypes = {
  children: PropTypes.element,
};

LayoutConsole.defaultProps = {
  children: null,
};

export default LayoutConsole;
