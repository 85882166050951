/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { CMSAnimationWrapper } from '../CMSAnimationWrapper';
import { Flip } from '../CMSEvents/Flip';
import { PopUp } from '../CMSEvents/PopUp';
import { Reveal } from '../CMSEvents/Reveal';

import { CMSEventWrapperProps } from './CMSEventWrapper.types';

import { makeComponentMap } from '../../components/makeComponentMap/makeComponentMap';

const getComponentByType = makeComponentMap({
  FLIP: Flip,
  POP_UP: PopUp,
  REVEAL: Reveal,
});

const getAnimationComponentByType = makeComponentMap({
  ANIMATED: CMSAnimationWrapper,
});

const CMSEventWrapper = ({ children, contentEvent, id }: CMSEventWrapperProps) => {
  const { animation, event } = contentEvent ?? {};

  const AnimationComponent = getAnimationComponentByType(animation ? 'ANIMATED' : undefined);
  const EventWrapper = getComponentByType(event);

  return (
    <AnimationComponent {...contentEvent} id={id}>
      <EventWrapper {...contentEvent}>{children}</EventWrapper>
    </AnimationComponent>
  );
};

export { CMSEventWrapper };
