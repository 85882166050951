import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_CONTAINER_STYLE = gql`
  mutation UpdateContentContainer($containerId: Int!, $sectionId: Int!, $borderColor: String, $backgroundColor: String, $title: String!) {
    contentSection {
      updateContainer(
        containerId: $containerId
        sectionId: $sectionId
        borderColor: $borderColor
        backgroundColor: $backgroundColor
        title: $title
      ) {
        id
        sort
        borderColor
        backgroundColor
      }
    }
  }
`;

const updateContainerStyle = ({ sectionId, containerId, borderColor, backgroundColor, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_CONTENT_CONTAINER_STYLE}>
    {(updateSection, { loading, error, data }) => {
      const variables = {
        sectionId,
        containerId,
        borderColor,
        backgroundColor,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSection.updateContainer);
      }

      return <MutationRequest isLoading={loading} mutation={updateSection} variables={variables} />;
    }}
  </Mutation>
);

updateContainerStyle.propTypes = {
  containerId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
};

updateContainerStyle.defaultProps = {
  backgroundColor: null,
  borderColor: null,
};

export default updateContainerStyle;
