import { NetfrontServices } from '../../constants/NetfrontServices';

const { BONOBO, VERVET } = NetfrontServices;
export const emailUsages = [
  {
    id: 0,
    value: 'ACTIVATE_ACCOUNT',
    label: 'Activate account',
  },
  {
    id: 1,
    value: 'FORGOT_PASSWORD',
    label: 'Forgot password',
  },
  {
    id: 2,
    value: 'ORGANISATION_INVITATION',
    label: 'Organisation invitation',
  },
  {
    id: 3,
    value: 'PROJECT_INVITATION',
    label: 'Project invitation',
  },
  {
    id: 4,
    value: 'GROUP_INVITATION',
    label: 'Group invitation',
  },
  {
    id: 5,
    value: 'CONTACT',
    label: 'Contact',
  },
  {
    id: 6,
    value: 'SUCCESSFUL_REGISTRATION',
    label: 'Successful registration',
  },
  {
    id: 7,
    value: 'POST_COMMENTED',
    label: 'Post commented',
    service: BONOBO,
  },
  {
    id: 8,
    value: 'POST_RELATED',
    label: 'Post related',
    service: BONOBO,
  },
  {
    id: 9,
    value: 'UPDATE_EMAIL',
    label: 'Update email',
  },
  {
    id: 10,
    value: 'COMMENT_COMMENTED',
    label: 'Comment commented',
    service: BONOBO,
  },
  {
    id: 11,
    value: 'INVITATION_ACCEPTED',
    label: 'Invitation accepted',
  },
  {
    id: 12,
    value: 'COMMENT_RELATED',
    label: 'Comment related',
    service: BONOBO,
  },
  {
    id: 13,
    value: 'NEW_POST_ADDED',
    label: 'New post added',
    service: BONOBO,
  },
  {
    id: 14,
    value: 'NEW_COMMENT_ADDED',
    label: 'New comment added',
    service: BONOBO,
  },
  {
    id: 15,
    value: 'INVOICE',
    label: 'Invoice',
    service: VERVET,
  },
  {
    id: 16,
    value: 'GROUP_CREATION_ACCEPTED',
    label: 'Group creation accepted',
  },
  {
    id: 17,
    value: 'GROUP_CREATION_DECLINED',
    label: 'Group creation declined',
  },
  // {
  //   id: 18,
  //   value: 'GROUP_CREATION_REQUEST',
  //   label: 'Group creation request',
  // },
  // {
  //   id: 19,
  //   value: 'CONTACT_FORM_VALIDATION',
  //   label: 'Contact form validation',
  // },
  // {
  //   id: 20,
  //   value: 'ERROR',
  //   label: 'Error',
  // },
  // {
  //   id: 21,
  //   value: 'TIER_APPROACHING',
  //   label: 'Tier approaching',
  //   service: VERVET,
  // },
  {
    id: 22,
    value: 'REPORT_REQUESTED',
    label: 'Report',
  },
  // {
  //   id: 23,
  //   value: 'GROUP_CREATION_TRANSFERRED',
  //   label: 'Group creation transferred',
  // },
  // {
  //   id: 24,
  //   value: 'PARTNER_INVITATION',
  //   label: 'Partner invitation',
  // },
  // {
  //   id: 25,
  //   value: 'ACCESS_REMINDER',
  //   label: 'Access reminder',
  // },
  // {
  //   id: 26,
  //   value: 'PAYMENT_FAILED',
  //   label: 'Payment failed',
  //   service: VERVET,
  // },
  // {
  //   id: 27,
  //   value: 'SENSITIVE_CONTENT',
  //   label: 'Sensitive content',
  // },
];

export const templateTypes = [
  {
    id: 0,
    value: 'EMAIL',
    label: 'Email',
  },
  {
    id: 1,
    value: 'SMS',
    label: 'Sms',
  },
];

export const smsUsages = [
  {
    id: 1,
    value: 'ACTIVATE_ACCOUNT',
    label: 'Activate account',
  },
  {
    id: 2,
    value: 'FORGOT_PASSWORD',
    label: 'Forgot password',
  },
  {
    id: 3,
    value: 'UPDATE_PHONE_NUMBER',
    label: 'Update phone number',
  },
];
