import gql from 'graphql-tag';

export const UPDATE_PAGE_PARENT = gql`
  mutation editTag($contentPageId: Int!, $contentGroupId: Int!) {
    contentPage {
      updateContentPageParent(
        contentPageId: $contentPageId
        contentGroupId: $contentGroupId
      ) {
        contentGroup {
          id
          title
        }
      }
    }
  }
`;
