import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';

import EditAssetSideBar from '../../components/EditAssetSideBar/EditAssetSideBar';
import Icon from '../../components/Icon/Icon';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import PopupMessage from '../../components/PopupMessage/PopupMessage';
import AudioPlayer from '../../components/UI/AudioPlayer/AudioPlayer';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import UploadImageSidebar from '../../components/UploadImageSidebar/UploadImageSidebar';
import { errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import AddAssetToFavRequest from '../../middleware/AssetLibrary/addAssetToFavRequest';
import GetAllImagesRequest from '../../middleware/AssetLibrary/getAllAssetsRequest';
import GetAllFavAssetsRequest from '../../middleware/AssetLibrary/getAllFavAssetsRequest';
import RemoveAssetToFavRequest from '../../middleware/AssetLibrary/removeAssetToFavRequest';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import GetOrganisation from '../../middleware/Organisation/getOrganisation';
import GetProject from '../../middleware/Project/getProject';
import DocIcon from '../../static/img/FileType/DocIcon.svg';
import PdfIcon from '../../static/img/FileType/PdfIcon.svg';
import XlsIcon from '../../static/img/FileType/XlsIcon.svg';
import FilterIcon from '../../static/img/filter-icon.svg';
import PlayIcon from '../../static/img/Media/Play.svg';
import SearchIcon from '../../static/img/search-icon.svg';
import { assetType, stringToDate, dynamicSort, getQueryStringValue, searchByFieldInArray } from '../../utils/utils';

const IconImg = styled.img`
  filter: grayscale(100%) brightness(125%) contrast(110%);
  margin: 0 auto;
  padding-top: 2em;
  width: 28%;
`;

const IconImgS = styled(IconImg)`
  width: 40%;
`;

const FileName = styled.p`
  bottom: 0;
  color: #a6adb4;
  font-size: 0.7em;
  padding-left: 5px;
  position: absolute;
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  display: block;
  height: 7em;
  position: relative;
  width: 7em;

  &:hover {
    ${IconImg} {
      filter: unset;
    }

    ${FileName} {
      color: #000;
      white-space: unset;
    }
  }
`;

const VideoBox = styled.video`
  left: 20%;
  max-height: 50vh;
  max-width: 50vh;
  position: absolute;
  top: 25%;
  z-index: 999;
`;

const PlayBtn = styled.button`
  background: url(${PlayIcon}) no-repeat 50% 50%;
  background-size: 2em;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 100%;
  outline: none;
  width: 100%;
`;

class LibraryAssetPage extends React.Component {
  // eslint-disable-next-line react/sort-comp, react/static-property-placement
  static contextType = AppContext;

  // eslint-disable-next-line react/static-property-placement
  propTypes = {
    match: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      organisationId: null,
      projectId: null,
      projectLoaded: true,
      orgLoaded: true,
      errorMessage: '',
      getAllImagesRequest: false,
      imagesSrc: [],
      isRightOpen: false,
      type: assetType.IMAGE,
      editAsset: {},
      isDropDownShow: false,
      filteredImagesSrc: [],
      searchInputValue: '',
      sendFavAssetRequest: false,
      assetId: '',
      removeFavAssetRequest: false,
      getAllFavAssets: true,
      favAssets: [],
      isError: true,
      isCreateRightOpen: false,
      selectedAudioId: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { orgId, projectId, type } = params;
    const { orgName, projectName } = this.context;
    this.setState({
      organisationId: Number(orgId),
      projectId,
      orgLoaded: orgName !== '',
      projectLoaded: projectName !== '',
      type: type.toUpperCase(),
    });
  }

  handleOrgError = () => {
    this.setState({
      orgLoaded: true,
    });
  };

  handleProjectError = () => {
    this.setState({
      projectLoaded: true,
    });
  };

  handleGetOrganisation = (name) => {
    const { updateOrgName } = this.context;
    updateOrgName(name);
    this.setState({
      orgLoaded: true,
    });
  };

  handleGetProject = (name) => {
    const { updateProjectName } = this.context;

    updateProjectName(name);

    this.setState({
      projectLoaded: true,
    });
  };

  handleOnResponseGetAllImages = (data) => {
    const imagesSrc = [];
    const { favAssets } = this.state;
    data.map((image) => {
      const type = image.fileName.split('.').pop();
      return imagesSrc.push({
        src: getSignedUrl(image.s3Key, image.contentType),
        id: image.assetId,
        name: image.fileName,
        date: stringToDate(image.uploaded),
        isFav: favAssets.includes(image.assetId),
        alt: image.alt,
        transcript: image.transcript,
        tags: image.tags,
        type,
      });
    });
    const searchText = getQueryStringValue(window.location, 'search').toLowerCase() || '';
    const filteredArray = searchByFieldInArray(imagesSrc, 'name', searchText);
    this.setState({
      getAllImagesRequest: false,
      imagesSrc,
      filteredImagesSrc: filteredArray,
      searchInputValue: searchText,
    });
  };

  handleOnCreateImageAsset = () => {
    this.setState({
      getAllImagesRequest: true,
    });
  };

  handleOnErrorGetAllImages = (msg) => {
    this.setState({
      getAllImagesRequest: false,
      errorMessage: msg,
    });
  };

  handleToggleSidebar = (asset = {}) => {
    const { isRightOpen } = this.state;
    this.setState({
      isRightOpen: !isRightOpen,
      editAsset: asset,
    });
  };

  handleCreateToggleSidebar = (asset = {}) => {
    const { isCreateRightOpen } = this.state;
    this.setState({
      isCreateRightOpen: !isCreateRightOpen,
      editAsset: asset,
    });
  };

  handleOnClickEdit = () => {};

  onHandleToggleSidebar = (isRightOpen) => {
    if (isRightOpen) {
      this.handleToggleSidebar();
    } else {
      this.handleCreateToggleSidebar();
    }
  };

  handleOnClickFilterToggle = () => {
    const { isDropDownShow } = this.state;
    this.setState({
      isDropDownShow: !isDropDownShow,
    });
  };

  handleOnSortingClick = (type) => {
    const { imagesSrc } = this.state;
    let filteredData = imagesSrc;
    if (type === 'favourites') {
      filteredData = filteredData.filter((image) => image.isFav);
    } else {
      const filterProp = type === 'newest' ? '-date' : 'date';
      filteredData = filteredData.sort(dynamicSort(filterProp));
    }
    this.setState({
      filteredImagesSrc: filteredData,
    });
    this.handleOnClickFilterToggle();
  };

  handleOnChangeSearch = (e) => {
    const { value } = e.target;
    const { imagesSrc } = this.state;
    const filteredArray = searchByFieldInArray(imagesSrc, 'name', value);
    this.setState({
      filteredImagesSrc: filteredArray,
      searchInputValue: value,
    });
  };

  handleOnAddFavorite = (assetId, isFav) => {
    if (isFav) {
      this.setState({
        assetId,
        removeFavAssetRequest: true,
      });
    } else {
      this.setState({
        assetId,
        sendFavAssetRequest: true,
      });
    }
  };

  handleOnResponseAddFavAsset = () => {
    this.setState({
      sendFavAssetRequest: false,
      getAllFavAssets: true,
      isError: false,
      errorMessage: 'Asset is added to favorite successfully!',
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
        isError: true,
      });
    }, 2000);
  };

  handleOnErrorAddFavAsset = (message) => {
    this.setState({
      sendFavAssetRequest: false,
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleOnResponseRemoveFavAsset = () => {
    this.setState({
      removeFavAssetRequest: false,
      getAllFavAssets: true,
      isError: false,
      errorMessage: 'Asset is removed from favorite successfully!',
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
        isError: true,
      });
    }, 2000);
  };

  handleOnErrorRemoveFavAsset = (message) => {
    this.setState({
      removeFavAssetRequest: false,
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleGetFavAssetsError = (message) => {
    this.setState({
      getAllFavAssets: false,
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleGetFavAssetsRes = (data) => {
    this.setState({
      getAllFavAssets: false,
      favAssets: data,
      getAllImagesRequest: true,
    });
  };

  handleImageLoaded = (id) => {
    document.getElementById(id).className = 'header-card';
  };

  handleAudio = (id) => {
    const { selectedAudioId } = this.state;
    if (id !== null && id !== selectedAudioId) {
      this.setState({ selectedAudioId: id });
    }
  };

  render() {
    const {
      organisationId,
      projectId,
      projectLoaded,
      orgLoaded,
      errorMessage,
      getAllImagesRequest,
      isRightOpen,
      type,
      editAsset,
      isDropDownShow,
      filteredImagesSrc,
      searchInputValue,
      sendFavAssetRequest,
      removeFavAssetRequest,
      getAllFavAssets,
      assetId,
      isError,
      isCreateRightOpen,
    } = this.state;
    const { org, project } = this.context;

    const {
      match: { params },
    } = this.props;

    const breadPath = [
      {
        id: 1,
        path: `/dashboard/${org.key}/${project.id}/library`,
        title: 'Library',
      },
      {
        id: 2,
        path: ``,
        title: `${params.type.charAt(0).toUpperCase() + params.type.slice(1)} library`,
      },
    ];

    return (
      <>
        <DragDropContext>
          <PageWrapper
            breadPath={breadPath}
            info={`Manage your ${params.type} assets`}
            pageDescription="Manage all your assets"
            pageTitle="Assets management page"
            params={params}
          >
            <ProjectNavBar title={project.name} />
            <div className="library_asset_container">
              <div className="image-block">
                <div className="gallery-header">
                  <div className="header-left">
                    <div className="imgtag">{type.charAt(0).toUpperCase() + type.substring(1).toLowerCase()}</div>
                    <Icon id="icon_question_circle" />
                  </div>
                  <div className="header-right">
                    <div
                      className="icon-tag"
                      style={{
                        background: `url(${SearchIcon}) no-repeat center`,
                      }}
                    >
                      <form>
                        <input
                          id="search"
                          name="search"
                          style={{ backgroundColor: 'transparent' }}
                          type="text"
                          value={searchInputValue}
                          placeholder
                          onChange={this.handleOnChangeSearch}
                        />
                        {/* <img
                      alt="searchbox"
                      height="17px"
                      src={
                        `${getAssetsUrl()}icons/myapp-dashboard/search-tool.svg`
                      }
                      width="17px"
                    /> */}
                      </form>
                    </div>
                    <div className="icon-tag">
                      <div className="dropdown">
                        <button
                          className="button-none menu-icon-container-library d-flex justify-content-center"
                          type="button"
                          onClick={this.handleOnClickFilterToggle}
                        >
                          <img
                            alt="edit"
                            aria-expanded="false"
                            aria-haspopup="true"
                            className="content-edit-icon"
                            data-toggle="dropdown"
                            id="dropdownMenuButton"
                            src={FilterIcon}
                          />
                        </button>
                        <div
                          aria-labelledby="dropdownMenuButton"
                          className={classnames('dropdown-menu', {
                            show: isDropDownShow,
                          })}
                        >
                          <button className="dropdown-item" type="button" onClick={this.handleOnSortingClick.bind(this, 'newest')}>
                            Newest
                          </button>
                          <button className="dropdown-item" type="button" onClick={this.handleOnSortingClick.bind(this, 'oldest')}>
                            Oldest
                          </button>
                          <button className="dropdown-item" type="button" onClick={this.handleOnSortingClick.bind(this, 'favourites')}>
                            Favorites
                          </button>
                        </div>
                      </div>
                    </div>
                    <button className="btn" type="button" onClick={this.handleCreateToggleSidebar}>
                      <div className="camra-icon">
                        <div className="camera-bg">
                          <Icon id="icon_camera" />
                        </div>
                      </div>
                      Add New {type.toLowerCase()}
                    </button>
                  </div>
                </div>
                <ul className="img-list">
                  {filteredImagesSrc.length
                    ? filteredImagesSrc.map((image) => (
                        <>
                          <div
                            key={image.id}
                            className="position-relative"
                            style={
                              type === assetType.VIDEO
                                ? {
                                    overflowY: 'hidden',
                                    maxHeight: '6em',
                                    marginBottom: '1em',
                                  }
                                : null
                            }
                          >
                            <div className="header-card d-none" id={`header-card_${image.id}`}>
                              <button
                                className="icon-bg button-none"
                                type="button"
                                onClick={this.handleOnAddFavorite.bind(this, image.id, image.isFav)}
                              >
                                <Icon
                                  id="icon_heart"
                                  style={{
                                    height: '0.75rem',
                                    width: '0.75rem',
                                    color: image.isFav ? 'red' : 'white',
                                  }}
                                />
                              </button>
                            </div>
                            <button
                              key={image.id}
                              className="button-none"
                              type="button"
                              onClick={type !== assetType.AUDIO && (() => this.handleToggleSidebar(image))}
                            >
                              <li
                                key={image.assetId}
                                className={classnames({
                                  'video-li': type === assetType.VIDEO,
                                })}
                              >
                                {type === assetType.IMAGE && (
                                  <>
                                    <img
                                      alt=""
                                      className=""
                                      src={image.src}
                                      onLoad={this.handleImageLoaded.bind(this, `header-card_${image.id}`)}
                                    />
                                  </>
                                )}
                                {type === assetType.VIDEO && (
                                  <>
                                    <PlayBtn />
                                    <video
                                      className="img-fluid"
                                      // eslint-disable-next-line max-len
                                      src={image.src}
                                      onLoadStart={this.handleImageLoaded.bind(this, `header-card_${image.id}`)}
                                    >
                                      <track kind="captions" srcLang="en" />
                                    </video>
                                  </>
                                )}
                                {type === assetType.AUDIO && (
                                  <IconWrapper>
                                    <AudioPlayer isClicked={() => this.handleAudio(image.id)} selected={image.src} />
                                    <FileName>{image.name}</FileName>
                                  </IconWrapper>
                                )}
                                {type === assetType.DOCUMENT && (
                                  <p>
                                    <a
                                      className="a_document"
                                      href={image.src}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      download
                                      onLoad={this.handleImageLoaded.bind(this, `header-card_${image.id}`)}
                                    >
                                      <IconWrapper>
                                        {(() => {
                                          switch (image.type) {
                                            case 'pdf':
                                              return <IconImg alt="file" src={PdfIcon} />;
                                            case 'docx':
                                              return <IconImgS alt="file" src={DocIcon} />;
                                            case 'xlsx':
                                              return <IconImgS alt="file" src={XlsIcon} />;
                                            default:
                                              return null;
                                          }
                                        })()}
                                        <FileName>{image.name}</FileName>
                                      </IconWrapper>
                                    </a>
                                  </p>
                                )}
                              </li>
                            </button>
                          </div>
                        </>
                      ))
                    : null}
                </ul>
                {filteredImagesSrc.length >= 20 ? (
                  <a className="btn-block" href="/">
                    Load More
                  </a>
                ) : null}
              </div>
            </div>
            <UploadImageSidebar
              isRightOpen={isCreateRightOpen}
              projectId={projectId}
              type={type}
              onCreateImageAsset={this.handleOnCreateImageAsset}
              onHandleToggleSidebar={this.handleCreateToggleSidebar}
            />
            {(isRightOpen || isCreateRightOpen) && (
              <Backdrop
                // eslint-disable-next-line react/jsx-no-bind
                isClicked={this.onHandleToggleSidebar.bind(this, isRightOpen)}
              />
            )}
            {/* remove 0 */}
            {Object.keys(editAsset).length !== 0 && (
              <div>
                <EditAssetSideBar
                  asset={editAsset}
                  isRightOpen={isRightOpen}
                  projectId={projectId}
                  type={type}
                  onCreateImageAsset={this.handleOnCreateImageAsset}
                  onHandleToggleSidebar={this.handleToggleSidebar}
                />
                {type === assetType.VIDEO && (
                  <VideoBox src={editAsset.src} controls>
                    <track kind="captions" srcLang="en" />
                  </VideoBox>
                )}
              </div>
            )}
          </PageWrapper>
          {!orgLoaded && <GetOrganisation id={organisationId} onError={this.handleOrgError} onSuccessResult={this.handleGetOrganisation} />}
          {!projectLoaded && <GetProject id={projectId} onError={this.handleProjectError} onSuccessResult={this.handleGetProject} />}
          {getAllImagesRequest && projectId && (
            <GetAllImagesRequest
              projectId={projectId}
              type={type}
              onError={this.handleOnErrorGetAllImages}
              onSuccessResult={this.handleOnResponseGetAllImages}
            />
          )}
          {sendFavAssetRequest && (
            <AddAssetToFavRequest
              assetId={assetId}
              onError={this.handleOnErrorAddFavAsset}
              onSuccessResult={this.handleOnResponseAddFavAsset}
            />
          )}
          {removeFavAssetRequest && (
            <RemoveAssetToFavRequest
              assetId={assetId}
              onError={this.handleOnErrorRemoveFavAsset}
              onSuccessResult={this.handleOnResponseRemoveFavAsset}
            />
          )}
          {getAllFavAssets && (
            <GetAllFavAssetsRequest assetType={type} onError={this.handleGetFavAssetsError} onSuccessResult={this.handleGetFavAssetsRes} />
          )}
          <ReactCSSTransitionGroup
            transitionEnterTimeout={errorMessageEnterTimeout}
            transitionLeaveTimeout={errorMessageLeaveTimeout}
            transitionName="dialog-popup"
          >
            {errorMessage !== '' && <PopupMessage isError={isError} message={errorMessage} />}
          </ReactCSSTransitionGroup>
        </DragDropContext>
      </>
    );
  }
}

LibraryAssetPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: { appId: PropTypes.string.isRequired },
  }).isRequired,
};

export default LibraryAssetPage;
