import gql from 'graphql-tag';

export const FRAGMENT_FORM = gql`
  fragment form on ContentSnippetFormType {
    formType
    form {
      id
      title
      recommendedFormType
      contentPages {
        contentSections {
          sectionContainers {
            snippets {
              id
              sort
              visible
              type: __typename
              __typename
              ...text
              ...image
              ...video
              ...audio
              ...question
              ...summary
            }
          }
        }
      }
    }
  }
`;
