import React from 'react';

import { Label, Input, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

const ConditionSliderQuestion = ({ minValue, maxValue, onInputChange }) => (
  <div className="c-action__item c-action__item__slider h-flex">
    <div className="c-action__item__slider--item">
      <Label additionalClassNames="c-action__item__label h-flex" forId="slider-min" labelText="Min value" isRequired>
        <Tooltip placement="top" text="The max value of slider" />
      </Label>
      <Input
        id="slider-min"
        name="minValue"
        type="number"
        value={minValue}
        onChange={({ target: { value, name } }) => onInputChange({ value, name })}
      />
    </div>
    <div className="c-action__item__slider--item">
      <Label additionalClassNames="c-action__item__label h-flex" forId="slider-max" labelText="Max value" isRequired>
        <Tooltip placement="left" text="The min value of slider" />
      </Label>
      <Input
        id="slider-max"
        name="maxValue"
        type="number"
        value={maxValue}
        onChange={({ target: { value, name } }) => onInputChange({ value, name })}
      />
    </div>
  </div>
);

ConditionSliderQuestion.propTypes = {
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onInputChange: PropTypes.func,
};

export { ConditionSliderQuestion };
