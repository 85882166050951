import React from 'react';

import { replacePlaceholderText } from '../Snippets.helpers';

import { QuestionAnswerCalendarSnippet } from './QuestionAnswerCalendarSnippet';
import { QuestionAnswerCheckboxSnippet } from './QuestionAnswerCheckboxSnippet';
import { QuestionAnswerDecimalSnippet } from './QuestionAnswerDecimalSnippet';
import { QuestionAnswerDropDownListSnippet } from './QuestionAnswerDropDownListSnippet';
import { QuestionAnswerMatchSnippet } from './QuestionAnswerMatchSnippet';
import { QuestionAnswerMultiLineTextSnippet } from './QuestionAnswerMultiLineTextSnippet';
import { QuestionAnswerMultiResponseTextSnippet } from './QuestionAnswerMultiResponseTextSnippet';
import { QuestionAnswerNumberSnippet } from './QuestionAnswerNumberSnippet';
import { QuestionAnswerRadioSnippet } from './QuestionAnswerRadioSnippet';
import { QuestionAnswerRangeInputSnippet } from './QuestionAnswerRangeInputSnippet';
import { QuestionAnswerRatingSnippet } from './QuestionAnswerRatingSnippet';
import { QuestionAnswerSingleLineTextSnippet } from './QuestionAnswerSingleLineTextSnippet';
import { QuestionSnippetProps } from './QuestionSnippet.interfaces';

import { makeComponentMap } from '../../../components/makeComponentMap/makeComponentMap';
import { getContentSnippet, useContentPageContext } from '../../../contexts';
import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

const getComponentByType = makeComponentMap({
  Calendar: QuestionAnswerCalendarSnippet,
  Checkbox: QuestionAnswerCheckboxSnippet,
  Decimal: QuestionAnswerDecimalSnippet,
  DropDownList: QuestionAnswerDropDownListSnippet,
  Match: QuestionAnswerMatchSnippet,
  MultiResponseText: QuestionAnswerMultiResponseTextSnippet,
  MultiText: QuestionAnswerMultiLineTextSnippet,
  Number: QuestionAnswerNumberSnippet,
  Radio: QuestionAnswerRadioSnippet,
  Rating: QuestionAnswerRatingSnippet,
  SingleText: QuestionAnswerSingleLineTextSnippet,
  Slider: QuestionAnswerRangeInputSnippet,
});

const QuestionSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  formType = 'LIST',
  summaryFeedbackConfiguration,
  summaryFeedbackMode,
  userFlowStepTrackId,
  response,
  placeholders,
}: QuestionSnippetProps) => {
  const { state: contentPage } = useContentPageContext();

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);

  const { configuration, majorNumber, questionText = '', visible: isVisible } = contentSnippet ?? {};
  const { __typename: questionConfigurationTypeName } = configuration ?? {};

  const describedById = `question-snippet-id-${contentSnippetId}`;
  const questionContent = majorNumber ? `<span class="c-question-snippet__major">${majorNumber}</span>${questionText}` : questionText;

  const Component = getComponentByType(questionConfigurationTypeName);

  return (
    <div className="c-question-snippet">
      {isVisible && questionText && (
        <React.Fragment>
          {formType !== 'TABLE' && (
            <div className="c-question-snippet__question" id={describedById}>
              <DOMPurifySanitizedHtmlContent html={replacePlaceholderText(questionContent, placeholders)} />
            </div>
          )}

          <Component
            accessToken={accessToken}
            contentSnippetId={contentSnippetId}
            contentSnippetQuestion={contentSnippetQuestion}
            placeholders={placeholders}
            response={response}
            summaryFeedbackConfiguration={summaryFeedbackConfiguration}
            summaryFeedbackMode={summaryFeedbackMode}
            userFlowStepTrackId={userFlowStepTrackId}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export { QuestionSnippet };
