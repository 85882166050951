import gql from 'graphql-tag';

// TODO: set new menu query. This query is to heavy on the back end.
export const GET_LEFT_NAV_BAR_MENU = gql`
  query GetLeftNavBarMenu($contentGroupId: Int!) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        id
        title
        url
        contentPages(topLevelOnly: true, status: [PUBLISHED, UNPUBLISHED]) {
          id
          title
          url
          sort
          contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
            id
            title
            url
            sort
            contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
              id
              title
              url
              sort
              contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                id
                title
                url
                sort
                contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                  id
                  title
                  url
                  sort
                  contentPageChildren(status: [PUBLISHED, UNPUBLISHED]) {
                    id
                    title
                    url
                    sort
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
