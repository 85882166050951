/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';

import UpdatePassword from '../../containers/UpdatePassword/UpdatePassword';
import UpdateUser from '../../containers/UpdateUser/UpdateUser';

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNamePanel: false,
      openPasswordPanel: false,
    };
  }

  handleNamePanelOpen = () => {
    this.setState({
      openNamePanel: true,
      openPasswordPanel: false,
    });
  };

  onNamePanelClose = () => {
    this.setState({
      openNamePanel: false,
    });
  };

  handlePasswordPanelOpen = () => {
    this.setState({
      openNamePanel: false,
      openPasswordPanel: true,
    });
  };

  onPasswordPanelClose = () => {
    this.setState({
      openPasswordPanel: false,
    });
  };

  render() {
    const {
      id,
      firstName,
      lastName,
      secondaryEmail,
      mobilePhone,
      updateUserName,
      email,
    } = this.props;

    const { openPasswordPanel, openNamePanel } = this.state;

    return (
      <>
        <ul className="a-details-list">
          <li
            onClick={this.handleNamePanelOpen}
            onKeyPress={this.handleNamePanelOpen}
          >
            <div className="label">Name</div>
            <div className="data">{`${firstName} ${lastName}`}</div>
          </li>

          <li>
            <div className="label">Email</div>
            <div className="data">{email}</div>
          </li>

          <li
            onClick={this.handleNamePanelOpen}
            onKeyPress={this.handleNamePanelOpen}
          >
            <div className="label">Secondary Email</div>
            <div className="data">{secondaryEmail}</div>
          </li>

          <li
            onClick={this.handleNamePanelOpen}
            onKeyPress={this.handleNamePanelOpen}
          >
            <div className="label">Phone Number</div>
            <div className="data">{mobilePhone}</div>
          </li>

          <li
            onClick={this.handlePasswordPanelOpen}
            onKeyPress={this.handlePasswordPanelOpen}
          >
            <div className="label">Password</div>
            <input disabled type="password" value="123456789" />
          </li>
        </ul>

        <UpdateUser
          firstName={firstName}
          handleClosePanel={this.onNamePanelClose}
          id={id}
          isPanelOpen={openNamePanel}
          lastName={lastName}
          mobilePhone={mobilePhone}
          secondaryEmail={secondaryEmail}
          updateUserName={updateUserName}
        />

        <UpdatePassword
          handleClosePanel={this.onPasswordPanelClose}
          isPanelOpen={openPasswordPanel}
        />
      </>
    );
  }
}

AccountDetails.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  mobilePhone: PropTypes.string.isRequired,
  secondaryEmail: PropTypes.string.isRequired,
  updateUserName: PropTypes.func.isRequired,
  id: PropTypes.number,
};

AccountDetails.defaultProps = {
  id: null,
};

export default AccountDetails;
