import React, { useState } from 'react';

import { Moment } from 'moment';
import { SingleDatePicker as SingularDatePicker } from 'react-dates';

import '../DatePicker.css';
import { dateToMoment, momentToDate } from '../DatePicker.helpers';

import { SingleDatePickerProps } from './SingleDatePicker.types';

const SingleDatePicker = ({
  onChangeHandler,
  selectedDate,
  maxDate,
  minDate,
  id,
  isReadOnly,
  isDisabled,
  isRequired,
}: SingleDatePickerProps) => {
  // As this is component update is just temporary until we find a solution that doesn't require moment.js and to make this component backwards compatible we need to pass a date object as a prop and convert to moment object
  const [currentSavedDate, setCurrentSavedDate] = useState<Moment | null>(dateToMoment(selectedDate));
  const [isFocused, setIsFocused] = useState(false);
  const [disabledParameters] = useState({
    minDate: dateToMoment(minDate),
    maxDate: dateToMoment(maxDate),
  });
  const isOutsideRange = () => false; // attribute expects an expression rather than a boolean value.

  const onDateChange = (date: Moment | null) => {
    setCurrentSavedDate(date);
    // As this is component update is just temporary until we find a solution that doesn't require moment.js and to make this component backwards compatible we need to return a date object
    if (onChangeHandler) onChangeHandler(momentToDate(date));
  };

  return (
    <SingularDatePicker
      date={currentSavedDate}
      disabled={isDisabled}
      displayFormat="DD.MM.YY"
      focused={isFocused}
      id={id}
      isDayBlocked={(day: Moment) => {
        if (
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          (disabledParameters.minDate && day < disabledParameters.minDate) ||
          (disabledParameters.maxDate && day > disabledParameters.maxDate)
        )
          return true;
        return false;
      }}
      isOutsideRange={isOutsideRange}
      numberOfMonths={1}
      readOnly={isReadOnly}
      renderDayContents={(day: Moment) => <div className="c-date-range__day">{day.date()}</div>}
      required={isRequired}
      enableOutsideDays
      showClearDate
      showDefaultInputIcon
      small
      onClose={() => setIsFocused(false)}
      onDateChange={onDateChange}
      onFocusChange={() => setIsFocused(true)}
    />
  );
};

export { SingleDatePicker };
