import React, { useState, useRef, useEffect, useContext } from 'react';

import { TabSet } from '@netfront/ui-library';
import axios from 'axios';
import PropTypes from 'prop-types';

import Preloader from '../../../../legacy/components/Preloader/Preloader';
import AppContext from '../../../../legacy/context/AppContext';
import { useToast } from '../../../../legacy/hooks';
import { validURL } from '../../../../legacy/utils/utils';
import { SnippetTypeConst } from '../../../constants';
import { useCreateCodeAsset } from '../../../hooks/UseCreateCodeAsset/UseCreateCodeAsset';
import { useCreateCodeSnippet } from '../../../hooks/UseCreateCodeSnippet/UseCreateCodeSnippet';
import { useDeleteCodeAsset } from '../../../hooks/UseDeleteCodeAsset/UseDeleteCodeAsset';
import { useGetCodeAsset } from '../../../hooks/UseGetCodeAsset/UseGetCodeAsset';
import { useUpdateCodeSnippet } from '../../../hooks/UseUpdateCodeSnippet/UseUpdateCodeSnippet';
import { UpsertCodeSnippetGeneralTab } from '../../tabs';

const UpsertCodeSnippetSidebar = (props) => {
  const { containerId, onClose, onCreate, onRefreshPage, onDelete, onUpdate, snippet } = props;

  const { project } = useContext(AppContext);
  const { handleToastError, handleToastCustomError } = useToast();
  const htmlContentRef = useRef({ value: '' });
  const htmlFileRef = useRef({ value: null });
  const uploadedAssetIdRef = useRef({ value: null });

  const [initialHtmlAssetId, setInitialHtmlAssetId] = useState();
  const [initialHtmlContent, setInitialHtmlContent] = useState('');

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCreateCodeSnippetOnCompleted = ({ snippet: returnedSnippet }) => {
    const { id, sort } = returnedSnippet;

    onCreate(id, sort, SnippetTypeConst.CODE, containerId, {
      location: { assetId: uploadedAssetIdRef.current.value, contentType: 'application/json' },
    });
  };

  const { handleCreateCodeSnippet, isLoading: isCreateCodeSnippetLoading = false } = useCreateCodeSnippet({
    onCompleted: handleCreateCodeSnippetOnCompleted,
    onError: handleGetError,
  });

  const handleUpdateCodeSnippetOnCompleted = ({ snippet: returnedSnippet }) => {
    const { id } = returnedSnippet;

    onClose();

    onRefreshPage?.();

    onUpdate?.(id, snippet.sort, SnippetTypeConst.CODE, containerId, {
      location: { assetId: uploadedAssetIdRef.current.value, contentType: 'application/json' },
    });
  };

  const { handleUpdateCodeSnippet, isLoading: isUpdateCodeSnippetLoading = false } = useUpdateCodeSnippet({
    onCompleted: handleUpdateCodeSnippetOnCompleted,
    onError: handleGetError,
  });

  const handleGetCodeAssetOnCompleted = ({ returnedAsset: { presignedUrl } }) => {
    axios
      .get(presignedUrl)
      .then((res) => {
        setInitialHtmlAssetId(snippet.location.assetId);
        setInitialHtmlContent(res.data);
        htmlContentRef.current.value = res.data;
      })
      .catch((error) => {
        handleToastCustomError({
          message: 'whoops! something went wrong',
        });
        // eslint-disable-next-line no-console
        console.log({ error });
      });
  };

  const { handleGetCodeAsset, isLoading: isGetCodeAssetLoading = false } = useGetCodeAsset({
    onCompleted: handleGetCodeAssetOnCompleted,
    onError: handleGetError,
  });

  const { handleDeleteCodeAsset, isLoading: isDeleteCodeAssetLoading = false } = useDeleteCodeAsset({
    onError: handleGetError,
  });

  const handleCreateCodeAssetOnCompleted = ({ returnedAsset: { signedUrl, asset } }) => {
    Object.defineProperty(htmlFileRef.current.value, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    axios
      .put(signedUrl, htmlFileRef.current.value, { headers: { 'content-type': String(htmlFileRef.current.value.type) } })
      .then(() => {
        uploadedAssetIdRef.current.value = asset.assetId;
        // save or update snippet
        if (!snippet) {
          handleCreateCodeSnippet({
            containerId,
            isVisible: true,
            locationId: asset.assetId,
          });
        } else {
          handleUpdateCodeSnippet({
            locationId: asset.assetId,
            snippetId: Number(snippet?.id),
          });
        }

        if (initialHtmlAssetId) {
          handleDeleteCodeAsset({
            assetId: initialHtmlAssetId,
          });
        }
      })
      .catch((error) => {
        handleToastCustomError({
          message: 'whoops! something went wrong',
        });
        // eslint-disable-next-line no-console
        console.log({ error });
      });
  };

  const { handleCreateCodeAsset, isLoading: isCreateCodeAssetLoading = false } = useCreateCodeAsset({
    onCompleted: handleCreateCodeAssetOnCompleted,
    onError: handleGetError,
  });

  const handleChange = (value) => {
    htmlContentRef.current.value = value;
  };

  const hasValidSource = () => {
    const contentWrapper = document.createElement('div');
    contentWrapper.innerHTML = htmlContentRef.current.value;
    const contentIframe = contentWrapper.querySelector('iframe');

    return contentIframe == null ? true : validURL(contentIframe.src);
  };

  const handleSave = () => {
    if (!hasValidSource()) {
      return;
    }

    const fileHtml = new File([htmlContentRef.current.value], 'code.json', {
      type: 'application/json',
      lastModified: new Date(0),
    });

    htmlFileRef.current.value = fileHtml;

    handleCreateCodeAsset({
      projectId: String(project.id),
      type: 'CODE',
      contentType: fileHtml.type,
      fileName: fileHtml.name,
      fileSizeInBytes: fileHtml.size,
      alt: 'asset',
    });
  };

  useEffect(() => {
    if (!snippet) {
      htmlContentRef.current.value = '';
    } else {
      handleGetCodeAsset({
        assetId: snippet.location.assetId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  const isLoading =
    isCreateCodeSnippetLoading ||
    isUpdateCodeSnippetLoading ||
    isGetCodeAssetLoading ||
    isCreateCodeAssetLoading ||
    isDeleteCodeAssetLoading;
  return (
    <>
      {isLoading && <Preloader />}
      <TabSet
        defaultActiveTabId="id_general_tab"
        tabs={[
          {
            iconId: 'id_general_tab_icon',
            id: 'id_general_tab',
            label: 'General',
            view: () => (
              <UpsertCodeSnippetGeneralTab
                initialHtmlContent={initialHtmlContent}
                snippet={snippet}
                onChange={handleChange}
                onClose={onClose}
                onDelete={onDelete}
                onSave={handleSave}
              />
            ),
          },
        ]}
      />
    </>
  );
};

UpsertCodeSnippetSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  containerId: PropTypes.number,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  snippet: PropTypes.shape(),
};

export { UpsertCodeSnippetSidebar };
