import gql from 'graphql-tag';

export const UPDATE_GROUP = gql`
  mutation updateGroup($group: UpdateGroupInputType!, $address: AddressInputType) {
    group {
      updateGroup(group: $group, address: $address) {
        address {
          city
          country
          line1
          line2
          postCode
          state
        }
        phoneNumber
        website
      }
    }
  }
`;
