import React, { useEffect, useState } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { useParams } from 'react-router-dom';

import TopicsTable from '../../components/Social/Topics/Tables/TopicsTable';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import SocialPageState from '../../context/socialPage/SocialPageState';
import { useNewEkardoSiteUrls } from '../../hooks';

const TopicManagementPage = () => {
  const params = useParams();
  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/social`,
        title: 'Social',
        isExternal: true,
      },
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/social/topics`,
        title: 'Topics',
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <SocialPageState>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage social topics"
        pageDescription="Manage social topics"
        pageTitle="Social topics"
        params={params}
      >
        <TopicsTable />
      </PageWrapper>
    </SocialPageState>
  );
};

export default TopicManagementPage;
