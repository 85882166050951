import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const RATE_MYAPP = gql`
  mutation deleteRate($rateId: Int!) {
    app {
      deleteRate(rateId: $rateId)
    }
  }
`;

const deleteRateApp = ({ rateId, onError, onSuccessResult }) => (
  <Mutation mutation={RATE_MYAPP}>
    {(deleteRate, { loading, error, data }) => {
      const variables = {
        rateId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.app.deleteRate);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={deleteRate}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

deleteRateApp.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  rateId: PropTypes.number.isRequired,
};

deleteRateApp.defaultProps = {};

export default deleteRateApp;
