/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon/Icon';
import ImageMapRegionSideBar from '../RightNavBar/ImageMapRegionSideBar';
import { Preview } from '../RightNavBar/styled';
import Backdrop from '../UI/Backdrop/Backdrop';

import ImageMap from './ImageMap';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ImageMapCreation = props => {
  const { match, imageMapDetail, onUpdate } = props;

  const [sideBarDetails, setSideBarDetails] = useState({
    isActive: false,
    region: { id: 0 },
  });

  const [mapAreas, setMapAreas] = useState([]);

  useEffect(() => {
    setMapAreas(imageMapDetail.regions);
  }, [imageMapDetail]);

  const handleAddRegion = e => {
    const rect = e.target.parentElement.getBoundingClientRect();
    setSideBarDetails({
      isActive: true,
      region: {
        ...sideBarDetails.region,
        id: 0,
        name: '',
        x: Math.round(e.clientX - rect.left),
        y: Math.round(e.clientY - rect.top),
      },
    });
  };

  const updateSelectedRegion = id => {
    setSideBarDetails({
      isActive: true,
      region: mapAreas.find(region => region.id === id),
    });
  };

  const updateSelectedRegionField = (type, value) => {
    setSideBarDetails({
      isActive: true,
      region: {
        ...sideBarDetails.region,
        [type]: value,
      },
    });
  };

  const removeRegion = () => {
    onUpdate();
    setSideBarDetails({
      isActive: false,
      region: {
        id: 0,
      },
    });
  };

  const updateRegion = () => {
    onUpdate();
    setSideBarDetails({
      isActive: false,
      region: {
        id: 0,
      },
    });
  };

  const regions = [...mapAreas];
  if (sideBarDetails.region && sideBarDetails.region.x) {
    regions.push({ ...sideBarDetails.region, isTemp: true });
  }

  return (
    <>
      <Wrapper>
        {sideBarDetails.isActive && (
          <div>
            <ImageMapRegionSideBar
              closeSideBar={() =>
                setSideBarDetails({ isActive: false, region: { id: 0 } })
              }
              detail={sideBarDetails.region}
              imageMapId={Number(match.params.imageMapId)}
              onChangeRegion={updateSelectedRegionField}
              onDelete={removeRegion}
              onUpdate={updateRegion}
            />
            <Backdrop
              isClicked={() =>
                setSideBarDetails({ isActive: false, region: { id: 0 } })
              }
            />
          </div>
        )}
        <ImageMap
          imageMapDetail={imageMapDetail}
          isInteractive
          onImageClick={handleAddRegion}
          onRegionClick={updateSelectedRegion}
          regions={regions}
        />
      </Wrapper>
    </>
  );
};

ImageMapCreation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  imageMapDetail: PropTypes.shape({
    iconAsset: PropTypes.shape({
      alt: PropTypes.string,
      presignedUrl: PropTypes.string,
    }),
    iconAssetId: PropTypes.string,
    id: PropTypes.number,
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        alternativeImageId: PropTypes.string,
        contentGroupId: PropTypes.number,
        contentPage: PropTypes.shape({
          id: PropTypes.number,
        }),
        name: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
      })
    ),
    title: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      containerId: PropTypes.string,
      groupId: PropTypes.string,
      imageMapId: PropTypes.string,
      projectId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

ImageMapCreation.defaultProps = {};

export default ImageMapCreation;
