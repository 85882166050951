import { IMessage } from '../Snippets.interfaces';

const DEFAULT_API_ERROR_MESSAGE = 'Something went wrong - please try again later!';

const DEFAULT_API_SUCCESS_MESSAGE = 'Saved!';

const DEFAULT_MESSAGE: IMessage = {
  error: '',
  success: '',
};

export { DEFAULT_API_ERROR_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_MESSAGE };
