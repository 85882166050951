import gql from 'graphql-tag';

export const UPDATE_CONTENT_GROUP_STATUS = gql`
  mutation UpdateContentGroup($contentGroupId: Int!, $status: EContentGroupStatus!) {
    contentGroup {
      updateContentGroupStatus(id: $contentGroupId, status: $status) {
        id
      }
    }
  }
`;
