import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_GROUP = gql`
  mutation createGroup($projectId: String!, $name: String!, $description: String!, $userFlowId: Int) {
    group {
      createGroup(group: { projectId: $projectId, name: $name, description: $description, userFlowId: $userFlowId }) {
        id
        name
        identifier
        status
        userFlowId
        createdByUserId
        creator: createdByUser {
          firstname
          lastname
          communityName
          credential {
            phoneNumber
            secondaryEmail
            email
          }
        }
      }
    }
  }
`;

const createGroupRequest = ({ projectId, name, description, userFlowId, onError, onSuccessResult }) => (
  <Mutation client={Client()} mutation={CREATE_GROUP}>
    {(createGroup, { loading, error, data }) => {
      const variables = {
        projectId,
        name,
        description,
        userFlowId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.group.createGroup);
      }

      return <MutationRequest isLoading={loading} mutation={createGroup} variables={variables} />;
    }}
  </Mutation>
);

createGroupRequest.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  userFlowId: PropTypes.number,
};

createGroupRequest.defaultProps = {
  userFlowId: null,
};

export default createGroupRequest;
