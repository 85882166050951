import React, { Fragment, useEffect, useState } from 'react';

import { Button, ButtonIconOnly, Input } from '@netfront/ui-library';
import { useCreateSummaryFeedbackMultiResponseToMultiResponse } from 'hooks';

import { ISummaryFeedbackConfigurationMultiResponseProps } from './SummaryFeedbackConfigurationMultiResponse.interfaces';

import {
  IDBMultiResponseCategoryType,
  IDBQuestionAnswerMultiResponseTextItem,
  IDBSummaryFeedbackConfigurationMultiResponse,
  IDBSummaryFeedbackMultiResponseAnswer,
} from '../../../../interfaces';
import { getHtmlElementOnChangeValueFromEvent } from '../../../Snippets/Snippets.helpers';

interface IEditableFieldProps {
  onValueDeleted: () => void;
  onValueEdited: (value: string) => void;
  value: string;
}

const EditableField = ({ onValueEdited, value, onValueDeleted }: IEditableFieldProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onCancelClick = () => {
    setIsEdit(false);
    setCurrentValue(value);
  };
  return (
    <div className="c-summary-feedback-multi-response__editiable-field">
      <div className="c-summary-feedback-multi-response__input">
        <Input
          id="12"
          isDisabled={!isEdit}
          labelText=""
          name=""
          type="text"
          value={currentValue}
          onChange={(event) => {
            const updatedValue = getHtmlElementOnChangeValueFromEvent(event);
            setCurrentValue(updatedValue);
          }}
        />
        {!isEdit && (
          <div className="c-summary-feedback-multi-response__controls">
            <ButtonIconOnly
              additionalClassNames="c-summary-feedback-multi-response__controls--edit"
              iconId="id_edit_icon"
              text="Edit"
              onClick={() => setIsEdit(true)}
            />
            <ButtonIconOnly
              additionalClassNames="c-summary-feedback-multi-response__controls--delete"
              iconId="id_bin_icon"
              text="Delete"
              onClick={() => onValueDeleted()}
            />
          </div>
        )}

        {isEdit && (
          <div className="c-summary-feedback-multi-response__controls">
            <ButtonIconOnly
              additionalClassNames="c-summary-feedback-multi-response__controls--validate"
              iconId="id_tick_icon"
              text="Validate"
              onClick={() => {
                onValueEdited(currentValue);
                setIsEdit(false);
              }}
            />
            <ButtonIconOnly
              additionalClassNames="c-summary-feedback-multi-response__controls--close"
              iconId="id_close_icon"
              text="Cancel"
              onClick={onCancelClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface IMultiResponseProps {
  categories: IDBMultiResponseCategoryType[];
  contentSnippetSummaryId: number;
  questionAnswerId: number;
  response: IDBQuestionAnswerMultiResponseTextItem;
}

interface INewResponseComponentProps {
  onValueAdded: (value: string) => void;
}

const NewResponseComponent = ({ onValueAdded }: INewResponseComponentProps) => {
  const [newResponse, setNewResponse] = useState<string>('');
  const onAddClick = () => {
    if (!newResponse.trim()) return;
    onValueAdded(newResponse);
    setNewResponse('');
  };
  return (
    <div className="c-summary-feedback-multi-response__entry">
      <Input
        id="add-multi-response-entry"
        labelText="Add response"
        name="add-multi-response-entry"
        placeholder="Add response"
        type="text"
        value={newResponse}
        isLabelHidden
        onChange={(event) => {
          const updatedValue = getHtmlElementOnChangeValueFromEvent(event);
          setNewResponse(updatedValue);
        }}
      />
      <Button text="Add" variant="secondary" onClick={onAddClick} />
    </div>
  );
};

const MultiResponseSummary = ({ response, categories, contentSnippetSummaryId, questionAnswerId }: IMultiResponseProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentResponses, setCurrentResponses] = useState<IDBSummaryFeedbackMultiResponseAnswer[]>();

  const onCompleted = () => {};
  const { handleCreateSummaryFeedbackMultiResponseToMultiResponse } = useCreateSummaryFeedbackMultiResponseToMultiResponse({ onCompleted });

  useEffect(() => {
    const mappedAnswers = response.summaryFeedback?.answers.map((r) => {
      const { multiResponseCategoryId, values } = r;
      const c: IDBSummaryFeedbackMultiResponseAnswer = { multiResponseCategoryId, values };
      return c;
    });
    setCurrentResponses(mappedAnswers ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentResponses) return;
    if (isInitialized) {
      handleCreateSummaryFeedbackMultiResponseToMultiResponse({
        contentSnippetSummaryId,
        questionAnswerId,
        responseId: response.id,
        values: currentResponses,
      });
      // code à exécuter après la mise à jour de l'état
    } else {
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResponses]);

  const getResponses = (categoryId: number) => {
    return currentResponses
      ?.filter((r) => r.multiResponseCategoryId === categoryId)
      .map((r) => r.values)
      .flat();
  };

  function removeValueFromResponse(categoryId: number, index: number) {
    setCurrentResponses((prevResponses) => {
      return prevResponses?.map((r) => {
        if (r.multiResponseCategoryId === categoryId) {
          const updatedValues = r.values.filter((value, i) => i !== index);
          return { ...r, values: updatedValues };
        }
        return r;
      });
    });
  }

  function onValueUpdated(index: number, value: string, categoryId: number) {
    setCurrentResponses((prevResponses) => {
      const updatedResponses = prevResponses?.map((r) => {
        if (r.multiResponseCategoryId === categoryId) {
          const updatedValues = [...r.values];
          updatedValues[index] = value;
          return { ...r, values: updatedValues };
        }
        return r;
      });
      return updatedResponses;
    });
  }

  function onValueAdded(value: string, categoryId: number) {
    setCurrentResponses((prevResponses) => {
      if (!prevResponses) return [];
      const updatedResponses = [...prevResponses];
      const responseIndex = updatedResponses.findIndex((r) => r.multiResponseCategoryId === categoryId);
      if (responseIndex !== -1) {
        const updatedValues = [...updatedResponses[responseIndex].values, value];
        const updatedResponse = { ...updatedResponses[responseIndex], values: updatedValues };
        updatedResponses[responseIndex] = updatedResponse;
      } else {
        updatedResponses.push({ multiResponseCategoryId: categoryId, values: [value] });
      }
      return updatedResponses;
    });
  }

  return (
    <div className="c-summary-feedback-multi-response">
      <div className="c-summary-feedback-multi-response__summary">{response.value}</div>
      <div className="c-summary-feedback-multi-response__container">
        {categories.map((category) => (
          <div key={`category-${category.id}`} className="c-summary-feedback-multi-response__category">
            <div className="c-summary-feedback-multi-response__category-head">{category.label}</div>
            {getResponses(category.id)?.map((r, i) => (
              <div key={`response-${category.id}`}>
                <EditableField
                  value={String(r)}
                  onValueDeleted={() => removeValueFromResponse(category.id, i)}
                  onValueEdited={(value) => onValueUpdated(i, value, category.id)}
                />
              </div>
            ))}
            <NewResponseComponent
              onValueAdded={(value) => {
                onValueAdded(value, category.id);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const SummaryFeedbackConfigurationMultiResponse = ({
  responses = [],
  summaryFeedbackConfiguration,
  questionAnswerId,
  contentSnippetSummaryId,
}: ISummaryFeedbackConfigurationMultiResponseProps) => {
  const { categories } = summaryFeedbackConfiguration as IDBSummaryFeedbackConfigurationMultiResponse;

  return (
    <Fragment>
      {responses.map((r) => (
        <div key={`response-${r.id}`}>
          <MultiResponseSummary
            categories={categories}
            contentSnippetSummaryId={contentSnippetSummaryId}
            questionAnswerId={questionAnswerId}
            response={r}
          />
        </div>
      ))}
    </Fragment>
  );
};

export { SummaryFeedbackConfigurationMultiResponse };
