import { gql } from '@apollo/client';

export const ADD_FEEDBACK_RADIO_ON_CHECKBOX = gql`
  mutation AddFeedbackRadioOnCheckbox($questionAnswerId: Int!, $radioResponseId: Int!, $snippetSummaryId: Int!, $checkboxResponseId: Int!) {
    summaryFeedback {
      addFeedbackRadioCheckbox(
        questionAnswerId: $questionAnswerId
        radioResponseId: $radioResponseId
        contentSnippetSummaryId: $snippetSummaryId
        checkboxResponseId: $checkboxResponseId
      )
    }
  }
`;
