import { useRef } from 'react';

import { OperationVariables, useLazyQuery } from '@apollo/client';

import { IUseApolloLazyQueryOptions } from './useApolloLazyQuery.interfaces';

import { getApolloClient } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useApolloLazyQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>({
  apiName,
  apolloLink,
  options,
  query,
  requestCredentials,
}: IUseApolloLazyQueryOptions<TData, TVariables>) => {
  const client = useRef(
    getApolloClient({
      apiName,
      apolloLink,
      requestCredentials,
    }),
  ).current;

  return useLazyQuery<TData, TVariables>(query, {
    ...options,
    client,
  });
};

export { useApolloLazyQuery };
