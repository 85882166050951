import React, { useContext, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styled from 'styled-components';

import ValidationProcessor from '../ErrorMessage/ValidationProcessor';
import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import DropdownButton from '../UI/Button/ShowHideButton';
import SwitchBtn from '../UI/Button/SwitchButton';
import ColorInput from '../UI/ColorPicker/ColorInput';
import EditableTag from '../UI/Tag/EditableTag';
import Tooltip from '../UI/Tooltip/Tooltips';

import { SnippetTypeConst } from './SnippetTypeConst';
import { AdvanceOpt, AdvanceOptTitle, AdvanceOptTitleBox, AdvanceTitle, AdvanceWrapper } from './styled';

import { errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import AppContext from '../../context/AppContext';
import client from '../../middleware/client';
import { SEARCH_PAGE } from '../../middleware/ContentPage/searhContentPages';
import CreateButtonContentSnippetRequest from '../../middleware/ContentSnippet/createButtonContentSnippetRequest';
import UpdateButtonContentSnippetRequest from '../../middleware/ContentSnippet/updateButtonContentSnippetRequest';

const StyleSelect = styled.select`
  border: #e4e4e3 1px solid;
  border-radius: 5px;
  height: 2.6rem;
  outline: none;
  padding: 0 0.8rem;

  &:hover {
    border-color: #44bed3;
  }
`;

const AdvanceItem = styled.div`
  margin: 1rem auto;
  width: 100%;
`;

const Search = styled.input`
  background-color: unset;
  border: none;
  height: 3rem;
  width: 100%;
`;

const TagInput = styled.div`
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  margin: 0.2rem 0;
  position: relative;
`;

const SearchSuggetionsBox = styled.div`
  display: flex;
  flex-flow: column;
  height: fit-content;
  justify-content: flex-start;
  max-height: 20rem;
  overflow-y: auto;
`;

const SearchSuggetionsRow = styled.button`
  background: none;
  border: 1px solid #e1e1e1;
  padding: 10px;
  text-align: left;

  &:hover {
    background-color: #e8e8e8;
  }
`;

function EditableButtonSnippet(props) {
  const { containerId, onCancel, onCreate, onUpdate, snippet } = props;

  const LIGHT_BLUE = '#4bc2ca';

  const DEFAULT_BORDER_COLOR = LIGHT_BLUE;
  const DEFAULT_BUTTON_BACKGROUND_COLOR = LIGHT_BLUE;
  const DEFAULT_FONT_COLOR = '#fff';
  const DEFAULT_HOVER_COLOR = LIGHT_BLUE;
  const DEFAULT_STYLE = 'MEDIUM';
  const DEFAULT_TARGET = '_self';

  const {
    borderColor,
    buttonBackgroundColor,
    fontColor,
    hoverColor,
    hyperlink,
    innerText,
    page: contentPage,
    style,
    target,
  } = snippet || {};

  const { id: contentPageId, title: contentPageTitle } = contentPage || {};
  const { project, contentGroup } = useContext(AppContext);

  const { title: contentGroupTitle, type: contentGroupType } = contentGroup || {};

  const defaultAdvanceStyles = {
    borderColor: borderColor || DEFAULT_BORDER_COLOR,
    buttonBackgroundColor: buttonBackgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR,
    fontColor: fontColor || DEFAULT_FONT_COLOR,
    hoverColor: hoverColor || DEFAULT_HOVER_COLOR,
    style: style || DEFAULT_STYLE,
    target: target || DEFAULT_TARGET,
  };

  const [advanceStyles, setAdvanceStyles] = useState(defaultAdvanceStyles);
  const [decodedHyperlink, setDecodedHyperlink] = useState(hyperlink ? decodeURIComponent(hyperlink) : '');
  const [buttonText, setButtonText] = useState(innerText);

  const [createSnippet, setCreateSnippet] = useState(false);
  const [isInternalLink, setIsInternalLink] = useState(contentPage);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [searchTitle, setSearchTitle] = useState('');

  const [searchedPages, setSearchedPages] = useState([]);

  const [linkedPage, setLinkedPage] = useState(
    contentPage
      ? {
          id: contentPageId,
          groupTitle: contentGroupTitle,
          pageTitle: contentPageTitle,
          type: contentGroupType,
        }
      : null,
  );

  const [sendQuery] = useLazyQuery(SEARCH_PAGE, {
    client,
    onCompleted: (res) => {
      const tmp = res.contentPage.search.edges.map((item) => ({
        id: item.node.id,
        groupTitle: item.node.contentGroup.title,
        pageTitle: item.node.title,
        type: item.node.contentGroup.type,
      }));
      setSearchedPages(tmp);
    },
    onError: (err) => {
      setErrorMessage(err.networkError.result.errors[0].message);
      setInterval(() => {
        setErrorMessage('');
      }, 3000);
    },
    fetchPolicy: 'cache-and-network',
  });

  const validate = () => ({
    NameEmpty: {
      validation: buttonText.trim().length > 0,
      errorMessage: 'Title must not be empty',
    },
    // !!!!!!!!!!!!!!!!!!! we need this later!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // URLEmpty: {
    //   validation: validURL(hyperlink.trim()),
    //   errorMessage: 'Please enter valid url',
    // },
  });

  const handleSaveButton = (e) => {
    e.preventDefault();

    const validationErrors = ValidationProcessor(validate());

    if (!validationErrors.modelValid) {
      setErrorMessage(validationErrors.validations[0].props.children);
      setInterval(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    setCreateSnippet(true);
  };

  const handleSnippetError = () => {
    setCreateSnippet(false);
  };

  const onChangeInnerText = (e) => {
    setButtonText(e.target.value);
  };

  const onChangeHyperLink = (e) => {
    setDecodedHyperlink(e.target.value);
  };

  const handleSnippetResponse = (id, sort) => {
    const returnSnippet = {
      ...advanceStyles,
      ...snippet,
      hyperlink: encodeURIComponent(decodedHyperlink),
      innerText: buttonText,
    };

    if (linkedPage) {
      returnSnippet.hyperlink = '';
      returnSnippet.page = {
        contentGroup: {
          title: linkedPage.groupTitle,
          type: linkedPage.type,
        },
        id: linkedPage.id,
        title: linkedPage.pageTitle,
      };
    } else {
      returnSnippet.page = null;
    }

    if (snippet) {
      onUpdate(id, snippet.sort, SnippetTypeConst.BUTTON, containerId, returnSnippet);
    } else {
      onCreate(id, sort, SnippetTypeConst.BUTTON, containerId, returnSnippet);
    }

    setCreateSnippet(false);
  };

  const handleStyle = (e) => {
    setAdvanceStyles({
      ...advanceStyles,
      [e.target.name]: e.target.value,
    });
  };

  const handleColorUpdate = (value, e) => {
    setAdvanceStyles({
      ...advanceStyles,
      [e.target.name]: value,
    });
  };

  const handleSearchInput = (e) => {
    setSearchTitle(e.target.value);
    sendQuery({
      variables: {
        after: '',
        contentGroupId: contentGroup.id,
        containsExactMessage: true,
        first: 50,
        message: e.target.value,
        projectId: project.id,
      },
    });
  };

  const handleLinkedPage = (page) => {
    setLinkedPage(page);
    setSearchedPages([]);
    setSearchTitle('');
  };

  const handleInternalLinkChange = () => {
    setIsInternalLink(!isInternalLink);
    setLinkedPage(null);
  };

  return (
    <>
      <div>
        <label className="text-left d-flex flex-column mb-4">
          <div className="d-flex align-items-center mb-2">
            <strong>Text</strong> <Tooltip />
          </div>
          <input aria-label="Text" className="w-100 bg-white border" onChange={onChangeInnerText} type="text" value={buttonText} />
        </label>
        <AdvanceItem>
          <div className="d-flex align-items-center mb-2">
            <strong>Internal link</strong>
            <Tooltip text="Internal linking: If switched on you can search and link to pages within your project, updates to the content URL will not break this link. If switched off you can add external absolute links " />
          </div>
          <SwitchBtn defaultChecked={isInternalLink} id={0} name="linkType" onChange={handleInternalLinkChange} />
        </AdvanceItem>
        {!isInternalLink ? (
          <label className="text-left d-flex flex-column">
            <input
              aria-label="Text"
              className="w-100 bg-white border"
              onChange={onChangeHyperLink}
              placeholder="Link e.g. https://google.com"
              type="text"
              value={decodedHyperlink}
            />
          </label>
        ) : (
          <AdvanceItem>
            {!linkedPage ? (
              <TagInput>
                <Search
                  autoComplete="off"
                  name="searchTitle"
                  onChange={(e) => handleSearchInput(e)}
                  placeholder="Search pages "
                  type="text"
                  value={searchTitle}
                />
                <SearchSuggetionsBox>
                  {searchedPages.map((p) => (
                    <SearchSuggetionsRow key={p.id} onClick={() => handleLinkedPage(p)}>
                      {p.type}: {p.groupTitle} - {p.pageTitle}
                    </SearchSuggetionsRow>
                  ))}
                </SearchSuggetionsBox>
              </TagInput>
            ) : (
              <EditableTag groupTitle={linkedPage.groupTitle} onDelete={() => handleLinkedPage(null)} title={linkedPage.pageTitle} />
            )}
          </AdvanceItem>
        )}
        <AdvanceTitle>
          <b style={{ marginRight: '1rem' }}>Advance</b>
          <DropdownButton
            direction="bottom"
            isClicked={() => {
              setShowAdvancedOptions(!showAdvancedOptions);
            }}
          />
        </AdvanceTitle>
        {showAdvancedOptions && (
          <AdvanceWrapper>
            <AdvanceOpt>
              <AdvanceOptTitleBox>
                <AdvanceOptTitle>Style</AdvanceOptTitle>
                <Tooltip />
              </AdvanceOptTitleBox>
              <StyleSelect defaultValue={advanceStyles.style} name="style" onChange={handleStyle}>
                <option value="SMALL">Small</option>
                <option value="MEDIUM">Medium</option>
                <option value="LARGE">Large</option>
              </StyleSelect>
            </AdvanceOpt>
            <AdvanceOpt>
              <AdvanceOptTitleBox>
                <AdvanceOptTitle>Target</AdvanceOptTitle>
                <Tooltip />
              </AdvanceOptTitleBox>
              <StyleSelect defaultValue={advanceStyles.target} name="target" onChange={handleStyle}>
                <option value="_blank">Blank</option>
                <option value="_self">Self</option>
              </StyleSelect>
            </AdvanceOpt>
            <AdvanceOpt>
              <AdvanceOptTitleBox>
                <AdvanceOptTitle>Font color</AdvanceOptTitle>
                <Tooltip />
              </AdvanceOptTitleBox>
              <ColorInput currentColor={advanceStyles.fontColor} getColor={handleColorUpdate} id="color-1" key="color-1" name="fontColor" />
            </AdvanceOpt>
            <AdvanceOpt>
              <AdvanceOptTitleBox>
                <AdvanceOptTitle>Background color</AdvanceOptTitle>
                <Tooltip />
              </AdvanceOptTitleBox>
              <ColorInput
                currentColor={advanceStyles.buttonBackgroundColor}
                getColor={handleColorUpdate}
                id="color-2"
                key="color-2"
                name="buttonBackgroundColor"
              />
            </AdvanceOpt>
            <AdvanceOpt>
              <AdvanceOptTitleBox>
                <AdvanceOptTitle>Hover color</AdvanceOptTitle>
                <Tooltip />
              </AdvanceOptTitleBox>
              <ColorInput
                currentColor={advanceStyles.hoverColor}
                getColor={handleColorUpdate}
                id="color-3"
                key="color-3"
                name="hoverColor"
              />
            </AdvanceOpt>
            <AdvanceOpt>
              <AdvanceOptTitleBox>
                <AdvanceOptTitle>Border color</AdvanceOptTitle>
                <Tooltip />
              </AdvanceOptTitleBox>
              <ColorInput
                currentColor={advanceStyles.borderColor}
                getColor={handleColorUpdate}
                id="color-3"
                key="color-3"
                name="borderColor"
              />
            </AdvanceOpt>
          </AdvanceWrapper>
        )}
        <div className="text-right">
          <button className="button--black mt-4 mr-3" onClick={onCancel} type="button">
            Cancel
          </button>
          <button className="button--blue" onClick={handleSaveButton} type="submit">
            Save
          </button>
        </div>
      </div>
      <ReactCSSTransitionGroup
        transitionEnterTimeout={errorMessageEnterTimeout}
        transitionLeaveTimeout={errorMessageLeaveTimeout}
        transitionName="dialog-popup"
      >
        {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
      </ReactCSSTransitionGroup>
      {createSnippet && !snippet && (
        <CreateButtonContentSnippetRequest
          borderColor={advanceStyles.borderColor}
          buttonBackgroundColor={advanceStyles.buttonBackgroundColor}
          containerId={Number(containerId)}
          fontColor={advanceStyles.fontColor}
          hoverColor={advanceStyles.hoverColor}
          hyperlink={decodedHyperlink ? encodeURIComponent(decodedHyperlink) : ''}
          innerText={buttonText}
          isVisible
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          pageId={linkedPage ? linkedPage.id : undefined}
          // eslint-disable-next-line react/style-prop-object
          style={advanceStyles.style}
          target={advanceStyles.target}
        />
      )}
      {createSnippet && snippet && (
        <UpdateButtonContentSnippetRequest
          borderColor={advanceStyles.borderColor}
          buttonBackgroundColor={advanceStyles.buttonBackgroundColor}
          fontColor={advanceStyles.fontColor}
          hoverColor={advanceStyles.hoverColor}
          hyperlink={decodedHyperlink ? encodeURIComponent(decodedHyperlink) : ''}
          innerText={buttonText}
          isVisible
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          pageId={linkedPage ? linkedPage.id : undefined}
          snippetId={snippet.id}
          // eslint-disable-next-line react/style-prop-object
          style={advanceStyles.style}
          target={advanceStyles.target}
        />
      )}
    </>
  );
}

EditableButtonSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape({
    borderColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    hoverColor: PropTypes.string,
    hyperlink: PropTypes.string,
    id: PropTypes.number,
    innerText: PropTypes.string,
    page: PropTypes.shape(),
    sort: PropTypes.number,
    style: PropTypes.string,
    target: PropTypes.string,
  }),
};

EditableButtonSnippet.defaultProps = {
  snippet: null,
};

export default EditableButtonSnippet;
