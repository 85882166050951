import React, { useState, useContext, useEffect } from 'react';

import { Select, Spacing, InformationBox, CheckboxGroup, NavigationButton } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { QuestionPreview } from '../QuestionPreview';

import { getDataTypeFormat } from './QuestionSelector.helpers';

import ButtonGroup from '../../../legacy/components/ButtonGroup/ButtonGroup';
import Preloader from '../../../legacy/components/Preloader/Preloader';
import AppContext from '../../../legacy/context/AppContext';
import { useToast } from '../../../legacy/hooks';
import { useGetQuestions, useGetProjectSteps } from '../../hooks';
import { getQuestionText, generateRandomId, sortObjectArrayAlphabetically } from '../../utils';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;
const QuestionSelector = ({
  onClose,
  onUpdateQuestion,
  onUpdateQuestionWithName,
  onUpdateQuestionWithConfig,
  onUpdateMultiQuestion,
  onUpdateMultiQuestionWithNames,
  onUpdateMultiQuestionWithConfig,
  initialQuestionFormId,
  onBackText,
  onUpdateModule,
  onSave,
  initialUserFlowStepId,
  initialQuestionId,
  hasDelete,
  onDelete,
  informationMessage,
  initialQuestionFormIds,
  isMultiQuestionSelector,
  hasPreview = true,
  hasButtons = true,
  type = 'general',
}) => {
  const { project } = useContext(AppContext);
  const { handleToastError } = useToast();
  const [questionOptions, setQuestionOptions] = useState([]);
  const [questionConfigs, setQuestionConfigs] = useState([]);
  const [userFlowStepOptions, setUserFlowStepOptions] = useState([]);
  const [selectedMultipleQuestionIds, setSelectedMultipleQuestionIds] = useState(initialQuestionFormIds ?? []);
  const [selectedQuestionId, setSelectedQuestionId] = useState(initialQuestionId ? String(initialQuestionId) : undefined);
  const [selectedUserFlowStepId, setSelectedUserFlowStepId] = useState(initialUserFlowStepId ? String(initialUserFlowStepId) : undefined);
  const [selectedQuestionFormId, setSelectedQuestionFormId] = useState(initialQuestionFormId);

  const { handleGetQuestions, isLoading: isGetQuestionsLoading = false } = useGetQuestions({
    onCompleted: ({ groupedContentSnippetQuestions }) => {
      const configs = [];
      const newQuestionOptions = groupedContentSnippetQuestions.flatMap(({ contentSnippetFormId, questions: contentSnippetQuestions }) =>
        contentSnippetQuestions.map((contentSnippetQuestion) => {
          const { id, question } = contentSnippetQuestion;
          const label = getQuestionText(question, 'text/html') || `Question-${id}`;
          configs.push(contentSnippetQuestion);
          return {
            id: `${generateRandomId()}-${id}`,
            initialId: id,
            name: `ID: ${id} - Question label: ${label.substring(0, 30)}`,
            value: `${contentSnippetFormId}-${id}`,
          };
        }),
      );

      const { config, options } = getDataTypeFormat(newQuestionOptions, configs, type);

      setQuestionConfigs(config);
      setQuestionOptions(sortObjectArrayAlphabetically(options, 'name'));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetProjectSteps, isLoading: isGetProjectStepsLoading = false } = useGetProjectSteps({
    onCompleted: ({ userFlowSteps }) => {
      if (!userFlowSteps.length) {
        return;
      }

      setUserFlowStepOptions(
        userFlowSteps.map(({ id, stepName }) => ({
          id,
          name: stepName,
          value: id,
        })),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleQuestionChange = ({ target: { value } }) => {
    const [contentSnippetFormId, questionId] = value.split('-');
    setSelectedQuestionId(questionId);
    setSelectedQuestionFormId(value);
    onUpdateQuestion?.(Number(questionId), Number(contentSnippetFormId));
    onUpdateQuestionWithName?.(questionOptions.find((option) => option.value === value));
    onUpdateQuestionWithConfig?.(questionConfigs.find((option) => option.id === Number(questionId)));
  };

  const handleUserFlowStepChange = ({ target: { value } }) => {
    setSelectedQuestionId(undefined);
    setSelectedQuestionFormId(undefined);
    setSelectedUserFlowStepId(Number(value));
    onUpdateModule?.(value);
  };

  const handleMultiQuestionChange = (values) => {
    setSelectedMultipleQuestionIds(values);
    onUpdateMultiQuestion?.(values);
    onUpdateMultiQuestionWithNames?.(questionOptions.filter((option) => values.includes(option.value)));
    onUpdateMultiQuestionWithConfig?.(questionConfigs.filter((option) => values.includes(String(option.id))));
  };

  useEffect(() => {
    if (!project) return;
    handleGetProjectSteps({
      variables: {
        projectId: String(project.id),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!selectedUserFlowStepId) return;
    handleGetQuestions({
      variables: {
        userFlowStepId: Number(selectedUserFlowStepId),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserFlowStepId]);

  const isLoading = isGetQuestionsLoading || isGetProjectStepsLoading;

  // Remove container when switching to new ekardo
  return (
    <Container>
      {isLoading && <Preloader />}
      {informationMessage && (
        <Spacing>
          <InformationBox message={informationMessage} />
        </Spacing>
      )}
      {onBackText && (
        <Spacing>
          <NavigationButton
            additionalClassNames="c-sidebar-navigation-button"
            direction="previous"
            iconId="id_enter_icon"
            rotationCssSuffix="180"
            text={onBackText}
            onClick={onClose}
          />
        </Spacing>
      )}
      <Spacing>
        <Select
          id="id_modules"
          labelText="Select a module"
          name="modules"
          options={userFlowStepOptions}
          value={selectedUserFlowStepId}
          onChange={handleUserFlowStepChange}
        />
      </Spacing>
      <Spacing>
        {isMultiQuestionSelector ? (
          // Switch this out to use multi select component when switching to v3
          <Spacing>
            <CheckboxGroup
              items={questionOptions.map(({ value, name }) => ({
                id: value,
                value,
                labelText: name,
              }))}
              legendText="Select questions"
              name="questions-multi-select"
              values={selectedMultipleQuestionIds}
              onChange={handleMultiQuestionChange}
            />
          </Spacing>
        ) : (
          <>
            <Spacing>
              <Select
                id="id_questions"
                labelText="Select a question"
                name="questions-single-select"
                options={questionOptions}
                value={selectedQuestionFormId}
                onChange={handleQuestionChange}
              />
            </Spacing>
            {selectedQuestionId && hasPreview && (
              <Spacing>
                <QuestionPreview questionId={Number(selectedQuestionId)} />
              </Spacing>
            )}
          </>
        )}
      </Spacing>

      {/* Switch ButtonGroup for TabsetButtons when switching to V3 */}
      {hasButtons && (
        <ButtonGroup
          isConfirmButtonDisabled={
            (isMultiQuestionSelector && selectedMultipleQuestionIds.length === 0) || (!isMultiQuestionSelector && !selectedQuestionFormId)
          }
          hasConfirmButton
          onCancel={onClose}
          onDelete={hasDelete ? onDelete : undefined}
          onSave={onSave}
        />
      )}
    </Container>
  );
};

QuestionSelector.propTypes = {
  hasButtons: PropTypes.bool,
  hasDelete: PropTypes.bool,
  hasPreview: PropTypes.bool,
  informationMessage: PropTypes.string,
  initialQuestionFormId: PropTypes.string,
  initialQuestionFormIds: PropTypes.arrayOf(PropTypes.string),
  initialQuestionId: PropTypes.number,
  initialUserFlowStepId: PropTypes.number,
  isMultiQuestionSelector: PropTypes.bool,
  onBackText: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onUpdateModule: PropTypes.func,
  onUpdateMultiQuestion: PropTypes.func,
  onUpdateMultiQuestionWithConfig: PropTypes.func,
  onUpdateMultiQuestionWithNames: PropTypes.func,
  onUpdateQuestion: PropTypes.func,
  onUpdateQuestionWithConfig: PropTypes.func,
  onUpdateQuestionWithName: PropTypes.func,
  type: PropTypes.string,
};

QuestionSelector.defaultProps = {
  hasDelete: false,
  isMultiQuestionSelector: false,
  hasPreview: true,
  hasButtons: true,
  type: 'general',
};

export { QuestionSelector };
