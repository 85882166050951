import { gql } from '@apollo/client';

const START_USER_FLOW_STEP_USING_URLS_MUTATION = gql`
  mutation startUserFlowStepUsingUrls($userFlowStepUrl: String!, $userFlowUrl: String!, $forceNewTracking: Boolean) {
    userFlowStepTrack {
      startUserFlowStepUsingUrls(userFlowStepUrl: $userFlowStepUrl, userFlowUrl: $userFlowUrl, forceNewTracking: $forceNewTracking) {
        id
      }
    }
  }
`;

export { START_USER_FLOW_STEP_USING_URLS_MUTATION };
