import { gql } from '@apollo/client';

const START_USER_FLOW_STEP_MUTATION = gql`
  mutation startUserFlowStep($userFlowStepId: Int!, $forceNewTracking: Boolean) {
    userFlowStepTrack {
      startUserFlowStep(stepId: $userFlowStepId, forceNewTracking: $forceNewTracking) {
        id
      }
    }
  }
`;

export { START_USER_FLOW_STEP_MUTATION };
