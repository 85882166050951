// addContainerRequest
import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const ADD_CONTENT_CONTAINER = gql`
  mutation addContentContainer($sectionId: Int!, $contentLayoutId: Int!, $title: String!) {
    contentSection {
      addSectionContainer(sectionContainer: { sectionId: $sectionId, contentLayoutId: $contentLayoutId, title: $title }) {
        id
        sectionContainers {
          id
          sort
        }
      }
    }
  }
`;

const addContainerRequest = ({ sectionId, contentLayoutId, onError, onSuccessResult }) => (
  <Mutation mutation={ADD_CONTENT_CONTAINER}>
    {(createSection, { loading, error, data }) => {
      const variables = {
        sectionId,
        contentLayoutId,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSection.addSectionContainer);
      }

      return <MutationRequest isLoading={loading} mutation={createSection} variables={variables} />;
    }}
  </Mutation>
);

addContainerRequest.propTypes = {
  contentLayoutId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
};

addContainerRequest.defaultProps = {};

export default addContainerRequest;
