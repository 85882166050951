import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import arrowIcon from '../../../static/img/icon-chevron.svg';

import '../../../styles/_fonts.css';

// **************styled Components********************
const Button = styled.button`
  align-items: center;
  background-color: ${(props) => (props.show ? '#4BC1C9' : '#E1E1E1')};
  border: none;
  border-radius: 50%;
  color: ${(props) => (props.show ? '#fff' : '#000')};
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 1em;
  justify-content: center;
  outline: none;
  transform: ${(props) => `rotate(${props.deg}deg) `};
  width: 1em;

  &:focus {
    outline: none;
  }
`;

// **************funtions*****************************

// **************styled Components********************

const ShowHideButton = (props) => {
  const { direction, isShow, isClicked } = props;
  const [degree, setDegree] = useState(null);
  const [show, setShow] = useState(isShow || false);
  let deg = 0;

  switch (direction) {
    case 'top':
      deg = 180;
      break;
    case 'left':
      deg = 90;
      break;
    case 'right':
      deg = -90;
      break;
    case 'bottom':
      deg = 0;
      break;
    default:
      deg = 0;
      break;
  }

  const handleClick = () => {
    if (degree === null) {
      setDegree(deg + 180);
    } else setDegree(degree + 180);
    setShow(!show);
    isClicked();
  };

  return (
    <Button deg={degree === null ? deg : degree} show={show} onClick={handleClick}>
      <img alt="arrow" src={arrowIcon} />
    </Button>
  );
};

ShowHideButton.propTypes = {
  direction: PropTypes.string,
  isClicked: PropTypes.func,
  isShow: PropTypes.bool,
};

ShowHideButton.defaultProps = {
  direction: '',
  isClicked: () => {},
  isShow: false,
};

export default ShowHideButton;
