/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import iconCheckImage from '../../static/img/icon-check.svg';

class EditableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      text: 'Add Page',
    };
  }

  componentDidMount() {
    const { text } = this.props;
    this.setState({
      text,
    });
  }

  handleOnClick = () => {
    this.setState(prevState => ({
      editable: !prevState.editable,
    }));
  };

  handleInputs = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { text } = this.state;
    const { onSubmit } = this.props;
    onSubmit(text);
  };

  render() {
    const { editable, text } = this.state;

    return (
      <div className="editable-text">
        {!editable ? (
          <div className=" d-flex flex-row">
            <span className="icon-plus" />
            <p onClick={this.handleOnClick} onKeyPress={this.handleOnClick}>
              {text}
            </p>
          </div>
        ) : (
          <>
            <input
              autoComplete="off"
              className="input"
              name="text"
              onChange={this.handleInputs}
              placeholder={text}
            />
            <img
              alt="icon-check-svg"
              className="editable-text__check"
              height="25px"
              onClick={this.handleSubmit}
              onKeyPress={this.handleSubmit}
              src={iconCheckImage}
              width="25px"
            />
          </>
        )}
      </div>
    );
  }
}

EditableText.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string,
};

EditableText.defaultProps = {
  text: 'Add Page',
};

export default EditableText;
