import React, { useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { addMonths, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';

import ExportPage from './ExportPage';
import Constants from './Shared/Constants';

import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import client from '../../../middleware/client';
import { EXPORT_BOOKMARKS } from '../../../middleware/Export/exportBookmarks';
import { downloadFileByUrl } from '../../../utils/utils';

const ExportBookmarksPage = () => {
  const { projectId } = useParams();

  const [apiError, setApiError] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(addMonths(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [isFormValid, setIsFormValid] = useState(true);

  const [sendExportBookmarksQuery, { loading: isGeneratingReport }] = useLazyQuery(EXPORT_BOOKMARKS, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { contentType, s3Key } = data.report.exportBookmarks;
      const signedUrl = getSignedUrl(s3Key, contentType);

      downloadFileByUrl(signedUrl);
    },
    onError: (error) => {
      setApiError(error);
    },
  });

  const download = (fileType = 'EXCEL' | 'CSV') => {
    sendExportBookmarksQuery({
      variables: {
        dateFrom,
        dateTo,
        ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        projectId,
        fileType,
      },
    });
  };

  const handleDateFromChange = (value) => {
    setDateFrom(value);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo));
  };

  const handleDateToChange = (value) => {
    setDateTo(value);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo));
  };

  const options = {
    apiError,
    downloadCSVCallback: () => download('CSV'),
    filtering: {
      date: {
        dateFrom,
        dateTo,
        handleDateFromChangeCallback: handleDateFromChange,
        handleDateToChangeCallback: handleDateToChange,
      },
    },
    flags: {
      isFormValid,
      preloader: {
        isGeneratingReport,
        isLoadingUserFlowSteps: false,
      },
    },
    infoBannerText: 'This page exports user bookmarks based on the selected filters',
  };

  return <ExportPage options={options} />;
};

export default ExportBookmarksPage;
