import React, { useContext, useState, useEffect } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { useCookie } from '@netfront/common-library';
import { useDomain } from '@netfront/gelada-identity-library';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PushRight } from '../OrganisationUsersPage/styled';

import { emailUsages, smsUsages, templateTypes } from './CreateNotificationPageUtils';
import { AdvanceItem, AdvanceTitle, Row } from './styled';

import Button from '../../components/Buttons/Button';
import { StyledTextarea } from '../../components/Form/styled';
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import Input from '../../components/UI/Input/InputWhite';
import Tooltip from '../../components/UI/Tooltip/Tooltips';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls } from '../../hooks';
import howlerClient from '../../middleware/_Howler/howlerClient';
import { CREATE_TEMPLATE } from '../../middleware/_Howler/Mutations/CreateTemplate';
import { DELETE_TEMPLATE } from '../../middleware/_Howler/Mutations/DeleteTemplate';
import { UPDATE_TEMPLATE } from '../../middleware/_Howler/Mutations/UpdateTemplate';

const DEFAULT_TEMPLATE = {
  smsUsage: [],
  usage: [],
};

const CreateNotificationPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const { org, project } = useContext(AppContext);

  const template = location.state?.template || DEFAULT_TEMPLATE;

  const [content, setContent] = useState(template.content);
  const [emailUsage, setEmailUsage] = useState(template.usage[0]);
  const [isPreview, setIsPreview] = useState(false);
  const [notificationType, setNotificationType] = useState(template.type ?? templateTypes[0].value);
  const [name, setName] = useState(template.name);
  const [smsUsage, setSmsUsage] = useState(template.smsUsage[0]);
  const [subject, setSubject] = useState(template.subject || '');
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  const context = {
    headers: {
      authorization: getAccessTokenCookie(),
    },
  };

  const [executeCreateTemplate, { loading: isCreateTemplateLoading }] = useMutation(CREATE_TEMPLATE, {
    context,
    client: howlerClient,
  });

  const [executeDeleteTemplate, { loading: isDeleteTemplateLoading }] = useMutation(DELETE_TEMPLATE, {
    context,
    client: howlerClient,
  });

  const [executeUpdateTemplate, { loading: isUpdateTemplateLoading }] = useMutation(UPDATE_TEMPLATE, {
    context,
    client: howlerClient,
  });

  const isEditMode = !isEqual(template, DEFAULT_TEMPLATE);
  const notificationsPageUrl = `/dashboard/${org.key}/${project.id}/notifications`;

  const handleCreateTemplate = () => {
    const projectId = project.id;
    const type = notificationType;
    const usage = emailUsage;

    executeCreateTemplate({
      variables: {
        content,
        name,
        projectId,
        smsUsage,
        subject,
        type,
        usage,
      },
    }).then(() => {
      history.push(`${notificationsPageUrl}/notification-templates`);
    });
  };

  const handleDeleteTemplate = () => {
    const templateId = template.id;

    executeDeleteTemplate({
      variables: {
        templateId,
      },
    }).then(() => {
      history.push(`${notificationsPageUrl}/notification-templates`);
    });
  };

  const handleUpdateTemplate = () => {
    const projectId = project.id;
    const templateId = template.id;
    const type = notificationType;
    const usage = emailUsage;

    executeUpdateTemplate({
      variables: {
        content,
        name,
        projectId,
        smsUsage,
        subject,
        templateId,
        type,
        usage,
      },
    }).then(() => {
      history.push(`${notificationsPageUrl}/notification-templates`);
    });
  };

  const htmlText = () => ({ __html: content });

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${project.id}/notifications`,
        title: 'Social',
        isExternal: true,
      },
      {
        path: `${notificationsPageUrl}/notification-templates`,
        title: 'Notification templates',
      },
      {
        id: 3,
        title: template.name,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const isLoading = isCreateTemplateLoading || isDeleteTemplateLoading || isUpdateTemplateLoading;

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info="Create template"
      pageDescription="Create template"
      pageTitle="Create template"
      params={params}
    >
      <AdvanceItem>
        <AdvanceTitle>
          Notification type
          <Tooltip text="Select the template type" />
        </AdvanceTitle>
        <Dropdown
          availableResponses={templateTypes}
          name="notification-types"
          selectedValue={notificationType}
          onChangeHandler={(_, value) => setNotificationType(value)}
        />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          Usage
          <Tooltip text="Select the usage" />
        </AdvanceTitle>
        {notificationType === 'EMAIL' && (
          <Dropdown
            availableResponses={sortBy(
              emailUsages.filter((usage) => !usage.service || project.services?.includes(usage.service)),
              'label',
            )}
            name="email-usages"
            selectedValue={emailUsage}
            onChangeHandler={(_, value) => setEmailUsage(value)}
          />
        )}
        {notificationType === 'SMS' && (
          <Dropdown
            availableResponses={sortBy(smsUsages, 'label')}
            name="sms-usages"
            selectedValue={smsUsage}
            onChangeHandler={(_, value) => setSmsUsage(value)}
          />
        )}
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          Title
          <Tooltip text="Title of the template" />
        </AdvanceTitle>
        <Input name="name" text={template.name} onChange={(e) => setName(e.target.value)} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          Subject
          <Tooltip text="Subject of the template" />
        </AdvanceTitle>
        <Input name="name" text={template.subject} onChange={(e) => setSubject(e.target.value)} />
      </AdvanceItem>
      <AdvanceItem>
        <Button onClick={() => setIsPreview(!isPreview)}>
          {!isPreview && <>Preview</>}
          {isPreview && <>Editor</>}
        </Button>
      </AdvanceItem>
      <AdvanceItem width="100%">
        <AdvanceTitle>
          Content
          <Tooltip text="Content of the template" />
        </AdvanceTitle>
        {!isPreview && <StyledTextarea minRows={8} value={content} onChange={(e) => setContent(e.target.value)} />}
        {isPreview && <div dangerouslySetInnerHTML={htmlText()} />}
      </AdvanceItem>
      <PushRight>
        <Row>
          {isEditMode && (
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              styles="background-color: #e63438; margin-right: 10px"
              onClick={handleDeleteTemplate}
            >
              Delete
            </Button>
          )}
          <Button
            isDisabled={isLoading}
            styles="background-color: #505354; margin-right: 10px"
            onClick={() => history.replace(`/dashboard/${org.key}/${project.id}/notifications`)}
          >
            Cancel
          </Button>
          <Button isDisabled={isLoading} isLoading={isLoading} onClick={isEditMode ? handleUpdateTemplate : handleCreateTemplate}>
            Save
          </Button>
        </Row>
      </PushRight>
    </PageWrapper>
  );
};

export default CreateNotificationPage;
