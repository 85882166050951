import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const TOGGLE_PAGE = gql`
  mutation togglePage($contentPageId: Int!) {
    contentPage {
      toggleContentPage(contentPageId: $contentPageId) {
        id
      }
    }
  }
`;

const toggleContentPage = ({ contentPageId, onError, onSuccessResult }) => (
  <Mutation mutation={TOGGLE_PAGE}>
    {(togglePage, { loading, error, data }) => {
      const variables = {
        contentPageId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentPage.toggleContentPage);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={togglePage}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

toggleContentPage.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default toggleContentPage;
