import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_SLIDER_ITEM = gql`
  mutation CreateSliderItem($sliderId: Int!, $type: ESlideType!, $author: String, $assetId: String, $quote: String, $text: String) {
    slider {
      createSliderItem(sliderItem: { sliderId: $sliderId, type: $type, quote: $quote, author: $author, assetId: $assetId, text: $text }) {
        id
      }
    }
  }
`;

const createSliderItem = ({ sliderId, type, quote, author, text, assetId, onError, onSuccessResult }) => (
  <Mutation mutation={CREATE_SLIDER_ITEM}>
    {(createSliderFunc, { loading, error, data }) => {
      const variables = {
        sliderId,
        type,
        quote,
        author,
        assetId,
        text,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.slider.createSliderItem.id);
      }

      return <MutationRequest isLoading={loading} mutation={createSliderFunc} variables={variables} />;
    }}
  </Mutation>
);

createSliderItem.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sliderId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  assetId: PropTypes.string,
  author: PropTypes.string,
  quote: PropTypes.string,
  text: PropTypes.string,
};

createSliderItem.defaultProps = {
  assetId: null,
  author: '',
  quote: '',
  text: '',
};

export default createSliderItem;
