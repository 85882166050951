import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_FORM_CONTENT_SNIPPET = gql`
  mutation createContentSnippet($containerId: Int!, $visible: Boolean!, $contentSnippetFormId: Int!, $title: String!) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible, entityTitle: $title }
        contentSnippetForm: { contentSnippetFormId: $contentSnippetFormId }
      ) {
        id
        sort
      }
    }
  }
`;

const createFormContentSnippetRequest = ({ containerId, isVisible, contentSnippetFormId, onError, onSuccessResult }) => (
  <Mutation mutation={CREATE_FORM_CONTENT_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        visible: isVisible,
        contentSnippetFormId,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.createContentSnippet.id, data.contentSnippet.createContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createFormContentSnippetRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  contentSnippetFormId: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

createFormContentSnippetRequest.defaultProps = {};

export default createFormContentSnippetRequest;
