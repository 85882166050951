import React from 'react';

import { ContentPageProvider } from './ContentPageContext';

const ContentPageState = ({ children }) => {
  const initialState = {
    contentPage: {},
    navTreeContentPageIds: [],
    projectCharacters: [],
    isNewPage: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setContentPage':
        return {
          ...state,
          contentPage: action.newState,
        };
      case 'addContentPageBubbleText':
        return {
          ...state,
          contentPage: {
            ...state.contentPage,
            ...{
              bubbleTexts: [...state.contentPage.bubbleTexts, action.newState],
            },
          },
        };
      case 'updateContentPageBubbleText':
        return {
          ...state,
          contentPage: {
            ...state.contentPage,
            ...{
              bubbleTexts: state.contentPage.bubbleTexts.map(item => {
                const { newState: bubbleText } = action;
                if (item.id === bubbleText.id) {
                  return bubbleText;
                }
                return item;
              }),
            },
          },
        };
      case 'deleteContentPageBubbleText':
        return {
          ...state,
          contentPage: {
            ...state.contentPage,
            ...{
              bubbleTexts: state.contentPage.bubbleTexts.filter(item => item.id !== action.newState),
            },
          },
        };
      case 'setNavTreeContentPageIds':
        return {
          ...state,
          navTreeContentPageIds: action.newState,
        };
      case 'setCharacters':
        return {
          ...state,
          projectCharacters: action.newState,
        };

      case 'setIsNewPage':
        return {
          ...state,
          isNewPage: action.newState,
        };

      default:
        return state;
    }
  };
  return (
    <ContentPageProvider initialState={initialState} reducer={reducer}>
      {children}
    </ContentPageProvider>
  );
};
export default ContentPageState;
