import React, { useEffect, useState } from 'react';

import { Moment } from 'moment';
import { DateRangePicker as RangePicker, FocusedInputShape } from 'react-dates';

import '../DatePicker.css';
import { momentToDate, dateToMoment } from '../DatePicker.helpers';

import { getRangePresets } from './DateRangePicker.helpers';
import { DateRangeOnChangeProps, DateRangePickerProps, DateRangePresetsProps } from './DateRangePicker.types';

const DateRangePicker = ({ onChangeHandler, selectedDate, isReadOnly, isDisabled, isRequired }: DateRangePickerProps) => {
  // As this is component update is just temporary until we find a solution that doesn't require moment.js and to make this component backwards compatible we need to pass a date object as a prop and convert to moment object
  const [startDate, setStartDate] = useState<Moment | null>(dateToMoment(selectedDate?.start));
  const [endDate, setEndDate] = useState<Moment | null>(dateToMoment(selectedDate?.end));
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [presets, setPresets] = useState<DateRangePresetsProps[]>();
  const isOutsideRange = () => false; // attribute expects an expression rather than a boolean value.

  const onDatesChange = ({ startDate: start, endDate: end }: DateRangeOnChangeProps) => {
    setStartDate(start ?? null);
    setEndDate(end ?? null);

    // As this is component update is just temporary until we find a solution that doesn't require moment.js and to make this component backwards compatible we need to return a date object
    if (onChangeHandler) onChangeHandler({ start: momentToDate(start), end: momentToDate(end) });
  };

  useEffect(() => {
    setPresets(getRangePresets());
  }, []);

  const renderDatePresets = () => {
    return (
      <div className="c-date-range__info-panel">
        <h3 className="c-date-range__title">Predefined periods:</h3>
        <div className="c-date-range-options">
          {presets &&
            presets.map(({ text, start, end }) => (
              <button
                key={text}
                className="c-date-range-option__item"
                type="button"
                onClick={() => onDatesChange({ startDate: start, endDate: end })}
              >
                {text}
              </button>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className="c-date-range">
      <RangePicker
        calendarInfoPosition="after"
        disabled={isDisabled}
        displayFormat="DD.MM.YY"
        endDate={endDate}
        endDateId="endDate"
        focusedInput={focusedInput}
        isOutsideRange={isOutsideRange}
        readOnly={isReadOnly}
        renderCalendarInfo={renderDatePresets}
        renderDayContents={(day: Moment) => <div className="c-date-range__day">{day.date()}</div>}
        required={isRequired}
        startDate={startDate}
        startDateId="startDate"
        showClearDates
        showDefaultInputIcon
        small
        onDatesChange={onDatesChange}
        onFocusChange={setFocusedInput}
      />
    </div>
  );
};

export { DateRangePicker };
