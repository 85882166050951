import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #f6f8f9;
  padding: 1em;
  border-radius: 5px;
  &:hover {
    background-color: #fff;
  }
`;

const Author = styled.p`
  font-style: italic;
  font-weight: bold;
  text-align: right;
  font-size: 1.2em;
`;

const QuoteMarker = styled.span`
  font-size: 5em;
  line-height: 0em;
  margin-bottom: 0em;
  position: relative;
  bottom: -30px;
`;

const QuoteMarker2 = styled(QuoteMarker)`
  left: calc(100% - 30px);
  bottom: -40px;
`;

const QuoteSnippet = props => {
  const { text, author } = props;
  return (
    <Wrapper>
      <QuoteMarker>&ldquo;</QuoteMarker>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: text }} />
      <QuoteMarker2>&rdquo;</QuoteMarker2>
      {author && <Author>- {author}</Author>}
    </Wrapper>
  );
};

QuoteSnippet.propTypes = {
  author: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default QuoteSnippet;
