import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ImageUploader from '../UI/AssetUploader/ImageUploader';
import Input from '../UI/Input/InputWhite';
import Textarea from '../UI/Textarea/Textarea';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import { Container, Item, Title } from './styled';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateAppRequest from '../../middleware/Marketplace/CreateAppRequest';
import { makeId } from '../../utils/utils';

const Logo = styled.img`
  width: 100%;
`;

const EditBtn = styled.button`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Preview = styled.div`
  margin: 0 0 2em 35%;
  max-width: 30%;
  position: relative;

  &:hover {
    ${EditBtn} {
      display: block;
    }
  }
`;

const AppSideBar = props => {
  const { onClose } = props;
  const [createRequest, setCreateRequest] = useState(false);
  const [newApp, setNewApp] = useState({});
  const [editLogo, setEditLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');

  const handleInfoUpdate = (value, e) => {
    // let newValue = value;
    // if (e.target.name === 'status') {
    //   newValue = newValue ? 'ACTIVE' : 'INACTIVE';
    // }
    const newInfo = {
      ...newApp,
      [e.target.name]: value,
    };
    setNewApp(newInfo);
  };

  const getLogoData = data => {
    const tmpURL = getSignedUrl(data.s3Key, data.contentType);
    setEditLogo(false);
    setLogoUrl(tmpURL);
    // setLogoAssetId(data.assetId);
    // setUpdateLogo(true);
    // setLogoData(data);
  };

  const handleSaveBtn = () => {
    setCreateRequest(true);
  };

  const handleCreateError = () => {
    setCreateRequest(false);
  };

  const handleCreateSuccess = data => {
    setCreateRequest(false);
    onClose('create', data);
  };

  const content = (
    <Container>
      <Item>
        <Title>
          <b>Name</b>
          <Tooltip />
        </Title>
        <Input isChanged={handleInfoUpdate} name="title" text="" />
      </Item>
      {/* <Item>
        <Title>
          <b>Organization Name</b>
          <Tooltip />
        </Title>
        <Input isChanged={handleInfoUpdate} name="OrgName" text="" />
      </Item> */}
      <Item>
        <Title>
          <b>Description</b>
          <Tooltip />
        </Title>
        <Textarea isChanged={handleInfoUpdate} name="description" text="" />
      </Item>
      <Item>
        <Title>
          <b>Thumbnail</b>
          <Tooltip />
        </Title>
        {logoUrl && !editLogo ? (
          <Preview>
            <EditBtn
              onClick={() => {
                setEditLogo(true);
              }}
            >
              Upload New Logo
            </EditBtn>
            <Logo alt="logo" src={logoUrl} />
          </Preview>
        ) : (
          <ImageUploader
            getData={getLogoData}
            name="logo"
            projectId={1}
            type="PROJECT_LOGO"
          />
        )}
      </Item>
    </Container>
  );

  return (
    <>
      <RightNavBar hasDelete={false} onCancel={onClose} onSave={handleSaveBtn}>
        <h2>Create an app</h2>
        {content}
      </RightNavBar>
      {createRequest && (
        <CreateAppRequest
          applicationDescription={newApp.description}
          identifier={`${newApp.title}-${makeId(6)}`}
          //   logoId={assetId}
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
          title={newApp.title}
        />
      )}
    </>
  );
};

AppSideBar.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AppSideBar;
