import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const DatePicker = props => {
  const { answer, id, label, value, name, onChange } = props;

  const defaultDate = value ? new Date(value) : null;
  const today = new Date(Date.now()).toLocaleString().split(',')[0];

  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const handleDateChange = date => {
    onChange({
      target: {
        name,
        value: date,
      },
    });

    setSelectedDate(date);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          format="dd/MM/yyyy"
          id={`date-picker-inline-${id}`}
          label={label}
          margin="normal"
          onChange={handleDateChange}
          placeholder={today}
          value={answer || selectedDate}
          variant="inline"
          // eslint-disable-next-line react/jsx-sort-props
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

DatePicker.defaultProps = {
  answer: '',
  label: 'Date',
  name: 'Date',
  onChange: () => {},
  value: '',
};

export default DatePicker;
