import React from 'react';

import PropTypes from 'prop-types';

import { QuestionSelector } from '../../QuestionSelector';

const UpsertSummarySnippetGeneralTab = ({
  onClose,
  onUpdateQuestion,
  initialQuestionFormId,
  onUpdateModule,
  onSave,
  initialUserFlowStepId,
  initialQuestionId,
  hasDelete,
  onDelete,
}) => (
  <QuestionSelector
    hasDelete={hasDelete}
    informationMessage="Summarise previous responses."
    initialQuestionFormId={initialQuestionFormId}
    initialQuestionId={initialQuestionId}
    initialUserFlowStepId={initialUserFlowStepId}
    onClose={onClose}
    onDelete={onDelete}
    onSave={onSave}
    onUpdateModule={onUpdateModule}
    onUpdateQuestion={onUpdateQuestion}
  />
);

UpsertSummarySnippetGeneralTab.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdateModule: PropTypes.func.isRequired,
  onUpdateQuestion: PropTypes.func.isRequired,
  hasDelete: PropTypes.bool,
  initialQuestionFormId: PropTypes.string,
  initialQuestionId: PropTypes.number,
  initialUserFlowStepId: PropTypes.number,
  onDelete: PropTypes.func,
};

UpsertSummarySnippetGeneralTab.defaultProps = {
  hasDelete: false,
};

export { UpsertSummarySnippetGeneralTab };
