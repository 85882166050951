import { capitalize } from '../../../../legacy/utils/utils';

export const getActionOptions = (type, hasContentEvent = false) => [
  // {
  //   id: 0,
  //   name: 'Select an option',
  //   value: null,
  // },
  {
    id: 1,
    name: 'Show',
    value: `QuestionActionShow${type === 'targetSnippet' ? '' : capitalize(type)}Type`,
    isHidden: type === 'page',
  },
  {
    id: 2,
    name: 'Hide',
    value: `QuestionActionHide${type === 'targetSnippet' ? '' : capitalize(type)}Type`,
    isHidden: type === 'page',
  },
  {
    id: 3,
    name: 'Skip',
    value: `QuestionActionSkipPageType`,
    isHidden: type !== 'page',
  },
  {
    id: 4,
    name: 'Trigger event',
    value: `QuestionActionTriggerContentEventType`,
    isHidden: !hasContentEvent,
  },
];

export const getActionTypeName = (type, selectedActionType) => {
  const actionItems = getActionOptions(type, true);
  const { name = '' } = actionItems.find(({ value }) => value === selectedActionType) ?? {};
  return name;
};
