import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';
import { useRef } from 'react';
import { useApolloClient } from '../apollo/useApolloClient';

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: String!, $name: String!, $description: String, $url: String) {
    project {
      updateProject(project: { projectId: $id, name: $name, description: $description, url: $url }) {
        id
      }
    }
  }
`;

const updateProjectRequest = ({ id, name, description, url, onError, onSuccessResult }) => {
  const client = useRef(useApolloClient('gelada')).current;
  return (
    <Mutation client={client} mutation={UPDATE_PROJECT}>
      {(updateProject, { loading, error, data }) => {
        const variables = {
          id,
          name,
          description,
          url,
        };

        if (error) {
          return <ErrorModal error={error} onError={onError} />;
        }
        if (data) {
          onSuccessResult(id, data.project.updateProject.status);
        }

        return <MutationRequest isLoading={loading} mutation={updateProject} variables={variables} />;
      }}
    </Mutation>
  );
};
updateProjectRequest.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default updateProjectRequest;
