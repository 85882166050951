import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
// slick-carousel css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Slider/LiveSlider.css';

const Wrapper = styled.div`
  padding: 2em;
`;

const SliderItem = styled.div``;

const Author = styled.p`
  font-size: 1.2em;
  font-style: italic;
  font-weight: bold;
  text-align: right;
`;

const Image = styled.img`
  max-height: 400px;
  width: 100%;
`;

const Quote = styled.div``;

const QuoteMarker = styled.span`
  bottom: -30px;
  font-size: 5em;
  line-height: 0em;
  margin-bottom: 0;
  position: relative;
`;

const QuoteMarker2 = styled(QuoteMarker)`
  bottom: -40px;
  left: calc(100% - 30px);
`;

const SliderSnippet = props => {
  const { sliderData } = props;
  const [settings, setSettings] = useState({});
  const AutoplayRef = useRef();
  useEffect(() => {
    const tmp = {
      // autoplay: true, //	Slideshow
      autoplay: sliderData.autoplay, //	Slideshow
      // autoplaySpeed: 2000, //	Slide show speed
      autoplaySpeed: sliderData.autoplaySpeed, //	Slide show speed
      arrows: sliderData.arrows, //	Control navigation
      dots: sliderData.dots, //	Direction navigation
      adaptiveHeight: false,
      infinite: sliderData.infinite, // Loop
      slidesToShow: sliderData.slidesToShow,
      slidesToScroll: sliderData.slidesToScroll,
      speed: sliderData.speed, //	Animation speed
      // Animation: fade / linear
      fade: sliderData.animation === 'FADE',
      // easing: true,
    };
    setSettings(tmp);
  }, [sliderData]);

  const sliderContent = sliderData.sliderItems.map(item => {
    if (item.type === 'QUOTE') {
      return (
        <SliderItem key={item.id}>
          <QuoteMarker>&ldquo;</QuoteMarker>
          <Quote>{item.quote}</Quote>
          <QuoteMarker2>&rdquo;</QuoteMarker2>
          <Author>{item.author ? `- ${item.author}` : null}</Author>
        </SliderItem>
      );
    }
    if (item.type === 'IMAGE') {
      const url = getSignedUrl(item.asset.s3Key, item.asset.type);
      return (
        <SliderItem key={item.id}>
          <Image alt="asset" src={url} />
        </SliderItem>
      );
    }
    return null;
  });

  return (
    <Wrapper>
      <Slider {...settings} ref={AutoplayRef}>
        {sliderContent}
      </Slider>
    </Wrapper>
  );
};

SliderSnippet.propTypes = {
  sliderData: PropTypes.shape({
    animation: PropTypes.string,
    arrows: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    sliderItems: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      })
    ),
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
    speed: PropTypes.number,
  }).isRequired,
};

SliderSnippet.defaultProps = {};

export default SliderSnippet;
