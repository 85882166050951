import React from 'react';

import cx from 'classnames';

import { CMSEventWrapper } from '../CMSEventWrapper';

import { CMSContainerProps } from './CMSContainer.interfaces';

import { getContentSectionContainer, useContentPageContext } from '../../contexts';

import './CMSContainer.css';

const CMSContainer = ({ children, contentSectionContainerId }: CMSContainerProps) => {
  const { state: contentPage } = useContentPageContext();

  const contentSectionContainer = getContentSectionContainer(contentSectionContainerId, contentPage);

  const { contentEvent, cssStyle, visible: isVisible = true } = contentSectionContainer ?? {};
  const { class: cssStyleClass } = cssStyle ?? {};

  return (
    <React.Fragment>
      {isVisible && (
        <CMSEventWrapper contentEvent={contentEvent}>
          <div
            className={cx('c-cms-container', 'ek-container', cssStyleClass, {
              [`ek-container-${contentSectionContainerId}`]: contentSectionContainerId,
            })}
            style={{
              ...cssStyle,
            }}
          >
            {children}
          </div>
        </CMSEventWrapper>
      )}
    </React.Fragment>
  );
};

export { CMSContainer };
