import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 2%;
  margin-right: 2%;
  position: relative;
  width: 10rem;
  height: 10rem;
  background: #f6f8f9;
  box-shadow: ${props =>
    props.isSelected ? '0px 0px 6px 1px #44bed3' : 'unset'};
  cursor: pointer;
  &:hover {
    background: #fff;
    box-shadow: ${props =>
      props.isSelected
        ? '0px 0px 6px 1px #44bed3'
        : '0 -2px 12px 1px rgba(0, 0, 0, 0.07)'};
    transform: scale(1.05);
    z-index: 2;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 8px;
  line-height: 1;
`;

export const Description = styled.div`
  height: 50%;
  padding: 8px;
  overflow: hidden;
`;
