import React from 'react';

import { Icon } from '@netfront/ui-library';

const FactCheck = ({ text }) => (
  <div className="c-factcheck__container">
    <div className="c-factcheck u-fadeup">
      <div className="c-factcheck__startburst">
        <h5 className="c-factcheck__title">Fact check</h5>
        <Icon className="c-factcheck__icon" id="id_tick_icon" />
      </div>

      <div className="c-factcheck__content">
        <div className="c-factcheck__body">{text}</div>
      </div>
    </div>
  </div>
);

export { FactCheck };
