const DIRECTORY_TABLE_COLUMNS = [
  {
    Header: 'Title',
    accessor: 'title',
    width: '22.5%',
  },
  {
    Header: 'Link',
    accessor: 'link',
    width: '30%',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    width: '20%',
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '20%',
  },
  {
    Header: '',
    accessor: 'settingsButton',
    width: '7.5%',
  },
];

export { DIRECTORY_TABLE_COLUMNS };
