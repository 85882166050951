import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_IMAGE_SNIPPET = gql`
  mutation updateQuestionContentSnippet(
    $snippetId: Int!
    $containerId: Int!
    $sort: Int!
    $visible: Boolean!
  ) {
    contentSnippet {
      updateContentSnippetBase(
        snippet: {
          id: $snippetId
          containerId: $containerId
          sort: $sort
          visible: $visible
        }
      ) {
        id
        containerId
        sort
        visible
      }
    }
  }
`;

const updateContentSnippetBaseRequest = ({
  snippetId,
  containerId,
  sort,
  visible,
  onSuccessResult,
  onError,
}) => (
  <Mutation mutation={UPDATE_CONTENT_IMAGE_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        containerId,
        sort,
        visible,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippetBase);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateContentSnippetBaseRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  sort: PropTypes.number.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  visible: PropTypes.bool,
};

updateContentSnippetBaseRequest.defaultProps = {
  visible: true,
};

export default updateContentSnippetBaseRequest;
