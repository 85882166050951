import React, { useEffect, useState } from 'react';

import { Spacing, InformationBox, Table, ButtonIconOnly } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonGroup from '../../../../../../legacy/components/ButtonGroup/ButtonGroup';
import { QuestionPreview } from '../../../../QuestionPreview';
import { RESPONSE_TYPE_ICON } from '../../../../tabs/UpsertSummarySnippetFeedbackTab/UpsertSummarySnippetFeedbackTab.constants';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const SettingsContainer = styled.div`
  display: flex;
  justify-content: end !important;
  align-items: center;
  gap: 0.5rem;
  color: #000;
`;

const FeedbackOverview = ({ questionId, onEditClick, onDeleteClick, feedbackItems, onDelete, onSave, onClose, hasDelete }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(feedbackItems);
  }, [feedbackItems]);
  return (
    <Container>
      <Spacing>
        <InformationBox message="Get feedback based on user responses on the question configured below." />
      </Spacing>

      <Spacing>
        <QuestionPreview questionId={questionId} />
      </Spacing>

      {items.length > 0 && (
        <Spacing>
          <Table
            additionalClassNames="c-communities-table"
            columns={[
              {
                accessor: 'type',
                Cell: ({ value }) => <span>{value}</span>,
                Header: () => <div>Type</div>,
              },
              {
                accessor: 'feedback',
                Cell: ({ value }) => <span>{value}</span>,
                Header: () => <div>Feedback</div>,
              },
              {
                accessor: 'settingsButtonData',
                Cell: ({ value: { id, handleEdit, handleDelete } }) => (
                  <SettingsContainer>
                    <ButtonIconOnly
                      additionalClassNames=""
                      iconId="id_bin_icon"
                      isIconBorderVisible={false}
                      onClick={() => handleDelete(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      iconId="id_caret_down_icon"
                      isIconBorderVisible={false}
                      onClick={() => handleEdit(id)}
                    />
                  </SettingsContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              type: RESPONSE_TYPE_ICON[item.__typename],
              feedback: item.label,
              settingsButtonData: {
                id: item.contentSnippetSummaryId,
                handleEdit: onEditClick,
                handleDelete: onDeleteClick,
              },
            }))}
          />
        </Spacing>
      )}
      {items.length === 0 && (
        <Spacing>
          <SettingsContainer>
            <ButtonIconOnly iconId="id_plus_icon" text="Add feedback" onClick={onEditClick} />
          </SettingsContainer>
        </Spacing>
      )}
      <ButtonGroup hasConfirmButton onCancel={onClose} onDelete={hasDelete ? onDelete : undefined} onSave={onSave} />
    </Container>
  );
};

FeedbackOverview.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  feedbackItems: PropTypes.arrayOf(
    PropTypes.shape({
      feedback: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  hasDelete: PropTypes.bool,
  questionId: PropTypes.number,
};

export { FeedbackOverview };
