import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import Clients from '../../components/Clients/Clients';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResetPassword from '../../containers/ResetPassword/ResetPassword';

function ResetPasswordPage(props) {
  const { location } = props;
  const url = location.search;
  const params = queryString.parse(url);
  const { token } = params;

  return (
    <div>
      <Helmet>
        <title>Ekardo: Building accessible and engaging educational content better</title>
        <meta content="Login to Ekardo to access and manage your projects" name="description" />
      </Helmet>

      <div className="login-page">
        <div className="login-page__fold">
          <Header theme="red" />
          <div className="login-page__container">
            <h2 className="heading-page" style={{ fontSize: '3em' }}>
              RESET
            </h2>
            <h2 className="heading-page">PASSWORD</h2>
          </div>
        </div>
        <div className="login-page__card">
          <div className="login-page__col login-page__image">
            <img alt="Laptop locked" src="https://ekardo-assets-prod.s3.ap-southeast-2.amazonaws.com/login.svg" />
          </div>
          <div className="login-page__col" style={{ backgroundColor: '#fff' }}>
            <ResetPassword token={token} />
          </div>
        </div>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

ResetPasswordPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

ResetPasswordPage.defaultProps = {
  location: undefined,
};

export default ResetPasswordPage;
