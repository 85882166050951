import gql from 'graphql-tag';

export const EXPORT_BOOKMARKS = gql`
  query ExportBookmarks($ianaTimeZone: String!, $projectId: String, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportBookmarks(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        presignedUrl
        s3Key
      }
    }
  }
`;
