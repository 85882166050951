import gql from 'graphql-tag';

export const CREATE_CHECKBOX_FEEDBACK_CONFIG = gql`
  mutation createCheckboxSummaryFeedbackConfig(
    $contentSnippetSummaryId: Int!
    $questionResponseSetId: Int!
    $label: String!
    $summaryFeedbackId: Int
  ) {
    summaryFeedback {
      addSummaryFeedbackConfigurationCheckbox(
        contentSnippetSummaryId: $contentSnippetSummaryId
        questionResponseSetId: $questionResponseSetId
        label: $label
        summaryFeedbackId: $summaryFeedbackId
      )
    }
  }
`;
