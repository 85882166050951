import React from 'react';

const ValidationProcessor = validations => {
  const validationErrors = [];

  Object.keys(validations).forEach(property => {
    if (!validations[property].validation)
      validationErrors.push(
        <li key={validations[property].errorMessage.substring(0, 5)}>
          {validations[property].errorMessage}
        </li>
      );
  });

  const response = {
    modelValid: validationErrors.length === 0,
    validations: validationErrors,
  };

  return response;
};

export default ValidationProcessor;
