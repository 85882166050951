import React, { Fragment } from 'react';

import cx from 'classnames';
import TreeMenu from 'react-simple-tree-menu';

import { ButtonIconOnly } from '../ButtonIconOnly';
import { PlayButton } from '../Buttons';
import { ToggleSwitch } from '../ToggleSwitch';

import { getIdFromKey } from './ReactTreeAccordion.helpers';
import { ReactTreeAccordionProps } from './ReactTreeAccordion.interfaces';

import './ReactTreeAccordion.css';

const ReactTreeAccordion = ({ data, onToggleSwitchClick, selectedIds = [] }: ReactTreeAccordionProps) => {
  return (
    <div className="c-tree-accordion">
      <TreeMenu data={data}>
        {({ items }) => {
          return (
            <Fragment>
              {items.map(({ hasNodes, key, isOpen, interactionComponent, itemInfoComponent, label, level, parent, toggleNode, url }) => {
                const handleDropdownClick = () => {
                  if (!(hasNodes && toggleNode)) {
                    return;
                  }

                  toggleNode();
                };

                const dropdownCaretComponent = hasNodes && (
                  <div className="c-tree-accordion__item-dropdown-icon-container">
                    <ButtonIconOnly
                      additionalClassNames={cx('c-tree-accordion__item-dropdown-icon', {
                        'c-tree-accordion__item-dropdown-icon--rotate': isOpen,
                      })}
                      iconId="id_caret_down_icon"
                      text=""
                      onClick={handleDropdownClick}
                    />
                  </div>
                );

                const id = getIdFromKey(key);
                const isChecked = Boolean(selectedIds.find((selectedId) => selectedId === id));

                /*
                 * TODO: Remove playButton and toggleSwitch components as this should
                 * not be a concern of this component and should be passed in instead
                 */

                const playButtonComponent = url ? <PlayButton audioSrc={url} /> : null;

                const toggleSwitchComponent =
                  onToggleSwitchClick && parent ? (
                    <div className="c-tree-accordion__item-toggle">
                      <ToggleSwitch
                        id={`accordion-item-toggle-switch-${key}`}
                        isChecked={isChecked}
                        labelText=""
                        onChange={() => onToggleSwitchClick(id)}
                      />
                    </div>
                  ) : null;

                return (
                  <div
                    key={`accordion-container-${key}`}
                    className={cx({
                      'c-tree-accordion__item-parent-container': !parent,
                      'c-tree-accordion__item-child-container': parent,
                    })}
                  >
                    <div className="c-tree-accordion__item-container">
                      <div className="c-tree-accordion__item-contents-container">
                        <div
                          className={cx('c-tree-accordion__item-label', {
                            [`c-tree-accordion__item-label-${level}`]: level > 0,
                          })}
                        >
                          {label}
                        </div>
                        {itemInfoComponent && !parent && <div className="c-tree-accordion__item-info">{itemInfoComponent}</div>}
                        {(interactionComponent ?? playButtonComponent ?? toggleSwitchComponent) && parent && (
                          <div className="c-tree-accordion__item-interaction">
                            {interactionComponent}
                            {playButtonComponent}
                            {toggleSwitchComponent}
                          </div>
                        )}
                      </div>
                      {dropdownCaretComponent}
                    </div>
                  </div>
                );
              })}
            </Fragment>
          );
        }}
      </TreeMenu>
    </div>
  );
};

export { ReactTreeAccordion };
