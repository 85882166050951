/* eslint-disable no-nested-ternary */
import React, { createRef, useState, forwardRef, useImperativeHandle } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Preloader from '../Preloader/Preloader';

import LibraryAssets from './LibraryAssets';
import { SnippetTypeConst } from './SnippetTypeConst';

import CreateAssetRequest from '../../middleware/Asset/createAssetRequest';
import CreateImageContentSnippetRequest from '../../middleware/ContentSnippet/createImageContentSnippetRequest';
import UpdateContentImageSnippetReq from '../../middleware/ContentSnippet/updateContentImageSnippetReq';

const EditableImageSnippet = forwardRef((props, ref) => {
  const {
    containerId,
    onCancel,
    onUpdate,
    onCreate,
    onAddFile = () => {},
    isSnippetInForm,
    onEditQuesSuccess,
    snippet,
    handleCloseEditMedia = () => {},
  } = props;

  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [createAsset, setCreateAsset] = useState(false);
  const [createSnippet, setCreateSnippet] = useState(false);
  const [asset, setAsset] = useState(null);
  const [openLib, setOpenLib] = useState(false);
  const [assetFromLib, setAssetFromLib] = useState(false);
  const [preview, setPreview] = useState(null);

  const dropzoneRef = createRef();

  const openLibrary = () => {
    setOpenLib(true);
  };

  const handleFileDrop = (files) => {
    setFile(files[0]);
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleAssetError = () => {
    setCreateAsset(false);
  };

  const handleAssetResponse = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, asset },
      },
    } = data;
    setCreateAsset(false);
    setLoader(true);
    Object.defineProperty(file, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    axios
      .put(signedUrl, file, { headers: { 'content-type': file.type } })
      .then(() => {
        setAsset(asset);
        setLoader(false);
        setCreateSnippet(true);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleSnippetError = () => {
    setCreateSnippet(false);
  };

  const handleSnippetResponse = (id, sort) => {
    onAddFile(preview, 'image');
    if (snippet) {
      onUpdate(id, sort, SnippetTypeConst.IMAGE, containerId, {
        asset,
        assetId: asset.assetId,
      });
    } else {
      onCreate(id, sort, SnippetTypeConst.IMAGE, containerId, {
        asset,
        assetId: asset.assetId,
      });
    }
    if (onEditQuesSuccess) {
      onEditQuesSuccess();
    }
    if (isSnippetInForm) {
      setOpenLib(false);
      setPreview(null);
      setFile(null);
    }
    setCreateSnippet(false);
    handleCloseEditMedia();
  };
  const handleSaveButton = () => {
    if (assetFromLib) setCreateSnippet(true);
    else setCreateAsset(true);
  };
  useImperativeHandle(ref, () => ({
    handleSaveButton() {
      // setCreateAsset(true);
      if (assetFromLib) setCreateSnippet(true);
      else setCreateAsset(true);
    },
  }));

  const handleSelectAsset = (ass) => {
    setAsset(ass);
    setAssetFromLib(true);
  };

  return (
    <>
      {file ? (
        <div>
          <img alt="preview" src={preview} style={{ maxWidth: '100%' }} />
        </div>
      ) : !openLib ? (
        <div>
          <Dropzone ref={dropzoneRef} accept="image/*" noClick noKeyboard onDropAccepted={handleFileDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p style={{ marginBottom: '0.6em' }}>{isDragActive ? 'Drop here' : 'Drop image here or click to upload'}</p>
                  <p>
                    (<strong>Maximum</strong> 1 file)
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="mt-3 text-center">
            <button className="button--green" type="button" onClick={openLibrary}>
              Choose from library
            </button>
          </div>
        </div>
      ) : (
        <LibraryAssets goBack={() => setOpenLib(false)} isClicked={handleSelectAsset} type="IMAGE" />
      )}
      {!isSnippetInForm && (
        <div className="text-right">
          <button className="button--black mt-4 mr-3" type="button" onClick={onCancel}>
            Cancel
          </button>
          <button className="mt-4 button--blue" type="submit" onClick={handleSaveButton}>
            Save
          </button>
        </div>
      )}
      {createAsset && (
        <CreateAssetRequest
          contentType={file.type}
          fileName={file.name}
          fileSizeInBytes={file.size}
          type="IMAGE"
          onError={handleAssetError}
          onSuccessResult={handleAssetResponse}
        />
      )}
      {createSnippet && !snippet && (
        <CreateImageContentSnippetRequest
          assetId={asset.assetId}
          containerId={Number(containerId)}
          height={50} // TODO: Remove
          width={50}
          isVisible
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse} // TODO: Remove
        />
      )}
      {createSnippet && snippet && (
        <UpdateContentImageSnippetReq
          assetId={asset.assetId}
          height={50} // TODO: Remove
          snippetId={snippet.id}
          width={50}
          onError={handleSnippetError} // TODO: Remove
          onSuccessResult={handleSnippetResponse}
        />
      )}
      {loader && <Preloader text="Uploading Image. Please wait!" />}
    </>
  );
});

EditableImageSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  handleCloseEditMedia: PropTypes.func,
  isSnippetInForm: PropTypes.bool,
  onAddFile: PropTypes.func,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onEditQuesSuccess: PropTypes.func,
  onUpdate: PropTypes.func,
  snippet: PropTypes.instanceOf(Object),
};

EditableImageSnippet.defaultProps = {
  handleCloseEditMedia: () => {},
  isSnippetInForm: false,
  onAddFile: () => {},
  onCancel: () => {},
  onCreate: () => {},
  onEditQuesSuccess: () => {},
  onUpdate: () => {},
  snippet: null,
};

// export default forwardRef(EditableImageSnippet);
export default EditableImageSnippet;
