import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_SLIDER_CONTENT_SNIPPET = gql`
  mutation updateContentSnippet($id: Int!, $sliderId: Int!, $title: String!) {
    contentSnippet {
      updateContentSnippet(baseSnippet: { id: $id, entityTitle: $title }, contentSnippetSlider: { sliderId: $sliderId }) {
        id
        sort
      }
    }
  }
`;

const updateSliderContentSnippet = ({ id, sliderId, onError, onSuccessResult }) => (
  <Mutation mutation={CREATE_SLIDER_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        id,
        sliderId,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id, data.contentSnippet.updateContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateSliderContentSnippet.propTypes = {
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sliderId: PropTypes.number.isRequired,
};

updateSliderContentSnippet.defaultProps = {};

export default updateSliderContentSnippet;
