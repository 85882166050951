import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Client } from '@netfront/gelada-react-shared';
import axios from 'axios';
import uniqBy from 'lodash.uniqby';
import PropTypes from 'prop-types';

import Preloader from '../Preloader/Preloader';

import NoteAddOrUpdate from './NoteAddOrUpdate';
import NoteDetails from './NoteDetails';
import { TAB_TYPE_KEYS } from './Notes.constants';
import NotesList from './NotesList';

import { EntityTypeKeys } from '../../constants/EntityTypeKeys';
import { DELETE_NOTE_ATTACHMENTS } from '../../middleware/_Gelada/mutations/noteAttachments';
import { ADD_GROUP_NOTE, ADD_USER_NOTE, DELETE_NOTE, EDIT_NOTE } from '../../middleware/_Gelada/mutations/notes';
import { GET_NOTES_BY_GROUP, GET_NOTES_BY_USER } from '../../middleware/_Gelada/queries/notes';

const TAB_COMPONENT_MAP = {
  [TAB_TYPE_KEYS.addNewTab]: NoteAddOrUpdate,
  [TAB_TYPE_KEYS.editTab]: NoteAddOrUpdate,
  [TAB_TYPE_KEYS.listTab]: NotesList,
  [TAB_TYPE_KEYS.viewTab]: NoteDetails,
};

const EMPTY_NOTE = {
  attachments: [],
  id: null,
  message: '',
  subject: '',
};

const Note = forwardRef(({ entityId, entityType, errorMessageHandler }, ref) => {
  const [allNotes, setAllNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState(EMPTY_NOTE);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);
  const [tabType, setTabType] = useState(TAB_TYPE_KEYS.listTab);

  const client = useRef(Client(true)).current;

  const [executeAddGroupNote, { loading: isAddGroupNoteLoading }] = useMutation(ADD_GROUP_NOTE, {
    client,
    onCompleted(response) {
      const {
        note: { addGroupNote: returnedNote },
      } = response;

      handleAddNoteCompleted(currentNote, returnedNote);
    },
    onError(error) {
      handleError(error);
    },
  });

  const [executeAddUserNote, { loading: isAddUserNoteLoading }] = useMutation(ADD_USER_NOTE, {
    client,
    onCompleted(response) {
      const {
        note: { addUserNote: returnedNote },
      } = response;

      handleAddNoteCompleted(currentNote, returnedNote);
    },
    onError(error) {
      handleError(error);
    },
  });

  const [executeDeleteNote, { loading: isDeleteNoteLoading }] = useMutation(DELETE_NOTE, {
    client,
    onCompleted() {
      handleDeleteNoteCompleted();
    },
    onError(error) {
      handleError(error);
    },
  });

  const [executeDeleteNoteAttachments, { loading: isDeleteNoteAttachmentsLoading }] = useMutation(DELETE_NOTE_ATTACHMENTS, {
    client,
    onCompleted() {
      handleDeleteNoteAttachmentsCompleted();
    },
    onError(error) {
      handleError(error);
    },
  });

  const [executeGetGroupNotes, { loading: isGetGroupNoteLoading }] = useLazyQuery(GET_NOTES_BY_GROUP, {
    client,
    variables: { entityId },
    onCompleted(response) {
      const {
        note: { getByGroup: notes },
      } = response;

      handleGetNotes(notes);
    },
    onError(error) {
      handleError(error);
    },
  });

  const [executeGetUserNotes, { loading: isGetUserNoteLoading }] = useLazyQuery(GET_NOTES_BY_USER, {
    client,
    variables: { entityId },
    onCompleted(response) {
      const {
        note: { getByUser: notes },
      } = response;

      handleGetNotes(notes);
    },
    onError(error) {
      handleError(error);
    },
  });

  const [executeUpdateNote, { loading: isEditNoteLoading }] = useMutation(EDIT_NOTE, {
    client,
    onCompleted(response) {
      const {
        note: { editNote: returnedNote },
      } = response;

      handleUpdateNoteCompleted(currentNote, returnedNote);
    },
    onError(error) {
      handleError(error);
    },
  });

  function getNoteHandlers(entityType) {
    const noteHandlersMap = {
      [EntityTypeKeys.Group]: {
        add: executeAddGroupNote,
        get: executeGetGroupNotes,
      },
      [EntityTypeKeys.User]: {
        add: executeAddUserNote,
        get: executeGetUserNotes,
      },
    };

    return noteHandlersMap[entityType];
  }

  function getValidationErrors(validationRules) {
    return Object.keys(validationRules)
      .map((validationRuleKey) => {
        const { errorMessage: validationErrorMessage, validation } = validationRules[validationRuleKey];

        return !validation ? validationErrorMessage : null;
      })
      .filter((validationErrorMessage) => validationErrorMessage);
  }

  function getValidationRules(note) {
    const { message, subject } = note;

    return {
      SubjectEmpty: {
        errorMessage: 'A subject is required',
        validation: subject.trim().length,
      },
      MessageEmpty: {
        errorMessage: 'A note is required',
        validation: message.trim().length,
      },
    };
  }

  function handleAddNote() {
    setCurrentNote(EMPTY_NOTE);
    setTabType(TAB_TYPE_KEYS.addNewTab);
  }

  function handleAddNoteCompleted(note, returnedNote) {
    uploadFiles(note, returnedNote);
    setAllNotes((previousNotes) => [...previousNotes, returnedNote]);
  }

  function handleCancelNote() {
    setCurrentNote(EMPTY_NOTE);
    setTabType(TAB_TYPE_KEYS.listTab);
  }

  function handleDeleteNote(noteId) {
    executeDeleteNote({
      variables: {
        noteId,
      },
    });
  }

  function handleDeleteNoteCompleted() {
    setAllNotes((previousNotes) => previousNotes.filter((previousNote) => previousNote.id !== currentNote.id));
    setTabType(TAB_TYPE_KEYS.listTab);
  }

  function handleDeleteNoteAttachmentsCompleted() {
    setCurrentNote((previousNote) => ({
      ...previousNote,
      attachments: previousNote.attachments.filter((attachment) => !attachment.file),
    }));
  }

  function handleDragDropAttachment(files, note) {
    setCurrentNote((previousNote) => {
      const { attachments: previousNoteAttachments = [], id: previousNoteId } = previousNote;
      if (note.id !== previousNoteId) {
        return previousNote;
      }

      const updatedAttachments = uniqBy(
        [
          ...previousNoteAttachments,
          ...files.map((file) => {
            const { name: fileName, size: fileSizeInBytes, type: contentType } = file;
            return {
              asset: {
                contentType,
                fileName,
                fileSizeInBytes,
              },
              file,
            };
          }),
        ],
        'asset.fileName',
      );

      return {
        ...previousNote,
        attachments: updatedAttachments,
      };
    });
  }

  function handleEditNote(note) {
    setCurrentNote(note);
    setTabType(TAB_TYPE_KEYS.editTab);
  }

  function handleError(error, errorMessage) {
    console.error(error);
    errorMessageHandler({
      text: 'Something went wrong, please try again later.',
      type: 'error',
    });
  }

  function handleGetNotes(notes) {
    setAllNotes(notes);
  }

  function handleUpdateNote(event, value) {
    const {
      target: { name },
    } = event;

    const updatedNote = {
      ...currentNote,
      [name]: value,
    };

    setCurrentNote(updatedNote);
  }

  function handleUpdateNoteCompleted(note, returnedNote) {
    uploadFiles(note, returnedNote);
    setAllNotes((previousNotes) =>
      previousNotes.map((previousNote) => (previousNote.id === returnedNote.id ? returnedNote : previousNote)),
    );
  }

  function handleValidation(note) {
    const validationErrors = getValidationErrors(getValidationRules(note));

    if (validationErrors.length) {
      errorMessageHandler({
        text: validationErrors[0],
        type: 'error',
      });
      return false;
    }

    return true;
  }

  function handleViewNote(note) {
    setCurrentNote(note);
    setTabType(TAB_TYPE_KEYS.viewTab);
  }

  function uploadFiles(note, returnedNote) {
    const { attachments: updatedNoteAttachments } = returnedNote;
    const { attachments: noteAttachments } = note;

    const filesToUpload = noteAttachments
      .filter((attachment) => attachment.file)
      .map((attachment) => {
        const { file } = attachment;
        const { name: attachmentFileName } = file;

        const foundAttachment = updatedNoteAttachments.find(
          (updatedNoteAttachment) => updatedNoteAttachment.asset.fileName === attachmentFileName,
        );

        return {
          file,
          presignedPutUrl: foundAttachment.asset.presignedPutUrl,
        };
      });

    if (!filesToUpload.length) {
      setCurrentNote(returnedNote);
      setTabType(TAB_TYPE_KEYS.listTab);
      return;
    }

    const fileUploadPromises = filesToUpload.map((fileToUpload) => {
      const { file, presignedPutUrl } = fileToUpload;

      return axios.put(presignedPutUrl, file, { headers: { 'content-type': file.type } });
    });

    setIsPreloaderVisible(true);

    Promise.all(fileUploadPromises)
      .catch((error) => {
        handleError(error, 'Unable to upload attachments');

        const noteAttachmentIds = updatedNoteAttachments
          .filter((updatedNoteAttachment) =>
            filesToUpload.map((fileToUpload) => fileToUpload.file.name).includes(updatedNoteAttachment.asset.fileName),
          )
          .map((updatedNoteAttachment) => updatedNoteAttachment.id);

        executeDeleteNoteAttachments({
          variables: {
            noteAttachmentIds,
          },
        });
      })
      .finally(() => {
        setCurrentNote(returnedNote);
        setIsPreloaderVisible(false);
        setTabType(TAB_TYPE_KEYS.listTab);
      });
  }

  useImperativeHandle(ref, () => ({
    addOrUpdateNote() {
      if (!handleValidation(currentNote)) {
        return;
      }

      const { attachments, id, message, subject } = currentNote;

      const noteHandler = getNoteHandlers(entityType);

      const variables = {
        entityId,
        message,
        subject,
      };

      if (attachments.length) {
        variables.attachments = attachments.map((attachment) => {
          const {
            asset: { contentType, fileName, fileSizeInBytes },
          } = attachment;
          return {
            contentType,
            fileName,
            fileSizeInBytes,
            type: 'DOCUMENT',
          };
        });
      }

      if (tabType === TAB_TYPE_KEYS.addNewTab) {
        noteHandler.add({ variables });
        return;
      }

      if (tabType === TAB_TYPE_KEYS.editTab) {
        variables.noteId = id;

        executeUpdateNote({ variables });
      }
    },
    deleteNote() {
      if (!currentNote) {
        return;
      }

      handleDeleteNote(currentNote.id);
    },
  }));

  useEffect(() => {
    const noteHandler = getNoteHandlers(entityType);
    noteHandler.get();
  }, [entityType]);

  const isLoading =
    isAddGroupNoteLoading ||
    isAddUserNoteLoading ||
    isDeleteNoteLoading ||
    isDeleteNoteAttachmentsLoading ||
    isEditNoteLoading ||
    isGetGroupNoteLoading ||
    isGetUserNoteLoading ||
    isPreloaderVisible;
  const Tab = TAB_COMPONENT_MAP[tabType];

  return (
    <>
      {isLoading && <Preloader />}
      <Tab
        allNotes={allNotes}
        currentNote={currentNote}
        tabType={tabType}
        onAddHandler={handleAddNote}
        onCancelHandler={handleCancelNote}
        onDeleteHandler={handleDeleteNote}
        onDragDropHandler={handleDragDropAttachment}
        onEditHandler={handleEditNote}
        onUpdateHandler={handleUpdateNote}
        onViewHandler={handleViewNote}
      />
    </>
  );
});

Note.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
  errorMessageHandler: PropTypes.func,
};

export default Note;
