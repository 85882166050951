import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import QueryRequest from '../QueryRequest';

export const GET_PROJECT = gql`
  query GetProject($id: String!) {
    project {
      getProject(projectId: $id) {
        hasMobileApplication
        id
        logo {
          contentType
          presignedUrl
          s3Key
        }
        name
        services
        theme {
          backGroundColor
          buttonColor
          footerColor
          headerColor
          linkColor
          sideColor
        }
        settings {
          impersonateUserLink
          hasModuleProgression
        }
      }
    }
  }
`;

const getProject = ({ id, onError, onSuccessResult }) => (
  <Query client={Client()} query={GET_PROJECT} variables={{ id }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.project.getProject}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getProject.propTypes = {
  id: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getProject;
