import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DropDownMenu } from '../DropDownMenu/DropDownMenu';
import RightSidebar from '../RightNavBar/RightNavBar';
import { Container, Item, Title } from '../RightNavBar/styled';
import EditBtn from '../UI/Button/EditButton';
import Input from '../UI/Input/InputWhite';
import Tooltip from '../UI/Tooltip/Tooltips';

import LeftNavBar from './LeftNavBar';
import { ContentItem } from './styled';

import CreateAppComponent from '../../middleware/MyAppDashboard/createMyAppComponentRequest';
import DeleteAppComponent from '../../middleware/MyAppDashboard/deleteAppComponentRequest';
import UpdateAppComponentName from '../../middleware/MyAppDashboard/updateMyAppComponentNameRequest';

const Wrapper = styled.div`
  margin-top: 0.8em;
`;

const EditBox = styled.div`
  display: none;
  margin-bottom: 1.4em;
  position: relative;
`;

export const OptBtn = styled.button`
  background: transparent;
  border: none;
  margin: 0 -1em;
  padding: 0 1em;
`;

const CompItem = styled(ContentItem)`
  background-color: ${props => (props.isActive ? '#fff' : 'transparent')};
  border-radius: 5px;
  color: ${props => (props.isActive ? '#000' : '#fff')};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;

  &:hover {
    ${EditBox} {
      display: block;
    }
  }
`;

const AppBuilderNavBar = props => {
  const { version, getActiveCompId, defaultCompId } = props;

  const [isDnd, setIsDnd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeId, setActiveId] = useState(defaultCompId);
  const [comps, setComps] = useState(version.components);
  const [newCompTitle, setNewCompTitle] = useState('');
  const [createRequest, setCreateRequest] = useState(false);
  const [selectedComp, setSelectedComp] = useState({});
  const [updateRequest, setUpdateRequest] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  useEffect(() => {
    setComps(version.components);
  }, [version]);

  const handleGetActiveCompId = id => {
    getActiveCompId(id);
    setActiveId(id);
  };

  const handleCreate = () => {
    setIsOpen(true);
  };

  const handleUpdate = comp => {
    setSelectedComp(comp);
    setIsOpen(true);
  };

  const handleDelete = comp => {
    setSelectedComp(comp);
    setDeleteRequest(true);
  };

  const handleInfoUpdate = value => {
    setNewCompTitle(value);
  };

  const handleSaveBtn = () => {
    if (Object.keys(selectedComp).length === 0) setCreateRequest(true);
    else setUpdateRequest(true);
  };

  const handleComponentError = () => {
    setIsOpen(false);
    setNewCompTitle('');
    setCreateRequest(false);
    setUpdateRequest(false);
    setDeleteRequest(false);
  };

  const handleCreateComponentSuccess = data => {
    setIsOpen(false);
    setComps([...comps, data]);
    setNewCompTitle('');
    setCreateRequest(false);
  };

  const handleUpdateComponentSuccess = () => {
    const ind = comps.findIndex(comp => comp.id === selectedComp.id);
    comps.splice(ind, 1, {
      id: selectedComp.id,
      title: newCompTitle,
    });
    setIsOpen(false);
    setSelectedComp({});
    setNewCompTitle('');
    setUpdateRequest(false);
  };

  const handleDeleteComponentSuccess = () => {
    const tmp = comps.filter(comp => comp.id !== selectedComp.id);
    setComps(tmp);
    setSelectedComp({});
    setNewCompTitle('');
    setDeleteRequest(false);
  };

  const content = comps.map(comp => (
    <CompItem
      isActive={activeId === comp.id}
      key={comp.id}
      onClick={() => handleGetActiveCompId(comp.id)}
    >
      <p>{comp.title}</p>
      <EditBox>
        <EditBtn isClicked={() => setIsDnd(!isDnd)} />
        <DropDownMenu
          onBlur={() => setIsDnd(!isDnd)}
          open={isDnd}
          style={{ top: '2em', left: '-1em', padding: '0.6em 0' }}
        >
          <li>
            <OptBtn onClick={handleCreate}>New component</OptBtn>
          </li>
          <li>
            <OptBtn onClick={() => {}}>Duplicate</OptBtn>
          </li>
          <li>
            <OptBtn onClick={() => handleUpdate(comp)}>Rename</OptBtn>
          </li>
          <li>
            <OptBtn onClick={() => handleDelete(comp)}>Delete</OptBtn>
          </li>
        </DropDownMenu>
      </EditBox>
    </CompItem>
  ));

  return (
    <>
      <LeftNavBar title={version.app.title}>
        <Wrapper>
          {content}
          {isOpen && (
            <RightSidebar
              hasDelete={false}
              hasNoFooter
              onCancel={() => setIsOpen(false)}
              onSave={handleSaveBtn}
            >
              <div>
                <h3>
                  {Object.keys(selectedComp).length === 0
                    ? 'Create an component'
                    : `Rename ${selectedComp.title}`}
                </h3>
                <Container>
                  <Item>
                    <Title>
                      <b>Title</b>
                      <Tooltip />
                    </Title>
                    <Input isChanged={handleInfoUpdate} name="title" text="" />
                  </Item>
                </Container>
              </div>
            </RightSidebar>
          )}
        </Wrapper>
      </LeftNavBar>
      {createRequest && (
        <CreateAppComponent
          componentTitle={newCompTitle}
          identifier={version.app.identifier}
          onError={handleComponentError}
          onSuccessResult={handleCreateComponentSuccess}
          version={version.version}
        />
      )}
      {updateRequest && (
        <UpdateAppComponentName
          componentId={selectedComp.id}
          onError={handleComponentError}
          onSuccessResult={handleUpdateComponentSuccess}
          title={newCompTitle}
        />
      )}
      {deleteRequest && (
        <DeleteAppComponent
          componentId={selectedComp.id}
          onError={handleComponentError}
          onSuccessResult={handleDeleteComponentSuccess}
        />
      )}
    </>
  );
};

AppBuilderNavBar.propTypes = {
  version: PropTypes.shape({
    app: PropTypes.shape(),
    components: PropTypes.array,
    version: PropTypes.string,
  }).isRequired,
  defaultCompId: PropTypes.number,
  getActiveCompId: PropTypes.func,
};

AppBuilderNavBar.defaultProps = {
  defaultCompId: null,
  getActiveCompId: () => {},
};

export default AppBuilderNavBar;
