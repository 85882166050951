import React, { useState, useContext } from 'react';

import { Input } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AppContext from '../../../context/AppContext';
import UpdateContentPageStatusRequest from '../../../middleware/ContentPage/updateContentPageStatusRequest';
import { ModalButtonGroup } from '../../ButtonGroup/styled';
import EditBtn from '../../UI/Button/EditButton';
import SquareButton from '../../UI/Button/SquareButton';
import NormalModal from '../../UI/Modal/NormalModal';

const DDContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  left: -8rem;
  top: 2rem;
  position: absolute;
  visibility: ${(props) => (props.isDropDownOpen ? 'visible' : 'hidden')};
  width: 9em;
`;

const Wrapper = styled.div``;

const DDItem = styled.button`
  background-color: inherit;
  border: none;

  &:hover {
    background-color: rgba(9, 30, 66, 0.04);
    fill: rgba(9, 30, 66, 0.04);
  }
`;

const Dropdown = (props) => {
  const { item, handleRefresh, onSideBarOpen, handleDeleteItem, isSettingsOnly, contentGroupType } = props;

  const { handleShowSidebar, setActionsSidebarDetails } = useContext(AppContext);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteText, setDeleteText] = useState('');

  const handleDropDown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const handleDuplicate = () => {
    onSideBarOpen('duplicate page');
  };

  const handleSetting = () => {
    onSideBarOpen('setting');
  };

  const handleNewPage = () => {
    onSideBarOpen('new page');
  };

  const handlePageActions = (id) => {
    setActionsSidebarDetails({
      type: 'page',
      id,
      activePageId: id,
      config: {
        contentPageId: id,
      },
    });
    handleShowSidebar([true, 'ACTIONS', id]);
  };

  const handleNewSubPage = () => {
    onSideBarOpen('new subpage');
  };

  const handleDeletePage = (id) => {
    if (deleteText.toLowerCase() !== 'delete') return;
    setDeleteRequest(true);
    setDeleteId(id);
  };

  const handleError = () => {};

  const onDelete = (id) => {
    setDeleteRequest(false);
    handleRefresh();
    handleDeleteItem(id);
    setDeleteText('');
  };

  return (
    <Wrapper>
      <EditBtn isClicked={() => handleDropDown()} />
      <DDContainer isDropDownOpen={isDropDownOpen} onMouseLeave={handleDropDown}>
        {isSettingsOnly ? (
          <DDItem onClick={handleSetting}>Setting</DDItem>
        ) : (
          <>
            <DDItem onClick={handleSetting}>Setting</DDItem>
            {['learning', 'cartoon', 'questionnaire'].includes(contentGroupType) && (
              <DDItem onClick={() => handlePageActions(item.id)}>Actions</DDItem>
            )}
            <DDItem onClick={handleNewPage}>New page</DDItem>
            <DDItem onClick={handleNewSubPage}>New sub page</DDItem>
            <DDItem onClick={handleDuplicate}>Duplicate</DDItem>
            <DDItem onClick={() => setIsDeleteModalOpen(true)}>Delete</DDItem>
          </>
        )}
      </DDContainer>
      {deleteRequest && (
        <UpdateContentPageStatusRequest contentPageId={deleteId} status={2} onError={handleError} onSuccessResult={onDelete} />
      )}

      <>
        <NormalModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
          <h3>Are you sure you would like to delete {item.data.title}</h3>
          <p>
            This operation can not be undone, all linked assets to the page such as images, documents and videos will not be deleted.
            Conditions added to the page content will be unlinked.
          </p>
          <p>To confirm, write delete in the input below and click confirm</p>
          <Input
            isLabelHidden
            labelText="Delete confirmation"
            name="delete_confirmation"
            value={deleteText}
            onChange={({ target: { value } }) => {
              setDeleteText(value);
            }}
          />
          <ModalButtonGroup>
            <SquareButton isClicked={() => setIsDeleteModalOpen(false)}>No, cancel</SquareButton>
            <SquareButton bgColor="#dc3545" color="white" isClicked={() => handleDeletePage(item.id)}>
              Confirm
            </SquareButton>
          </ModalButtonGroup>
        </NormalModal>
      </>
    </Wrapper>
  );
};

Dropdown.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  item: PropTypes.shape({
    children: PropTypes.array,
    data: PropTypes.object,
    hasChildren: PropTypes.bool,
    id: PropTypes.number,
    isChildrenLoading: PropTypes.bool,
    isExpanded: PropTypes.bool,
  }).isRequired,
  onSideBarOpen: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func,
  isSettingsOnly: PropTypes.bool,
  contentGroupType: PropTypes.string,
};

Dropdown.defaultProps = {
  handleDeleteItem: () => {},
  isSettingsOnly: false,
  contentGroupType: '',
};

export default Dropdown;
