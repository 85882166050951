import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SINGLE_LINE_FEEDBACK_CONFIG } from './UseUpsertSummaryFeedbackConfigSingleLine.graphql';

const useUpsertSummaryFeedbackConfigSingleLine = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeUpsertSummaryFeedbackConfigSingleLine, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? CREATE_SINGLE_LINE_FEEDBACK_CONFIG,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { addSummaryFeedbackConfigurationSingleLine: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpsertSummaryFeedbackConfigSingleLine = ({ variables }) => {
    executeUpsertSummaryFeedbackConfigSingleLine({
      variables,
    });
  };

  return {
    handleUpsertSummaryFeedbackConfigSingleLine,
    isLoading,
  };
};

export { useUpsertSummaryFeedbackConfigSingleLine };
