import React from 'react';

import { SocialProvider } from './SocialPageContext';

const SocialPageState = ({ children }) => {
  const initialState = {
    posts: [],
    users: [],
    comments: [],
    topics: [],
    activePostId: null,
    activeUserId: null,
    activeCommentId: null,
    activeUserPost: null,
    isAddNewComment: false,
    isAddNewPost: false,
    activeTopicId: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setPosts':
        return {
          ...state,
          posts: action.newState,
        };

      case 'updatePost':
        return {
          ...state,
          posts: state.posts.map((item) => {
            const {
              newState: { id, message },
            } = action;

            if (item.id !== id) {
              return item;
            }

            return {
              ...item,
              message,
            };
          }),
        };

      case 'deletePost':
        return {
          ...state,
          posts: state.posts.filter((item) => item.id !== action.newState),
        };

      case 'setActivePostId':
        return {
          ...state,
          activePostId: action.newState,
        };

      case 'setIsAddNewPost':
        return {
          ...state,
          isAddNewPost: action.newState,
        };

      case 'setComments':
        return {
          ...state,
          comments: action.newState,
        };

      case 'updateComment':
        return {
          ...state,
          comments: state.comments.map((item) => {
            const {
              newState: { id, message },
            } = action;

            if (item.id !== id) {
              return item;
            }

            return {
              ...item,
              message,
            };
          }),
        };

      case 'deleteComment':
        return {
          ...state,
          comments: state.comments.filter((item) => item.node.id !== action.newState),
        };

      case 'setActiveCommentId':
        return {
          ...state,
          activeCommentId: action.newState,
        };

      case 'setIsAddNewComment':
        return {
          ...state,
          isAddNewComment: action.newState,
        };

      case 'setUsers':
        return {
          ...state,
          users: action.newState,
        };

      case 'setActiveUserPost':
        return {
          ...state,
          activeUserPost: action.newState,
        };

      case 'setActiveUserId':
        return {
          ...state,
          activeUserId: action.newState,
        };

      case 'setActiveTopicId':
        return {
          ...state,
          activeTopicId: action.newState,
        };

      case 'setTopics':
        return {
          ...state,
          topics: action.newState,
        };

      case 'addTopic':
        return {
          ...state,
          topics: [...state.topics, action.newState],
        };

      case 'editTopic':
        return {
          ...state,
          topics: state.topics.map((item) => {
            const { newState: topic } = action;
            if (item.id === topic.id) {
              return topic;
            }
            return item;
          }),
        };

      case 'deleteTopic':
        return {
          ...state,
          topics: state.topics.filter((item) => item.id !== action.newState),
        };

      default:
        return state;
    }
  };
  return (
    <SocialProvider initialState={initialState} reducer={reducer}>
      {children}
    </SocialProvider>
  );
};
export default SocialPageState;
