import { gql } from '@apollo/client';

export const CREATE_SUMMARY_FEEDBACK_RANK_AND_NOTE_CHECKBOX_MUTATION = gql`
  mutation createSummaryFeedbackRankAndNoteCheckbox(
    $contentSnippetSummaryId: Int!
    $note: String
    $questionAnswerId: Int!
    $questionResponseId: Int!
    $rank: Int
  ) {
    summaryFeedback {
      addFeedbackRankAndNoteCheckbox(
        contentSnippetSummaryId: $contentSnippetSummaryId
        checkboxResponseId: $questionResponseId
        note: $note
        questionAnswerId: $questionAnswerId
        sort: $rank
      )
    }
  }
`;
