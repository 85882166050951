import React, { useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { addMonths, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';

import ExportPage from './ExportPage';
import CONSTANTS from './Shared/Constants';
import getDropdownOptions from './Shared/getDropdownOptions';

import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import client from '../../../middleware/client';
import { EXPORT_MODULE_PAGES } from '../../../middleware/Export/exportModulePages';
import { GET_USERFLOW_STEPS } from '../../../middleware/UserFlow/getUserFlowSteps';
import { downloadFileByUrl } from '../../../utils/utils';

const ExportModulePagesPage = () => {
  const { projectId } = useParams();

  const [apiError, setApiError] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(addMonths(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [defaultStepId] = useState(CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID);
  const [hasLoadingUserFlowStepsError, setHasLoadingUserFlowStepsError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [stepId, setStepId] = useState(defaultStepId);
  const [stepOptions, setStepOptions] = useState(null);

  const { loading: isLoadingUserFlowSteps } = useQuery(GET_USERFLOW_STEPS, {
    client,
    onCompleted: (data) => {
      setIsFormValid(isValid(dateFrom) && isValid(dateTo) && stepId > -1);
      setStepOptions(
        getDropdownOptions(
          CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID,
          CONSTANTS.DROPDOWN.DEFAULT_OPTION_LABEL.STEP,
          data.userFlowStep.getStepsOfProject,
          [{ key: 'stepName' }, { key: 'url', prefix: 'URL: /' }],
          'id',
        ),
      );
    },
    onError: (error) => {
      setApiError(error);
      setHasLoadingUserFlowStepsError(true);
    },
    variables: {
      projectId,
    },
  });

  const [sendExportModulePagesQuery, { loading: isGeneratingReport }] = useLazyQuery(EXPORT_MODULE_PAGES, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { contentType, s3Key } = data.report.exportModulePages;
      const signedUrl = getSignedUrl(s3Key, contentType);

      downloadFileByUrl(signedUrl);
    },
    onError: (error) => {
      setApiError(error);
    },
  });

  const download = (fileType = 'EXCEL' | 'CSV') => {
    if (stepId === CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID) {
      return;
    }

    sendExportModulePagesQuery({
      variables: {
        dateFrom,
        dateTo,
        ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        stepId,
        fileType,
      },
    });
  };

  const handleDateFromChange = (value) => {
    setDateFrom(value);
    setIsFormValid(isValid(value) && isValid(dateTo) && stepId > -1);
  };

  const handleDateToChange = (value) => {
    setDateTo(value);
    setIsFormValid(isValid(dateFrom) && isValid(value) && stepId > -1);
  };

  const handleOptionSelected = (selectedId) => {
    setStepId(selectedId);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo) && selectedId > -1);
  };

  const options = {
    apiError,
    downloadCSVCallback: () => download('CSV'),
    filtering: {
      date: {
        dateFrom,
        dateTo,
        handleDateFromChangeCallback: handleDateFromChange,
        handleDateToChangeCallback: handleDateToChange,
      },
      userFlowSteps: {
        handleOptionSelectedCallback: handleOptionSelected,
        hasLoadError: hasLoadingUserFlowStepsError,
        options: stepOptions,
        selectedId: stepId,
      },
    },
    flags: {
      isFormValid,
      preloader: {
        isGeneratingReport,
        isLoadingUserFlowSteps,
      },
    },
    infoBannerText: 'This page exports module progress based on the selected filters',
  };

  return <ExportPage options={options} />;
};

export default ExportModulePagesPage;
