import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const SEARCH_TAGS = gql`
  query searchTag($projectId: String!, $text: String!) {
    tagQuery {
      searchTags(projectId: $projectId, text: $text) {
        id
        name
      }
    }
  }
`;

const searchTags = ({ projectId, text, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={SEARCH_TAGS}
    variables={{ projectId, text }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data && Object.entries(data).length !== 0 && data.tagQuery.searchTags
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);
searchTags.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default searchTags;
