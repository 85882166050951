/* eslint-disable @typescript-eslint/indent */

import {
  createAccessTokenCookie,
  createCookie,
  createCustomBuildProjectGuidCookie,
  createImpersonatedUserAccessTokenCookie,
  createImpersonatedUserDataCookie,
  createImpersonatedUserRefreshTokenCookie,
  createImpersonatedUserTemporaryTokenCookie,
  createLastOrganisationVisitedCookie,
  createLastProjectVisitedCookie,
  createLastUrlBeforeAuthenticationExpiredCookie,
  createOrganisationIdCookie,
  createProjectDomainCookie,
  createProjectGuidCookie,
  createProjectLogoUrlCookie,
  createProjectNameCookie,
  createRedirectAfterLoginUrlCookie,
  createRefreshTokenCookie,
  createReturnUrlCookie,
  createStopImpersonationReturnUrlCookie,
  createTemporaryTokenCookie,
  createUserDataCookie,
  deleteAccessTokenCookie,
  deleteCookie,
  deleteCustomBuildProjectGuidCookie,
  deleteImpersonatedUserAccessTokenCookie,
  deleteImpersonatedUserDataCookie,
  deleteImpersonatedUserRefreshTokenCookie,
  deleteImpersonatedUserTemporaryTokenCookie,
  deleteLastOrganisationVisitedCookie,
  deleteLastProjectVisitedCookie,
  deleteLastUrlBeforeAuthenticationExpiredCookie,
  deleteOrganisationIdCookie,
  deleteProjectDomainCookie,
  deleteProjectGuidCookie,
  deleteProjectLogoUrlCookie,
  deleteProjectNameCookie,
  deleteRedirectAfterLoginUrlCookie,
  deleteRefreshTokenCookie,
  deleteReturnUrlCookie,
  deleteStopImpersonationReturnUrlCookie,
  deleteTemporaryTokenCookie,
  deleteUserDataCookie,
  deserializeCookie,
  getAccessTokenCookie,
  getAllCookies,
  getCookieValue,
  getCustomBuildProjectGuidCookie,
  getImpersonatedUserAccessTokenCookie,
  getImpersonatedUserDataCookie,
  getImpersonatedUserRefreshTokenCookie,
  getImpersonatedUserTemporaryTokenCookie,
  getLastOrganisationVisitedCookie,
  getLastProjectVisitedCookie,
  getLastUrlBeforeAuthenticationExpiredCookie,
  getOrganisationIdCookie,
  getProjectDomainCookie,
  getProjectGuidCookie,
  getProjectLogoUrlCookie,
  getProjectNameCookie,
  getRedirectAfterLoginUrlCookie,
  getRefreshTokenCookie,
  getReturnUrlCookie,
  getStopImpersonationReturnUrlCookie,
  getTemporaryTokenCookie,
  getUserDataCookie,
  isSecureCookie,
  isValidCookieSize,
  serializeCookie,
  setCookie,
} from './useCookie.helpers';
import { IUseCookie } from './useCookie.interfaces';

const useCookie = (): IUseCookie => {
  return {
    createAccessTokenCookie,
    createCookie,
    createCustomBuildProjectGuidCookie,
    createImpersonatedUserAccessTokenCookie,
    createImpersonatedUserDataCookie,
    createImpersonatedUserRefreshTokenCookie,
    createImpersonatedUserTemporaryTokenCookie,
    createLastOrganisationVisitedCookie,
    createLastProjectVisitedCookie,
    createLastUrlBeforeAuthenticationExpiredCookie,
    createOrganisationIdCookie,
    createProjectDomainCookie,
    createProjectGuidCookie,
    createProjectLogoUrlCookie,
    createProjectNameCookie,
    createRedirectAfterLoginUrlCookie,
    createRefreshTokenCookie,
    createReturnUrlCookie,
    createStopImpersonationReturnUrlCookie,
    createTemporaryTokenCookie,
    createUserDataCookie,
    deleteAccessTokenCookie,
    deleteCookie,
    deleteCustomBuildProjectGuidCookie,
    deleteImpersonatedUserAccessTokenCookie,
    deleteImpersonatedUserDataCookie,
    deleteImpersonatedUserRefreshTokenCookie,
    deleteImpersonatedUserTemporaryTokenCookie,
    deleteLastOrganisationVisitedCookie,
    deleteLastProjectVisitedCookie,
    deleteLastUrlBeforeAuthenticationExpiredCookie,
    deleteOrganisationIdCookie,
    deleteProjectDomainCookie,
    deleteProjectGuidCookie,
    deleteProjectLogoUrlCookie,
    deleteProjectNameCookie,
    deleteRedirectAfterLoginUrlCookie,
    deleteRefreshTokenCookie,
    deleteReturnUrlCookie,
    deleteStopImpersonationReturnUrlCookie,
    deleteTemporaryTokenCookie,
    deleteUserDataCookie,
    deserializeCookie,
    getAccessTokenCookie,
    getAllCookies,
    getCookieValue,
    getCustomBuildProjectGuidCookie,
    getImpersonatedUserAccessTokenCookie,
    getImpersonatedUserDataCookie,
    getImpersonatedUserRefreshTokenCookie,
    getImpersonatedUserTemporaryTokenCookie,
    getLastOrganisationVisitedCookie,
    getLastProjectVisitedCookie,
    getLastUrlBeforeAuthenticationExpiredCookie,
    getOrganisationIdCookie,
    getProjectDomainCookie,
    getProjectGuidCookie,
    getProjectLogoUrlCookie,
    getProjectNameCookie,
    getRedirectAfterLoginUrlCookie,
    getRefreshTokenCookie,
    getReturnUrlCookie,
    getStopImpersonationReturnUrlCookie,
    getTemporaryTokenCookie,
    getUserDataCookie,
    isSecureCookie,
    isValidCookieSize,
    serializeCookie,
    setCookie,
  };
};

export { useCookie };
