import { useEkardoMutation } from '../useEkardoMutation';

import { CREATE_SUMMARY_FEEDBACK_RANK_AND_NOTE_CHECKBOX_MUTATION } from './useCreateSummaryFeedbackRankAndNoteCheckbox.graphql';
import {
  ICreateSummaryFeedbackRankAndNoteCheckboxMutationGraphQLResponse,
  ICreateSummaryFeedbackRankAndNoteCheckboxMutationVariables,
  IHandleCreateSummaryFeedbackRankAndNoteCheckboxParams,
  IUseCreateSummaryFeedbackRankAndNoteCheckboxOptions,
  IUseCreateSummaryFeedbackRankAndNoteCheckbox,
} from './useCreateSummaryFeedbackRankAndNoteCheckbox.interfaces';

const useCreateSummaryFeedbackRankAndNoteCheckbox = (
  options?: IUseCreateSummaryFeedbackRankAndNoteCheckboxOptions
): IUseCreateSummaryFeedbackRankAndNoteCheckbox => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateSummaryFeedbackRankAndNoteCheckboxOptions);

  const [executeCreateSummaryFeedbackRankAndNoteCheckbox, { loading: isLoading }] = useEkardoMutation<
    ICreateSummaryFeedbackRankAndNoteCheckboxMutationGraphQLResponse,
    ICreateSummaryFeedbackRankAndNoteCheckboxMutationVariables
  >({
    mutation: mutation ?? CREATE_SUMMARY_FEEDBACK_RANK_AND_NOTE_CHECKBOX_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { addFeedbackRankAndNoteCheckbox: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateSummaryFeedbackRankAndNoteCheckbox = async ({
    contentSnippetSummaryId,
    questionAnswerId,
    questionResponseId,
    note,
    rank,
  }: IHandleCreateSummaryFeedbackRankAndNoteCheckboxParams) => {
    await executeCreateSummaryFeedbackRankAndNoteCheckbox({
      variables: {
        contentSnippetSummaryId,
        questionAnswerId,
        questionResponseId,
        note,
        rank,
      },
    });
  };

  return {
    handleCreateSummaryFeedbackRankAndNoteCheckbox,
    isLoading,
  };
};

export { useCreateSummaryFeedbackRankAndNoteCheckbox };
