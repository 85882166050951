import React from 'react';

import { Icons as IconsNetfront } from '@netfront/ui-library';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { Router } from 'react-router-dom';

import '@netfront/ui-library/dist/index.css';
import App from './App';
import Icons from './legacy/components/Icons/Icons';

import './legacy/styles/index.scss';

const history = createBrowserHistory();
ReactDOM.render(
  <Router history={history}>
    <Icons />
    <IconsNetfront />
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
        }}
      />
    </div>
    <App />
  </Router>,
  document.getElementById('root'),
);
