import React, { useState, useContext, useEffect } from 'react';

import { TabSet } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import Preloader from '../../../../legacy/components/Preloader/Preloader';
import AppContext from '../../../../legacy/context/AppContext';
import { useToast } from '../../../../legacy/hooks';
import { SnippetTypeConst } from '../../../constants';
import { useCreateSummarySnippet, useUpdateSummarySnippet } from '../../../hooks';
import { UpsertSummarySnippetFeedbackTab } from '../../tabs/UpsertSummarySnippetFeedbackTab';
import { UpsertSummarySnippetGeneralTab } from '../../tabs/UpsertSummarySnippetGeneralTab';

const UpsertSummarySnippetSidebar = ({ onClose, containerId, onCreate, onRefreshPage, onDelete, snippet, isAddNew = false }) => {
  const { handleShowSidebar } = useContext(AppContext);
  const { handleToastError, handleToastSuccess } = useToast();
  const [selectedQuestionId, setSelectedQuestionId] = useState();
  const [selectedSnippetFormId, setSelectedSnippetFormId] = useState();
  const [selectedUserFlowStepId, setSelectedUserFlowStepId] = useState();
  const [selectedQuestionFormId, setSelectedQuestionFormId] = useState();

  const { handleCreateSummarySnippet, isLoading: isCreateSummarySnippetLoading = false } = useCreateSummarySnippet({
    onCompleted: ({ contentSnippet }) => {
      const { id, sort } = contentSnippet;
      onCreate?.(id, sort, SnippetTypeConst.SUMMARY, containerId, contentSnippet);
      handleToastSuccess({
        message: `Summary feedback successfully created`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateSummarySnippet, isLoading: isUpdateSummarySnippetLoading = false } = useUpdateSummarySnippet({
    onCompleted: () => {
      onRefreshPage?.();
      handleToastSuccess({
        message: `Summary feedback successfully updated`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSave = () => {
    if (snippet) {
      handleUpdateSummarySnippet({
        variables: {
          baseSnippet: {
            id: snippet.containerId,
            visible: true,
            entityTitle: '',
          },
          summarySnippet: {
            userFlowStepId: selectedUserFlowStepId,
            contentSnippetQuestionId: selectedQuestionId,
            contentSnippetFormId: selectedSnippetFormId,
          },
        },
      });
    } else {
      handleCreateSummarySnippet({
        variables: {
          baseSnippet: {
            containerId,
            visible: true,
            entityTitle: '',
          },
          summarySnippet: {
            userFlowStepId: selectedUserFlowStepId,
            contentSnippetQuestionId: selectedQuestionId,
            contentSnippetFormId: selectedSnippetFormId,
          },
        },
      });
    }
  };

  const handleUserFlowStepChange = (id) => {
    setSelectedQuestionId(undefined);
    setSelectedQuestionFormId(undefined);
    setSelectedUserFlowStepId(id);
  };

  const handleQuestionChange = (questionId, contentSnippetFormId) => {
    setSelectedSnippetFormId(Number(contentSnippetFormId));
    setSelectedQuestionId(Number(questionId));
    setSelectedQuestionFormId(`${contentSnippetFormId}-${questionId}`);
  };

  useEffect(() => {
    if (!snippet) return;
    setSelectedQuestionId(snippet.contentSnippetQuestionId);
    setSelectedSnippetFormId(snippet.contentSnippetFormId);
    setSelectedUserFlowStepId(snippet.userFlowStepId);
    setSelectedQuestionFormId(`${snippet.contentSnippetFormId}-${snippet.contentSnippetQuestionId}`);
  }, [snippet]);

  const editTabs = [
    {
      iconId: 'id_general_tab_icon',
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <UpsertSummarySnippetGeneralTab
          initialQuestionFormId={selectedQuestionFormId}
          initialQuestionId={Number(selectedQuestionId)}
          initialUserFlowStepId={Number(selectedUserFlowStepId)}
          hasDelete
          onClose={onClose}
          onDelete={() => {
            handleShowSidebar([false, '', null]);
            onDelete(snippet);
          }}
          onSave={handleSave}
          onUpdateModule={handleUserFlowStepChange}
          onUpdateQuestion={handleQuestionChange}
        />
      ),
    },
    {
      iconId: 'id_seo_tab_icon',
      id: 'id_feedback_icon',
      label: 'Feedback',
      view: () => (
        <UpsertSummarySnippetFeedbackTab
          questionId={selectedQuestionId}
          snippet={snippet}
          hasDelete
          onClose={onClose}
          onDelete={() => {
            handleShowSidebar([false, '', null]);
            onDelete(snippet);
          }}
          onSave={handleSave}
        />
      ),
    },
  ];

  const addTabs = [
    {
      iconId: 'id_general_tab_icon',
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <UpsertSummarySnippetGeneralTab
          initialQuestionFormId={selectedQuestionFormId}
          initialQuestionId={Number(selectedQuestionId)}
          initialUserFlowStepId={Number(selectedUserFlowStepId)}
          onClose={onClose}
          onSave={handleSave}
          onUpdateModule={handleUserFlowStepChange}
          onUpdateQuestion={handleQuestionChange}
        />
      ),
    },
  ];

  const tabs = isAddNew ? addTabs : editTabs;

  const isLoading = isCreateSummarySnippetLoading || isUpdateSummarySnippetLoading;

  return (
    <>
      {isLoading && <Preloader />}
      <TabSet defaultActiveTabId="id_general_tab" tabs={tabs} />
    </>
  );
};

UpsertSummarySnippetSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  containerId: PropTypes.number,
  isAddNew: PropTypes.bool,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  snippet: PropTypes.shape(),
};

export { UpsertSummarySnippetSidebar };
