import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const TagBtn = styled.label`
  align-items: center;
  background-color: ${(props) => (props.active ? '#4BC2CA' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#000')};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  border: 1px solid #4bc2ca;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 0 1em 1em 0;
  padding: 10px;
  width: fit-content;
`;

const ClickableTag = (props) => {
  const { isActive, isClicked, isDisabled, tag } = props;
  const [active, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    setActive(!active);
    isClicked(!active, tag);
  };

  return (
    <TagBtn active={active} isDisabled={isDisabled} onClick={handleClick}>
      {tag.name}
    </TagBtn>
  );
};

ClickableTag.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  isClicked: PropTypes.func,
  isDisabled: PropTypes.bool,
};
ClickableTag.defaultProps = {
  isActive: false,
  isClicked: () => {},
  isDisabled: false,
};

export default ClickableTag;
