const HOUR_TO_SECONDS = 3600;

const DAYS_TO_SECONDS = 86400;

const YEAR_TO_SECONDS = 31536000;

const conditionDateTimespanConstants = Object.freeze({
  secondsInHour: HOUR_TO_SECONDS,
  secondsInDay: DAYS_TO_SECONDS,
  secondsInYear: YEAR_TO_SECONDS,
});

export { conditionDateTimespanConstants };
