import gql from 'graphql-tag';

export const CREATE_SINGLE_LINE_FEEDBACK_CONFIG = gql`
  mutation createSingleLineSummaryFeedbackConfig($contentSnippetSummaryId: Int!, $label: String!) {
    summaryFeedback {
      addSummaryFeedbackConfigurationRankAndNote(
        contentSnippetSummaryId: $contentSnippetSummaryId
        label: $label
        configuration: [NOTE, EDIT_NOTE]
      )
    }
  }
`;
