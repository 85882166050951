import { useEkardoMutation } from '../useEkardoMutation';

import { CREATE_SUMMARY_FEEDBACK_MULTI_RESPONSE_TO_MULTI_RESPONSE_MUTATION } from './useCreateSummaryFeedbackMultiResponseToMultiResponse.graphql';
import {
  ICreateSummaryFeedbackMultiResponseToMultiResponseMutationGraphQLResponse,
  ICreateSummaryFeedbackMultiResponseToMultiResponseMutationVariables,
  IHandleCreateSummaryFeedbackMultiResponseToMultiResponseParams,
  IUseCreateSummaryFeedbackMultiResponseToMultiResponseOptions,
  IUseCreateSummaryFeedbackMultiResponseToMultiResponse,
} from './useCreateSummaryFeedbackMultiResponseToMultiResponse.interfaces';

const useCreateSummaryFeedbackMultiResponseToMultiResponse = (
  options?: IUseCreateSummaryFeedbackMultiResponseToMultiResponseOptions
): IUseCreateSummaryFeedbackMultiResponseToMultiResponse => {
  const { mutation, onCompleted, onError, product, token } =
    options ?? ({} as IUseCreateSummaryFeedbackMultiResponseToMultiResponseOptions);

  const [executeCreateSummaryFeedbackMultiResponseToMultiResponse, { loading: isLoading }] = useEkardoMutation<
    ICreateSummaryFeedbackMultiResponseToMultiResponseMutationGraphQLResponse,
    ICreateSummaryFeedbackMultiResponseToMultiResponseMutationVariables
  >({
    mutation: mutation ?? CREATE_SUMMARY_FEEDBACK_MULTI_RESPONSE_TO_MULTI_RESPONSE_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          summaryFeedback: { addFeedbackMultiResponseToMultiResponse: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateSummaryFeedbackMultiResponseToMultiResponse = async ({
    contentSnippetSummaryId,
    questionAnswerId,
    responseId,
    values,
  }: IHandleCreateSummaryFeedbackMultiResponseToMultiResponseParams) => {
    await executeCreateSummaryFeedbackMultiResponseToMultiResponse({
      variables: {
        contentSnippetSummaryId,
        questionAnswerId,
        responseId,
        values,
      },
    });
  };

  return {
    handleCreateSummaryFeedbackMultiResponseToMultiResponse,
    isLoading,
  };
};

export { useCreateSummaryFeedbackMultiResponseToMultiResponse };
