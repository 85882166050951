import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_QUE_DRAGANDDROP_CONFIGURATION = gql`
  mutation createDragAndDropConfigRequest(
    $projectId: String!
    $description: String
    $type: EQuestionMatchType!
    $title: String!
    $matchItems: [MatchItemInputType]
    $availableResponses: [QuestionResponseMatchInputType]!
  ) {
    questionConfiguration {
      createQuestionConfigurationMatch(
        configuration: {
          type: $type
          randomise: true
          questionResponseSet: { title: $title }
          matchItems: $matchItems
        }
        baseConfiguration: { projectId: $projectId, description: $description }
        availableResponses: $availableResponses
      ) {
        id
        ... on Match {
          responseSet {
            id
          }
        }
      }
    }
  }
`;

const createDragAndDropConfigRequest = ({
  projectId,
  description,
  title,
  matchItems,
  type,
  availableResponses,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_QUE_DRAGANDDROP_CONFIGURATION}>
    {(createMatchVersion, { loading, error, data }) => {
      const variables = {
        projectId,
        description,
        title,
        matchItems,
        type,
        availableResponses,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.questionConfiguration.createQuestionConfigurationMatch.id,
          data
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createMatchVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createDragAndDropConfigRequest.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  availableResponses: PropTypes.arrayOf(
    PropTypes.shape({
      correctPosition: PropTypes.number,
      label: PropTypes.string,
      startPosition: PropTypes.number,
      static: PropTypes.bool,
      value: PropTypes.number,
    })
  ).isRequired,
  description: PropTypes.string.isRequired,
  matchItems: PropTypes.arrayOf(
    PropTypes.shape({
      sort: PropTypes.number,
      text: PropTypes.string,
    })
  ).isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

createDragAndDropConfigRequest.defaultProps = {};

export default createDragAndDropConfigRequest;
