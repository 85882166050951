import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

import { ContentStatusEnum } from '../../utils/utils';

export const UPDATE_CONTENT_PAGE_STATUS = gql`
  mutation UpdateContentPageStatus(
    $contentPageId: Int!
    $status: EContentPageStatus!
  ) {
    contentPage {
      updateContentPageStatus(
        contentPage: { contentPageId: $contentPageId, status: $status }
      ) {
        id
      }
    }
  }
`;

const updateContentPageStatusRequest = ({
  contentPageId,
  status,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_CONTENT_PAGE_STATUS}>
    {(updateContentGroupStatus, { loading, error, data }) => {
      let contentPageStatus;
      if (status === 0) {
        contentPageStatus = ContentStatusEnum.UNPUBLISHED;
      } else if (status === 1) {
        contentPageStatus = ContentStatusEnum.PUBLISHED;
      } else {
        contentPageStatus = ContentStatusEnum.DELETED;
      }

      const variables = {
        contentPageId,
        status: contentPageStatus,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentPage.updateContentPageStatus.id);
      }

      return (
        <MutationRequest
          isLoading={status === 2 && loading}
          mutation={updateContentGroupStatus}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateContentPageStatusRequest.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
};

export default updateContentPageStatusRequest;
