import styled from 'styled-components';

export const FieldWrapper = styled.div`
  margin-top: 1em;
  span {
    display: block;
  }
`;

export const Label = styled.label`
  font-size: 0.9em;
  font-weight: 600;
  display: block;
  margin-bottom: 2px;
  margin-top: 0;
`;
