import React from 'react';

import styled from 'styled-components';

import NoDataImg from '../../../static/img/open-box.png';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
`;

const NoDataAlert = () => (
  <Wrapper>
    <img alt="No data" src={NoDataImg} />
    <p>Sorry, it looks like there isn’t any data added.</p>
  </Wrapper>
);

export default NoDataAlert;
