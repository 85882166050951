import React, { useState, useEffect } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { useNewEkardoSiteUrls } from '../../hooks';
import IconApps from '../../static/img/card-apps.svg';
import IconAudio from '../../static/img/card-audio.svg';
import IconDocument from '../../static/img/card-document.svg';
import IconForm from '../../static/img/card-form.svg';
import IconImageMap from '../../static/img/card-image-map.svg';
import IconImage from '../../static/img/card-image.svg';
import IconVideo from '../../static/img/card-video.svg';
import IconDirectory from '../../static/img/directory.svg';
import IconSlider from '../../static/img/library-slider.svg';
import IconTags from '../../static/img/tags.svg';
import { assetType } from '../../utils/utils';

const CardsBox = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-right: -1.7em;
`;

const LibraryCards = [
  {
    title: 'Image',
    image: IconImage,
    path: `/assets/${assetType.IMAGE.toLowerCase()}`,
    id: 1,
  },
  {
    title: 'Document',
    image: IconDocument,
    path: `/assets/${assetType.DOCUMENT.toLowerCase()}`,
    id: 2,
  },
  {
    title: 'Video',
    image: IconVideo,
    path: `/assets/${assetType.VIDEO.toLowerCase()}`,
    id: 3,
  },
  {
    title: 'Audio',
    image: IconAudio,
    path: `/assets/${assetType.AUDIO.toLowerCase()}`,
    id: 4,
  },
  {
    title: 'Form',
    image: IconForm,
    path: '/forms',
    id: 5,
  },
  {
    title: 'Slider',
    image: IconSlider,
    path: '/slider',
    id: 6,
  },
  {
    title: 'App',
    image: IconApps,
    path: '/myApps',
    id: 7,
  },
  {
    title: 'Tags',
    image: IconTags,
    path: '/tags',
    id: 8,
  },
  {
    title: 'Directory',
    image: IconDirectory,
    path: '/directory',
    id: 9,
  },
  {
    title: 'Interactive Regions',
    image: IconImageMap,
    path: `/imageMap`,
    id: 10,
  },
];

const LibraryHomePage = (props) => {
  const {
    match: { url, params },
  } = props;

  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  const menu = (
    <CardsBox>
      {LibraryCards.map((card) => (
        <MenuCard key={card.id} href={`${url}${card.path}`} name={card.title} src={card.image} url={`${url}${card.path}`} />
      ))}
    </CardsBox>
  );

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library`,
        title: 'Library',
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage your images, documents, videos and audio files"
        pageDescription="Libray of the console"
        pageTitle="Library home page"
        params={params}
      >
        <ProjectNavBar title="Library" />
        {/* <BottomNavBar /> */}
        {menu}
      </PageWrapper>
    </>
  );
};

LibraryHomePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.any,
  }).isRequired,
  match: PropTypes.shape().isRequired,
};

export default LibraryHomePage;
