import gql from 'graphql-tag';

export const FRAGMENT_CONTENT_PAGE = gql`
  fragment ContentPage on ContentPageType {
    id
    title
    url
    status
    sort
    parentId
    contentGroupId
    minimumExposureTime
    audio {
      appVersionId
      assetId
      fileName
      contentType
      fileSizeInBytes
      isPublic
      presignedUrl
      projectId
      publicUrl
      s3Key
      transcript
      uploaded
    }
    bubbleTexts {
      text
      angle
      x
      y
      w
      shape
      tailType
      isTailVisible
      assetId
      id
      hasShadow
      character {
        id
        name
        projectId
        __typename
      }
    }
    cssStyle {
      id
      name
    }
    mode
    tags {
      id
      name
    }
    contentPageType {
      id
      name
    }
    asset {
      isAnimatedImage
      assetId
      s3Key
      contentType
      presignedUrl
    }
    relatedPage {
      id
      title
      contentGroup {
        id
        title
      }
    }
    featured
    releaseDate
    seoDescription
    seoTitle
    contentEventsOnPage {
      contentEvents {
        animation
        animationDirection
        event
        eventDirection
        eventTitle
        id
        entityId
        infinite
        millisecondDelay
        millisecondSpeed
        isDefaultOpen
      }
      type
    }
    contentPageConfigurationNavigation {
      backButtonToolTip
      enableBackButton
      enableNextButton
      nextButtonToolTip
    }
  }
`;
