export const topicsTableHeadings = [
  {
    Header: 'Topics',
    accessor: 'topic',
    width: '90%',
  },
  {
    Header: '',
    accessor: 'settings',
    width: '10%',
  },
];
