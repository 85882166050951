import React, { ChangeEvent, useEffect, useState } from 'react';

import { InputFieldWrapper } from '../InputFieldWrapper';

import { RangeInputProps } from './RangeInput.types';

import './RangeInput.css';

const RangeInput = ({
  hasPadding = true,
  iconAfter,
  iconBefore,
  id,
  initialValue = 0,
  isDisabled = false,
  isEncouraged = false,
  isLabelHidden = false,
  isRequired = false,
  label,
  maxValue = 5,
  message,
  minValue = 0,
  name,
  onChange,
  step = 1,
  ticks,
  tooltipText,
  tooltipPosition = 'end',
  labelFontWeight = 'light',
  hasLabelPadding = false,
}: RangeInputProps) => {
  const [value, setValue] = useState<number>();
  const [position, setPosition] = useState<string>();

  const calculateTrackStyle = (selectedValue: string, target: HTMLInputElement | null) => {
    if (!target) return;

    target.style.setProperty('--value', selectedValue);
    target.style.setProperty('--min', target.min == '' ? '0' : target.min);
    target.style.setProperty('--max', target.max == '' ? '100' : target.max);
  };

  const positionHoverValue = (selectedValue: string) => {
    const newValue = Number(((Number(selectedValue) - minValue) * 100) / (maxValue - minValue));
    const newPosition = 10 - newValue * 0.2;
    setPosition(`calc(${newValue}% + (${newPosition}px))`);
  };

  const handleChange = (selectedValue: string, targetElement: HTMLInputElement) => {
    positionHoverValue(selectedValue);
    calculateTrackStyle(selectedValue, targetElement);
    setValue(Number(selectedValue));
    onChange(Number(selectedValue));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    const targetElement = target as HTMLInputElement;

    const { value: selectedValue } = target;

    handleChange(selectedValue, targetElement);
  };

  useEffect(() => {
    setValue(Number(initialValue));
    positionHoverValue(String(initialValue));
    calculateTrackStyle(String(initialValue), document.getElementById(id) as HTMLInputElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const stepsArray = [...Array(Math.floor(maxValue) - Math.floor(minValue) + 1)];

  return (
    <div className="c-range-input-container">
      <InputFieldWrapper
        hasLabelPadding={hasLabelPadding}
        hasPadding={hasPadding}
        iconAfter={iconAfter}
        iconBefore={iconBefore}
        id={id}
        isEncouraged={isEncouraged}
        isLabelHidden={isLabelHidden}
        isRequired={isRequired}
        label={label}
        labelFontWeight={labelFontWeight}
        message={message}
        tooltipPosition={tooltipPosition}
        tooltipText={tooltipText}
        type="range"
        isContentStacked
      >
        <div className="c-range-input__wrapper">
          <div className="c-range-input__value-popup" style={{ left: position }}>
            {value}
          </div>
          <input
            className="c-range-input__input"
            disabled={isDisabled}
            id={id}
            list={ticks && ticks.length ? 'id_steplist' : ''}
            max={maxValue}
            min={minValue}
            name={name ?? id}
            step={step}
            type="range"
            value={value}
            onChange={handleInputChange}
          />
          {ticks && ticks.length && (
            <div className="c-range-input__labels" id="id_steplist">
              {stepsArray.map((_, i) => (
                <div key={i}>{ticks.find(({ sliderValue }) => i === sliderValue)?.label ?? ''}</div>
              ))}
            </div>
          )}
        </div>
      </InputFieldWrapper>
    </div>
  );
};

export { RangeInput };
