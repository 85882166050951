import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_TEXT_CONTENT_SNIPPET = gql`
  mutation updateTextContentSnippet(
    $snippetId: Int!
    $text: String!
    $fontColor: String
    $linkColor: String
    $listIconColor: String
    $title: String!
  ) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId, entityTitle: $title }
        contentSnippetText: { text: $text, fontColor: $fontColor, linkColor: $linkColor, listIconColor: $listIconColor }
      ) {
        id
      }
    }
  }
`;

const updateTextSnippetStyle = ({ snippetId, text, fontColor, linkColor, listIconColor, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_TEXT_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        text,
        fontColor,
        linkColor,
        listIconColor,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateTextSnippetStyle.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
  linkColor: PropTypes.string,
  listIconColor: PropTypes.string,
};

updateTextSnippetStyle.defaultProps = {
  fontColor: null,
  linkColor: null,
  listIconColor: null,
};

export default updateTextSnippetStyle;
