import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_EMBED_SNIPPET = gql`
  mutation updateSpacerSnippet($snippetId: Int!, $code: String!, $title: String!, $type: EContentSnippetEmbedType) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId, entityTitle: $title }
        contentSnippetEmbed: { code: $code, title: $title, type: $type }
      ) {
        id
      }
    }
  }
`;

const updateEmbedSnippetRequest = ({ snippetId, code, title, type, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_EMBED_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        code,
        title,
        type,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateEmbedSnippetRequest.propTypes = {
  code: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

updateEmbedSnippetRequest.defaultProps = {
  type: 'AUDIO',
};

export default updateEmbedSnippetRequest;
