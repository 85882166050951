import gql from 'graphql-tag';

const NOTE_ATTACHMENT_FRAGMENT = gql`
  fragment noteAttachment on NoteAttachmentGraphType {
    asset {
      contentType
      fileName
      fileSizeInBytes
      presignedDownloadUrl: presignedUrl
      presignedPutUrl
      s3Key
    }
    id
  }
`;

const NOTE_FRAGMENT = gql`
  fragment note on NoteGraphType {
    attachments {
      ...noteAttachment
    }
    creationDate
    createdByUser {
      firstname
      lastname
    }
    id
    message
    subject
  }
`;

export { NOTE_ATTACHMENT_FRAGMENT, NOTE_FRAGMENT };
