import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import {
  AudioBox,
  FileName,
  IconImg,
  IconImgS,
} from '../../pages/LibraryAssetPage/styled';
import DocIcon from '../../static/img/FileType/DocIcon.svg';
import PdfIcon from '../../static/img/FileType/PdfIcon.svg';
import XlsIcon from '../../static/img/FileType/XlsIcon.svg';

const Icon = styled(IconImg)`
  filter: unset;
`;
const IconS = styled(IconImgS)`
  filter: unset;
`;

function DocumentSnippet(props) {
  const { asset, assetId } = props;
  const index1 = asset.s3Key.lastIndexOf('/') + 1;
  const filename = asset.s3Key.substr(index1);
  const index2 = asset.s3Key.lastIndexOf('.') + 1;
  const type = asset.s3Key.substr(index2);
  const tmpURL = getSignedUrl(asset.s3Key, asset.contentType);

  return (
    <>
      <AudioBox>
        <a
          className="a_document"
          download
          href={tmpURL}
          key={assetId}
          rel="noopener noreferrer"
          target="_blank"
        >
          {type === 'pdf' && <Icon alt="file" src={PdfIcon} />}
          {type === 'docx' && <IconS alt="file" src={DocIcon} />}
          {type === 'xlsx' && <IconS alt="file" src={XlsIcon} />}
          <FileName>{filename}</FileName>
        </a>
      </AudioBox>
    </>
  );
}

DocumentSnippet.propTypes = {
  asset: PropTypes.shape({
    contentType: PropTypes.string,
    s3Key: PropTypes.string,
  }).isRequired,
  assetId: PropTypes.string.isRequired,
};

export default DocumentSnippet;
