import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import AddIcon from '../../../static/img/icon-add.svg';
import BackIcon from '../../../static/img/icon-arrow-2.svg';
import deleteIcon from '../../../static/img/icon-bin.svg';
import EditIcon from '../../../static/img/icon-edit-2.svg';

const Button = styled.button`
  align-items: center;
  background-color: ${(props) => props.backgroundColor ?? '#EBEDED'};
  border: none;
  border-radius: ${(props) => props.borderRadius ?? '50%'};
  color: ${(props) => props.color ?? '#A6ADB4'};
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.fontSize ?? '2rem'};
  height: 0.8em;
  justify-content: center;
  line-height: 0;
  margin: 0;
  outline: none;
  padding: ${(props) => props.padding ?? '0'};
  width: 0.8em;

  &:hover {
    background-color: #4bc2ca;
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    color: #fff;
  }

  &:focus {
    outline: 0;
  }
`;

export const ICONS = {
  add: 'add',
  back: 'back',
  bin: 'bin',
  cross: 'cross',
  edit: 'edit',
  forward: 'forward',
};

const getIconSpecification = (icon) => {
  switch (icon) {
    case ICONS.edit:
      return { image: EditIcon, rotation: 0, isTranslate: false };
    case ICONS.back:
      return { image: BackIcon, rotation: 180, isTranslate: true };
    case ICONS.forward:
      return { image: BackIcon, rotation: 0, isTranslate: false };
    case ICONS.add:
      return { image: AddIcon, rotation: 0, isTranslate: false };
    case ICONS.cross:
      return { image: AddIcon, rotation: 45, isTranslate: true };
    case ICONS.bin:
      return { image: deleteIcon, isTranslate: true };
    default:
      return { image: AddIcon, rotation: 0, isTranslate: false };
  }
};

const ImageButton = (props) => {
  const { onClicked, fontSize, color, backgroundColor, borderRadius, padding, iconType, rotate, animate } = props;

  const { image, rotation, isTranslate } = getIconSpecification(iconType);
  const isAnimate = animate ?? isTranslate;

  return (
    <Button
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      color={color}
      fontSize={fontSize}
      padding={padding}
      onClick={onClicked}
    >
      <img
        alt=""
        src={image}
        style={{
          transform: `rotate(${rotate || rotation}deg)`,
          transition: isAnimate && 'transform 0.5s',
        }}
        title="Image button"
      />
    </Button>
  );
};

ImageButton.propTypes = {
  onClicked: PropTypes.func,
  iconType: PropTypes.string.isRequired,
};

ImageButton.defaultProps = {
  onClicked: () => {},
  iconType: ICONS.add,
};

export default ImageButton;
