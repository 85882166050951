import React from 'react';

import cx from 'classnames';

import { RadioButtonItem } from '../RadioButtonItem/RadioButtonItem';

import { RadioButtonGroupProps } from './RadioButtonGroup.types';

import { Spacing } from '../../Spacing';

import './RadioButtonGroup.css';

const RadioButtonGroup = ({ additionalClassNames, name, items = [], onChange, selectedValue, title }: RadioButtonGroupProps) => {
  const radioButtonGroupClassNames = cx('c-radio-button-group', additionalClassNames);

  return (
    <fieldset className={radioButtonGroupClassNames}>
      <legend className="c-radio-button-group__title">{title}</legend>
      {items.map(({ id, isDisabled = false, labelContentComponent, labelText, value, tooltip }) => {
        const isChecked = value === selectedValue;

        return (
          <Spacing key={id}>
            <RadioButtonItem
              id={id}
              isChecked={isChecked}
              isDisabled={isDisabled}
              labelContentComponent={labelContentComponent}
              labelText={labelText}
              name={name}
              tooltip={tooltip}
              value={value}
              onChange={onChange}
            />
          </Spacing>
        );
      })}
    </fieldset>
  );
};

export { RadioButtonGroup };
