import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_USER = gql`
  query {
    user {
      getLoggedUser {
        id
        firstname
        lastname
        created
        # phoneNumber
        credential {
          email
          secondaryEmail
        }
      }
    }
  }
`;

const getUserRequest = ({ onError, onSuccessResult }) => (
  <Query client={Client()} query={GET_USER}>
    {({ loading, error, data }) => {
      const onSuccessGetUser = dataDetail => {
        const {
          id,
          firstname,
          lastname,
          credential,
          phoneNumber,
          created,
        } = dataDetail;

        onSuccessResult(
          id,
          firstname,
          lastname,
          credential.email,
          created,
          credential.secondaryEmail,
          phoneNumber
        );
      };

      return (
        <QueryRequest
          data={
            data && Object.entries(data).length !== 0 && data.user.getLoggedUser
          }
          error={error}
          isLoading={loading}
          onError={onError}
          onSuccessResult={onSuccessGetUser}
        />
      );
    }}
  </Query>
);

getUserRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getUserRequest;
