import React from 'react';

import { Avatar, ButtonIconOnly, Tooltip as NetfrontTooltip } from '@netfront/ui-library';

import { EMAIL_ICONS, EMAIL_ICON_TOOLTIPS } from './UserTable.constants';

import RoundSettingsButton from '../../../../components/UI/Button/RoundSettingsButton/RoundSettingsButton';
import Tooltip from '../../../../components/UI/Tooltip/Tooltips';
import { capitalize } from '../../../../utils/utils';
import { ActiveStatus, PendingStatus, ResendBtn } from '../../../OrganisationUsersPage/styled';

import './UserTable.css';

export const createUserTableData = (data, onDeletePendingInvitation, onEdit, onResend) =>
  data.map((user) => {
    const { id, isProjectAdmin, user: userItem, group, userType, invitationEmailStatus } = user || {};
    const { firstname, lastname, credential, status, communityName } = userItem || {};
    const isPending = user.status === 'PENDING';

    const adminIndicator = isProjectAdmin
      ? {
          component: <NetfrontTooltip iconId="id_star_icon" placement="right" text="This user is an administrator" />,
        }
      : undefined;

    const pendingFirstNameElement = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar additionalClassNames="c-user-avatar" title={`${firstname} ${lastname}`} />
        <p style={{ marginLeft: '1rem' }}>{user.firstName}</p>
      </div>
    );

    const avatarBlock = (
      <div className="c-avatar-block">
        <div>
          <Avatar additionalClassNames="c-user-avatar" roleIndicator={adminIndicator} title={`${firstname} ${lastname}`} />
        </div>
        <p style={{ marginLeft: '1rem' }}>{firstname}</p>
      </div>
    );

    const emailType = credential && credential.email ? credential.email : 'No email';

    const statusElement = (
      <div>
        <ActiveStatus isActive={status === 'ACTIVE'} />
        {capitalize(status)}
      </div>
    );

    const pendingStatus = (
      <div>
        <PendingStatus>!</PendingStatus>Pending
      </div>
    );

    const invitationIconElement = EMAIL_ICONS[invitationEmailStatus] ? (
      <Tooltip customIcon={EMAIL_ICONS[invitationEmailStatus]} text={EMAIL_ICON_TOOLTIPS[invitationEmailStatus]} width={2} />
    ) : (
      <Tooltip customIcon={EMAIL_ICONS.NOT_CLICKED} text="Not clicked" width={2} />
    );

    if (isPending) {
      return {
        id,
        firstName: pendingFirstNameElement,
        lastName: user.lastName,
        email: user.invitedUserEmail,
        alias: communityName,
        group: group == null ? '' : group.name,
        invitation: invitationIconElement,
        userType: userType === null ? '' : userType.name,
        status: pendingStatus,
        button: (
          <div className="button-group">
            <ResendBtn onClick={() => onResend(user)}>Resend</ResendBtn>
            <ButtonIconOnly iconId="id_bin_icon" onClick={() => onDeletePendingInvitation(user.id)} />
          </div>
        ),
      };
    }

    return {
      id,
      firstName: avatarBlock,
      lastName: lastname,
      email: emailType,
      alias: communityName,
      group: group == null ? '' : group.name,
      userType: userType === null ? '' : userType.name,
      status: statusElement,
      button: <RoundSettingsButton onClick={() => onEdit(user)} />,
    };
  });
