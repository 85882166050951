import gql from 'graphql-tag';

export const GET_GROUP_CODES = gql`
  query($projectId: String!) {
    group {
      searchGroups(projectId: $projectId, status: [ACTIVE]) {
        id
        name
        identifier
      }
    }
  }
`;
