import React from 'react';

import PropTypes from 'prop-types';

import Button from '../Button/styled/ButtonOutline';
import { EKDateRangePicker } from '../DatePickers/EKDateRangePicker/EKDateRangePicker';
import SearchInput from '../SearchInput/SearchInput';
import Dropdown from '../UI/Dropdown/Dropdown';

import './EKTableFilterBar.css';

const EKTableFilterBar = ({
  date,
  dropdown,
  isFilterApplied = false,
  isDisabled = false,
  onApplyFiltersHandler,
  onClearFiltersHandler,
  searchInput,
}) => {
  const { dates, onDateChangeHandler } = date || {};
  const { items: dropdownItems, onDropDownChangeHandler, selectedValue: selectedDropDownValue } = dropdown || {};
  const { onSearchInputChangeHandler, searchInputValue } = searchInput || {};

  const handleDateRangeChanged = (selectedDates) => {
    onDateChangeHandler(selectedDates);
  };

  const handleApplyFilters = () => {
    const { endDate, startDate } = dates || {};

    const filters = {
      endDate,
      searchInputValue,
      startDate,
    };

    onApplyFiltersHandler(filters);
  };

  const handleClearFilters = () => {
    onClearFiltersHandler();
  };

  const handleSearchInputChanged = (event) => {
    const {
      target: { value },
    } = event;

    onSearchInputChangeHandler(value);
  };

  const showApplyClearFiltersButtons = date || searchInput;

  return (
    <div className="ek-table-tool-bar-container">
      {searchInput && (
        <div className="ek-table-tool-bar-container-search-column">
          <SearchInput isDisabled={isDisabled} value={searchInputValue} onChange={(event) => handleSearchInputChanged(event)} />
        </div>
      )}
      {date && (
        <div className="ek-table-tool-bar-container-date-column">
          <EKDateRangePicker
            defaultEndDate={dates.endDate}
            defaultStartDate={dates.startDate}
            isDisabled={isDisabled}
            onChangeHandler={handleDateRangeChanged}
          />
        </div>
      )}
      {dropdown && (
        <Dropdown
          availableResponses={dropdownItems}
          isDisabled={isDisabled}
          name="dropdown"
          selectedValue={selectedDropDownValue}
          onChangeHandler={onDropDownChangeHandler}
        />
      )}
      {showApplyClearFiltersButtons && (
        <div className="ek-table-tool-bar-container-filter-button-column">
          <Button color="#4bc2ca" disabled={isDisabled} text="Apply filters" onClick={(event) => handleApplyFilters(event)} />
          {isFilterApplied && <Button color="#4bc2ca" margin="0 0 0 0.5rem" text="Clear filters" onClick={handleClearFilters} />}
        </div>
      )}
    </div>
  );
};

EKTableFilterBar.propTypes = {
  date: PropTypes.shape({
    dates: PropTypes.shape({
      endDate: PropTypes.instanceOf(Date).isRequired,
      startDate: PropTypes.instanceOf(Date).isRequired,
    }).isRequired,
    onDateChangeHandler: PropTypes.func.isRequired,
  }),
  dropdown: PropTypes.shape({
    onDropDownChangeHandler: PropTypes.func.isRequired,
    selectedValue: PropTypes.string.isRequired,
  }),
  isDisabled: PropTypes.bool,
  isFilterApplied: PropTypes.bool,
  onApplyFiltersHandler: PropTypes.func,
  onClearFiltersHandler: PropTypes.func,
  searchInput: PropTypes.shape({
    onSearchInputChangeHandler: PropTypes.func.isRequired,
    searchInputValue: PropTypes.string.isRequired,
  }),
};

export { EKTableFilterBar };
