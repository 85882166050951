import React, { useEffect, useState } from 'react';

import { Spacing, InformationBox, Table, ButtonIconOnly } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const SettingsContainer = styled.div`
  display: flex;
  justify-content: end !important;
  align-items: center;
  gap: 0.5rem;
  color: #000;
`;

const ConditionsOverview = ({ onEditClick, onDeleteClick, conditionItems }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(conditionItems);
  }, [conditionItems]);
  return (
    <Container>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'title',
                Cell: ({ value }) => <span>{value}</span>,
                Header: () => <div>Conditions</div>,
              },
              {
                accessor: 'settingsButtonData',
                Cell: ({ value: { handleEdit, handleDelete, tempId } }) => (
                  <SettingsContainer>
                    <ButtonIconOnly
                      additionalClassNames=""
                      iconId="id_bin_icon"
                      isIconBorderVisible={false}
                      onClick={() => handleDelete(tempId)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      iconId="id_caret_down_icon"
                      isIconBorderVisible={false}
                      onClick={() => handleEdit(tempId)}
                    />
                  </SettingsContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              title: item.title,
              settingsButtonData: {
                tempId: item.tempId,
                handleEdit: onEditClick,
                handleDelete: onDeleteClick,
              },
            }))}
          />
        </Spacing>
      )}

      <Spacing>
        <SettingsContainer>
          <ButtonIconOnly iconId="id_plus_icon" text="Add action" onClick={() => onEditClick(undefined)} />
        </SettingsContainer>
      </Spacing>
    </Container>
  );
};

ConditionsOverview.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  conditionItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

export { ConditionsOverview };
