import React, { Fragment, useEffect, useState } from 'react';

import cx from 'classnames';
import Modal from 'react-modal';

import { ButtonIconOnly } from '../ButtonIconOnly';
import { Button } from '../Buttons';
import { Icon } from '../Icon';

import { ICON_ID_TYPES } from './Dialog.constants';
import { DialogProps } from './Dialog.types';

import { IconIdType } from '../../types';

import './Dialog.css';

const Dialog = ({
  additionalClassNames,
  appRootId = 'root',
  children,
  confirmText = 'Confirm',
  isOpen = false,
  onCancel,
  onConfirm,
  onClose,
  isConfirmDisabled = false,
  title,
  type,
  isNarrow = false,
}: DialogProps) => {
  const iconId: IconIdType | undefined = type ? ICON_ID_TYPES[type] : undefined;

  const [appElement, setAppElement] = useState<HTMLElement | null>();

  useEffect(() => {
    setAppElement(document.getElementById(appRootId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {appElement && (
        <Modal
          appElement={appElement}
          bodyOpenClassName="h-no-scroll"
          className={cx('c-dialog', additionalClassNames, { 'c-dialog--narrow': isNarrow })}
          isOpen={isOpen}
          overlayClassName="c-dialog-overlay"
          onRequestClose={onClose}
        >
          <ButtonIconOnly additionalClassNames="c-dialog__close-icon" iconId="id_close_icon" text="Close" onClick={onClose} />
          <div className="c-dialog__header">
            {iconId ? <Icon className="c-dialog-icon" id={iconId} /> : null}
            <h3 className="c-dialog__title">{title}</h3>
          </div>
          <div className="c-dialog__content">{children}</div>
          {onCancel ?? onConfirm ? (
            <div className="c-dialog__buttons">
              {onCancel && <Button size="xs" text="Cancel" variant="dark" onClick={onCancel} />}
              {onConfirm && <Button isDisabled={isConfirmDisabled} size="xs" text={confirmText} onClick={onConfirm} />}
            </div>
          ) : null}
        </Modal>
      )}
    </Fragment>
  );
};

export { Dialog };
