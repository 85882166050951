import React, { useState, useContext } from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PopupMessage from '../PopupMessage/PopupMessage';
import { SearchSuggetionsBox, SearchSuggetionsRow } from '../RightNavBar/styled';
import Button from '../UI/Button/SquareButton';
import Modal from '../UI/Modal/NormalModal';

import AppContext from '../../context/AppContext';
import client from '../../middleware/client';
import { SEARCH_CONTENT_GROUPS } from '../../middleware/ContentGroup/searchContentGroups';
import { UPDATE_PAGE_PARENT } from '../../middleware/ContentPage/movePageToGroup';

const Text = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function SearchContentGroups({ projectId, title, group, pageId, onGroupSelected }) {
  const { loading, error, data } = useQuery(SEARCH_CONTENT_GROUPS, {
    client,
    variables: {
      projectId,
      title,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { org, project } = useContext(AppContext);
  const history = useHistory();
  const [moveGroup] = useMutation(UPDATE_PAGE_PARENT, {
    client,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [errMsg, setErrMsg] = useState('');

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const handleSelect = (select) => {
    setIsOpen(true);
    setSelectedItem(select);
    onGroupSelected(select);
  };

  const handleMoveGroup = () => {
    moveGroup({
      variables: {
        contentPageId: pageId,
        contentGroupId: selectedItem.id,
      },
    })
      .then(() => {
        history.push(`/dashboard/${org.key}/${project.id}/group/${selectedItem.type.toLowerCase()}/${selectedItem.id}/content`);
        // After we move a page to another content group, we direct to the new
        // content group and reload data. The 'history.push()' method not
        // triggers rendering. So we use history.go() to reload current page.
        // TODO: when we refactor it, we should use state to control the data.
        history.go();
      })
      .catch((err) => {
        setErrMsg(err.toString());
        setTimeout(() => {
          setErrMsg('');
        }, 3000);
      });
  };

  return (
    <SearchSuggetionsBox>
      {data.contentGroup.searchContentGroups
        .filter((g) => g.id !== group.id)
        .map((g) => (
          <SearchSuggetionsRow key={g.id} style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => handleSelect(g)}>
            {g.title}
            <span style={{ textTransform: 'capitalize' }}>{g.type.toLowerCase()}</span>
          </SearchSuggetionsRow>
        ))}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <Text>
            Are you sure you want to move current page from {group.title} to {selectedItem.title}?
          </Text>
          <ButtonGroup>
            <Button bgColor="#4bc2ca" color="#fff" isClicked={handleMoveGroup}>
              Confirm
            </Button>
            <Button isClicked={() => setIsOpen(false)}>Cancel</Button>
          </ButtonGroup>
          {errMsg !== '' && <PopupMessage message={errMsg} isError />}
        </Modal>
      )}
    </SearchSuggetionsBox>
  );
}

SearchContentGroups.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onGroupSelected: PropTypes.func.isRequired,
  pageId: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
};

SearchContentGroups.defaultProps = {
  group: null,
  title: '',
};

export default SearchContentGroups;
