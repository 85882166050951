import gql from 'graphql-tag';

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $userId: Int!
    $newPassword: String!
    $projectId: String!
  ) {
    user {
      resetPassword(
        userId: $userId
        newPassword: $newPassword
        projectId: $projectId
      )
    }
  }
`;
