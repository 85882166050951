/* eslint-disable react/boolean-prop-naming */
import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_MAP_CONTENT_SNIPPET = gql`
  mutation updateMapContentSnippet(
    $snippetId: Int!
    $longitude: Float!
    $latitude: Float!
    $zoom: Int!
    $draggable: Boolean
    $grayscale: Boolean
    $title: String!
  ) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId, entityTitle: $title }
        contentSnippetMap: { longitude: $longitude, latitude: $latitude, zoom: $zoom, draggable: $draggable, grayscale: $grayscale }
      ) {
        id
      }
    }
  }
`;

const updateMapContentSnippetRequest = ({ snippetId, longitude, latitude, zoom, draggable, grayscale, onError, onSuccessResult }) => (
  <Mutation mutation={UPDATE_MAP_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        longitude,
        latitude,
        zoom,
        draggable,
        grayscale,
        title: '',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateMapContentSnippetRequest.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  draggable: PropTypes.bool,
  grayscale: PropTypes.bool,
};

updateMapContentSnippetRequest.defaultProps = {
  draggable: true,
  grayscale: false,
};

export default updateMapContentSnippetRequest;
