/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import InputHolder from '../../components/InputHolder/InputHolder';
import PopupMessageSideBar from '../../components/PopupMessageSideBar/PopupMessageSideBar';
import SideBarRight from '../../components/SideBarRight/SideBarRight';
import UpdatePopup from '../../components/UpdatePopup/UpdatePopup';
import { errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import { urlGenerator } from '../../utils/utils';

class Organisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      errorMessage: '',
      name: '',
      tag: '',
      updatePopup: false,
      url: '',
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
    const { name, description, tag, id, url } = this.props;
    if (id) {
      this.setState({
        description,
        name,
        tag,
        url,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isPanelOpen, name, description, tag, id, url } = this.props;

    if (isPanelOpen === true && id && id !== prevProps.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        description,
        name,
        tag,
        url,
      });
    } else if (isPanelOpen === true && !id && prevProps.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        description: '',
        name: '',
        tag: '',
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleClearErrorMessages = () => {
    const { errorMessage } = this.state;
    if (errorMessage !== '') {
      this.setState({
        errorMessage: '',
      });
    }
  };

  handleErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
    });
  };

  handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      const tmpUrl = urlGenerator(value);
      this.setState({
        [name]: value,
        url: tmpUrl,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  validate = () => {
    const { name, description, tag } = this.state;
    return {
      NameEmpty: {
        validation: name.trim().length > 0,
        errorMessage: 'Name must not be empty',
      },
      DescriptionEmpty: {
        validation: description.trim().length > 0,
        errorMessage: 'Description must not be empty',
      },
      TagEmpty: {
        validation: tag.trim().length > 0,
        errorMessage: 'Tag must not be empty',
      },
    };
  };

  handleCreateOrUpdate = (e) => {
    e.preventDefault();
    const validationErrors = ValidationProcessor(this.validate());

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });

      return;
    }

    const { handleCreate, handleUpdate, id } = this.props;
    const { description, name, tag, url } = this.state;

    if (id) {
      handleUpdate(description, id, name, tag, url);
      return;
    }

    handleCreate(description, name, tag, url);
  };

  updateConfirmationHandler = () => {
    this.setState({
      updatePopup: false,
    });
  };

  closeUpdatePopupHandler = () => {
    this.setState({
      updatePopup: false,
    });
  };

  // trigger enter to fire handleCreateOrUpdate()
  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleCreateOrUpdate(e);
    }
  };

  render() {
    const { isPanelOpen, handleClosePanel, name: organisationName } = this.props;

    const { name, url, description, tag, errorMessage, updatePopup } = this.state;

    const inputHolderValue = [
      {
        label: 'Name',
        name: 'name',
        value: name,
        required: true,
      },
      {
        label: 'Description',
        name: 'description',
        value: description,
        required: true,
      },
      {
        label: 'URL',
        name: 'url',
        value: url,
        required: true,
      },
      {
        label: 'Tag',
        name: 'tag',
        value: tag,
        required: true,
      },
    ];

    return (
      <>
        <SideBarRight open={isPanelOpen}>
          <button className="c-sidebar-right__close" type="button" onClick={handleClosePanel} onKeyDown={handleClosePanel}>
            <span className="icon-plus" />
          </button>
          <div className="c-sidebar-right-form">
            <p className="c-sidebar-right-title">{organisationName || 'Create an organisation'}</p>
            <form autoComplete="off">
              {inputHolderValue.map((item) => (
                <InputHolder
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  onChange={this.handleInputs}
                  // required={item.required}
                  onClick={this.handleClearErrorMessages}
                />
              ))}
            </form>
            <div className="text-right">
              <button className="button--black" style={{ margin: '1.5em 1em 1.5em 0' }} type="button" onClick={handleClosePanel}>
                Cancel
              </button>
              <button
                className="button--blue"
                style={{ margin: '1.5em 0' }}
                type="submit"
                onClick={this.handleCreateOrUpdate}
                onKeyDown={this.handleKeyDown}
              >
                Save
              </button>
            </div>
          </div>
        </SideBarRight>

        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
        </ReactCSSTransitionGroup>

        {updatePopup && (
          <UpdatePopup closePopup={this.closeUpdatePopupHandler} type="organisation" updateConfirmation={this.updateConfirmationHandler} />
        )}
      </>
    );
  }
}

Organisation.propTypes = {
  handleClosePanel: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  tag: PropTypes.string,
  url: PropTypes.string,
};

Organisation.defaultProps = {
  description: undefined,
  id: undefined,
  name: undefined,
  tag: undefined,
  url: undefined,
};

export default Organisation;
