import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const getAllImageMapsQuery = gql`
  query getAllImageMaps($projectId: String!) {
    contentGroup {
      searchContentGroups(projectId: $projectId, type: IMAGE_MAP) {
        id
        title
        iconAssetId
        iconAsset {
          alt
          assetId
          contentType
          presignedUrl
          s3Key
        }
      }
    }
  }
`;

const getAllImageMaps = ({ projectId, title, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={getAllImageMapsQuery}
    variables={{ projectId, title }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          Object.entries(data).length !== 0 && data.imageMap.searchImageMaps
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);
getAllImageMaps.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

getAllImageMaps.defaultProps = {
  title: '',
};

export default getAllImageMaps;
