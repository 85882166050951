import React from 'react';

import { Icon } from '../Icon';

import { QuoteProps } from './Quote.types';

import './Quote.css';

const Quote = ({ author, children, source }: QuoteProps) => {
  return (
    <figure className="c-quote" data-testid="qa-quote">
      <span aria-hidden="true" className="c-quote__speech-marks c-quote__speech-marks--open">
        <Icon className="c-quote__speech-marks-icon" id="id_open_quote_icon" />
      </span>

      <blockquote className="c-quote__content ek-quote-body">{children}</blockquote>

      <span aria-hidden="true" className="c-quote__speech-marks c-quote__speech-marks--close">
        <Icon className="c-quote__speech-marks-icon" id="id_close_quote_icon" />
      </span>

      {Boolean(author) && (
        <figcaption className="c-quote__author ek-quote-author" data-testid="qa-quote-author">
          &ndash; {author}
          {Boolean(source) && (
            <React.Fragment>
              , <cite data-testid="qa-quote-source">{source}</cite>
            </React.Fragment>
          )}
        </figcaption>
      )}
    </figure>
  );
};

export { Quote };
