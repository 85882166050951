import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const REMOVE_TAG_GROUP = gql`
  mutation removeTag($contentGroupId: Int!, $tagId: Int!) {
    tagMutation {
      removeTagInContentGroup(contentGroupId: $contentGroupId, tagId: $tagId)
    }
  }
`;

const removeTagInContentGroup = ({
  contentGroupId,
  tagId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={REMOVE_TAG_GROUP}>
    {(removeTag, { loading, error, data }) => {
      const variables = {
        contentGroupId,
        tagId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.tagMutation.removeTagInContentGroup);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={removeTag}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

removeTagInContentGroup.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  tagId: PropTypes.number.isRequired,
};

export default removeTagInContentGroup;
