import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_CONDITION } from './UseDeleteCondition.graphql';

const useDeleteCondition = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeDeleteCondition, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? DELETE_CONDITION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { removeCondition: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteCondition = ({ variables }) => {
    executeDeleteCondition({
      variables,
    });
  };

  return {
    handleDeleteCondition,
    isLoading,
  };
};

export { useDeleteCondition };
