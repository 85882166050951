import { useCookie } from '@netfront/common-library';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { useParams } from 'react-router-dom';

import { API_ENDPOINTS } from '../apiEndpoints';

import introspectionQueryResultData from '../../schema/fragmentTypes.json';

const useApolloClient = (apiName) => {
  const { getAccessTokenCookie } = useCookie();
  const params = useParams();
  const { projectId } = params;
  const apiEndpoint = API_ENDPOINTS[apiName];

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const apolloLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Origin': '*',
      Authorization: getAccessTokenCookie(),
      'Content-Type': 'application/graphql',
      custom_build_guid: apiName === API_ENDPOINTS.gelada ? process.env.PUBLIC_PROJECT_ID : '',
      project_id: projectId,
    },
  }));

  const httpLink = createHttpLink({
    uri: apiEndpoint,
  });

  const cache = new InMemoryCache({
    fragmentMatcher,
  });

  const link = apolloLink.concat(httpLink);

  return new ApolloClient({
    cache,
    link,
  });
};

export { useApolloClient };
