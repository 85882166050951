import gql from 'graphql-tag';

export const FRAGMENT_REGIONS = gql`
  fragment regions on ContentSnippetImageMapType {
    contentGroup {
      id
      title
      iconAsset {
        contentType
        presignedUrl
        s3Key
      }
      regions {
        id
        iconSize
        iconType
        x
        y
      }
    }
  }
`;
