import gql from 'graphql-tag';

export const FRAGMENT_AUDIO = gql`
  fragment audio on ContentSnippetAudioType {
    assetId
    asset {
      presignedUrl
      s3Key
      contentType
    }
  }
`;
