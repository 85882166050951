import { endOfDay } from 'date-fns';

const getDisabledDates = (currentDate, maxDate, minDate) => {
  const endOfDayToday = endOfDay(new Date());
  const parsedMaxDate = maxDate ? endOfDay(maxDate) : undefined;
  const parsedMinDate = minDate ? endOfDay(minDate) : undefined;

  if (parsedMaxDate && parsedMinDate) {
    return currentDate > parsedMaxDate || currentDate < parsedMinDate;
  }

  if (parsedMaxDate) {
    return currentDate > parsedMaxDate;
  }

  if (parsedMinDate) {
    return currentDate < parsedMinDate;
  }

  return currentDate > endOfDayToday;
};

export { getDisabledDates };
