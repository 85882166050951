const newData = {};

export const convertData = (data, pageId) => {
  const rootArr = [];
  data
    .sort((a, b) => a.sort - b.sort)
    .map(item => {
      const childArr = [];
      let hasChild = false;
      rootArr.push(item.id);
      if (item.contentPageChildren.length > 0) {
        hasChild = true;
        item.contentPageChildren.map(child => childArr.push(child.id));
        convertData(item.contentPageChildren, pageId);
      }
      newData[item.id] = {
        id: item.id,
        children: childArr,
        hasChildren: hasChild,
        isExpanded: hasChild,
        isChildrenLoading: false,
        data: {
          title: item.title,
          url: item.url,
        },
      };
      return null;
    });
  newData.root = {
    id: 'root',
    children: rootArr,
    hasChildren: true,
    isExpanded: true,
    isChildrenLoading: false,
    data: {
      title: 'Troot',
    },
  };
};

export const dataToTree = (data, pageId) => {
  convertData(data, pageId);
  const defaultTree = {
    rootId: 'root',
    items: newData,
  };
  return defaultTree;
};
