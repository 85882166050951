import gql from 'graphql-tag';

export const GET_QUESTION_PREVIEW = gql`
  query ($id: Int!) {
    contentSnippet {
      question(id: $id) {
        question
        __typename
        configuration {
          __typename
          ... on Radio {
            responseSet {
              availableResponses {
                label
                id
                value
              }
            }
          }
          ... on Checkbox {
            responseSet {
              availableResponses {
                label
                id
                value
              }
            }
          }
          ... on DropDownList {
            responseSet {
              availableResponses {
                label
                id
                value
              }
            }
          }
          ... on Slider {
            maxValue
            minValue
            step
            ticks {
              label
              sliderValue
            }
          }
          ... on Calendar {
            defaultDate
            maxDate
            minDate
          }
          ... on Rating {
            initialValue
            iconType
            maxRating
            precision
            areLabelsVisible
          }
          ... on MultiResponseText {
            minResponse
          }
          ... on Match {
            matchItems {
              id
              sort
              text
            }
            responseSet {
              availableResponses {
                label
                id
                value
              }
            }
          }
        }
      }
    }
  }
`;
