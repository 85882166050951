import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_RESPONSE_SET } from './UseCreateResponseSet.graphql';

const useCreateResponseSet = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeCreateResponseSet, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? CREATE_RESPONSE_SET,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionSet: { add: config },
        } = data;

        onCompleted({
          config,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateResponseSet = ({ variables }) => {
    executeCreateResponseSet({
      variables,
    });
  };

  return {
    handleCreateResponseSet,
    isLoading,
  };
};

export { useCreateResponseSet };
