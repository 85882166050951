import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.a`
  background: ${props => props.bgColor || '#4bc2ca'};
  border: 2px solid ${props => props.borderColor || 'transparent'};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${props => props.fontColor || '#fff'};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  margin: 0.5rem;
  min-width: 4rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${props => props.hoverColor || '#4bc2ca'};
  }
`;

const Large = styled(Button)`
  padding: 0.9rem 2.2rem;
  width: 100%;
`;
const Medium = styled(Button)`
  padding: 0.9rem 2.2rem;
  width: fit-content;
`;
const Small = styled(Button)`
  font-size: 0.8rem;
  padding: 0.6rem 1.1rem;
  width: fit-content;
`;

const buttonSize = {
  SMALL: Small,
  MEDIUM: Medium,
  LARGE: Large,
};

const ButtonSnippet = props => {
  const { page, snippet } = props;
  const prefix = process.env.REACT_APP_CONTENT_URL_PREFIX;
  const link =
    page && Object.keys(page).length > 0
      ? `${prefix ? `/${prefix}` : ''}/${page.contentGroup.url}/${page.url}`
      : decodeURIComponent(snippet.hyperlink);

  const linkProps =
    link.indexOf('http') === 0 ? { rel: 'noopener noreferrer' } : {};

  const buttonStyle = snippet ? `${snippet.style.toLowerCase()}-button` : '';

  const Component = buttonSize[snippet.style];
  return (
    <Component
      className={`button-snippet button-snippet-${snippet &&
        snippet.id} ${buttonStyle}`}
      href={link}
      target={snippet.target}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...linkProps}
      bgColor={snippet.buttonBackgroundColor}
      borderColor={snippet.borderColor}
      fontColor={snippet.fontColor}
      hoverColor={snippet.hoverColor}
      type="button"
    >
      <span className="button-snippet-text">{snippet.innerText}</span>
    </Component>
  );
};

ButtonSnippet.propTypes = {
  hyperlink: PropTypes.string.isRequired,
  innerText: PropTypes.string.isRequired,
  snippet: PropTypes.shape({
    borderColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    hoverColor: PropTypes.string,
    hyperlink: PropTypes.string,
    id: PropTypes.number,
    innerText: PropTypes.string,
    style: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
  page: PropTypes.shape(),
};

ButtonSnippet.defaultProps = {
  page: {},
};

export default ButtonSnippet;
