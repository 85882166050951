import React, { useState, useContext, useEffect } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useParams } from 'react-router-dom';

import { Filters, SearchContainer } from '../OrganisationUsersPage/styled';

import { UserTagTable } from './LibraryTagsPage.styled';

import { Search, ResponseSet, AddNewResponse, InfoIcon } from '../../components/Form/styled';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import PopupMessage from '../../components/PopupMessage/PopupMessage';
import EditTagSideBar from '../../components/RightNavBar/EditTagSideBar';
import SearchInput from '../../components/SearchInput/SearchInput';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import NoDataAlerts from '../../components/UI/NoDataComp/NoDataAlert';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls } from '../../hooks';
import CreateProjectTag from '../../middleware/Project/CreateProjectTag';
import SearchProjectTags from '../../middleware/Project/SearchProjectTags';
import { sortUtil } from '../../utils/utils';

const LibraryTagsPage = (props) => {
  const { match } = props;
  const { project } = useContext(AppContext);
  const params = useParams();
  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const [breadCrumbPath, setBreadcrumbPath] = useState([]);
  const [tags, setTags] = useState([]);
  const [newTagValue, setNewTagValue] = useState('');
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [isGettingTags, setIsGettingTags] = useState(true);
  const [filteredTags, setFilteredTags] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});

  const setTagDataSources = (data) => {
    setTags(data);
    setFilteredTags(data);
  };

  const handleTagInput = (e) => {
    setNewTagValue(e.target.value);

    if (hasCreateError) {
      setHasCreateError(false);
    }
  };

  const handleAddTag = () => {
    if (newTagValue !== '') {
      setIsCreatingTag(true);
    }
  };

  const handleCreateTagSuccess = (data) => {
    setIsCreatingTag(false);
    setTagDataSources([...tags, data]);
    setNewTagValue('');
  };

  const handleCreateTagError = () => {
    setIsCreatingTag(false);
    setHasCreateError(true);
  };

  const handleGetTagsSuccess = (data) => {
    setIsGettingTags(false);
    const sortedTags = sortUtil((a, b) => a.sort - b.sort, data);
    setTagDataSources(sortedTags);
  };

  const handleGetTypesError = () => {
    setIsGettingTags(false);
  };

  const handleSearchFilter = (e) => {
    const { value } = e.target;

    const filtered = tags.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

    setFilteredTags(filtered);
  };

  const handleEditTag = (item) => {
    setIsEdit(true);
    setSelectedTag(item);
  };

  const handleTagUpdate = (item) => {
    const tmpTags = filteredTags.map((t) => (t.id === item.id ? item : t));
    setFilteredTags(tmpTags);
    setIsEdit(false);
  };

  const handleDeleteTag = (item) => {
    const tmpTags = filteredTags.filter((t) => t.id !== item.id);
    setFilteredTags(tmpTags);
    setIsEdit(false);
  };

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/library`,
        title: 'Library',
        isExternal: true,
      },
      {
        id: 2,
        path: ``,
        title: 'Tags',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage project tags"
        pageDescription="Manage project tags"
        pageTitle="Manage project tags"
        params={match.params}
      >
        <ProjectNavBar title="Project team" />

        <Filters>
          <SearchContainer>
            <SearchInput onChange={(e) => handleSearchFilter(e)} />
          </SearchContainer>
        </Filters>

        {tags && tags.length > 0 ? <UserTagTable currentTags={filteredTags} onEdit={handleEditTag} /> : <NoDataAlerts />}

        <ResponseSet>
          <Search
            list="browsers"
            name="browser"
            placeholder="Begin typing to create new tag"
            value={newTagValue}
            onChange={handleTagInput}
          />
          <AddNewResponse onClick={handleAddTag}>
            <InfoIcon>+</InfoIcon>
            Add tag
          </AddNewResponse>
        </ResponseSet>

        {isEdit && (
          <div>
            <EditTagSideBar tag={selectedTag} onCancel={() => setIsEdit(false)} onDelete={handleDeleteTag} onUpdate={handleTagUpdate} />
            <Backdrop isClicked={() => setIsEdit(false)} />
          </div>
        )}

        {/** @todo - add more contextul error handling eg duplicates */}

        {hasCreateError && <PopupMessage message="Whoops, looks like something went wrong..." />}
      </PageWrapper>

      {isGettingTags && (
        <SearchProjectTags projectId={match.params.projectId} onError={handleGetTypesError} onSuccessResult={handleGetTagsSuccess} />
      )}

      {isCreatingTag && (
        <CreateProjectTag
          projectId={project.id}
          tagName={newTagValue}
          onError={handleCreateTagError}
          onSuccessResult={handleCreateTagSuccess}
        />
      )}
    </>
  );
};

LibraryTagsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
    }),
  }).isRequired,
};

export default LibraryTagsPage;
