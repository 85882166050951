import React, { useContext, useEffect, useState } from 'react';

import { useDomain } from '@netfront/gelada-identity-library';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Filters, PushRight } from '../OrganisationUsersPage/styled';

import TemplateTable from './TemplateTable';

import Button from '../../components/Buttons/Button';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls } from '../../hooks';
import { getBreadCrumbPath } from '../../utils/utils';

const NotificationTemplatesPage = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const { org, project } = useContext(AppContext);
  const history = useHistory();
  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });
  const [breadCrumbPath, setBreadcrumbPath] = useState([]);

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/notifications`,
        title: 'Notifications',
        isExternal: true,
      },
      ...getBreadCrumbPath(pathname, 0, 'Notification templates'),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info="Manage your push notifications"
      pageDescription="Manage push notifications"
      pageTitle="Push notifications"
      params={params}
    >
      <Filters>
        <PushRight>
          <Button
            onClick={() => {
              history.push(`/dashboard/${org.key}/${project.id}/create-notification`);
            }}
          >
            New template
          </Button>
        </PushRight>
      </Filters>
      <ProjectNavBar title={project.name} />
      <TemplateTable />
    </PageWrapper>
  );
};

export default NotificationTemplatesPage;
