import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_QUE_SLIDER_CONFIGURATION = gql`
  mutation createSliderQuesConfigRequest(
    $projectId: String!
    $description: String!
    $defaultValue: Int!
    $maxValue: Int!
    $minValue: Int!
    $step: Int!
    $ticks: [QuestionConfigurationSliderTickInputType]
    $title: String!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationSlider: {
          defaultValue: $defaultValue
          maxValue: $maxValue
          minValue: $minValue
          step: $step
          ticks: $ticks
          title: $title
        }
      ) {
        id
      }
    }
  }
`;

const createSliderQuesConfigRequest = ({
  projectId,
  description,
  defaultValue,
  maxValue,
  minValue,
  step,
  ticks,
  onError,
  onSuccessResult,
  title,
}) => (
  <Mutation mutation={CREATE_QUE_SLIDER_CONFIGURATION}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        projectId,
        description,
        defaultValue,
        maxValue,
        minValue,
        step,
        ticks,
        title,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.questionConfiguration.createQuestionConfiguration.id
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createSliderQuesConfigRequest.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  ticks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
};

createSliderQuesConfigRequest.defaultProps = {};

export default createSliderQuesConfigRequest;
