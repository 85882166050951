import { ApolloError } from '@apollo/client';
import { getApolloErrorMessages } from '@netfront/common-library';

const handleApolloError = (apolloError: ApolloError): void => {
  const apolloErrorMessages = getApolloErrorMessages(apolloError);
  if (!apolloErrorMessages.length) {
    return;
  }

  // eslint-disable-next-line no-console
  console.error(apolloErrorMessages.join(','));
};

export { handleApolloError };
