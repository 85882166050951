import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_REPORTS_FOR_PROJECT = gql`
  query GetReports($projectId: String!) {
    report {
      getReportsPerProject(projectId: $projectId) {
        id
        title
      }
    }
  }
`;

const getReportsForProject = ({ projectId, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_REPORTS_FOR_PROJECT}
    variables={{ projectId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.report.getReportsPerProject
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getReportsForProject.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default getReportsForProject;
