export const getAdditionalParameterOptions = (type) => [
  {
    id: 1,
    name: 'Open',
    value: 'QuestionActionTriggerContentEventTypeOpen',
    isHidden: type !== 'QuestionActionTriggerContentEventType',
  },
  {
    id: 2,
    name: 'Close',
    value: 'QuestionActionTriggerContentEventTypeClose',
    isHidden: type !== 'QuestionActionTriggerContentEventType',
  },
];

export const getAdditionalParameters = (value) => {
  const parameterMap = {
    QuestionActionTriggerContentEventTypeOpen: {
      type: 'OPEN',
    },
    QuestionActionTriggerContentEventTypeClose: {
      type: 'CLOSE',
    },
  };

  return parameterMap[value];
};
