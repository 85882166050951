import { gql } from '@apollo/client';

const GET_BOOKMARKS_FOR_USER_QUERY = gql`
  query getBookmarksForUser($shouldIncludeContentPage: Boolean = false) {
    bookmark {
      getBookmarksForUser: getBooksmarksPerUser {
        contentPage @include(if: $shouldIncludeContentPage) {
          asset {
            presignedUrl
          }
          id
          seoDescription
          sort
          title
          url
          userFlowStep {
            contentGroup {
              groupingContentGroup {
                id
                name
                sort
                url
              }
            }
            id
            description
            stepName
            sort
            url
            userFlow {
              id
              sort
              url
            }
          }
        }
        contentPageId
        datetime
        id
        sort
        userId
      }
    }
  }
`;

export { GET_BOOKMARKS_FOR_USER_QUERY };
