import React, { useState, useEffect } from 'react';

import { Checkbox, Input, Label, RadioButtonGroup, Spacing, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { ConditionCheckboxQuestion } from '../ConditionCheckboxQuestion';
import { ConditionDateQuestion } from '../ConditionDateQuestion';
import { ConditionRadioQuestion } from '../ConditionRadioQuestion';
import { ConditionSliderQuestion } from '../ConditionSliderQuestion';
import { ConditionTextQuestion } from '../ConditionTextQuestion';

import ButtonGroup from '../../../../../../../legacy/components/ButtonGroup/ButtonGroup';
import Preloader from '../../../../../../../legacy/components/Preloader/Preloader';
import { useConditionLocations, useConditionSnippetAnswers } from '../../../../../../../legacy/components/RightNavBar/ActionTab/hooks';
import { useToast } from '../../../../../../../legacy/hooks';
import { getQuestionText, generateRandomId } from '../../../../../../utils';
import { QuestionSelector } from '../../../../../QuestionSelector';

const ConditionQuestionDetails = ({ onUpdate, onCancel, onDelete, selectedCondition }) => {
  const { handleToastError } = useToast();

  // radio / checkbox
  const [questionResponses, setQuestionResponses] = useState([]);

  const [conditionQuestion, setConditionQuestion] = useState();
  const [questionLocations, setQuestionLocations] = useState([]);
  const [currentCondition, setCurrentCondition] = useState();
  const [questionSnippetForms, setQuestionSnippetForms] = useState([]);
  const [questionConditionContentGroupId, setQuestionConditionContentGroupId] = useState(undefined);

  const {
    contentPageId = null,
    contentSnippetQuestionId = null,
    title = '',
    type = '',
    isTemplate = false,
    tempId = '',
    questionResponseCheckboxId,
    questionResponseRadioId,
    text,
    minValue,
    maxValue,
    dateRangeType = '',
    minDate,
    maxDate,
    minTimeSpan = 0,
    maxTimeSpan = 0,
    selectedDate,
    selection,
    id: conditionId,
  } = currentCondition ?? {};

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  // Get locations of question
  const handleGetConditionLocationsOnCompleted = ({ getSnippetForms }) => {
    setQuestionSnippetForms(getSnippetForms);
    const conditionItemLocations = getSnippetForms
      .filter(({ contentPage: { status } }) => status !== 'DELETED')
      .map(
        ({
          contentPage: {
            id,
            title: contentPageTitle,
            contentGroup: { title: contentGroupTitle },
          },
        }) => ({
          id,
          labelText: `${contentPageTitle} - ${contentGroupTitle}`,
          value: id.toString(),
        }),
      );

    setQuestionLocations(conditionItemLocations);
  };

  const { handleGetLocations, isLoading: isGetLocationsLoading = false } = useConditionLocations({
    onCompleted: handleGetConditionLocationsOnCompleted,
    onError: handleGetError,
  });

  // snippet answers
  const handleGetSnippetAnswersOnCompleted = (data) => {
    const { id: questionId, contentGroupId, configuration, formQuestion } = data;

    const { responseSet } = configuration ?? {};

    const { availableResponses = [] } = responseSet ?? {};

    setQuestionConditionContentGroupId(contentGroupId);

    const questionLabel = getQuestionText(formQuestion) || `Question-${questionId}`;
    setConditionQuestion(`ID: ${questionId} - Question label: ${questionLabel.substring(0, 30)}`);
    setQuestionResponses(
      availableResponses.map(({ id, label }) => ({
        id,
        labelText: label,
        value: id.toString(),
      })),
    );
  };

  const { handleGetSnippetAnswers, isLoading: isGetSnippetAnswersLoading = false } = useConditionSnippetAnswers({
    onCompleted: handleGetSnippetAnswersOnCompleted,
    onError: handleGetError,
  });

  const handleInputChange = ({ name, value }) => {
    setCurrentCondition({
      ...currentCondition,
      [name]: value,
    });
  };

  const handleQuestionItemClick = (selectedQuestionItem) => {
    const {
      id,
      configuration: { __typename: questionConfigurationTypeName },
    } = selectedQuestionItem;

    const typeName = questionConfigurationTypeName === 'SingleText' ? 'text' : questionConfigurationTypeName.toLowerCase();

    setCurrentCondition({
      ...currentCondition,
      contentSnippetQuestionId: id,
      type: typeName,
    });
  };

  const handleSaveCondition = () => {
    const condition = !tempId
      ? {
          ...currentCondition,
          tempId: generateRandomId(),
        }
      : currentCondition;
    onUpdate(condition);
  };

  useEffect(() => {
    if (!questionSnippetForms.length) return;

    const [firstContentSnippetForm] = questionSnippetForms;

    const {
      contentPage: { id },
    } = firstContentSnippetForm;

    setCurrentCondition((currentLocation) => ({
      ...currentLocation,
      contentPageId: id,
    }));
  }, [questionSnippetForms]);

  useEffect(() => {
    if (!questionConditionContentGroupId) return;

    handleGetLocations({
      contentGroupId: questionConditionContentGroupId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionConditionContentGroupId]);

  useEffect(() => {
    if (!contentSnippetQuestionId) return;
    handleGetSnippetAnswers({
      contentSnippetQuestionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentSnippetQuestionId]);

  useEffect(() => {
    setCurrentCondition(selectedCondition);
  }, [selectedCondition]);

  const isLoading = isGetSnippetAnswersLoading || isGetLocationsLoading;

  const questionTypeMap = {
    checkbox: (
      <ConditionCheckboxQuestion
        questionResponses={questionResponses}
        selectedResponse={questionResponseCheckboxId ? [String(questionResponseCheckboxId)] : []}
        onInputChange={handleInputChange}
      />
    ),
    radio: (
      <ConditionRadioQuestion
        questionResponses={questionResponses}
        selectedResponse={questionResponseRadioId ? String(questionResponseRadioId) : ''}
        onInputChange={handleInputChange}
      />
    ),
    text: <ConditionTextQuestion text={text} onInputChange={handleInputChange} />,
    slider: <ConditionSliderQuestion maxValue={maxValue} minValue={minValue} onInputChange={handleInputChange} />,
    calendar: (
      <ConditionDateQuestion
        dateRangeType={dateRangeType}
        maxDate={maxDate}
        maxTimeSpan={maxTimeSpan}
        minDate={minDate}
        minTimeSpan={minTimeSpan}
        selectedDate={selectedDate}
        onInputChange={handleInputChange}
      />
    ),
  };

  return (
    <>
      {isLoading && <Preloader />}
      <Spacing size="2x-large">
        <Input
          labelText="Title"
          maxLength={100}
          minLength={1}
          name="title"
          placeholder="Create your condition title"
          type="text"
          value={title}
          isRequired
          onChange={({ target: { value, name } }) => handleInputChange({ value, name })}
        />
      </Spacing>
      {!tempId ? (
        <QuestionSelector hasButtons={false} hasPreview={false} type="condition" onUpdateQuestionWithConfig={handleQuestionItemClick} />
      ) : (
        <Input
          id="id_question_label"
          labelText="Question"
          name="question-label"
          type="text"
          value={conditionQuestion}
          isDisabled
          onChange={() => undefined}
        />
      )}
      {contentSnippetQuestionId && type && (
        <>
          {/* Temporarily here to make sure the button group doesnt cover the items, this will be sorted in new ekardo */}

          <div style={{ marginBottom: '8rem' }}>
            <div className="c-action">
              <Spacing size="2x-large" />
              <div className="c-action__item">
                <>{questionTypeMap[type]}</>
              </div>
              {['radio', 'checkbox'].includes(type) && (
                <>
                  <Spacing size="2x-large" />
                  <div className="c-action__item">
                    <Label additionalClassNames="c-action__item__label h-flex" forId="question selected" labelText="Question selected">
                      <Tooltip placement="left" text="This determines whether the option should be selected or not" />
                    </Label>

                    <RadioButtonGroup
                      items={[
                        {
                          id: 'SELECTED',
                          labelText: 'Selected',
                          value: 'SELECTED',
                        },
                        {
                          id: 'NOT_SELECTED',
                          labelText: 'Not selected',
                          value: 'NOT_SELECTED',
                        },
                      ]}
                      name="selection"
                      selectedValue={selection ? selection.toString() : ''}
                      onChange={({ target: { value, name } }) => handleInputChange({ name, value })}
                    />
                  </div>
                </>
              )}

              <Spacing size="2x-large" />
              <div className="c-action__item">
                <Label additionalClassNames="c-action__item__label h-flex" forId="question location" labelText="Question location">
                  <Tooltip placement="left" text="This action will execute if the Checkbox selection is as selected below" />
                </Label>

                <RadioButtonGroup
                  items={questionLocations}
                  name="contentPageId"
                  selectedValue={contentPageId ? contentPageId.toString() : ''}
                  onChange={({ target: { value, name } }) => handleInputChange({ name, value: Number(value) })}
                />
              </div>
              <Spacing size="2x-large" />
              {!conditionId && (
                <div className="c-action__item">
                  <Label additionalClassNames="c-action__item__label h-flex" forId="template" labelText="Template">
                    <Tooltip
                      placement="left"
                      text="Templates are used to copy condition configuration. When you save this condition as a template, it will become available for reuse within all actions."
                    />
                  </Label>
                  <div className="c-action__item__checkbox h-flex">
                    <Checkbox
                      id="template"
                      isChecked={isTemplate}
                      labelText="Save conditional configuration for reuse"
                      name="isTemplate"
                      onChange={({ target: { name, checked } }) => handleInputChange({ name, value: checked })}
                    />
                  </div>
                </div>
              )}
            </div>
            <Spacing size="6x-large" />
          </div>
          <ButtonGroup
            confirmationMessage={`Delete ${title} condition?`}
            confirmButtonType="button"
            isConfirmButtonDisabled={Boolean(!title)}
            hasConfirmButton
            onCancel={onCancel}
            onDelete={onDelete}
            onSave={handleSaveCondition}
          />
        </>
      )}
    </>
  );
};

ConditionQuestionDetails.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export { ConditionQuestionDetails };
