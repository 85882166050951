import styled from 'styled-components';

export const FadeIn = styled.div`
  opacity: 0;
  animation: fadeIn 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
