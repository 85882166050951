import React from 'react';

import { userTypeTableHeadings } from './UserTypeTable.constants';
import { createUserTypeTableData } from './UserTypeTable.helpers';

import { EKReactTable } from '../../../../components/Table/EKReactTable/EKReactTable';

const UserTypeTable = ({ data, onEdit }) => <EKReactTable columns={userTypeTableHeadings} data={createUserTypeTableData(data, onEdit)} />;

export default UserTypeTable;
