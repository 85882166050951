import React, { ReactNode, useState } from 'react';

import { Layout, Table } from '@netfront/ui-library';
import sortBy from 'lodash.sortby';
import { Column } from 'react-table';

import { QuestionSnippet } from '../QuestionSnippet';
import { replacePlaceholderText } from '../Snippets.helpers';

import { FormSnippetProps, IFormTableData } from './FormSnippet.interfaces';

import { CMSContentPage } from '../../CMSContentPage';
import { CMSSection } from '../../CMSSection';
import { CMSSectionGroup } from '../../CMSSectionGroup';
import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

import './FormSnippet.css';

const FormSnippet = ({
  accessToken,
  contentSnippetId,
  form: { contentPages, formType },
  placeholders,
  userFlowStepTrackId,
}: FormSnippetProps) => {
  const [index, setIndex] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const { sectionGroups = [] } = contentPages[0];
  const { contentSections = [], id } = sectionGroups[0];

  const totalIndex = contentSections.length - 1;

  const handleNextClick = () => {
    if (index <= totalIndex) {
      setIndex(index + 1);
    } else {
      setIsComplete(true);
    }
  };

  const handlePreviousClick = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const isLastIndex = index === totalIndex;
  const isZeroIndex = index === 0;

  if (formType === 'LIST') {
    return <CMSContentPage accessToken={accessToken} formContentSnippetId={contentSnippetId} userFlowStepTrackId={userFlowStepTrackId} />;
  }

  if (formType === 'TABLE') {
    const [contentPage] = contentPages;

    const { sectionGroups: formSectionGroup } = contentPage;
    const { contentSections: formContentSections } = formSectionGroup[0];
    const { id: formSectionId, contentLayoutId, sectionContainers } = formContentSections[0];

    const formSnippets = sectionContainers[0].snippets;
    const questionSnippets = sortBy(formSnippets, 'sort').filter(({ __typename }) => __typename === 'ContentSnippetQuestionType');

    const { configuration: firstQuestionSnippetConfiguration } = questionSnippets[0];
    const { responseSet: firstQuestionSnippetConfigurationResponseSet } = firstQuestionSnippetConfiguration ?? {};
    const { availableResponses: firstQuestionSnippetConfigurationResponseSetAvailableResponses = [] } =
      firstQuestionSnippetConfigurationResponseSet ?? {};

    const formTableColumns: ReadonlyArray<Column<IFormTableData>> = [
      {
        accessor: 'question',
        Cell: ({ value }: { value: ReactNode }) => <div className="c-question-snippet__question">{value}</div>,
        Header: () => <div />,
      },
      ...firstQuestionSnippetConfigurationResponseSetAvailableResponses.map(({ label = '' }) => ({
        accessor: label,
        Cell: ({ value }: { value: ReactNode }) => <div className="">{value}</div>,
        Header: () => <div>{label}</div>,
      })),
    ];

    const visibleQuestionSnippets = questionSnippets.filter(({ visible }) => visible);

    const formTableData: IFormTableData[] = visibleQuestionSnippets.map((snippet) => {
      const { configuration, id: formSnippetId, questionText } = snippet;
      const { responseSet } = configuration ?? {};
      const { availableResponses = [] } = responseSet ?? {};

      const initialTableData: IFormTableData = {
        id: snippet.id,
        question: (
          <DOMPurifySanitizedHtmlContent
            html={`<div class="c-question-snippet__question-${snippet.id}">${replacePlaceholderText(questionText, placeholders)}</div>`}
          />
        ),
      };

      return availableResponses
        .flatMap((availableResponse) => {
          const { label } = availableResponse;

          if (!label) {
            return [];
          }

          return [
            {
              availableResponse,
              label,
            },
          ];
        })
        .reduce((accumulator, { availableResponse, label }) => {
          return {
            ...accumulator,
            [label]: (
              <QuestionSnippet
                accessToken={accessToken}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...snippet}
                contentSnippetId={formSnippetId}
                contentSnippetQuestion={snippet}
                formType={formType}
                response={availableResponse}
                userFlowStepTrackId={userFlowStepTrackId}
              />
            ),
          };
        }, initialTableData);
    });

    return (
      <CMSSection contentSectionId={formSectionId}>
        <Layout gap="none" layoutId={contentLayoutId}>
          <Table<IFormTableData>
            additionalClassNames="c-table-form"
            areColumnHeadingsSortable={false}
            columns={formTableColumns}
            data={formTableData}
          />
        </Layout>
      </CMSSection>
    );
  }

  return (
    <React.Fragment>
      {!isComplete && (
        <div className={`ek-quiz ek-quiz-${contentSnippetId}`}>
          <CMSSectionGroup contentSections={[contentSections[index]]} sectionGroupId={id} userFlowStepTrackId={userFlowStepTrackId} />
          {!isZeroIndex && <input type="button" value="Previous" onClick={handlePreviousClick} />}
          <input type="button" value={isLastIndex ? 'Finish' : 'Next'} onClick={handleNextClick} />
        </div>
      )}
      {isComplete && <p>Thanks for completing the quiz!</p>}
    </React.Fragment>
  );
};

export { FormSnippet };
