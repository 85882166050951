import gql from 'graphql-tag';

export const GET_TEMPLATES = gql`
  query($projectId: String!) {
    project {
      getTemplates(projectId: $projectId) {
        content
        id
        subject
        name
        type
        usage
        smsUsage
      }
    }
  }
`;
