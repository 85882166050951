import {
  getTimeInMillisecondsFromDays,
  getTimeInMillisecondsFromHours,
  getTimeInMillisecondsFromMinutes,
  getTimeInMillisecondsFromSeconds,
} from '../number';

import { StorageExpiryTimeUnitCalculationFunctionMapType } from './storage.types';

const EXPIRY_TIME_UNIT_CALCULATION_FUNCTION_MAP: StorageExpiryTimeUnitCalculationFunctionMapType = Object.freeze({
  days: getTimeInMillisecondsFromDays,
  minutes: getTimeInMillisecondsFromMinutes,
  hours: getTimeInMillisecondsFromHours,
  seconds: getTimeInMillisecondsFromSeconds,
});

export { EXPIRY_TIME_UNIT_CALCULATION_FUNCTION_MAP };
