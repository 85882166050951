/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import styled from 'styled-components';

import {
  ActionsContainer,
  TableContainer,
  FirstnameTd,
} from '../OrganisationUsersPage/styled';

import PopupMessage from '../../components/PopupMessage/PopupMessage';
import client from '../../middleware/client';
import { SORT_TAG_IN_PROJECT } from '../../middleware/Tag/sortTagInProject';
import EditIcon from '../../static/img/icon-dots.svg';
import TopIcon from '../../static/img/icon-top.svg';

const Spacer = styled.div`
  margin: 2rem 0;
`;

const Action = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;

const FlexTr = styled(Tr)`
  background-color: ${props => (props.isDragging ? '#e4e4e3' : '#fff')};
  display: flex;
  justify-content: space-between;
`;

const BlockTbody = styled(Tbody)`
  display: block;
  max-height: 40em;
  overflow-y: scroll;
`;

/**
 *
 * @todo
 * Upate action components
 *
 * There are several instances of this component in user management.
 * Might be useful to update and put into
 * it's own module for future re-use
 */

export const UserTagTable = props => {
  const { currentTags, onEdit } = props;

  const [tags, setTags] = useState(currentTags);
  const [errMsg, setErrMsg] = useState('');
  const [sortTag] = useMutation(SORT_TAG_IN_PROJECT, {
    client,
  });

  useEffect(() => {
    setTags(currentTags);
  }, [currentTags]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    sortTag({
      variables: {
        sort: endIndex,
        tagId: removed.id,
      },
    })
      .then(() => {})
      .catch(err => {
        setErrMsg(err.toString());
        setTimeout(() => {
          setErrMsg('');
        }, 3000);
      });
    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const items = reorder(tags, result.source.index, result.destination.index);
    setTags(items);
  };

  const handleMoveToTop = id => {
    const source = tags.findIndex(t => t.id === id);
    const items = reorder(tags, source, 0);
    setTags(items);
  };

  return (
    <Spacer>
      <TableContainer style={{ border: '1px solid #e1e1e1' }}>
        <Thead>
          <Tr key="table-head">
            <Th data-sort="tag" scope="col" style={{ paddingLeft: '2.3em' }}>
              Tag
            </Th>
          </Tr>
        </Thead>
        <BlockTbody>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {tags.length > 0 &&
                    tags.map((tag, index) => (
                      <Draggable
                        draggableId={`drag-${tag.id}`}
                        index={index}
                        key={tag.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <FlexTr
                              isDragging={snapshot.isDragging}
                              key={tag.id}
                            >
                              <FirstnameTd>
                                <p>{tag.name}</p>
                              </FirstnameTd>
                              <Td>
                                <ActionsContainer>
                                  {/* It will be implemented in future. */}
                                  {/* <Action>
                                    <img alt="visibility" src={ViewIcon} />
                                  </Action> */}
                                  <Action
                                    onClick={() => handleMoveToTop(tag.id)}
                                  >
                                    <img alt="move to top" src={TopIcon} />
                                  </Action>
                                  <Action onClick={() => onEdit(tag)}>
                                    <img alt="edit tag" src={EditIcon} />
                                  </Action>
                                </ActionsContainer>
                              </Td>
                            </FlexTr>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </BlockTbody>
      </TableContainer>
      {errMsg !== '' && <PopupMessage isError message={errMsg} />}
    </Spacer>
  );
};

UserTagTable.propTypes = {
  currentTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEdit: PropTypes.func,
};

UserTagTable.defaultProps = {
  onEdit: () => {},
};
