import gql from 'graphql-tag';

export const DEFINE_MESSAGES = gql`
  mutation(
    $configurationId: Int!
    $correct: String!
    $incorrect: String!
    $incomplete: String!
    $example: String!
  ) {
    questionConfiguration {
      defineMessages(
        configurationId: $configurationId
        messages: [
          { type: CORRECT, text: $correct }
          { type: INCORRECT, text: $incorrect }
          { type: INCOMPLETE, text: $incomplete }
          { type: EXAMPLE, text: $example }
        ]
      ) {
        id
      }
    }
  }
`;
