import React, { useEffect, useState } from 'react';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Preloader } from '@netfront/ui-library';
import kebabCase from 'lodash.kebabcase';
import sortBy from 'lodash.sortby';
import PropTypes from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { SideBar } from '../Sidebar/SideBar';
import { ConfirmModal } from '../UI/Modal/ConfirmModal';

import { AddressTab } from './Directory/AddressTab/AddressTab';
import { DirectoryTab } from './Directory/DirectoryTab/DirectoryTab';
import { TagsTab } from './Directory/TagsTab/TagsTab';

import client from '../../middleware/client';
import { CREATE_DIRECTORY } from '../../middleware/Project/createDirectory';
import { CREATE_OR_UPDATE_ADDRESS } from '../../middleware/Project/createOrUpdateAddress';
import { REMOVE_DIRECTORY } from '../../middleware/Project/removeDirectory';
import { SEARCH_TAGS } from '../../middleware/Project/SearchProjectTags';
import { UPDATE_DIRECTORY } from '../../middleware/Project/updateDirectory';
import { DEFAULT_TOAST_OPTIONS } from '../../utils';
import { getApiErrorMessages } from '../../utils/utils';

const TAB_IDS = Object.freeze({
  directoryTab: 0,
  addressTab: 1,
  tagsTab: 4,
});

const TOAST_MESSAGES = Object.freeze({
  success: Object.freeze({
    directoryCreated: 'Directory successfully created',
    directoryRemoved: 'Directory successfully removed',
    directoryUpdated: 'Directory successfully updated',
  }),
});

const DirectorySideBar = ({ directoryTypeOptions, item, isCreateNew, isSideBarOpen, onClose, onCreate, onDelete, onUpdate }) => {
  const { projectId } = useParams();

  const [addressItem, setAddressItem] = useState(item ? item.address : undefined);
  const [canOpenSideBar, setCanOpenSideBar] = useState(false);
  const [directoryItem, setDirectoryItem] = useState(item);
  const [modal, setModal] = useState(null);
  const [selectedTabId, setSelectedTabId] = useState(TAB_IDS.directoryTab);
  const [tags, setTags] = useState([]);
  const [operatingHours, setOperatingHours] = useState([]);

  const {
    asset,
    description = '',
    id: directoryId,
    directoryTypeId,
    phoneNumber,
    email,
    tags: directoryTags = '',
    title,
    status,
    subTitle,
    url,
  } = directoryItem || {};

  const { city, country, latitude, line1, longitude, postcode, state, suburb } = addressItem || {};

  const [createOrUpdateAddress, { loading: isCreateOrUpdateAddressLoading }] = useMutation(CREATE_OR_UPDATE_ADDRESS, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const {
        directory: { createOrUpdateAddress: updatedDirectory },
      } = response;

      setAddressItem(updatedDirectory.address);

      onUpdate(updatedDirectory);

      toast.success(isCreateNew ? TOAST_MESSAGES.success.directoryCreated : TOAST_MESSAGES.success.directoryUpdated);
    },
    onError(error) {
      getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));
    },
  });

  const [createDirectory, { loading: isCreateDirectoryLoading }] = useMutation(CREATE_DIRECTORY, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const {
        directory: { createDirectory: newDirectory },
      } = response;

      onCreate(newDirectory);

      if (!addressItem) {
        toast.success(TOAST_MESSAGES.success.directoryCreated);
        return;
      }

      const { id } = newDirectory;
      const address = { city, country, latitude, line1, longitude, postcode, state, suburb };

      createOrUpdateAddress({
        variables: {
          address,
          directoryId: id,
        },
      });
    },
    onError(error) {
      getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));

      if (!isCreateNew) {
        return;
      }

      setSelectedTabId(TAB_IDS.directoryTab);

      toast.error('Please fill out the required fields to create a directory');
    },
  });

  const [removeDirectoryItem, { loading: isRemoveDirectoryItem }] = useMutation(REMOVE_DIRECTORY, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted() {
      onDelete(directoryItem);

      toast.success(TOAST_MESSAGES.success.directoryRemoved);
    },
    onError(error) {
      getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));
    },
  });

  const [searchProjectTags, { loading: isSearchProjectTagsLoading }] = useLazyQuery(SEARCH_TAGS, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const {
        tag: { searchTags: projectTags },
      } = response;

      setCanOpenSideBar(true);
      setTags(sortBy(projectTags, 'sort'));
    },
    onError(error) {
      getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));
    },
  });

  const [updateDirectory, { loading: isUpdateDirectoryLoading }] = useMutation(UPDATE_DIRECTORY, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const {
        directory: { updateDirectory: updatedDirectory },
      } = response;

      onUpdate(updatedDirectory);

      if (!addressItem) {
        toast.success(TOAST_MESSAGES.success.directoryUpdated);
        return;
      }

      const { city: city1, country: country1, line1: line11, postcode: postcode1, state: state1, suburb: suburb1 } = addressItem;
      const { city: city2, country: country2, line1: line12, postcode: postcode2, state: state2, suburb: suburb2 } = item.address || {};

      const initialAddressWithoutLatLng = [city1, country1, line11, postcode1, state1, suburb1].join(',');
      const updatedAddressWithoutLatLng = [city2, country2, line12, postcode2, state2, suburb2].join(',');
      const isAddressChanged = initialAddressWithoutLatLng !== updatedAddressWithoutLatLng;

      const address = {
        city,
        country,
        latitude: isAddressChanged ? undefined : latitude,
        line1,
        longitude: isAddressChanged ? undefined : longitude,
        postcode,
        state,
        suburb,
      };

      createOrUpdateAddress({
        variables: {
          address,
          directoryId,
        },
      });
    },
    onError(error) {
      getApiErrorMessages(error).forEach((errorMessage) => toast.error(errorMessage));
    },
  });

  const handleCloseModal = () => {
    setModal(null);
  };

  const handleCloseSideBar = () => {
    onClose();

    setDirectoryItem({});
    setSelectedTabId(TAB_IDS.directoryTab);
  };

  const handleCreate = (event) => {
    event.preventDefault();

    const { assetId } = asset || {};

    if (!title) {
      toast.error('Please fill out the directory details');

      setSelectedTabId(TAB_IDS.directoryTab);

      return;
    }

    const directory = {
      assetId,
      description,
      directoryTypeId,
      friendlyUrl: kebabCase(title),
      phoneNumber,
      email,
      projectId,
      tags: directoryTags,
      title,
      subTitle,
      url: url ? encodeURIComponent(url) : undefined,
      operatingHours: operatingHours.map((operatingHoursItem) => JSON.stringify(operatingHoursItem)),
    };

    createDirectory({
      variables: {
        directory,
      },
    });
  };

  const handleConfirm = () => {
    removeDirectoryItem({
      variables: {
        directoryId,
      },
    });
  };

  const handleOpenModal = (info) => {
    setModal(info);
  };

  const handleSelectSideBarTab = (id) => {
    setSelectedTabId(id);
  };

  const handleTagClick = (isTagActive, tag) => {
    const activeTags = directoryTags ? directoryTags.split(',') : [];
    const updatedActiveTags = [...activeTags];

    if (isTagActive) {
      updatedActiveTags.push(tag.name);
    } else {
      const index = updatedActiveTags.findIndex((activeTag) => activeTag === tag.name);
      updatedActiveTags.splice(index, 1);
    }

    setDirectoryItem((currentState) => ({
      ...currentState,
      tags: updatedActiveTags.join(','),
    }));
  };

  const handleUpdateAddress = (event) => {
    const {
      target: { name, value },
    } = event;

    setAddressItem((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };

  const handleUpdateAsset = (value) => {
    setDirectoryItem((currentState) => ({
      ...currentState,
      asset: value,
    }));
  };

  const handleUpdateCountryDropdown = (event) => {
    const {
      target: { value },
    } = event;

    setAddressItem((currentState) => ({
      ...currentState,
      country: value,
    }));
  };

  const handleUpdateDirectory = (event) => {
    const {
      target: { value, name },
    } = event;

    setDirectoryItem((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };

  const handleUpdateDirectoryType = (event) => {
    const {
      target: { value },
    } = event;

    setDirectoryItem((currentState) => ({
      ...currentState,
      directoryTypeId: Number(value),
    }));
  };

  const handleUpdateStatus = (event) => {
    const {
      target: { value },
    } = event;

    setDirectoryItem((currentState) => ({
      ...currentState,
      status: value,
    }));
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const { assetId } = asset || {};

    const directory = {
      assetId,
      description,
      directoryTypeId,
      directoryId,
      phoneNumber,
      friendlyUrl: kebabCase(title),
      email,
      tags: directoryTags,
      title,
      status,
      subTitle,
      url: url ? encodeURIComponent(url) : undefined,
      operatingHours: operatingHours.map((operatingHoursItem) => JSON.stringify(operatingHoursItem)),
    };

    updateDirectory({
      variables: {
        directory,
      },
    });
  };

  const handleUpdateOperatingHours = (updatedOperatingHours) => {
    setOperatingHours(updatedOperatingHours);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    searchProjectTags({
      variables: {
        projectId,
      },
    });
  }, [projectId]);

  useEffect(() => {
    if (!item) return;
    const { operatingHours: selectedOperatingHours } = item;
    setOperatingHours(selectedOperatingHours?.map((operatingHoursItem) => JSON.parse(operatingHoursItem || '{}')) ?? []);
  }, [item]);

  const isLoading =
    isCreateDirectoryLoading ||
    isCreateOrUpdateAddressLoading ||
    isRemoveDirectoryItem ||
    isSearchProjectTagsLoading ||
    isUpdateDirectoryLoading;

  const isEdit = Boolean(item);

  const addressTab = {
    component: (
      <AddressTab
        address={addressItem}
        onClose={handleCloseSideBar}
        onDelete={isEdit ? handleOpenModal : undefined}
        onSave={isEdit ? handleUpdate : handleCreate}
        onUpdateAddress={handleUpdateAddress}
        onUpdateCountryDropdown={handleUpdateCountryDropdown}
      />
    ),
    label: 'Address',
    id: TAB_IDS.addressTab,
  };

  const directoryTab = {
    component: (
      <DirectoryTab
        directory={directoryItem}
        directoryTypeOptions={directoryTypeOptions}
        isCreateNew={isCreateNew}
        operatingHours={operatingHours}
        onClose={handleCloseSideBar}
        onDelete={isEdit ? handleOpenModal : undefined}
        onSave={isEdit ? handleUpdate : handleCreate}
        onUpdateAsset={handleUpdateAsset}
        onUpdateDirectoryType={handleUpdateDirectoryType}
        onUpdateInfo={handleUpdateDirectory}
        onUpdateOperatingHours={handleUpdateOperatingHours}
        onUpdateStatus={handleUpdateStatus}
      />
    ),
    label: 'General',
    id: TAB_IDS.directoryTab,
  };

  const tagsTab = {
    component: (
      <TagsTab
        activeTags={directoryTags}
        tags={tags}
        onClickTag={handleTagClick}
        onClose={handleCloseSideBar}
        onDelete={isEdit ? handleOpenModal : undefined}
        onSave={isEdit ? handleUpdate : handleCreate}
      />
    ),
    label: 'Tags',
    id: TAB_IDS.tagsTab,
  };

  const sideBarTabViews = [directoryTab, addressTab];

  if (tags.length) {
    sideBarTabViews.push(tagsTab);
  }

  return (
    <>
      <Preloader isLoading={isLoading} />
      {modal ? <ConfirmModal modal={modal} onCloseModal={handleCloseModal} onConfirm={handleConfirm} /> : null}
      {canOpenSideBar && (
        <SideBar
          isOpen={isSideBarOpen}
          selectedTabId={selectedTabId}
          tabViews={sideBarTabViews}
          onCloseHandler={handleCloseSideBar}
          onTabChangeHandler={handleSelectSideBarTab}
        />
      )}
      <Toaster toastOptions={DEFAULT_TOAST_OPTIONS} />
    </>
  );
};

DirectorySideBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  directoryTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  isCreateNew: PropTypes.bool,
  isSideBarOpen: PropTypes.bool,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    assetId: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    tags: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default DirectorySideBar;
