import React, { useEffect, useState, useRef } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { format } from 'date-fns';

import Buttons from '../Buttons/Buttons';

import { useSocialState } from '../../../context/socialPage/SocialPageContext';
import { useApolloClient } from '../../../middleware/apollo/useApolloClient';
import { CREATE_COMMENT } from '../../../middleware/Social/createComment';
import { DELETE_COMMENT } from '../../../middleware/Social/deleteComment';
import { UPDATE_COMMENT } from '../../../middleware/Social/updateComment';
import goBackIcon from '../../../static/img/back-icon.svg';
import ImageIcon from '../../Icon/ImageIcon';
import { ModalButtonGroup } from '../../RightNavBar/RightNavBar';
import SquareButton from '../../UI/Button/SquareButton';
import NormalModal from '../../UI/Modal/NormalModal';
import NotificationMessage from '../../UI/NotificationMessage/NotificationMessage';
import Textarea from '../../UI/Textarea/Textarea';

import './views.scss';

const Comment = ({ onClose }) => {
  const client = useRef(useApolloClient('bonobo')).current;
  const [pageState, dispatch] = useSocialState();
  const { activePostId, activeCommentId, comments } = pageState;
  const [comment, setComment] = useState(undefined);
  const [currentCommentId, setCurrentCommentId] = useState(undefined);
  const [currentComment, setCurrentComment] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [notifications, setNotifications] = useState([]);

  const { message = '', author = {}, createdDateTime } = currentComment || {};
  const { firstName = '', lastName = '' } = author;

  const clearActiveCommentId = () => {
    dispatch({
      type: 'setActiveCommentId',
      newState: null,
    });
  };

  const handlePreviousView = () => {
    clearActiveCommentId();
    dispatch({
      type: 'setIsAddNewComment',
      newState: false,
    });
  };

  const handleCommentToastError = (error) => {
    setNotifications([
      ...notifications,
      {
        group: 'comment',
        message: error,
        type: 'error',
      },
    ]);
  };

  const [createNewCommentRequest] = useMutation(CREATE_COMMENT, {
    client,
    onCompleted() {
      setNotifications([
        ...notifications,
        {
          group: 'comment',
          message: 'Comment successfully added',
          type: 'success',
        },
      ]);

      setTimeout(() => {
        handlePreviousView();
      }, 1000);
    },
    onError(error) {
      handleCommentToastError(error.message);
    },
  });

  const [updateCommentRequest] = useMutation(UPDATE_COMMENT, {
    client,
    onCompleted({ comment: { update } }) {
      dispatch({
        type: 'updateComment',
        newState: update,
      });
      setNotifications([
        ...notifications,
        {
          group: 'comment',
          message: 'Comment successfully updated',
          type: 'success',
        },
      ]);

      setTimeout(() => {
        handlePreviousView();
      }, 1000);
    },
    onError(error) {
      handleCommentToastError(error.message);
    },
  });

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    client,
    onCompleted() {
      setIsModalOpen(false);
      setNotifications([
        ...notifications,
        {
          group: 'generic',
          message: 'Comment successfully deleted',
          type: 'info',
        },
      ]);

      dispatch({
        type: 'deleteComment',
        newState: currentCommentId,
      });
      setTimeout(() => {
        clearActiveCommentId();
      }, 1000);
    },
    onError(error) {
      handleCommentToastError(error.message);
    },
  });

  const handleClearNotifications = () => {
    setNotifications([]);
  };

  const handleOnSave = () => {
    if (currentComment) {
      updateCommentRequest({
        variables: {
          id: activeCommentId,
          message: comment,
        },
      });
      return;
    }

    createNewCommentRequest({
      variables: {
        postId: activePostId,
        message: comment,
      },
    });
  };

  const handleDeleteComment = () => {
    setCurrentCommentId(activeCommentId);
    deleteComment({ variables: { commentId: activeCommentId } });
  };

  useEffect(() => {
    const selectedComment = comments.find((item) => item.node.id === activeCommentId);
    if (!selectedComment) {
      return;
    }

    const { node } = selectedComment;
    setCurrentComment(node);
  }, [activeCommentId]);

  return (
    <>
      <NormalModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        Are you sure you want to delete this comment?
        <ModalButtonGroup>
          <SquareButton isClicked={() => setIsModalOpen(false)}>No, cancel</SquareButton>
          <SquareButton bgColor="#dc3545" color="white" isClicked={handleDeleteComment}>
            Yes, delete
          </SquareButton>
        </ModalButtonGroup>
      </NormalModal>
      <div className="c-view-wrapper">
        <fieldset>
          <legend className="h-hide">Comment</legend>
          <div className="h-flex h-align-items-center">
            <button className="c-icon-button h-margin-right-sm" type="button" onClick={handlePreviousView}>
              <ImageIcon src={goBackIcon} />
            </button>
            <strong className="c-view-title">Comments</strong>
          </div>
          <div className="h-flex h-justify-content-between h-align-items-center h-margin-top-sm h-margin-bottom-sm">
            {!currentComment && (
              <label className="c-list-item-title" htmlFor="add-new-comment">
                Add new comment
              </label>
            )}
            {currentComment && (
              <>
                <span className="c-list-item-info-text h-bold-text">{`${firstName} ${lastName}`}</span>
                <span className="c-list-item-info-text c-light-text">{format(new Date(createdDateTime), 'Pp')}</span>
              </>
            )}
          </div>
          <Textarea id="add-new-comment" isChanged={(value) => setComment(value)} text={message} />
          {notifications.map(({ group, message, type }) => {
            if (!group.includes('comment')) {
              return null;
            }
            return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
          })}
        </fieldset>
      </div>
      <div className="c-toast-container">
        {notifications.map(({ group, message, type }) => {
          if (!group.includes('generic')) {
            return null;
          }
          return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
        })}
      </div>
      <div className="c-sidebar-button-container c-flex-center">
        <Buttons onClose={onClose} onDelete={() => setIsModalOpen(true)} onSave={handleOnSave} />
      </div>
    </>
  );
};

export default Comment;
