import React from 'react';

import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import Cookies from 'universal-cookie';

import GeladaClient from '../_Gelada/geladaClient';
import ErrorModal from '../ErrorModal';

import { LOGIN } from './loginRequest';

import Preloader from '../../components/Preloader/Preloader';

const cookies = new Cookies();
const custom = cookies.get('customBuild');
const projectId = custom === undefined ? null : custom.projectId;

const loginRequestWithoutRedirection = ({
  userName,
  password,
  onLoginError,
  onSuccess,
}) => (
  <Query
    client={GeladaClient(false)}
    fetchPolicy="network-only"
    query={LOGIN}
    variables={{
      login: userName,
      password,
      customProjectId: projectId,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Preloader />;
      if (error) {
        return <ErrorModal error={error} onError={onLoginError} />;
      }

      if (data && Object.keys(data).length > 0) {
        onSuccess(data);
      }
      return null;
    }}
  </Query>
);
loginRequestWithoutRedirection.propTypes = {
  onLoginError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default loginRequestWithoutRedirection;
