import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ACTION_DETAILS } from './UseGetActionDetails.graphql';

const useGetActionDetails = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};
  const [executeGetActionDetails, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { get: actionDetails },
        } = data;

        onCompleted({
          actionDetails,
        });
      },
      onError,
    },
    query: query ?? GET_ACTION_DETAILS,
    token,
  });

  const handleGetActionDetails = ({ variables }) => {
    executeGetActionDetails({
      variables,
    });
  };

  return {
    handleGetActionDetails,
    isLoading,
  };
};

export { useGetActionDetails };
