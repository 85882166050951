import React from 'react';

import './Dropzone.css';
import Dropzone from 'react-dropzone';

import { createDropzoneFileName } from '../../utils';

const DropzoneArea = ({ onDrop, fileType, dropRef }) => (
  <Dropzone accept={fileType} noClick noKeyboard onDropAccepted={files => onDrop(files)} ref={dropRef}>
    {({ getRootProps, getInputProps, isDragActive }) => (
      <div className="d-dropzone-container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>{isDragActive ? 'Drop here' : `Drop ${createDropzoneFileName(fileType)} here or click to upload`}</p>
          <p>
            (<strong>Maximum</strong> 1 file)
          </p>
        </div>
      </div>
    )}
  </Dropzone>
);

export default DropzoneArea;
