import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SNIPPET_EVENT_DETAILS } from './UseGetSnippetEventDetails.graphql';

const useGetSnippetEventDetails = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};
  const [executeGetSnippetEventDetails, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getContentSnippet: snippet },
        } = data;

        onCompleted({
          snippet,
        });
      },
      onError,
    },
    query: query ?? GET_SNIPPET_EVENT_DETAILS,
    token,
  });

  const handleGetSnippetEventDetails = ({ variables }) => {
    executeGetSnippetEventDetails({
      variables,
    });
  };

  return {
    handleGetSnippetEventDetails,
    isLoading,
  };
};

export { useGetSnippetEventDetails };
