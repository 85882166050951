import React from 'react';

import { ButtonIconOnly, IOption, Select, TreeNodeInArray } from '@netfront/ui-library';
import first from 'lodash.first';
import noop from 'lodash.noop';

import { IGetAccordionDataParams } from './QuestionAnswerMatchSnippet.interfaces';

import { IDBQuestionAnswerMatchResponseMatch, IDBQuestionResponse } from '../../../../interfaces';

const getAccordionData = ({
  answers,
  availableResponses,
  matchItems,
  onMatchItemsDropdownChange,
}: IGetAccordionDataParams): TreeNodeInArray[] => {
  const matchItemOptions = matchItems.map(({ id, text: innerMatchItemText = '' }, innerMatchItemIndex): IOption => {
    return {
      id,
      name: innerMatchItemText,
      value: innerMatchItemIndex,
    };
  });

  const matchItemData = matchItems.reduce((accumulator, { maxItemsCount = 0 }, matchItemIndex): Record<number, boolean> => {
    const filteredAnswers = answers.filter(({ definedPosition }) => definedPosition === matchItemIndex);

    const { length: totalMatchItemValues } = filteredAnswers;

    const hasAnsweredMaxItemsInMatchItem = maxItemsCount === totalMatchItemValues;

    return {
      ...accumulator,
      [matchItemIndex]: hasAnsweredMaxItemsInMatchItem,
    };
  }, {} as Record<number, boolean>);

  return matchItems.map(({ id: matchItemId, maxItemsCount = 0, text = '' }, matchItemIndex): TreeNodeInArray => {
    const filteredAnswers = answers.filter(({ definedPosition }) => definedPosition === matchItemIndex);
    const key = `match-item-${matchItemId}`;

    const { length: totalMatchItemValues } = filteredAnswers;

    let dropdownItemOptions = [...matchItemOptions];

    if (!maxItemsCount) {
      dropdownItemOptions = matchItemOptions.flatMap((matchItemOption) => {
        const { value } = matchItemOption;
        const hasAnsweredMaxItemsInMatchItem = matchItemData[value];

        if (hasAnsweredMaxItemsInMatchItem) {
          return [];
        }

        return [matchItemOption];
      });
    }

    const hasAnsweredMaxItemsInMatchItem = matchItemData[matchItemIndex];

    const itemInfoComponent = (
      <div className="c-question-answer-match__info-component">
        {hasAnsweredMaxItemsInMatchItem && <ButtonIconOnly iconId="id_tick_icon" text="" onClick={noop} />}
        {maxItemsCount ? (
          <span>
            {totalMatchItemValues} of {maxItemsCount}
          </span>
        ) : (
          <span>{totalMatchItemValues} values</span>
        )}
      </div>
    );

    return {
      itemInfoComponent,
      key,
      label: text,
      nodes: filteredAnswers.map(({ questionResponseMatchId }): TreeNodeInArray => {
        const availableResponse = availableResponses.find(
          ({ id: availableResponseId }) => availableResponseId === questionResponseMatchId
        ) as IDBQuestionResponse;

        const { id: availableResponseId, label = '' } = availableResponse;

        const interactionComponent = (
          <Select
            id={`${key}-select`}
            labelText=""
            name=""
            options={dropdownItemOptions}
            value={matchItemIndex}
            onChange={({ target: { value } }) => {
              onMatchItemsDropdownChange(availableResponseId, Number(value));
            }}
          />
        );

        return {
          key: `${key}-available-response-${availableResponseId}`,
          interactionComponent,
          itemInfoComponent,
          label,
          matchItemIndex,
          nodes: [],
        };
      }),
    };
  });
};

const getAvailableResponseNumber = (availableResponse: IDBQuestionResponse, availableResponses: IDBQuestionResponse[]): number => {
  return availableResponses.findIndex(({ id }) => availableResponse.id === id) + 1;
};

const UnansweredAvailableResponses = (
  answers: IDBQuestionAnswerMatchResponseMatch[],
  availableResponses: IDBQuestionResponse[]
): IDBQuestionResponse[] => {
  const answeredAvailableResponseIds = answers.map(({ questionResponseMatchId }) => questionResponseMatchId);
  const unansweredAvailableResponses = availableResponses.filter(({ id }) => !answeredAvailableResponseIds.includes(id));

  return unansweredAvailableResponses;
};

const getFirstUnansweredAvailableResponse = (
  answers: IDBQuestionAnswerMatchResponseMatch[],
  availableResponses: IDBQuestionResponse[]
): IDBQuestionResponse | undefined => {
  return first(UnansweredAvailableResponses(answers, availableResponses));
};

export { getAccordionData, getAvailableResponseNumber, getFirstUnansweredAvailableResponse, UnansweredAvailableResponses };
