import React from 'react';

import cx from 'classnames';

import { SpacingProps } from './Spacing.types';

import './Spacing.css';

/**
 * For creating vertical space between components.
 */
const Spacing: React.FC<SpacingProps> = ({ additionalClassNames, children, size, isFullWidth = false }) => (
  <div
    className={cx(additionalClassNames, { 'h-spacing': !size, [`h-spacing-${size}`]: size, 'is-full-width': isFullWidth })}
    data-testid="qa-spacing"
  >
    {children}
  </div>
);

export { Spacing };
