import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SNIPPET_LOCATION } from './UseConditionLocations.graphql';

const useConditionLocations = (options) => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? {};

  const [executeGetLocations, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getSnippetForms },
        } = data;

        onCompleted({
          getSnippetForms,
        });
      },
      onError,
    },
    query: query ?? GET_SNIPPET_LOCATION,
    token,
  });

  const handleGetLocations = ({ contentGroupId }) => {
    executeGetLocations({
      variables: {
        contentGroupId,
      },
    });
  };

  return {
    handleGetLocations,
    isLoading,
  };
};

export { useConditionLocations };
