import React from 'react';

import { Button, Icon, Input } from '@netfront/ui-library';

import './SearchFilter.css';

const SearchFilter = ({ isDisabled, onChange, onClear, onSearch, value, ...rest }) => {
  const handleSearchOnEnter = (event) => {
    const { charCode } = event;

    if (charCode === 13) {
      event.preventDefault();
      onSearch(String(value));
    }
  };

  return (
    <form className="c-search-filter">
      <div className="c-search-filter__input">
        <Icon className="c-search-filter__icon" id="id_search_icon" />

        <Input {...rest} isDisabled={isDisabled} value={value ?? ''} onChange={onChange} onKeyPress={handleSearchOnEnter} />

        <button className="c-search-filter__clear" title="Clear search" type="button" onClick={onClear}>
          <Icon id="id_close_icon" />
        </button>
      </div>

      <Button isDisabled={!value} text="Search" type="button" variant="primary-inverse" onClick={() => onSearch(String(value))} />
    </form>
  );
};

export { SearchFilter };
