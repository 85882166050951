import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../../ErrorModal';
import MutationRequest from '../../MutationRequest';

export const DEFINE_RESPONSE_SET_REQUEST = gql`
  mutation defineResponseSetRequest(
    $questionConfigurationId: Int!
    $questionResponseSetId: Int!
  ) {
    questionConfiguration {
      defineQuestionResponseSet(
        questionConfigurationId: $questionConfigurationId
        questionResponseSetId: $questionResponseSetId
      ) {
        id
      }
    }
  }
`;

const defineResponseSetRequest = ({
  questionConfigurationId,
  questionResponseSetId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={DEFINE_RESPONSE_SET_REQUEST}>
    {(createRadioResponseAns, { loading, error, data }) => {
      const variables = {
        questionConfigurationId,
        questionResponseSetId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createRadioResponseAns}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

defineResponseSetRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  questionConfigurationId: PropTypes.number.isRequired,
  questionResponseSetId: PropTypes.number.isRequired,
};

defineResponseSetRequest.defaultProps = {};

export default defineResponseSetRequest;
