import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  AppImage,
  LinkButton,
  FlexContainer,
  Heading1,
  OptionsBanner,
  OptionsBannerInner,
  PushRight1,
  ReturnButton,
  SubTitle,
} from '../MarketplaceAppDetailsPage/styled';
import { NewIcon } from '../MarketplacePage/styled';

import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import ApproveOrRejeceVersion from '../../middleware/Marketplace/ApproveOrRejectVersion';

export const ReturnToApp = props => {
  const { appId } = props;
  return (
    <PushRight1>
      <ReturnButton href={`/marketplace/app/${Number(appId)}`}>
        <NewIcon>
          <span className="icon-chevron" />
        </NewIcon>
        Return to app
      </ReturnButton>
    </PushRight1>
  );
};
ReturnToApp.propTypes = {
  appId: PropTypes.string.isRequired,
};

export const AppBuilder = props => {
  const {
    app,
    component,
    assetSource,
    content,
    handleInputs,
    handleClearErrorMessages,
    handleOnSaveComponent,
    history,
    iframe,
    isApproval,
    appVersionId,
    onUpdateUserData,
    versionStatus,
  } = props;

  const [newContent, setNewContent] = useState(content);
  const [changeStatus, setChangeStatus] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setNewContent(content);
  }, [content]);

  const handleLeaveIframe = () => {
    onUpdateUserData();
  };

  const handleVersionStatus = value => {
    setValid(value);
    setChangeStatus(true);
  };

  const handleStatusChangeError = () => {
    setChangeStatus(false);
  };

  const handleStatusChangeSuccess = () => {
    setChangeStatus(false);
    history.push('/marketplace/pending-apps');
  };

  let document = '';
  if (iframe.current) {
    document = iframe.current.contentDocument;
  }

  const documentContents = newContent.htmlContent
    ? `
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title>Document</title>
      <style>
        ${newContent.cssContent}
      </style>
      <script>
        ${newContent.jsContent}
      </script>
    </head>
    <body>
        ${newContent.htmlContent}
    </body>
    </html>
  `
    : '<html lang="en"></html>';
  if (document) {
    document.open();
    document.write(documentContents);
    document.close();
  }

  return (
    <div>
      <OptionsBanner style={{ backgroundColor: '#fff' }}>
        <ConsoleContainer>
          <OptionsBannerInner>
            <FlexContainer>
              <AppImage src={assetSource} />
              <div className="u-flex-column">
                <Heading1>{component.title}</Heading1>
                <SubTitle>by</SubTitle>
                <FlexContainer className="u-push-bottom">
                  <LinkButton
                    color="#972868"
                    href={`/marketplace/app/${app.id}/versions`}
                  >
                    Versions
                  </LinkButton>
                </FlexContainer>
              </div>
            </FlexContainer>
          </OptionsBannerInner>
        </ConsoleContainer>
      </OptionsBanner>
      <div className="row">
        <div className="col-md-4">
          <div className="c-editor">
            <div className="c-editor-header">HTML</div>
            <div className="c-editor-body  p-0 overflow-hidden">
              <textarea
                aria-label="Name"
                className="w-100 bg-transparent c-editor-body outline-none border-none"
                name="htmlContent"
                onChange={handleInputs}
                onClick={handleClearErrorMessages}
                required=""
                value={newContent.htmlContent || ''}
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="c-editor">
            <div className="c-editor-header">CSS</div>
            <div className="c-editor-body p-0 overflow-hidden">
              <textarea
                aria-label="Name"
                className="w-100 bg-transparent c-editor-body outline-none border-none"
                name="cssContent"
                onChange={handleInputs}
                onClick={handleClearErrorMessages}
                required=""
                value={newContent.cssContent || ''}
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="c-editor">
            <div className="c-editor-header">JS</div>
            <div className="c-editor-body p-0 overflow-hidden">
              <textarea
                aria-label="Name"
                className="w-100 bg-transparent c-editor-body outline-none border-none"
                name="jsContent"
                onChange={handleInputs}
                onClick={handleClearErrorMessages}
                required=""
                value={newContent.jsContent || ''}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="c-editor">
            <div className="c-editor-header">Preview</div>
            <div
              className="c-editor-body c-editor-preview"
              id="htmlContent"
              onMouseLeave={handleLeaveIframe}
            >
              {/* eslint-disable-next-line react/no-danger */}
              {/* <div dangerouslySetInnerHTML=
              {{ __html: documentContents }} /> */}
              <iframe className=" border-none" ref={iframe} title="result" />
            </div>
          </div>
        </div>

        {!isApproval && versionStatus !== 'PENDING' && (
          <div className="col-12 text-right mb-3">
            <button className="button--black px-4 mr-3" type="submit">
              Cancel
            </button>
            <button
              className="button--blue px-4"
              onClick={handleOnSaveComponent}
              type="submit"
            >
              Save
            </button>
          </div>
        )}

        {isApproval && versionStatus === 'PENDING' && (
          <div className="col-12 text-right mb-3">
            <button
              className="button--red px-4 mr-3"
              onClick={() => handleVersionStatus(false)}
              type="submit"
            >
              Reject
            </button>
            <button
              className="button--blue px-4"
              onClick={() => handleVersionStatus(true)}
              type="submit"
            >
              Approve
            </button>
          </div>
        )}
      </div>
      {changeStatus && (
        <ApproveOrRejeceVersion
          appVersionId={appVersionId}
          onError={handleStatusChangeError}
          onSuccess={handleStatusChangeSuccess}
          valid={valid}
        />
      )}
    </div>
  );
};

AppBuilder.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  appVersionId: PropTypes.number.isRequired,
  assetSource: PropTypes.string.isRequired,
  component: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  content: PropTypes.shape({
    cssContent: PropTypes.string,
    htmlContent: PropTypes.string,
    jsContent: PropTypes.string,
  }).isRequired,
  handleClearErrorMessages: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  handleOnSaveComponent: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  iframe: PropTypes.shape({
    current: PropTypes.shape({
      contentDocument: PropTypes.string,
    }),
  }).isRequired,
  isApproval: PropTypes.bool.isRequired,
  onUpdateUserData: PropTypes.func.isRequired,
  versionStatus: PropTypes.string.isRequired,
};
