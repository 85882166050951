import React from 'react';

import { Label, RadioButtonGroup, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

const ConditionRadioQuestion = ({ questionResponses, selectedResponse, onInputChange }) => (
  <>
    <Label additionalClassNames="c-action__item__label h-flex" forId="question response" labelText="Question response" isRequired>
      <Tooltip placement="left" text="This action will execute if the checkbox selection is as selected below" />
    </Label>

    <RadioButtonGroup
      items={questionResponses}
      name="questionResponseRadioId"
      selectedValue={selectedResponse}
      onChange={({ target: { name, value } }) => onInputChange({ name, value: Number(value) })}
    />
  </>
);

ConditionRadioQuestion.propTypes = {
  onInputChange: PropTypes.func,
  questionResponses: PropTypes.arrayOf(PropTypes.shape()),
  selectedResponse: PropTypes.string,
};

export { ConditionRadioQuestion };
