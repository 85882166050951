import React from 'react';

import PropTypes from 'prop-types';

function PopupMessageSideBar(props) {
  const { message } = props;
  return (
    <div className="c-popup-message-sidebar">
      <p>{message}</p>
    </div>
  );
}

PopupMessageSideBar.propTypes = {
  message: PropTypes.string,
};

PopupMessageSideBar.defaultProps = {
  message: null,
};

export default PopupMessageSideBar;
