import React, { Fragment, useEffect, useState } from 'react';

import { AudioSnippetProps } from './AudioSnippet.types';

import './AudioSnippet.css';

const AudioSnippet = ({ audio: { presignedUrl }, figureCaption }: AudioSnippetProps) => {
  const [audioUrl, setAudioUrl] = useState<string>();

  useEffect(() => {
    setAudioUrl(presignedUrl);
  }, [presignedUrl]);

  return (
    <Fragment>
      {audioUrl && (
        <div className="c-audio-snippet">
          <figure className="c-audio-snippet__figure">
            <audio className="c-audio-snippet__audio" controls>
              <source src={audioUrl} />
              <track kind="captions" srcLang="en" />
              Your browser does not support the
              <code>audio</code> element.
            </audio>
            {figureCaption ? <figcaption className="c-audio-snippet__figure-caption">{figureCaption}</figcaption> : null}
          </figure>
        </div>
      )}
    </Fragment>
  );
};

export { AudioSnippet };
