import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  CREATE_ACTION_SHOW_SNIPPET,
  CREATE_ACTION_HIDE_SNIPPET,
  CREATE_ACTION_TRIGGER_EVENT,
  CREATE_ACTION_SHOW_SECTION,
  CREATE_ACTION_HIDE_SECTION,
  CREATE_ACTION_SHOW_CONTAINER,
  CREATE_ACTION_HIDE_CONTAINER,
  CREATE_ACTION_SKIP_PAGE,
} from './UseCreateAction.graphql';

const useCreateAction = (options) => {
  const { mutation, onCompleted, onError, token, type } = options ?? {};

  const mutationMap = {
    QuestionActionShowType: CREATE_ACTION_SHOW_SNIPPET,
    QuestionActionHideType: CREATE_ACTION_HIDE_SNIPPET,
    QuestionActionTriggerContentEventType: CREATE_ACTION_TRIGGER_EVENT,
    QuestionActionShowSectionType: CREATE_ACTION_SHOW_SECTION,
    QuestionActionHideSectionType: CREATE_ACTION_HIDE_SECTION,
    QuestionActionShowContainerType: CREATE_ACTION_SHOW_CONTAINER,
    QuestionActionHideContainerType: CREATE_ACTION_HIDE_CONTAINER,
    QuestionActionSkipPageType: CREATE_ACTION_SKIP_PAGE,
  };

  const [executeCreateAction, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? mutationMap[type] ?? CREATE_ACTION_SHOW_SNIPPET,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { addAction: action },
        } = data;

        onCompleted({
          action,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateAction = ({ variables }) => {
    executeCreateAction({
      variables,
    });
  };

  return {
    handleCreateAction,
    isLoading,
  };
};

export { useCreateAction };
