import gql from 'graphql-tag';

export const GET_SUMMARY_FEEDBACK_CONFIGURATION = gql`
  query getSummaryFeedbackConfiguration($contentSnippetSummaryId: Int!) {
    contentSnippet {
      getContentSnippet(contentSnippetId: $contentSnippetSummaryId) {
        ... on ContentSnippetSummaryType {
          summaryFeedbackConfiguration {
            __typename
            contentSnippetSummaryId
            label
            ... on SummaryFeedbackConfigurationRadioType {
              __typename
              responseSet {
                availableResponses {
                  label
                  id
                  value
                }
                id
                title
              }
              responseSetId
            }

            ... on SummaryFeedbackConfigurationRankAndNoteType {
              noteMaxLength
            }
          }
        }
      }
    }
  }
`;
