import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_QUESTION_IN_CONTENT_GROUP } from './UseGetQuestions.graphql';

const useGetQuestions = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};
  const [executeGetQuestions, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: { getAllQuestionInStep: groupedContentSnippetQuestions },
        } = data;

        onCompleted({
          groupedContentSnippetQuestions,
        });
      },
      onError,
    },
    query: query ?? GET_QUESTION_IN_CONTENT_GROUP,
    token,
  });

  const handleGetQuestions = ({ variables }) => {
    executeGetQuestions({
      variables,
    });
  };

  return {
    handleGetQuestions,
    isLoading,
  };
};

export { useGetQuestions };
