import React from 'react';

import cx from 'classnames';

// eslint-disable-next-line import/extensions
import 'react-dates/initialize.js';

import { InputFieldWrapper } from '../InputFieldWrapper';
import { Skeleton } from '../Skeleton';

import { DatePickerProps } from './DatePicker.types';
import { IDateRange } from './DateRangePicker';
import { DateRangePicker } from './DateRangePicker/DateRangePicker';
import { SingleDatePicker } from './SingleDatePicker/SingleDatePicker';

const DatePicker = ({
  errorMessage,
  isReadOnly = false,
  isDisabled = false,
  hasLabelPadding = false,
  hasPadding = true,
  iconAfter,
  iconBefore,
  id,
  isCorrect = false,
  isEncouraged = false,
  isLabelHidden = false,
  isLoading = false,
  isRange = false,
  isRequired = false,
  labelFontWeight = 'light',
  labelText,
  maxDate,
  minDate,
  onDateRangeChangeHandler,
  onSingleDateChangeHandler,
  selectedDate,
  successMessage,
  tooltipPosition = 'end',
  tooltipText,
}: DatePickerProps) => {
  const datePickerClassNames = cx('c-date-picker', {
    'is-correct': isCorrect,
    'is-invalid': Boolean(errorMessage),
  });
  if (isLoading)
    return (
      <>
        {!isLabelHidden && <Skeleton additionalClassNames="c-label" width="10rem" />}
        <Skeleton height="2.5rem" />
      </>
    );

  return (
    <InputFieldWrapper
      hasLabelPadding={hasLabelPadding}
      hasPadding={hasPadding}
      iconAfter={iconAfter}
      iconBefore={iconBefore}
      id={id}
      isEncouraged={isEncouraged}
      isLabelHidden={isLabelHidden}
      isLoading={isLoading}
      isRequired={isRequired}
      label={labelText}
      labelFontWeight={labelFontWeight}
      message={{ error: errorMessage ?? '', success: successMessage ?? '' }}
      tooltipPosition={tooltipPosition}
      tooltipText={tooltipText}
      type="date"
    >
      <div className={datePickerClassNames}>
        {isRange ? (
          <DateRangePicker
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            selectedDate={selectedDate as IDateRange}
            onChangeHandler={onDateRangeChangeHandler}
          />
        ) : (
          <SingleDatePicker
            id={id}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            maxDate={maxDate}
            minDate={minDate}
            selectedDate={selectedDate as Date}
            onChangeHandler={onSingleDateChangeHandler}
          />
        )}
      </div>
    </InputFieldWrapper>
  );
};

export { DatePicker };
