import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_ACTION } from './UseDeleteAction.graphql';

const useDeleteAction = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeDeleteAction, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? DELETE_ACTION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { removeAction: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAction = ({ variables }) => {
    executeDeleteAction({
      variables,
    });
  };

  return {
    handleDeleteAction,
    isLoading,
  };
};

export { useDeleteAction };
