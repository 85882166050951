import React from 'react';

import cx from 'classnames';

import { FlipProps } from './Flip.types';

import './Flip.css';

const Flip = ({ asset, backgroundColor, borderColor, children, eventDirection, eventTitle, style, titleColor }: FlipProps) => {
  const flipCardFrontClass = cx('c-flip-card__front', {
    [`c-flip-card__front--${eventDirection.toLowerCase()}`]: eventDirection,
  });

  const flipWithoutImageStyle = {
    border: `1px solid ${borderColor}`,
    color: titleColor,
  };

  const flipWithImageStyle = {
    backgroundImage: asset?.presignedUrl ? `url(${asset.presignedUrl})` : '',
    ...flipWithoutImageStyle,
  };

  const frontMap: Record<FlipProps['style'], () => JSX.Element> = {
    ICON_ONLY: () => <div className={flipCardFrontClass} style={flipWithImageStyle} />,
    NONE: () => <div className={flipCardFrontClass} style={flipWithoutImageStyle} />,
    TEXT_AND_ICON: () => (
      <div className={flipCardFrontClass} style={flipWithImageStyle}>
        {eventTitle}
      </div>
    ),
    TEXT_ONLY: () => (
      <div className={flipCardFrontClass} style={flipWithImageStyle}>
        {eventTitle}
      </div>
    ),
  };

  return (
    <div className="c-flip-card">
      {frontMap[style]()}
      <div
        className={cx('c-flip-card__back', {
          [`c-flip-card__back--${eventDirection.toLowerCase()}`]: eventDirection,
        })}
        style={{ backgroundColor }}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export { Flip };
