import gql from 'graphql-tag';

export const CREATE_CONDITION_ON_ACTION_RADIO = gql`
  mutation questionActionAddCondition(
    $questionResponseRadioId: Int!
    $questionActionId: Int!
    $title: String!
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $isTemplate: Boolean!
  ) {
    questionAction {
      addConditionOnAction(
        radioResponseSelected: {
          questionResponseRadioId: $questionResponseRadioId
          questionActionId: $questionActionId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          isTemplate: $isTemplate
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionRadioResponseSelectedType {
          questionResponseRadioId
          selection
        }
      }
    }
  }
`;

export const CREATE_CONDITION_ON_ACTION_TEXT = gql`
  mutation questionActionAddCondition(
    $text: String!
    $questionActionId: Int!
    $title: String!
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $isTemplate: Boolean!
  ) {
    questionAction {
      addConditionOnAction(
        textEquals: {
          text: $text
          questionActionId: $questionActionId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          isTemplate: $isTemplate
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionTextType {
          text
        }
      }
    }
  }
`;

export const CREATE_CONDITION_ON_ACTION_SLIDER = gql`
  mutation questionActionAddConditionSlider(
    $minValue: Int!
    $maxValue: Int!
    $questionActionId: Int!
    $title: String!
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $isTemplate: Boolean!
  ) {
    questionAction {
      addConditionOnAction(
        slider: {
          maxValue: $maxValue
          minValue: $minValue
          questionActionId: $questionActionId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          isTemplate: $isTemplate
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionSliderValueSelectedType {
          minValue
          maxValue
        }
      }
    }
  }
`;

export const CREATE_CONDITION_ON_ACTION_CHECKBOX = gql`
  mutation questionActionAddConditionCheckbox(
    $questionResponseCheckboxId: Int!
    $questionActionId: Int!
    $title: String!
    $contentSnippetQuestionId: Int!
    $contentPageId: Int!
    $isTemplate: Boolean!
  ) {
    questionAction {
      addConditionOnAction(
        checkbox: {
          questionResponseCheckboxId: $questionResponseCheckboxId
          questionActionId: $questionActionId
          title: $title
          contentSnippetQuestionId: $contentSnippetQuestionId
          contentPageId: $contentPageId
          isTemplate: $isTemplate
        }
      ) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionCheckboxSelectedType {
          questionResponseCheckboxId
          selection
        }
      }
    }
  }
`;
