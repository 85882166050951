import React, { Fragment, useEffect, useState } from 'react';

import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { Dialog } from '@netfront/ui-library';
import update from 'immutability-helper';
import isNil from 'lodash.isnil';
import max from 'lodash.max';
import maxBy from 'lodash.maxby';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDebouncedCallback } from 'use-debounce';

import { INITIAL_DIALOG_STATE } from './SummaryFeedbackConfigurationRankAndNotes.constants';
import { getUpdatedQuestionResponses, getUpdatedQuestionResponsesWithRank } from './SummaryFeedbackConfigurationRankAndNotes.helpers';
import { IDialogState, ISummaryFeedbackConfigurationRankAndNotesProps } from './SummaryFeedbackConfigurationRankAndNotes.interfaces';

import {
  IHandleCreateSummaryFeedbackRankAndNoteMatchParams,
  useCreateSummaryFeedbackRankAndNoteCheckbox,
  useCreateSummaryFeedbackRankAndNoteMatch,
} from '../../../../hooks';
import {
  IDBQuestionAnswerCheckboxResponseCheckbox,
  IDBQuestionAnswerMatchResponseMatch,
  IDBSummaryFeedback,
  IDBSummaryFeedbackConfiguration,
  IQuestionResponse,
} from '../../../../interfaces';
import { QuestionResponseDraggableItem } from '../../../DragDrop';
import { handleApolloError } from '../../QuestionSnippet/QuestionSnippet.handlers';

const SummaryFeedbackConfigurationRankAndNotes = ({
  accessToken,
  answers = [],
  selectedAnswers: checkboxAnswers = [],
  availableResponses,
  questionAnswerId,
  summaryFeedbackConfiguration,
  type,
}: ISummaryFeedbackConfigurationRankAndNotesProps) => {
  const {
    configuration,
    contentSnippetSummaryId,
    itemsToRank = 0,
    noteMaxLength = 0,
  } = summaryFeedbackConfiguration ?? ({} as IDBSummaryFeedbackConfiguration);

  const shouldAllowDragAndDrop = configuration.includes('DRAG_AND_DROP_SORT');
  const shouldShowRankedItems = configuration.includes('RANKED_ITEMS');
  const shouldShowUnRankedItems = configuration.includes('UN_RANKED_ITEMS');
  const shouldAllowSingleRowSort = configuration.includes('SINGLE_ROW_SORT');
  const shouldAllowNote = configuration.includes('NOTE');

  const isMatchQuestion = type === 'Match';
  const isCheckbox = type === 'Checkbox';

  const [canRankQuestionResponses, setCanRankQuestionResponses] = useState<boolean>(true);
  const [currentSelectedQuestionResponses, setCurrentSelectedQuestionResponses] = useState<IQuestionResponse[]>([]);
  const [dialogState, setDialogState] = useState<IDialogState>(INITIAL_DIALOG_STATE);
  const [lastSetRank, setLastSetRank] = useState<number>(0);

  const { handleCreateSummaryFeedbackRankAndNoteCheckbox } = useCreateSummaryFeedbackRankAndNoteCheckbox({
    onError: handleApolloError,
    token: accessToken,
  });

  const { handleCreateSummaryFeedbackRankAndNoteMatch } = useCreateSummaryFeedbackRankAndNoteMatch({
    onError: handleApolloError,
    token: accessToken,
  });

  const debouncedMutation = useDebouncedCallback(
    ({ questionResponseId, note, rank }: IHandleCreateSummaryFeedbackRankAndNoteMatchParams) => {
      if (isMatchQuestion) {
        handleCreateSummaryFeedbackRankAndNoteMatch({
          contentSnippetSummaryId,
          note,
          questionAnswerId,
          questionResponseId,
          rank,
        });
      }
      if (isCheckbox) {
        handleCreateSummaryFeedbackRankAndNoteCheckbox({
          contentSnippetSummaryId,
          note,
          questionAnswerId,
          questionResponseId,
          rank,
        });
      }
    },
    API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS
  );

  const handleConfirmQuestionResponseDeletion = (inputQuestionResponse: IQuestionResponse) => {
    setDialogState({
      children: `${inputQuestionResponse.label}`,
      isVisible: true,
      selectedId: inputQuestionResponse.id,
      title: `Are you sure you want to un-rank the following life value?`,
    });
  };

  const handleDecrementQuestionResponseRank = (inputQuestionResponse: IQuestionResponse) => {
    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponsesWithRank({
        inputQuestionResponse,
        selectedQuestionResponses: currentSelectedQuestionResponses,
        updateRankBy: 1,
      })
    );

    const { id, note, rank } = inputQuestionResponse;

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: id,
      note,
      rank: rank ? rank + 1 : rank,
    });
  };

  const handleIncrementQuestionResponseRank = (inputQuestionResponse: IQuestionResponse) => {
    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponsesWithRank({
        inputQuestionResponse,
        selectedQuestionResponses: currentSelectedQuestionResponses,
        updateRankBy: -1,
      })
    );

    const { id, note, rank } = inputQuestionResponse;

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: id,
      note,
      rank: rank ? rank - 1 : rank,
    });
  };

  const handleDragEnd = (result: DropResult) => {
    if (!shouldAllowDragAndDrop) {
      return;
    }

    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const { id: questionResponseId } = currentSelectedQuestionResponses[source.index];
    const rank = destination.index + 1;

    const updatedQuestionResponses = update(currentSelectedQuestionResponses, {
      $splice: [
        [source.index, 1],
        [destination.index, 0, currentSelectedQuestionResponses[source.index]],
      ],
    });

    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponses({
        questionResponses: updatedQuestionResponses,
        shouldShowRankedItems,
        shouldShowUnRankedItems,
      })
    );

    const selectedQuestionResponse = currentSelectedQuestionResponses.find(({ id }) => id === questionResponseId) as IQuestionResponse;

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: selectedQuestionResponse.id,
      note: selectedQuestionResponse.note,
      rank,
    });
  };

  const handleQuestionResponseDeletionConfirmation = () => {
    const { selectedId: questionResponseId } = dialogState;

    if (!questionResponseId) {
      return;
    }

    const selectedQuestionResponse = currentSelectedQuestionResponses.find(({ id }) => id === questionResponseId) as IQuestionResponse;

    const updatedQuestionResponses = currentSelectedQuestionResponses.filter(({ id }) => id !== questionResponseId);

    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponses({
        questionResponses: updatedQuestionResponses,
        shouldShowRankedItems,
        shouldShowUnRankedItems,
      })
    );

    setDialogState(INITIAL_DIALOG_STATE);

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId,
      note: selectedQuestionResponse.note,
      rank: null,
    });
  };

  const handleQuestionResponseNoteChange = (inputQuestionResponse: IQuestionResponse, note: string) => {
    setCurrentSelectedQuestionResponses((questionResponses) =>
      questionResponses.map((questionResponse) => {
        return inputQuestionResponse.id === questionResponse.id
          ? {
              ...questionResponse,
              note,
            }
          : questionResponse;
      })
    );

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: inputQuestionResponse.id,
      note,
      rank: inputQuestionResponse.rank,
    });
  };

  const handleRankQuestionResponse = (inputQuestionResponse: IQuestionResponse) => {
    if (inputQuestionResponse.rank) {
      return;
    }

    const questionResponseWithMaxRank = maxBy(currentSelectedQuestionResponses, 'rank');

    const { rank: maxRank } = questionResponseWithMaxRank ?? {};
    const nextRank = maxRank ? maxRank + 1 : 1;

    const updatedQuestionResponses = currentSelectedQuestionResponses.map((questionResponse) => {
      return inputQuestionResponse.id === questionResponse.id
        ? {
            ...questionResponse,
            rank: nextRank,
          }
        : questionResponse;
    });

    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponses({
        questionResponses: updatedQuestionResponses,
        shouldShowRankedItems,
        shouldShowUnRankedItems,
      })
    );

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: inputQuestionResponse.id,
      note: inputQuestionResponse.note,
      rank: nextRank,
    });
  };

  const handleShowHideQuestionResponseNote = (inputQuestionResponse: IQuestionResponse) => {
    setCurrentSelectedQuestionResponses((questionResponses) =>
      questionResponses.map((questionResponse): IQuestionResponse => {
        return inputQuestionResponse.id === questionResponse.id
          ? {
              ...questionResponse,
              isNoteVisible: !questionResponse.isNoteVisible,
            }
          : questionResponse;
      })
    );
  };

  useEffect(() => {
    if (isCheckbox && checkboxAnswers.length > 0) {
      const questionResponseCheckboxSummaryFeedbackMap = checkboxAnswers.reduce(
        (accumulator, { questionResponseCheckboxId, summaryFeedback }) => {
          if (!summaryFeedback) {
            return accumulator;
          }

          return {
            ...accumulator,
            [questionResponseCheckboxId]: summaryFeedback,
          };
        },
        {} as Record<IDBQuestionAnswerCheckboxResponseCheckbox['questionResponseCheckboxId'], IDBSummaryFeedback | undefined>
      );

      const filteredQuestionResponses = availableResponses.filter(({ id }) =>
        checkboxAnswers.map((r) => r.questionResponseCheckboxId).includes(id)
      );

      const selectedQuestionResponses = filteredQuestionResponses.map((questionResponse): IQuestionResponse => {
        const { note, rank } = questionResponseCheckboxSummaryFeedbackMap[questionResponse.id] ?? {};

        return {
          ...questionResponse,
          isNoteVisible: true,
          note,
          rank: isNil(rank) ? rank : rank + 1,
        };
      });

      setCurrentSelectedQuestionResponses(
        getUpdatedQuestionResponses({
          questionResponses: selectedQuestionResponses.map((response, index) => ({ ...response, rank: response.rank ?? index + 1 })),
          shouldShowRankedItems,
          shouldShowUnRankedItems,
        })
      );
    } else if (isMatchQuestion && answers.length > 0) {
      const filteredAnswers = answers.filter(({ definedPosition }) => definedPosition === 0);

      const questionResponseMatchIdSummaryFeedbackMap = filteredAnswers.reduce(
        (accumulator, { questionResponseMatchId, summaryFeedback }) => {
          if (!summaryFeedback) {
            return accumulator;
          }

          return {
            ...accumulator,
            [questionResponseMatchId]: summaryFeedback,
          };
        },
        {} as Record<IDBQuestionAnswerMatchResponseMatch['questionResponseMatchId'], IDBSummaryFeedback | undefined>
      );

      const selectedQuestionResponseMatchIds = filteredAnswers.map(({ questionResponseMatchId }) => questionResponseMatchId);
      const filteredQuestionResponses = availableResponses.filter(({ id }) => selectedQuestionResponseMatchIds.includes(id));

      const selectedQuestionResponses = filteredQuestionResponses.map((questionResponse): IQuestionResponse => {
        const { note, rank } = questionResponseMatchIdSummaryFeedbackMap[questionResponse.id] ?? {};

        return {
          ...questionResponse,
          isNoteVisible: true,
          note,
          rank: isNil(rank) ? rank : rank + 1,
        };
      });

      setCurrentSelectedQuestionResponses(
        getUpdatedQuestionResponses({
          questionResponses: selectedQuestionResponses.map((response, index) => ({ ...response, rank: response.rank ?? index + 1 })),
          shouldShowRankedItems,
          shouldShowUnRankedItems,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentSelectedQuestionResponses.length) {
      return;
    }

    const totalRankedQuestionResponses = currentSelectedQuestionResponses.flatMap(({ rank }) => {
      if (isNil(rank)) {
        return [];
      }

      return [rank];
    });

    setCanRankQuestionResponses(totalRankedQuestionResponses.length < itemsToRank);
    setLastSetRank(max(totalRankedQuestionResponses) ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedQuestionResponses]);

  const { children: dialogChildren, isVisible: isDialogVisible, title: dialogTitle } = dialogState;

  return (
    <Fragment>
      {isDialogVisible && (
        <Dialog
          isOpen={isDialogVisible}
          title={dialogTitle}
          onCancel={() => setDialogState(INITIAL_DIALOG_STATE)}
          onClose={() => setDialogState(INITIAL_DIALOG_STATE)}
          onConfirm={handleQuestionResponseDeletionConfirmation}
        >
          {dialogChildren}
        </Dialog>
      )}

      {currentSelectedQuestionResponses.length ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {currentSelectedQuestionResponses.map((questionResponse, index) => {
                  const { id, label = '', note = '', rank, isNoteVisible } = questionResponse;

                  return (
                    <QuestionResponseDraggableItem
                      key={`question-response-drag-drop-item-${id}`}
                      canIncrementOrDecrementQuestionResponseRank={shouldAllowSingleRowSort}
                      canRankQuestionResponses={canRankQuestionResponses}
                      id={String(id)}
                      index={index}
                      isNoteVisible={isNoteVisible}
                      label={label}
                      lastSetRank={lastSetRank}
                      note={note}
                      noteMaxLength={noteMaxLength}
                      questionResponse={questionResponse}
                      rank={rank}
                      shouldAllowNote={shouldAllowNote}
                      onDecrementRank={handleDecrementQuestionResponseRank}
                      onDeleteConfirm={handleConfirmQuestionResponseDeletion}
                      onIncrementRank={handleIncrementQuestionResponseRank}
                      onNoteChange={shouldAllowNote ? handleQuestionResponseNoteChange : undefined}
                      onRank={handleRankQuestionResponse}
                      onShowHideNote={shouldAllowNote ? handleShowHideQuestionResponseNote : undefined}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="c-summary-snippet__message">
          <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>This activity requires responses from a previous page. Please navigate back and complete and missing responses.</p>
        </div>
      )}
    </Fragment>
  );
};

export { SummaryFeedbackConfigurationRankAndNotes };
