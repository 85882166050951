import React, { useEffect, useState } from 'react';

// import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
// import { useDebouncedCallback } from 'use-debounce';

import { getApolloErrorMessages } from '@netfront/common-library';

import { DEFAULT_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { removeInternalServerError, saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerDecimalSnippetProps } from './QuestionAnswerDecimalSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerMutationGraphQLResponse,
  ISetUserAnswerNumberMutationVariables,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import { IContentPage, IQuestionAnswerNumber, IQuestionConfigurationNumber } from '../../../../interfaces';
import { DecimalInputSnippet } from '../../DecimalInputSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerDecimalSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  message = DEFAULT_MESSAGE,
  userFlowStepTrackId,
}: QuestionAnswerDecimalSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, mandatory: isMandatory = false, message: contentSnippetMessage } = contentSnippet ?? {};
  const { numberAnswered } = answer ?? ({} as IQuestionAnswerNumber);
  const { defaultValue, description, maxValue, minValue } = configuration ?? ({} as IQuestionConfigurationNumber);

  const [currentMessage, setCurrentMessage] = useState<IMessage>(message);
  const [savedNumber, setSaveNumber] = useState<number>(numberAnswered);

  const [executeSetUserAnswerDecimal] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerNumberMutationVariables>({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerDecimal,
    options: {
      onCompleted: () => {
        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            numberAnswered: savedNumber,
          },
        });

        dispatch({
          type: 'updateQuestionAnswerNumberTotals',
        });

        setCurrentMessage({
          error: '',
          success: DEFAULT_API_SUCCESS_MESSAGE,
        });
      },
      onError: (error) => {
        handleApolloError(error);
        setCurrentMessage({
          error: removeInternalServerError(getApolloErrorMessages(error).join(',')),
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const clearMessage = () => {
    setCurrentMessage(DEFAULT_MESSAGE);
  };

  // const debouncedMutation = useDebouncedCallback((inputNumber: number) => {
  //   if (!contentSnippetFormId) {
  //     return;
  //   }

  // const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

  // const variables: ISetUserAnswerNumberMutationVariables = {
  //   ...baseVariables,
  //   numberAnswered: inputNumber,
  // };

  // executeSetUserAnswerNumber({
  //   variables,
  // });
  // }, API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS);

  const handleNumberChange = (inputNumber: number) => {
    clearMessage();

    // debouncedMutation(inputNumber);

    if (!contentSnippetFormId) {
      return;
    }
    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    const variables: ISetUserAnswerNumberMutationVariables = {
      ...baseVariables,
      numberAnswered: inputNumber,
    };

    executeSetUserAnswerDecimal({
      variables,
    });

    setSaveNumber(inputNumber);
  };

  useEffect(() => {
    if (!contentSnippetMessage) {
      return;
    }

    setCurrentMessage(contentSnippetMessage);
  }, [contentSnippetMessage]);

  const isSummary = Boolean(contentSnippetQuestion);

  return (
    <DecimalInputSnippet
      defaultValue={defaultValue}
      id={`input-decimal-${contentSnippetId}`}
      isDisabled={isSummary}
      isRequired={isMandatory}
      maxValue={maxValue}
      message={currentMessage}
      minValue={minValue}
      placeholder={description}
      value={savedNumber}
      onChange={(value) => handleNumberChange(value)}
    />
  );
};

export { QuestionAnswerDecimalSnippet };
