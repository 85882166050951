import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';

import Preloader from '../Preloader/Preloader';

import LeftNavBar from './LeftNavBar';
import NavTree from './NavTree/NavTree';
import { dataToTree } from './NavTree/TreeUtils';

import { useContentPageState } from '../../context/contentPage/ContentPageContext';
import client from '../../middleware/client';
import { GET_LEFT_NAV_BAR_MENU } from '../../middleware/ContentGroup/getLeftNavBarMenu';

function ContentPageNavBar(props) {
  const [, dispatch] = useContentPageState();

  const {
    contentGroupId,
    contentGroupName,
    defaultPageId,
    getActivePageId,
    handleGetDisplayStatus,
    isSettingsOnly,
    contentGroupType,
    handleRefreshPage,
  } = props;

  const [navTree, setNavTree] = useState(undefined);

  const [executeGetLeftNavBarMenu, { loading: isGetLeftNavBarMenuLoading }] = useLazyQuery(GET_LEFT_NAV_BAR_MENU, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const {
        contentGroup: {
          getContentGroup: { contentPages },
        },
      } = response;
      const tree = dataToTree(contentPages, defaultPageId);
      setNavTree(tree);
      dispatch({
        type: 'setNavTreeContentPageIds',
        newState: contentPages.reduce((acc, parent) => {
          // Add the main contentPage id
          acc.push(parent.id);

          // Add the ids of the contentPageChildren if it's not null
          if (parent.contentPageChildren) {
            parent.contentPageChildren.forEach((child) => {
              acc.push(child.id);
            });
          }

          return acc;
        }, []),
      });
    },
  });

  function handleGetActivePageId(id) {
    getActivePageId(id);
    handleGetDisplayStatus(false);
  }

  function handleRefresh() {
    setNavTree(undefined);
  }

  function handleShowStatus(show) {
    handleGetDisplayStatus(show);
  }

  useEffect(() => {
    if (navTree) {
      return;
    }

    executeGetLeftNavBarMenu({
      variables: {
        contentGroupId,
      },
    });
  }, [navTree]);

  if (isGetLeftNavBarMenuLoading || !navTree) {
    return <Preloader />;
  }

  return (
    <LeftNavBar isShowed={handleShowStatus} title={contentGroupName} isOpenOnLoad>
      <NavTree
        activeId={defaultPageId}
        contentGroupType={contentGroupType}
        defaultTree={navTree}
        getActivePageId={handleGetActivePageId}
        handleRefresh={handleRefresh}
        handleRefreshPage={handleRefreshPage}
        isSettingsOnly={isSettingsOnly}
        isShowed={handleShowStatus}
      />
    </LeftNavBar>
  );
}

export default ContentPageNavBar;
