import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const SEARCH_PAGE = gql`
  query getPages(
    $after: String
    $first: Int
    $tags: [Int]
    $type: Int
    $sorting: ESorting
    $contentGroupId: Int
    $contentGroupType: EContentGroupType
    $message: String
    $containsExactMessage: Boolean
    $projectId: String!
  ) {
    contentPage {
      search(
        projectId: $projectId
        after: $after
        first: $first
        tags: $tags
        type: $type
        containsExactMessage: $containsExactMessage
        contentGroupId: $contentGroupId
        contentGroupType: $contentGroupType
        message: $message
        sorting: $sorting
      ) {
        totalCount
        pageInfo {
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            asset {
              assetId
              presignedUrl
              s3Key
              contentType
            }
            seoDescription
            tags {
              id
              name
            }
            contentGroup {
              id
              title
              type
              groupingContentGroup {
                id
                name
              }
            }
            created
            featured
            url
          }
        }
      }
    }
  }
`;
const searhContentPages = ({
  projectId,
  message,
  after,
  first,
  tags,
  type,
  containsExactMessage,
  contentGroupId,
  contentGroupType,
  sorting,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={SEARCH_PAGE}
    variables={{
      projectId,
      message,
      after,
      first,
      tags,
      type,
      containsExactMessage,
      contentGroupId,
      contentGroupType,
      sorting,
    }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data && Object.entries(data).length !== 0 && data.contentPage.search
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

searhContentPages.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  after: PropTypes.string,
  containsExactMessage: PropTypes.bool,
  contentGroupId: PropTypes.number,
  contentGroupType: PropTypes.string,
  first: PropTypes.number,
  message: PropTypes.string,
  sorting: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.number),
  type: PropTypes.string,
};

searhContentPages.defaultProps = {
  after: null,
  containsExactMessage: null,
  contentGroupId: null,
  contentGroupType: null,
  first: null,
  message: null,
  sorting: null,
  tags: null,
  type: null,
};

export default searhContentPages;
