import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Preloader from '../../../../legacy/components/Preloader/Preloader';
import { useToast } from '../../../../legacy/hooks';
import { useGetSnippetSummaryFeedbackConfig, useDeleteSnippetSummaryFeedbackConfigItem } from '../../../hooks';
import { FeedbackOverview } from '../../sidebars/UpsertSummarySnippetSidebar/Components/FeedbackOverview';
import { UpsertFeedback } from '../../sidebars/UpsertSummarySnippetSidebar/Components/UpsertFeedback';

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const UpsertSummarySnippetFeedbackTab = ({ questionId, snippet, onClose, onDelete, onSave, hasDelete = false }) => {
  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const [isUpsertMode, setIsUpsertMode] = useState(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState();
  const [items, setItems] = useState([]);
  const [feedbackConfig, setFeedbackConfig] = useState();

  const { handleGetSnippetSummaryFeedbackConfig, isLoading: isGetSnippetSummaryFeedbackLoading = false } =
    useGetSnippetSummaryFeedbackConfig({
      onCompleted: ({ config }) => {
        // temporarily set this to an array until we can add multiple feedback questions to a summary
        setItems(config ? [config] : []);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleDeleteSnippetSummaryFeedbackConfigItem, isLoading: isDeleteSnippetSummaryFeedbackItemLoading = false } =
    useDeleteSnippetSummaryFeedbackConfigItem({
      onCompleted: ({ isCompleted }) => {
        if (isCompleted) {
          handleGetSnippetSummaryFeedbackConfig({
            variables: {
              contentSnippetSummaryId: snippet.id,
            },
          });
          setIsUpsertMode(false);
          handleToastSuccess({
            message: `Summary feedback successfully deleted`,
          });
        } else {
          handleToastCustomError({
            message: `Whoops something went wrong`,
          });
        }
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const handleUpsertFeedback = (id) => {
    setSelectedFeedbackId(id);
    setIsUpsertMode(true);
  };

  const handleCloseFeedback = () => {
    setSelectedFeedbackId(undefined);
    setIsUpsertMode(false);
  };

  const deleteFeedbackItem = (id) => {
    handleDeleteSnippetSummaryFeedbackConfigItem({
      variables: {
        contentSnippetSummaryId: id,
      },
    });
  };

  const onSaveFeedback = () => {
    handleGetSnippetSummaryFeedbackConfig({
      variables: {
        contentSnippetSummaryId: snippet.id,
      },
    });
    handleCloseFeedback();
  };

  useEffect(() => {
    setFeedbackConfig(items.find((item) => item.contentSnippetSummaryId === selectedFeedbackId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeedbackId]);

  useEffect(() => {
    handleGetSnippetSummaryFeedbackConfig({
      variables: {
        contentSnippetSummaryId: snippet.id,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  const isLoading = isDeleteSnippetSummaryFeedbackItemLoading || isGetSnippetSummaryFeedbackLoading;

  return (
    <Container>
      {isLoading && <Preloader />}
      {isUpsertMode ? (
        <UpsertFeedback
          feedbackConfig={feedbackConfig}
          snippetId={snippet.id}
          onCancel={handleCloseFeedback}
          onDelete={deleteFeedbackItem}
          onSave={onSaveFeedback}
        />
      ) : (
        <FeedbackOverview
          feedbackItems={items}
          hasDelete={hasDelete}
          questionId={questionId}
          onClose={onClose}
          onDelete={onDelete}
          onDeleteClick={deleteFeedbackItem}
          onEditClick={handleUpsertFeedback}
          onSave={onSave}
        />
      )}
    </Container>
  );
};

UpsertSummarySnippetFeedbackTab.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  snippet: PropTypes.shape().isRequired,
  hasDelete: PropTypes.bool,
  questionId: PropTypes.number,
};

export { UpsertSummarySnippetFeedbackTab };
