import { TextSnippetMarkupHandler } from './TextSnippet.types';

export const appendExternalLinkIcon: TextSnippetMarkupHandler = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const anchorTags = doc.querySelectorAll('a[target="_blank"]');
  anchorTags.forEach((anchorTag) => {
    anchorTag.classList.add('c-text-snippet__link--target');
    const externalLinkIcon = document.createElement('span');
    externalLinkIcon.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="none" viewBox="0 0 24 24" stroke-width="1.8" style="margin-left: .25rem" stroke="currentColor">' +
      '<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />' +
      '</svg>';
    anchorTag.appendChild(externalLinkIcon);
  });

  return doc.body.innerHTML;
};
