import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LinkBtn, LinkIcon } from '../MyApplicationPage/styled';

import StarRating from '../../components/StarRating/StarRating';

export const NewAppBtn = styled(LinkBtn)`
  background-color: #4bc1c9;
`;

export const NewIcon = styled(LinkIcon)`
  background-color: #fff;
  svg,
  span {
    color: #4bc1c9;
  }
`;

export const ApprovalImg = styled.img`
  margin-right: 0.5em;
`;

export const ApprovalBtn = styled(LinkBtn)`
  background-color: #106ff8;
  color: #fff;
  margin-right: 1em;
`;

export const BtnGroup = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: -9em;
`;

export const FormButton = styled.button`
  background-color: ${({ bg }) => bg};
  border: none;
  border-radius: 5px;
  color: ${({ color }) => color};
  display: flex;
  font-weight: 600;
  padding: 0.6em 1.2em;
`;
export const ActionHeading = styled.h4`
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 0.6em 0;
`;

export const FlexContainer = styled.div`
  display: flex;
  margin: -1em 0 0 0;
`;
export const FieldGroup = styled.div`
  padding: 4px 0;

  label {
    display: block;
    font-weight: 600;
    margin: 0;
    width: 100%;
  }

  input,
  textarea {
    background-color: transparent;
    border: 1px solid #e4e4e3;
    border-radius: 4px;
    width: 100%;
  }
`;
export const IconLinkButton = styled.button`
  background-color: ${({ color }) => color};
  border: none;
  border-radius: 8px;
  color: #231f20;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.2em;
  font-weight: 600;
  padding: 0.6em 1em;
`;
export const ImageDropContainer = styled.div`
  border: 1px dashed #0087f7;
  border-radius: 6px;
  cursor: pointer;
  padding: 1em;

  div {
    text-align: center;
  }

  img {
    max-width: 100%;
  }
`;

export const PanelActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 2em 0;

  button + button {
    margin-left: 0.6em;
  }
`;

export const PushRight = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Card = styled.a`
  border: 1px solid rgba(0, 42, 76, 0.1);
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0 2em 1em 0;
  max-width: 221px;
  width: 100%;
  overflow: hidden;
  transition: 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.04);

    svg {
      .a {
        fill: #4bc2ca;
      }
    }
  }
`;

export const CardImage = styled.div`
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 170px;
`;

export const CardDetails = styled.div`
  border-top: 1px solid rgba(0, 42, 76, 0.1);
  flex-direction: column;
  display: flex;
  padding: 1em;
  position: relative;

  h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.1em;
  }

  svg {
    position: absolute;
    bottom: 1em;
    right: 1em;
    transition: 0.25s ease-in-out;

    .a {
      fill: #7a7a7a;
    }
    .b,
    .c {
      fill: none;
      stroke: #fff;
      stroke-miterlimit: 10;
    }
    .b {
      stroke-width: '0.523px;';
    }
    .c {
      stroke-width: 0.554px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em;
  max-height: 60vh;
  overflow: scroll;
`;

export const SearchWarning = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const AppCard = props => {
  const { /* id, */ img, name, href, owner, rating, version } = props;
  return (
    <Card href={href}>
      <CardImage img={img} />
      <CardDetails>
        <h4>{name}</h4>
        <span>by {owner}</span>
        <svg
          height="26"
          viewBox="0 0 26 26"
          width="26"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs />
          <g transform="translate(0 0)">
            <circle className="a" cx="13" cy="13" r="13" />
            <g transform="translate(7.753 8.414)">
              <path
                className="b"
                d="M1050.275,498.6l5.441-4.586-5.441-4.586"
                transform="translate(-1045.626 -489.434)"
              />
              <line className="c" transform="translate(0 4.586)" x1="10" />
            </g>
          </g>
        </svg>

        <StarRating rating={rating} />
        {version !== '' && <span>Version: {version}</span>}
      </CardDetails>
    </Card>
  );
};

AppCard.propTypes = {
  // id: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
  // link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  version: PropTypes.string,
};

AppCard.defaultProps = {
  version: '',
};
