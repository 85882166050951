import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

import {
  DBContentSnippetTypeNameType,
  IDBContentPage,
  IDBGroupingContentGroup,
  IDBUserFlowStep,
  IToolBoxContentPage,
  IToolBoxData,
  IToolBoxGroupingContentGroup,
  IToolBoxItems,
  IToolBoxUserFlow,
  IToolBoxUserFlowStep,
} from '../../interfaces';

const getToolBoxItems = (contentPage: IDBContentPage, contentSnippetTypeName?: DBContentSnippetTypeNameType): IToolBoxItems => {
  const { id: contentPageId, sort: contentPageSort, title: contentPageTitle, url: contentPageUrl, userFlowStep } = contentPage;

  const {
    contentGroup,
    description: userFlowStepDescription,
    id: userFlowStepId,
    stepName: userFlowStepName,
    sort: userFlowStepSort,
    type: userFlowStepType,
    url: userFlowStepUrl,
    userFlow: { id: userFlowId, sort: userFlowSort, url: userFlowUrl },
  } = userFlowStep ?? ({} as IDBUserFlowStep);

  const { groupingContentGroup } = contentGroup ?? {};

  const {
    id: groupingContentGroupId,
    name: groupingContentGroupName,
    sort: groupingContentGroupSort,
    url: groupingContentGroupUrl,
  } = groupingContentGroup ?? ({} as IDBGroupingContentGroup);

  const toolboxContentPage: IToolBoxContentPage = {
    contentSnippetTypeName,
    groupingContentGroupId,
    id: contentPageId,
    sort: contentPageSort,
    title: contentPageTitle,
    url: contentPageUrl,
    userFlowId,
    userFlowStepId,
  };

  const toolboxGroupingContentGroup: IToolBoxGroupingContentGroup = {
    contentPageId,
    contentSnippetTypeName,
    id: groupingContentGroupId,
    name: groupingContentGroupName,
    sort: groupingContentGroupSort,
    url: groupingContentGroupUrl,
    userFlowId,
    userFlowStepId,
  };

  const toolboxUserFlow: IToolBoxUserFlow = {
    contentPageId,
    contentSnippetTypeName,
    groupingContentGroupId,
    id: userFlowId,
    sort: userFlowSort,
    url: userFlowUrl,
    userFlowStepId,
  };

  const toolboxUserFlowStep: IToolBoxUserFlowStep = {
    contentPageId,
    contentSnippetTypeName,
    id: userFlowStepId,
    description: userFlowStepDescription,
    groupingContentGroupId,
    stepName: userFlowStepName,
    sort: userFlowStepSort,
    type: userFlowStepType,
    url: userFlowStepUrl,
    userFlowId,
  };

  return {
    toolboxContentPage,
    toolboxGroupingContentGroup: groupingContentGroup ? toolboxGroupingContentGroup : undefined,
    toolboxUserFlow,
    toolboxUserFlowStep,
  };
};

const getToolBoxData = (toolboxData: IToolBoxData, toolboxItems: IToolBoxItems): IToolBoxData => {
  const { contentPages, groupingContentGroups, userFlows, userFlowSteps } = toolboxData;
  const { toolboxContentPage, toolboxGroupingContentGroup, toolboxUserFlow, toolboxUserFlowStep } = toolboxItems;

  const combinedContentPages = sortBy(
    uniqBy([...contentPages, toolboxContentPage], ({ id }) => id),
    'sort'
  );

  const combinedGroupingContentGroups =
    groupingContentGroups && toolboxGroupingContentGroup
      ? sortBy(
          uniqBy([...groupingContentGroups, toolboxGroupingContentGroup], ({ id }) => id),
          'sort'
        )
      : [];

  const combinedUserFlows = sortBy(
    uniqBy([...userFlows, toolboxUserFlow], ({ id }) => id),
    'sort'
  );

  const combinedUserFlowSteps = sortBy(
    uniqBy([...userFlowSteps, toolboxUserFlowStep], ({ id }) => id),
    'sort'
  );

  return {
    contentPages: combinedContentPages,
    groupingContentGroups: combinedGroupingContentGroups,
    userFlows: combinedUserFlows,
    userFlowSteps: combinedUserFlowSteps,
  };
};

export { getToolBoxData, getToolBoxItems };
