import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useCookie } from '@netfront/common-library';
import { useDomain } from '@netfront/gelada-identity-library';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import Preloader from '../../components/Preloader/Preloader';
import MenuCard from '../../components/UI/Card/MenuCard';
import { NetfrontServices } from '../../constants/NetfrontServices';
import { ProjectFeatures } from '../../constants/ProjectFeatures';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls, useSchedulerSiteUrls } from '../../hooks';
import howlerClient from '../../middleware/_Howler/howlerClient';
import { GET_PROJECT_FEATURES } from '../../middleware/_Howler/Queries/GetProjectFeatures';
import AnnouncementIcon from '../../static/img/announcement-icon.svg';
import IconScheduler from '../../static/img/icon-notification-scheduler.svg';
import IconTemplate from '../../static/img/icon-notification-template.svg';
import IconPush from '../../static/img/icon-push-notification.svg';

const CardsContainer = styled.ul`
  display: flex;
  flex-flow: wrap;
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  margin-left: 0;
`;

const dictionaryValues = {
  TRUE: 'True',
  FALSE: 'False',
};

function NotificationsPage() {
  const { getAccessTokenCookie } = useCookie();
  const { isDomainReady } = useDomain();
  const { pathname } = useLocation();
  const params = useParams();

  const { project } = useContext(AppContext);

  const [schedulerSiteUrl, setSchedulerSiteUrl] = useState();
  const [newEkardoSiteUrl, setNewEkardoSiteUrl] = useState();

  const { projectId, orgId: organisationId } = params;
  const { services = [] } = project;

  const { getBaseUrl: getSchedulerSiteBaseUrl } = useSchedulerSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_SCHEDULER_SITE_LOCAL_PORT,
  });

  const { getBaseUrl: getEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_SCHEDULER_SITE_LOCAL_PORT,
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setSchedulerSiteUrl(getSchedulerSiteBaseUrl());
    setNewEkardoSiteUrl(getEkardoSiteBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  let hasPushNotification = false;
  const subscriptionsCards = [
    {
      href: `${schedulerSiteUrl}/project/${projectId}/scheduler`,
      image: IconScheduler,
      key: 'scheduler',
      visible: services.includes(NetfrontServices.CAPUCHIN),
      title: 'Scheduler',
    },
    {
      image: IconTemplate,
      key: 'templates',
      path: 'notification-templates',
      visible: services.includes(NetfrontServices.HOWLER),
      title: 'Templates',
    },
    {
      image: AnnouncementIcon,
      key: 'announcements',
      href: `${newEkardoSiteUrl}/dashboard/${organisationId}/${projectId}/notifications/announcements`,
      visible: services.includes(NetfrontServices.HOWLER),
      title: 'Announcements',
    },
    {
      image: IconPush,
      key: 'push',
      path: 'push-notification',
      visible: hasPushNotification,
      title: 'Push notification',
    },
  ];

  const { data, loading } = useQuery(GET_PROJECT_FEATURES, {
    client: howlerClient,
    context: {
      headers: {
        authorization: getAccessTokenCookie(),
      },
    },
    fetchPolicy: 'network-only',
    skip: projectId === undefined,
    variables: {
      projectId,
    },
  });

  if (loading) {
    return <Preloader />;
  }

  if (data) {
    const {
      project: {
        getProject: { features },
      },
    } = data;

    hasPushNotification = features?.find(({ key }) => key === ProjectFeatures.PUSH_NOTIFICATION)?.value === dictionaryValues.TRUE;
  }

  const breadCrumbPath = [
    {
      path: `${newEkardoSiteUrl}/dashboard/${organisationId}/${projectId}/notifications`,
      title: 'Social',
      isExternal: true,
    },
  ];

  return (
    <PageWrapper breadPath={breadCrumbPath} info="Manage notification" pageDescription="" pageTitle="Notification" params={params}>
      <ProjectNavBar title="Notifications" />
      <CardsContainer>
        {subscriptionsCards
          .filter((r) => r.visible)
          .map(({ href, image, key, path, title }) => {
            const lastRoute = path ? `/${path}` : '';
            const url = `${href || pathname}${lastRoute}`;

            return (
              <ListItem key={key}>
                <MenuCard key={`notifications-card-${key}`} href={url} name={title} src={image} />
              </ListItem>
            );
          })}
      </CardsContainer>
    </PageWrapper>
  );
}

export default NotificationsPage;
