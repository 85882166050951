import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const ENABLE_PROJECT = gql`
  mutation($projectGuid: String!) {
    project {
      enableProject(projectGuid: $projectGuid) {
        id
      }
    }
  }
`;

const enableProjectRequest = ({ projectId, onError, onSuccessResult }) => (
  <Mutation mutation={ENABLE_PROJECT}>
    {(enableProjectFunc, { loading, error, data }) => {
      const variables = {
        projectGuid: projectId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.project.enableProject);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={enableProjectFunc}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

enableProjectRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default enableProjectRequest;
