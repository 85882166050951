import React, { useState } from 'react';

import { InputFieldWrapper } from '@netfront/ui-library';

import { getAriaDescribedBy, getHtmlElementOnChangeValueFromEvent, getInputFieldClassNames } from '../Snippets.helpers';

import { MultiLineTextSnippetProps } from './MultiLineTextSnippet.interfaces';

const MultiLineTextSnippet = ({
  describedById,
  iconId,
  id,
  isDirty,
  isDisabled,
  isLabelHidden,
  isRequired,
  label,
  maxLength,
  message,
  minLength,
  onChange,
  placeholder,
  value,
}: MultiLineTextSnippetProps) => {
  const { error } = message ?? {};

  const [currentValue, setCurrentValue] = useState<string>(value);

  const ariaDescribedBy = getAriaDescribedBy(describedById, id, Boolean(error));
  const classNames = getInputFieldClassNames('textarea', isDirty, Boolean(error), isRequired);

  return (
    <InputFieldWrapper iconId={iconId} id={id} isLabelHidden={isLabelHidden} isRequired={isRequired} label={label} message={message}>
      <textarea
        aria-describedby={ariaDescribedBy}
        aria-invalid={Boolean(error)}
        className={classNames}
        disabled={isDisabled}
        id={id}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        required={isRequired}
        value={currentValue}
        onChange={(event) => {
          const updatedValue = getHtmlElementOnChangeValueFromEvent(event);

          setCurrentValue(updatedValue);

          if (!onChange) {
            return;
          }

          onChange(updatedValue);
        }}
      />
    </InputFieldWrapper>
  );
};

export { MultiLineTextSnippet };
