import { filterDuplicatesByKey } from '../../utils';

export const getDataTypeFormat = (questionOptions, configOptions, type) => {
  const formatMap = {
    condition: {
      config: filterDuplicatesByKey(configOptions, 'id'),
      options: filterDuplicatesByKey(questionOptions, 'initialId'),
    },
    general: {
      config: configOptions,
      options: questionOptions,
    },
  };

  return formatMap[type];
};
