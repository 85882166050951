/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { createRef, Component } from 'react';

import Axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Dropzone from 'react-dropzone';

import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import MyAppCard from '../../components/MyAppCard/MyAppCard';
import PopupMessageSideBar from '../../components/PopupMessageSideBar/PopupMessageSideBar';
import Preloader from '../../components/Preloader/Preloader';
import SideBarRight from '../../components/SideBarRight/SideBarRight';
import { errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import CreateAssetRequest from '../../middleware/Asset/createAssetRequest';
import DeleteAssetRequest from '../../middleware/Asset/deleteAssetRequest';
import CreateMyAppRequest from '../../middleware/MyAppDashboard/createMyAppRequest';
import GetMyAppListRequest from '../../middleware/MyAppDashboard/getMyAppListRequest';
import GetOrganisation from '../../middleware/Organisation/getOrganisation';
import GetProject from '../../middleware/Project/getProject';

class MyApplicationPage extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      organisationId: null,
      projectLoaded: true,
      orgLoaded: true,
      appLoaded: false,
      appsList: [],
      filteredList: [],
      isRightOpen: false,
      activeTab: 'all',
      createMyAppRequest: false,
      appTitle: '',
      file: '',
      createAsset: false,
      assetId: '',
      appDescription: '',
      loader: false,
      appCode: '',
      errorMessage: '',
      deleteAssetRequest: false,
      appListView: 'grid-view',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { org: orgId } = params;
    const { orgName, projectName } = this.context;

    this.setState({
      organisationId: Number(orgId),
      orgLoaded: orgName !== '',
      projectLoaded: projectName !== '',
    });
  }

  handleOnClickGridView = () => {
    this.setState({
      appListView: 'grid-view',
    });
  };

  handleOnClickListView = () => {
    this.setState({
      appListView: 'list-view',
    });
  };

  validate = () => {
    const { appTitle, appCode, appDescription, file } = this.state;

    return {
      AppTitleEmpty: {
        validation: appTitle.trim().length > 0,
        errorMessage: 'App Title must not be empty',
      },
      AppCodeEmpty: {
        validation: appCode.trim().length > 0,
        errorMessage: 'App code must not be empty',
      },
      AppDescriptionEmpty: {
        validation: appDescription.trim().length > 0,
        errorMessage: 'App descriotion must not be empty',
      },
      FileEmpty: {
        validation: file,
        errorMessage: 'App logo must not be empty',
      },
    };
  };

  handleOrgError = () => {
    this.setState({
      orgLoaded: true,
    });
  };

  handleToggleRightSideBar = () => {
    const { isRightOpen } = this.state;
    this.setState({
      isRightOpen: !isRightOpen,
    });
  };

  handleProjectError = () => {
    this.setState({
      projectLoaded: true,
    });
  };

  handleGetOrganisation = (name) => {
    const { updateOrgName } = this.context;

    updateOrgName(name);

    this.setState({
      orgLoaded: true,
    });
  };

  handleGetProject = (name) => {
    const { updateProjectName } = this.context;

    updateProjectName(name);

    this.setState({
      projectLoaded: true,
    });
  };

  handleOnClickButton = (e) => {
    const appCard = e.target.value;
    const { appsList } = this.state;
    let filterdAppList = appsList;
    if (appCard !== 'all') {
      filterdAppList = appsList.filter((app) => app.publishStatus === appCard);
    }
    this.setState({
      filteredList: filterdAppList.length === 0 ? appsList : filterdAppList,
      activeTab: appCard,
    });
  };

  handleOnChangeSearch = (e) => {
    const appCard = e.target.value;
    const { appsList } = this.state;
    let filterdAppList = appsList;
    if (appCard !== '') {
      filterdAppList = appsList.filter((app) => !app.title.toLowerCase().indexOf(appCard.toLowerCase()));
    }
    this.setState({
      filteredList: filterdAppList,
    });
  };

  handleInputs = (e, count) => {
    const { name, value } = e.target;
    if (value.length <= count) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleClearErrorMessages = () => {
    const { errorMessage } = this.state;
    if (errorMessage !== '') {
      this.setState({
        errorMessage: '',
      });
    }
  };

  handleOnSubmit = () => {
    const validationErrors = ValidationProcessor(this.validate());

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });
      setTimeout(() => {
        this.setState({
          errorMessage: '',
        });
      }, 2000);
    } else {
      this.setState({
        // createMyAppRequest: true,
        createAsset: true,
      });
    }
  };

  handleOnSuccessResultGetList = (res) => {
    const tempList = res.apps.map((app) => ({
      title: app.title,
      publishStatus: app.isPublished ? 'PUBLISHED' : 'UNPUBLISHED',
      byFrom: 'By Netfront',
      id: app.id,
      logoId: app.logoId,
      averageRateValue: app.averageRateValue,
      identifier: app.identifier,
    }));
    this.setState({
      appLoaded: true,
      appsList: tempList,
      filteredList: tempList,
    });
  };

  handleOnSuccessResultCreateApp = () => {
    this.setState({
      appLoaded: false,
      createMyAppRequest: false,
      isRightOpen: false,
      appTitle: '',
      appCode: '',
      appDescription: '',
      file: '',
    });
  };

  handleOnError = (errorMessage) => {
    this.setState({
      createMyAppRequest: false,
      deleteAssetRequest: true,
      errorMessage,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleGetListOnError = () => {
    this.setState({
      appLoaded: true,
    });
  };

  handleFileDrop = (files) => {
    this.setState({
      file: files[0],
    });
  };

  handleAssetError = () => {
    this.setState({
      createAsset: false,
    });
  };

  handleAssetResponse = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, asset },
      },
    } = data;
    const { file } = this.state;

    this.setState({
      createAsset: false,
      loader: true,
    });

    Object.defineProperty(file, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, file, { headers: { 'content-type': file.type } })
      .then(() => {
        this.setState({
          createMyAppRequest: true,
          assetId: asset.assetId,
          loader: false,
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  };

  handleDeleteSnippetError = () => {
    this.setState({
      deleteAssetRequest: false,
    });
  };

  handleDeleteSnippetResponse = () => {
    this.setState({
      deleteAssetRequest: false,
    });
  };

  handleOnGettingRatingResponse = () => {
    this.setState({
      appLoaded: false,
    });
  };

  render() {
    const {
      match: { url, params },
    } = this.props;
    const { projectId } = params;
    const {
      organisationId,
      filteredList,
      isRightOpen,
      activeTab,
      projectLoaded,
      orgLoaded,
      createMyAppRequest,
      appTitle,
      appLoaded,
      file,
      createAsset,
      assetId,
      appDescription,
      loader,
      appCode,
      errorMessage,
      deleteAssetRequest,
      appListView,
    } = this.state;
    let filePreview = '';
    if (file) {
      filePreview = URL.createObjectURL(file);
    }

    const dropzoneRef = createRef();

    const { org, project } = this.context;

    const openDialog = () => {
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    };

    const breadPath = [
      {
        id: 1,
        path: `/dashboard/${org.key}/${project.id}/library`,
        title: 'Library',
      },
      {
        id: 2,
        path: ``,
        title: 'My apps',
      },
    ];

    return (
      <>
        <PageWrapper breadPath={breadPath} pageDescription="Manage all your apps" pageTitle="App management page" params={params}>
          <ProjectNavBar title="Apps" />
          <div className="app-toolbar">
            <div className="app-tabbar">
              <button
                className={classnames('btn btn-tab', {
                  'is-active': activeTab === 'all',
                })}
                type="button"
                value="all"
                onClick={this.handleOnClickButton}
              >
                All
              </button>
              <button
                className={classnames('btn btn-tab', {
                  'is-active': activeTab === 'PUBLISHED',
                })}
                type="button"
                value="PUBLISHED"
                onClick={this.handleOnClickButton}
              >
                Published
              </button>
              <button
                className={classnames('btn btn-tab', {
                  'is-active': activeTab === 'UNPUBLISHED',
                })}
                type="button"
                value="UNPUBLISHED"
                onClick={this.handleOnClickButton}
              >
                Unpublished
              </button>
            </div>
            <div className="app-searchbar">
              <input className="form-control" placeholder="Search" type="text" onChange={this.handleOnChangeSearch} />
            </div>
          </div>

          <div className="app-styleview">
            <button
              className={classnames('btn btn-listview', {
                'is-active': appListView === 'list-view',
              })}
              type="button"
              onClick={this.handleOnClickListView}
            />
            <button
              className={classnames('btn btn-gridview ', {
                'is-active': appListView === 'grid-view',
              })}
              type="button"
              onClick={this.handleOnClickGridView}
            />
          </div>

          <div className="app-container">
            <div className="app-leftbar">
              <div className="app-item app-item-create">
                <div className="app-head">
                  <div className="title">My apps</div>
                  <div className="content">Explore, edit and update your apps.</div>
                </div>
                <div className="app-content">
                  <button className="btn btn-create-app" type="button" onClick={this.handleToggleRightSideBar}>
                    Create an app
                  </button>
                </div>
              </div>
            </div>

            <div className="app-rightbar">
              <div
                className={classnames('app-list', {
                  'flex-column': appListView === 'list-view',
                })}
              >
                {filteredList.length > 0 &&
                  filteredList.map((app) => (
                    <MyAppCard
                      key={app.id}
                      assetId={app.logoId}
                      byFrom={app.byFrom}
                      identifier={app.identifier}
                      rating={app.averageRateValue}
                      status={app.publishStatus}
                      title={app.title}
                      url={`${url}/${app.id}/versions`}
                      onGettingRatingResponse={this.handleOnGettingRatingResponse}
                    />
                  ))}
              </div>
            </div>
          </div>
          <SideBarRight open={isRightOpen}>
            <button
              className="c-sidebar-right__close"
              type="button"
              onClick={this.handleToggleRightSideBar}
              onKeyDown={this.handleToggleRightSideBar}
            >
              <span className="icon-plus" />
            </button>
            <strong className="c-sidebar-right-title text-bold">Create an app</strong>

            <label className="text-left mt-2 " htmlFor="name">
              <strong>Name</strong>
              <br />
              <input
                aria-label="Name"
                className="w-100 bg-transparent p-2 border"
                name="appTitle"
                required=""
                value={appTitle}
                onChange={(e) => this.handleInputs(e, 10)}
                onClick={this.handleClearErrorMessages}
              />
            </label>
            <div className="fs-12 d-flex justify-content-end small text-secondary" id="appTitleMsg">
              {10 - appTitle.length} characters left
            </div>
            <label className="text-left mt-2 " htmlFor="name">
              <strong>App code</strong>
              <br />
              <input
                aria-label="App code"
                className="w-100 bg-transparent p-2 border"
                name="appCode"
                required=""
                value={appCode}
                onChange={(e) => this.handleInputs(e, 10)}
                onClick={this.handleClearErrorMessages}
              />
            </label>
            <div className="fs-12 d-flex justify-content-end small text-secondary" id="appCodeMsg">
              {10 - appCode.length} characters left
            </div>
            <label className="text-left mt-2" htmlFor="description">
              <strong>Add description</strong>
              <br />
              <textarea
                aria-label="Add description"
                className="w-100 bg-transparent p-2 border"
                id="text"
                name="appDescription"
                required=""
                value={appDescription}
                onChange={(e) => this.handleInputs(e, 60)}
                onClick={this.handleClearErrorMessages}
              />
              <div className="fs-12 d-flex justify-content-end small text-secondary" id="msg">
                {60 - appDescription.length} characters left
              </div>
            </label>
            <strong>Thumbnail</strong>
            <Dropzone ref={dropzoneRef} accept="image/*" noClick noKeyboard onDropAccepted={this.handleFileDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div className="container dropzone-outer">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>{isDragActive ? 'Drop here' : 'Drop image here or click to upload'}</p>
                    <p>
                      (<strong>Maximum</strong> 1 file)
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="mt-3 text-center">
              <button className="button--green" type="button" onClick={openDialog}>
                Choose from library
              </button>
            </div>
            <div className="border-bottom mt-3 mb-3" />
            <div>
              <div>
                <strong className="mt-3">App preview</strong>
              </div>
              <div className="d-flex p-2">
                <div className="p-1">
                  {filePreview && <img alt="img" className="rounded" height="90px" src={filePreview} width="90px" />}
                </div>
                <div className="p-1 ml-2">
                  <strong className="mt-3">{appTitle}</strong>
                  <p>{appDescription}</p>
                </div>
              </div>
            </div>
            <div />
            <div className="text-center">
              <button className="button--black mt-4 mr-3" type="button" onClick={this.handleToggleRightSideBar}>
                Cancel
              </button>
              <button className="mt-4  button--blue" type="submit" onClick={this.handleOnSubmit}>
                Save
              </button>
            </div>
          </SideBarRight>
        </PageWrapper>
        {!orgLoaded && <GetOrganisation id={organisationId} onError={this.handleOrgError} onSuccessResult={this.handleGetOrganisation} />}
        {!projectLoaded && <GetProject id={projectId} onError={this.handleProjectError} onSuccessResult={this.handleGetProject} />}
        {createMyAppRequest && (
          <CreateMyAppRequest
            applicationDescription={appDescription}
            identifier={appCode}
            logoId={assetId}
            title={appTitle}
            onError={this.handleOnError}
            onSuccessResult={this.handleOnSuccessResultCreateApp}
          />
        )}
        {!appLoaded && <GetMyAppListRequest onError={this.handleGetListOnError} onSuccessResult={this.handleOnSuccessResultGetList} />}
        {createAsset && (
          <CreateAssetRequest
            contentType={file.type}
            fileName={file.name}
            fileSizeInBytes={file.size}
            projectId={projectId}
            type="IMAGE"
            onError={this.handleAssetError}
            onSuccessResult={this.handleAssetResponse}
          />
        )}
        {deleteAssetRequest && (
          <DeleteAssetRequest
            assetId={assetId}
            onError={this.handleDeleteSnippetError}
            onSuccessResult={this.handleDeleteSnippetResponse}
          />
        )}
        {loader && <Preloader text="Uploading Image. Please wait!" />}
        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
        </ReactCSSTransitionGroup>
      </>
    );
  }
}

MyApplicationPage.propTypes = {
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

export default MyApplicationPage;
