import gql from 'graphql-tag';

export const GET_CONTAINER = gql`
  query($containerId: Int!) {
    container {
      get(containerId: $containerId) {
        cssStyle {
          id
          name
        }
      }
    }
  }
`;
