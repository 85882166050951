import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import PopUpMsg from '../UI/PopUp/PopUpMsg';

import { getAssetsUrl } from '../../config';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CopyIcon from '../../static/img/copyIcon.svg';

export const Wrapper = styled.div`
  background-color: #f6f8f9;
  bottom: ${props => (props.sideOpen ? '0' : '60px')};
  left: ${props => (props.sideOpen ? '-6.8em' : '0')};
  position: relative;
  width: ${props => (props.sideOpen ? 'calc(100% + 11em)' : '100%')};
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1em 0;
`;

export const Select = styled.select`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 2.2em;
  width: 10em;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 6px -1em 1em 0;
`;
// TODO this needs to be global, discussion around where to put these??? currently lives in sidebar style.js
export const Title = styled.label.attrs(({ htmlFor }) => ({
  htmlFor,
}))`
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  line-height: ${({ lineHeight }) => lineHeight};
  margin: ${({ margin }) => margin};
  width: 100%;
`;
export const Option = styled.option``;

export const InputWrapper = styled.div`
  margin-left: 1em;
  max-width: 20em;
`;

export const PlusButton = styled.button`
  background-color: #e1e1e1;
  border: none;
  border-radius: 50%;
  height: 20px;
  margin: 0 0.4em;
  width: 20px;

  &:focus {
    outline: none;
  }
`;

export const AdvanceContainer = styled.div`
  background-color: #e8edf0;
  max-height: 20em;
  overflow-y: auto;
  padding: 2em;
`;

export const AdvanceItem = styled.div`
  margin: 0 auto;
  max-width: 25.5em;
`;

export const AdvanceTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AssetContainer = styled(AdvanceContainer)`
  display: flex;
  flex-flow: row wrap;
  height: 20em;
  justify-content: space-between;
`;

export const AssetBox = styled.div`
  height: 100%;
  width: 45%;
`;

export const AssetItemWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #000;
  display: flex;
  height: 3em;
  justify-content: space-between;
  padding: 0 1em;
  width: 100%;
`;

export const AssetsLib = styled(AssetBox)`
  border: 1px solid #000;
  border-radius: 5px;
  height: calc(100% - 2.7em);
  margin: 1em 0;
  overflow-y: auto;
  width: calc(55% - 2em);

  & ${AssetItemWrapper} :last-child {
    border-bottom: 1px solid transparent;
  }
`;

const AssetIcon = styled.span`
  align-items: center;
  background-color: #4bc2ca;
  border-radius: 50%;
  display: block;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
`;

const AssetTitle = styled.span`
  color: #4bc2ca;
  margin: 0 1em;
  max-width: calc(100% - 5em);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
`;

const CopyBtn = styled.button`
  border: none;
`;

export const AssetItem = props => {
  const { asset } = props;

  const [showMsg, setShowMsg] = useState(false);

  const assetTypes = ['image', 'video', 'audio'];
  const index = asset.contentType.indexOf('/');
  let tmpType = asset.contentType.substring(0, index);
  if (!assetTypes.includes(tmpType)) {
    tmpType = 'table';
  }
  const handleCopy = data => {
    const value = getSignedUrl(data.s3Key, data.contentType);
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', value);
    input.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      setShowMsg(true);
    }
    document.body.removeChild(input);
  };

  const handleMsgDisappear = () => {
    setTimeout(() => {
      setShowMsg(false);
    }, 500);
  };

  return (
    <AssetItemWrapper>
      <AssetIcon>
        <img alt="edit" height="16px" src={`${getAssetsUrl()}/icons/content-snippet/${tmpType}.svg`} width="16px" />
      </AssetIcon>
      <AssetTitle>{asset.fileName}</AssetTitle>
      <CopyBtn onClick={() => handleCopy(asset)}>
        <img alt="copy" src={CopyIcon} />
      </CopyBtn>
      {showMsg && <PopUpMsg message="Url copied" msgDisappear={handleMsgDisappear} />}
    </AssetItemWrapper>
  );
};

AssetItem.propTypes = {
  asset: PropTypes.shape({
    contentType: PropTypes.string,
    fileName: PropTypes.string,
  }).isRequired,
};
