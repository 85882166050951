import React, { useState, createRef } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import Button from '../Button/SquareButton';

import CreateAssetRequest from '../../../middleware/AppAssets/createAppAssetRequest';

const Wrapper = styled.div`
  margin: 1em 0;
  min-height: 20vh;
  text-align: center;
`;

const Preview = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;

  /* white-space: nowrap; */
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const fontTypes = ['otf', 'ttf', 'eof', 'woff', 'woff2'];
const assetType = ['image', 'video', 'audio'];

const AppAssetUploader = (props) => {
  const { appVersionId, getData } = props;
  const [createAsset, setCreateAsset] = useState(false);

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  //   const [logoData, setLogoData] = useState({});

  const dropzoneRef = createRef();

  const handleFileDrop = (files) => {
    setFile(files[0]);
    const index1 = files[0].name.lastIndexOf('.') + 1;
    const suffix = files[0].name.substr(index1);
    if (fontTypes.includes(suffix)) {
      setFileType('font');
    } else if (suffix === 'js') {
      setFileType('script');
    } else {
      // if type is application, it means asset isn't image/video/audio.
      const index2 = files[0].type.indexOf('/');
      const tmpType = files[0].type.substring(0, index2);
      if (assetType.includes(tmpType)) {
        setFileType(tmpType);
      } else {
        setFileType('unknown');
      }
    }
  };

  const handleCancelBtn = () => {
    setFile(null);
    // setPreview(null);
  };

  const handleSaveBtn = () => {
    setCreateAsset(true);
  };

  const handleAssetError = () => {
    setCreateAsset(false);
  };

  const handleAssetResponse = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, asset },
      },
    } = data;

    setCreateAsset(false);
    Object.defineProperty(file, 'name', {
      writable: true,
      value: asset.s3Key,
    });
    setFile(null);
    axios.put(signedUrl, file, { headers: { 'content-type': file.type } }).then(() => {
      getData(asset);
    });
  };

  return (
    <>
      {file ? (
        <Wrapper>
          {fileType === 'unknown' ? (
            <p>Unknown file type can be uploaded!</p>
          ) : (
            <div>
              <Preview>{file.name} </Preview>
              <p>is ready to upload</p>{' '}
            </div>
          )}
          <BtnGroup>
            <Button bgColor="#505354" color="#fff" isClicked={handleCancelBtn}>
              Cancel
            </Button>
            {fileType !== 'unknown' && <Button isClicked={handleSaveBtn}>Save</Button>}
          </BtnGroup>
        </Wrapper>
      ) : (
        <Wrapper>
          <Dropzone ref={dropzoneRef} noClick noKeyboard onDropAccepted={handleFileDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p style={{ marginBottom: '0.6em' }}>{isDragActive ? 'Drop here' : 'Drop assets here or click to upload'}</p>
                  <p>
                    (<strong>Maximum</strong> 1 file)
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
        </Wrapper>
      )}
      {createAsset && (
        <CreateAssetRequest
          appVersionId={appVersionId}
          contentType={file.type}
          fileName={file.name}
          fileSizeInBytes={file.size}
          type={fileType.toUpperCase()}
          onError={handleAssetError}
          onSuccessResult={handleAssetResponse}
        />
      )}
    </>
  );
};

AppAssetUploader.propTypes = {
  appVersionId: PropTypes.number.isRequired,
  getData: PropTypes.func.isRequired,
};

export default AppAssetUploader;
