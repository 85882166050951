import React, { useContext, useEffect, useState } from 'react';

import { Client } from '@netfront/gelada-react-shared';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ImageUploader from '../UI/AssetUploader/ImageUploader';
import ColorInput from '../UI/ColorPicker/ColorInput';
import Input from '../UI/Input/InputWhite';
import SidebarTab from '../UI/Tab/SidebarTab';
import Textarea from '../UI/Textarea/Textarea';
import Tooltip from '../UI/Tooltip/Tooltips';

import gql from 'graphql-tag';
import RightNavBar from './RightNavBar';

import AppContext from '../../context/AppContext';
import ThemeContext from '../../context/ThemeContext';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateProjectRequest from '../../middleware/Project/createProjectRequest';
import EnableProjectRequest from '../../middleware/Project/enableProjectRequest'; // ENABLE_PROJECT,
import UpdateProjectRequest from '../../middleware/Project/updateProjectRequest';
import UpdateProjectSetting from '../../middleware/Project/updateProjectSettings';
import UpdateProjectStatusRequest from '../../middleware/Project/updateProjectStatusRequest';
import { useRef } from 'react';
import { useApolloClient } from '../../middleware/apollo/useApolloClient';
import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { useToast } from '../../hooks';
import { Spinner } from '../Preloader/Preloader';

const AdvanceContainer = styled.div`
  overflow-y: scroll;
  padding: 0 1em;
`;

const AdvanceItem = styled.div`
  margin: 1em auto;
  width: 100%;
`;

const ColorItem = styled(AdvanceItem)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const AdvanceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ColorTitle = styled(AdvanceTitle)`
  margin-left: 1em;
`;

const EditButton = styled.button`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Preview = styled.div`
  margin: 0 0 2em 35%;
  max-width: 30%;
  position: relative;

  &:hover {
    ${EditButton} {
      display: block;
    }
  }
`;

const Logo = styled.img`
  width: 100%;
`;

const UPDATE_LOGO = gql`
  mutation updateLogo($id: String!, $projectId: String!) {
    project {
      updateLogo(logo: { id: $id, projectId: $projectId }) {
        id
      }
    }
  }
`;

const ProjectSideBar = ({ currentProject, onCancel, onDelete, onSave }) => {
  const { org } = useContext(AppContext);
  const { theme } = useContext(ThemeContext);

  const { handleToastSuccess } = useToast();
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [editLogo, setEditLogo] = useState(false);
  const [enableRequest, setEnableRequest] = useState(false);
  const [isNewProject, setIsNewProject] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [project, setProject] = useState(currentProject);
  const [projectId, setProjectId] = useState(null);
  const [sendRequest, setSendRequest] = useState(false);
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const client = useRef(useApolloClient('gelada')).current;

  const [updateLogo] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        setIsLoading(false);
        handleToastSuccess({
          message: 'Logo updated successfully',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_LOGO,
  });

  useEffect(() => {
    if (Object.keys(currentProject).length === 0) {
      setIsNewProject(true);
    } else if (currentProject.logo) {
      const tmpURL = getSignedUrl(currentProject.logo.s3Key, currentProject.logo.contentType);
      setLogoUrl(tmpURL);
    } else if (currentProject.projectThemeS3Key) {
      const tmpURL = getSignedUrl(currentProject.projectThemeS3Key, 'text/css');
      axios
        .get(tmpURL)
        .then((res) => {
          const newInfo = {
            ...project,
            css: res.data,
          };
          setProject(newInfo);
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
    if (Object.keys(currentProject).length !== 0) {
      const footerColor = currentProject.theme.footerColor || theme.footerColor;
      const buttonColor = currentProject.theme.buttonColor || theme.buttonColor;
      const linkColor = currentProject.theme.linkColor || theme.linkColor;
      const backGroundColor = currentProject.theme.backGroundColor || theme.backGroundColor;

      const newProject = {
        ...project,
        headerColor: currentProject.theme.headerColor,
        footerColor,
        sideColor: currentProject.theme.sideColor,
        buttonColor,
        linkColor,
        backGroundColor,
      };

      setProject(newProject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  const handleCreateRequest = (id) => {
    setProjectId(id);
    setSendRequest(false);
    setEnableRequest(true);
  };

  const handleEnableRequest = () => {
    setEnableRequest(false);
    onSave();
  };

  const handleUpdataRequest = () => {
    onSave();
    setSendRequest(false);
  };

  const handleClickSave = () => {
    setSendRequest(true);
  };

  const handleClickDelete = () => {
    setDeleteRequest(true);
  };

  const handleInfoUpdate = (value, e) => {
    let newValue = value;

    if (e.target.name === 'status') {
      newValue = newValue ? 'ACTIVE' : 'INACTIVE';
    }
    const newInfo = {
      ...project,
      [e.target.name]: newValue,
    };
    setProject(newInfo);
  };

  const getLogoData = (value) => {
    setIsLoading(true);
    const tmpURL = getSignedUrl(value.s3Key, value.contentType);
    updateLogo({
      variables: {
        projectId: project.id,
        id: value.assetId,
      },
    });
    setEditLogo(false);
    setLogoUrl(tmpURL);
  };

  const handleErrorMessage = () => {
    setSendRequest(false);
    setDeleteRequest(false);
  };

  const handleDeleteRequest = () => {
    onDelete();
    setDeleteRequest(false);
  };

  const handleSettingSuccess = () => {
    setSendRequest(true);
  };

  const createItem = (
    <AdvanceContainer>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Title</b>
          <Tooltip />
        </AdvanceTitle>
        <Input isChanged={handleInfoUpdate} name="name" text={project.name} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Summary</b>
          <Tooltip />
        </AdvanceTitle>
        <Textarea isChanged={handleInfoUpdate} name="description" text={project.description} />
      </AdvanceItem>
    </AdvanceContainer>
  );

  const infoItem = () => (
    <AdvanceContainer>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Title</b>
          <Tooltip />
        </AdvanceTitle>
        <Input isChanged={handleInfoUpdate} name="name" text={project.name} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Summary</b>
          <Tooltip />
        </AdvanceTitle>
        <Textarea isChanged={handleInfoUpdate} name="description" text={project.description} />
      </AdvanceItem>
      {!isNewProject && project.id && (
        <AdvanceItem>
          <AdvanceTitle>
            <b>Logo</b>
            <Tooltip />
          </AdvanceTitle>
          {logoUrl && !editLogo ? (
            <Preview>
              <EditButton
                onClick={() => {
                  setEditLogo(true);
                }}
              >
                Upload new logo
              </EditButton>
              <Logo alt="lsogo" src={logoUrl} />
            </Preview>
          ) : (
            <ImageUploader apiClient={client} getData={getLogoData} name="logo" projectId={project.id} type="PROJECT_LOGO" />
          )}
        </AdvanceItem>
      )}
    </AdvanceContainer>
  );

  return (
    <>
      {isLoading && <Spinner />}
      <RightNavBar hasDelete={!isNewProject} onCancel={onCancel} onDelete={handleClickDelete} onSave={handleClickSave}>
        <div>
          <h3 className="custom-h3">{!isNewProject ? `Edit Project - ${currentProject.name}` : 'Create New Project'}</h3>
          {isNewProject ? <div style={{ margin: '0 -1em' }}>{createItem}</div> : <SidebarTab item0={infoItem} label0="Info" />}
        </div>
      </RightNavBar>
      {sendRequest && isNewProject && (
        <CreateProjectRequest
          description={project.description || ''}
          name={project.name}
          orgId={org.id}
          onError={handleErrorMessage}
          onSuccessResult={handleCreateRequest}
        />
      )}
      {enableRequest && projectId && (
        <EnableProjectRequest projectId={projectId} onError={handleErrorMessage} onSuccessResult={handleEnableRequest} />
      )}
      {sendRequest && !isNewProject && (
        <UpdateProjectRequest
          description={project.description}
          id={project.id}
          name={project.name}
          url={project.url}
          onError={handleErrorMessage}
          onSuccessResult={handleUpdataRequest}
        />
      )}

      {/* ******************** DELETE project ******************8 */}
      {deleteRequest && (
        <UpdateProjectStatusRequest id={project.id} status={2} onError={handleErrorMessage} onSuccessResult={handleDeleteRequest} />
      )}
    </>
  );
};

ProjectSideBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  currentProject: PropTypes.shape({
    logo: PropTypes.shape({
      contentType: PropTypes.string,
      s3Key: PropTypes.string,
    }),
    name: PropTypes.string,
    projectThemeS3Key: PropTypes.string,
    theme: PropTypes.shape({
      backGroundColor: PropTypes.string,
      buttonColor: PropTypes.string,
      footerColor: PropTypes.string,
      headerColor: PropTypes.string,
      linkColor: PropTypes.string,
      sideColor: PropTypes.string,
    }),
  }),
};

ProjectSideBar.defaultProps = {
  currentProject: {},
};

export default ProjectSideBar;
