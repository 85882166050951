import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ConsoleHeader from '../Header/ConsoleHeader';

const Wrapper = styled.div`
  height: fit-content;
  min-height: ${props =>
    props.isContentPage ? 'calc(100vh - 121px)' : 'calc(100vh - 60px)'};
  width: 100%;
`;

export default function ConsoleMaster(props) {
  const { children, path, isContentPage } = props;

  return (
    <Wrapper isContentPage={isContentPage}>
      <ConsoleHeader organisationId={path.match.params.org} />
      {children}
    </Wrapper>
  );
}

ConsoleMaster.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  path: PropTypes.objectOf(PropTypes.object).isRequired,
  isContentPage: PropTypes.bool,
};

ConsoleMaster.defaultProps = {
  isContentPage: false,
};
