import gql from 'graphql-tag';

export const ENABLE_USER = gql`
  mutation {
    user {
      enableAccount {
        id
      }
    }
  }
`;
