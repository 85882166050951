import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';

import InvoiceTable from './InvoiceTable';

import Button from '../../components/Buttons/Button';
import Preloader from '../../components/Preloader/Preloader';
import PopUpMsg from '../../components/UI/PopUp/PopUpMsg';
import { GET_TRANSACTION_FOR_ORDER } from '../../middleware/_Muriqui/GetTransactionForOrder';
import { GET_ORDER_ITEMS } from '../../middleware/_Vervet/Queries/GetOrderItems';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import muriquiClient from '../../middleware/muriquiClient';
import vervetClient from '../../middleware/vervetClient';
import { downloadFileByUrl } from '../../utils/utils';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

function InvoicePage({ orderId }) {
  const [message, setMessage] = useState('');
  const [orderSummary, setOrderSummary] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);

  const [executeGetOrderItems, { loading: isLoadingItems }] = useLazyQuery(GET_ORDER_ITEMS, {
    client: vervetClient,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const {
        orders: { getOrderSummary: returnedOrderSummary },
      } = data;

      setOrderSummary(returnedOrderSummary);
    },
    onError: (error) => {
      console.error(error.message);
      setMessage('Something went wrong! Please try again later.');
    },
  });

  const [executeGetTransactionForOrder, { loading: isLoadingTransaction }] = useLazyQuery(GET_TRANSACTION_FOR_ORDER, {
    client: muriquiClient,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const {
        transactions: { getTransactionForOrder: returnedTransaction },
      } = data;

      const {
        order: { guid: orderGuid },
      } = returnedTransaction;

      setTransaction(returnedTransaction);

      executeGetOrderItems({
        variables: {
          orderGuid,
        },
      });
    },
    onError: (error) => {
      console.error(error.message);
      setMessage('Something went wrong! Please try again later.');
    },
  });

  function handleDownloadInvoice(invoiceS3Key) {
    const signedUrl = getSignedUrl(invoiceS3Key, 'application/pdf');
    downloadFileByUrl(signedUrl);
  }

  function handleMsgDisappear() {
    setTimeout(() => {
      setMessage('');
    }, 500);
  }

  useEffect(() => {
    executeGetTransactionForOrder({
      variables: {
        orderId,
      },
    });
  }, [orderId]);

  const isLoaderVisible = isLoadingItems || isLoadingTransaction;
  if (isLoaderVisible) {
    return <Preloader />;
  }

  const { s3Key: invoiceS3Key } = transaction || {};
  const hasInvoice = Boolean(invoiceS3Key);

  return (
    <>
      {orderSummary && transaction && (
        <>
          <div style={{ padding: '1rem' }}>
            <InvoiceTable orderSummary={orderSummary} transaction={transaction} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
            {hasInvoice && <Button onClick={() => handleDownloadInvoice(invoiceS3Key)}>Download</Button>}
          </div>
        </>
      )}
      <PopUpMsg message={message} msgDisappear={handleMsgDisappear} />
    </>
  );
}

export default InvoicePage;
