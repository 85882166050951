import { gql } from '@apollo/client';

const SET_USER_ANSWER_MATCH_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $answers: [QuestionAnswerMatchAnswerInputType]
    $status: EQuestionAnswerStatus
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        answerMatch: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          answers: $answers
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
        contentSnippetFormId: $contentSnippetFormId
      ) {
        ... on QuestionAnswerMatchType {
          answers {
            definedPosition
            questionAnswerMatchId
            questionResponseMatchId
            summaryFeedback {
              ... on SummaryFeedbackRankAndNoteType {
                id
                note
                rank
              }
            }
          }
        }
        id
      }
    }
  }
`;

export { SET_USER_ANSWER_MATCH_MUTATION };
