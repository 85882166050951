import React, { useContext, useEffect, useState } from 'react';

import { removeItemFromLocalStorage, saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import { useGetGeladaOrganisationByKey, useGetGeladaProject } from '@netfront/gelada-identity-library';
import { Preloader } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/ConsoleFooter/Footer';
import Header from '../../components/Header/ConsoleHeader';
import InfoBanner from '../../components/InfoBanner/InfoBanner';
import { getAssetsUrl } from '../../config';
import { NetfrontServices } from '../../constants/NetfrontServices';
import AppContext from '../../context/AppContext';
import ThemeContext from '../../context/ThemeContext';
import { useNewEkardoSiteUrls, useToast } from '../../hooks';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1400px;
  min-height: calc(100vh - 59px);
  padding: 0 2em 5em 2em;
`;

function PageWrapper(props) {
  const {
    breadPath: additionalBreadCrumbPath,
    children,
    containerStyle,
    hasNoTheme,
    info,
    isLoaderVisible,
    linkText,
    pageDescription,
    pageTitle,
    url,
  } = props;

  const { handleToastError } = useToast();

  const { orgId: organisationId, projectId } = useParams();

  const { updateOrg: updateOrganisation, updateProject, toggleSubscriptions } = useContext(AppContext);
  const { updateLogo, updateTheme } = useContext(ThemeContext);

  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const [breadCrumbPath, setBreadCrumbPath] = useState();
  const [breadCrumbTitle, setBreadCrumbTitle] = useState('Dashboard');
  const [project, setProject] = useState(window.localStorage.getItem('project'));
  const [projectExpiry, setProjectExpiry] = useState();
  const [organisation, setOrganisation] = useState(window.localStorage.getItem('organisation'));
  const [organisationExpiry, setOrganisationExpiry] = useState();

  const handleUpdateOrganisationRelatedInfo = (geladaOrganisation) => {
    setBreadCrumbTitle(geladaOrganisation.name);
    updateOrganisation(geladaOrganisation);
  };

  const { handleGetGeladaOrganisationByKey, isLoading: isGetGeladaOrganisationLoading = false } = useGetGeladaOrganisationByKey({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaOrganisation }) => {
      saveItemWithExpiryToLocalStorage('organisation', JSON.stringify(geladaOrganisation), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      handleUpdateOrganisationRelatedInfo(geladaOrganisation);
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpdateProjectRelatedInfo = (geladaProject) => {
    const {
      id,
      logo,
      name: projectName,
      organisation: { key },
      services,
      theme,
    } = geladaProject;
    const { contentType, s3Key } = logo || {};

    const hasSubscriptions = services.includes(NetfrontServices.MURIQUI) && services.includes(NetfrontServices.VERVET);

    const updatedBreadCrumbPath = [
      ...[
        {
          path: `${getNewEkardoSiteBaseUrl()}/dashboard/${key}/${id}`,
          title: 'Dashboard',
          isExternal: true,
        },
      ],
      ...additionalBreadCrumbPath,
    ];

    if (contentType && s3Key) {
      updateLogo(getSignedUrl(s3Key, contentType));
    }

    setBreadCrumbPath(updatedBreadCrumbPath);
    setBreadCrumbTitle(projectName);

    toggleSubscriptions(hasSubscriptions);

    updateProject(geladaProject);
    updateTheme(theme);
  };

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading = false } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject }) => {
      saveItemWithExpiryToLocalStorage('project', JSON.stringify(geladaProject), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      handleUpdateProjectRelatedInfo(geladaProject);
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!organisationId) {
      return;
    }

    if (!organisation) {
      handleGetGeladaOrganisationByKey({
        organisationKey: organisationId,
      });

      return;
    }

    if (typeof organisation !== 'string') {
      return;
    }
    try {
      const orgIdNumber = organisationId;
      const orgJSON = JSON.parse(organisation);
      const orgValue = orgJSON.value;
      const orgValueJSON = JSON.parse(orgValue);

      if (orgIdNumber !== orgValueJSON.key) {
        removeItemFromLocalStorage('organisation');
        handleGetGeladaOrganisationByKey({
          organisationKey: organisationId,
        });
      } else {
        handleUpdateOrganisationRelatedInfo(orgValueJSON);
      }
    } catch {
      removeItemFromLocalStorage('organisation');
      handleGetGeladaOrganisationByKey({
        organisationKey: organisationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation, organisationId]);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    if (!project) {
      handleGetGeladaProject({
        projectId,
        shouldIncludeProjectLogo: true,
      });

      return;
    }

    if (typeof project !== 'string') {
      return;
    }
    try {
      const projectJSON = JSON.parse(project);
      const projectValue = projectJSON.value;
      const projectValueJSON = JSON.parse(projectValue);

      if (projectId !== projectValueJSON.id) {
        removeItemFromLocalStorage('project');
        handleGetGeladaProject({
          projectId,
          shouldIncludeProjectLogo: true,
        });
      } else {
        handleUpdateProjectRelatedInfo(projectValueJSON);
      }
    } catch {
      removeItemFromLocalStorage('project');
      handleGetGeladaProject({
        projectId,
        shouldIncludeProjectLogo: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project]);

  useEffect(() => {
    try {
      const projectJSON = JSON.parse(window.localStorage.getItem('project'));
      const organisationJSON = JSON.parse(window.localStorage.getItem('organisation'));

      if (!projectJSON) {
        return;
      }

      updateProject(JSON.parse(projectJSON.value));
      setProject(JSON.parse(projectJSON.value));
      setProjectExpiry(Number(projectJSON.expiry));

      if (!organisationJSON) {
        return;
      }

      updateOrganisation(JSON.parse(organisationJSON.value));
      setOrganisation(JSON.parse(organisationJSON.value));
      setOrganisationExpiry(Number(organisationJSON.expiry));
    } catch {
      window.localStorage.removeItem('project');
      window.localStorage.removeItem('organisation');
    }
  }, []);

  useEffect(() => {
    if (!projectExpiry) {
      return;
    }

    if (new Date().getTime() < projectExpiry) {
      return;
    }

    removeItemFromLocalStorage('project');
  }, [projectExpiry]);

  useEffect(() => {
    if (!organisationExpiry) {
      return;
    }

    if (new Date().getTime() < organisationExpiry) {
      return;
    }

    removeItemFromLocalStorage('organisation');
  }, [organisationExpiry]);

  if (hasNoTheme) {
    updateLogo(`${getAssetsUrl()}/logo/logo-minimal.svg`);
  }

  const isLoading = isGetGeladaOrganisationLoading || isGetGeladaProjectLoading || isLoaderVisible;

  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta content={pageDescription} name="description" />
      </Helmet>
      <div>
        <Header />
        <Container style={containerStyle}>
          {breadCrumbTitle && <Breadcrumb breads={breadCrumbPath} title={breadCrumbTitle} />}
          {info && <InfoBanner linkText={linkText} text={info} url={url} />}
          {children}
        </Container>
        <Footer />
      </div>
    </>
  );
}

PageWrapper.propTypes = {
  breadPath: PropTypes.arrayOf(PropTypes.shape({})),
  containerStyle: PropTypes.shape({}),
  hasNoTheme: PropTypes.bool,
  info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isLoaderVisible: PropTypes.bool,
  linkText: PropTypes.string,
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string,
  url: PropTypes.string,
};

PageWrapper.defaultProps = {
  breadPath: [],
  containerStyle: {},
  hasNoTheme: false,
  info: '',
  isLoaderVisible: false,
  linkText: null,
  pageDescription: 'This is the Ekardo website',
  pageTitle: 'Building accessible and engaging educational content better',
  url: null,
};

export default PageWrapper;
