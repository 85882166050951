import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { LicencedUserDetails } from '../LicencedUserDetails/LicencedUserDetails';

import { getOrderModuleLicencedUsers } from './LicencedUsersTable.helpers';

import { EKReactTable } from '../../../components/Table/EKReactTable/EKReactTable';
import ImageButton, { ICONS } from '../../../components/UI/Button/ImageButton';

const TABLE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'fullName',
    width: '60%',
  },
  {
    Header: 'Expiry date',
    Cell: ({ value }) => <span>{value.toLocaleDateString()}</span>,
    accessor: 'expiryDate',
    width: '32.5%',
  },
  {
    Header: '',
    accessor: 'button',
    width: '7.5%',
  },
];

const LicencedUsersTable = ({ onUpdateHandler, orderId, ordersDetails }) => {
  const [filter, setFilter] = useState(undefined);
  const [selectedLicence, setSelectedLicence] = useState(undefined);
  const [selectedOrdersDetails, setSelectedOrdersDetails] = useState(ordersDetails);
  const [selectedUserFlowId, setSelectedUserFlowId] = useState(undefined);
  const [tableData, setTableData] = useState(undefined);
  const [userFlows, setUserFlows] = useState(undefined);
  const [userFlowLicencedUsers, setUserFlowLicencedUsers] = useState(undefined);

  const handleClose = () => {
    setSelectedLicence(undefined);
  };

  const handleDelete = (licenceId) => {
    const updatedOrdersDetails = selectedOrdersDetails.map((orderDetails) => {
      const { items } = orderDetails;

      const updateItems = items.map((orderDetailItem) => {
        const { licences } = orderDetailItem;

        const updatedLicences = licences.filter((licence) => licence.id !== licenceId);

        return {
          ...orderDetailItem,
          licences: updatedLicences,
        };
      });

      return {
        ...orderDetails,
        items: updateItems,
      };
    });

    const updatedOrderModuleLicencedUsers = getOrderModuleLicencedUsers(orderId, updatedOrdersDetails);

    setSelectedLicence(undefined);
    setSelectedOrdersDetails(updatedOrdersDetails);
    setUserFlowLicencedUsers(updatedOrderModuleLicencedUsers.userFlowLicencedUsers);

    onUpdateHandler();
  };

  const handleSave = (expiresAtUtc, licenceId) => {
    const updatedOrdersDetails = selectedOrdersDetails.map((orderDetails) => {
      const { items } = orderDetails;

      const updateItems = items.map((orderDetailItem) => {
        const { licences } = orderDetailItem;

        const updatedLicences = licences.map((licence) =>
          licence.id === licenceId
            ? {
                ...licence,
                expiresAtUtc,
              }
            : licence,
        );

        return {
          ...orderDetailItem,
          licences: updatedLicences,
        };
      });

      return {
        ...orderDetails,
        items: updateItems,
      };
    });

    const updatedOrderModuleLicencedUsers = getOrderModuleLicencedUsers(orderId, updatedOrdersDetails);

    setSelectedLicence(undefined);
    setSelectedOrdersDetails(updatedOrdersDetails);
    setUserFlowLicencedUsers(updatedOrderModuleLicencedUsers.userFlowLicencedUsers);

    onUpdateHandler();
  };

  const handleUserFlowChange = (userFlowId) => {
    setSelectedUserFlowId(Number(userFlowId));
  };

  const handleViewLicence = (licence) => {
    setSelectedLicence(licence);
  };

  useEffect(() => {
    const orderModuleLicencedUsers = getOrderModuleLicencedUsers(orderId, ordersDetails);

    setSelectedUserFlowId(orderModuleLicencedUsers.userFlows[0].id);
    setUserFlows(orderModuleLicencedUsers.userFlows);
    setUserFlowLicencedUsers(orderModuleLicencedUsers.userFlowLicencedUsers);
  }, [orderId]);

  useEffect(() => {
    if (!(userFlowLicencedUsers && userFlows)) {
      return;
    }

    setFilter({
      dropdown: {
        items: userFlows.map((userFlow) => {
          const { id, title } = userFlow;

          return {
            id: id.toString(),
            label: `Module: ${title}`,
            value: id,
          };
        }),
        onDropDownChangeHandler: handleUserFlowChange,
        selectedValue: selectedUserFlowId.toString(),
      },
    });

    setTableData(
      userFlowLicencedUsers[selectedUserFlowId].map((licencedUser) => {
        const { expiryDate, fullName, licence } = licencedUser;

        return {
          button: <ImageButton borderRadius="15%" iconType={ICONS.forward} onClicked={() => handleViewLicence(licence)} />,
          expiryDate,
          fullName,
        };
      }),
    );
  }, [selectedUserFlowId, userFlowLicencedUsers, userFlows]);

  return (
    <div style={{ padding: '1rem' }}>
      {selectedLicence && (
        <LicencedUserDetails
          licence={selectedLicence}
          onCloseHandler={handleClose}
          onDeleteHandler={handleDelete}
          onSaveHandler={handleSave}
        />
      )}
      {!selectedLicence && tableData && <EKReactTable columns={TABLE_COLUMNS} data={tableData} filter={filter} />}
    </div>
  );
};

LicencedUsersTable.propTypes = {
  orderId: PropTypes.number.isRequired,
  orderDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    items: PropTypes.instanceOf(Array),
  }),
};

export { LicencedUsersTable };
