import gql from 'graphql-tag';

export const GET_CONDITION_TEMPLATES = gql`
  query getTemplates($projectId: String!, $title: String) {
    questionAction {
      getConditions(projectId: $projectId, isTemplate: true, title: $title) {
        id
        contentPageId
        contentSnippetQuestionId
        title
        ... on QuestionConditionRadioResponseSelectedType {
          questionResponseRadioId
          selection
        }
        ... on QuestionConditionTextType {
          text
        }
        ... on QuestionConditionSliderValueSelectedType {
          maxValue
          minValue
        }
        ... on QuestionConditionCheckboxSelectedType {
          questionResponseCheckboxId
          selection
        }

        ... on QuestionConditionDateSelectedType {
          dateRangeType: type
          minDate
          maxDate
          selectedDate
          minTimeSpan
          maxTimeSpan
        }
      }
    }
  }
`;
