import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SUMMARY_FEEDBACK_CONFIGURATION } from './UseGetSnippetSummaryFeedbackConfig.graphql';

const useGetSnippetSummaryFeedbackConfig = (options) => {
  const { query, fetchPolicy, onCompleted, onError, token } = options ?? {};

  const [executeGetSnippetSummaryFeedbackConfig, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: {
            getContentSnippet: { summaryFeedbackConfiguration: config },
          },
        } = data;

        onCompleted({
          config,
        });
      },
      onError,
    },
    query: query ?? GET_SUMMARY_FEEDBACK_CONFIGURATION,
    token,
  });

  const handleGetSnippetSummaryFeedbackConfig = ({ variables }) => {
    executeGetSnippetSummaryFeedbackConfig({
      variables,
    });
  };

  return {
    handleGetSnippetSummaryFeedbackConfig,
    isLoading,
  };
};

export { useGetSnippetSummaryFeedbackConfig };
