import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../../ErrorModal';
import MutationRequest from '../../MutationRequest';

export const CREATE_DROPDOWN_RESPONSE_ANS_REQUEST = gql`
  mutation createDropdownListResponsesAnsRequest(
    $responseSetId: Int!
    $responsesDropDownList: [QuestionResponseDropDownListInputType]
  ) {
    questionSet {
      addAvailableAnswers(
        responseSetId: $responseSetId
        responsesDropDownList: $responsesDropDownList
      ) {
        id
      }
    }
  }
`;

const createDropdownListResponsesAnsRequest = ({
  responseSetId,
  responsesDropDownList,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_DROPDOWN_RESPONSE_ANS_REQUEST}>
    {(createDropdownResponseAns, { loading, error, data }) => {
      const variables = {
        responseSetId,
        responsesDropDownList,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createDropdownResponseAns}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createDropdownListResponsesAnsRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  responseSetId: PropTypes.number.isRequired,
  responsesDropDownList: PropTypes.instanceOf(Array).isRequired,
};

createDropdownListResponsesAnsRequest.defaultProps = {};

export default createDropdownListResponsesAnsRequest;
