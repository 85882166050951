import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_GROUP = gql`
  mutation UpdateContentGroup(
    $contentGroupId: Int!
    $title: String!
    $description: String!
    $sort: Int!
    $url: String!
    $estimatedTime: Int
    $releaseDate: DateTime
    $iconAssetId: String
    $contentType: EContentType!
  ) {
    contentGroup {
      updateContentGroup(
        contentGroup: {
          contentGroupId: $contentGroupId
          title: $title
          description: $description
          sort: $sort
          url: $url
          estimatedTime: $estimatedTime
          releaseDate: $releaseDate
          iconAssetId: $iconAssetId
          contentType: $contentType
        }
      ) {
        id
        estimatedTime
        releaseDate
        iconAssetId
        iconAsset {
          contentType
          presignedUrl
          s3Key
        }
        description
        title
        url
        contentType
      }
    }
  }
`;

const updateContentGroupRequest = ({
  contentGroupId,
  title,
  description,
  sort,
  url,
  estimatedTime,
  releaseDate,
  iconAssetId,
  onError,
  onSuccessResult,
  contentType,
}) => (
  <Mutation mutation={UPDATE_CONTENT_GROUP}>
    {(updateContentGroup, { loading, error, data }) => {
      const variables = {
        contentGroupId,
        title,
        description,
        sort,
        url,
        estimatedTime,
        releaseDate,
        iconAssetId,
        contentType,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentGroup.updateContentGroup);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentGroup}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateContentGroupRequest.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  contentType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  estimatedTime: PropTypes.number,
  iconAssetId: PropTypes.string,
  releaseDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
};

updateContentGroupRequest.defaultProps = {
  estimatedTime: null,
  iconAssetId: null,
  releaseDate: null,
};

export default updateContentGroupRequest;
