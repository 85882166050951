import React, { useState } from 'react';

import { EventTrigger } from '../EventTrigger';

import { RevealProps } from './Reveal.types';

import './Reveal.css';

const Reveal = ({
  asset,
  backgroundColor,
  borderColor,
  children,
  defaultToOpen = false,
  eventTitle,
  iconUrl,
  style,
  titleColor,
  triggerStyle,
}: RevealProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultToOpen);

  return (
    <div className="ek-event-reveal c-accordion" style={{ borderColor }}>
      <div className="c-accordion__summary">
        <EventTrigger
          asset={asset}
          backgroundColor={backgroundColor}
          color={titleColor}
          handleClick={() => setIsExpanded(!isExpanded)}
          iconType={triggerStyle}
          iconUrl={iconUrl}
          isExpanded={isExpanded}
          triggerText={eventTitle}
          triggerType={style}
        />
      </div>
      <div className="c-accordion__details" hidden={!isExpanded}>
        {children}
      </div>
    </div>
  );
};

export { Reveal };
