import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_ACTION } from './UseUpdateAction.graphql';

const useUpdateAction = (options) => {
  const { mutation, onCompleted, onError, token } = options ?? {};

  const [executeUpdateAction, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? UPDATE_ACTION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { update: action },
        } = data;

        onCompleted({
          action,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateAction = ({ variables }) => {
    executeUpdateAction({
      variables,
    });
  };

  return {
    handleUpdateAction,
    isLoading,
  };
};

export { useUpdateAction };
