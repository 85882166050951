import gql from 'graphql-tag';

export const GET_COMPLETED_ORDERS_FOR_GROUP = gql`
  query getCompletedOrdersForGroup($groupId: Int!) {
    orders {
      getCompletedOrdersForGroup(groupId: $groupId) {
        id
        modifiedAtUtc
        group {
          id
          name
        }
        items {
          id
          licences {
            appliedToUser {
              email
              firstName
              id
              lastName
            }
            expiresAtUtc
            id
          }
          userFlow {
            id
            title
          }
        }
        transaction {
          id
          invoiceId
          totalBilledAmountIncTaxInDollars
        }
      }
    }
  }
`;
