import React from 'react';

import defaultTail from '../../static/img/default-tail.svg';
import thinkTail from '../../static/img/think-tail.svg';

const BubbleTail = ({ tailType, angle, tailAngles, additionalClassNames, width }) => {
  const tailTypeMap = {
    DEFAULT: defaultTail,
    THINK: thinkTail,
  };

  const tailWidthMap = {
    DEFAULT: '30%',
    THINK: '30%',
  };

  return (
    <img
      className={`c-speech-bubble-tail ${additionalClassNames}`}
      src={tailTypeMap[tailType]}
      alt=""
      style={{
        width: `${width ? `${width}%` : tailWidthMap[tailType]}`,
        transform: `translate(-50%, 0) rotate(${tailAngles[Math.round(angle)]})`,
      }}
    />
  );
};

export default BubbleTail;
