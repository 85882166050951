import React from 'react';

import Dropzone from 'react-dropzone';

const DropzoneArea = ({ dropRef, fileType, multiple = false, onDrop }) => (
  <Dropzone accept={fileType} multiple={multiple} ref={dropRef} onDropAccepted={files => onDrop(files)} noClick noKeyboard>
    {({ getRootProps, getInputProps, isDragActive }) => (
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{`Drop file(s) here${isDragActive ? '' : ' or click to upload'}`}</p>
        {!multiple && (
          <p>
            (<strong>Maximum</strong> 1 file)
          </p>
        )}
      </div>
    )}
  </Dropzone>
);

export default DropzoneArea;
