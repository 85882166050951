import gql from 'graphql-tag';

export const FRAGMENT_MAP = gql`
  fragment map on ContentSnippetMapType {
    longitude
    latitude
    zoom
    draggable
    grayscale
  }
`;
