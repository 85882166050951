import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

const APPROVE_REJECT_VERSION = gql`
  mutation approveOrReject($valid: Boolean!, $appVersionId: Int!) {
    app {
      acceptOrRejectApplication(
        inputType: { valid: $valid, appVersionId: $appVersionId }
      )
    }
  }
`;

const ApproveOrRejectVersion = ({
  valid,
  appVersionId,
  onError,
  onSuccess,
}) => (
  <Mutation mutation={APPROVE_REJECT_VERSION}>
    {(approveOrReject, { loading, error, data }) => {
      const variables = {
        valid,
        appVersionId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccess(data.app.acceptOrRejectApplication);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={approveOrReject}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

ApproveOrRejectVersion.propTypes = {
  appVersionId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  valid: PropTypes.bool.isRequired,
};

export default ApproveOrRejectVersion;
