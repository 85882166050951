import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useDomain } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import { LoadingMenuCard } from '../../components/UI/Card/LoadingMenuCard';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useNewEkardoSiteUrls } from '../../hooks';
import client from '../../middleware/client';
import { GET_PROJECT_QUERY } from '../../middleware/Project/getProjectQuery';
import activityImage from '../../static/img/contentGroupTypes/activity.svg';
import blogImage from '../../static/img/contentGroupTypes/blog.svg';
import cartoonImage from '../../static/img/contentGroupTypes/cartoon.svg';
import consentImage from '../../static/img/contentGroupTypes/consent.svg';
import eventsImage from '../../static/img/contentGroupTypes/events.svg';
import helpImage from '../../static/img/contentGroupTypes/help.svg';
import learningImage from '../../static/img/contentGroupTypes/learning.svg';
import newsImage from '../../static/img/contentGroupTypes/news.svg';
import pageImage from '../../static/img/contentGroupTypes/page.svg';
import questionnaireImage from '../../static/img/contentGroupTypes/questionaire.svg';
import resourcesImage from '../../static/img/contentGroupTypes/resources.svg';

const CardAvatar = styled.div`
  align-items: center;
  background-color: ${(props) => props.color || '#4bc1c9'};
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.6em;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  margin: 0 auto 0.6em auto;
  max-height: 50px;
  max-width: 50px;
  width: 100%;
`;

const CardInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const CardBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -1.7em;
`;

const ContentTypePage = (props) => {
  const {
    match: { url, params },
  } = props;
  const { contentGroup } = useContext(AppContext);
  const { projectId, orgId } = params;

  const { isDomainReady } = useDomain();
  const { getBaseUrl: getNewEkardoSiteBaseUrl } = useNewEkardoSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_NEW_EKARDO_SITE_LOCAL_PORT,
  });

  const { loading, data } = useQuery(GET_PROJECT_QUERY, {
    client,
    variables: {
      projectId: params.projectId,
    },
  });

  const [breadCrumbPath, setBreadcrumbPath] = useState([]);
  let contentTypes = [];
  if (data) contentTypes = data.project.getProject.availableContentGroupType;

  const icons = {
    Learning: learningImage,
    Resources: resourcesImage,
    Page: pageImage,
    News: newsImage,
    Blog: blogImage,
    Questionnaire: questionnaireImage,
    Events: eventsImage,
    Help: helpImage,
    Consent: consentImage,
    Cartoon: cartoonImage,
    Activity: activityImage,
  };

  const handleAvatar = (title) => {
    const splitTitle = title.split(' ');
    return splitTitle.length > 1 ? `${splitTitle[0].split('')[0]}${splitTitle[1].split('')[0]}` : title.split('')[0];
  };

  useEffect(() => {
    if (!isDomainReady) return;

    setBreadcrumbPath([
      {
        path: `${getNewEkardoSiteBaseUrl()}/dashboard/${orgId}/${projectId}/content`,
        title: 'Content group library',
        isExternal: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage your content groups"
        pageDescription="Manage your content groups"
        pageTitle="Content group type page"
        params={params}
      >
        <ProjectNavBar title={contentGroup.name} />
        <CardBox>
          {loading && <LoadingMenuCard count={12} />}
          {!loading &&
            contentTypes.map((item) => (
              <MenuCard key={item} href={`${url}/${item.toLowerCase()}`} name={item} src={icons[item]} url={`${url}/${item.toLowerCase()}`}>
                {!icons[item] && (
                  <CardInner>
                    <CardAvatar>{handleAvatar(item)}</CardAvatar>
                  </CardInner>
                )}
              </MenuCard>
            ))}
        </CardBox>
      </PageWrapper>
    </>
  );
};

ContentTypePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string,
      projectId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default ContentTypePage;
